<div class="block column">
  <div class="heading2">Add invitations to my calendar</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.invitations"
    [options]="invitationsOptions"
    appearance="amethyst"
  />
</div>

<div class="block no-border column">
  <div class="heading2">Invitations from other people</div>

  <div class="body-primary">Control how invitations,responses, and notifications are handled</div>

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.updatedInvitations"
    title="Delete invitations and responses that have been updated"
    appearance="new"
  />

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.forwardedInvitations"
    title="Delete invitations about forwarded events"
    appearance="new"
  />
</div>

<div class="block">
  <div class="work-week">
    <div class="heading2">Work Week</div>

    <div class="body-primary">Select the work week format that best fits your schedule.</div>
  </div>

  <app-work-week-picker
    [inputFormControl]="form.controls.workWeek"
  />
</div>

<div class="block column">
  <div class="heading2">Start Week on</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.startWeek"
    [options]="weekDays"
    appearance="amethyst"
  />
</div>

<div class="block column no-border">
  <div class="heading2">Show Hours in:</div>

  <div class="block-row">
    <app-radio-button
      [inputFormControl]="form.controls.showHoursIn"
      title="15-minute increments"
      [value]="15"
      appearance="radio"
    />

    <app-radio-button
      [inputFormControl]="form.controls.showHoursIn"
      title="30-minute increments"
      [value]="30"
      appearance="radio"
    />
  </div>
</div>

<div class="block column no-border">
  <div class="heading2">Meeting Hours:</div>

  <div class="block-row">
    <app-time-picker
      [inputFormControl]="form.controls.meetingHoursFrom"
    />

    <app-time-picker
      [inputFormControl]="form.controls.meetingHoursTo"
    />
  </div>
</div>

<div class="block column no-border">
  <div class="heading2">Meeting Hours:</div>

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.showWeekends"
    title="Show weekends"
    appearance="new"
  />

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.showDeclinedEvents"
    title="Show declined events"
    appearance="new"
  />

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.showWeekNumbers"
    title="Show week numbers"
    appearance="new"
  />

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.unBrightPastEvents"
    title="Reduce the brightness of past events"
    appearance="new"
  />

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.defaultShortEventsSize"
    title="Display shorter events the same size as 30 minute events"
    appearance="new"
  />
</div>
