// Common
import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// Services
import { AccountService } from '@modules/account/services/account.service';

// RxJs
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less'],
  standalone: false,
})
export class ForgotPasswordComponent implements OnDestroy {
  public emailFormControl = new FormControl('');
  public requested = false;

  private alive = new Subject<void>();

  constructor(private accountService: AccountService) {}

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleSubmit() {
    this.accountService
      .initRestorePassword(this.emailFormControl.value)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.requested = true));
  }
}
