// Types
import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class PlansFilters extends Filters {
  sortBy?: 'createdAt' | 'title' | 'monthlyPrice' | 'annuallyPrice';

  constructor(filters?: Like<PlansFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = super.format();

    result['sort_by'] = this.sortBy || 'createdAt';

    return result;
  }
}
