// Common
import { Component, Injector } from '@angular/core';

// Types
import { Project } from '@modules/tasks/types/project';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-project-shared',
  templateUrl: './project-shared.component.html',
  styleUrls: ['./project-shared.component.less'],
  standalone: false,
})
export class ProjectSharedComponent extends SharedBaseComponent<Project> {
  protected stitchType = StitchType.project;

  constructor(injector: Injector) {
    super(injector);
  }

  get project() {
    return this.stitchItem;
  }
}
