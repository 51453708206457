export class Connection {
  createdAt?: Date;
  id?: string;
  name: string;

  // Tech properties
  added?: boolean;
  changed?: boolean;
  deleted?: boolean;

  constructor(connectionObject) {
    this.createdAt = connectionObject.createdAt && new Date(connectionObject.createdAt);
    this.id = connectionObject.id;
    this.name = connectionObject.name;

    this.added = connectionObject.added;
    this.deleted = connectionObject.deleted;
    this.changed = connectionObject.changed;
  }

  static normalizeName = (name) => name.trim().toLowerCase().replace(/\s+/g, ' ').slice(0, 255);
}
