// Common
import { Component, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// Types
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { TasksListState } from '@modules/tasks/types/tasks-list-state';
import { ProjectsListState } from '@modules/tasks/types/projects-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';

// Services
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.projectsDPListState]?: ProjectsListState;
    [StateKey.tasksDPListState]?: TasksListState;
  }
}

@Component({
  selector: 'app-tasks-panel',
  templateUrl: './tasks-panel.component.html',
  styleUrls: ['./tasks-panel.component.less'],
  standalone: false,
})
export class TasksPanelComponent implements OnInit, OnDestroy {
  public tasksListState = new BehaviorSubject<TasksListState>(null);
  public projectsListState = new BehaviorSubject<ProjectsListState>(null);
  public projectsListStateKey: StateKey = StateKey.projectsDPListState;
  public tasksListStateKey: StateKey = StateKey.tasksDPListState;
  public projectsFilters: ProjectsFilters;
  public tasksFilters: TasksFilters;
  public contextMenuOpened: boolean;
  public selectedFilter: VirtualFolder;

  private alive = new Subject<void>();

  @Input() searchTemplate: TemplateRef<any>;

  constructor(
    private dpService: DynamicPanelService,
    private modalService: ModalService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: TaskingAppStateService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([this.projectsListState, this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(debounceTime(300), takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.projectsFilters = new ProjectsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([this.tasksListState, this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(debounceTime(300), takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.tasksFilters = new TasksFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewTask(task = new Task()) {
    this.dpService.setFormItem(task);
  }

  handleNewProject(project = new Project()) {
    this.dpService.setFormItem(project);
  }

  openTaskInModal(task: Task) {
    this.modalService.openFullForm(task, this.injector);
  }

  openTask(task: Task) {
    this.dpService.setFormItem(task);
  }

  openProject(project: Project) {
    this.dpService.setFormItem(project);
  }

  handleTasksListStateChange(event: TasksListState) {
    this.tasksListState.next(event);
  }

  handleProjectsListStateChange(event: ProjectsListState) {
    this.projectsListState.next(event);
  }

  selectFilter(folder: VirtualFolder): void {
    this.selectedFilter = folder;
    this.stateService.setVirtualFolder(folder);
  }
}
