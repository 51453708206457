export default function (x) {
  if (isNaN(x = +x)) return this; // ignore invalid points

  var x0 = this._x0,
    x1 = this._x1;

  // If the binarytree has no extent, initialize them.
  // Integer extent are necessary so that if we later double the extent,
  // the existing half boundaries don’t change due to floating point error!
  if (isNaN(x0)) {
    x1 = (x0 = Math.floor(x)) + 1;
  }

  // Otherwise, double repeatedly to cover.
  else {
    var z = x1 - x0 || 1,
      node = this._root,
      parent,
      i;
    while (x0 > x || x >= x1) {
      i = +(x < x0);
      parent = new Array(2), parent[i] = node, node = parent, z *= 2;
      switch (i) {
        case 0:
          x1 = x0 + z;
          break;
        case 1:
          x0 = x1 - z;
          break;
      }
    }
    if (this._root && this._root.length) this._root = node;
  }
  this._x0 = x0;
  this._x1 = x1;
  return this;
}