// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { CommentsService } from '@modules/comments/services/comments.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Comment } from '@modules/comments/types/comment';
import { Account } from '@modules/account/types/account';
import { Space } from '@modules/settings/types/space';
import { SpaceParticipant } from '@modules/settings/types/space-participant';

@Component({
  selector: 'stch-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.less'],
  standalone: false,
})
export class CommentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stitch: Stitch;
  @Input() comment: Comment;
  @Input() account: Account;
  @Input() space: Space;

  public editMode = false;
  public form: UntypedFormGroup;
  public submitted = false;
  public participant: SpaceParticipant;

  private alive = new Subject<void>();

  constructor(private commentsService: CommentsService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (!this.comment) {
      this.editMode = true;
    }

    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('comment' in changes) {
      this.resetForm();

      if (this.comment && this.space) {
        this.participant = this.space.participants.find(({ id }) => id === this.comment.accountId);
      }
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  resetForm() {
    this.submitted = false;

    this.form = (
      this.comment ||
      new Comment({
        stitchId: this.stitch?.id,
        stitchType: this.stitch?.getStitchType(),
      })
    ).asFormGroup();
  }

  handleEdit() {
    this.editMode = true;
  }

  handleCancel() {
    if (!this.comment) {
      return;
    }
    this.editMode = false;
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const comment = Comment.fromFormGroup(this.form);

    this.commentsService
      .upsert(comment)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetForm();
      });
  }

  handleDelete() {
    this.commentsService.deletePermanently(this.comment.id).pipe(takeUntil(this.alive)).subscribe();
  }
}
