// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';

// Types
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { StateKey } from '@modules/settings/types/state-key';

// RxJs
import { take } from 'rxjs/operators';

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';

@Component({
  selector: 'app-side-bar-groups-tree',
  templateUrl: './side-bar-groups-tree.component.html',
  styleUrls: ['./side-bar-groups-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService],
  standalone: false,
})
export class SideBarGroupsTreeComponent
  extends BaseSidebarContainersTreeComponent<Group, Contact, GroupsFilters, VirtualFolder>
  implements OnInit
{
  selfDragDataTypes = [DragDataTypes.contact, DragDataTypes.group];
  application = Application.contacts;
  treeStateKey = StateKey.contactsSidebarGroupsTree;

  dndPredicate =
    (stitchItem: Stitch) =>
    (dragData: DragData): boolean =>
      stitchItem &&
      !(
        dragData.type === DragDataTypes.group &&
        dragData.data.length === 1 &&
        dragData.data?.[0]?.id === stitchItem?.id
      ) &&
      dragDataTypeAllowed(dragData.type);

  constructor(
    private groupsService: GroupsService,
    private contactsService: ContactsService,
    contactsAppStateService: ContactsAppStateService,
  ) {
    super(groupsService, contactsAppStateService);
  }

  /**
   * Actions
   */

  public handleMove(dragData: DragData, item: Group) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.contact) {
      this.contactsService.bunchUpdate({ ids: this.getIds(dragData.data) }, { groupId: item.id }, { message });
    } else if (dragData.type === DragDataTypes.group) {
      this.groupsService.bunchUpdate({ ids: this.getIds(dragData.data) }, { parentId: item.id }, { message });
    }
  }

  public handleDuplicate(dragData: DragData, group: Group) {
    if (dragData.type === DragDataTypes.contact) {
      this.contactsService.duplicate(this.getIds(dragData.data), group.id).pipe(take(1)).subscribe();
    } else if (dragData.type === DragDataTypes.group) {
      this.groupsService.duplicate(this.getIds(dragData.data)).pipe(take(1)).subscribe();
    }
  }
}
