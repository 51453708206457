// Common
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

// Types
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { ListState } from '@modules/tasks/types/list-state';
import { Tab } from '@modules/common/types/tab';
import { ProjectsListState } from '@modules/tasks/types/projects-list-state';
import { TasksListState } from '@modules/tasks/types/tasks-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.projectsListState]?: ProjectsListState;
    [StateKey.sidebarProjectsListState]?: ProjectsListState;
    [StateKey.tasksListState]?: TasksListState;
  }
}

@Component({
  selector: 'app-tasks-list-container',
  templateUrl: './tasks-list-container.component.html',
  styleUrls: ['./tasks-list-container.component.less'],
  standalone: false,
})
export class TasksListContainerComponent implements OnInit, OnDestroy {
  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder;
  public quickProject: Project;
  public quickTask: Task;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Projects', value: 'projects' },
    { title: 'Tasks', value: 'tasks' },
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'tasks';
  public tasksListState = new BehaviorSubject<TasksListState>(null);
  public projectsListState = new BehaviorSubject<ProjectsListState>(null);
  public projectsListStateKey: StateKey = StateKey.projectsListState;
  public tasksListStateKey: StateKey = StateKey.tasksListState;
  public projectsFilters: ProjectsFilters;
  public tasksFilters: TasksFilters;
  public debug: 'score' = null;

  private alive = new Subject<void>();

  constructor(
    private splitViewService: SplitViewService,
    private stateService: TaskingAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {
    combineLatest([this.projectsListState, this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(debounceTime(300), takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.projectsFilters = new ProjectsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([this.tasksListState, this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(debounceTime(300), takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.tasksFilters = new TasksFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService
      .getMinimized('tasksList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService
      .getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(takeUntil(this.alive))
      .subscribe(([state, folder]) => {
        this.sidebarFilter = folder;
        this.quickTask = Task.fromAdvancedState(state, folder);
        this.quickProject = Project.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewTask(task = new Task()) {
    this.stateService.setMainView(task);
  }

  handleNewProject(project = new Project()) {
    this.stateService.setMainView(project);
  }

  handleDoubleClickTask(task: Task) {
    this.modalService.openFullForm(task, this.injector);
  }

  handleClickTask(task: Task) {
    this.stateService.setMainView(task);
  }

  handleDoubleClickProject(project: Project) {
    this.modalService.openFullForm(project, this.injector);
  }

  handleClickProject(project: Project) {
    this.stateService.setMainView(project);
  }

  handleTasksListStateChange(event: TasksListState) {
    this.tasksListState.next(event);
  }

  handleProjectsListStateChange(event: ProjectsListState) {
    this.projectsListState.next(event);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
