// Common
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { WeekDay } from '@angular/common';

@Component({
  selector: 'app-settings-calendar-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.less'],
  standalone: false,
})
export class CalendarComponent {
  // Public
  public form = new FormGroup({
    invitations: new FormControl<string>('everyone'),
    updatedInvitations: new FormControl<boolean>(true),
    forwardedInvitations: new FormControl<boolean>(false),
    workWeek: new FormControl<number[]>([1, 2, 3, 4, 5]),
    startWeek: new FormControl<number>(0),
    showHoursIn: new FormControl<number>(30),
    meetingHoursFrom: new FormControl<Date>(null),
    meetingHoursTo: new FormControl<Date>(null),
    showWeekends: new FormControl<boolean>(true),
    showDeclinedEvents: new FormControl<boolean>(false),
    showWeekNumbers: new FormControl<boolean>(false),
    unBrightPastEvents: new FormControl<boolean>(true),
    defaultShortEventsSize: new FormControl<boolean>(false),
  });
  public invitationsOptions: DropdownSelectItem<unknown>[] = [
    { title: 'From everyone', value: 'everyone' },
    { title: 'When I respond to the invitation in email', value: 'responded' },
  ];
  public weekDays: DropdownSelectItem<unknown>[] = Object.entries(WeekDay)
    .slice(0, 7)
    .reduce((memo, [key, value]) => [...memo, { title: value, value: parseInt(key, 0) }], []);
}
