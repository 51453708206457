// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateKey } from '@modules/settings/types/state-key';
import { type File } from './file';

// Services
import { SortBy } from '@modules/common/types/SortBy';
import { SortOrder } from '@modules/common/types/sort-order';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export type FilesListStateSortField = 'title' | 'date' | 'due-date' | 'size' | 'score';

export class FilesListState extends BaseStitchListState<File, FilesListStateSortField> {
  @OnChange('onPropertyChange') public override sortBy?: FilesListStateSortField;
  @OnChange('onPropertyChange') today?: boolean;
  @OnChange('onPropertyChange') fromSize?: number;
  @OnChange('onPropertyChange') toSize?: number;
  @OnChange('onPropertyChange') mimeTypes?: string[];

  constructor({
    searchService,
    stateService,
    stateKey,
    withAdvanced,
    defaultSort = { by: 'date', order: SortOrder.DESC },
  }: {
    searchService?: AdvancedSearchService;
    stateService?: StateService;
    stateKey?: StateKey;
    withAdvanced?: boolean;
    defaultSort?: SortBy<FilesListStateSortField>;
  }) {
    super({ searchService, stateService, stateKey, withAdvanced, defaultSort });
  }

  protected override applySavedState(state?: Like<FilesListState>) {
    super.applySavedState(state);

    this.today = !!state?.today;
    this.fromSize = state?.fromSize;
    this.toSize = state?.toSize;
    this.mimeTypes = Array.isArray(state?.mimeTypes) ? state?.mimeTypes : [];
  }

  public override onPropertyChange(attribute) {
    if ((attribute === 'from' && this.from) || (attribute === 'to' && this.to)) {
      this.today = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
    }

    super.onPropertyChange(attribute);
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today,
      fromSize: this.fromSize,
      toSize: this.toSize,
      mimeTypes: this.mimeTypes,
    });
  }

  public override isDefault(): boolean {
    return super.isDefault() && !this.today && !this.fromSize && !this.toSize && !this.mimeTypes?.length;
  }

  protected override syncFromAS({ files: state }: AdvancedSearchState) {
    this.stitchedWith = state.stitched;
    this.fromSize = state.fileSize.from;
    this.toSize = state.fileSize.to;
    this.mimeTypes = state.fileType;
    this.from = state.dateRangeFrom;
    this.to = state.dateRangeTo;
  }

  protected override syncToAS(): void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.files;

    state.stitched = this.stitchedWith;
    state.dateRangeFrom = this.from;
    state.dateRangeTo = this.to;
    state.fileSize.to = this.toSize;
    state.fileSize.from = this.fromSize;
    state.fileType = this.mimeTypes;

    this.searchService.setState(searchState);
  }
}
