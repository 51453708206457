// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stch-dropdown-container',
  templateUrl: './dropdown-container.component.html',
  styleUrls: ['./dropdown-container.component.less'],
  standalone: false,
})
export class DropdownContainerComponent {
  @Input() appearance: 'default' | 'amethyst' | 'sapphire' = 'default';
}
