// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';

@Pipe({
  name: 'titleByMessagesFilter',
  standalone: false,
})
export class TitleByMessagesFilterPipe implements PipeTransform {
  transform(value: VirtualFolder, _folder?: MessageFolder): any {
    if (!value) {
      return 'All';
    }

    switch (value) {
      case 'all_messages':
        return 'Inbox';
      case 'bulk':
        return 'Bulk';
      case 'sent':
        return 'Sent';
      case 'draft':
        return 'Draft';
      case 'flagged':
        return 'Flagged';
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'spam':
        return 'Spam';
      case 'all_folders':
        return 'Folders';
      case 'followed':
        return 'Follow Up';
      case 'snoozed':
        return 'Snoozed';
      case 'scheduled':
        return 'Scheduled';
      default:
        return checkExhaustiveness(value);
    }
  }
}
