<app-context-menu-item
  [withCheckOption]="true"
  title="Tasks"
  [active]="
    filterKey === 'all_tasks' ||
    filterKey === 'scheduled' ||
    filterKey === 'unscheduled'
  "
  (execute)="selectFilter('all_tasks')"
>
  <app-context-menu-item
    [withCheckOption]="true"
    [close]="true"
    title="Scheduled"
    [active]="filterKey === 'scheduled'"
    (execute)="selectFilter('scheduled')"
  />

  <app-context-menu-item
    [withCheckOption]="true"
    [close]="true"
    title="Unscheduled"
    [active]="filterKey === 'unscheduled'"
    (execute)="selectFilter('unscheduled')"
  />
</app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Overdue"
  [active]="filterKey === 'overdue'"
  (execute)="selectFilter('overdue')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Today"
  [active]="filterKey === 'today'"
  (execute)="selectFilter('today')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Next 7 Days"
  [active]="
    filterKey === 'week' ||
    filterKey === 'day0' ||
    filterKey === 'day1' ||
    filterKey === 'day2' ||
    filterKey === 'day3' ||
    filterKey === 'day4' ||
    filterKey === 'day5' ||
    filterKey === 'day6'
  "
  (execute)="selectFilter('week')"
>
  <app-context-menu-item
    *ngFor="let day of nextWeekDays; first as first; index as index"
    [withCheckOption]="true"
    [close]="true"
    [title]="first ? 'Tomorrow' : day | date: 'EEEE'"
    [active]="filterKey === 'day' + index"
    (execute)="selectFilter('day' + index, $event)"
  />
</app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Priority"
  [active]="
    filterKey === 'priority' ||
    filterKey === 'low' ||
    filterKey === 'normal' ||
    filterKey === 'high'
  "
  (execute)="selectFilter('priority')"
>
  <app-context-menu-item
    *ngFor="let priority of prioritiesList"
    [withCheckOption]="true"
    [close]="true"
    [title]="priority.title"
    [active]="filterKey === priority.name"
    (execute)="selectFilter(priority.name)"
  />
</app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Flagged"
  [active]="filterKey === 'flagged'"
  (execute)="selectFilter('flagged')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Follow Up"
  [active]="filterKey === 'followed'"
  (execute)="selectFilter('followed')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Archive"
  [active]="filterKey === 'archived'"
  (execute)="selectFilter('archived')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Trash"
  [active]="filterKey === 'deleted'"
  (execute)="selectFilter('deleted')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Projects"
  [active]="filterKey === 'all_projects'"
  (execute)="selectFilter('all_projects')"
/>
