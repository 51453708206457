import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ContactsListState } from '@modules/contacts/types/contacts-list-state';
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { combineLatest, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contacts-list-context-menu',
  templateUrl: './contacts-list-context-menu.component.html',
  styleUrls: ['./contacts-list-context-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ContactsListContextMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public state: ContactsListState;
  @Input()
  public withAdvancedSearch: boolean;
  @Input()
  public withScore: boolean;

  public innerFromDate: UntypedFormControl = new UntypedFormControl();
  public innerToDate: UntypedFormControl = new UntypedFormControl();
  public dateButtonSelected: 'from' | 'to' = 'from';
  public advancedSearchApplied: boolean;

  private readonly stateChanged = new Subject<void>();
  private readonly alive = new Subject<void>();
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly advancedSearchService = inject(AdvancedSearchService);

  public ngOnInit() {
    combineLatest([
      this.innerFromDate.valueChanges.pipe(startWith(this.state.from)),
      this.innerToDate.valueChanges.pipe(startWith(this.state.to)),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([from, to]) => {
        if (from?.getTime() !== this.state?.from?.getTime() || to?.getTime() !== this.state?.to?.getTime()) {
          this.state.from = from;
          this.state.to = to;
          this.state.notifyStateChanged();
        }
      });

    this.stateChanged
      .pipe(
        startWith(void 0),
        switchMap(() => this.state.changes().pipe(startWith(this.state))),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.innerFromDate.setValue(this.state.from, { emitEvent: false });
        this.innerToDate.setValue(this.state.to, { emitEvent: false });
        this.changeDetectorRef.markForCheck();
      });

    this.advancedSearchService
      .getApplied()
      .pipe(takeUntil(this.alive))
      .subscribe((state) => {
        this.advancedSearchApplied = state;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('state' in changes) {
      this.stateChanged.next();
    }
  }

  public ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  public sort(sortBy: 'title' | 'date' | 'due-date' | 'score') {
    let order = this.state.sortOrder;
    let by = this.state.sortBy;

    if (by === sortBy) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      by = sortBy;
      order = 'asc';
    }

    this.state.sortBy = by;
    this.state.sortOrder = order;
    this.state.notifyStateChanged();
  }

  public pinnedOnTop() {
    this.state.pinnedOnTop = !this.state.pinnedOnTop;
    this.state.notifyStateChanged();
  }

  public flaggedOnTop() {
    this.state.flaggedOnTop = !this.state.flaggedOnTop;
    this.state.notifyStateChanged();
  }

  public flag() {
    this.state.flagged = this.state.flagged ? null : true;
    this.state.notifyStateChanged();
  }

  public pin() {
    this.state.pinned = this.state.pinned ? null : true;
    this.state.notifyStateChanged();
  }

  public stitch(stitch: StitchTypeFilters) {
    this.state.stitchedWith = stitch;
    this.state.notifyStateChanged();
  }

  public today() {
    this.state.today = !this.state.today;
    this.state.notifyStateChanged();
  }
}
