<div class="block column">
  <div class="heading2">Default Text</div>

  <div class="body-primary">Use the 'Remove formatting' button on the toolbar to reset the default text style.</div>
</div>

<div class="block column">
  <div class="heading2">Page Width</div>

  <div class="body-primary">Default page width in a document</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.pageWidth"
    [options]="pageWidthOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Line Height</div>

  <div class="body-primary line-height">Adjusting Line Height will increase and decrease the vertical spacing between lines of text, letting you adjust the amount of text on the screen.</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.lineHeight"
    [options]="lineHeightOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Block Spacing</div>

  <div class="body-primary">Space between each blocks in a document</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.blockSpacing"
    [options]="blockSpacingOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Show Author</div>

  <div class="body-primary">Show the author in the page’s header for documents in Stitch App</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.showAuthor"
    [options]="showAuthorOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Show Date</div>

  <div class="body-primary">Show the date in the page’s header for documents in Stitch App</div>

  <app-dropdown-multiple-input
    class="dropdown"
    [inputFormControl]="form.controls.showDate"
    [options]="showDateOptions"
    appearance="amethyst"
  />
</div>
