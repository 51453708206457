// Common
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// Services
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Connection } from '@modules/connections/types/connection';
import { Message } from '@modules/messages/types/message';
import { Participant } from '../../types/participant';

@Component({
  selector: 'app-message-connections',
  templateUrl: './message-connections.component.html',
  styleUrls: ['./message-connections.component.less'],
  standalone: false,
})
export class MessageConnectionsComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() message: Message;

  public selectedConnections: Connection[];

  private alive = new Subject<void>();

  constructor(private kpService: KnowledgePanelService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.kpService
      .getSelectedConnections()
      .pipe(takeUntil(this.alive))
      .subscribe((connections: Connection[]) => {
        this.selectedConnections = connections;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectContact(contact: Participant, event: MouseEvent) {
    if (!contact) {
      return;
    }

    this.kpService.addConnectionToSelection(new Connection({ name: contact.address }), event, true);
  }
}
