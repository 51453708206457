// Env
import { environment } from '@environment';
import { Stitch } from '@modules/common/types/stitch';

export class Attachment {
  stitchItem: Stitch;
  attachmentId: string;
  fileName: string;
  fileType: string;
  fileSize: string;
  inline: boolean;

  constructor(attachmentObject: Partial<Attachment> = {}) {
    this.attachmentId = attachmentObject.attachmentId;
    this.fileName = attachmentObject.fileName;
    this.fileType = attachmentObject.fileType;
    this.fileSize = attachmentObject.fileSize;
    this.inline = attachmentObject.inline;
    this.stitchItem = attachmentObject.stitchItem;
  }

  download() {
    const link = document.createElement('a');
    //link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      `${environment.baseUrl}/api/attachments/${this.stitchItem.id}/${this.attachmentId}/${this.fileName}`,
    );
    link.setAttribute('download', this.attachmentId);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
