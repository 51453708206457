// Common
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { FoldersService } from '@modules/files/services/folders.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Application } from '@modules/common/types/application';
import { PostponeType } from '@modules/common/types/postpone-type';
import { File } from '@modules/files/types/file';
import { Folder } from '@modules/files/types/folder';

// RX
import { take, takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-folder-context-menu',
  templateUrl: './folder-context-menu.component.html',
  styleUrls: ['../stitch-context-menu/stitch-context-menu.component.less', './folder-context-menu.component.less'],
  standalone: false,
})
export class FolderContextMenuComponent extends StitchContextMenuComponent<Folder> implements OnInit, OnChanges {
  @Input() folder: Folder;

  @Output() openFile = new EventEmitter<File>();

  applicationName = Application.files;
  gaPrefix = 'folder-context-menu';
  public form: UntypedFormGroup;

  constructor(
    private foldersService: FoldersService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, foldersService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.folder;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('folder' in changes) {
      this.item = this.folder;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.foldersService
      .update(Folder.fromFormGroup(this.form))
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.folder || new Folder()).asFormGroup();
  }

  handleFileFormMore(file: File) {
    this.openFile.emit(file);
  }

  duplicate() {
    if (!this.folder) {
      return;
    }

    this.foldersService.duplicate(this.getIds()).pipe(take(1)).subscribe();
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.foldersService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(
      `When deleting, all files in ${this.multiple ? 'these' : 'this'} folder${this.multiple && 's'} will be removed also`,
    );
  }
}
