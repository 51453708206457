// Common
import { Component, Injector } from '@angular/core';

// Types
import { Folder } from '@modules/files/types/folder';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-folder-shared',
  templateUrl: './folder-shared.component.html',
  styleUrls: ['./folder-shared.component.less'],
  standalone: false,
})
export class FolderSharedComponent extends SharedBaseComponent<Folder> {
  protected stitchType = StitchType.folder;

  constructor(injector: Injector) {
    super(injector);
  }

  get folder() {
    return this.stitchItem;
  }
}
