// Common
import { Component } from '@angular/core';

// Services
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { PermissionParticipantsService } from '@modules/messages/services/permission-participants.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesService } from '@modules/files/services/files.service';

@Component({
  selector: 'stch-shared-layout',
  templateUrl: './shared-layout.component.html',
  styleUrls: ['./shared-layout.component.less'],
  providers: [
    StitchServiceFactory,
    MessageFoldersService,
    MessagesService,
    PermissionParticipantsService,
    CalendarsService,
    EventsService,
    ProjectsService,
    TasksService,
    NotebooksService,
    NotesService,
    GroupsService,
    ContactsService,
    FoldersService,
    FilesService,
  ],
  standalone: false,
})
export class SharedLayoutComponent {}
