import { Application } from '@modules/common/types/application';
import { Like } from '@modules/common/types/like';
import { StitchChildFilters } from '@modules/common/types/stitch-child-filters';
import { isNil } from '@modules/common/utils/base';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Note } from './note';
import { NoteListSortField, NotesListState } from './notes-list-state';
import { VirtualFolder } from './virtual-folder';

export class NotesFilters extends StitchChildFilters<Note> {
  // Filters
  fromTime?: Date;
  toTime?: Date;
  createdAt?: Date;
  updatedAt?: Date;

  sortBy?: NoteListSortField;

  constructor(filters?: Like<NotesFilters>) {
    super(filters);
    this.fromTime = filters?.fromTime;
    this.toTime = filters?.toTime;
    this.sortBy = filters?.sortBy;
    this.createdAt = filters?.createdAt;
    this.updatedAt = filters?.updatedAt;
  }

  applyListState(state: NotesListState) {
    const today = new Date();

    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = super.format();

    if (this.containersIds) {
      result['notebooks_ids[]'] = this.containersIds;
    }
    if (this.originsIds) {
      result['notebooks_ids[]'] = this.originsIds;
    }
    if (!isNil(this.noContainer)) {
      result['no_notebook'] = this.noContainer + '';
    }

    if (this.fromTime) {
      result['from_time'] = this.fromTime.toISOString();
    }
    if (this.toTime) {
      result['to_time'] = this.toTime.toISOString();
    }
    if (this.createdAt) {
      result['created_at'] = this.createdAt.toISOString();
    }
    if (this.updatedAt) {
      result['updated_at'] = this.updatedAt.toISOString();
    }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced, Application.notes);

    const state = advanced.notes;

    this.esTitle = state.title;
    this.esBody = state.description + ' ' + advanced.notes.body;
    this.createdAt = state.createdAt;
    this.updatedAt = state.updatedAt;
    this.fromTime = state.dateRangeFrom;
    this.toTime = state.dateRangeTo;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) {
      return this;
    }

    this.deleted = false;
    this.archived = false;
    this.noParent = null;
    this.noContainer = null;
    this.snoozedOnTop = true;
    this.followedOnTop = true;

    switch (folder) {
      case 'archived':
        this.archived = true;
        break;
      case 'deleted':
        this.deleted = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      case 'all_notes':
        this.noParent = true;
        this.noContainer = true;
        break;
      case 'all_notebooks':
      case 'upcoming':
      case 'recent':
        break;
      case 'flagged':
        this.flagged = true;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
