<div
  *ngIf="notification"
  class="notification"
  (click)="handleClickEntity()"
>
  <img
    *ngIf="notification.icon"
    class="notification-icon"
    [src]="notification.icon"
    alt=""
  />

  <div class="notification-body">
    <h4>
      <span *ngIf="notification?.subjectType === 'task' && showIssueKey && notification?.entity?.issueKeyTitle">{{ notification?.entity?.issueKeyTitle }}</span>
      {{ notification.title }}
    </h4>
    <div>{{ notification.content }}</div>
    <div
      class="notification-body-entity"
      [class.underline]="notification.entity"
    >
      <span *ngIf="notification?.subjectType === 'task' && showIssueKey && notification?.entity?.issueKeyTitle">{{ notification?.entity?.issueKeyTitle }}</span>
      <span>{{ notification?.entity?.title }}</span>
    </div>
  </div>

  <span class="notification-created">{{ notification.createdAt | date: 'MMM dd hh:mm aaa' }}</span>
</div>
