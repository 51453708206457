import { ComponentRef, Directive, inject, Input, OnInit } from '@angular/core';
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { StateKey } from '@modules/settings/types/state-key';
import { startWith, takeUntil } from 'rxjs/operators';
import { CalendarsListContextMenuComponent } from '../components/calendars-list-context-menu/calendars-list-context-menu.component';
import { CalendarsListStateService } from '../services/calendars-list-state.service';
import { CalendarsListState } from '../types/calendars-list-state';

@Directive({
  selector: '[appCalendarsListContextMenu]',
  standalone: false,
})
export class CalendarsListContextMenuDirective extends ContextMenuDirective implements OnInit {
  @Input() appCalendarsListContextMenuWithAdvancedSearch: boolean;
  @Input() appCalendarsListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  private readonly calendarsListStateService = inject(CalendarsListStateService);

  /**
   * Context Menu Interface
   */
  public registerInstance(componentRef: ComponentRef<CalendarsListContextMenuComponent>) {
    componentRef.instance.state = this.calendarsListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appCalendarsListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appCalendarsListContextMenuWithScore;
  }

  public registerComponent() {
    return CalendarsListContextMenuComponent;
  }

  /**
   * Lifecycle
   */
  public override ngOnInit() {
    super.ngOnInit();

    this.calendarsListStateService.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.isDefaultStateObservable?.next(this.calendarsListStateService.state.isDefault());
      });

    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.calendarsListStateService.state.isDefault()) {
        this.calendarsListStateService.state.resetToDefault();
      }
    });
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.calendarsListState]?: CalendarsListState;
    [StateKey.sidebarCalendarsListState]?: CalendarsListState;
  }
}
