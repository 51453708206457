  <div
    *ngIf="!pushNotificationsEnabled"
    class="block"
  >
    Your push notifications is not active. Please
    <app-button
      appearance="link-blue"
      label="enable"
      (click)="requestPermission()"
    />
    push notifications
  </div>

  <div class="block">
    <div class="block-grow">
      <div class="heading2">
        Notifications

        <app-toggle-switch
          class="heading2-toggle"
          [inputFormControl]="toggleAllNotifications"
        />
      </div>
      <div class="body-primary">
        Allows to display popup notifications on your desktop
      </div>
    </div>

    <div class="block-row">
      <div class="block-col">
        <div class="block-col-item vertical heading3">
          Mail App

          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="mailForm.controls.notificationsEnabled"
          />
        </div>

        <div class="block-col-item vertical heading3">
          Calendar App
          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="calendarForm.controls.notificationsEnabled"
          />
        </div>
      </div>

      <div class="block-col">
        <div class="block-col-item vertical heading3">
          Task App
          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="taskingForm.controls.notificationsEnabled"
          />
        </div>

        <div class="block-col-item vertical heading3">
          Note App

          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="notesForm.controls.notificationsEnabled"
          />
        </div>
      </div>

      <div class="block-col">
        <div class="block-col-item vertical heading3">
          File App

          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="filesForm.controls.notificationsEnabled"
          />
        </div>

        <div class=" block-col-item vertical heading3">
          Contact App

          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="contactsForm.controls.notificationsEnabled"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="block">
    <div class="block-grow">
      <div class="heading2">
        Reminders

        <app-toggle-switch
          class="heading2-toggle"
          [inputFormControl]="toggleAllReminders"
        />
      </div>
      <div class="body-primary">
        Allows to display popup notifications on your desktop
      </div>
    </div>

    <div class="block-col">
      <div class="block-col-container">
        <div class="heading3 block-col-item task-app-extra-space">
          Task App
          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="taskingForm.controls.remindersEnabled"
          />
        </div>

        <div class="heading3 block-col-item task-app-extra-space">
          Calendar App
          <app-toggle-switch
            class="heading2-toggle"
            [inputFormControl]="calendarForm.controls.remindersEnabled"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="block">
    <div class="block-grow">
      <div class="heading2">
        Snooze/Follow up

        <app-toggle-switch
          class="heading2-toggle"
          [inputFormControl]="toggleAllPostpone"
        />
      </div>
      <div class="body-primary">
        Allows to display popup notifications on your desktop.
      </div>
    </div>

    <div class="block-row">
      <div class="block-col">
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="mailForm.controls.messagesPostponeNotificationsEnabled"
          title="Mails"
          appearance="new"
        />
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="mailForm.controls.messageFoldersPostponeNotificationsEnabled"
          title="Mail folders"
          appearance="new"
        />
      </div>

      <div class="block-col">
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="calendarForm.controls.calendarsPostponeNotificationsEnabled"
          title="Calendars"
          appearance="new"
        />
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="calendarForm.controls.eventsPostponeNotificationsEnabled"
          title="Events"
          appearance="new"
        />
      </div>

      <div class="block-col">
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="filesForm.controls.filesPostponeNotificationsEnabled"
          title="Files"
          appearance="new"
        />
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="filesForm.controls.foldersPostponeNotificationsEnabled"
          title="Folders"
          appearance="new"
        />
      </div>

      <div class="block-col">
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="taskingForm.controls.tasksPostponeNotificationsEnabled"
          title="Tasks"
          appearance="new"
        />
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="taskingForm.controls.projectsPostponeNotificationsEnabled"
          title="Projects"
          appearance="new"
        />
      </div>

      <div class="block-col">
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="notesForm.controls.notesPostponeNotificationsEnabled"
          title="Notes"
          appearance="new"
        />
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="notesForm.controls.notebooksPostponeNotificationsEnabled"
          title="Notebooks"
          appearance="new"
        />
      </div>

      <div class="block-col">
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="contactsForm.controls.contactsPostponeNotificationsEnabled"
          title="Contacts"
          appearance="new"
        />
        <app-checkbox
          class="block-col-item vertical"
          [inputFormControl]="contactsForm.controls.groupsPostponeNotificationsEnabled"
          title="Groups"
          appearance="new"
        />
      </div>
    </div>
  </div>

  <div class="block">
    <div class="block-grow">
      <div class="heading2">
        Push Notifications

        <app-toggle-switch
          class="heading2-toggle"
          [inputFormControl]="notificationsForm.controls.isPushEnabled"
        />
      </div>
      <div class="body-primary full-width">
        Allows to receive push notifications
      </div>
    </div>

    <div class="block-col">
      <app-button appearance="link-blue" label="Check Space Volume" />
    </div>
  </div>

  <div class="block">
    <div class="block-grow">
      <div class="heading2">
        Space Volume

        <app-toggle-switch
          class="heading2-toggle"
          [inputFormControl]="spaceVolumeToggleControl"
        />
      </div>
      <div class="body-primary full-width">
        I'm running out of space in my account.
      </div>
    </div>

    <div class="block-col">
      <app-button appearance="link-blue" label="Check Space Volume" />
    </div>
  </div>
