// Common
import { ComponentRef, Directive, Input } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { RowContextMenuComponent } from '@modules/tasks/components/row-context-menu/row-context-menu.component';

// Types
import { Row } from '@modules/tasks/types/row';

@Directive({
  selector: '[appRowContextMenu]',
  standalone: false,
})
export class RowContextMenuDirective extends ContextMenuDirective {
  // Inputs
  @Input() appRowContextMenu: Row;

  registerComponent() {
    return RowContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<RowContextMenuComponent>) {
    componentRef.instance.row = this.appRowContextMenu;
  }
}
