// Common
import { ComponentRef, Directive, Input } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ParticipantContextMenuComponent } from '../components/participant-context-menu/participant-context-menu.component';

// Types
import { Participant } from '../types/participant';

@Directive({
  selector: '[appParticipantContextMenu]',
  standalone: false,
})
export class ParticipantContextMenuDirective extends ContextMenuDirective {
  // Inputs
  @Input() appParticipantContextMenu: Participant;

  registerComponent() {
    return ParticipantContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<ParticipantContextMenuComponent>) {
    componentRef.instance.participant = this.appParticipantContextMenu;
  }
}
