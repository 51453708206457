// Common
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

// Utils
import { addDays, beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { CalendarContact } from '@modules/calendar-app/types/calendar-contact';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Like } from '@modules/common/types/like';
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { TypedFormGroup } from '@modules/common/utils/form';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';
import { Message } from '@modules/messages/types/message';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Priority } from '@modules/tasks/types/priority';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Task } from './task';

// Pipes
import { DatePipe } from '@angular/common';
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';

// Validators
import { NestedValidators } from '@modules/form-controls/validators/nested.validators';

// Services
import { ProjectsService } from '../services/projects.service';

type ProjectFormGroup = FormGroup<TypedFormGroup<Project> & { reminders: FormArray<ReminderFormGroup> }>;

export class Project extends Stitch {
  completed?: boolean;
  fromDate?: Date;
  fromTime?: Date;
  issueKey?: string;
  parentId?: string;
  participants?: CalendarContact[];
  priority?: Priority;
  progress?: number;
  reminders?: Reminder[];
  toDate?: Date;
  toTime?: Date;
  title?: string;
  readonly tasksCount?: number;

  constructor(data: Partial<Project> = {}) {
    super(data);

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.fromDate = data.fromDate && new Date(data.fromDate);
      this.fromTime = data.fromTime && new Date(data.fromTime);
      this.participants = data.participants;
      this.priority = data.priority || 'normal';
      this.reminders =
        (Array.isArray(data.reminders) && data.reminders?.map((reminder) => new Reminder(reminder))) || [];
      this.title = data.title;
      this.toDate = data.toDate && new Date(data.toDate);
      this.toTime = data.toTime && new Date(data.toTime);
      this.parentId = data.parentId || null;
      this.issueKey = data.issueKey;
      this.completed = data.completed;
      this.progress = data.progress;
      this.tasksCount = data.tasksCount || 0;
    }
  }

  static fromFormGroup(form: ProjectFormGroup): Project {
    const formValues = form.value;

    return new Project({
      archived: formValues.archived,
      color: formValues.color,
      deleted: formValues.deleted,
      description: formValues.description,
      flagged: formValues.flagged,
      followed: formValues.followed,
      fromDate: formValues.fromDate,
      fromTime: formValues.fromTime,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      parentId: formValues.parentId,
      priority: formValues.priority,
      reminders: form.controls.reminders.controls.map((reminder) => Reminder.fromFormGroup(reminder)),
      snoozed: formValues.snoozed,
      tags: formValues.tags,
      title: formValues.title,
      toDate: formValues.toDate,
      toTime: formValues.toTime,
      issueKey: formValues.issueKey,
      connections: formValues.connections,
      completed: formValues.completed,
      uploads: formValues.uploads,
    });
  }

  static fromDragData(dragItem: DragData): Project {
    return super.fromDragData(dragItem) as Project;
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Project {
    const today = new Date();

    const attributes = {
      title: filters.query || filters.tasking.title,
      tags: filters.tags,
      knots: filters.knots,
      parentId: filters.tasking.containersIds[0],
      description: filters.tasking.description,
      priority: filters.tasking.priority,
      fromDate: filters.tasking.startDate,
      fromTime: filters.tasking.startDate,
      toDate: filters.tasking.dueDate,
      toTime: filters.tasking.dueDate,

      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
    };

    switch (folder) {
      case 'today':
        attributes.fromDate = beginningOfDay(today);
        attributes.toDate = endOfDay(today);
        attributes.fromTime = beginningOfDay(today);
        attributes.toTime = endOfDay(today);
        break;
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
        const dateByDayIndexPipe = new DateByDayIndexPipe();
        const day = dateByDayIndexPipe.transform(folder);

        attributes.fromTime = beginningOfDay(day);
        attributes.toTime = endOfDay(day);
        attributes.fromDate = beginningOfDay(day);
        attributes.toDate = endOfDay(day);
        break;
      case 'week':
        attributes.fromTime = beginningOfDay(addDays(today, 1));
        attributes.toTime = endOfDay(addDays(today, 7));
        attributes.fromDate = beginningOfDay(addDays(today, 1));
        attributes.toDate = endOfDay(addDays(today, 7));
        break;
      case 'low':
      case 'normal':
      case 'high':
        attributes.priority = folder;
        break;
    }

    return new Project(attributes);
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color', 'parentId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<Project>; message: string } {
    let message = 'Successfully moved to ';
    const dateByDayIndexPipe = new DateByDayIndexPipe();

    switch (sidebar) {
      case 'all_tasks':
        break;
      case 'all_projects':
        return { changes: { parentId: null }, message: (message += 'Orphan') };
      case 'scheduled':
        return {
          changes: {
            toDate: dateByDayIndexPipe.transform('today'),
            toTime: dateByDayIndexPipe.transform('today'),
          },
          message: (message += 'Scheduled'),
        };
      case 'today':
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
      case 'week':
        const day = dateByDayIndexPipe.transform(sidebar);
        const datePipe = new DatePipe('en-us');

        return {
          changes: { fromDate: day, fromTime: day },
          message: (message += sidebar === 'day0' ? 'Tomorrow' : datePipe.transform(day, 'EEEE')),
        };
      case 'priority':
        break;
      case 'low':
      case 'normal':
      case 'high':
        return { changes: { priority: sidebar }, message: (message += sidebar) };
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      case 'unscheduled':
        return { changes: { toDate: null, toTime: null }, message: (message += 'Unscheduled') };
      case 'overdue':
        break;
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.project;
  }

  asFormGroup(projectsService?: ProjectsService): ProjectFormGroup {
    return this.formBuilder.group(
      {
        archived: [this.archived],
        color: [this.color],
        deleted: [this.deleted],
        description: [this.description],
        flagged: [this.flagged],
        followed: [this.followed],
        fromDate: [this.fromDate],
        fromTime: [this.fromTime],
        id: [this.id],
        knots: [this.knots || []],
        linkedInfo: [this.linkedInfo || []],
        parentId: [this.parentId || ''],
        priority: [this.priority],
        reminders: this.formBuilder.array((this.reminders || []).map((reminder) => reminder.asFormGroup())),
        snoozed: [this.snoozed],
        tags: [this.tags || []],
        title: [this.title, Validators.required],
        issueKey: [this.issueKey],
        toDate: [this.toDate],
        toTime: [this.toTime],
        completed: [this.completed],
        uploads: [this.uploads || [], FileValidators.size(26214400)],
      },
      {
        asyncValidators: [NestedValidators.circular('id', 'parentId', projectsService)],
      },
    );
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      color: this.color,
      deleted: this.deleted,
      description: this.description,
      flagged: this.flagged,
      followed: this.followed,
      fromDate: this.fromDate,
      fromTime: this.fromTime,
      parentId: this.parentId,
      participants: this.participants,
      priority: this.priority,
      reminders: (this.reminders || []).map((reminder) => reminder.asPayloadJson(this.fromTime, this.toTime)),
      snoozed: this.snoozed,
      title: this.title && this.title.trim(),
      issueKey: this.issueKey,
      toDate: this.toDate,
      toTime: this.toTime,
      noNotification: this.noNotification,
      completed: this.completed,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(this.title, this.fromDate, this.toDate, this.fromTime, this.toTime);
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.fromDate = data.date;
      this.fromTime = data.date;
      this.toDate = data.date;
      this.toTime = data.date;
    } else if (data instanceof CalendarEvent) {
      this.fromDate = data.endTime;
      this.fromTime = data.startTime;
      this.reminders = data.reminders;
      this.toDate = data.startTime;
      this.toTime = data.endTime;
    } else if (data instanceof Project) {
      this.fromDate = data.fromDate;
      this.fromTime = data.fromTime;
      this.participants = data.participants;
      this.priority = data.priority;
      this.reminders = data.reminders;
      this.toDate = data.toDate;
      this.toTime = data.toTime;
      this.completed = data.completed;
    } else if (data instanceof Task) {
      this.fromDate = data.fromDate;
      this.fromTime = data.fromTime;
      this.reminders = data.reminders;
      this.toDate = data.toDate;
      this.toTime = data.toTime;
      this.completed = data.completed;
    } else if (data instanceof Notebook) {
      this.fromDate = data.createdAt;
      this.fromTime = data.createdAt;
      this.toDate = data.updatedAt;
      this.toTime = data.updatedAt;
    } else if (data instanceof Note) {
      this.toDate = data.updatedAt;
      this.toTime = data.updatedAt;
      this.fromDate = data.createdAt;
      this.fromTime = data.createdAt;
    }
  }
}
