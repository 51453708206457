// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MatIconModule } from '@angular/material/icon';

// Components
import { SplitViewComponent } from './components/split-view/split-view.component';
import { SplitViewToggleDirective } from './directives/split-view-toggle.directive';

// Services
import { SplitViewService } from './services/split-view.service';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [SplitViewComponent, SplitViewToggleDirective],
  declarations: [SplitViewComponent, SplitViewToggleDirective],
  providers: [SplitViewService],
})
export class SplitViewModule {}
