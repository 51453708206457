// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';

// Services
import { MessagesService } from '@modules/messages/services/messages.service';
import { BackgroundJobsService } from '@modules/common/services/background-jobs.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Types
import { Message } from '@modules/messages/types/message';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';
import { type MessageActionType } from '@modules/messages/types/message-action-type';

// RX
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-message-context-menu',
  templateUrl: './message-context-menu.component.html',
  styleUrls: ['./message-context-menu.component.less'],
  standalone: false,
})
export class MessageContextMenuComponent extends StitchContextMenuComponent<Message> implements OnInit, OnChanges {
  @Input() message: Message;

  applicationName = Application.mail;
  gaPrefix = 'message-context-menu';

  constructor(
    private messagesService: MessagesService,
    private backgroundJobs: BackgroundJobsService,
    private toasterService: ToasterService,
    private messagesStateService: MailAppStateService,
    protected injector: Injector,
  ) {
    super(injector, messagesService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.message;
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('message' in changes) {
      this.item = this.message;
    }
  }

  /**
   * Actions
   */

  read() {
    if (!this.message) {
      return;
    }

    this.ga.trackEvent(this.gaPrefix, this.message.unread ? 'read' : 'unread');
    this.messagesService.read({ ids: this.getIds() }, this.message.unread);
  }

  bulk() {
    if (!this.message) {
      return;
    }

    this.ga.trackEvent(this.gaPrefix, this.message.bulk ? 'unbulk' : 'bulk');

    this.messagesService.bulk({ ids: this.getIds() }, !this.message.bulk);
  }

  spam() {
    if (!this.message) {
      return;
    }

    this.ga.trackEvent(this.gaPrefix, this.message.spam ? 'unspam' : 'spam');

    this.messagesService.spam({ ids: this.getIds() }, !this.message.bulk);
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.messagesService[postponeType]({ ids: this.getIds() }, date);
  }

  unschedule() {
    this.backgroundJobs
      .undo({ type: 'message-deliver', id: `message-deliver-${this.message.id}` })
      .pipe(
        tap(() => {
          this.toasterService.show({ text: 'Message sending undone.' });
        }),
        catchError(() => {
          this.toasterService.show({ text: "Message sending can't be undone." });
          return of(null);
        }),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.messagesService.forceRefresh(1000);
        this.close();
      });
  }

  sendNow() {
    this.backgroundJobs
      .force({ type: 'message-deliver', id: `message-deliver-${this.message.id}` })
      .pipe(
        tap(() => {
          this.toasterService.show({ text: 'Message sent.' });
        }),
        catchError(() => {
          this.toasterService.show({ text: "Message can't be send." });
          return of(null);
        }),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.messagesService.forceRefresh(1000);
        this.close();
      });
  }

  forward(action: MessageActionType) {
    this.messagesStateService.composeMessage({
      reply: this.message,
      forward: this.multiple ? this.selectedItems : [this.message],
      action,
      injector: this.injector,
    });

    this.close();
  }

  downloadOriginal() {
    this.messagesService.downloadOriginal(this.message);
  }

  deletePermanently() {
    super.deletePermanently(
      `Are you sure you want to remove ${this.multiple ? 'these' : 'this'} message${this.multiple ? 's' : ''}?`,
    );
  }
}
