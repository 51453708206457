// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Environment
import { environment } from '@environment';

// Types
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { MessageTemplate } from '@modules/account/types/message-template';

// Services
import { BaseService } from '@modules/common/services/base.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

@Injectable()
export class TemplatesService extends BaseService {
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {
    super();
  }

  search(_filters?: unknown, _config?: object): Observable<BaseSearchResponse<MessageTemplate>> {
    return this.refreshRequired.pipe(
      switchMap(() =>
        this.http.get<{ count: number; items: MessageTemplate[] }>(environment.baseUrl + '/api/mail/templates'),
      ),
      map(({ count, items = [] }) => ({ count, items: items.map((template) => new MessageTemplate(template)) })),
    );
  }

  create(instance: MessageTemplate): Observable<MessageTemplate> {
    return this.http
      .post<{
        success: boolean;
        data: MessageTemplate;
      }>(environment.baseUrl + '/api/mail/templates', instance.asPayloadJSON())
      .pipe(map((response: any) => new MessageTemplate(response.data)));
  }

  update(instance: MessageTemplate): Observable<MessageTemplate> {
    return this.http
      .put<{
        success: boolean;
        data: MessageTemplate;
      }>(environment.baseUrl + '/api/mail/templates/' + instance.id, instance.asPayloadJSON())
      .pipe(map((response: any) => new MessageTemplate(response.data)));
  }

  @warmUpObservable
  upsert(instance: MessageTemplate, feedbackConfig: FeedbackConfig = { emit: true }): Observable<MessageTemplate> {
    return (instance.id ? this.update(instance) : this.create(instance)).pipe(
      tap(() => {
        feedbackConfig.emit && this.forceRefresh();
        feedbackConfig.displayToast &&
          this.toasterService.show({
            text: feedbackConfig.toastMessage || `Template ${instance.id ? 'updated' : 'created'} successfully`,
          });
      }),
      catchError((error: HttpErrorResponse) => {
        this.toasterService.show({ text: error.error.error });
        return throwError(error);
      }),
    );
  }

  @warmUpObservable
  delete(instance: MessageTemplate, feedbackConfig: FeedbackConfig = { emit: true }): Observable<boolean> {
    return this.http.delete<boolean>(environment.baseUrl + '/api/mail/templates/' + instance.id).pipe(
      tap(() => {
        feedbackConfig.emit && this.forceRefresh();
        feedbackConfig.displayToast &&
          this.toasterService.show({
            text: feedbackConfig.toastMessage || 'Template removed successfully',
          });
      }),
      catchError((error: HttpErrorResponse) => {
        this.toasterService.show({ text: error.error.error });
        return throwError(error);
      }),
    );
  }
}
