import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { environment } from '@environment';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { NotebookComponent } from '@modules/elements/components/notebook/notebook.component';
import { KnotSource } from '@modules/knots/types/knot-source';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notebooks-list',
  templateUrl: './notebooks-list.component.html',
  styleUrls: ['./notebooks-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class NotebooksListComponent extends BaseStitchListComponent<Notebook> implements OnInit, OnDestroy, OnChanges {
  @Input()
  public override filters: Partial<NotebooksFilters> = {};
  @Input()
  public withTags = false;
  @Input()
  public withKnots = false;
  @Input()
  public knotsSource: KnotSource;
  @Input()
  public debug: 'score';
  @Input()
  public notFoundTemplate?: TemplateRef<void>;

  @Output()
  public readonly clickNotebook = new EventEmitter<Notebook>();
  @Output()
  public readonly openNotebook = new EventEmitter<Notebook>();
  @Output()
  public readonly doubleClickNotebook = new EventEmitter<Notebook>();
  @Output()
  public readonly openNote = new EventEmitter<Note>();

  @ViewChildren(NotebookComponent, { read: ElementRef<HTMLElement> })
  public override itemElements: QueryList<ElementRef<HTMLElement>>;

  public override items: StitchListItem<Notebook>[] = [];

  private readonly notebooksService = inject(NotebooksService);
  private readonly notesStateService = inject(NotesAppStateService);
  private readonly linkedInfoService = inject(LinkedInfoService);

  public override ngOnInit() {
    merge(
      // Global refresh button
      this.notesStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getRefresh(),
      // Make automatic updates for new notebook
      interval(environment.messageFetchInterval),
      this.notebooksService.getRefresh(),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadPage(this.page);
      });

    merge(this.notesStateService.getRefreshAll(), this.notebooksService.getRefreshRequired())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: Notebook[]; count: number }> {
    return this.notebooksService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  public handleDoubleClickItem(notebook: Notebook) {
    this.doubleClickNotebook.emit(notebook);
  }

  public handleClickItem(notebook: Notebook) {
    this.clickNotebook.emit(notebook);
  }
}
