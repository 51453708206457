<div class="knots">
  <div class="knots-title">
    Add Knots
  </div>

  <app-knots
    class="knots-existing-items"
    [stitchItems]="stitchItems"
    [control]="control"
    [withControls]="false"
    [withPlaceholder]="true"
    [withTemporalExpressions]="false"
  />

  <div
    class="knots-area"
    [class.focused]="focused"
  >
    <app-simple-input
      class="knots-input"
      placeholder="Add New Knot"
      [inputFormControl]="inputControl"
      [noBorder]="true"
      [withClear]="true"
      [suggestions]="suggestions"
      [forceOutput]="true"
      [openImmediately]="false"
      (onEnter)="addKnot()"
      (onSelect)="handleSelect($event)"
      (onFocus)="focused = true"
      (onBlur)="focused = false"
    />

    <div
      *ngIf="canAdd && suggestionsReceived"
      class="knots-add"
      (click)="addKnot()"
    >
      Add Knot «{{ inputControl.value }}»
    </div>

    <div
      class="knots-placeholder"
      *ngIf="!canAdd && knots?.length === 0"
    >
      You can add multiple Knots with enter e.g. personal, must read, educational
    </div>

    <div class="knots-list">
      <div
        *ngFor="let knot of knots | sortAndFilter : state"
        class="knots-list-item"
        [class.pinned]="knot.pinned"
        [appKnotContextMenu]="knot"
        (appKnotContextMenuPinned)="handlePin(knot)"
        (appKnotContextMenuDelete)="handleDelete(knot)"
      >
        <mat-icon
          *ngIf="knot.pinned"
          class="knots-list-item-pin-icon"
          svgIcon="pin-small"
        />

        {{ knot.name }}

        <mat-icon
          class="knots-list-item-delete-icon"
          svgIcon="close-thin"
          (click)="handleDelete(knot)"
        />
      </div>
    </div>
  </div>

  <div class="knots-buttons">
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
