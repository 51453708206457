<div class="subtasks">
  <div
    class="subtasks-input"
    [class.active]="subtaskInputFocused"
  >
    <input
      class="subtasks-input-input"
      placeholder="Add New Subtask"
      (keyup.enter)="handleEnterNew()"
      [(ngModel)]="title"
      (focusin)="subtaskInputFocused = true"
      (focusout)="subtaskInputFocused = false"
    >

    <mat-icon class="subtasks-input-icon" svgIcon="subtask" />
  </div>

  <div class="subtasks-label" *ngIf="subtasks.length">Subtasks</div>

  <div
    class="subtasks-input update-item"
    *ngFor="let subtask of subtasks; let index = index"
    [appTaskContextMenu]="subtask"
  >
    <div class="subtasks-input-anchor"></div>

    <mat-icon class="subtasks-input-pin-icon" svgIcon="pin-direct" *ngIf="subtask.pinned" />

    <app-circular-progress
    [progress]="subtask.progress"
    size="16"
    [priority]="subtask.priority"
    [completed]="subtask.completed"
    (onClick)="handleComplete(subtask)"
    />

    <span
      *ngIf="showIssueKey && subtask.issueKeyTitle"
      class="subtasks-input-issue-key"
    >
      {{ subtask.issueKeyTitle }}
    </span>
    <div class="subtasks-input-details">
      <span class="subtasks-input-details-title">{{ subtask.title }}</span>

      <mat-icon class="subtasks-input-icon" svgIcon="subtask" />
    </div>
  </div>
</div>
