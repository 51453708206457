import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-list-load-error',
  templateUrl: './list-load-error.component.html',
  styleUrl: './list-load-error.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListLoadErrorComponent {
  @Input() error?: unknown;
  @Output() public readonly retry = new EventEmitter<void>();
}
