// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Types
import { VirtualFolder } from '@modules/files/types/virtual-folder';

@Component({
  selector: 'app-files-list-context-menu',
  templateUrl: './files-context-menu.component.html',
  styleUrls: ['./files-context-menu.component.less'],
  standalone: false,
})
export class FilesContextMenuComponent {
  // Input
  @Input() filterKey: VirtualFolder;

  // Outputs
  @Output() change = new EventEmitter<VirtualFolder>();

  /**
   * Actions
   */

  selectFilter(filter: VirtualFolder) {
    this.change.emit(filter);
  }
}
