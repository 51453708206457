// Common
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { LocalStorageItem, LSBoolean } from 'src/app/decorators/local-storage.decorator';

// Types
import { Application } from '@modules/common/types/application';
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';
import { Team } from '@modules/settings/types/team';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

@Component({
  template: '',
  standalone: false,
})
export abstract class BaseSidebarContainersItemComponent<
  C extends Stitch,
  I extends Stitch,
  Filters extends StitchFilters<C>,
  VirtualFolder,
  ListState extends BaseStitchListState<C, unknown>,
> implements OnInit
{
  @Input() team: Team;

  @Output() readonly selectFilter = new EventEmitter<VirtualFolder>();

  public abstract application: Application;
  public abstract sidebarSplitViewKey: SidebarSplitViewKey;

  public sidebarFilter: VirtualFolder;
  /**
   * @deprecated
   * */
  public readonly itemsListState = new Subject<ListState>();
  public minimized = true;
  public quickFormPopoverClose = new Subject<void>();
  @LSBoolean({ lsKey: 'sidebar-container.collapsed' }) public expanded: LocalStorageItem<boolean>;
  public currentTeamId: string;

  protected filters: Filters;
  protected alive = new Subject<void>();

  private readonly splitViewService: SplitViewService = inject(SplitViewService);

  constructor(protected stateService: BaseAppStateService<C, I, VirtualFolder>) {}

  ngOnInit(): void {
    this.splitViewService
      .getMinimized(this.sidebarSplitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe((minimized) => {
        this.minimized = minimized;
      });

    this.stateService
      .getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe((sidebar) => {
        this.sidebarFilter = sidebar;
      });

    this.stateService
      .getTeam()
      .pipe(takeUntil(this.alive))
      .subscribe((teamId) => {
        this.currentTeamId = teamId;
      });
  }

  /**
   * @deprecated
   * */
  handleListStateChange(event: ListState) {
    this.itemsListState.next(event);
  }

  public handleMore(stitch: C) {
    this.stateService.setMainView(stitch);
  }
}
