// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-layout',
  templateUrl: './settings-layout.component.html',
  styleUrls: ['./settings-layout.component.less', '../../styles.less'],
  standalone: false,
})
export class SettingsLayoutComponent {}
