// Common
import { Injectable } from '@angular/core';

// Services
import { BaseSettingsService } from '@modules/common/services/base-settings.service';

// Types
import { Settings } from '../types/settings';

// Env
import { environment } from '@environment';

@Injectable()
export class TaskingAppSettingsService extends BaseSettingsService<Settings> {
  protected url = `${environment.baseUrl}/api/tasking/settings`;

  public override createInstance(data?: Partial<Settings>) {
    return new Settings(data);
  }
}
