<div class="block column">
  <div class="heading2">Default Date</div>

  <div class="body-primary">When creating a new task, a default date will be set to:</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.defaultDate"
    [options]="defaultDateOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2 center">
    Issue keys
    <app-toggle-switch
      class="heading2-toggle"
      [inputFormControl]="form.controls.issueKeyEnabled"
    />
  </div>
  <div class="body-primary">
    Issue keys are unique identifiers for every task you track.
    <br>
    They are easily recognizable and quick to remember.
  </div>
</div>

<div class="block column">
  <div class="heading2">Default Reminder</div>

  <div class="body-primary">When creating a new task, a default reminder will be set to:</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.defaultReminder"
    [options]="defaultReminderOptions"
    appearance="amethyst"
  />

  <div class="body-primary all-day">When creating a new “All Day”, a default reminder will be set to:</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.defaultReminderWholeDay"
    [options]="defaultReminderWholeDayOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Default Priority</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.defaultPriority"
    [options]="defaultPriorityOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Default Add to</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.defaultAddTo"
    [options]="defaultAddToOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Overdue Tasks show at</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.overdueTasksShowAt"
    [options]="overdueTasksShowAtOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Completed Task Appearance</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.completedTaskAppearance"
    [options]="completedTaskAppearanceOptions"
    appearance="amethyst"
  />
</div>
