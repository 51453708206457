<ng-container *ngIf="!multiple">
  <app-context-menu-item
    title="Open"
    [close]="true"
    (execute)="handleOpen()"
  />

  <app-context-menu-item
    [title]="calendar?.pinned ? 'Unpin' : 'Pin'"
    [close]="true"
    (execute)="pin()"
  />

  <app-context-menu-item
    [title]="calendar?.flagged ? 'Unflag' : 'Flag'"
    [close]="true"
    (execute)="flag()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="[calendar]"
      (onSelect)="postpone($event, 'snooze')"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="[calendar]"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    />
  </app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable" />

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Open in Modal"
    [close]="true"
    (execute)="openInModal()"
  />

  <app-context-menu-item
    title="Open in Dynamic Panel"
    [close]="true"
    (execute)="openInDP()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Copy To"
  >
    <app-move-to
      appearance="copy"
      [items]="[calendar]"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="[calendar]"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Duplicate"
    (execute)="duplicate()"
    [close]="true"
  />

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="[calendar]"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-divider />

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="[calendar]"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="[calendar]"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Print Calendar"
  />

  <app-context-menu-item
    [title]="calendar?.archived ? 'Restore From Archive' : 'Archive'"
    [close]="true"
    (execute)="archive()"
  />

  <app-context-menu-item
    [title]="calendar?.deleted ? 'Restore From Trash' : 'Trash'"
    [close]="true"
    (execute)="trash()"
  />

  <app-context-menu-item
    *ngIf="calendar?.deleted"
    title="Delete Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  />

  <app-context-menu-item
    title="Settings"
    [close]="true"
    (execute)="openSettings()"
  />
</ng-container>

<ng-container *ngIf="multiple">
  <app-context-menu-item
    [title]="calendar?.pinned ? 'Unpin Selected' : 'Pin Selected'"
    [close]="true"
    (execute)="pin()"
  />

  <app-context-menu-item
    [title]="calendar?.flagged ? 'Unflag' : 'Flag'"
    [close]="true"
    (execute)="flag()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      (onSelect)="postpone($event, 'snooze')"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    />
  </app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable" />

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Duplicate"
    (execute)="duplicate()"
    [close]="true"
  />

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="selectedItems"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch All Together"
    [close]="true"
    (execute)="stitchAllTogether()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="selectedItems"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="selectedItems"
      (close)="close()"
    />
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  />

  <app-context-menu-divider />

  <app-context-menu-item
    [title]="calendar?.archived ? 'Restore From Archive Selected' : 'Archive Selected'"
    [close]="true"
    (execute)="archive()"
  />

  <app-context-menu-item
    [title]="calendar?.deleted ? 'Restore Selected From Trash' : 'Trash Selected'"
    [close]="true"
    (execute)="trash()"
  />

  <app-context-menu-item
    *ngIf="calendar?.deleted"
    title="Delete Selected Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  />
</ng-container>
