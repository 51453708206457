// Common
import { Component, inject, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// Types
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { MessageFolderListStateService } from '@modules/messages/services/message-folder-list-state-holder.service';
import { MessageListStateService } from '@modules/messages/services/message-list-state-holder.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messages2-panel',
  templateUrl: './messages-panel.component.html',
  styleUrls: ['./messages-panel.component.less'],
  standalone: false,
  providers: [
    MessageListStateService.providers({
      stateKey: StateKey.messagesDPListState,
    }),
    MessageFolderListStateService.providers({
      stateKey: StateKey.messagesFoldersDPListState,
    }),
  ],
})
export class MessagesPanelComponent implements OnInit, OnDestroy {
  @Input() searchTemplate: TemplateRef<any>;

  public foldersFilters: FoldersFilters;
  public messagesFilters: MessagesFilters;
  public contextMenuOpened: boolean;
  public selectedFilter: VirtualFolder;

  private readonly alive = new Subject<void>();

  private readonly messageListStateService = inject(MessageListStateService, { self: true });
  private readonly messageFolderListStateService = inject(MessageFolderListStateService, { self: true });

  constructor(
    private dpService: DynamicPanelService,
    private modalService: ModalService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: MailAppStateService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.messageFolderListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.foldersFilters = new FoldersFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.messageListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.messagesFilters = new MessagesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewMessage(message = new Message()) {
    this.dpService.setFormItem(message);
  }

  handleNewFolder(folder = new MessageFolder()) {
    this.dpService.setFormItem(folder);
  }

  openMessageInModal(message: Message) {
    this.modalService.openFullForm(message, this.injector);
  }

  openMessage(message: Message) {
    this.dpService.setFormItem(message);
  }

  openFolder(folder: MessageFolder) {
    this.dpService.setFormItem(folder);
  }

  selectFilter(folder: VirtualFolder): void {
    this.stateService.setVirtualFolder(folder);
  }
}
