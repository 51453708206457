// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Services
import { ConnectionsService } from '@modules/connections/services/connections.service';

// Types
import { Connection } from '@modules/connections/types/connection';

@Component({
  selector: 'app-connection-context-menu',
  templateUrl: './connection-context-menu.component.html',
  standalone: false,
})
export class ConnectionContextMenuComponent {
  @Input() connection: Connection;
  @Input() withDetach = false;

  @Output() delete = new EventEmitter();

  constructor(private connectionsService: ConnectionsService) {}

  /**
   * Actions
   */

  detachConnection() {
    this.delete.emit();
  }

  deleteConnection() {
    if (this.connection.id) {
      this.connectionsService.deletePermanently({ ids: [this.connection.id] });
    } else {
      this.delete.emit();
    }
  }
}
