// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Types
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { Charge } from '../types/charge';
import { ChargesFilters } from '../types/charges-filters';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class ChargesService extends BaseRestService<Charge, ChargesFilters> {
  private url = environment.baseUrl + '/api/admin/charges/';

  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(filters?: Partial<ChargesFilters>): Observable<BaseSearchResponse<Charge>> {
    const requestParams = { params: new ChargesFilters(filters || {}).format() };

    return this.http.get<{ count: number; items: Charge[] }>(this.url, requestParams).pipe(
      map(({ count, items }) => ({
        count,
        items: items.map((item) => new Charge(item)),
      })),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  create(): Observable<Charge> {
    throw new Error('Method forbidden');
  }

  update(): Observable<Charge> {
    throw new Error('Method forbidden');
  }
}
