// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ContactsListContextMenuComponent } from '../components/contacts-list-context-menu/contacts-list-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { StateKey } from '@modules/settings/types/state-key';
import { ContactsListState } from '../types/contacts-list-state';

// RX
import { startWith, takeUntil } from 'rxjs/operators';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appContactsListContextMenu]',
  standalone: false,
})
export class ContactsListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {
  // Inputs
  @Input() appContactsListContextMenuStateKey: StateKey;
  @Input() appContactsListContextMenuWithAdvancedSearch: boolean;
  @Input() appContactsListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  // Outputs
  @Output() appContactsListContextMenuChange = new EventEmitter<ContactsListState>();

  // Private
  private state: ContactsListState;

  constructor(
    private stateService: StateService,
    private searchService: AdvancedSearchService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<ContactsListContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.withAdvancedSearch = this.appContactsListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appContactsListContextMenuWithScore;
  }

  registerComponent() {
    return ContactsListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.state = new ContactsListState({
      searchService: this.searchService,
      stateService: this.stateService,
      stateKey: this.appContactsListContextMenuStateKey,
      withAdvanced: this.appContactsListContextMenuWithAdvancedSearch,
    });

    this.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.appContactsListContextMenuChange.emit(this.state);
        this.isDefaultStateObservable?.next(this.state.isDefault());
      });

    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.state.isDefault()) {
        this.state.resetToDefault();
      }
    });
  }

  ngOnDestroy() {
    this.state.detach();
    super.ngOnDestroy();
  }
}
