// Common
import { Injectable } from '@angular/core';

// Types
import { CalendarEvent } from '../types/calendar-event';
import { Calendar } from '../types/calendar';
import { Like } from '@modules/common/types/like';

// Services
import { EventsService } from './events.service';
import { SectionsService } from './sections.service';
import { SignalsService } from '@modules/common/services/signals-service/signals.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Injectable()
export class SectionsListService extends BaseSectionsListService<CalendarEvent, Calendar> {
  constructor(sectionsService: SectionsService, itemsService: EventsService, signalsService: SignalsService) {
    super(sectionsService, itemsService, signalsService);
  }

  protected createInstance(data: Like<CalendarEvent>): CalendarEvent {
    return new CalendarEvent(data);
  }
}
