// Common
import { Injectable } from '@angular/core';
import { LocalStorageItem, LSBoolean, LSNumber } from 'src/app/decorators/local-storage.decorator';

// Rx
import { Subject, Observable } from 'rxjs';
import { map, filter, throttleTime } from 'rxjs/operators';

const defaultSizes = {
  dp: 300,
  dpModal: 250,
  eventsList: 340,
  mailsList: 340,
  tasksList: 340,
  notesList: 340,
  contactsList: 340,
  filesList: 340,
  eventsSidebar: 150,
  mailsSidebar: 150,
  tasksSidebar: 150,
  notesSidebar: 150,
  contactsSidebar: 150,
  filesSidebar: 150,
};

@Injectable()
export class SplitViewService {
  @LSBoolean({ lsKey: 'split.collapsed' }) collapsed: LocalStorageItem<boolean>;
  @LSNumber({
    lsKey: 'split',
    parse: ({ raw, variant }) => {
      let value: number;
      if (typeof raw === 'string') {
        value = parseInt(raw);
      } else if (typeof raw === 'number') {
        value = raw;
      }
      return isNaN(value) ? defaultSizes[variant] || 150 : value;
    },
  })
  size: LocalStorageItem<number>;

  // Private
  private toggleClick: Subject<string> = new Subject<string>();
  private sizeChanges: Subject<void> = new Subject<void>();

  /**
   * Interface
   */

  toggleClicked(key: string): void {
    this.toggleClick.next(key);
  }

  getToggleIconClick(key: string): Observable<void> {
    return this.toggleClick.asObservable().pipe(
      filter((toggleKey) => toggleKey === key),
      map(() => null),
    );
  }

  setMinimized(key: string, value: boolean): void {
    this.collapsed.set(value, key);
  }

  getMinimized(key: string): Observable<boolean> {
    return this.collapsed.get(key);
  }

  emitSizeChanges(): void {
    this.sizeChanges.next();
  }

  getSizeChanges(): Observable<void> {
    return this.sizeChanges.asObservable().pipe(throttleTime(400));
  }
}
