// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { KnotsModule } from '@modules/knots/knots.module';
import { MatIconModule } from '@angular/material/icon';
import { PopoverModule } from '@modules/popover/popover.module';
import { TagsModule } from '@modules/tags/tags.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AdvancedFormsComponent } from './components/advanced-forms/advanced-forms.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchPopoverComponent } from './components/search-popover/search-popover.component';

@NgModule({
  imports: [
    AppCommonModule,
    BrowserAnimationsModule,
    CommonModule,
    ConnectionsModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    MatIconModule,
    PopoverModule,
    ReactiveFormsModule,
    TagsModule,
    UtilsModule,
  ],
  declarations: [AdvancedFormsComponent, SearchInputComponent, SearchPopoverComponent],
  exports: [SearchInputComponent, SearchPopoverComponent],
  providers: [],
})
export class SearchModule {}
