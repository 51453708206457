import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ListStateService } from '@modules/common/components/list/list-state.service';
import { SortBy } from '@modules/common/types/SortBy';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import { StateKey } from '@modules/settings/types/state-key';
import { CalendarEvent } from '../types/calendar-event';
import { EventsListSortField, EventsListState } from '../types/events-list-state';

export interface EventsListStateServiceConfig {
  stateKey?: StateKey;
  withAdvancedSearch?: boolean;
  defaultSort?: SortBy<EventsListSortField>;
}

export const EventsListStateServiceConfigToken = new InjectionToken<EventsListStateServiceConfig>(
  'Configuration object for calendar events list state service',
);

@Injectable()
export class EventsListStateService extends ListStateService<CalendarEvent, EventsListState> {
  public override readonly state: EventsListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(EventsListStateServiceConfigToken) config: EventsListStateServiceConfig,
  ) {
    super();

    this.state = new EventsListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch,
      defaultSort: config.defaultSort,
    });
  }

  static providers(config: EventsListStateServiceConfig = {}): any[] {
    return super.prepareProviders(EventsListStateServiceConfigToken, config);
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.eventsListState]?: EventsListState;
  }
}
