import { environment } from '@environment';
import { Account } from '@modules/account/types/account';

export class SpaceParticipant {
  avatar: string;
  firstName: string;
  fullName: string;
  id: string;
  joinedAt: Date;
  lastName: string;
  owner: boolean;
  roleId: string;
  spaceId: string;
  status: 'pending' | 'accepted' | 'rejected';
  userName: string;

  constructor(data: Account | Partial<SpaceParticipant> = {}) {
    this.id = data?.id;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;
    this.fullName = [data?.firstName, data?.lastName].join(' ');

    if (data instanceof Account) {
      this.avatar = data.avatar;
      this.userName = data.name;
    } else {
      this.avatar =
        data?.avatar &&
        `${environment.baseUrl}/api/attachments/avatar/avatar/${data?.avatar}?t=${new Date().getTime()}&account-id=${data?.id}`;
      this.joinedAt = data.joinedAt && new Date(data.joinedAt);
      this.owner = data?.owner;
      this.roleId = data?.roleId;
      this.spaceId = data?.spaceId;
      this.status = data?.status;
      this.userName = data?.userName;
    }
  }

  asPayloadJSON() {
    return {
      roleId: this.roleId,
    };
  }
}
