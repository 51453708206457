// Common
import { Component, inject, Injector, Input, OnInit } from '@angular/core';

// RX
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Tab } from '@modules/common/types/tab';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Notebook } from '@modules/notes/types/notebook';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { NotesListState } from '@modules/notes/types/notes-list-state';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';
import { NotesListStateService } from '@modules/notes/services/notes-list-state.service';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.notesFFListState]?: NotesListState;
  }
}

@Component({
  selector: 'app-notebook-form',
  templateUrl: './notebook-form.component.html',
  styleUrls: ['./notebook-form.component.less'],
  standalone: false,
  providers: [
    NotesListStateService.providers({
      stateKey: StateKey.notesFFListState,
    }),
  ],
})
export class NotebookFormComponent extends FullFormBaseComponent<Notebook> implements OnInit {
  public changesKey = 'notebook';
  tabs: Tab[] = [
    { title: 'Notebook', value: 'notebook' },
    { title: 'Details', value: 'details' },
    { title: 'Attachments', value: 'attachments' },
    { title: 'Stitch', value: 'stitch' },
    { title: 'Lab', value: 'lab' },
    { title: 'Sections', value: 'sections' },
    { title: 'Comments', value: 'comments' },
  ];
  tabsStateKey = 'ffNotebook';
  public parentSuggestions: AutocompleteFactory<Notebook>;
  public notesFilters: NotesFilters;

  protected dragDataType = DragDataTypes.notebook;

  @Input() notebook = new Notebook();

  private readonly notesListStateService = inject(NotesListStateService, { self: true });

  constructor(
    private notebooksService: NotebooksService,
    notesAppStateService: NotesAppStateService,
    injector: Injector,
  ) {
    super(injector, notebooksService, notesAppStateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stitchItemChanged.pipe(takeUntil(this.alive)).subscribe(() => {
      this.parentSuggestions = this.notebooksService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
    });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.notesListStateService.value()),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.notesFilters = new NotesFilters({ containersIds: [this.stitchItem.id] });
        this.notesFilters.applyListState(this.notesListStateService.state);
      });
  }

  /**
   * Helpers
   */

  get stitchItem(): Notebook {
    return this.notebook;
  }

  protected shouldRefreshList(prev, current) {
    return Notebook.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Notebook {
    return Notebook.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.notebook.asFormGroup(this.notebooksService);
  }
}
