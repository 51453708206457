<app-context-menu-heading>Sorting options:</app-context-menu-heading>

<app-context-menu-item
  *ngIf="(withAdvancedSearch && advancedSearchApplied) || withScore"
  [withCheckOption]="true"
  title="Relevance"
  [active]="state.sortBy === 'score'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('score')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Title"
  [active]="state.sortBy === 'title'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('title')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Created Date"
  [active]="state.sortBy === 'date'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('date')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Updated Date"
  [active]="state.sortBy === 'due-date'"
  [sortable]="true"
  [reversed]="state.sortOrder === 'desc'"
  (execute)="sort('due-date')"
/>

<app-context-menu-divider />

<app-context-menu-item
  [withCheckOption]="true"
  title="Pinned on Top"
  [active]="state.pinnedOnTop"
  (execute)="pinnedOnTop()"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Flagged on Top"
  [active]="state.flaggedOnTop"
  (execute)="flaggedOnTop()"
/>

<app-context-menu-heading>Filters:</app-context-menu-heading>

<app-stitch-type-context-menu-item
  [withCheckOption]="true"
  title="Stitch"
  [state]="state.stitchedWith"
  (onSelect)="stitch($event)"
/>

<app-context-menu-divider />

<app-context-menu-item
  [withCheckOption]="true"
  title="Flagged"
  [active]="state.flagged"
  (execute)="flag()"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Pinned"
  [active]="state.pinned"
  (execute)="pin()"
/>
 
<app-context-menu-item
  [withCheckOption]="true"
  title="Today"
  [active]="state.today"
  (execute)="today()"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [active]="state.from || state.to"
  title="Selected Date"
>
  <div class="date">
    <div class="date-buttons">
      <div
        class="date-buttons-item"
        [class.selected]="dateButtonSelected === 'from'"
        [class.active]="innerFromDate?.value"
        (click)="dateButtonSelected = 'from'"
      >
        {{ innerFromDate?.value ? (innerFromDate?.value | date : 'MMM d') : 'Start Date'}}
      </div>
      <div
        class="date-buttons-item"
        [class.selected]="dateButtonSelected === 'to'"
        [class.active]="innerToDate?.value"
        (click)="dateButtonSelected = 'to'"
      >
        {{ innerToDate?.value ? (innerToDate?.value | date : 'MMM d') : 'Due Date'}}
      </div>
    </div>
  
    <app-date-picker-2
      [inputFormControl]="dateButtonSelected === 'from' ? innerFromDate : innerToDate"
      [collapseable]="false"
      [maxDate]="dateButtonSelected === 'from' && innerToDate?.value"
      [minDate]="dateButtonSelected === 'to' && innerFromDate?.value"
      [highlightRange]="true"
    />
  </div>
</app-context-menu-item>
