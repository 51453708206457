import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'differentDay',
  standalone: false,
})
export class DifferentDayPipe implements PipeTransform {
  transform(firstTimestamp: number, secondTimestamp: number): boolean {
    const firstDate = firstTimestamp && new Date(firstTimestamp);
    const secondDate = secondTimestamp && new Date(secondTimestamp);

    return (
      !!secondDate &&
      !!firstDate &&
      !(
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
      )
    );
  }
}
