// Common
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';

// RX
import { BehaviorSubject, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Directives
import { SelectableItemDirective } from './selectable.directive';

// Types
import { SelectableItem } from '../types/selectable-item';

// Services
import { SelectableService } from '../services/selectable.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

@Directive({
  standalone: true,
  selector: '[appSelectableList]',
  providers: [
    {
      provide: SelectableService,
      useFactory: (selectableService?: SelectableService) => {
        if (!selectableService) {
          selectableService = new SelectableService();
        }
        return selectableService;
      },
      deps: [[new Optional(), new SkipSelf(), SelectableService]],
    },
  ],
})
export class SelectableListDirective implements AfterViewInit, OnDestroy {
  // Private
  private alive = new Subject<void>();
  private focused = false;
  private lastSelectedPosition: number;
  private reset = new Subject<void>();

  // Inputs
  @Input() appSelectableListReset: Subject<void>;

  // View Children
  // @ContentChildren(SelectableItemDirective) selectableItems: QueryList<SelectableItemDirective>;
  // https://github.com/angular/angular/issues/14842

  /**
   * Constructor
   */

  constructor(
    private element: ElementRef,
    private viewport: ElementRef,
    @Self() private selectableService: SelectableService,
    @Optional() @Inject(ScrollToPosition) protected scrollToPositionSubject: BehaviorSubject<number>,
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.reset
      .pipe(
        filter(() => !!this.appSelectableListReset),
        switchMap(() => this.appSelectableListReset),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.selectableService.setSelectedItems([]);
      });

    this.selectableService
      .getUpdate()
      .pipe(
        debounceTime(200), // dirtly hack - timeout is here to give Angular time to render app-selectable-item items.
        map(() =>
          Array.from(this.element.nativeElement.querySelectorAll('[app-selectable-item]')).map(
            (element) => element['component'] as SelectableItemDirective,
          ),
        ),
        tap((directives) => {
          this.selectableService.items = directives.map((selectable) => selectable.data);
          this.selectableService.fillPositions();
        }),
        switchMap((directives) => merge(...directives.map((selectable) => selectable.select))),
        takeUntil(this.alive),
      )
      .subscribe(([position, event]) => {
        this.selectItem(position, event);
      });

    fromEvent(window.document, 'click')
      .pipe(takeUntil(this.alive))
      .subscribe((event: MouseEvent) => {
        this.focused =
          this.viewport.nativeElement.contains(event.target as Node) ||
          event['path']?.includes(this.viewport.nativeElement);
      });

    fromEvent(window.document, 'keydown')
      .pipe(
        filter(
          (event: KeyboardEvent) =>
            this.focused && !(event.target instanceof Element && event.target.tagName.toLowerCase() === 'input'),
        ),
        tap((event: KeyboardEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }),
        takeUntil(this.alive),
      )
      .subscribe((event: KeyboardEvent) => this.handleKeydown(event));

    this.selectableService
      .getSelectAll()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        const positions = this.selectableService.items.map((item) => item.position);
        this.lastSelectedPosition = Math.min(...positions);
        this.selectableService.setSelectedItems(this.selectableService.items);
      });

    this.reset.next();
  }

  ngOnChange(changes: SimpleChanges) {
    if ('appSelectableListReset' in changes) {
      this.reset.next();
      this.lastSelectedPosition = null;
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectItem(position: number, event: MouseEvent | KeyboardEvent) {
    const multi = event?.ctrlKey || event?.shiftKey || event?.metaKey;
    const range = event?.shiftKey;
    const item = this.selectableService.items.find((i) => i.position === position);

    if (!item) {
      return;
    }

    const selectedItems = this.selectableService.getSelectedItemsSync();
    let newItems = [...selectedItems];

    if (selectedItems.length === 1 && this.compareItems(selectedItems[0], item)) {
      newItems = [];
    } else if (!multi || selectedItems.length === 0) {
      newItems = [item];
    } else if (range) {
      const start = Math.min(this.lastSelectedPosition, position);
      const end = Math.max(this.lastSelectedPosition, position);

      for (let i = start; i <= end; i++) {
        const itemToAdd = this.selectableService.items.find((existingItem) => existingItem.position === i);

        if (!selectedItems.some((selectedItem) => selectedItem.position === i) && itemToAdd) {
          newItems.push(itemToAdd);
        }
      }
    } else {
      const index = selectedItems.findIndex(
        ({ position: selectedItemPosition }) => item.position === selectedItemPosition,
      );
      if (index === -1) {
        newItems.push(item);
      } else {
        newItems.splice(index, 1);
      }
    }

    this.selectableService.setSelectedItems(newItems);
    this.lastSelectedPosition = position;
  }

  private handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.selectableService.setSelectedItems([]);
      return;
    }

    const selectAll = event.code === 'KeyA' && (event.ctrlKey || event.metaKey);

    if (!this.selectableService.items?.length || (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && !selectAll)) {
      return;
    }

    const selectedItems = this.selectableService.getSelectedItemsSync();
    const positions = this.selectableService.items.map((item) => item.position);
    const selectedPositions = selectedItems.map((item) => item.position);

    let nextPosition = 0;

    if (event.key === 'ArrowUp' && selectedItems.length !== 0) {
      this.lastSelectedPosition = Math.min(...selectedPositions);
      const upPositions = positions.filter((position) => position < this.lastSelectedPosition);

      if (upPositions.length) {
        nextPosition = Math.max(...upPositions);
        this.scrollToPositionSubject?.next(nextPosition);
        this.selectItem(nextPosition, event);
      } else {
        return;
      }
    } else if (event.key === 'ArrowDown' && selectedItems.length !== 0) {
      this.lastSelectedPosition = Math.max(...selectedPositions);
      const downPositions = positions.filter((position) => position > this.lastSelectedPosition);

      if (downPositions.length) {
        nextPosition = Math.min(...downPositions);
        this.scrollToPositionSubject?.next(nextPosition);
        this.selectItem(nextPosition, event);
      } else {
        return;
      }
    } else if (selectAll) {
      this.lastSelectedPosition = Math.min(...positions);
      this.selectableService.setSelectedItems(this.selectableService.items);
    }
  }

  compareItems(item1: SelectableItem, item2: SelectableItem): boolean {
    return item1 && item2 && item1.position === item2.position;
  }
}
