<div class="connections py-2 px-4">
  <span
    *ngIf="!withPlaceholder || connections.length > 0"
    class="text-sm font-medium text-gray-950"
  >
    Connections:
  </span>

  <div
    *ngIf="withPlaceholder && connections.length === 0"
    class="connections-placeholder"
  >
    No connections were extracted
  </div>

  <mat-icon
    *ngIf="withControls"
    class="connections-icon"
    svgIcon="manage"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="manageForm"
    stchPopoverTrigger="click"
    stchPopoverPlacement="right"
    [stchPopoverShowUntil]="hidePopover"
  />

  <mat-icon
    *ngIf="withControls"
    class="connections-icon"
    svgIcon="plus-manage"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="addForm"
    stchPopoverTrigger="click"
    stchPopoverPlacement="right"
    [stchPopoverShowUntil]="hidePopover"
  />

  <app-context-menu-state-indicator
    class="connections-icon no-width"
    svgIcon="filters"
    appConnectionsManageContextMenu
    [appConnectionsManageContextMenuStateKey]="stateKey"
    (appConnectionsManageContextMenuStateChange)="setState($event)"
  />

  <app-connection
    *ngFor="let connection of connections | slice : 0 : (page + 1) * perPage"
    class="align-middle inline-block mr-2"
    [connection]="connection"
    [withDetach]="true"
    [withControls]="withControls"
    [selected]="selectedConnections | includesBy : connection?.name : 'name'"
    (delete)="handleDelete(connection)"
    (click)="handleClick(connection, $event)"
  />

  <div
    *ngIf="page !== 0"
    class="connections-item connections-more"
    (click)="showLess()"
  >
    Less
  </div>

  <div
    *ngIf="page < pagesCount - 1"
    class="connections-item connections-more"
    (click)="showMore()"
  >
    More
  </div>
</div>

<ng-template #manageForm>
  <app-manage-connections-form
    [existingConnections]="connections"
    (close)="closePopovers()"
    (save)="saveConnections($event)"
  />
</ng-template>

<ng-template #addForm>
  <app-add-connections
    [stitchItems]="stitchItems"
    [control]="control"
    (close)="closePopovers()"
    (save)="saveConnections($event)"
  />
</ng-template>
