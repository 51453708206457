import { ComponentRef, Directive, inject, Input, OnInit } from '@angular/core';
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { startWith, takeUntil } from 'rxjs/operators';
import { MessagesListContextMenuComponent } from '../components/messages-list-context-menu/messages-list-context-menu.component';
import { MessageListStateService } from '../services/message-list-state-holder.service';

@Directive({
  selector: '[appMessagesListContextMenu]',
  standalone: false,
})
export class MessagesListContextMenuDirective extends ContextMenuDirective implements OnInit {
  @Input()
  public appMessagesListContextMenuWithAdvancedSearch: boolean;
  @Input()
  public appMessagesListContextMenuWithScore: boolean;
  @Input()
  public stchPopoverTrigger: PopoverTrigger = 'click';
  @Input()
  public stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  private readonly messageListStateService = inject(MessageListStateService);

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<MessagesListContextMenuComponent>) {
    componentRef.instance.state = this.messageListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appMessagesListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appMessagesListContextMenuWithScore;
  }

  registerComponent() {
    return MessagesListContextMenuComponent;
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.messageListStateService.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.isDefaultStateObservable?.next(this.messageListStateService.state.isDefault());
      });

    this.isDefaultStateObservable?.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.messageListStateService.state.isDefault()) {
        this.messageListStateService.state.resetToDefault();
      }
    });
  }
}
