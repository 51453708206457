import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ContactsModule } from '@modules/contacts/contacts.module';
import { CalendarAppModule } from '@modules/calendar-app/calendar-app.module';
import { FilesModule } from '@modules/files/files.module';
import { MessagesModule } from '@modules/messages/messages.module';
import { ModalModule } from '@modules/modal/modal.module';
import { NotesModule } from '@modules/notes/notes.module';
import { PopupRoutingModule } from './popup-routing.module';
import { TasksModule } from '@modules/tasks/tasks.module';

// Services
import { PopupService } from './services/popup.service';
import { PopupGuardService } from './services/popup-guard.service';

// Components
import { PopupWrapperComponent } from './components/popup-wrapper/popup-wrapper.component';
import { MailEditorPopupComponent } from './components/mail-editor-popup/mail-editor-popup.component';
import { FullFormPopupComponent } from './components/full-form-popup/full-form-popup.component';

@NgModule({
  imports: [
    CalendarAppModule,
    CommonModule,
    ContactsModule,
    FilesModule,
    MessagesModule,
    ModalModule,
    NotesModule,
    PopupRoutingModule,
    TasksModule,
  ],
  declarations: [FullFormPopupComponent, MailEditorPopupComponent, PopupWrapperComponent],
  providers: [PopupService, PopupGuardService],
})
export class PopupModule {}
