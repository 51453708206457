<div class="block column">
  <div class="heading2">Open on Start</div>

  <div class="body-primary">Choose what to show when Stitch starts or when you switch apps</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.openOnStart"
    [options]="openOnStartOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Open Slite links</div>

  <div class="body-primary">Where do you  prefer to open your Slite links</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.openSliteLinks"
    [options]="openSliteLinksOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">
    Readable line length

    <app-toggle-switch
      class="toggle"
      [inputFormControl]="form.controls.readableLineLength"
    />
  </div>

  <div class="body-primary readable-line-length">Limit maximum line length. Less content fits onscreen, but long blocks of text are more readable</div>
</div>

<div class="block column">
  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.addItemsToBottom"
    title="Add new items to the bottom"
    appearance="new"
  />

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.showLinksPreview"
    title="Display rich link previews"
    appearance="new"
  />
</div>
