// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { VirtualFolder } from '../types/virtual-folder';

@Pipe({
  name: 'titleByContactsFilter',
  standalone: false,
})
export class TitleByContactsFilterPipe implements PipeTransform {
  transform(value: VirtualFolder): any {
    if (!value) {
      return 'All';
    }

    switch (value) {
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'all_contacts':
        return 'Contacts';
      case 'vip':
        return 'VIP';
      case 'flagged':
        return 'Flagged';
      case 'upcoming':
        return 'Upcoming';
      case 'frequent':
        return 'Frequent';
      case 'recent':
        return 'Recent';
      case 'followed':
        return 'Follow up';
      case 'all_groups':
        return 'Groups';
      case 'snoozed':
        return 'Snoozed';
      default:
        return checkExhaustiveness(value);
    }
  }
}
