// Common
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-settings-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less'],
  standalone: false,
})
export class ContactsComponent {
  // Public
  public form = new FormGroup({
    sortOrder: new FormControl<string>('lastName'),
    sortDisplay: new FormControl<string>('firstLast'),
    addressLayout: new FormControl<string>('us'),
    isFormatPhoneNumbers: new FormControl<boolean>(true),
    defaultAreaCode: new FormControl<string>('323'),
  });
  public sortOrderOptions = [
    { title: 'First Name', value: 'firstName' },
    { title: 'Last Name', value: 'lastName' },
  ];
  public sortDisplayOptions = [
    { title: 'First Name, Last Name', value: 'firstLast' },
    { title: 'Last Name, First Name', value: 'lastFirst' },
  ];
  public addressLayoutOptions = [{ title: 'United States', value: 'us' }];
}
