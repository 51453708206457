// Common
import { Component, Input, ElementRef, ChangeDetectorRef } from '@angular/core';

// Services
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';

// Types
import { Message } from '@modules/messages/types/message';
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { LinkedInfoData } from '@modules/linked-info/types/linked-info-data';
import { type MessageActionType } from '@modules/messages/types/message-action-type';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RxJS
import { takeUntil } from 'rxjs/operators';
import { Stitch } from '@modules/common/types/stitch';

@Component({
  selector: 'app-message-compose-modal',
  templateUrl: './message-compose.component.html',
  styleUrls: ['./message-compose.component.less'],
  providers: [DynamicPanelService, KnowledgePanelService],
  standalone: false,
})
export class MessageComposeComponent extends BaseModalComponent {
  // Common properties for all modals
  @Input() availableExpand = true;
  @Input() action: MessageActionType;
  @Input() message: Message;
  @Input() reply: Message;
  @Input() forward: Message[];

  // Override
  public defaultSize: ModalFrame = {
    x: 'calc(100% - 900px)',
    y: 'calc(100% - 500px)',
    width: '500px',
    height: '500px',
  };
  public linkedInfo: LinkedInfoData[];

  // Public
  public stitchListMinimized = false;

  /**
   * Constructor
   */

  constructor(
    protected ga: GoogleAnalyticsService,
    protected element: ElementRef,
    protected changeDetector: ChangeDetectorRef,
    private splitService: SplitViewService,
  ) {
    super();
    this.splitService
      .getMinimized('modalStitch')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized) => (this.stitchListMinimized = minimized));
  }

  /**
   * Actions
   */

  expand() {
    this.ga.trackEvent('mail-editor-modal', 'expand');
    // this.popupService.compose(this.message, this.maximizedSize.popupSize());
    this.close();
  }

  handleClickItem(_stitch: Stitch) {
    // TODO action on click by stitch item
  }
  handleDblClickItem(_stitch: Stitch) {
    // TODO action on double click by stitch item
  }
}
