// Common
import { addDays, beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Utils
import { isNil } from '@modules/common/utils/base';

// Types
import { EventsListSortField, EventsListState } from './events-list-state';
import { StitchChildFilters } from '@modules/common/types/stitch-child-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Participant } from '@modules/messages/types/participant';
import { VirtualFolder } from './virtual-folder';
import { Application } from '@modules/common/types/application';
import { CalendarEvent } from './calendar-event';

export class EventsFilters extends StitchChildFilters<CalendarEvent> {
  // Filters
  fromTime?: Date;
  toTime?: Date;
  startDate?: Date;
  dueDate?: Date;

  // Advanced filters
  location?: number;
  attendees?: Pick<Participant, 'name' | 'address'>[];

  // Sort Options
  sortBy?: EventsListSortField;

  constructor(filters?: Like<EventsFilters>) {
    super(filters);
    this.attendees = filters?.attendees;
    this.dueDate = filters?.dueDate;
    this.fromTime = filters?.fromTime;
    this.location = filters?.location;
    this.sortBy = filters?.sortBy;
    this.startDate = filters?.startDate;
    this.toTime = filters?.toTime;
  }

  applyListState(state: EventsListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    if (state?.next7days) {
      const today = new Date();
      this.fromTime = beginningOfDay(addDays(today, 1));
      this.toTime = endOfDay(addDays(today, 7));
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = super.format();

    if (this.containersIds) {
      result['calendars_ids[]'] = this.containersIds;
    }
    if (!isNil(this.noContainer)) {
      result['no_calendar'] = this.noContainer + '';
    }

    if (this.fromTime) {
      result['from_time'] = this.fromTime.toISOString();
    }
    if (this.toTime) {
      result['to_time'] = this.toTime.toISOString();
    }
    if (this.startDate) {
      result['start_date'] = beginningOfDay(this.startDate).toISOString();
    }
    if (this.dueDate) {
      result['due_date'] = endOfDay(this.dueDate).toISOString();
    }
    if (!isNil(this.location)) {
      result['location'] = this.location;
    }
    if (this.attendees && this.attendees.length) {
      result['attendees[]'] = this.attendees.map(({ address }) => address);
    }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced, Application.calendar);

    const state = advanced.calendar;

    this.esTitle = state.subject;
    this.esBody = state.body;
    this.fromTime = state.dateRangeFrom;
    this.toTime = state.dateRangeTo;
    this.startDate = state.fromDate;
    this.dueDate = state.toDate;
    this.location = state.location;
    this.attendees = state.attendees;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) {
      return this;
    }

    this.deleted = false;
    this.archived = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;
    this.noContainer = null;

    switch (folder) {
      case 'archived':
        this.archived = true;
        break;
      case 'deleted':
        this.deleted = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      case 'all_events':
        this.noContainer = true;
        break;
      case 'all_calendars':
        break;
      case 'flagged':
        this.flagged = true;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
