import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Settings {
  formBuilder = injector.get(FormBuilder);

  blackListEnabled?: boolean;
  cesarBulkAnalyzerThreshold?: number;
  companyDomainsListEnabled?: boolean;
  messageFoldersPostponeNotificationsEnabled?: boolean;
  messagesPostponeNotificationsEnabled?: boolean;
  notificationsEnabled?: boolean;
  spamAssassinThresholdJunk?: number;
  spamAssassinThresholdSpam?: number;
  undoSendTimeout?: number;
  vacationResponderEnabled?: boolean;
  vipListEnabled?: boolean;
  whiteListEnabled?: boolean;

  constructor(data: Partial<Settings> = {}) {
    this.blackListEnabled = data.blackListEnabled || false;
    this.cesarBulkAnalyzerThreshold = data.cesarBulkAnalyzerThreshold || 0.6;
    this.companyDomainsListEnabled = data.companyDomainsListEnabled || false;
    this.messageFoldersPostponeNotificationsEnabled = data.messageFoldersPostponeNotificationsEnabled ?? false;
    this.messagesPostponeNotificationsEnabled = data.messagesPostponeNotificationsEnabled ?? false;
    this.notificationsEnabled = data.notificationsEnabled;
    this.spamAssassinThresholdJunk = data.spamAssassinThresholdJunk || 3;
    this.spamAssassinThresholdSpam = data.spamAssassinThresholdSpam || 5;
    this.undoSendTimeout = data.undoSendTimeout || null;
    this.vacationResponderEnabled = data.vacationResponderEnabled || false;
    this.vipListEnabled = data.vipListEnabled || false;
    this.whiteListEnabled = data.whiteListEnabled || false;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      blackListEnabled: formValues.blackListEnabled,
      cesarBulkAnalyzerThreshold: formValues.cesarBulkAnalyzerThreshold,
      companyDomainsListEnabled: formValues.companyDomainsListEnabled,
      messageFoldersPostponeNotificationsEnabled: formValues.messageFoldersPostponeNotificationsEnabled,
      messagesPostponeNotificationsEnabled: formValues.messagesPostponeNotificationsEnabled,
      notificationsEnabled: formValues.notificationsEnabled,
      spamAssassinThresholdJunk: formValues.spamAssassinThresholdJunk,
      spamAssassinThresholdSpam: formValues.spamAssassinThresholdSpam,
      undoSendTimeout: formValues.undoSendTimeout,
      vacationResponderEnabled: formValues.vacationResponderEnabled,
      vipListEnabled: formValues.vipListEnabled,
      whiteListEnabled: formValues.whiteListEnabled,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      blackListEnabled: [this.blackListEnabled],
      cesarBulkAnalyzerThreshold: [this.cesarBulkAnalyzerThreshold],
      companyDomainsListEnabled: [this.companyDomainsListEnabled],
      messageFoldersPostponeNotificationsEnabled: [this.messageFoldersPostponeNotificationsEnabled],
      messagesPostponeNotificationsEnabled: [this.messagesPostponeNotificationsEnabled],
      notificationsEnabled: [this.notificationsEnabled],
      spamAssassinThresholdJunk: [this.spamAssassinThresholdJunk],
      spamAssassinThresholdSpam: [this.spamAssassinThresholdSpam],
      undoSendTimeout: [this.undoSendTimeout],
      vacationResponderEnabled: [this.vacationResponderEnabled],
      vipListEnabled: [this.vipListEnabled],
      whiteListEnabled: [this.whiteListEnabled],
    });
  }

  public asPayloadJSON() {
    return {
      blackListEnabled: this.blackListEnabled,
      cesarBulkAnalyzerThreshold: this.cesarBulkAnalyzerThreshold,
      companyDomainsListEnabled: this.companyDomainsListEnabled,
      foldersPostponeNotificationsEnabled: this.messageFoldersPostponeNotificationsEnabled,
      messagesPostponeNotificationsEnabled: this.messagesPostponeNotificationsEnabled,
      notificationsEnabled: this.notificationsEnabled,
      spamAssassinThresholdJunk: this.spamAssassinThresholdJunk,
      spamAssassinThresholdSpam: this.spamAssassinThresholdSpam,
      undoSendTimeout: this.undoSendTimeout,
      vacationResponderEnabled: this.vacationResponderEnabled,
      vipListEnabled: this.vipListEnabled,
      whiteListEnabled: this.whiteListEnabled,
    };
  }
}
