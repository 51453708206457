<app-input
  class="title-input"
  [inputFormControl]="form.controls.title"
  appearance="amethyst"
  label="Title"
/>

<app-rich-text-editor
  class="editor"
  [control]="form.controls.content"
/>

<div class="actions">
  <app-button
    appearance="purple-outline"
    label="Cancel"
    (click)="handleClose()"
  />

  <app-button
    appearance="purple"
    label="Submit"
    (click)="handleSubmit()"
  />
</div>
