// Common
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Self,
  ViewChild,
} from '@angular/core';

// RxJS
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { debounceTime, filter, map, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

@Component({
  selector: 'app-infinity-scroll-list',
  templateUrl: './infinity-scroll-list.component.html',
  styleUrls: ['./infinity-scroll-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class InfinityScrollListComponent implements OnInit, OnDestroy {
  // ViewChildren
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  // Public
  public loading = false;
  public loadingError = false;
  public items: Stitch[];
  public itemsStream = new BehaviorSubject<Stitch[]>([]);
  public itemsStreamObservable = this.itemsStream.asObservable();
  public focused = false;
  public itemHeight = 88; // Used for programmatic scroll calculations

  // Protected
  protected componentNotDestroyed = new Subject<void>();
  protected currentIndex = new BehaviorSubject<number>(0);

  // Private
  private loadItems = new Subject<{ start: number; end: number; rewrite: boolean }>();
  private initialIndex = 0;

  @Input() selectedItems: Stitch[];
  @Output() loadInProgress = new EventEmitter<boolean>();

  /**
   * Constructor
   */

  constructor(
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) private scrollToPositionSubject,
  ) {}

  /**
   * Component lifecycle
   */

  ngOnInit() {
    /* Item Loading logic */
    this.loadItems
      .pipe(
        debounceTime(400),
        map((range) => {
          if (this.initialIndex) {
            range = { ...range, start: this.initialIndex, end: this.initialIndex + 20 };
          }
          if (!this.items || range.rewrite) {
            return range;
          }
          const updatedRange = { start: undefined, end: undefined, rewrite: range.rewrite };
          for (let i = range.start; i <= range.end && i < this.items.length; i++) {
            if (!this.items[i]) {
              updatedRange.start = updatedRange.start !== undefined ? updatedRange.start : i;
              updatedRange.end = i;
            }
          }
          return updatedRange;
        }),
        filter((range) => range && range.end > 0 && range.start < range.end),
        tap(() => {
          this.loadInProgress.next(true);
          this.loadingError = false;
        }),
        switchMap((range) =>
          this.getItems(range.start, range.end - range.start + 1).pipe(map((response) => ({ range, response }))),
        ),
        takeUntil(this.componentNotDestroyed),
        map(({ range, response }) => {
          this.loadInProgress.next(false);
          if (!this.items) {
            this.items = new Array(response.count || 0);
          } else if (range.rewrite) {
            // Compare if new messages are not the same messages in selected range
            if (
              !response.items.length ||
              this.items.length !== response.count ||
              response.items.some(
                (_item, index) =>
                  !this.items[range.start + index] ||
                  !this.compareItems(response.items[index], this.items[range.start + index]),
              )
            ) {
              this.items = new Array(response.count || 0);
            }
          } else if (this.items.length !== response.count) {
            this.refreshCurrentItems();
          }

          this.items.splice(range.start, response.items.length, ...(response.items as Stitch[]));

          if (this.initialIndex) {
            timer(0).subscribe(() => {
              this.scrollToIndex(this.initialIndex);
              this.initialIndex = 0;
            });
          }
          return this.items;
        }),
        retryWhen((errors) =>
          errors.pipe(
            tap(() => {
              this.loadInProgress.next(false);
              this.loadingError = true;
            }),
          ),
        ),
      )
      .subscribe((items) => this.itemsStream.next(items));

    this.viewport.renderedRangeStream
      .pipe(
        filter((range) => range && range.end > 0 && range.start <= range.end),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe((range) => {
        this.loadItems.next({ start: range.start, end: range.end, rewrite: false });
      });

    // Add subs for event required for keyboard navigation. Defined outside of angular for performance reasons
    this.ngZone.runOutsideAngular(() => {
      this.viewport.scrolledIndexChange
        .pipe(takeUntil(this.componentNotDestroyed))
        .subscribe((index) => this.currentIndex.next(index));
    });

    this.loadInProgress
      .asObservable()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe((value: boolean) => (this.loading = value));

    this.resetItems();

    this.scrollToPositionSubject
      ?.pipe(
        filter((index) => index !== null && index !== undefined),
        takeUntil(this.componentNotDestroyed),
      )
      ?.subscribe((index) => {
        this.scrollToSelected(index);
      });
  }

  ngOnDestroy() {
    this.componentNotDestroyed.next();
    this.componentNotDestroyed.complete();
  }

  /**
   * Actions
   */

  resetItems() {
    this.items = null;
    this.itemsStream.next([]); // Viewport accepts only arrays, so can't send null
    this.loadItems.next({ start: 0, end: 20, rewrite: true });
  }

  refreshCurrentItems() {
    const renderedRange = this.viewport.getRenderedRange();
    if (renderedRange && !renderedRange.start && !renderedRange.end) {
      renderedRange.end = 20;
    }
    this.loadItems.next({ ...renderedRange, rewrite: true });
  }

  scrollToIndex(offset: number, behavior?: ScrollBehavior) {
    if (!this.items) {
      this.initialIndex = offset;
    } else {
      this.viewport.scrollToIndex(offset, behavior);
    }
  }

  // This method have to be overload, to get appropriate items
  getItems(_offset: number, _limit: number): Observable<{ items: object[]; count: number }> {
    return of({ items: [], count: 0 });
  }

  // Optionally overload this method for items comparison. For example if id field named differently
  compareItems(item1: object, item2: object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }

  selectItem(_item: Stitch, _event: MouseEvent | KeyboardEvent, _selectAll = false) {
    // TODO
  }

  private scrollToSelected(selectedIndex: number) {
    if (selectedIndex <= this.currentIndex.value) {
      this.viewport.scrollToIndex(selectedIndex, 'smooth');
      return;
    }

    // These manipulations required because of CDK Viewport throwing error when call measureRangeSize outside of rendered content
    const renderedRange = this.viewport.getRenderedRange();
    const measuringRangeEnd = selectedIndex + 1 < renderedRange.end ? selectedIndex + 1 : renderedRange.end;
    const notRenderedTopItemsHeight = renderedRange.start * this.itemHeight;
    const notRenderedBottomItemsHeight = (selectedIndex + 1 - measuringRangeEnd) * this.itemHeight;

    // Calculates is selected item visible, < 0 visible, > 0 invisible
    const visibleRangeDelta =
      this.viewport.measureRangeSize({ start: this.currentIndex.value, end: measuringRangeEnd }) +
      notRenderedBottomItemsHeight -
      this.viewport.getViewportSize();

    if (visibleRangeDelta > 0) {
      this.viewport.scrollToOffset(
        this.viewport.measureRangeSize({ start: renderedRange.start, end: measuringRangeEnd }) +
          notRenderedTopItemsHeight +
          notRenderedBottomItemsHeight -
          this.viewport.getViewportSize(),
        'smooth',
      );
    }
  }
}
