// Common
import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

// Services
import { NestedService } from '@modules/common/types/nested-service.interface';

// RX
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class NestedValidators {
  static circular(id: string, parentId: string, service: NestedService) {
    return (form: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const parentControl = form.controls[parentId];
      const idControl = form.controls[id];

      if (!service || !idControl.value || !parentControl.value) {
        return of(null);
      }

      return service.validateNested(idControl.value, parentControl.value).pipe(
        tap(({ circular, deep }) => {
          if (circular) {
            parentControl.setErrors({ circular: true });
          } else if (parentControl.errors?.circular) {
            delete parentControl.errors.circular;
            parentControl.updateValueAndValidity();
          }

          if (deep) {
            parentControl.setErrors({ deep: true });
          } else if (parentControl.errors?.deep) {
            delete parentControl.errors.deep;
            parentControl.updateValueAndValidity();
          }
        }),
        map(() => null),
        catchError(() => of(null)),
      );
    };
  }
}
