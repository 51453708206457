import { Application } from '@modules/common/types/application';
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { SortOrder } from '@modules/common/types/sort-order';
import { type SortBy } from '@modules/common/types/SortBy';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateService } from '@modules/settings/services/state.service';
import { StateKey } from '@modules/settings/types/state-key';
import { OnChange } from 'src/app/decorators/on-change';
import { type CalendarEvent } from './calendar-event';

export type EventsListSortField = 'title' | 'startTime' | 'endTime' | 'score';

export class EventsListState extends BaseStitchListState<CalendarEvent, EventsListSortField> {
  @OnChange('onPropertyChange') public override sortBy?: EventsListSortField;
  @OnChange('onPropertyChange') today?: boolean;
  @OnChange('onPropertyChange') next7days?: boolean;

  constructor({
    searchService,
    stateService,
    stateKey,
    withAdvanced,
    defaultSort = { by: 'startTime', order: SortOrder.ASC },
  }: {
    searchService?: AdvancedSearchService;
    stateService?: StateService;
    stateKey?: StateKey;
    withAdvanced?: boolean;
    defaultSort?: SortBy<EventsListSortField>;
  }) {
    super({ searchService, stateService, stateKey, withAdvanced, defaultSort });
  }

  protected override applySavedState(state?: Like<EventsListState>) {
    super.applySavedState(state);
    this.today = !!state?.today;
    this.next7days = !!state?.next7days;
  }

  public override onPropertyChange(attribute) {
    if ((attribute === 'from' && this.from) || (attribute === 'to' && this.to)) {
      this.today = false;
      this.next7days = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
      this.next7days = false;
    }

    if (attribute === 'next7days') {
      this.from = null;
      this.to = null;
      this.today = false;
    }

    super.onPropertyChange(attribute);
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today,
      next7days: this.next7days,
    });
  }

  public override isDefault(): boolean {
    return super.isDefault() && !this.today && !this.next7days;
  }

  protected override syncFromAS(advancedSearchState: AdvancedSearchState) {
    const state = advancedSearchState[Application.calendar];
    this.stitchedWith = state.stitched;
    this.from = state.dateRangeFrom;
    this.to = state.dateRangeTo;
  }

  protected override syncToAS(): void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.calendar;

    state.stitched = this.stitchedWith;
    state.dateRangeFrom = this.from;
    state.dateRangeTo = this.to;

    this.searchService.setState(searchState);
  }
}
