// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';

// Types
import { Tab } from '@modules/common/types/tab';
import { User } from '@modules/admin/types/user';

// Services
import { AdminUsersService } from '@modules/admin/services/users.service';

// RX
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less'],
  standalone: false,
})
export class UserComponent implements OnInit, OnDestroy {
  public tabs: Tab[] = [
    { title: 'USER', value: 'user' },
    { title: 'PAYMENTS HISTORY', value: 'payments' },
  ];
  public selectedTab = 'user';
  public user: User;
  public mailUrl = environment.mailUrl;

  private alive = new Subject<void>();

  constructor(
    private router: Router,
    private usersService: AdminUsersService,
    private route: ActivatedRoute,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params) => this.usersService.getItem(params.id)),
        takeUntil(this.alive),
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  back() {
    this.router.navigate(['/admin/users']);
  }
}
