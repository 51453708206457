// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { ContactsAppStateService } from '@modules/contacts/services/state.service';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { StitchType } from '@modules/common/types/stitch-type';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contacts-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.less'],
  standalone: false,
})
export class ContactsMainViewComponent implements OnInit, OnDestroy {
  // Public
  public item: Contact | Group;
  public StitchType = StitchType;

  // Private
  private alive = new Subject<void>();

  constructor(private contactsStateService: ContactsAppStateService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.contactsStateService
      .getMainView()
      .pipe(takeUntil(this.alive))
      .subscribe((item) => {
        this.item = item;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.contactsStateService.setMainView(null);
  }
}
