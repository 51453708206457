<div
  *ngIf="stitch?.hasStitched"
  class="bar"
  [appStitchInfoPopover]="stitch"
  (appStitchInfoPopoverItemDoubleClick)="handleDoubleClick($event)"
>
  <mat-icon
    *ngIf="stitch?.hasStitchedMessageFolders || stitch?.hasStitchedMessages"
    class="bar-icon"
    svgIcon="mail"
  />

  <mat-icon
    *ngIf="stitch?.hasStitchedProjects || stitch?.hasStitchedTasks"
    class="bar-icon"
    svgIcon="task"
  />

  <mat-icon
    *ngIf="stitch?.hasStitchedEvents || stitch?.hasStitchedCalendars"
    class="bar-icon"
    svgIcon="event"
  />

  <mat-icon
    *ngIf="stitch?.hasStitchedGroups || stitch?.hasStitchedContacts"
    class="bar-icon"
    svgIcon="contacts"
  />

  <mat-icon
    *ngIf="stitch?.hasStitchedNotebooks || stitch?.hasStitchedNotes"
    class="bar-icon"
    svgIcon="note-small"
  />

  <mat-icon
    *ngIf="stitch?.hasStitchedFiles || stitch?.hasStitchedFolders"
    class="bar-icon"
    svgIcon="file"
  />
</div>
