import { Component, inject, Injector, Input, OnInit } from '@angular/core';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsListStateService } from '@modules/calendar-app/services/events-list-state.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { EventsListState } from '@modules/calendar-app/types/events-list-state';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';
import { Tab } from '@modules/common/types/tab';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StateKey } from '@modules/settings/types/state-key';
import { switchMap, takeUntil } from 'rxjs/operators';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.eventsFFListState]?: EventsListState;
  }
}

@Component({
  selector: 'app-calendar-form',
  templateUrl: './calendar-form.component.html',
  styleUrls: ['./calendar-form.component.less'],
  standalone: false,
  providers: [
    EventsListStateService.providers({
      stateKey: StateKey.eventsFFListState,
    }),
  ],
})
export class CalendarFormComponent extends FullFormBaseComponent<Calendar> implements OnInit {
  public changesKey = 'calendar';
  public tabs: Tab[] = [
    { title: 'Calendar', value: 'calendar' },
    { title: 'Details', value: 'details' },
    { title: 'Attachments', value: 'attachments' },
    { title: 'Stitch', value: 'stitch' },
    { title: 'Sections', value: 'sections' },
    { title: 'Lab', value: 'lab' },
    { title: 'Comments', value: 'comments' },
  ];
  tabsStateKey = 'ffCalendar';
  public currentSidebarFilter: VirtualFolder;
  public parentSuggestions: AutocompleteFactory<Calendar>;
  public eventsFilters: EventsFilters;

  protected dragDataType = DragDataTypes.calendar;

  @Input() calendar = new Calendar();

  private readonly eventsListStateService = inject(EventsListStateService, { self: true });

  constructor(
    private calendarsService: CalendarsService,
    calendarAppStateService: CalendarAppStateService,
    private stateService: CalendarAppStateService,
    injector: Injector,
  ) {
    super(injector, calendarsService, calendarAppStateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    // TODO should calendar pinned/archived/folder/flagged/etc change ?
    this.stateService
      .getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe((folder) => (this.currentSidebarFilter = folder));

    this.stitchItemChanged.pipe(takeUntil(this.alive)).subscribe(() => {
      this.parentSuggestions = this.calendarsService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
    });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.eventsListStateService.value()),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.eventsFilters = new EventsFilters({ containersIds: [this.stitchItem.id] });
        this.eventsFilters.applyListState(this.eventsListStateService.state);
      });
  }

  /**
   * Helpers
   */

  get stitchItem(): Calendar {
    return this.calendar;
  }

  protected shouldRefreshList(prev, current) {
    return Calendar.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Calendar {
    return Calendar.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.calendar.asFormGroup(this.calendarsService);
  }
}
