// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Types
import { Knot } from '@modules/knots/types/knot';

@Component({
  selector: 'app-manage-knot',
  templateUrl: './manage-knot.component.html',
  styleUrls: ['./manage-knot.component.less'],
  standalone: false,
})
export class ManageKnotComponent {
  // Inputs
  @Input() knot: Knot;

  // Output
  @Output() delete = new EventEmitter();
  @Output() pin = new EventEmitter<void>();
  @Output() change = new EventEmitter<void>();

  // Public
  public focused = false;
  private changed = false;

  /**
   * Actions
   */

  handlePin() {
    if (!this.knot) {
      return;
    }
    this.pin.emit();
  }

  handleDelete() {
    if (!this.knot) {
      return;
    }
    this.delete.emit();
  }

  handleFocus() {
    this.focused = true;
  }

  handleBlur() {
    this.focused = false;
  }

  handleChange() {
    if (!this.knot) {
      return;
    }
    if (this.changed) {
      return;
    }
    this.changed = true;
    this.change.emit();
  }
}
