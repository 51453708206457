// Common
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesService } from '@modules/files/services/files.service';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { StitchType } from '@modules/common/types/stitch-type';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { ListState as StitchListState } from '@modules/linked-info/types/list-state';
import { Stitch } from '@modules/common/types/stitch';

// RX
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stitch-to',
  templateUrl: './stitch-to.component.html',
  styleUrls: ['./stitch-to.component.less'],
  standalone: false,
})
export class StitchToComponent implements OnDestroy {
  // Inputs
  @Input() stitchItems: Stitch[];

  // Outputs
  @Output() close = new EventEmitter();

  // Public
  public stitchListState: StitchListState = {
    filters: {},
    sort: {
      by: 'date',
      order: 'asc',
    },
    view: 'short',
  };
  public inputSideOptions: DropdownSelectItem<undefined>[] = [
    { title: 'Messages', icon: 'mail', value: 'message' },
    { title: 'Events', icon: 'event', value: 'event' },
    { title: 'Projects', icon: 'project', value: 'project' },
    { title: 'Tasks', icon: 'task', value: 'task' },
    { title: 'Notebooks', icon: 'notebook', value: 'notebook' },
    { title: 'Notes', icon: 'note-small', value: 'note' },
    { title: 'Groups', icon: 'groups', value: 'group' },
    { title: 'Contacts', icon: 'contacts', value: 'contact' },
    { title: 'Folders', icon: 'folder', value: 'folder' },
    { title: 'Files', icon: 'file', value: 'file' },
  ];
  public inputSideValue: StitchType = this.inputSideOptions[0].value;
  public suggestions: AutocompleteFactory<Stitch>;
  public inputControl = new UntypedFormControl();
  public displayedLinkedInfo: LinkedInfo[] = [];
  public scrollShadowTop = false;
  public scrollShadowBottom = false;
  public loading = false;

  // Private
  private tempLinkedInfo: LinkedInfo[] = [];
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private messageFoldersService: MessageFoldersService,
    private messagesService: MessagesService,
    private calendarsService: CalendarsService,
    private eventsService: EventsService,
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private notebooksService: NotebooksService,
    private notesService: NotesService,
    private groupsService: GroupsService,
    private contactsService: ContactsService,
    private foldersService: FoldersService,
    private filesService: FilesService,
    private linkedInfoService: LinkedInfoService,
  ) {
    this.setInputSideValue(StitchType.message);
  }

  /**
   * Lifecycle
   */

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(item: DropdownSelectItem<Stitch>) {
    this.inputControl.setValue('');

    if (
      this.displayedLinkedInfo.find(
        (linkedItem) => linkedItem.type === this.inputSideValue && linkedItem.id === item.source.id,
      )
    ) {
      return;
    }

    if (item.source) {
      this.tempLinkedInfo = [...this.tempLinkedInfo, new LinkedInfo(item.source)];
      this.displayedLinkedInfo = [...this.tempLinkedInfo];
    }
  }

  handleQuickAfterSave(event: Stitch) {
    this.tempLinkedInfo = [...this.tempLinkedInfo, new LinkedInfo(event)];
    this.displayedLinkedInfo = [...this.tempLinkedInfo];
  }

  composeSuggestions(type: StitchType): AutocompleteFactory<Stitch> {
    switch (type) {
      case StitchType.messageFolder:
        return this.messageFoldersService.getAutocompleteSuggestions();
      case StitchType.message:
        return this.messagesService.getAutocompleteSuggestions();
      case StitchType.calendar:
        return this.calendarsService.getAutocompleteSuggestions();
      case StitchType.event:
        return this.eventsService.getAutocompleteSuggestions();
      case StitchType.project:
        return this.projectsService.getAutocompleteSuggestions();
      case StitchType.task:
        return this.tasksService.getAutocompleteSuggestions();
      case StitchType.notebook:
        return this.notebooksService.getAutocompleteSuggestions();
      case StitchType.note:
        return this.notesService.getAutocompleteSuggestions();
      case StitchType.group:
        return this.groupsService.getAutocompleteSuggestions();
      case StitchType.contact:
        return this.contactsService.getAutocompleteSuggestions();
      case StitchType.folder:
        return this.foldersService.getAutocompleteSuggestions();
      case StitchType.file:
        return this.filesService.getAutocompleteSuggestions();
      default:
        return checkExhaustiveness(type);
    }
  }

  setInputSideValue(type: StitchType): void {
    this.inputSideValue = type;
    this.suggestions = this.composeSuggestions(type);
  }

  handleCancel() {
    this.close.emit();
  }

  handleSave() {
    if (!this.stitchItems?.length) {
      return;
    }

    this.loading = true;

    merge(
      this.stitchItems.map((stitchItem) => {
        const itemsToLink = this.tempLinkedInfo.filter(
          (item) =>
            !stitchItem.linkedInfo.find(
              (existingItem) => item.id === existingItem.id && item.type === existingItem.type,
            ),
        );

        return this.linkedInfoService.linkItems([new LinkedInfo(stitchItem), ...itemsToLink]);
      }),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(
        () => {
          this.close.emit();
        },
        () => {
          this.close.emit();
        },
      );
  }

  removeItem(event: LinkedInfo) {
    this.tempLinkedInfo = [
      ...this.displayedLinkedInfo.filter(
        (linkedItem) => !(linkedItem.type === event.type && linkedItem.id === event.id),
      ),
    ];
    this.displayedLinkedInfo = [...this.tempLinkedInfo];
  }

  unlinkAll() {
    this.tempLinkedInfo = [];
    this.displayedLinkedInfo = [...this.tempLinkedInfo];
  }
}
