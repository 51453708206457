// Common
import { Component, Inject, Injector, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Services
import { ModalService } from '../../../modal/services/modal.service';
import { GoogleAnalyticsService } from '../../../analytics/services/google-analytics.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { NotificationsService } from '@modules/notification/services/notifications.service';
import { SpaceStateService } from '@modules/common/services/space-state.service';
import { DynamicPanelService as DPService } from '@modules/dynamic-panel/services/dynamic-panel.service';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { Application } from '@modules/common/types/application';
import { Space } from '@modules/settings/types/space';

// RX
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Utils
import { getStitchInstanceByStitchType, mapAppNameToStitchType } from '@modules/common/utils/stitch';
import { AppRoutesMap } from '@modules/pages/types/app-routes.map';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.less'],
  providers: [DPService, { provide: CloseToken, useFactory: () => new Subject<void>() }],
  standalone: false,
})
export class NavBarComponent implements OnInit, OnDestroy {
  @Input() withSearch = false;

  public popoverHide = new Subject<void>();
  public StitchType = StitchType;
  public Application = Application;
  public notificationsCount = 0;
  public appContextMenuOpened = false;
  public spacesContextMenuOpened = false;
  public space: Space;
  public app: Application = Application.mail;

  private alive = new Subject<void>();

  constructor(
    private ga: GoogleAnalyticsService,
    private messagesStateService: MailAppStateService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    @Inject(CloseToken) @Self() public closeToken,
    private router: Router,
    private spaceStateService: SpaceStateService,
    private injector: Injector,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.setApp(this.router.url);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.alive),
      )
      .subscribe((event: NavigationEnd) => {
        this.setApp(event.url);
      });

    this.notificationsService
      .getCount()
      .pipe(takeUntil(this.alive))
      .subscribe((count) => (this.notificationsCount = count));

    this.spaceStateService
      .getCurrentSpace()
      .pipe(takeUntil(this.alive))
      .subscribe((space) => (this.space = space));
  }

  ngOnDestroy() {
    this.popoverHide.next();
    this.popoverHide.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleAppChange(app: Application) {
    const route = AppRoutesMap.find((item) => item.app === app).path;

    this.router.navigate([route]);
  }

  compose() {
    const stitchType = mapAppNameToStitchType[this.app];
    if (!stitchType) {
      return;
    }
    this.newStitchItem(stitchType);
  }

  newStitchItem(type: StitchType) {
    this.ga.trackEvent('nav-bar', `new-${type}`);

    type === StitchType.message
      ? this.messagesStateService.composeMessage({ injector: this.injector })
      : this.modalService.openFullForm(getStitchInstanceByStitchType(type), this.injector);

    this.popoverHide.next();
  }

  private setApp(url: string) {
    this.app = AppRoutesMap.find((item) => url.startsWith(item.path))?.app ?? this.app;
  }
}
