<div
  class="phone-input"
  [class.with-border]="!noBorder"
>
  <app-country-picker
    class="phone-input-code"
    [inputFormControl]="phoneCode"
    showBy="phoneCode"
  />
  <app-input
    class="phone-input-phone"
    [inputFormControl]="phoneNumber"
    placeholder="Phone Number"
  />
</div>
