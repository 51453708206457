import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PopupWrapperComponent } from '../components/popup-wrapper/popup-wrapper.component';

@Injectable()
export class PopupGuardService {
  constructor(private router: Router) {}

  canDeactivate(
    _component: PopupWrapperComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot,
  ) {
    alert('Sorry, but you can`t navigate to another screen in compact mode.');
    return false;
  }
}
