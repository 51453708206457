import { Pipe, PipeTransform } from '@angular/core';
import mime from '@modules/common/utils/mime';

@Pipe({
  name: 'fileMimeToExt',
  standalone: false,
})
export class FileMimeToExtPipe implements PipeTransform {
  transform(mimeType: string): string {
    return mime.getExtension(mimeType) || '';
  }
}
