// Common
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: false,
})
export class ReplacePipe implements PipeTransform {
  transform(originalString: string, searchValue: string, newValue: string): string {
    return originalString.replace(searchValue, newValue);
  }
}
