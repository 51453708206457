// Common
import { Injectable, Injector } from '@angular/core';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesService } from '@modules/files/services/files.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Stitch } from '../types/stitch';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

@Injectable()
export class StitchServiceFactory {
  constructor(private injector: Injector) {}

  getServiceByStitchType(stitchType: StitchType): BaseStitchService<Stitch, StitchFilters<Stitch>> {
    switch (stitchType) {
      case StitchType.messageFolder:
        return this.injector.get(MessageFoldersService, undefined, { optional: true });
      case StitchType.message:
        return this.injector.get(MessagesService, undefined, { optional: true });
      case StitchType.calendar:
        return this.injector.get(CalendarsService, undefined, { optional: true });
      case StitchType.event:
        return this.injector.get(EventsService, undefined, { optional: true });
      case StitchType.project:
        return this.injector.get(ProjectsService, undefined, { optional: true });
      case StitchType.task:
        return this.injector.get(TasksService, undefined, { optional: true });
      case StitchType.notebook:
        return this.injector.get(NotebooksService, undefined, { optional: true });
      case StitchType.note:
        return this.injector.get(NotesService, undefined, { optional: true });
      case StitchType.group:
        return this.injector.get(GroupsService, undefined, { optional: true });
      case StitchType.contact:
        return this.injector.get(ContactsService, undefined, { optional: true });
      case StitchType.folder:
        return this.injector.get(FoldersService, undefined, { optional: true });
      case StitchType.file:
        return this.injector.get(FilesService, undefined, { optional: true });
      default:
        return checkExhaustiveness(stitchType);
    }
  }
}
