// Common
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'template',
  standalone: false,
})
export class TemplatePipe implements PipeTransform {
  transform(template: string, ...values: unknown[]): string {
    if (!template) {
      return null;
    }

    return template.replace(/\$(\d+)/g, (match, index) => {
      const valueIndex = parseInt(index, 10) - 1;
      return String(values[valueIndex] !== undefined ? values[valueIndex] : match);
    });
  }
}
