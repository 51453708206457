// Common
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// RX
import { combineLatest, fromEvent, ReplaySubject, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { QuickReplyTemplatesService } from '@modules/account/services/quick-reply-templates.service';
import { SignaturesService } from '@modules/account/services/signatures.service';
import { TemplatesService } from '@modules/account/services/templates.service';
import { AttachmentsService } from '@modules/attachments/services/attachments.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { MessagesService } from '@modules/messages/services/messages.service';

// Types
import { Account } from '@modules/account/types/account';
import { MessageTemplate } from '@modules/account/types/message-template';
import { QuickReplyTemplate } from '@modules/account/types/quick-reply-template';
import { Signature } from '@modules/account/types/signature';
import { Upload } from '@modules/common/types/upload';
import { Contact } from '@modules/contacts/types/contact';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { LinkedInfoData } from '@modules/linked-info/types/linked-info-data';
import { Message } from '@modules/messages/types/message';
import { type MessageActionType } from '@modules/messages/types/message-action-type';
import { type Participant } from '@modules/messages/types/participant';
import { type DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// Pipes
import { DatePipe } from '@angular/common';
import { FormatParticipantPipe } from '@modules/messages/pipes/format-participant.pipe';

@Component({
  selector: 'app-message-compose',
  templateUrl: './message-compose.component.html',
  styleUrls: ['./message-compose.component.less'],
  standalone: false,
})
export class MessageComposeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  // Inputs
  @Input() message: Message;
  @Input() linkedInfo: LinkedInfoData[];
  @Input() reply: Message;
  @Input() forward: Message[];
  @Input() action: MessageActionType;

  // Outputs
  @Output() close = new EventEmitter();

  // Public
  public contactsSuggestions: AutocompleteFactory<Participant>;
  public form: UntypedFormGroup;
  public cc = false;
  public bcc = false;
  public loading = false;
  public dragOver = 0;
  public droppedFiles = new ReplaySubject<FileList>();
  public filesUploading = false;
  public customPopoverClose = new Subject<void>();
  public signatures: Signature[];
  public templates: MessageTemplate[];
  public quickReplyTemplates: QuickReplyTemplate[];
  public editorCommandExecutor = new Subject<{ commandName: string; ui: boolean; content: string }>();

  // Private
  private alive = new Subject<void>();
  private messageChanged = new Subject<void>();
  private account: Account;

  // View Children
  @ViewChild('droppableArea') droppableArea: ElementRef;

  // Callable attributes
  public readonly useInputTextGetValue: (text: string) => DropdownSelectItem<Participant>;

  // Callable attributes
  public dndPredicate =
    (currentItems: unknown[]) =>
    (dragData: DragData): boolean =>
      dragData.type === 'contact' &&
      dragData.data.every((item) => item?.['value']?.address || item?.['emails']?.length) &&
      !dragData.data.some((dragDataItem) =>
        currentItems.some(
          (item) => item['address'] === (dragDataItem['value']?.address || dragDataItem['emails'][0].value),
        ),
      );

  /**
   * Constructor
   */

  constructor(
    private messagesService: MessagesService,
    private contactsService: ContactsService,
    private ngZone: NgZone,
    private router: Router,
    private attachmentsService: AttachmentsService,
    private formatParticipantPipe: FormatParticipantPipe,
    private datePipe: DatePipe,
    private accountService: AccountService,
    private signaturesService: SignaturesService,
    private templatesService: TemplatesService,
    private quickReplyTemplatesService: QuickReplyTemplatesService,
  ) {
    this.contactsSuggestions = this.contactsService.getEmailAutocompleteSuggestions();
    this.reset();

    this.useInputTextGetValue = (text: string) => {
      const participant: Participant = { address: text };
      return {
        value: participant,
        source: participant,
        title: formatParticipantPipe.transform(participant),
      };
    };
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.accountService
      .getAccount()
      .pipe(takeUntil(this.alive))
      .subscribe((account) => {
        this.account = account;
        this.initByAction();
      });

    this.signaturesService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: signatures }) => (this.signatures = signatures));

    this.templatesService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: templates }) => (this.templates = templates));

    this.quickReplyTemplatesService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: quickReplyTemplates }) => (this.quickReplyTemplates = quickReplyTemplates));

    this.messageChanged
      .pipe(
        tap(() => {
          this.cc = this.message?.cc?.length > 0;
          this.bcc = this.message?.bcc?.length > 0;
        }),
        filter(() => this.action !== 'forwardAsAttachment'),
        map(() => {
          if (this.action === 'forward' && this.forward?.length) {
            return this.forward;
          } else if (this.action === 'reply' && this.reply) {
            return [this.reply];
          } else if (this.message?.id) {
            return [this.message];
          } else {
            return [];
          }
        }),
        switchMap((messages) => combineLatest(messages.map((message) => this.attachmentsService.listAll(message)))),
        takeUntil(this.alive),
      )
      .subscribe((attachments) => {
        this.form.controls.uploads.setValue(
          [].concat(...attachments).map(
            (attachment) =>
              new Upload({
                id: attachment.attachmentId,
                name: attachment.fileName,
                type: attachment.fileType,
                size: attachment.fileSize,
                temp: false,
                inline: attachment.inline,
                copy: this.action === 'forward' ? attachment.stitchItem.id : null,
              }),
          ),
        );
      });

    this.messageChanged.next();
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.droppableArea.nativeElement, 'dragover')
        .pipe(takeUntil(this.alive))
        .subscribe((event: DragEvent) => event.preventDefault());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('message' in changes) {
      this.reset();
      this.messageChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  reset() {
    this.form = (this.message || new Message()).asFormGroup();
    this.initByAction();
  }

  send(draft = false) {
    if (!this.form.valid) {
      return;
    }

    this.form.patchValue({ noSend: draft, draft: true, linkedInfo: this.linkedInfo }, { emitEvent: false });

    const message = Message.fromFormGroup(this.form);

    this.loading = true;

    this.messagesService
      .create(message)
      .pipe(takeUntil(this.alive))
      .subscribe(
        () => {
          this.loading = false;
          this.close.emit();
        },
        (error) => this.handleError(error),
      );
  }

  handleError(_error: Error) {
    this.loading = false;
  }

  selectTemplate(template: MessageTemplate) {
    Message.formatSignatureTemplate(this.form.controls.bodyHtml, template);
    this.customPopoverClose.next();
  }

  selectSignature(signature: Signature) {
    Message.formatSignatureTemplate(this.form.controls.bodyHtml, signature);
    this.customPopoverClose.next();
  }

  selectQuickReply(quickReply: QuickReplyTemplate) {
    this.editorCommandExecutor.next({ commandName: 'mceInsertContent', ui: false, content: quickReply.content });
    this.customPopoverClose.next();
  }

  handleNewTemplate(type) {
    this.close.emit();
    this.router.navigate(['/settings', 'mail', type]);
  }

  handleDropFiles(event: DragEvent) {
    this.dragOver = 0;
    event.stopPropagation();
    event.preventDefault();

    this.droppedFiles.next(event.dataTransfer.files);
  }

  handleDrop(dragData: DragData, controlName: string) {
    const items = dragData.data.map((item) =>
      item instanceof Contact ? { name: item.title, address: item.emails?.[0]?.value } : item['value'],
    );

    this.form.controls[controlName].setValue(this.form.controls[controlName].value.concat(items));
  }

  handleEditorUploadedImage(upload: Upload) {
    this.form.controls['uploads'].setValue(this.form.controls['uploads'].value.concat(upload));
  }

  initByAction() {
    if (!this.action || !this.account) {
      return;
    }

    // https://support.microsoft.com/en-us/office/reply-to-or-forward-an-email-message-a843f8d3-01b0-48da-96f5-a71f70d0d7c8#:~:text=Reply%20all%20sends%20the%20new,when%20you%20forward%20a%20message.

    if ((this.action === 'forward' || this.action === 'forwardAsAttachment') && this.forward?.length) {
      this.form.controls.subject.setValue(`Fwd: ${this.forward[0].subject}`);
      this.form.controls.forwardOf.setValue(this.forward[0].id);

      if (this.action === 'forwardAsAttachment') {
        this.form.controls.uploads.setValue(
          this.forward.map(
            (message) =>
              new Upload({
                id: 'eml-' + message.id,
                name: `${message.subject}.eml`,
                type: 'message/rfc822',
                size: message.emlSize || 0,
                temp: false,
                copy: message.id,
              }),
          ),
        );
      }

      this.form.controls.bodyHtml.setValue(
        this.forward
          .map(
            (message) => `
            <br/>
            ---------- Forwarded message ---------
            <br/>
            From: ${this.formatParticipantPipe.transform(message.from[0], true)}
            <br/>
            Date: ${this.datePipe.transform(message.date, 'full')}
            <br/>
            Subject: ${message.subject || 'No Subject'}
            <br/>
            To: ${(message.to || [])
              .map((participant) => this.formatParticipantPipe.transform(participant, true))
              .join(', ')}
            <br/>
            CC: ${(message.cc || [])
              .map((participant) => this.formatParticipantPipe.transform(participant, true))
              .join(', ')}
            <br/>
            <br/>

            ${message.bodyHtml}
          `,
          )
          .join('<br/><br/>'),
      );
    }

    if ((this.action === 'reply' || this.action === 'replyAll') && this.reply) {
      this.form.controls.subject.setValue(`Re: ${this.reply.subject}`);
      this.form.controls.replyTo.setValue(this.reply.id);

      if (this.action === 'reply') {
        this.form.controls.to.setValue(this.reply.from || []);
      } else {
        this.form.controls.to.setValue(
          [...(this.reply.from || []), ...(this.reply.to || []), ...(this.reply.cc || [])].filter(
            (participant) => participant.address !== this.account.email,
          ),
        );
      }

      this.form.controls.bodyHtml.setValue(`
        <br/>
        <p>On ${this.datePipe.transform(this.reply.date, 'full')} ${this.formatParticipantPipe.transform(this.reply.from[0], true)} wrote:</p>
        <blockquote>${this.reply.bodyHtml}</blockquote>
      `);
    }
  }
}
