// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Types
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { GroupsListState } from '@modules/contacts/types/groups-list-state';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { ContactsAppStateService } from '@modules/contacts/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';

@Component({
  selector: 'app-side-bar-groups',
  templateUrl: './side-bar-groups.component.html',
  styleUrls: [
    '../../../common/components/base-sidebar-containers-item/base-sidebar-containers-item.component.less',
    './side-bar-groups.component.less',
  ],
  animations: [heightAnimation],
  standalone: false,
})
export class SideBarGroupsComponent
  extends BaseSidebarContainersItemComponent<Group, Contact, GroupsFilters, VirtualFolder, GroupsListState>
  implements OnInit
{
  application = Application.contacts;
  sidebarSplitViewKey = SidebarSplitViewKey.contacts;
  listStateKey = StateKey.sidebarGroupsListState;

  constructor(contactsStateService: ContactsAppStateService) {
    super(contactsStateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.itemsListState.pipe(takeUntil(this.alive)).subscribe((state) => {
      this.filters = new GroupsFilters().applyListState(state);
    });
  }
}
