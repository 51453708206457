import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';
import { TimeZone } from '@modules/form-controls/types/timezones';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class SpaceSettings {
  formBuilder = injector.get(FormBuilder);

  timeZone?: TimeZone;

  constructor(data: Partial<SpaceSettings> = {}) {
    this.timeZone = data.timeZone || (Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZone);
  }

  static fromFormGroup(form: FormGroup): SpaceSettings {
    const { value: formValues } = form;

    return new SpaceSettings({
      timeZone: formValues.timeZone,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      timeZone: [this.timeZone],
      changeAutomatically: [true],
      notifyOnTimezoneChanges: [true],
      timeFormat: [''],
      dateFormat: [''],
    });
  }

  public asPayloadJSON() {
    return {
      timeZone: this.timeZone,
    };
  }
}
