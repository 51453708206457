// Common
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Tab } from '@modules/common/types/tab';
import {
  Condition,
  generateSchemas,
  Input as RuleInput,
  stitchTypeOptions,
} from '@modules/settings/types/automation-rules';
import { AutomationRule } from '@modules/settings/types/automation-rule';
import { StitchType } from '@modules/common/types/stitch-type';

// RX
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { AutomationRulesService } from '@modules/settings/services/automation-rules.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { FilesService } from '@modules/files/services/files.service';

@Component({
  selector: 'app-settings-rule-form',
  templateUrl: './rule-form.component.html',
  styleUrls: ['./rule-form.component.less'],
  standalone: false,
})
export class RuleFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rule: AutomationRule;

  public form: FormGroup;
  public tabs: Tab<Condition>[] = [
    { title: 'IF', value: 'if' },
    { title: 'AND', value: 'and' },
    { title: 'THEN', value: 'then' },
  ];
  public selectedTab = 'if';
  public currentSchema: RuleInput[];
  public stitchTypeOptions = stitchTypeOptions;
  public schemas = generateSchemas({
    knotsService: this.knotsService,
    tagsService: this.tagsService,
    stitchServices: {
      [StitchType.messageFolder]: this.messageFoldersService,
      [StitchType.message]: this.messagesService,
      [StitchType.calendar]: this.calendarsService,
      [StitchType.event]: this.eventsService,
      [StitchType.project]: this.projectsService,
      [StitchType.task]: this.tasksService,
      [StitchType.notebook]: this.notebooksService,
      [StitchType.note]: this.notesService,
      [StitchType.group]: this.groupsService,
      [StitchType.contact]: this.contactsService,
      [StitchType.folder]: this.foldersService,
      [StitchType.file]: this.filesService,
    },
  });
  public scrollShadowTop = false;
  public scrollShadowBottom = false;

  // Private
  private alive = new Subject<void>();
  private saveInProgress = false;
  private ruleChanged = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private knotsService: KnotsService,
    private tagsService: TagsService,
    private messageFoldersService: MessageFoldersService,
    private messagesService: MessagesService,
    private calendarsService: CalendarsService,
    private eventsService: EventsService,
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    private notebooksService: NotebooksService,
    private notesService: NotesService,
    private contactsService: ContactsService,
    private automationRulesService: AutomationRulesService,
    private groupsService: GroupsService,
    private foldersService: FoldersService,
    private filesService: FilesService,
    @Inject(CloseToken) private closeToken,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.initForm();

    this.ruleChanged
      .pipe(
        switchMap(() => this.form.controls.stitchType.valueChanges),
        takeUntil(this.alive),
      )
      .subscribe((type) => {
        this.currentSchema = this.schemas[type];
      });

    this.ruleChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('rule' in changes) {
      this.initForm();
      this.ruleChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  initForm() {
    const rule = this.rule || new AutomationRule();
    this.form = rule.asFormGroup();
    this.currentSchema = rule.getSchema(this.schemas);
  }

  handleCancel() {
    this.closeToken.next();
  }

  handleClickInput(input: RuleInput) {
    input.active = !input.active;
    this.currentSchema = [...this.currentSchema];
    this.changeDetector.detectChanges();
  }

  handleSubmit() {
    if (!this.form.valid || !this.currentSchema) {
      return;
    }

    this.saveInProgress = true;

    const rule = AutomationRule.fromFormGroup(this.form, this.currentSchema);

    (this.form.get('id').value
      ? this.automationRulesService.update(rule, true, false)
      : this.automationRulesService.create(rule)
    )
      .pipe(takeUntil(this.alive))
      .subscribe(
        () => {
          this.closeToken.next();
        },
        () => this.handleError(),
      );
  }

  handleError() {
    this.saveInProgress = false;
  }
}
