// Common
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { heightAnimation } from '@modules/common/animations/height.animation';

// RX
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { BoardService } from '@modules/tasks/services/board.service';

// Types
import { Row } from '@modules/tasks/types/row';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { SectionTaskAppearance } from '@modules/tasks/types/section-task-appearance';
import { Column } from '@modules/tasks/types/column';

@Component({
  selector: 'app-project-board-row',
  templateUrl: './project-board-row.component.html',
  styleUrls: ['./project-board-row.component.less'],
  animations: [heightAnimation],
  standalone: false,
})
export class ProjectBoardRowComponent implements OnInit, OnChanges, OnDestroy {
  public rowForm: UntypedFormGroup;
  public collapsed = false;
  public columns: Column[] = [];

  private alive = new Subject<void>();
  private rowChanged = new Subject<void>();

  @Input() row: Row;
  @Input() appearance: SectionTaskAppearance = 'default';

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => dragData.type === 'task';

  constructor(private boardService: BoardService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.rowForm = this.row?.asFormGroup();

    this.boardService
      .getColumns()
      .pipe(takeUntil(this.alive))
      .subscribe((columns) => {
        this.columns = columns;
      });

    this.rowChanged
      .pipe(
        filter(() => !!this.row),
        switchMap(() => this.boardService.getRowCollapsed(this.row.id)),
        takeUntil(this.alive),
      )
      .subscribe((value: boolean) => (this.collapsed = value));

    this.rowChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('row' in changes) {
      this.rowChanged.next();
      this.rowForm = this.row?.asFormGroup();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleBlur() {
    if (this.rowForm.get('title').dirty && this.rowForm.get('title').value.trim() !== '') {
      this.boardService.updateRow(Row.fromFormGroup(this.rowForm));
    }
  }

  delete() {
    this.boardService.removeRow(this.row);
  }

  moveUp() {
    this.boardService.moveRow(this.row, -1);
  }

  moveDown() {
    this.boardService.moveRow(this.row, 1);
  }

  toggleCollapse() {
    this.boardService.setRowCollapsed(this.row.id, !this.collapsed);
  }
}
