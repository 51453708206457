// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { FilesAppStateService } from '@modules/files/services/state.service';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { File } from '@modules/files/types/file';
import { Folder } from '@modules/files/types/folder';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-files-main-view',
  templateUrl: './files-main-view.component.html',
  styleUrls: ['./files-main-view.component.less'],
  standalone: false,
})
export class FilesMainViewComponent implements OnInit, OnDestroy {
  // Public
  public StitchType = StitchType;
  public item: File | Folder;

  private alive = new Subject<void>();

  constructor(private filesStateService: FilesAppStateService) {}

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.filesStateService
      .getMainView()
      .pipe(takeUntil(this.alive))
      .subscribe((item) => {
        this.item = item;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.filesStateService.setMainView(null);
  }
}
