<app-context-menu-item
  title="Mark Tasks as Completed"
  [active]="columnFormGroup.controls['completed'].value"
  [withCheckOption]="true"
  (execute)="handleCompleted()"
/>

<app-context-menu-item
  title="Add Column to the Left"
  [close]="true"
  (execute)="addLeft()"
/>

<app-context-menu-item
  title="Add Column to the Right"
  [close]="true"
  (execute)="addRight()"
/>

<app-context-menu-item
  title="Move to the Left"
  [close]="true"
  (execute)="moveLeft()"
/>

<app-context-menu-item
  title="Move to the Right"
  [close]="true"
  (execute)="moveRight()"
/>

<app-context-menu-item
  title="Delete"
  [close]="true"
  matTooltip="Can't delete uncategorized column"
  [matTooltipDisabled]="!column.uncategorized"
  (execute)="delete()"
/>

<app-context-menu-divider />

<app-color-picker [inputFormControl]="columnFormGroup?.controls['color']" />
