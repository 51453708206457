import { Inject, Injectable } from '@angular/core';
import { ListStateService } from '@modules/common/components/list/list-state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import {
  MessageListStateServiceConfig,
  MessageListStateServiceConfigToken,
} from '../types/MessageListStateServiceConfig';
import { MessagesListState } from '../types/messages-list-state';
import { type Message } from '../types/message';
import { StateKey } from '@modules/settings/types/state-key';

@Injectable()
export class MessageListStateService extends ListStateService<Message, MessagesListState> {
  public override readonly state: MessagesListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(MessageListStateServiceConfigToken) config: MessageListStateServiceConfig,
  ) {
    super();

    this.state = new MessagesListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch,
      defaultSort: config.defaultSort,
    });
  }

  public static providers(config: MessageListStateServiceConfig = {}): any[] {
    return super.prepareProviders(MessageListStateServiceConfigToken, config);
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.messagesListState]?: MessagesListState;
    [StateKey.messagesDPListState]?: MessagesListState;
  }
}
