import { Pipe, PipeTransform } from '@angular/core';
import { RelativeDate } from '@modules/common/types/relative-date';
import { relativeDateToDate } from '@modules/common/utils/date';

@Pipe({
  name: 'fromRelativeDate',
  standalone: false,
})
export class FromRelativeDatePipe implements PipeTransform {
  transform(range: RelativeDate): Date {
    return relativeDateToDate(range);
  }
}
