import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Stripe, StripeCardElement, StripeElements, loadStripe } from '@stripe/stripe-js';
import { HttpClient } from '@angular/common/http';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseService } from '@modules/common/services/base.service';
import { AlertService } from '@modules/alert/services/alert.service';

// Types
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// RX
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StripeService extends BaseService {
  private stripe: Stripe;
  private elements: StripeElements;

  constructor(
    protected toaster: ToasterService,
    protected http: HttpClient,
    private alertService: AlertService,
  ) {
    super();
  }

  initializeStripeElements(): Observable<StripeElements> {
    if (this.stripe) {
      this.elements = this.stripe.elements();
      return of(this.elements);
    }

    return from(loadStripe(environment.stripePublicKey)).pipe(
      map((stripe) => {
        this.stripe = stripe;
        this.elements = this.stripe.elements();
        return this.elements;
      }),
    );
  }

  attachCard(card: StripeCardElement, { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }) {
    return from(this.stripe.createPaymentMethod({ type: 'card', card })).pipe(
      tap(({ error }) => {
        if (!error) {
          return;
        }
        this.toaster.show({ text: `Stripe Error: ${error.message}` });
        throw error;
      }),
      switchMap(({ paymentMethod }) =>
        this.http.post<{ clientSecret: string }>(
          environment.baseUrl + '/api/account/payment-methods/stripe/request_confirmation',
          { paymentMethodId: paymentMethod.id },
        ),
      ),
      switchMap(({ clientSecret }) => from(this.stripe.confirmCardSetup(clientSecret))),
      switchMap(({ setupIntent, error }) => {
        if (error) {
          this.alertService.show({
            title: 'Error!',
            body: `${error.message} ${error.code || error.decline_code || ''}`,
            rightButtons: [{ title: 'OK', close: true }],
          });

          return of({ success: false });
        }

        return this.http.post<{ success: boolean }>(environment.baseUrl + '/api/account/payment-methods/stripe', {
          paymentMethod: setupIntent.payment_method,
        });
      }),
      tap(({ success }) => {
        if (!success) {
          return;
        }

        emit && this.forceRefresh();
        toast && this.toaster.show({ text: message || `Card added.` });
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }
}
