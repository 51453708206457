import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allPropertiesTrue',
  standalone: false,
})
export class AllPropertiesTruePipe implements PipeTransform {
  transform(object: { [key: string]: boolean } = {}): boolean {
    return Object.values(object).every(Boolean);
  }
}
