// Common
import { NgModule } from '@angular/core';

// Modules
import { AlertModule } from '@modules/alert/alert.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { CommonModule } from '@angular/common';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@modules/utils/utils.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollModule } from '@modules/scroll/scroll.module';

// Components
import { AdminSideBarComponent } from './components/side-bar/side-bar.component';
import { PlansPlansComponent } from './components/plans/plans/plans.component';
import { PlansSettingsComponent } from './components/plans/settings/settings.component';
import { SubscriptionPlanFormComponent } from './components/plans/plan-form/plan-form.component';
import { UserChargesComponent } from './components/user/charges/charges.component';
import { UserComponent } from './components/user/user.component';
import { UserUserComponent } from './components/user/user/user.component';
import { UsersComponent } from './components/users/users.component';
import { UsersUsersComponent } from './components/users/users/users.component';

// Services
import { AdminUsersService } from './services/users.service';
import { ChargesService } from './services/charges.service';
import { SettingsService } from './services/settings.service';
import { SubscriptionPlansService } from './services/subscription-plans.service';

@NgModule({
  imports: [
    AlertModule,
    AppCommonModule,
    CommonModule,
    DragNDropModule,
    FormControlsModule,
    ImageCropperModule,
    MatIconModule,
    MatTooltipModule,
    UtilsModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollModule,
  ],
  declarations: [
    AdminSideBarComponent,
    PlansPlansComponent,
    PlansSettingsComponent,
    SubscriptionPlanFormComponent,
    UserChargesComponent,
    UserComponent,
    UserUserComponent,
    UsersComponent,
    UsersUsersComponent,
  ],
  exports: [AdminSideBarComponent],
  providers: [AdminUsersService, ChargesService, SettingsService, SubscriptionPlansService],
})
export class AdminModule {}
