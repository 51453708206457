// Common
import { Component, Injector } from '@angular/core';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'app-message-folder-shared',
  templateUrl: './message-folder-shared.component.html',
  styleUrls: ['./message-folder-shared.component.less'],
  standalone: false,
})
export class MessageFolderSharedComponent extends SharedBaseComponent<MessageFolder> {
  protected stitchType = StitchType.messageFolder;

  constructor(injector: Injector) {
    super(injector);
  }

  get folder() {
    return this.stitchItem;
  }
}
