// Common
import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Types
import { Like } from '@modules/common/types/like';
import { Notebook } from '../types/notebook';
import { NotebooksFilters } from '../types/notebooks-filters';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { NestedService } from '@modules/common/types/nested-service.interface';

@Injectable()
export class NotebooksService
  extends BaseStitchService<Notebook, NotebooksFilters>
  implements CalendarEventsService, NestedService
{
  protected url = environment.baseUrl + '/api/notes/notebooks/';
  protected stitchType = StitchType.notebook;
  protected filtersConstructor = NotebooksFilters;

  constructor(
    protected http: HttpClient,
    protected toasterService: ToasterService,
    @Optional() linkedInfoService: LinkedInfoService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<NotebooksFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Notebook, 'parentId'>>,
    config: FeedbackConfig,
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  create(notebookInstance: Notebook, { emit, toast, message }: FeedbackConfig = { emit: true }): Observable<Notebook> {
    return this.http
      .post<{
        notebook: Notebook;
        success: boolean;
      }>(`${environment.baseUrl}/api/notes/notebooks`, notebookInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Notebook created.` });
        }),
        map(({ notebook }) => new Notebook(notebook)),
        switchMap((notebook) =>
          this.processKnowledgeItems(
            {
              linkedInfo: notebookInstance.linkedInfo,
              knots: notebookInstance.knots,
              tags: notebookInstance.tags,
            },
            notebook,
          ),
        ),
        switchMap((notebook) =>
          this.processUploads(notebookInstance.uploads, notebook, { other: true, parentFolderName: 'Note App' }),
        ),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  update(
    notebookInstance: Notebook,
    { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true },
  ): Observable<Notebook> {
    return this.http
      .put<{
        notebook: Notebook;
        success: boolean;
      }>(environment.baseUrl + '/api/notes/notebooks/' + notebookInstance.id, notebookInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Notebook updated' });
          }
        }),
        map(({ notebook }) => notebook),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime }).pipe(
      map(({ items }) => items.map((notebook) => notebook.asAngularCalendarEvent())),
    );
  }

  getAutocompleteSuggestions(inputFilters: Like<NotebooksFilters> = {}): AutocompleteFactory<Notebook> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<NotebooksFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters,
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title.trim();
        filters.esAnalyzer = 'ngrams_7';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters).pipe(
        map((response: BaseSearchResponse<Notebook>) =>
          response.items.map((notebook) => ({
            title: notebook.title,
            value: notebook.id,
            source: notebook,
          })),
        ),
      );
    };
  }

  createInstance(item): Notebook {
    return new Notebook(item);
  }

  validateNested(id: string, parentId: string) {
    return this.http
      .get<{
        deep: boolean;
        circular: boolean;
      }>(`${environment.baseUrl}/api/notes/notebooks/validate-nesting/${id}/${parentId}`)
      .pipe(catchError((error) => this.handleObserverError(error)));
  }
}
