<div
  class="upload"
  [ngClass]="size"
>
  <div
    class="upload-container"
    [ngClass]="size"
  >
    <mat-icon
      class="upload-container-type-icon"
      [ngClass]="size"
      svgIcon="sapphire-file"
    >
      <span class="upload-container-type-icon-text">{{ upload.type | fileMimeToExt | uppercase }}</span>
    </mat-icon>

    <div
      class="upload-container-body"
      [ngClass]="size"
    >
      <div class="upload-container-body-name">
        {{ upload.name }}
      </div>

      <ng-container
        *ngIf="upload.temp && size !== 'l'"
        [ngTemplateOutlet]="progressTemplate"
      />
      <div class="upload-container-body-size">
        {{ upload.size | fileSize }}
        <ng-container *ngIf="size === 'l'">: {{ upload.progress | async }}%</ng-container>
      </div>
    </div>

    <ng-container
      *ngIf="upload.temp"
    >
      <mat-icon
        *ngIf="upload.error"
        class="upload-container-icon retry"
        [class.inactive]="uploadInProgress"
        svgIcon="retry"
        (click)="retry.emit()"
      />

      <mat-icon
        *ngIf="!showDoneIcon"
        class="upload-container-icon"
        [class.inactive]="uploadInProgress"
        svgIcon="sapphire-close"
        (click)="delete.emit()"
      />

      <mat-icon
        *ngIf="showDoneIcon"
        class="upload-container-icon complete"
        svgIcon="check-mark"
      />
    </ng-container>
  </div>

  <ng-container
    *ngIf="upload.temp && size === 'l'"
    [ngTemplateOutlet]="progressTemplate"
  />

  <div
    *ngIf="!upload.temp"
    class="upload-hover-actions"
    [ngClass]="size"
  >
    <mat-icon
      class="upload-hover-actions-item"
      svgIcon="sapphire-flagged"
    />
    <mat-icon
      class="upload-hover-actions-item"
      svgIcon="sapphire-download"
    />

    <mat-icon
      class="upload-hover-actions-item"
      svgIcon="sapphire-ellipsis"
    />
  </div>

</div>

<ng-template #progressTemplate>
  <div
    *ngIf="(upload.progress | async | between: 0: 100: false) || upload.error"
    class="upload-progress"
    [ngClass]="size"
    [class.error]="upload.error"
  >
    <div
      class="upload-progress-done"
      [class.error]="upload.error"
      [style.width.%]="upload.progress | async"
    ></div>
  </div>
</ng-template>
