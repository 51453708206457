<div
  class="overlay"
  (click)="$event.stopPropagation()"
>
  <div
    class="alert popover-nested-state-container"
    [ngClass]="config?.appearance || 'prompt'"
    #popoverContainer
    [style.width.px]="config?.width"
  >
    <div
      *ngIf="config?.title"
      class="heading"
    >
      <div class="title heading1">
        {{ config?.title }}
      </div>

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-close"
        (click)="hide()"
      />
    </div>

    <ng-container [ngTemplateOutlet]="config?.template" />

    <ng-template #modalAnchor />

    <div
      *ngIf="config?.body"
      class="body-primary"
    >
      {{ config?.body }}
    </div>

    <ng-container
      *ngFor="let input of inputs"
      [ngSwitch]="input.type"
    >
      <app-input
        *ngSwitchCase="'simple'"
        appearance="amethyst"
        class="input"
        [label]="input.label"
        [inputFormControl]="input.control"
        [withClear]="true"
      />
    </ng-container>

    <div
      *ngIf="config?.leftButtons?.length || config?.rightButtons?.length"
      class="buttons"
    >
      <app-button
        *ngFor="let button of config?.leftButtons; let index = index"
        class="buttons-item"
        [label]="button.title"
        [appearance]="button.appearance || 'link-blue'"
        [size]="button.size || 's'"
        (click)="handleButtonClick('left', index)"
      />

      <div class="buttons-divider"></div>

      <app-button
        *ngFor="let button of config?.rightButtons; let index = index"
        class="buttons-item"
        [label]="button.title"
        [appearance]="button.appearance || 'link-blue'"
        [size]="button.size || 's'"
        (click)="handleButtonClick('right', index)"
      />
    </div>
  </div>
</div>
