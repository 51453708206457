// Common
import { ComponentRef, Directive, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { AppContextMenuComponent } from '../components/app-context-menu/app-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { Application } from '../types/application';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appContextMenu]',
  standalone: false,
})
export class AppContextMenuDirective extends ContextMenuDirective implements OnChanges {
  private instance: AppContextMenuComponent;

  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() appContextMenu: Application;
  @Input() appContextMenuDP: boolean;

  @Output() appContextMenuOpened = this.contextMenuOpened;
  @Output() appContextMenuChange = new EventEmitter<Application>();

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('appContextMenu' in changes && this.instance) {
      this.instance.app = this.appContextMenu;
    }
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<AppContextMenuComponent>) {
    this.instance = componentRef.instance;

    this.instance.app = this.appContextMenu;
    this.instance.dp = this.appContextMenuDP;

    this.instance.appChange.pipe(takeUntil(this.alive)).subscribe((app: Application) => {
      this.appContextMenuChange.emit(app);
    });
  }

  registerComponent() {
    return AppContextMenuComponent;
  }
}
