// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { NoteContextMenuComponent } from '../components/context-menus/note-context-menu/note-context-menu.component';

// Types
import { Note } from '@modules/notes/types/note';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appNoteContextMenu]',
  standalone: false,
})
export class NoteContextMenuDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() appNoteContextMenu: Note;
  @Input() appNoteContextMenuDisabled = false;
  @Input() appNoteContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appNoteContextMenuOpened = this.contextMenuOpened;
  @Output() appNoteContextMenuOpenNote = new EventEmitter();

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appNoteContextMenuDisabled;

    if (this.appNoteContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return NoteContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<NoteContextMenuComponent>) {
    componentRef.instance.note = this.appNoteContextMenu;
    componentRef.instance.open.pipe(takeUntil(this.alive)).subscribe(() => {
      this.appNoteContextMenuOpenNote.emit();
    });
  }
}
