// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';
import { environment } from '@environment';

// RX
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// Types
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { PaymentMethod } from '../types/payment-method';
import { PaymentMethodsFilters } from '../types/payment-methods-filters';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService extends BaseRestService<PaymentMethod, PaymentMethodsFilters> {
  private url = environment.baseUrl + '/api/account/payment-methods/';

  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(filters?: Partial<PaymentMethodsFilters>): Observable<BaseSearchResponse<PaymentMethod>> {
    const requestParams = { params: new PaymentMethodsFilters(filters || {}).format() };

    return this.http.get<{ count: number; items: PaymentMethod[] }>(this.url, requestParams).pipe(
      map(({ count, items }) => ({
        count,
        items: items.map((item) => new PaymentMethod(item)),
      })),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  create(): Observable<PaymentMethod> {
    throw new Error('Method forbidden. Use StripeService::attachCard instead.');
  }

  update(
    paymentMethod: PaymentMethod,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<PaymentMethod> {
    return this.http
      .put<{ success: boolean; item: PaymentMethod }>(this.url + paymentMethod.id, {
        isDefault: paymentMethod.isDefault,
      })
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();
          toast &&
            this.toaster.show({
              text: message || `Payment Method ${paymentMethod.isDefault ? 'Activated' : 'Suspended'}.`,
            });
        }),
        map(({ item }) => new PaymentMethod(item)),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  delete(
    instance: PaymentMethod,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(this.url + instance.id).pipe(
      map(({ success }) => success),
      tap((success) => {
        if (!success) {
          return;
        }

        emit && this.forceRefresh();

        toast && this.toaster.show({ text: message || 'Payment Method successfully deleted' });
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }
}
