<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="groups-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"

  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <ng-container *cdkVirtualFor="let group of itemsStreamObservable; let index = index">

    <!-- Date header -->
    <div
      class="days-separator"
      *ngIf="!group?.pinned && isHover && group?.createdAt | differentDay: items[index - 1]?.createdAt"
    >
      <div class="days-separator-date">
        {{ group.createdAt | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <app-group
      [item]="group"
      [position]="index"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [debug]="debug"
      (open)="openGroup.emit(group)"
      (openContact)="openContact.emit($event)"
      (appClick)="handleClickItem(group)"
      (appDblClick)="handleDoubleClickItem(group)"

      [appSelectable]="group"
      [appSelectablePosition]="index"
    />
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="group-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="groups-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="groups-stub-image" src="assets/placeholders/placeholder-tasks.svg" alt="image for empty groups list" />
  <p class="groups-stub-text">{{ placeholderText }}</p>
</div>
