// Common
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

// Services
import { SpaceStateService } from '@modules/common/services/space-state.service';
import { AlertService } from '@modules/alert/services/alert.service';

// Types
import { SpaceParticipant } from '@modules/settings/types/space-participant';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { EventParticipant } from '@modules/calendar-app/types/event-participant';

// RX
import { Subject, of, timer } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

interface ParticipantItem {
  participant: SpaceParticipant;
  optional: boolean;
}

@Component({
  selector: 'app-participants-input',
  templateUrl: './participants-input.component.html',
  styleUrls: ['./participants-input.component.less'],
  standalone: false,
})
export class ParticipantsInputComponent implements OnInit, OnChanges, OnDestroy {
  public allParticipants: SpaceParticipant[] = [];
  public innerParticipants: ParticipantItem[] = [];
  public currentParticipants: ParticipantItem[] = [];
  public searchControl = new FormControl();
  public suggestions: AutocompleteFactory<SpaceParticipant>;

  private alive = new Subject<void>();
  private controlChanged = new Subject<void>();

  @Input() control: UntypedFormControl;

  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<void>;

  constructor(
    private spaceService: SpaceStateService,
    private alertsService: AlertService,
    private elementRef: ElementRef,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestions = this.spaceService.getUsersAutocompleteSuggestions();

    this.spaceService
      .getCurrentSpace()
      .pipe(
        map((space) => space?.participants || []),
        takeUntil(this.alive),
      )
      .subscribe((participants) => {
        this.allParticipants = participants;
        this.controlChanged.next();
      });

    this.controlChanged
      .pipe(
        switchMap(() => this.control?.valueChanges || of(null)),
        startWith(this.control?.value),
        takeUntil(this.alive),
      )
      .subscribe((value: EventParticipant[]) => {
        this.currentParticipants = value
          .map(({ id, optional }) => ({
            optional,
            participant: this.allParticipants.find((participant) => participant.id === id),
          }))
          .filter(({ participant }) => !!participant);

        this.innerParticipants = [...this.currentParticipants];
      });

    this.searchControl.valueChanges.pipe(takeUntil(this.alive)).subscribe((value) => {
      if (!value) {
        return;
      }
      if (this.innerParticipants.find(({ participant: { id } }) => id === value)) {
        return;
      }

      const participant = this.allParticipants.find(({ id }) => id === value);
      this.innerParticipants.push({ participant, optional: true });

      timer(0).subscribe(() => this.searchControl.setValue(null));
    });

    this.controlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.controlChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openModal() {
    this.alertsService.show({
      appearance: 'sapphire-modal',
      title: 'Add Participants',
      template: this.modalTemplate,
      origin: this.elementRef.nativeElement,
      rightButtons: [
        {
          title: 'Cancel',
          close: true,
          appearance: 'sapphire-secondary',
          size: 's',
        },
        {
          title: 'Save',
          close: true,
          appearance: 'sapphire-primary',
          size: 's',
          click: () => this.handleSave(),
        },
      ],
    });
  }

  handleSave() {
    this.control.setValue(this.innerParticipants.map(({ participant: { id }, optional }) => ({ id, optional })));

    this.control.markAsDirty();
  }

  handleOptionalCheck(id: string) {
    this.innerParticipants = this.innerParticipants.map(({ participant, optional }) => ({
      participant,
      optional: participant.id === id ? !optional : optional,
    }));
  }

  handleRemove(participantId: string) {
    this.innerParticipants = this.innerParticipants.filter(({ participant: { id } }) => id !== participantId);
  }

  trackByParticipant(_index: number, item: ParticipantItem): string {
    return item.participant.id;
  }
}
