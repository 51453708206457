// Commons
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Modules
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { MatIconModule } from '@angular/material/icon';
import { PopoverModule } from '../popover/popover.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { CalendarCellContextMenuComponent } from './components/calendar-cell-context-menu/calendar-cell-context-menu.component';
import { CalendarMonthSmallCellComponent } from './components/calendar-month-small-cell/calendar-month-small-cell.component';
import { CalendarMonthSmallComponent } from './components/calendar-month-small/calendar-month-small.component';
import { CalendarMonthSmallDIComponent } from './components/calendar-month-small-di/calendar-month-small-di.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { CalendarDayComponent } from './components/calendar-day/calendar-day.component';
import { CalendarMonthComponent } from './components/calendar-month/calendar-month.component';
import { CalendarWeekComponent } from './components/calendar-week/calendar-week.component';
import { FullCalendarYearComponent } from './components/calendar-year/calendar-year.component';

// Directives
import { CalendarCellContextMenuDirective } from './directives/calendar-cell-context-menu.directive';

@NgModule({
  imports: [
    CommonModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatIconModule,
    PopoverModule,
    DragNDropModule,
    UtilsModule,
  ],
  declarations: [
    CalendarCellContextMenuComponent,
    CalendarCellContextMenuDirective,
    CalendarDayComponent,
    CalendarMonthComponent,
    CalendarMonthSmallCellComponent,
    CalendarMonthSmallComponent,
    CalendarMonthSmallDIComponent,
    CalendarWeekComponent,
    EventsListComponent,
    FullCalendarYearComponent,
  ],
  exports: [
    CalendarDayComponent,
    CalendarMonthComponent,
    CalendarMonthSmallDIComponent,
    CalendarWeekComponent,
    FullCalendarYearComponent,
  ],
})
export class FullCalendarModule {}
