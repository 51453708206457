// Common
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';

// Types
import { Knot } from '@modules/knots/types/knot';

// RX
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-item-knots',
  templateUrl: './list-item-knots.component.html',
  styleUrls: ['./list-item-knots.component.less'],
  standalone: false,
})
export class ListItemKnotsComponent implements AfterViewInit, OnDestroy {
  // Input
  @Input() knots: Knot[] = [];

  // View Children
  @ViewChildren('knot') knotsRefs: QueryList<ElementRef>;

  // Public
  public displayCount = 0;
  public more = false;

  // Private
  private childrenChanged = new Subject<ElementRef[]>();
  private sizeChanged = new BehaviorSubject<number>(0);
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(private changeDetector: ChangeDetectorRef) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    combineLatest([this.childrenChanged, this.sizeChanged])
      .pipe(takeUntil(this.alive))
      .subscribe(([children, size]) => {
        const { count } = children
          .map((child) => child.nativeElement.offsetWidth)
          .reduce(
            (memo, item) => {
              if (memo.accumulatedWidth + item > size - 20) {
                // 20px size of more-icon
                return memo;
              } else {
                return {
                  count: memo.count + 1,
                  accumulatedWidth: memo.accumulatedWidth + item,
                };
              }
            },
            { count: 0, accumulatedWidth: 0 },
          );

        this.displayCount = count;
        this.more = this.displayCount !== this.knots.length;
        this.changeDetector.detectChanges();
      });

    this.childrenChanged.next(this.knotsRefs.toArray());

    this.knotsRefs.changes.pipe(takeUntil(this.alive)).subscribe(() => {
      this.childrenChanged.next(this.knotsRefs.toArray());
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleResize([width, _height]: [number, number]) {
    this.sizeChanged.next(width);
  }
}
