// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { BoardService } from '@modules/tasks/services/board.service';

// RX
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Types
import { Column } from '@modules/tasks/types/column';

@Component({
  selector: 'app-project-board-column-context-menu',
  templateUrl: './column-context-menu.component.html',
  styleUrls: ['./column-context-menu.component.less'],
  standalone: false,
})
export class ColumnContextMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() column: Column;

  public columnFormGroup: UntypedFormGroup;

  private colorChanges = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private ga: GoogleAnalyticsService,
    private boardService: BoardService,
  ) {
    this.colorChanges
      .pipe(
        switchMap(() => this.columnFormGroup.valueChanges),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.boardService.updateColumn(Column.fromFormGroup(this.columnFormGroup));
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('column' in changes) {
      this.reset();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  delete() {
    if (this.column.uncategorized) {
      return;
    }
    this.ga.trackEvent('column-context-menu', 'delete-permanently');
    this.boardService.removeColumn(this.column);
  }

  addLeft() {
    this.boardService.addColumn(false, this.column.position);
  }

  addRight() {
    this.boardService.addColumn(true, this.column.position);
  }

  moveLeft() {
    this.boardService.moveColumn(this.column, -1);
  }

  moveRight() {
    this.boardService.moveColumn(this.column, 1);
  }

  handleCompleted() {
    this.columnFormGroup.controls.completed.setValue(!this.columnFormGroup.controls.completed.value);
  }

  private reset() {
    this.columnFormGroup = this.column.asFormGroup();
    this.colorChanges.next();
  }
}
