// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.less'],
  standalone: false,
})
export class WelcomeComponent {
  // Public
  public activeForm: 'signup' | 'signin' = 'signup';
}
