// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateKey } from '@modules/settings/types/state-key';
import { type Folder } from './folder';

// Services
import { SortBy } from '@modules/common/types/SortBy';
import { SortOrder } from '@modules/common/types/sort-order';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export type FoldersListStateSortField = 'title' | 'createdAt' | 'updatedAt' | 'score';

export class FoldersListState extends BaseStitchListState<Folder, FoldersListStateSortField> {
  @OnChange('onPropertyChange') public override sortBy?: FoldersListStateSortField;
  @OnChange('onPropertyChange') today?: boolean;

  constructor({
    searchService,
    stateService,
    stateKey,
    withAdvanced,
    defaultSort = { by: 'createdAt', order: SortOrder.DESC },
  }: {
    searchService?: AdvancedSearchService;
    stateService?: StateService;
    stateKey?: StateKey;
    withAdvanced?: boolean;
    defaultSort?: SortBy<FoldersListStateSortField>;
  }) {
    super({ searchService, stateService, stateKey, withAdvanced, defaultSort });
  }

  protected override applySavedState(state?: Like<FoldersListState>) {
    super.applySavedState(state);
    this.today = !!state?.today;
  }

  public override onPropertyChange(attribute) {
    if ((attribute === 'from' && this.from) || (attribute === 'to' && this.to)) {
      this.today = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
    }

    super.onPropertyChange(attribute);
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today,
    });
  }

  public override isDefault(): boolean {
    return super.isDefault() && !this.today;
  }

  protected override syncFromAS({ files }: AdvancedSearchState) {
    this.stitchedWith = files.stitched;
    this.from = files.dateRangeFrom;
    this.to = files.dateRangeTo;
  }

  protected override syncToAS(): void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.files;

    state.stitched = this.stitchedWith;
    state.dateRangeFrom = this.from;
    state.dateRangeTo = this.to;

    this.searchService.setState(searchState);
  }
}
