<app-context-menu-item
  [withCheckOption]="withCheckOption"
  [title]="title"
  [active]="state | someTrue"
  [disabled]="disabled"
>
  <app-context-menu-item
    title="All Items"
    [active]="state | allPropertiesTrue"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="selectAll()"
  />

  <app-context-menu-item
    title="Message Folders"
    [active]="state?.[StitchType.messageFolder]"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.messageFolder)"
  />

  <app-context-menu-item
    title="Messages"
    [active]="state?.message"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.message)"
  />

  <app-context-menu-item
    title="Events"
    [active]="state?.event"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.event)"
  />

  <app-context-menu-item
    title="Projects"
    [active]="state?.project"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.project)"
  />

  <app-context-menu-item
    title="Tasks"
    [active]="state?.task"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.task)"
  />

  <app-context-menu-item
    title="Notebooks"
    [active]="state?.notebook"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.notebook)"
  />

  <app-context-menu-item
    title="Notes"
    [active]="state?.note"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.note)"
  />

  <app-context-menu-item
    title="Groups"
    [active]="state?.group"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.group)"
  />

  <app-context-menu-item
    title="Contacts"
    [active]="state?.contact"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.contact)"
  />

  <app-context-menu-item
    title="Folders"
    [active]="state?.folder"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.folder)"
  />

  <app-context-menu-item
    title="File"
    [active]="state?.file"
    icon="checkbox-off"
    activeIcon="checkbox-on"
    (execute)="select(StitchType.file)"
  />
</app-context-menu-item>
