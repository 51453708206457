// Common
import { Component, Injector } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-stach-event-shared',
  templateUrl: './event-shared.component.html',
  styleUrls: ['./event-shared.component.less'],
  standalone: false,
})
export class EventSharedComponent extends SharedBaseComponent<CalendarEvent> {
  protected stitchType = StitchType.event;

  constructor(injector: Injector) {
    super(injector);
  }

  get event() {
    return this.stitchItem;
  }
}
