// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Tag } from '../types/tag';
import { ManageListState } from '../types/manage-list-state';

@Pipe({
  name: 'sortAndFilter',
  standalone: false,
})
export class SortAndFilterPipe implements PipeTransform {
  transform(tags: Tag[] = [], state: ManageListState): Tag[] {
    return tags
      .filter(
        (tag) =>
          !tag.deleted &&
          (!state?.filters?.createdFrom || tag.createdAt >= state.filters.createdFrom) &&
          (!state?.filters?.createdTo || tag.createdAt <= state.filters.createdTo),
      )
      .sort((a, b) => {
        const reverse = state?.sort?.order === 'desc' ? -1 : 1;

        switch (state?.sort?.by) {
          case 'date':
            return (a.createdAt > b.createdAt ? 1 : -1) * reverse;
          case 'name':
          case 'frequency':
            return (a.name > b.name ? 1 : -1) * reverse;
        }
      });
  }
}
