// Common
import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@modules/common/utils/base';

// Types
import { SortOrder } from '@modules/common/types/sort-order';

@Pipe({
  name: 'sortBy',
  standalone: false,
})
export class SortByPipe implements PipeTransform {
  transform(list: object[], property: string, order: SortOrder = 'asc'): object[] {
    if (!Array.isArray(list)) {
      return [];
    }

    return [...list].sort((a: object, b: object) => {
      const orderFactor = order === 'asc' ? -1 : 1;

      if (isNil(a[property])) {
        return 1;
      } else if (isNil(b[property])) {
        return -1;
      } else if (a[property] < b[property]) {
        return orderFactor;
      } else if (a[property] > b[property]) {
        return -1 * orderFactor;
      } else {
        return 0;
      }
    });
  }
}
