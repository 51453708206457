import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-input-side-options',
  templateUrl: './input-side-options.component.html',
  styleUrls: ['./input-side-options.component.less'],
  standalone: false,
})
export class InputSideOptionsComponent {
  // Inputs
  @Input() sideFormControl: UntypedFormControl;
  @Input() sideOptions: DropdownSelectItem<unknown>[];

  // public
  public popoverHide = new Subject<void>();

  handleSelect(option: DropdownSelectItem<unknown>) {
    this.sideFormControl.setValue(option.value);
    this.popoverHide.next();
  }
}
