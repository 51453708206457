<div
  *ngIf="!item"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-tasks.svg" alt="icon for empty tasks view" />
</div>

<ng-container
  *ngIf="item"
  [ngSwitch]="item | stitchType"
>
  <app-task-form
    *ngSwitchCase="StitchType.task"
    [task]="item"
    (close)="handleFormClose()"
  />

  <app-project-form
    *ngSwitchCase="StitchType.project"
    [project]="item"
    (close)="handleFormClose()"
  />
</ng-container>
