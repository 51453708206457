<div class="header">
  <div class="header-title">
    <ng-container *ngTemplateOutlet="tabs" />
  </div>

  <div class="header-separator"></div>

  <div
    class="debug"
    [class.active]="debug"
    (click)="doDebug()"
  >
    {{ debug || 'Debug' }}
  </div>

  <app-context-menu-state-indicator
    class="header-filters-icon"
    svgIcon="filters"
    appKnotsManageContextMenu
    [appKnotsManageContextMenuNeighbours]="true"
    [appKnotsManageContextMenuStateKey]="'kp-knots'"
    (appKnotsManageContextMenuStateChange)="setState($event)"
  />
</div>

@if (neighborsKnots.length === 0) {
  <app-no-content-stub imageSrc="assets/placeholders/placeholder-pellets.svg">
    @if (!stitchItem) {
      Select item to see related knots
    } @else {
      No related knots
    }
  </app-no-content-stub>
}

<div
  *ngIf="stitchItem"
  class="list"
>
  <div class="knots">
    <div
      *ngFor="let knot of (neighborsKnots | slice : 0 : (page + 1) * perPage)"
      class="knots-debug"
    >
      <div *ngIf="debug === 'score'" class="knots-debug-text">{{ knot?.score?.toFixed(4) }}</div>
      <div *ngIf="debug === 'frequency'" class="knots-debug-text">{{ knot?.frequency }}</div>
      <div *ngIf="debug === 'recency'" class="knots-debug-text">{{ knot?.recency | date : 'short' }}</div>
      <div *ngIf="debug === 'createdAt'" class="knots-debug-text">{{ knot?.createdAt | date : 'short' }}</div>
      <span
        class="knots-item"
        (click)="handleClickItem(knot, $event)"
      >
        {{ knot?.name }}
      </span>
    </div>

    <div
      *ngIf="page !== 0"
      class="more-less"
      (click)="showLess()"
    >
      &lt; Less
    </div>

    <div
      *ngIf="page < pagesCount - 1"
      class="more-less"
      (click)="showMore()"
    >
      More >
    </div>
  </div>
</div>
