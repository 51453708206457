<div
  *ngIf="event"
  class="event"
  [class.no-actions]="!withActions"
  [class.pinned]="event.pinned"
  [class.short-view]="viewType === 'short'"
  [class.with-description]="event.description"
  [class.with-linked-info]="event.linkedInfo?.length"

  [appEventContextMenu]="event"
  [appEventContextMenuDisabled]="!withContextMenu"
  (appEventContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [event], type: 'event'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="event-body">
    <div class="event-body-heading">
      <div class="event-body-heading-title">
        {{ event.title }}
      </div>
    </div>

    <div class="event-body-date">
      <div
        *ngIf="event?.startTime"
        class="event-body-date-item"
        [class.today]="event?.startTime | includesNow : event?.endTime"
      >
        {{ event?.startTime | date : 'MMM d' }}
        {{ !event?.allDay ? ((event?.startTime | date : ', h:mm aaa') | lowercase) : '' }}
      </div>

      <div class="event-body-date-separator"></div>

      <div
        class="event-body-date-item"
        *ngIf="event?.startTime && event?.endTime"
        [class.today]="event?.startTime | includesNow : event?.endTime"
      >
        {{ (event?.startTime | sameDay : event?.endTime) ? '' : (event?.endTime | date : 'MMM d') }}
        {{ (event?.startTime | sameDay : event?.endTime) ? '' : ', ' }}
        {{ !event?.allDay ? ((event?.endTime | date : 'h:mm aaa') | lowercase) : 'All Day' }}
      </div>
    </div>

    <div class="event-body-description" *ngIf="event.description && viewType !== 'short'">
      {{event.description}}
    </div>

    <app-link-info-bar
      class="event-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="event"
    />
  </div>

  <mat-icon
    class="event-pin-icon"
    [class.active]="event.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  />

  <mat-icon
    class="event-flag-icon"
    [class.active]="event.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  />

  <mat-icon class="event-marker" svgIcon="event" />
  <mat-icon class="event-attachments-icon" *ngIf="event.files?.length" svgIcon="attachment" />

  <div
    class="event-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="event"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    />
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="archive"
      [class.active]="event.archived"
      [matTooltip]="event?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    />
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="trash"
      [class.active]="event.deleted"
      [matTooltip]="event?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div
    class="event-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="trash"
      [class.active]="event.deleted"
      [matTooltip]="event?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div class="event-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="event-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    />
  </div>

  <div class="drop-zone"></div>
</div>

<!-- TODO waiting for design -->
<div
  class="event placeholder"
  *ngIf="!event"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-event
    [event]="event"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  />
</ng-template>
