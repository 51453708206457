<div class="forgot-password">
  <ng-container *ngIf="!requested">
    <div class="heading">Forgot password?</div>
    <img class="image" src="assets/logo/welcome.png" alt="img"/>
    <div class="heading3">Enter your email and we'll send you the instruction to your recovery email</div>

    <app-input
      class="input"
      [inputFormControl]="emailFormControl"
      placeholder="Enter Email"
    />

    <app-button
      appearance="purple"
      label="Restore"
      (click)="handleSubmit()"
    />
  </ng-container>

  <ng-container *ngIf="requested">
    <span class="heading">Check your recovery email</span>
    <img class="image" src="assets/logo/welcome.png" alt="img"/>
  </ng-container>
</div>

