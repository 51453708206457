// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-context-menu-heading',
  templateUrl: './context-menu-heading.component.html',
  styleUrls: ['./context-menu-heading.component.less'],
  standalone: false,
})
export class ContextMenuHeadingComponent {}
