// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

// Types
import { TimeZone, timezones } from '../../types/timezones';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { ButtonAppearance } from '@modules/form-controls/types/button-appearance';

// RX
import { Subject, of } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

@Component({
  selector: 'app-timezone-input',
  templateUrl: './timezone-input.component.html',
  styleUrls: ['./timezone-input.component.less'],
  standalone: false,
})
export class TimeZoneInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() appearance: 'default' | 'sapphire' = 'default';
  @Input() buttonAppearance: ButtonAppearance = 'sapphire-primary-ghost';
  @Input() buttonSize: 'small' | 'normal' | 'medium' | 'xs' | 's' | 'm' | 'l' = 'xs';
  @Input() disabled = false;

  public suggestions: AutocompleteFactory<TimeZone>;
  public popoverHide = new Subject<void>();
  public currentOption: DropdownSelectItem<TimeZone>;
  public defaultTimeZone = timezones.find(({ value }) => value === CURRENT_TIMEZONE) || {
    title: CURRENT_TIMEZONE,
    value: CURRENT_TIMEZONE,
  };
  public searchControl = new FormControl<string>(null);
  public options: DropdownSelectItem<TimeZone>[];

  private alive = new Subject<void>();
  private inputControlChanged = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestions = (title?: string, values?: string[], _options: { limit: number } = { limit: 5 }) => {
      return of(
        timezones.filter(
          ({ title: optionTitle, value: optionValue }) =>
            optionTitle.includes(title) || (values || []).includes(optionValue),
        ),
      );
    };

    this.inputControlChanged
      .pipe(
        filter(() => this.appearance === 'sapphire'),
        switchMap(() => (this.control ? this.control.valueChanges.pipe(startWith(this.control.value)) : of(null))),
        takeUntil(this.alive),
      )
      .subscribe((value) => {
        this.currentOption = timezones.find((zone) => zone.value === value) || { title: value, value };
      });

    this.inputControlChanged.next();

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        map((query) => query.trim().toLowerCase()),
        takeUntil(this.alive),
      )
      .subscribe((query) => {
        this.options = timezones.filter(
          ({ title, value }) => title.toLowerCase().includes(query) || value.toLowerCase().includes(query),
        );
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(option: DropdownSelectItem<TimeZone>) {
    if (this.disabled || option.disabled) {
      return;
    }

    this.control?.setValue(option.value);
    this.popoverHide.next();
  }
}
