// Common
import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  NgZone,
  Output,
  EventEmitter,
  Self,
  Inject,
} from '@angular/core';

// RX
import { interval, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { FilesAppStateService } from '@modules/files/services/state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { FilesService } from '@modules/files/services/files.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { FilesFilters } from '@modules/files/types/files-filters';
import { File } from '@modules/files/types/file';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class FilesListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {
  // Inputs
  @Input() placeholderText = 'You have no files';
  @Input() filters: Like<FilesFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  // Outputs
  @Output() clickFile = new EventEmitter<File>();
  @Output() openFile = new EventEmitter<File>();
  @Output() doubleClickFile = new EventEmitter<File>();

  // Public
  public itemHeight = 94;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  constructor(
    private filesService: FilesService,
    private filesStateService: FilesAppStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject,
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter((value) => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.filesStateService.getRefreshAll(),
      this.linkedInfoService.getRefreshRequired(),
      interval(environment.messageFetchInterval),
      this.filesService.getRefreshRequired(),
    )
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    merge(this.filesStateService.getRefreshAll(), this.filesService.getRefreshRequired())
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: File[]; count: number }> {
    return this.filesService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(file: File) {
    this.doubleClickFile.emit(file);
  }

  handleClickItem(file: File) {
    this.clickFile.emit(file);
  }

  compareItems(item1: object, item2: object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
