// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// Services
import { SessionsService } from '@modules/account/services/sessions.service';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Session } from '@modules/account/types/session';

@Component({
  selector: 'app-settings-general-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.less'],
  standalone: false,
})
export class SecurityComponent implements OnInit, OnDestroy {
  // Public
  public sessions: Session[];

  public form = new UntypedFormGroup({
    alertsEnabled: new UntypedFormControl(true),
    allowedLoginAttempts: new UntypedFormControl(10),
    incorrectLoginDelay: new UntypedFormControl(15),
    incorrectLoginDelayUnits: new UntypedFormControl('minutes'),
  });

  public loginDelayUnits: DropdownSelectItem<unknown>[] = [
    { title: 'minutes', value: 'minutes' },
    { title: 'hours', value: 'hours' },
  ];

  // Private
  private alive = new Subject<void>();

  constructor(private sessionsService: SessionsService) {}

  ngOnInit() {
    this.sessionsService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: sessions }) => (this.sessions = sessions));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleDestroy(session) {
    this.sessionsService.destroySession(session);
  }

  handleDestroyOther() {
    this.sessionsService.destroyOtherSessions();
  }
}
