import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'first',
  standalone: false,
})
export class FirstPipe implements PipeTransform {
  transform(array?: unknown[]): unknown {
    return array?.[0];
  }
}
