// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Services
import { SpaceSettingsService } from '@modules/account/services/space-settings.service';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { SpaceSettings } from '@modules/account/types/space-settings';

// RX
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-settings-general-time-date',
  templateUrl: './time-date.component.html',
  styleUrls: ['./time-date.component.less'],
  standalone: false,
})
export class TimeDateComponent implements OnInit, OnDestroy {
  public form = new SpaceSettings().asFormGroup();

  public timeFormatOptions: DropdownSelectItem<unknown>[] = [
    { title: '12H Format', value: '12' },
    { title: '24H Format', value: '24' },
  ];

  public dateFormatOptions: DropdownSelectItem<unknown>[] = [
    { title: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
    { title: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
    { title: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
  ];

  private alive = new Subject<void>();

  constructor(private settingsService: SpaceSettingsService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.settingsService
      .listAll(true)
      .pipe(
        tap((settings) => {
          this.form = settings.asFormGroup();
        }),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() => this.settingsService.update(SpaceSettings.fromFormGroup(this.form))),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
