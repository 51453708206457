// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Tab } from '@modules/common/types/tab';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { FilesFilters } from '@modules/files/types/files-filters';
import { FilesListState } from '@modules/files/types/files-list-state';
import { Folder } from '@modules/files/types/folder';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// Services
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesAppStateService } from '@modules/files/services/state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateKey } from '@modules/settings/types/state-key';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.filesFFListState]?: FilesListState;
  }
}

@Component({
  selector: 'app-folder-form',
  templateUrl: './folder-form.component.html',
  styleUrls: ['./folder-form.component.less'],
  standalone: false,
})
export class FolderFormComponent extends FullFormBaseComponent<Folder> implements OnInit {
  public changesKey = 'folder';
  public tabs: Tab[] = [
    { title: 'Folder', value: 'folder' },
    { title: 'Details', value: 'details' },
    { title: 'Attachments', value: 'attachments' },
    { title: 'Stitch', value: 'stitch' },
    { title: 'Sections', value: 'sections' },
    { title: 'Lab', value: 'lab' },
    { title: 'Comments', value: 'comments' },
  ];
  tabsStateKey = 'ffFolder';
  public currentSidebarFilter: VirtualFolder;
  public filesListStateKey: StateKey = StateKey.filesFFListState;
  public filesListState = new BehaviorSubject<FilesListState>(null);
  public parentSuggestions: AutocompleteFactory<Folder>;
  public filesFilters: FilesFilters;

  protected dragDataType = DragDataTypes.folder;

  @Input() folder = new Folder();

  constructor(
    private foldersService: FoldersService,
    private searchService: AdvancedSearchService,
    private stateService: FilesAppStateService,
    injector: Injector,
  ) {
    super(injector, foldersService, stateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stateService
      .getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe((folder) => (this.currentSidebarFilter = folder));

    this.stitchItemChanged.pipe(takeUntil(this.alive)).subscribe(() => {
      this.parentSuggestions = this.foldersService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
    });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.filesListState),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.filesFilters = new FilesFilters({ containersIds: [this.stitchItem.id] });
        this.filesFilters.applyListState(this.filesListState.getValue());
      });
  }

  /**
   * Actions
   */

  handleFilesListStateChange(state: FilesListState) {
    this.filesListState.next(state);
  }

  /**
   * Helpers
   */

  get stitchItem(): Folder {
    return this.folder;
  }

  protected shouldRefreshList(prev, current) {
    return Folder.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Folder {
    return Folder.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.folder.asFormGroup(this.foldersService);
  }
}
