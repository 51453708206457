// Common
import { Injectable } from '@angular/core';
import { Stitch } from '@modules/common/types/stitch';

// Types
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { SelectionParams } from '@modules/knowledge/types/selection-params';
import { HistoryService, StitchFiltersHistoryRecord } from '@modules/common/services/history.service';

// RX
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class KnowledgePanelService {
  private readonly selectedStitchItem = new BehaviorSubject<Stitch>(null);
  private readonly stitchItemKnots = new BehaviorSubject<Knot[]>([]);
  private readonly stitchItemTags = new BehaviorSubject<Tag[]>([]);
  private readonly stitchItemConnections = new BehaviorSubject<Connection[]>([]);

  private shiftSelectionStartIndex: number | null = null;

  constructor(private historyService: HistoryService) {}

  addKnotToSelection(knot: Knot, event: MouseEvent | null, resetHistory: boolean) {
    this.updateSelection({
      item: knot,
      event,
      resetHistory,
      historyKey: 'knots',
      allItems: this.stitchItemKnots.value,
    });
  }

  addTagToSelection(tag: Tag, event: MouseEvent | null, resetHistory: boolean) {
    this.updateSelection({
      item: tag,
      event,
      resetHistory,
      historyKey: 'tags',
      allItems: this.stitchItemTags.value,
    });
  }

  addConnectionToSelection(connection: Connection, event: MouseEvent | null, resetHistory: boolean) {
    this.updateSelection({
      item: connection,
      event,
      resetHistory,
      historyKey: 'connections',
      allItems: this.stitchItemConnections.value,
    });
  }

  setSelectedStitchItem(item: Stitch) {
    this.selectedStitchItem.next(item);
    this.historyService.resetHistory();
  }

  getSelectedStitchItem(): Observable<Stitch> {
    return this.selectedStitchItem.pipe(distinctUntilChanged());
  }

  getSelectedKnots(): Observable<Knot[]> {
    return this.historyService.onChanges().pipe(map((history) => history.knots));
  }

  getSelectedTags(): Observable<Tag[]> {
    return this.historyService.onChanges().pipe(map((history) => history.tags));
  }

  getSelectedConnections(): Observable<Connection[]> {
    return this.historyService.onChanges().pipe(map((history) => history.connections));
  }

  setStitchItemKnots(knots: Knot[]) {
    this.stitchItemKnots.next(knots);
  }

  setStitchItemTags(tags: Tag[]) {
    this.stitchItemTags.next(tags);
  }

  setStitchItemConnections(connections: Connection[]) {
    this.stitchItemConnections.next(connections);
  }

  getStitchItemKnots(): Observable<Knot[]> {
    return this.stitchItemKnots.asObservable();
  }

  getStitchItemTags(): Observable<Tag[]> {
    return this.stitchItemTags.asObservable();
  }

  getStitchItemConnections(): Observable<Connection[]> {
    return this.stitchItemConnections.asObservable();
  }

  private updateSelection<T extends Knot | Tag | Connection>({
    item,
    event,
    resetHistory,
    historyKey,
    allItems,
  }: SelectionParams<T>) {
    const isCtrlPressed = event?.ctrlKey || event?.metaKey;
    const isShiftPressed = event?.shiftKey;
    const currentIndex = allItems.indexOf(item);

    if (isShiftPressed) {
      if (this.shiftSelectionStartIndex === null) {
        this.shiftSelectionStartIndex = currentIndex;
      }

      const start = Math.min(this.shiftSelectionStartIndex, currentIndex);
      const end = Math.max(this.shiftSelectionStartIndex, currentIndex);

      const itemsToSelect = allItems.slice(start, end + 1);

      this.updateHistory(historyKey, itemsToSelect, resetHistory, false);
    } else {
      this.shiftSelectionStartIndex = currentIndex;

      if (isCtrlPressed) {
        this.updateHistory(historyKey, [item], false, true);
      } else {
        this.updateHistory(historyKey, [item], resetHistory, false);
      }
    }
  }

  private updateHistory<K extends keyof StitchFiltersHistoryRecord>(
    key: K,
    items: StitchFiltersHistoryRecord[K],
    reset: boolean,
    toggle: boolean,
  ) {
    const currentHistory = this.historyService.getCurrentHistory();
    const currentItems = currentHistory[key];

    let updatedItems: StitchFiltersHistoryRecord[K];

    if (reset) {
      updatedItems = items;
    } else if (toggle) {
      updatedItems = currentItems.some((item) => items.includes(item))
        ? currentItems.filter((item) => !items.includes(item))
        : [...currentItems, ...items];
    } else {
      updatedItems = [...currentItems, ...items];
    }

    this.historyService.updateHistory({ ...currentHistory, [key]: updatedItems });
  }
}
