// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { Group } from '@modules/contacts/types/group';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { GroupsService } from '@modules/contacts/services/groups.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-link-info-group',
  templateUrl: './link-info-group.component.html',
  styleUrls: ['./link-info-group.component.less'],
  standalone: false,
})
export class LinkInfoGroupComponent {
  // Inputs
  @Input() group: Group;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.group &&
    !(
      dragData.type === DragDataTypes.group &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.group.id
    ) &&
    dragDataTypeAllowed(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private groupsService: GroupsService,
    private stitchService: StitchService,
  ) {}

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.groupsService
      .flag({ ids: [this.group.id] }, !this.group.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.group.flagged = !this.group.flagged));
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.groupsService
      .pin({ ids: [this.group.id] }, !this.group.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.group.pinned = !this.group.pinned));
  }

  archive() {
    this.groupsService
      .archive({ ids: [this.group.id] }, !this.group.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.group.archived = !this.group.archived));
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.group.deleted
        ? this.groupsService.deletePermanently({ ids: [this.group.id] })
        : this.groupsService.delete({ ids: [this.group.id] }, true);
    }
  }

  dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.group, dragData);
  }
}
