export const isNil = (object: any) => object === null || object === undefined;

/**
 * generate groups of 4 random characters
 * @example uuid() : 607f
 * @example uuid(2) : 95ca-361a-f8a1-1e73
 */
export function uuid(parts: number = 1): string {
  const stringArr = [];
  for (let i = 0; i < parts; i++) {
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}

export function simpleObjectHash(obj: any): string {
  const jsonString = JSON.stringify(obj || {});

  let hash = 0;

  for (let i = 0; i < jsonString.length; i++) {
    const char = jsonString.charCodeAt(i);
    hash = (hash << 5) - hash + char; // (hash * 32) - hash + char
    hash |= 0; // prevent 32 bit overflow
  }

  return hash.toString();
}
