// Common
import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';

// RX
import { Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-agenda-event',
  templateUrl: './agenda-event.component.html',
  styleUrls: ['./agenda-event.component.less'],
  standalone: false,
})
export class AgendaEventComponent implements OnInit, OnChanges {
  public selected = false;
  public dragData: { data: CalendarEvent[]; type: DragDataTypes.event };

  private changed = new Subject<void>();
  private alive = new Subject<void>();

  @Input() event: CalendarEvent;
  @Input() position: number;

  constructor(@Optional() private selectableService: SelectableService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectableService &&
      this.changed
        .pipe(
          startWith(() => null),
          switchMap(() => this.selectableService.getSelected(this.position)),
          takeUntil(this.alive),
        )
        .subscribe((selected) => {
          this.selected = selected;
        });

    this.selectableService &&
      this.changed
        .pipe(
          startWith(() => null),
          switchMap(() => this.selectableService.getDragData(this.event, this.position)),
          takeUntil(this.alive),
        )
        .subscribe((data: CalendarEvent[]) => {
          this.dragData = { data, type: DragDataTypes.event };
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('event' in changes) {
      this.changed.next();
    }
  }
}
