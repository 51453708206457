import { PopupSize } from './../../popup/types/popup-size';

export class ModalFrame {
  width?: string;
  height?: string;
  x?: string;
  y?: string;

  constructor(width?: string, height?: string, x?: string, y?: string) {
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
  }

  public popupSize?(): PopupSize {
    return {
      width: Number(this.width),
      height: Number(this.height),
    };
  }
}
