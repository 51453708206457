import { environment as prodEnvironment } from './environment.prod';

export const environment = {
  ...prodEnvironment,
  // This needs to be replaced later on by
  // a post-pocessing hook in the built javascript file.
  // Right now we do this while running the Docker image
  baseUrl: 'https://api-ovh-dev1.infra.betastack.com',
  authDomains: ['api-ovh-dev1.infra.betastack.com'],
};
