// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Types
import { BaseAppAdvancedFilters } from './base-app-advanced-filters';
import { Participant } from '@modules/messages/types/participant';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }] });

export class CalendarAppAdvancedFilters extends BaseAppAdvancedFilters {
  formBuilder = injector.get(UntypedFormBuilder);

  attendees?: Pick<Participant, 'name' | 'address'>[];
  body?: string;
  fromDate?: Date;
  location?: number;
  subject?: string;
  timeZone?: string;
  toDate?: Date;

  constructor(filters?: any) {
    super(filters);
    this.attendees = Array.isArray(filters?.attendees) ? filters.attendees : [];
    this.body = filters?.body || '';
    this.fromDate = filters?.fromDate ? new Date(filters.fromDate) : null;
    this.location = filters?.location || null; // TODO
    this.subject = filters?.subject || '';
    this.timeZone = filters?.timeZone || null;
    this.toDate = filters?.toDate ? new Date(filters.toDate) : null;
  }

  static fromFormGroup(form: UntypedFormGroup): CalendarAppAdvancedFilters {
    return new CalendarAppAdvancedFilters(this.getFormValues(form));
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...super.commonFormGroupFields(),
      attendees: [this.attendees],
      body: [this.body],
      fromDate: [this.fromDate],
      location: [this.location],
      subject: [this.subject],
      timeZone: [this.timeZone],
      toDate: [this.toDate],
    });
  }
}
