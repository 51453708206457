// Common
import { Injector } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// Types
import { OutboundMailStrategyType } from '@modules/admin/types/outboun-mail-strategy-type';
import { TypedFormGroup } from '@modules/common/utils/form';
import { Like } from '@modules/common/types/like';

interface AdminSettingsFormModel extends Like<Omit<Settings, 'outboundMailStrategy'>> {
  outboundMailStrategy?: boolean;
}
export type AdminSettingsFormGroup = FormGroup<TypedFormGroup<AdminSettingsFormModel>>;

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }] });

export class Settings {
  formBuilder = injector.get(UntypedFormBuilder);

  trialPeriod?: number;
  gracePeriod?: number;
  outboundMailStrategy?: OutboundMailStrategyType;

  constructor(data: Partial<Settings> = {}) {
    this.trialPeriod = data?.trialPeriod;
    this.gracePeriod = data?.gracePeriod;
    this.outboundMailStrategy = data?.outboundMailStrategy;
  }

  static fromFormGroup(form: UntypedFormGroup): Settings {
    const formValues = form.value;

    return new Settings({
      trialPeriod: formValues.trialPeriod,
      gracePeriod: formValues.gracePeriod,
      outboundMailStrategy: formValues.outboundMailStrategy
        ? OutboundMailStrategyType.SENDGRID
        : OutboundMailStrategyType.HARAKA,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      trialPeriod: [this.trialPeriod, [Validators.required, Validators.min(1)]],
      gracePeriod: [this.gracePeriod, [Validators.required, Validators.min(1)]],
      outboundMailStrategy: [this.outboundMailStrategy === OutboundMailStrategyType.SENDGRID],
    });
  }

  asPayloadJSON() {
    return {
      trialPeriod: this.trialPeriod,
      gracePeriod: this.gracePeriod,
      outboundMailStrategy: this.outboundMailStrategy,
    };
  }
}
