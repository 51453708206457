<div
  *ngIf="message"
  class="message"
  [class.no-actions]="!withActions"
  [class.pinned]="message.pinned"
  [class.short-view]="viewType === 'short'"
  [class.with-description]="message.snippet || message.highlightedSnippet"
  [class.with-linked-info]="message.linkedInfo?.length"

  [appMessageContextMenu]="message"
  [appMessageContextMenuDisabled]="!withContextMenu"
  (appMessageContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [message], type: 'message'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="message-body">
    <div class="message-body-heading">
      <div class="message-body-heading-title">
        {{ message.from?.[0]?.name || message.from?.[0]?.address || '(unknown)' }}
      </div>
    </div>
  
    <div class="message-body-heading">
      <div class="message-body-heading-title">
        {{ message.subject }}
      </div>
    </div>

    <app-message-snippet
      class="message-body-description"
      *ngIf="message.highlightedSnippet && viewType !== 'short'"
      [snippet]="message.highlightedSnippet"
    />

    <div class="message-body-description" *ngIf="message.snippet && viewType !== 'short'">
      {{ message.snippet }}
    </div>

    <app-link-info-bar
      class="message-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="message"
    />
  </div>

  <mat-icon
    class="message-pin-icon"
    [class.active]="message.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  />

  <mat-icon
    class="message-flag-icon"
    [class.active]="message.starred"
    svgIcon="mail-star"
    (click)="flag($event)"
  />

  <div class="message-marker">
    <mat-icon
      class="message-marker-reply-icon"
      *ngIf="message.replied"
      svgIcon="mail-reply"
    />
    <mat-icon
      class="message-marker-unread-icon"
      *ngIf="message.unread"
      svgIcon="mail-unread"
    />
    {{ message.date | relativeDate }}
    <mat-icon class="message-marker-icon" svgIcon="mail" />
  </div>

  <mat-icon class="message-attachments-icon" *ngIf="message.files?.length" svgIcon="attachment" />

  <div
    class="message-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="message-hover-icons-item"
      [class.active]="message.unread"
      svgIcon="mail"
      [matTooltip]="message.unread ? 'Mark read' : 'Mark unread'"
      (click)="unreadMessage()"
    />
    <mat-icon
      *ngIf="!message.snoozed"
      class="message-hover-icons-item"
      [class.active]="message.snoozed"
      svgIcon="snooze"
      (click)="snoozeMessage()"
    />
    <mat-icon
      *ngIf="message.snoozed"
      class="message-hover-icons-item"
      [class.active]="message.snoozed"
      svgIcon="snooze"
      matTooltip="Remove snoozed"
      (click)="removeSnoozeMessage()"
    />
    <mat-icon
      *ngIf="!message.followed"
      class="message-hover-icons-item"
      [class.active]="message.followed"
      svgIcon="followup"
      matTooltip="Follow up by tomorrow"
      (click)="followUpMessage()"
    />
    <mat-icon
      *ngIf="message.followed"
      class="message-hover-icons-item"
      [class.active]="message.followed"
      svgIcon="followup"
      matTooltip="Remove follow up"
      (click)="removeFollowUpMessage()"
    />
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="message"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    />
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="archive"
      [class.active]="message.archived"
      [matTooltip]="message?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    />
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="trash"
      [class.active]="message.deleted"
      [matTooltip]="message?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div
    class="message-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="trash"
      [class.active]="message.deleted"
      [matTooltip]="message?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div class="message-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="message-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    />
  </div>

  <div class="drop-zone"></div>
</div>

<div
  class="message placeholder"
  *ngIf="!message"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-message
    [message]="message"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  />
</ng-template>
