<ng-container *ngTemplateOutlet="template; context: { history: connectionsHistory, type: 'connections' }" />
<ng-container *ngTemplateOutlet="template; context: { history: knotsHistory, type: 'knots' }" />
<ng-container *ngTemplateOutlet="template; context: { history: tagsHistory, type: 'tags' }" />

<ng-template #template let-history="history" let-type="type">
  <div
    *ngIf="history?.length"
    class="history items-center"
  >
    <div class="text-sm font-medium text-gray-950 mr-2">{{ type | titlecase }}:</div>

    <div
      *ngFor="let item of history; let index = index; let last = last"
      class="items-center history-item text-sm font-medium mr-2"
      (click)="handleClickHistoryItem($event, type, index)"
    >
      {{ item.name }}
      <mat-icon
        class="history-item-close"
        svgIcon="close-small"
        (click)="removeHistoryItem(type, index)"
      />
      <span *ngIf="!last"> | </span>
    </div>
  </div>
</ng-template>
