import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { contextMenuStateIndicator } from '@modules/popover/types/context-menu-state-indicator';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-context-menu-state-indicator',
  templateUrl: './context-menu-state-indicator.component.html',
  styleUrls: ['./context-menu-state-indicator.component.less'],
  providers: [{ provide: contextMenuStateIndicator, useFactory: () => new BehaviorSubject<boolean>(null) }],
  standalone: false,
})
export class ContextMenuStateIndicatorComponent implements OnInit, OnDestroy {
  public isDefault: boolean;

  private alive = new Subject<void>();

  @Input() svgIcon: string;
  @Input() appearance: 'amethyst' | 'sapphire' = 'amethyst';

  constructor(@Inject(contextMenuStateIndicator) private isDefaultStateObservable) {}

  public ngOnInit() {
    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefaultState) => {
      this.isDefault = isDefaultState;
    });
  }

  public ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  public handleReset(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isDefaultStateObservable.next(true);
  }
}
