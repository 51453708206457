// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';

// Subject
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-full-calendar-month',
  templateUrl: './full-calendar-month.component.html',
  styleUrls: ['./full-calendar-month.component.less'],
  standalone: false,
})
export class FullCalendarMonthComponent implements OnInit, OnChanges, OnDestroy {
  public agenda = false;
  public size: 's' | 'm' | 'l';
  public calendarEvents: AngularCalendarEvent[] = [];
  public displayEvents: CalendarEvent[] = [];
  public selectedDateChanged = new Subject<void>();

  private alive = new Subject<void>();

  @Input() viewDate: Date;
  @Input() selectedDate: Date;
  @Input() events: CalendarEvent[] = [];

  @Output() dateClicked = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked = new EventEmitter<Date>();
  @Output() eventDropped = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents = new EventEmitter<Date>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectedDateChanged.pipe(debounceTime(600), takeUntil(this.alive)).subscribe(() => {
      const beginning = beginningOfDay(this.selectedDate);
      const end = endOfDay(this.selectedDate);

      this.displayEvents = this.events.filter(
        ({ startTime, endTime }) =>
          (startTime > beginning && startTime < end) ||
          (endTime > beginning && endTime < end) ||
          (startTime < beginning && endTime > end),
      );
    });

    this.selectedDateChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('events' in changes) {
      this.mapEvents();
      this.selectedDateChanged.next();
    }

    if ('selectedDate' in changes) {
      this.selectedDateChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  mapEvents() {
    this.calendarEvents = (this.events || []).map((event) => event.asAngularCalendarEvent());
  }

  handleResize([width]) {
    if (width < 538) {
      this.size = 's';
    } else if (width < 800) {
      this.size = 'm';
    } else {
      this.size = 'l';
    }

    this.agenda = width < 800;
  }
}
