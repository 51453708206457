// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

// Services
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { Signature } from '@modules/account/types/signature';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { SignaturesService } from '@modules/account/services/signatures.service';
import { SignatureFormComponent } from '@modules/settings/components/messages/signatures/signature-form/signature-form.component';

@Component({
  selector: 'app-settings-messages-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.less'],
  standalone: false,
})
export class SignaturesComponent implements OnInit, OnDestroy {
  public signatures: Signature[];

  private alive = new Subject<void>();

  constructor(
    private signaturesService: SignaturesService,
    private modalService: ModalService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.signaturesService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: signatures }) => (this.signatures = signatures));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleRemove(signature: Signature) {
    this.signaturesService.delete(signature);
  }

  handleOpenForm(signature?: Signature) {
    this.modalService.showModal({
      component: SignatureFormComponent,
      content: { signature, invertedColor: true, singleButton: false, withCloseToken: true },
      appearance: 'default',
      title: signature?.id ? 'Update Signature' : 'Create Signature',
      maxModals: 1,
      injector: this.injector,
    });
  }
}
