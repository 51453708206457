// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Icon } from '@modules/icons/types/icons';

// RX
import { Subject, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.less'],
  standalone: false,
})
export class RadioButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputFormControl: UntypedFormControl;
  @Input() value: any;
  @Input() appearance: 'checkbox' | 'button' | 'radio' | 'sapphire' = 'button';
  @Input() title: string;
  @Input() icon: string;
  @Input() booleanValue: boolean; // only for multiple = false
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';
  @Input() width: number; // only for 'button'

  public checked = false;
  public iconOn: Icon;
  public iconOff: Icon;

  private inputsChanged = new Subject<void>();
  private alive = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputsChanged
      .pipe(
        switchMap(() => this.inputFormControl?.valueChanges || of(null)),
        startWith(this.inputFormControl?.value),
        takeUntil(this.alive),
      )
      .subscribe((value) => {
        this.checked = this.multiple
          ? (value || []).includes(this.value)
          : this.booleanValue
            ? value
            : value === this.value;
      });

    this.inputsChanged.next(); // ngOnChanges called before ngOnInit
    this.setIcons(); // ngOnChanges not called when default appearance
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('booleanValue' in changes || 'inputFormControl' in changes || 'value' in changes || 'multiple' in changes) {
      this.inputsChanged.next();
    }

    if ('appearance' in changes) {
      this.setIcons();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClick() {
    if (this.disabled) {
      return;
    }

    if (this.multiple) {
      const value = this.inputFormControl.value || [];

      this.inputFormControl.setValue(
        value.includes(this.value) ? value.filter((v) => v !== this.value) : [...value, this.value],
      );
    } else if (this.booleanValue) {
      this.inputFormControl.setValue(!this.inputFormControl.value);
    } else {
      if (this.inputFormControl.value !== this.value) {
        this.inputFormControl.setValue(this.value);
      }
    }

    this.inputFormControl.markAsDirty();
  }

  setIcons() {
    switch (this.appearance) {
      case 'checkbox':
        this.iconOn = 'checkbox-on';
        this.iconOff = 'checkbox-off';
        break;
      case 'radio':
        this.iconOn = 'radio-button-on';
        this.iconOff = 'radio-button-off';
        break;
      case 'sapphire':
        this.iconOn = 'sapphire-radio-on';
        this.iconOff = 'sapphire-radio-off';
        break;
    }
  }
}
