// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stch-item-logo',
  templateUrl: './stitch-item-logo.component.html',
  styleUrl: './stitch-item-logo.component.less',
  standalone: false,
})
export class StitchItemLogoComponent {
  @Input() appearance: 'default' | 'round' = 'default';
  @Input() size: 'xs' | 's' | 'm' | 'l' | 'xl' = 's';
  @Input() color: 'purple' | 'green' | 'grey' | 'red' | 'orange' | 'raspberry' | 'violet' | 'cyan' | 'transparent' =
    'transparent';
  @Input() title: string;
}
