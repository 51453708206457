// Common
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { KnotSource } from '@modules/knots/types/knot-source';

// Types
import { ChartState } from '@modules/knowledge/types/chart-state';
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';

// RX
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-knowledge-chart-context-menu',
  templateUrl: './knowledge-chart-context-menu.component.html',
  styleUrls: ['./knowledge-chart-context-menu.component.less'],
  standalone: false,
})
export class KnowledgeChartContextMenuComponent implements OnInit, OnDestroy {
  @Input() state: ChartState;

  @Output() change = new EventEmitter<ChartState>();

  public createdFromControl = new UntypedFormControl();
  public createdToControl = new UntypedFormControl();
  public linkedFromControl = new UntypedFormControl();
  public linkedToControl = new UntypedFormControl();
  public dateButtonSelected: 'from' | 'to' = 'from';
  public sources: { key: KnotSource, title: string }[] = [
    { key: 'user', title: 'User' },
    { key: 'keywords_named_entities', title: 'Bridge' },
  ];

  private alive = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.createdFromControl.valueChanges.pipe(startWith(this.state.filters.createdFrom)),
      this.createdToControl.valueChanges.pipe(startWith(this.state.filters.createdTo)),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([from, to]) => {
        if (
          from?.getTime() !== this.state?.filters?.createdFrom?.getTime() ||
          to?.getTime() !== this.state?.filters?.createdTo?.getTime()
        ) {
          this.change.emit({
            ...this.state,
            filters: {
              ...this.state.filters,
              createdFrom: from,
              createdTo: to,
              createdToday: false,
              createdThisWeek: false,
              createdThisMonth: false,
              createdThisYear: false,
              createdLastHour: false,
              createdLast3Hours: false,
              createdLast5Hours: false,
            },
          });
        }
      });

    combineLatest([
      this.linkedFromControl.valueChanges.pipe(startWith(this.state.filters.linkedFrom)),
      this.linkedToControl.valueChanges.pipe(startWith(this.state.filters.linkedTo)),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([from, to]) => {
        if (
          from?.getTime() !== this.state?.filters?.linkedFrom?.getTime() ||
          to?.getTime() !== this.state?.filters?.linkedTo?.getTime()
        ) {
          this.change.emit({
            ...this.state,
            filters: {
              ...this.state.filters,
              linkedFrom: from,
              linkedTo: to,
              linkedToday: false,
              linkedThisWeek: false,
              linkedThisMonth: false,
              linkedThisYear: false,
              linkedLastHour: false,
              linkedLast3Hours: false,
              linkedLast5Hours: false,
            },
          });
        }
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  setBooleanFilter(
    property:
      | 'autoDiscovery'
      | 'pinnedTags'
      | 'pinnedKnots'
      | 'pinnedItems'
      | 'displayKnots'
      | 'displayTags'
      | 'displayConnections'
      | 'displayStitched'
      | 'collapsed',
  ) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        [property]: !this.state.filters[property],
      },
    });
  }

  stitch(stitch: StitchTypeFilters) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        stitch,
      },
    });
  }

  setDateCreatedFilter(
    key:
      | 'createdToday'
      | 'createdThisWeek'
      | 'createdThisMonth'
      | 'createdThisYear'
      | 'createdLastHour'
      | 'createdLast3Hours'
      | 'createdLast5Hours',
  ) {
    this.createdFromControl.setValue(null);
    this.createdToControl.setValue(null);

    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        createdFrom: null,
        createdTo: null,
        createdToday: false,
        createdThisWeek: false,
        createdThisMonth: false,
        createdThisYear: false,
        createdLastHour: false,
        createdLast3Hours: false,
        createdLast5Hours: false,
        [key]: !this.state.filters[key],
      },
    });
  }

  setDateLinkedFilter(
    key:
      | 'linkedToday'
      | 'linkedThisWeek'
      | 'linkedThisMonth'
      | 'linkedThisYear'
      | 'linkedLastHour'
      | 'linkedLast3Hours'
      | 'linkedLast5Hours',
  ) {
    this.linkedFromControl.setValue(null);
    this.linkedToControl.setValue(null);

    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        linkedFrom: null,
        linkedTo: null,
        linkedToday: false,
        linkedThisWeek: false,
        linkedThisMonth: false,
        linkedThisYear: false,
        linkedLastHour: false,
        linkedLast3Hours: false,
        linkedLast5Hours: false,
        [key]: !this.state.filters[key],
      },
    });
  }

  setSource(source: KnotSource) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        source,
      },
    });
  }
}
