<div
  class="heading"
  (resize)="handleResize($event)"
>
  <app-input
    class="input"
    appearance="sapphire-inline"
    [inputFormControl]="form.controls.title"
    placeholder="New Folder"
    [validationMessages]="{ required: 'Can\'t be blank' }"
    [withErrors]="submitted"
    size="m"
  />

  <div class="icons">
    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="bug"
      [active]="selectedTab === 'debug'"
      matTooltip="Debug"
      (onClick)="selectedTab = 'debug'"
    />

    <app-button
      *ngIf="!contracted"
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-stitch"
      [disabled]="!folder?.id"
      matTooltip="Stitch To"
      [appStitchToPopover]="folder"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-archive2"
      [active]="form.controls.archived.value"
      [matTooltip]="form.controls.archived.value ? 'Move To Archive' : 'Restore From Archive'"
      (onClick)="archive()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-trash"
      [active]="form.controls.deleted.value"
      [matTooltip]="form.controls.deleted.value ? 'Move To Trash' : 'Restore From Trash'"
      (onClick)="delete()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-share"
      matTooltip="Share"
      [disabled]="!folder?.id"
      [stchShareContextMenu]="folder"
      [stchShareContextMenuDisabled]="!folder?.id"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-ellipsis"
      matTooltip="More Options"
      [appMessageFolderContextMenu]="folder"
      appMessageFolderContextMenuMode="popover"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-close"
      (click)="handleClose()"
    />
  </div>
</div>

<div class="date">
  Created: {{ folder?.createdAt | date : 'MMM d' }}
  <div class="date-divider"></div>
  Updated: {{ folder?.createdAt | date : 'MMM d' }}
</div>

<div class="tabs">
  <app-tabs
    appearance="sapphire"
    [values]="tabs"
    [(value)]="selectedTab"
    [selectedStateKey]="tabsStateKey"
    size="m"
  />

  <div class="filler"></div>

  <app-button appearance="sapphire-secondary" size="xs" label="Assign" />

  <stch-knowledge-button
    [active]="displayKnowledge"
    (click)="showKnowledge()"
  />
</div>

<div
  *ngIf="form"
  class="form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
  [appDroppableZIndex]="0"
>
  <div class="drop-area-border"></div>

  <div class="form-heading">
    <div class="form-heading-nav">
      <div class="form-heading-nav-separator"></div>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'stitch'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appStitchListContextMenu
        (appStitchListContextMenuChange)="stitchListState = $event"
      />

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'lab'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appKnowledgeChartContextMenu
        (appKnowledgeChartContextMenuChange)="chartState = $event"
        matTooltip="Graph Filters"
      />
    </div>

    <div
      *ngIf="displayKnowledge"
      class="form-heading-nav-underline"
    ></div>

    <app-tags
      class="form-tags"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[folder]"
      [control]="form.controls['tags']"
      stateKey="ff-tags"
    />

    <app-knots
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[folder]"
      [control]="form.controls['knots']"
      stateKey="ff-knots"
    />

    <app-connections
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[folder]"
      [control]="form.controls['connections']"
      stateKey="ff-connections"
    />
  </div>

  <app-scroll
    *ngIf="selectedTab !== 'lab'"
    [ngSwitch]="selectedTab"
    class="form-scroll"
    (top)="displayScrollShadow = !$event"
  >
    <div
      *ngIf="displayScrollShadow"
      class="form-scroll-shadow"
    ></div>

    <div
      *ngSwitchCase="'folder'"
    >
      <app-quick-form
        class="form-file-quick-form"
        [inline]="true"
      >
        <app-message-quick-form
          class="form-quick-form"
          [message]="quickMessage"
          [parentId]="this.folder?.id"
          (save)="handleSubmitQuickMessage($event)"
        />
      </app-quick-form>
    </div>

    <div
      *ngSwitchCase="'details'"
      class="details"
      [class.contracted]="contracted"
    >
      <div class="details-row">
        <div class="details-row-label">Parent Folder</div>
        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          placeholder="Parent Folder"
          [suggestions]="parentSuggestions"
          [inputFormControl]="form.controls.parentId"
          size="s"
          [withErrors]="submitted"
          [validationMessages]="{
            circular: 'Circular dependency',
            deep: 'Too deep'
          }"
          [withClear]="true"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Description</div>
        <app-textarea
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.description"
          [rows]="3"
          [withClear]="true"
          placeholder="Description"
          [validationMessages]="{ required: 'Please enter a value' }"
          [withErrors]="submitted"
        />
      </div>
    </div>

    <app-attachments-list
      class="form-attachments"
      *ngSwitchCase="'attachments'"
      [stitchItem]="stitchItem"
      [inputControl]="form.controls.uploads"
      contextMenuStateVariant="messageFolders"
    />

    <app-linked-info-list
      *ngSwitchCase="'stitch'"
      class="form-linked-info"
      [stitch]="folder"
      [state]="stitchListState"
      (clickStitchItem)="handleClickItem($event)"
      (dblClickStitchItem)="handleDblClickItem($event)"
    />

    <stch-comments
      *ngSwitchCase="'comments'"
      class="form-linked-info"
      [stitch]="folder"
    />

    <stch-json-viewer
      *ngSwitchCase="'debug'"
      [json]="folder?.debug"
    />
  </app-scroll>

  <app-knowledge-chart
    *ngIf="selectedTab === 'lab'"
    class="form-lab"
    [sizeChanges]="displayKnowledge"
    [item]="folder"
    [state]="chartState"
  />
</div>
