<app-context-menu-item
  [title]="tag?.pinned ? 'Unpin' : 'Pin'"
  [close]="true"
  (execute)="pinTag()"
/>

<app-context-menu-item
  *ngIf="withDetach"
  title="Detach"
  [close]="true"
  (execute)="detachTag()"
/>

<app-context-menu-item
  title="Delete Permanently"
  [close]="true"
  (execute)="deleteTag()"
/>
