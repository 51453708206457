// Common
import { Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

// Types
import { Task } from './task';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }] });

export class Row {
  formBuilder = injector.get(UntypedFormBuilder);

  id: string;
  title?: string;
  position?: number;
  projectId?: string;

  // Tech properties

  /**
   * tasks - build rows and columns tree structures
   */
  tasks?: Task[];

  /**
   * default - invisible row for tasks without row
   */
  default?: boolean;

  constructor(rowObject: Partial<Row> = {}) {
    this.id = rowObject.id;
    this.title = rowObject.title;
    this.position = rowObject.position;
    this.projectId = rowObject.projectId;
    this.tasks = rowObject.tasks;
    this.default = rowObject.default;
  }

  static fromFormGroup(form: UntypedFormGroup): Row {
    const formValues = form.value;

    return new Row({
      id: formValues.id,
      title: formValues.title,
      position: formValues.position,
      projectId: formValues.projectId,
      tasks: formValues.tasks,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      position: [this.position],
      projectId: [this.projectId],
      tasks: [this.tasks],
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      position: Math.ceil(this.position),
      projectId: this.projectId,
    };
  }
}
