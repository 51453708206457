import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@modules/common/types/Constants';

@Pipe({
  name: 'relativeDate',
  standalone: true,
  pure: false,
})
export class RelativeDatePipe implements PipeTransform {
  private readonly datePipe = inject(DatePipe);

  public transform(value: string | number | Date, format?: string): string {
    const messageDate = new Date(value);
    const currentDate = new Date();
    const daysDelta = (messageDate.setHours(0, 0, 0, 0) - currentDate.setHours(0, 0, 0, 0)) / 86400000;

    let dateString: string;
    if (daysDelta >= -1 && daysDelta <= 1) {
      dateString = ['Yesterday', 'Today', 'Tomorrow'][daysDelta + 1];
    } else if (messageDate.getFullYear() === currentDate.getFullYear()) {
      dateString = this.datePipe.transform(messageDate, format ?? 'MMM, d');
    } else {
      dateString = this.datePipe.transform(messageDate, format ?? Constants.DATE_DEFAULT_FORMAT);
    }
    return dateString;
  }
}
