// Types
import { Filters } from '@modules/common/types/filters';
import { SortOrder } from '@modules/common/types/sort-order';
import { KnotSortableField } from './knot-sortable-field';
import { KnotSource } from './knot-source';
import { Tag } from '@modules/tags/types/tag';
import { Connection } from '@modules/connections/types/connection';

// Utils
import { isNil } from '@modules/common/utils/base';

export class KnotsNeighboursFilters extends Filters {
  // Filters
  collapsed?: boolean;
  commonNeighbours?: boolean;
  connections?: Connection[];
  createdFrom?: Date;
  createdTo?: Date;
  excludeItems?: string[];
  ids?: string[];
  source?: KnotSource;
  tags?: Tag[];
  threeInARow?: boolean;

  // Sort Options
  sortBy?: KnotSortableField;
  secondarySortBy?: KnotSortableField;
  secondarySortOrder?: SortOrder;

  constructor(filters: Partial<KnotsNeighboursFilters> = {}) {
    super(filters);
    this.collapsed = filters.collapsed;
    this.commonNeighbours = filters.commonNeighbours;
    this.connections = filters.connections;
    this.createdFrom = filters.createdFrom;
    this.createdTo = filters.createdTo;
    this.excludeItems = filters.excludeItems || [];
    this.secondarySortBy = filters.secondarySortBy;
    this.secondarySortOrder = filters.secondarySortOrder;
    this.sortBy = filters.sortBy;
    this.source = filters.source;
    this.tags = filters.tags;
    this.threeInARow = filters.threeInARow;
  }

  format(): { [param: string]: string | string[] } {
    const result = {};

    if (this.excludeItems?.length) {
      result['excludeItems[]'] = this.excludeItems.filter((i) => !isNil(i));
    }

    if (this.tags?.length) {
      result['tags[]'] = this.tags.map(({ name }) => name);
    }

    if (this.connections?.length) {
      result['connections[]'] = this.connections.map(({ name }) => name);
    }

    if (this.source) {
      result['source'] = this.source;
    }

    if (this.ids && this.ids.length) {
      // 'undefined' possible when new item in FF and new not existing knot
      result['ids[]'] = this.ids.filter((i) => !isNil(i));
    }

    if (this.createdFrom) {
      result['created_from_time'] = this.createdFrom.toISOString();
    }
    if (this.createdTo) {
      result['created_to_time'] = this.createdTo.toISOString();
    }

    if (this.threeInARow) {
      result['three_in_a_row'] = true;
    }
    if (this.commonNeighbours) {
      result['common_neighbours'] = true;
    }
    if (this.collapsed) {
      result['collapsed'] = true;
    }

    if (this.sortBy) {
      result['sort_by'] = this.sortBy;
    }
    if (this.sortOrder) {
      result['sort_order'] = this.sortOrder;
    }
    if (this.secondarySortBy) {
      result['secondary_sort_by'] = this.secondarySortBy;
    }
    if (this.secondarySortOrder) {
      result['secondary_sort_order'] = this.secondarySortOrder;
    }

    if (!isNil(this.limit)) {
      result['limit'] = this.limit + '';
    }
    if (!isNil(this.offset)) {
      result['offset'] = this.offset + '';
    }

    return result;
  }
}
