// Common
import {
  Component,
  Input,
  ViewChild,
  OnInit,
  ElementRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  Optional,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';

// RX
import { Subject, Subscription, BehaviorSubject, of } from 'rxjs';
import { takeUntil, distinctUntilChanged, startWith, tap, switchMap } from 'rxjs/operators';

// Types
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { CollapsedStateKey } from '@modules/settings/types/collapsed-state';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Utils
import { addHours } from '@modules/common/utils/date';

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: '43px' }),
    animate(
      `.3s ease-in-out`,
      style({
        height: '{{height}}',
      }),
    ),
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{height}}' }),
    animate(
      `.3s ease-in-out`,
      style({
        height: '43px',
      }),
    ),
  ]),
]);

@Component({
  selector: 'app-date-picker-2',
  templateUrl: './date-picker-2.component.html',
  styleUrls: ['./date-picker-2.component.less', '../../styles/input.less'],
  animations: [collapseMotion],
  standalone: false,
})
export class DatePicker2Component implements OnInit, OnDestroy, OnChanges {
  // View Children
  @ViewChild('calendarContainer') calendarContainer: ElementRef;

  // Inputs
  @Input() inputFormControl = new FormControl<Date>(null);
  @Input() width: string;
  @Input() disabled = false;
  @Input() collapsedStateKey: CollapsedStateKey;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() highlightRange: boolean;
  @Input() collapseable = true;

  // Output
  @Output() activeDateChange = new EventEmitter();
  @Output() dateSelected = new EventEmitter();
  @Output() eventDropped: EventEmitter<CalendarDropEvent> = new EventEmitter<CalendarDropEvent>();

  // Public
  public collapsed = false;
  public activeDate = new BehaviorSubject(new Date());
  public inlineHeight: string;
  public years: number[] = [];
  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public events: AngularCalendarEvent[] = [];
  public monthsPopoverClose = new Subject<void>();

  // Private
  private alive = new Subject<void>();
  private inputFormControlSubscription: Subscription;
  private yearsShift = 0;

  /**
   * Constructor
   */

  constructor(
    private stateService: StateService,
    @Optional() private calendarEventsService: CalendarEventsService,
  ) {
    this.subscribeToFormControl();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.getPersistedState();
    this.generateYearsList();

    this.activeDate
      .pipe(
        takeUntil(this.alive),
        distinctUntilChanged(
          (previous, next) => previous.getFullYear() === next.getFullYear() && previous.getMonth() === next.getMonth(),
        ),
        tap((date: Date) => {
          this.activeDateChange.emit(date);
        }),
        switchMap((date: Date) =>
          this.calendarEventsService
            ? this.calendarEventsService.getCalendarEvents(this.beginningOfMonth(date), this.endOfMonth(date))
            : of([]),
        ),
      )
      .subscribe((events: AngularCalendarEvent[]) => {
        this.events = events;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes && this.inputFormControl) {
      this.subscribeToFormControl();
    }
  }

  /**
   * Actions
   */

  handlePreviousMonthClick(): void {
    const currentDate = this.activeDate.getValue();

    this.activeDate.next(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        Math.min(
          currentDate.getDate(),
          this.daysInMonth(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)),
        ),
      ),
    );
  }

  handleNextMonthClick(): void {
    const currentDate = this.activeDate.getValue();

    this.activeDate.next(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        Math.min(
          currentDate.getDate(),
          this.daysInMonth(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)),
        ),
      ),
    );
  }

  handleChangeYearsViewport(direction: 1 | -1) {
    this.yearsShift += direction;
    this.generateYearsList();
  }

  handleMonthClick(monthIndex: number): void {
    const currentDate = this.activeDate.getValue();

    this.activeDate.next(
      new Date(
        currentDate.getFullYear(),
        monthIndex,
        Math.min(currentDate.getDate(), this.daysInMonth(new Date(currentDate.getFullYear(), monthIndex))),
      ),
    );
  }

  handleYearClick(year: number) {
    const currentDate = this.activeDate.getValue();

    this.activeDate.next(
      new Date(
        year,
        currentDate.getMonth(),
        Math.min(currentDate.getDate(), this.daysInMonth(new Date(year, currentDate.getMonth()))),
      ),
    );
  }

  handleDateSelect(date: Date = new Date()) {
    this.activeDate.next(date);

    if ((this.maxDate && date > this.maxDate) || (this.minDate && date < this.minDate)) {
      return;
    }

    this.inputFormControl.markAsDirty();
    this.inputFormControl.setValue(date);
  }

  handleCollapse() {
    this.inlineHeight = this.calendarContainer.nativeElement.offsetHeight + 'px';
    this.collapsed = !this.collapsed;
    if (this.collapsedStateKey) {
      this.stateService.setCollapsed(this.collapsedStateKey, this.collapsed);
    }
  }

  handleCancel() {
    this.monthsPopoverClose.next();
  }

  handleSave() {
    this.monthsPopoverClose.next();
  }

  handleDrop(dragData: DragData, newStart: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart: newStart,
      newEnd: addHours(newStart, 1),
      originRef: new ElementRef(origin),
    });
  }

  /**
   * Helpers
   */

  generateYearsList() {
    const selectedYear = this.activeDate.getValue().getFullYear() + this.yearsShift * 12;

    this.years = [...Array(12).keys()].map((index) => selectedYear - 6 + index);
  }

  getPersistedState() {
    if (this.collapsedStateKey) {
      this.stateService
        .getCollapsed(this.collapsedStateKey)
        .pipe(takeUntil(this.alive), distinctUntilChanged())
        .subscribe((value: boolean) => (this.collapsed = value));
    }
  }

  subscribeToFormControl() {
    if (this.inputFormControlSubscription) {
      this.inputFormControlSubscription.unsubscribe();
    }
    this.inputFormControlSubscription = this.inputFormControl.valueChanges
      .pipe(startWith(this.inputFormControl.value as Date), takeUntil(this.alive))
      .subscribe((date: Date) => {
        if (!date) {
          return;
        }
        this.activeDate.next(date);
        this.dateSelected.emit(date);
      });
  }

  private beginningOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  }

  private endOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);
  }

  private daysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }
}
