<div
  class="container"
  (resize)="handleResize($event)"
  resizeDelayType="debounce"
  appSelectableList
  [appSelectableListReset]="selectedDateChanged"
>
  <stch-calendar-month
    *ngIf="size"
    class="calendar"
    [ngClass]="size"
    [viewDate]="viewDate"
    [selectedDate]="selectedDate"
    [events]="calendarEvents"
    [eventsAppearance]="size === 'l' ? 'list' : 'dots'"
    (dateClicked)="dateClicked.emit($event)"
    (dateDblClicked)="dateDblClicked.emit($event)"
    (eventDropped)="eventDropped.emit($event)"
    (loadDayEvents)="loadDayEvents.emit($event)"
  />

  <div
    *ngIf="agenda"
    class="agenda"
  >
    <div class="title">
      {{ (selectedDate | dateToWords : 'MMM dd yyyy' : true) | titlecase }} Events
      <span class="count">{{ displayEvents.length }}</span>
    </div>

    <div class="list">
      <stch-agenda-event
        *ngFor="let event of displayEvents; let index = index"
        [event]="event"
        [position]="index"
        [appSelectable]="event"
        [appSelectablePosition]="index"
      />
    </div>
  </div>
</div>
