import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@modules/messages/types/message';

@Pipe({
  name: 'isMessageSenderVip',
  standalone: false,
})
export class IsMessageSenderVip implements PipeTransform {
  transform(message: Message): any {
    const propertyKey = message.draft || message.sent ? 'to' : 'from';

    if (!message?.[propertyKey]?.[0]) {
      return false;
    }

    return !!message?.[propertyKey][0].vip;
  }
}
