<div class="heading1">Users</div> 
<div class="heading2-subtitle">Spaces helps bring people together so that they can get things done.</div>

<stch-table
  class="items"
  [items]="users"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
  (rowClick)="handleRowClick($event)"
/>

<ng-template #birthDateTemplate let-user=item>
  {{ user.birthDate | date : 'mediumDate' }}
</ng-template>

<ng-template #dateTemplate let-user=item>
  {{ user.createdAt | date : 'mediumDate' }}
</ng-template>

<ng-template #avatarTemplate let-user=item>
  <img *ngIf="user.avatar" class="avatar" [src]="user.avatar" alt="user's avatar">
</ng-template>
