// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { HistoryService } from '@modules/common/services/history.service';

// Types
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { StitchFiltersHistoryRecord } from '@modules/common/services/history.service';
import { Tag } from '@modules/tags/types/tag';

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.less'],
  standalone: false,
})
export class FiltersPanelComponent implements OnInit, OnDestroy {
  public connectionsHistory: Connection[] = [];
  public knotsHistory: Knot[] = [];
  public tagsHistory: Tag[] = [];

  private alive = new Subject<void>();

  constructor(private historyService: HistoryService) {}

  /**
   * Lifecycle
   */
  ngOnInit() {
    this.historyService
      .onChanges()
      .pipe(takeUntil(this.alive))
      .subscribe(({ connections, knots, tags }) => {
        this.connectionsHistory = connections;
        this.knotsHistory = knots;
        this.tagsHistory = tags;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */
  handleClickHistoryItem(event: MouseEvent, type: keyof StitchFiltersHistoryRecord, index: number) {
    event.stopPropagation();
    event.preventDefault();

    const currentHistory = this.historyService.getCurrentHistory();

    if (!currentHistory[type][index]) return;

    const updatedHistory: StitchFiltersHistoryRecord = {
      ...currentHistory,
      [type]: currentHistory[type].slice(0, index + 1),
    };

    this.historyService.updateHistory(updatedHistory);
  }

  removeHistoryItem(type: keyof StitchFiltersHistoryRecord, index: number) {
    const currentHistory = this.historyService.getCurrentHistory();

    const updatedHistory: StitchFiltersHistoryRecord = {
      ...currentHistory,
      [type]: currentHistory[type].filter((_, i) => i !== index),
    };

    this.historyService.updateHistory(updatedHistory);
  }
}
