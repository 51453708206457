// Common
import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reminders-dropdown',
  templateUrl: './reminders-dropdown.component.html',
  styleUrls: ['./reminders-dropdown.component.less'],
  standalone: false,
})
export class RemindersDropdownComponent implements OnDestroy {
  @Input() control: FormArray<ReminderFormGroup>;
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  @Input() appearance: 'sapphire' = 'sapphire';

  @Output() onCustom = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<string>();

  public options?: DropdownSelectItem<Reminder[]>[] = [
    { title: 'No Reminder', value: [] },
    {
      title: 'At time',
      value: [
        new Reminder({
          notifyBy: 'notification',
          duration: 0,
          units: 'minutes',
          reminderType: 'beforeStart',
        }),
      ],
    },
    {
      title: '10 minutes before',
      value: [
        new Reminder({
          notifyBy: 'notification',
          duration: 10,
          units: 'minutes',
          reminderType: 'beforeStart',
        }),
      ],
    },
    {
      title: '1 hour before',
      value: [
        new Reminder({
          notifyBy: 'notification',
          duration: 1,
          units: 'hours',
          reminderType: 'beforeStart',
        }),
      ],
    },
    {
      title: '1 day before',
      value: [
        new Reminder({
          notifyBy: 'notification',
          duration: 1,
          units: 'days',
          reminderType: 'beforeStart',
        }),
      ],
    },
  ];

  private alive = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(option: DropdownSelectItem<Reminder>) {
    this.control.clear({ emitEvent: option.value.length === 0 });

    option.value.forEach((reminder) => this.control.push(reminder.asFormGroup()));

    this.control.markAsDirty();

    this.onSelect.emit(option.value);
  }
}
