import { animation, style, animate, AnimationTriggerMetadata, trigger, transition } from '@angular/animations';

export const slideAnimation: AnimationTriggerMetadata = trigger('dateAnimation', [
  transition(
    '* <=> *',
    animation(
      [
        style({ opacity: 0, transform: 'translateX({{offsetStart}}%)' }),
        animate('{{ duration }} ease-out', style({ opacity: 1, transform: 'translateX({{offsetFinish}}%)' })),
      ],
      { params: { duration: '300ms', offsetStart: -5, offsetFinish: 0 } },
    ),
  ),
]);
