import { Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypedFormGroup } from '@modules/common/utils/form';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export type AutoReplyTemplateFormGroup = FormGroup<
  TypedFormGroup<{
    title: string;
    content: string;
    id: string;
    dateFrom: Date;
    dateTo: Date;
    enabled: boolean;
  }>
>;

export class AutoReplyTemplate {
  formBuilder = injector.get(FormBuilder);

  id: string;
  content: string;
  title: string;
  dateFrom?: Date;
  dateTo?: Date;
  enabled?: boolean;

  constructor(data: Partial<AutoReplyTemplate> = {}) {
    this.id = data.id;
    this.title = data.title || '';
    this.content = data.content || '';
    this.dateFrom = data.dateFrom ? new Date(data.dateFrom) : null;
    this.dateTo = data.dateTo ? new Date(data.dateTo) : null;
    this.enabled = data.enabled;
  }

  static fromFormGroup(form: FormGroup): AutoReplyTemplate {
    const { value: formValues } = form;

    return new AutoReplyTemplate({
      id: formValues.id,
      title: formValues.title,
      content: formValues.content,
      dateFrom: formValues.dateFrom,
      dateTo: formValues.dateTo,
      enabled: formValues.enabled,
    });
  }

  public asFormGroup(): AutoReplyTemplateFormGroup {
    return this.formBuilder.group({
      id: this.id,
      title: [this.title, Validators.required],
      content: this.content,
      dateFrom: [this.dateFrom, Validators.required],
      dateTo: [this.dateTo, Validators.required],
      enabled: this.enabled,
    });
  }

  public asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      enabled: this.enabled,
    };
  }
}
