// Common
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RX
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Types
import { Coordinates } from '@modules/common/types/geo-location';

// Services
import { GeoLocationService } from '@modules/common/services/geo-location.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Env
import { environment } from '@environment';
import { AUTH_PAYLOAD_KEY, AuthResponseDto, AuthResponsePayloadDto } from '@modules/auth/types/auth';
import { CSRF_TOKEN_KEY } from '@modules/auth/types/token';

@Injectable()
export class AuthService {
  private readonly authenticated: BehaviorSubject<AuthResponsePayloadDto | null>;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private geoLocationService: GeoLocationService,
  ) {
    this.authenticated = new BehaviorSubject<AuthResponsePayloadDto | null>(this.getStoredAuthPayload());
  }

  public isAuthenticated(): boolean {
    return this.authenticated.value !== null;
  }

  public getAuthenticated(): Observable<AuthResponsePayloadDto | null> {
    return this.authenticated.asObservable();
  }

  public setAuthPayload(payload: AuthResponsePayloadDto | null, csrfToken: string | null) {
    if (payload === null) {
      localStorage.removeItem(AUTH_PAYLOAD_KEY);
      localStorage.removeItem(CSRF_TOKEN_KEY);
      this.authenticated.next(null);
      return;
    }

    try {
      localStorage.setItem(AUTH_PAYLOAD_KEY, JSON.stringify(payload));
      localStorage.setItem(CSRF_TOKEN_KEY, csrfToken);
      this.authenticated.next(payload);
    } catch (e) {
      console.error('Error during setting payload: ', e.message);
      localStorage.removeItem(AUTH_PAYLOAD_KEY);
      this.authenticated.next(null);
    }
  }

  public signOut(): Observable<boolean> {
    return this.http.post(environment.baseUrl + '/api/account/sessions/logout', {}, { withCredentials: true }).pipe(
      map(() => true),
      catchError(() => of(false)),
      tap(() => {
        this.setAuthPayload(null, null);
      }),
    );
  }

  public isUsernameTaken(username: string) {
    return this.http
      .post<{ isTaken: boolean; alternative: string }>(environment.baseUrl + '/api/account/usercheck', { username })
      .pipe(catchError((error) => this.handleObserverError(error)));
  }

  public signUp(formValues) {
    return this.geoLocationService.getCoordinates().pipe(
      switchMap((coordinates: Coordinates) =>
        this.http.post<AuthResponseDto>(
          environment.baseUrl + '/api/account/signup',
          { ...formValues, ...coordinates },
          { withCredentials: true },
        ),
      ),
      tap((response) => {
        this.setAuthPayload(response.payload, response.csrfToken);
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  public signIn2(formValues) {
    return this.geoLocationService.getCoordinates().pipe(
      switchMap((coordinates: Coordinates) =>
        this.http.post<AuthResponseDto>(
          environment.baseUrl + '/api/account/signin',
          { ...formValues, ...coordinates },
          { withCredentials: true },
        ),
      ),
      tap((response) => {
        this.setAuthPayload(response.payload, response.csrfToken);
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  private handleObserverError(error: Error) {
    this.toasterService.show({ text: error?.['error']?.message });
    return throwError(() => error);
  }

  private getStoredAuthPayload(): AuthResponsePayloadDto | null {
    const payloadStr = localStorage.getItem(AUTH_PAYLOAD_KEY);
    if (!payloadStr) {
      return null;
    }

    try {
      const payload = JSON.parse(payloadStr);
      if (payload.exp && payload.exp * 1000 < Date.now()) {
        return null;
      }

      return payload;
    } catch (e) {
      console.error('Error during parsing payload: ', e.message);
      return null;
    }
  }
}
