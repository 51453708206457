// Common
import { Injectable } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';

// RX
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class DynamicPanelService {
  private formItem = new BehaviorSubject<Stitch>(null);
  private formTab = new BehaviorSubject<string>(null);

  /**
   * Actions
   */

  setFormItem(item: Stitch) {
    this.formItem.next(item);
  }

  getFormItem(): Observable<Stitch> {
    return this.formItem.asObservable();
  }

  getFormTab(): Observable<string> {
    return this.formTab.asObservable();
  }

  setFormTab(tab: string) {
    this.formTab.next(tab);
  }
}
