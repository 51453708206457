// Common
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

// Types
import { NativeFile } from '@modules/common/types/native-file';
import { Upload } from '@modules/common/types/upload';

// RX
import { fromEvent, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Service
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { AccountService } from '@modules/account/services/account.service';
import { UploadsService } from '@modules/common/services/uploads.service';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

@Component({
  selector: 'app-settings-avatar-form',
  templateUrl: './avatar-form.component.html',
  styleUrls: ['./avatar-form.component.less'],
  standalone: false,
})
export class AvatarFormComponent implements AfterViewInit, OnDestroy {
  public dragOver = 0;
  public imageFile: NativeFile;
  public upload: Upload;
  public uploadInProgress = false;
  public croppedEvent: ImageCroppedEvent;

  private alive = new Subject<void>();

  // View Children
  @ViewChild('droppableArea') droppableArea: ElementRef;

  constructor(
    private ngZone: NgZone,
    private toasterService: ToasterService,
    private accountService: AccountService,
    @Inject(CloseToken) private closeToken,
    private uploadsService: UploadsService,
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.droppableArea.nativeElement, 'dragover')
        .pipe(takeUntil(this.alive))
        .subscribe((event: DragEvent) => event.preventDefault());
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleDropFiles(event: DragEvent) {
    this.dragOver = 0;
    event.stopPropagation();
    event.preventDefault();

    this.imageFile = event.dataTransfer?.files?.[0];
  }

  handleChange(event: any): void {
    this.imageFile = event?.target?.files?.[0];
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedEvent = event;
  }

  loadImageFailed() {
    this.toasterService.show({
      text: 'Loading image failed.',
    });
  }

  handleSave() {
    if (!this.croppedEvent) {
      return;
    }

    this.uploadInProgress = true;

    this.upload = new Upload({
      nativeFile: new File([this.croppedEvent.blob], this.imageFile.name),
      temp: true,
    });

    this.uploadsService
      .upload(this.upload)
      .pipe(
        switchMap(({ id }) => {
          return this.accountService.update({ tempAvatarId: id });
        }),
        takeUntil(this.alive),
      )
      .subscribe(
        () => {
          this.uploadInProgress = false;
          // this.accountService.reloadAccount();
          this.closeToken.next();
        },
        () => {
          this.uploadInProgress = false;
        },
      );
  }

  handleCancel() {
    this.closeToken.next();
  }
}
