import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const collapse: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: 0 }),
    animate(`.3s ease-in-out`, style({ height: '{{height}}px' })),
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{height}}px' }),
    animate(`.3s ease-in-out`, style({ height: 0 })),
  ]),
]);
