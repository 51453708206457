<app-context-menu-item
  [title]="participant?.vip ? 'Remove from VIPs' : 'Add to VIPs'"
  (execute)="moveToList('vip')"
/>

<app-context-menu-item
  [title]="participant?.['white-list'] ? 'Remove from White List' : 'Add to White List'"
  (execute)="moveToList('white-list')"
/>

<app-context-menu-item
  [title]="participant?.['black-list'] ? 'Remove from Black List' : 'Add to Black List'"
  (execute)="moveToList('black-list')"
/>

<app-context-menu-divider />

<app-context-menu-item
  title="Copy Email Address"
  [close]="true"
  (execute)="copy()"
/>

<app-context-menu-item
  title="Compose Email"
  [close]="true"
  (execute)="compose()"
/>
