// Common
import { Component, Injector } from '@angular/core';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

// Types
import { Message } from '@modules/messages/types/message';
import { StitchType } from '@modules/common/types/stitch-type';

@Component({
  selector: 'stch-message-shared',
  templateUrl: './message-shared.component.html',
  styleUrls: ['./message-shared.component.less'],
  standalone: false,
})
export class MessageSharedComponent extends SharedBaseComponent<Message> {
  protected stitchType = StitchType.message;

  constructor(protected injector: Injector) {
    super(injector);
  }

  get message() {
    return this.stitchItem;
  }
}
