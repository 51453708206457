<div class="content" #content>
  <ng-content />

  <div class="resize-container">
    <mat-icon
      class="resize-icon"
      svgIcon="resize"
      (mousedown)="handleResize($event)"
      />
  </div>
</div>
