<div
  class="container"
  [style.width.px]="size"
  [style.height.px]="size"
>
  <img
    *ngIf="url"
    class="avatar"
    alt="avatar image"
    [src]="url"
    (error)="handleError()"
    [matTooltip]="user?.fullName"
  />

  <div
    *ngIf="!url"
    class="initials"
    [matTooltip]="user?.fullName"
  >
    {{ initials }}
  </div>

  <div
    *ngIf="withStatus && online"
    class="online"
  ></div>
</div>
