<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Security Alert

      <app-toggle-switch
        class="heading2-toggle"
        [inputFormControl]="form.controls.alertsEnabled"
      />
    </div>
    <div class="body-primary fixed-width">
      Improve the security of your account by getting alerts when someone tries logging in to your account from an unknown device or browser.
    </div>
  </div>
</div>

<div class="block no-border column">
  <div class="block-grow">
    <div class="heading2">
      Where You’re Logged In
    </div>
  </div>

  <div class="block-grow">
    <div
      *ngFor="let session of sessions | sortBy: 'current': 'desc'"
      class="logged-device-container"
    >
      <mat-icon
        class="logged-device-container-device-icon"
        svgIcon="computer"
      />
      <div class="logged-device-container-group">
        <div class="logged-device-container-group-item body-primary">
          <span>{{ session.os }}</span>
          <span class="logged-device-container-delimiter"></span>
          <span>{{ session.location }}</span>
        </div>

        <div class="logged-device-container-group-item body-primary">
          <span>{{ session.browser }}</span>
          <span class="logged-device-container-delimiter"></span>
          <span
            class="logged-device-container-group-item-activity"
            [class.active]="session.active"
          >
            {{ session.active ? 'Active Now' : session.recentActivity | date: 'MMM d, hh:mm aaa' }}</span>
        </div>
        <app-button
          *ngIf="session.current"
          class="logged-device-container-group-button"
          label="Destroy Other Sessions"
          appearance="purple"
          (click)="handleDestroyOther()"
        />

        <app-button
          *ngIf="!session.current"
          class="logged-device-container-group-button"
          label="Destroy Session"
          appearance="purple-outline"
          (click)="handleDestroy(session)"
        />
      </div>
    </div>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Allowed Login attempts
    </div>
    <div class="body-primary">
      Account will be blocked for some time after provided number of incorrect attempts
    </div>
  </div>

  <app-input
    appearance="amethyst"
    class="login-attempts-input"
    [inputFormControl]="form.controls.allowedLoginAttempts"
  />
</div>

<div class="block no-border">
  <div class="block-grow">
    <div class="heading2">
      Incorrect login block time increment
    </div>
    <div class="body-primary incorrect-login">
      Incremental period of time account is blocked after provided number of incorrect login attempts.
    </div>
  </div>

  <app-combined-dropdown-input
    class="incorrect-login-delay-input"
    [inputFormControl]="form.controls.incorrectLoginDelay"
    [dropDownFormControl]="form.controls.incorrectLoginDelayUnits"
    [dropDownOptions]="loginDelayUnits"
  />
</div>

