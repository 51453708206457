import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { environment } from '@environment';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { MessageFolderComponent } from '@modules/elements/components/message-folder/message-folder.component';
import { KnotSource } from '@modules/knots/types/knot-source';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-message-folders-list',
  templateUrl: './folders-list.component.html',
  styleUrls: ['./folders-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class MessageFoldersListComponent extends BaseStitchListComponent<MessageFolder> implements OnChanges {
  @Input()
  public override filters: Partial<FoldersFilters> = {};
  @Input()
  public withTags = false;
  @Input()
  public withKnots = false;
  @Input()
  public knotsSource: KnotSource;
  @Input()
  public debug: 'score';

  @Output()
  public readonly clickFolder = new EventEmitter<MessageFolder>();
  @Output()
  public readonly openFolder = new EventEmitter<MessageFolder>();
  @Output()
  public readonly openMessage = new EventEmitter<Message>();
  @Output()
  public readonly doubleClickFolder = new EventEmitter<MessageFolder>();

  @ViewChildren(MessageFolderComponent, { read: ElementRef<HTMLElement> })
  public override itemElements: QueryList<ElementRef<HTMLElement>>;

  public override items: StitchListItem<MessageFolder>[] = [];

  constructor(
    private foldersService: MessageFoldersService,
    private messagesStateService: MailAppStateService,
    private linkedInfoService: LinkedInfoService,
  ) {
    super();

    merge(
      this.messagesStateService.getRefreshAll(),
      this.linkedInfoService.getRefresh(),
      this.foldersService.getRefresh(),
      interval(environment.messageFetchInterval),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadPage(this.page);
      });

    merge(this.messagesStateService.getRefreshAll(), this.foldersService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetSelected.next();
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes && !changes.filters.isFirstChange()) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: MessageFolder[]; count: number }> {
    return this.foldersService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  public handleDoubleClickItem(folder: MessageFolder) {
    this.doubleClickFolder.emit(folder);
  }

  public handleClickItem(folder: MessageFolder) {
    this.clickFolder.emit(folder);
  }
}
