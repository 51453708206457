import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ListStateService } from '@modules/common/components/list/list-state.service';
import { SortBy } from '@modules/common/types/SortBy';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import { StateKey } from '@modules/settings/types/state-key';
import { Note } from '../types/note';
import { NotebooksListSortField, NotebooksListState } from '../types/notebooks-list-state';

export interface NotebookListStateServiceConfig {
  stateKey?: StateKey;
  withAdvancedSearch?: boolean;
  defaultSort?: SortBy<NotebooksListSortField>;
}

export const NotebookListStateServiceConfigToken = new InjectionToken<NotebookListStateServiceConfig>(
  'Configuration object for notebook list state service',
);

@Injectable()
export class NotebookListStateService extends ListStateService<Note, NotebooksListState> {
  public override readonly state: NotebooksListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(NotebookListStateServiceConfigToken) config: NotebookListStateServiceConfig,
  ) {
    super();

    this.state = new NotebooksListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch,
      defaultSort: config.defaultSort,
    });
  }

  public static providers(config: NotebookListStateServiceConfig = {}): any[] {
    return super.prepareProviders(NotebookListStateServiceConfigToken, config);
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.notebooksListState]?: NotebooksListState;
    [StateKey.sidebarNotebooksListState]?: NotebooksListState;
    [StateKey.notebooksDPListState]?: NotebooksListState;
  }
}
