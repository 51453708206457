// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Direction } from '../types/direction';

@Pipe({
  name: 'orderedItemZonePosition',
  standalone: false,
})
export class OrderedItemZonePositionPipe implements PipeTransform {
  transform(
    direction: Direction,
    hovered: boolean,
    size: number,
    placeholderSize: number,
    neighborSize: number,
  ): object {
    if (direction === 'vertical') {
      return {
        top: `${-neighborSize / 2}px`,
        height: `${(hovered ? placeholderSize : 0) + size / 2 + neighborSize / 2}px`,
        left: 0,
        width: '100%',
      };
    } else {
      return {
        top: 0,
        height: '100%',
        left: `${-neighborSize / 2}px`,
        width: `${(hovered ? placeholderSize : 0) + size / 2 + neighborSize / 2}px`,
      };
    }
  }
}
