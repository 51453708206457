import { Component, inject, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { NotebookListStateService } from '@modules/notes/services/notebook-list-state.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { NotebooksListState } from '@modules/notes/types/notebooks-list-state';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-side-bar-notebooks',
  templateUrl: './side-bar-notebooks.component.html',
  styleUrls: [
    '../../../common/components/base-sidebar-containers-item/base-sidebar-containers-item.component.less',
    './side-bar-notebooks.component.less',
  ],
  animations: [heightAnimation],
  standalone: false,
  providers: [
    NotebookListStateService.providers({
      stateKey: StateKey.sidebarNotebooksListState,
    }),
  ],
})
export class SideBarNotebooksComponent
  extends BaseSidebarContainersItemComponent<Notebook, Note, NotebooksFilters, VirtualFolder, NotebooksListState>
  implements OnInit
{
  application = Application.notes;
  sidebarSplitViewKey = SidebarSplitViewKey.notes;

  private readonly notebookListStateService = inject(NotebookListStateService, { self: true });

  constructor(notesStateService: NotesAppStateService) {
    super(notesStateService);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.notebookListStateService
      .value()
      .pipe(takeUntil(this.alive))
      .subscribe((state) => {
        this.filters = new NotebooksFilters().applyListState(state);
      });
  }
}
