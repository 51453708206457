// Common
import { ComponentRef, Directive, Input } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ColumnContextMenuComponent } from '@modules/tasks/components/column-context-menu/column-context-menu.component';

// Types
import { Column } from '@modules/tasks/types/column';

@Directive({
  selector: '[appProjectBoardColumnContextMenu]',
  standalone: false,
})
export class BoardColumnContextMenuDirective extends ContextMenuDirective {
  // Inputs
  @Input() appProjectBoardColumnContextMenu: Column;

  registerComponent() {
    return ColumnContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<ColumnContextMenuComponent>) {
    componentRef.instance.column = this.appProjectBoardColumnContextMenu;
  }
}
