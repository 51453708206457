<div
  class="popover"
  [style.width.px]="width"
>
  <div class="popover-keywords">
    Keyword:
    <app-autocomplete-input
      class="popover-keywords-input"
      label="Type to search"
      [inputFormControl]="inputControl"
      [withClear]="true"
      [useInputText]="true"
      [suggestions]="suggestions"
      [focusImmediately]="true"
      [itemTemplate]="autocompleteTemplate"
      [noBorder]="true"
    />
  </div>

  <div
    *ngIf="state.knots.length"
    class="popover-knowledge knots padding"
  >
    <div class="popover-knowledge-label">Knots:</div>
    <app-knot
      *ngFor="let knot of state.knots"
      class="popover-knowledge-item"
      [knot]="knot"
      [withControls]="false"
      [withRemove]="true"
      (onRemove)="removeKnowledgeItem(knot)"
    />
  </div>

  <div
    *ngIf="state.tags.length"
    class="popover-knowledge tags padding"
  >
    <div class="popover-knowledge-label">Tags:</div>
    <app-tag
      *ngFor="let tag of state.tags"
      class="popover-knowledge-item"
      [tag]="tag"
      [withControls]="false"
      [withRemove]="true"
      (onRemove)="removeKnowledgeItem(tag)"
    />
  </div>

  <div
    *ngIf="state.connections.length"
    class="popover-knowledge connections padding"
  >
    <div class="popover-knowledge-label">Connections:</div>
    <app-connection
      *ngFor="let connection of state.connections"
      class="popover-knowledge-item"
      [connection]="connection"
      [withControls]="false"
      [withRemove]="true"
      (onRemove)="removeKnowledgeItem(connection)"
    />
  </div>

  <div
    class="popover-advanced"
    [@heightAnimation]="advanced ? 'show' : 'hide'"
  >
    <div
      #advancedForms
      class="popover-advanced-inner"
    >
      <app-tabs
        class="popover-advanced-inner-tabs"
        selectedStateKey="search"
        [values]="tabs"
        [value]="selectedTab"
        (valueChange)="handleChangeTab($event)"
      />

      <app-advanced-forms
        *ngIf="mailForm"
        [currentApp]="selectedTab"
        [mailForm]="mailForm"
        [calendarForm]="calendarForm"
        [taskingForm]="taskingForm"
        [notesForm]="notesForm"
        [contactsForm]="contactsForm"
        [filesForm]="filesForm"
      />

      <div class="forms">
        <div class="forms-item">
          <div class="forms-item-label">
            Analyzer:
          </div>

          <div class="forms-item-input">
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="standard"
              title="Standard"
              [inputFormControl]="analyzerControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="english"
              title="English"
              [inputFormControl]="analyzerControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="ngrams_7"
              title="n-grams (1, 7)"
              [inputFormControl]="analyzerControl"
            />
          </div>
        </div>

        <div class="forms-item">
          <div class="forms-item-label">
            Priority:
          </div>

          <div class="forms-item-input">
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="title"
              title="Prioritize title"
              [inputFormControl]="priorityControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="body"
              title="Prioritize body"
              [inputFormControl]="priorityControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="none"
              title="Prioritize none"
              [inputFormControl]="priorityControl"
            />
          </div>
        </div>

        <div class="forms-item">
          <div class="forms-item-label">
            Multi match type:
          </div>

          <div class="forms-item-input">
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="best_fields"
              title="best_fields"
              [inputFormControl]="multiMatchTypeControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="most_fields"
              title="most_fields"
              [inputFormControl]="multiMatchTypeControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="cross_fields"
              title="cross_fields"
              [inputFormControl]="multiMatchTypeControl"
            />
            <app-radio-button
              class="forms-item-input-checkbox"
              appearance="checkbox"
              value="phrase"
              title="phrase"
              [inputFormControl]="multiMatchTypeControl"
            />
          </div>
        </div>

        <div class="forms-item">
          <div
            *ngIf="analyzerControl.value === 'english' && multiMatchTypeControl.value === 'phrase'"
            class="forms-item-label red"
          >
            This combination is "Exact Match" from the previous version
          </div>

          <div
            *ngIf="analyzerControl.value === 'ngrams_7' && multiMatchTypeControl.value === 'best_fields'"
            class="forms-item-label red"
          >
            This combination is "Partial Match" from the previous version
          </div>

          <div
            *ngIf="analyzerControl.value === 'english' && multiMatchTypeControl.value === 'best_fields'"
            class="forms-item-label red"
          >
            This combination is "Word Overlap" from the previous version
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="popover-label-button">
    <span (click)="toggleAdvanced()">
      {{ advanced ? 'Collapse' : 'Advanced Search' }}
    </span>
  </div>

  <div class="popover-buttons">
    <app-button
      class="popover-buttons-item"
      label="Cancel"
      appearance="square"
      size="medium"
      (click)="handleCancel()"
    />

    <app-button
      class="popover-buttons-item"
      label="Search"
      appearance="square-blue"
      size="medium"
      (click)="handleSearch()"
    />
  </div>
</div>

<ng-template #autocompleteTemplate let-item="item" let-type="type">
  <div
    *ngIf="type === 'suggestion'"
    class="suggestion-text"
  >
    {{ item }}
  </div>

  <div
    *ngIf="type === 'knots' && item.length"
    class="popover-knowledge knots"
  >
    <app-knot
      *ngFor="let knot of item"
      class="popover-knowledge-item"
      [knot]="knot"
      [withControls]="false"
      (click)="addKnowledgeItem(knot)"
    />
  </div>

  <div
    *ngIf="type === 'tags' && item.length"
    class="popover-knowledge tags"
  >
    <app-tag
      *ngFor="let tag of item"
      class="popover-knowledge-item"
      [tag]="tag"
      [withControls]="false"
      (click)="addKnowledgeItem(tag)"
    />
  </div>

  <div
    *ngIf="type === 'connections' && item.length"
    class="popover-knowledge connections"
  >
    <app-connection
      *ngFor="let connection of item"
      class="popover-knowledge-item"
      [connection]="connection"
      [withControls]="false"
      (click)="addKnowledgeItem(connection)"
    />
  </div>
</ng-template>
