<div class="connections">
  <div class="connections-title">
    Add Connections
  </div>

  <app-connections
    class="connections-existing-items"
    [stitchItems]="stitchItems"
    [control]="control"
    [withControls]="false"
    [withPlaceholder]="true"
  />

  <div
    class="connections-area"
    [class.focused]="focused"
  >
    <app-simple-input
      class="connections-input"
      placeholder="Add New Connection"
      [inputFormControl]="inputControl"
      [noBorder]="true"
      [withClear]="true"
      [suggestions]="suggestions"
      [forceOutput]="true"
      [openImmediately]="false"
      (onEnter)="addConnection()"
      (onSelect)="handleSelect($event)"
      (onFocus)="focused = true"
      (onBlur)="focused = false"
    />

    <div
      *ngIf="canAdd && suggestionsReceived"
      class="connections-add"
      (click)="addConnection()"
    >
      Add Connection «{{ inputControl.value }}»
    </div>

    <div
      *ngIf="!canAdd && connections?.length === 0"
      class="connections-placeholder"
    >
      You can add multiple Connections with enter e.g. personal, must read, educational
    </div>

    <div class="connections-list">
      <div
        *ngFor="let connection of connections | sortAndFilter : state"
        class="connections-list-item"
        [stchConnectionContextMenu]="connection"
        (stchConnectionContextMenuPinned)="handlePin(connection)"
        (stchConnectionContextMenuDelete)="handleDelete(connection)"
      >
        {{ connection.name }}

        <mat-icon
          class="connections-list-item-delete-icon"
          svgIcon="close-thin"
          (click)="handleDelete(connection)"
        />
      </div>
    </div>
  </div>

  <div class="connections-buttons">
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
