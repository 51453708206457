// Common
import { Injectable } from '@angular/core';
import { warmUpObservable } from '@decorators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Types
import { Signature } from '@modules/account/types/signature';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Environment
import { environment } from '@environment';

// Services
import { BaseService } from '@modules/common/services/base.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

@Injectable()
export class SignaturesService extends BaseService {
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {
    super();
  }

  search(_filters?: unknown, _config?: object): Observable<BaseSearchResponse<Signature>> {
    return this.refreshRequired.pipe(
      switchMap(() =>
        this.http.get<{ count: number; items: Signature[] }>(environment.baseUrl + '/api/mail/signatures'),
      ),
      map(({ count, items = [] }) => ({ count: count, items: items.map((signature) => new Signature(signature)) })),
    );
  }

  create(instance: Signature): Observable<Signature> {
    return this.http
      .post<{
        success: boolean;
        data: Signature;
      }>(environment.baseUrl + '/api/mail/signatures', { signature: instance.asPayloadJSON() })
      .pipe(map((response) => new Signature(response.data)));
  }

  update(instance: Signature): Observable<Signature> {
    return this.http
      .put<{
        success: boolean;
        data: Signature;
      }>(environment.baseUrl + '/api/mail/signatures/' + instance.id, { signature: instance.asPayloadJSON() })
      .pipe(map((response) => new Signature(response.data)));
  }

  @warmUpObservable
  upsert(instance: Signature, feedbackConfig: FeedbackConfig = { emit: true }): Observable<Signature> {
    return (instance.id ? this.update(instance) : this.create(instance)).pipe(
      tap(() => {
        feedbackConfig.emit && this.forceRefresh();
        feedbackConfig.displayToast &&
          this.toasterService.show({
            text: feedbackConfig.toastMessage || `Signature ${instance.id ? 'updated' : 'created'} successfully`,
          });
      }),
      catchError((error: HttpErrorResponse) => {
        this.toasterService.show({ text: error.error.error });
        return throwError(error);
      }),
    );
  }

  @warmUpObservable
  delete(instance: Signature, feedbackConfig: FeedbackConfig = { emit: true }): Observable<boolean> {
    return this.http.delete<boolean>(environment.baseUrl + '/api/mail/signatures/' + instance.id).pipe(
      tap(() => {
        feedbackConfig.emit && this.forceRefresh();
        feedbackConfig.displayToast &&
          this.toasterService.show({
            text: feedbackConfig.toastMessage || 'Signature removed successfully',
          });
      }),
      catchError((error: HttpErrorResponse) => {
        this.toasterService.show({ text: error.error.error });
        return throwError(error);
      }),
    );
  }
}
