import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToWords',
  standalone: false,
})
export class CamelCaseToWordsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const words = value.replace(/([a-z])([A-Z])/g, '$1 $2');

    return words.charAt(0).toUpperCase() + words.slice(1);
  }
}
