import { Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class MessageTemplate {
  formBuilder = injector.get(FormBuilder);

  id: string;
  content: string;
  title: string;

  constructor(data: Partial<MessageTemplate> = {}) {
    this.id = data.id;
    this.title = data.title || '';
    this.content = data.content || '';
  }

  static fromFormGroup(form: FormGroup): MessageTemplate {
    const { value: formValues } = form;

    return new MessageTemplate({
      id: formValues.id,
      title: formValues.title,
      content: formValues.content,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: this.id,
      title: this.title,
      content: this.content,
    });
  }

  public asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
    };
  }
}
