// Common
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// RX
import { switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-event-quick-form',
  templateUrl: './event-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './event-quick-form.component.less'],
  standalone: false,
})
export class EventQuickFormComponent extends BaseQuickFormComponent implements OnInit, OnChanges, OnDestroy {
  // Inputs
  @Input() event: CalendarEvent = new CalendarEvent();
  @Input() calendar: Calendar;
  @Input() withMore = true;
  @Input() withCancel = true;

  // Outputs
  @Output() eventChange = new EventEmitter<CalendarEvent>();
  @Output() more: EventEmitter<CalendarEvent> = new EventEmitter<CalendarEvent>();
  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<CalendarEvent>();

  // Public
  public calendarsSuggestions: AutocompleteFactory<Calendar>;
  public popoverClose = new Subject<void>();

  // Private
  private changed = new BehaviorSubject<void>(null);

  /**
   * Constructor
   */
  constructor(
    private calendarsService: CalendarsService,
    private eventsService: EventsService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(changeDetector);
    this.reset();
    this.calendarsSuggestions = this.calendarsService.getAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.changed
      .pipe(
        switchMap(() => this.form.valueChanges),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.eventChange.emit(CalendarEvent.fromFormGroup(this.form));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('event' in changes) {
      this.reset();
    }

    if ('calendar' in changes && this.calendar) {
      this.form.patchValue({ calendarId: this.calendar.id });
    }
  }

  ngOnDestroy() {
    this.changed.complete();
    super.ngOnDestroy();
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const event = CalendarEvent.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(event);
      return;
    }

    this.saveInProgress = true;

    this.eventsService
      .create(event)
      .pipe(takeUntil(this.alive))
      .subscribe(
        (eventInstance) => {
          this.afterSave.emit(eventInstance);
          this.close.emit();
        },
        () => this.handleError(),
      );
  }

  reset() {
    if (!this.event) {
      return;
    }
    this.form = this.event.asFormGroup();
    this.changed.next();
    super.reset();
  }

  clickMore() {
    this.more.emit(CalendarEvent.fromFormGroup(this.form));
    super.clickMore();
  }
}
