// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Knot } from '../types/knot';
import { ManageListState } from '../types/manage-list-state';

@Pipe({
  name: 'sortAndFilter',
  standalone: false,
})
export class SortAndFilterPipe implements PipeTransform {
  transform(knots: Knot[] = [], state: ManageListState): Knot[] {
    return knots
      .filter(
        (knot) =>
          !knot.deleted &&
          (!state?.filters?.createdFrom || knot.createdAt >= state.filters.createdFrom) &&
          (!state?.filters?.createdTo || knot.createdAt <= state.filters.createdTo),
      )
      .sort((a, b) => {
        const reverse = state?.sort?.order === 'desc' ? -1 : 1;

        switch (state?.sort?.by) {
          case 'date':
            return (a.createdAt > b.createdAt ? 1 : -1) * reverse;
          case 'name':
            return (a.name > b.name ? 1 : -1) * reverse;
        }
      });
  }
}
