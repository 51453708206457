<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Contacts"
  [active]="filterKey === 'all_contacts'"
  (execute)="selectFilter('all_contacts')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="VIP"
  [active]="filterKey === 'vip'"
  (execute)="selectFilter('vip')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Flagged"
  [active]="filterKey === 'flagged'"
  (execute)="selectFilter('flagged')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Recent"
  [active]="filterKey === 'recent'"
  (execute)="selectFilter('recent')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Follow Up"
  [active]="filterKey === 'followed'"
  (execute)="selectFilter('followed')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Archive"
  [active]="filterKey === 'archived'"
  (execute)="selectFilter('archived')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Trash"
  [active]="filterKey === 'deleted'"
  (execute)="selectFilter('deleted')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Groups"
  [active]="filterKey === 'all_groups'"
  (execute)="selectFilter('all_groups')"
/>
