// Common
import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil, switchMap, filter, startWith } from 'rxjs/operators';

// Types
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';

@Component({
  selector: 'app-notification-picker',
  templateUrl: './notification-picker.component.html',
  styleUrls: ['./notification-picker.component.less'],
  standalone: false,
})
export class NotificationPickerComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() inputFormGroup: ReminderFormGroup;

  // Output
  @Output() save = new EventEmitter<Reminder>();
  @Output() delete = new EventEmitter();

  // Public
  public options = {
    minutes: [5, 10, 20, 30, 40],
    hours: [1, 2, 3, 4, 5],
    days: [1, 2, 3, 4, 5],
    weeks: [1, 2, 3],
  };
  public popoverClose = new Subject<void>();
  public innerFormGroup = new Reminder().asFormGroup();

  // Private
  private alive = new Subject<void>();
  private inputFormGroupChanged = new Subject<void>();

  /**
   * Constructor
   */

  constructor() {
    this.innerFormGroup.controls.units.valueChanges.pipe(takeUntil(this.alive)).subscribe((units) => {
      if (!(this.options && units && this.options[units].includes(this.innerFormGroup.value.duration))) {
        this.setValue(null);
      }
    });

    this.inputFormGroupChanged
      .pipe(
        filter(() => !!this.inputFormGroup),
        switchMap(() => this.inputFormGroup.valueChanges.pipe(startWith(this.inputFormGroup.value))),
        takeUntil(this.alive),
      )
      .subscribe((value) => {
        this.innerFormGroup.patchValue(value, { emitEvent: false });
      });
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormGroup' in changes) {
      this.inputFormGroupChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.popoverClose.next();
  }

  handleSave() {
    if (this.inputFormGroup) {
      this.innerFormGroup.controls.id.setValue(null);
      this.inputFormGroup.patchValue(this.innerFormGroup.value);
    } else {
      this.save.emit(Reminder.fromFormGroup(this.innerFormGroup));
    }

    this.popoverClose.next();
  }

  setValue(option: number) {
    this.innerFormGroup.controls.duration.setValue(option);
  }

  handlePopoverVisibleChange(visible: boolean) {
    if (visible) {
      this.inputFormGroupChanged.next();
    }
  }
}
