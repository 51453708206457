import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { environment } from '@environment';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { EventComponent } from '@modules/elements/components/event/event.component';
import { KnotSource } from '@modules/knots/types/knot-source';
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class EventsListComponent
  extends BaseStitchListComponent<CalendarEvent>
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  public override filters: Partial<EventsFilters> = {};
  @Input()
  public withTags = false;
  @Input()
  public withKnots = false;
  @Input()
  public knotsSource: KnotSource;
  @Input()
  public scrollToDay: Observable<Date>;
  @Input()
  public debug: 'score';
  @Input()
  public notFoundTemplate?: TemplateRef<void>;

  @Output()
  public readonly clickEvent = new EventEmitter<CalendarEvent>();
  @Output()
  public readonly openEvent = new EventEmitter<CalendarEvent>();
  @Output()
  public readonly doubleClickEvent = new EventEmitter<CalendarEvent>();

  @ViewChildren(EventComponent, { read: ElementRef<HTMLElement> })
  public override itemElements: QueryList<ElementRef<HTMLElement>>;

  public override items: StitchListItem<CalendarEvent>[] = [];

  private readonly eventsService = inject(EventsService);
  private readonly eventsStateService = inject(CalendarAppStateService);

  public override ngOnInit() {
    merge(
      this.eventsStateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.eventsService.getRefresh(),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadPage(this.page);
      });

    merge(this.eventsStateService.getRefreshAll(), this.eventsService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: CalendarEvent[]; count: number }> {
    return this.eventsService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  public handleDoubleClickItem(appointment: CalendarEvent) {
    if (!appointment) {
      return;
    }
    this.doubleClickEvent.emit(appointment);
  }

  public handleClickItem(appointment: CalendarEvent) {
    if (!appointment) {
      return;
    }
    this.clickEvent.emit(appointment);
  }
}
