// Common
import { Component, OnDestroy, Input, TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';

// RxJS
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

// Service
import { HistoryService } from '@modules/common/services/history.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { ConnectionsService } from '@modules/connections/services/connections.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Connection } from '@modules/connections/types/connection';
import { ManageListState } from '@modules/connections/types/manage-list-state';
import { ConnectionsFilters } from '@modules/connections/types/connections-filters';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';

// Components
import { TabsComponent } from '@modules/common/components/tabs/tabs.component';

@Component({
  selector: 'app-connections-knowledge-section',
  templateUrl: './connections-section.component.html',
  styleUrls: ['./connections-section.component.less'],
  standalone: false,
})
export class KnowledgeConnectionsSectionComponent implements OnInit, OnDestroy {
  @Input() tabs: TemplateRef<TabsComponent>;

  public StitchType = StitchType;
  public state: ManageListState;
  public itemTags: Tag[] = [];
  public itemKnots: Knot[] = [];
  public itemConnections: Connection[] = [];
  public neighborsConnections: Connection[] = [];
  public stitchItem: Stitch;
  public pagesCount = 0;
  public page = 0;
  public perPage = 20;
  public knotsHistory: Knot[] = [];
  public tagsHistory: Tag[] = [];
  public connectionsHistory: Connection[] = [];

  private alive = new Subject<void>();
  private loadNeighbors = new Subject<void>();

  constructor(
    private historyService: HistoryService,
    private kpService: KnowledgePanelService,
    private connectionsService: ConnectionsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.historyService
      .onChanges()
      .pipe(takeUntil(this.alive))
      .subscribe(({ connections, knots, tags }) => {
        this.knotsHistory = knots;
        this.tagsHistory = tags;
        this.connectionsHistory = connections;
        this.page = 0;
        this.changeDetector.detectChanges();
        this.loadNeighbors.next();
      });

    this.loadNeighbors
      .pipe(
        debounceTime(300),
        map(() => [
          this.knotsHistory.length ? this.knotsHistory : this.itemKnots,
          this.tagsHistory.length ? this.tagsHistory : this.itemTags,
          this.connectionsHistory.length ? this.connectionsHistory : this.itemConnections,
        ]),
        switchMap(([knots, tags, connections]) =>
          knots.length || tags.length || connections.length
            ? this.connectionsService.getNeighbors({
                ...ConnectionsFilters.fromListState(this.state),
                names: connections.map((t) => t.name),
                tags,
                knots,
                offset: this.page * this.perPage,
                limit: this.perPage,
              })
            : of({ connections: [], count: 0 }),
        ),
        takeUntil(this.alive),
      )
      .subscribe(({ connections, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.neighborsConnections.length = count;
        this.neighborsConnections = [
          ...this.neighborsConnections.slice(0, this.page * this.perPage),
          ...connections,
          ...this.neighborsConnections.slice((this.page + 1) * this.perPage, count),
        ].filter((i) => !!i);
      });

    this.kpService
      .getSelectedStitchItem()
      .pipe(takeUntil(this.alive))
      .subscribe((stitchItem) => {
        this.stitchItem = stitchItem;
      });

    combineLatest([
      this.kpService.getStitchItemTags(),
      this.kpService.getStitchItemKnots(),
      this.kpService.getStitchItemConnections(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([tags, knots, connections]) => {
        this.itemTags = tags;
        this.itemKnots = knots;
        this.itemConnections = connections;
        this.page = 0;
        this.loadNeighbors.next();
      });
  }

  ngOnDestroy() {
    this.loadNeighbors.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClickItem(connection: Connection) {
    this.kpService.addConnectionToSelection(connection, null, false);
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);
    if (!this.neighborsConnections[this.page * this.perPage]) {
      this.loadNeighbors.next();
    }
  }

  showLess() {
    this.page = 0;
  }

  setState(state: ManageListState) {
    this.page = 0;
    this.state = state;
    this.loadNeighbors.next();
  }
}
