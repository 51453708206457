// Common
import { Component, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isEqual } from 'lodash';

// RX
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Connection } from '@modules/connections/types/connection';
import { ConnectionsFilters } from '@modules/connections/types/connections-filters';
import { ManageListState } from '@modules/connections/types/manage-list-state';
import { Stitch } from '@modules/common/types/stitch';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { ConnectionsService } from '@modules/connections/services/connections.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { FullFormService } from '@modules/messages/services/full-form.service';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.less'],
  standalone: false,
})
export class ConnectionsComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() stitchItems: Stitch[];
  @Input() control = new UntypedFormControl();
  @Input() withControls = true;
  @Input() withPlaceholder = false;
  @Input() stateKey: StateKey;

  // Public
  public hidePopover = new Subject<void>();
  public connections: Connection[] = [];
  public selectedConnections: Connection[];
  public pagesCount = 0;
  public page = 0;
  public perPage = 20;
  public state: ManageListState;

  // Private
  private alive = new Subject<void>();
  private loadPage = new Subject<void>();

  constructor(
    private connectionsService: ConnectionsService,
    private kpService: KnowledgePanelService,
    private ffService: FullFormService,
  ) {
    this.loadPage
      .pipe(
        switchMap(() => this.connectionsService.getRefreshRequired()),
        debounceTime(300),
        switchMap(() =>
          this.stitchItems?.filter(({ id }) => !!id)?.length
            ? this.connectionsService.search({
                ...ConnectionsFilters.fromListState(this.state),
                items: this.stitchItems,
                offset: this.page * this.perPage,
                limit: this.perPage,
              })
            : (this.control?.valueChanges || of([{ items: [] }])).pipe(
                startWith(this.control?.value || [{ items: [] }]),
                map((items) => ({ items, count: items.length })),
              ),
        ),
        takeUntil(this.alive),
      )
      .subscribe(({ items: connections, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.connections.length = count;
        this.connections = [
          ...this.connections.slice(0, this.page * this.perPage),
          ...connections,
          ...this.connections.slice((this.page + 1) * this.perPage, count),
        ].filter((i) => !!i);

        this.notifyVisibleConnectionsChanged();
      });

    this.kpService
      .getSelectedConnections()
      .pipe(takeUntil(this.alive))
      .subscribe((connections: Connection[]) => {
        this.selectedConnections = connections;
      });
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if (
      'stitchItems' in changes &&
      !isEqual(
        changes.stitchItems.previousValue?.map(({ id }) => id),
        changes.stitchItems.currentValue?.map(({ id }) => id),
      )
    ) {
      this.page = 0;
      this.connections = [];
      this.loadPage.next();
    }
  }

  ngOnDestroy() {
    this.loadPage.complete();
    this.hidePopover.next();
    this.hidePopover.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClick(connection: Connection, event: MouseEvent) {
    this.kpService.addConnectionToSelection(connection, event, true);
  }

  closePopovers() {
    this.hidePopover.next();
  }

  saveConnections(connections: Connection[]) {
    this.control?.setValue(
      connections.reduce(
        (acc: Connection[], item) =>
          acc.find((existingItem) => existingItem.name === item.name) ? acc : [...acc, item],
        this.control?.value || [],
      ),
    );

    const existingStitchItems = this.stitchItems.filter(({ id }) => id);
    if (existingStitchItems.length === 0) {
      return;
    }

    const newConnections = connections.filter(
      (connection) => (connection.added && connection.id === undefined) || connection.id === null,
    );
    const changedConnections = connections.filter(
      (connection) => connection.changed && connection.id !== undefined && connection.id !== null,
    );
    const linkedConnections = connections.filter((connection) => connection.added);
    const unlinkedConnections = connections.filter((connection) => connection.deleted);

    combineLatest([
      newConnections.length ? this.connectionsService.createBulk(newConnections, false, false) : of(true),
      changedConnections.length ? this.connectionsService.update(changedConnections, false, false) : of(true),
    ])
      .pipe(
        switchMap(([created, updated]) =>
          created && updated
            ? combineLatest([
                linkedConnections.length
                  ? this.connectionsService.link(linkedConnections, existingStitchItems, false, false)
                  : of(true),
                unlinkedConnections.length
                  ? this.connectionsService.unlink(unlinkedConnections, existingStitchItems, false, false)
                  : of(true),
              ])
            : of(null),
        ),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.connectionsService.forceRefresh();
        this.closePopovers();
      });
  }

  handleDelete(connection: Connection) {
    if (this.stitchItems?.length) {
      this.connectionsService
        .unlink([connection], this.stitchItems)
        .pipe(takeUntil(this.alive))
        .subscribe((success: boolean) => {
          success && this.setRemovedValue(connection);
        });
    } else {
      this.setRemovedValue(connection);
    }
  }

  setRemovedValue(connection: Connection) {
    if (!this.stitchItems?.length) {
      this.control.setValue(this.control.value.filter((item) => item.name !== connection.name));
    }
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);

    if (this.connections[this.page * this.perPage]) {
      this.notifyVisibleConnectionsChanged();
    } else {
      this.loadPage.next();
    }
  }

  showLess() {
    this.page = 0;
    this.notifyVisibleConnectionsChanged();
  }

  notifyVisibleConnectionsChanged() {
    this.kpService.setStitchItemConnections(this.connections.slice(0, (this.page + 1) * this.perPage));
  }

  setState(state: ManageListState) {
    this.page = 0;
    this.connections = [];
    this.state = state;
    this.loadPage.next();
  }
}
