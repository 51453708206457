// Common
import { Component, Input, TemplateRef, ElementRef, EventEmitter, Output } from '@angular/core';

// Services
import { StateService } from '@modules/settings/services/state.service';

// RX
import { Observable } from 'rxjs';

// Types
import { Icon } from '@modules/icons/types/icons';

export interface SideBarItemAction {
  icon: Icon;
  template: TemplateRef<unknown>;
  context?: object;
  until: Observable<void>;
}

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.less'],
  standalone: false,
})
export class SidebarItemComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() active: boolean;
  @Input() hover: boolean;
  @Input() badge: { template: TemplateRef<any>; context: object };
  @Input() iconColor = '';
  @Input() depth = 0;
  @Input() expandable = false;
  @Input() expanded: boolean;
  @Input() actions: SideBarItemAction[] = [];
  @Input() minimized: boolean;
  @Input() withCheckbox: boolean;
  @Input() checked: boolean;
  @Input() iconPadding = true;

  @Output() onCheck = new EventEmitter<void>();
  @Output() onExpand = new EventEmitter<boolean>();

  constructor(
    protected stateService: StateService,
    public elementRef: ElementRef, // Accessible from outside
  ) {}

  /**
   * Actions
   */

  trigger(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.onExpand.emit(!this.expanded);
  }
}
