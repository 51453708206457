// Common
import { Injectable, Injector } from '@angular/core';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Task } from '../types/task';
import { Project } from '../types/project';
import { ListState } from '@modules/tasks/types/list-state';
import { VirtualFolder } from '../types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

@Injectable()
export class TaskingAppStateService extends BaseAppStateService<Project, Task, VirtualFolder> {
  protected application = Application.tasking;
  protected defaultVirtualFolder: VirtualFolder = 'all_tasks';

  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Actions
   */

  getTabs(): Observable<ListState> {
    return this.getVirtualFolder().pipe(
      map((folder) => {
        switch (folder) {
          case 'all_tasks':
          case 'scheduled':
          case 'unscheduled':
            return 'tasks';
          case 'all_projects':
            return 'projects';
          case 'priority':
          case 'low':
          case 'normal':
          case 'high':
          case 'today':
          case 'week':
          case 'day0':
          case 'day1':
          case 'day2':
          case 'day3':
          case 'day4':
          case 'day5':
          case 'day6':
          case 'followed':
          case 'snoozed':
          case 'flagged':
          case 'overdue':
          case 'archived':
          case 'deleted':
            return 'tabs';
          default:
            return 'tasks';
        }
      }),
    );
  }
}
