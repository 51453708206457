// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// Types
import { Space } from '@modules/settings/types/space';
import { SpaceRole, SpaceRoleForm } from '@modules/settings/types/space-role';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Services
import { SpaceRolesService } from '@modules/settings/services/space-roles.service';

@Component({
  selector: 'app-settings-spaces-space-role-form',
  templateUrl: './space-role-form.component.html',
  styleUrls: ['./space-role-form.component.less'],
  standalone: false,
})
export class SpacesSpaceRoleFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() space: Space;

  public form: SpaceRoleForm;
  public submitted = false;

  private alive = new Subject<void>();

  constructor(
    @Inject(CloseToken) private closeToken,
    private spaceRolesService: SpaceRolesService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('space' in changes) {
      this.createForm();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  createForm() {
    this.form = new SpaceRole({ spaceId: this.space.id }).asFormGroup();
  }

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const item = SpaceRole.fromFormGroup(this.form);

    this.spaceRolesService
      .create(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.closeToken.next();
      });
  }
}
