// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';

// RX
import { take, takeUntil } from 'rxjs/operators';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarsFilters } from '@modules/calendar-app/types/calendars-filters';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { StateKey } from '@modules/settings/types/state-key';

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';

@Component({
  selector: 'app-side-bar-calendars-tree',
  templateUrl: './side-bar-calendars-tree.component.html',
  styleUrls: ['./side-bar-calendars-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService],
  standalone: false,
})
export class SideBarCalendarsTreeComponent
  extends BaseSidebarContainersTreeComponent<Calendar, CalendarEvent, CalendarsFilters, VirtualFolder>
  implements OnInit
{
  public fullCalendarViewIds: string[] = [];
  selfDragDataTypes = [DragDataTypes.event, DragDataTypes.calendar];
  application = Application.calendar;
  treeStateKey = StateKey.calendarSidebarCalendarsTree;

  public dndPredicate =
    (stitchItem: Stitch) =>
    (dragData: DragData): boolean =>
      stitchItem &&
      !(
        dragData.type === DragDataTypes.calendar &&
        dragData.data.length === 1 &&
        dragData.data?.[0]?.id === stitchItem?.id
      ) &&
      dragDataTypeAllowed(dragData.type);

  constructor(
    private calendarsService: CalendarsService,
    private eventsService: EventsService,
    private calendarAppStateService: CalendarAppStateService,
  ) {
    super(calendarsService, calendarAppStateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.calendarAppStateService
      .getFullCalendarView()
      .pipe(takeUntil(this.alive))
      .subscribe((ids: string[]) => (this.fullCalendarViewIds = ids));
  }

  /**
   * Actions
   */

  onItemsReceived() {
    const ids = this.items.map(({ id }) => id);

    const nonExistentItems = this.fullCalendarViewIds.filter((id) => !ids.includes(id));

    if (nonExistentItems.length === this.fullCalendarViewIds.length) {
      return;
    }

    this.calendarAppStateService.setFullCalendarView(
      this.fullCalendarViewIds.filter((id) => !nonExistentItems.includes(id)),
    );
  }

  checkFullCalendarView(calendar: Calendar) {
    this.calendarAppStateService.setFullCalendarView(
      this.fullCalendarViewIds.includes(calendar.id)
        ? this.fullCalendarViewIds.filter((id) => id !== calendar.id)
        : [...this.fullCalendarViewIds, calendar.id],
    );
  }

  public handleMove(dragData: DragData, item: Calendar) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.event) {
      this.eventsService.bunchUpdate({ ids: this.getIds(dragData.data) }, { calendarId: item.id }, { message });
    } else if (dragData.type === DragDataTypes.calendar) {
      this.calendarsService.bunchUpdate({ ids: this.getIds(dragData.data) }, { parentId: item.id }, { message });
    }
  }

  public handleDuplicate(dragData: DragData, calendar: Calendar) {
    if (dragData.type === DragDataTypes.event) {
      this.eventsService.duplicate(this.getIds(dragData.data), calendar.id).pipe(take(1)).subscribe();
    } else if (dragData.type === DragDataTypes.calendar) {
      this.calendarsService.duplicate(this.getIds(dragData.data)).pipe(take(1)).subscribe();
    }
  }
}
