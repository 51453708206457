export enum NotificationsType {
  snoozedMessage = 'snoozedMessage',
  snoozedMessageFolder = 'snoozedMessageFolder',
  snoozedTask = 'snoozedTask',
  snoozedProject = 'snoozedProject',
  snoozedNote = 'snoozedNote',
  snoozedNotebook = 'snoozedNotebook',
  snoozedFile = 'snoozedFile',
  snoozedFolder = 'snoozedFolder',
  snoozedEvent = 'snoozedEvent',
  snoozedCalendar = 'snoozedCalendar',
  snoozedContact = 'snoozedContact',
  snoozedGroup = 'snoozedGroup',

  followedProject = 'followedProject',
  followedTask = 'followedTask',
  followedMessage = 'followedMessage',
  followedMessageFolder = 'followedMessageFolder',
  followedFolder = 'followedFolder',
  followedFile = 'followedFile',
  followedContact = 'followedContact',
  followedGroup = 'followedGroup',
  followedNote = 'followedNote',
  followedNotebook = 'followedNotebook',
  followedEvent = 'followedEvent',
  followedCalendar = 'followedCalendar',

  startedTask = 'startedTask',
  startedProject = 'startedProject',
  startedEvent = 'startedEvent',
  dueTask = 'dueTask',
  dueProject = 'dueProject',
  dueEvent = 'dueEvent',
  commented = 'commented',
  spaceInvited = 'space-invited',
  spaceDeleted = 'space-deleted',
  spaceAccepted = 'space-accepted',
  spaceRejected = 'space-rejected',
  spaceRoleChanged = 'space-roleChanged',

  newMessage = 'newMessage',
}

export type ButtonsActionTypes = 'followed' | 'snoozed' | 'space-accept' | 'space-reject';
