<div
  *ngIf="contact"
  class="contact"
  [class.no-actions]="!withActions"
  [class.pinned]="contact.pinned"
  [class.short-view]="viewType === 'short'"
  [class.with-description]="contact.description"
  [class.with-linked-info]="contact.linkedInfo?.length"

  [appContactContextMenu]="contact"
  [appContactContextMenuDisabled]="!withContextMenu"
  (appContactContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [contact], type: 'contact'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <app-user-avatar
    class="contact-avatar-icon"
    size="28"
    [contact]="{ name: contact.title, email: contact.address }"
    [usePopover]="false"
  />

  <div class="contact-body">
    <div class="contact-body-heading">
      <div class="contact-body-heading-title">
        {{ contact.title || 'No Name' }}
      </div>
    </div>

    <div class="contact-body-date">
      <div class="contact-body-date-item">
        {{ contact.address || 'No Email'}}
      </div>
    </div>

    <div class="contact-body-description" *ngIf="contact.description && viewType !== 'short'">
      {{ contact.description }}
    </div>

    <app-link-info-bar
      class="contact-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="contact"
    />
  </div>

  <mat-icon
    class="contact-pin-icon"
    [class.active]="contact.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  />

  <mat-icon
    class="contact-flag-icon"
    [class.active]="contact.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  />

  <mat-icon class="contact-marker" svgIcon="contacts" />
  <mat-icon class="contact-attachments-icon" *ngIf="contact.files?.length" svgIcon="attachment" />

  <div
    class="contact-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="contact"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    />
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="archive"
      [class.active]="contact.archived"
      [matTooltip]="contact?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    />
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="trash"
      [class.active]="contact.deleted"
      [matTooltip]="contact?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div
    class="contact-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="trash"
      [class.active]="contact.deleted"
      [matTooltip]="contact?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div class="contact-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="contact-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    />
  </div>

  <div class="drop-zone"></div>
</div>

<div
  class="contact placeholder"
  *ngIf="!contact"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-contact
    [contact]="contact"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  />
</ng-template>
