// Common
import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

// RX
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { BaseStitchChildService } from '@modules/common/services/base-stitch-child.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { SpacesService } from '@modules/settings/services/spaces.service';

// Types
import { Like } from '@modules/common/types/like';
import { Task } from '../types/task';
import { TasksFilters } from '../types/tasks-filters';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';
import { KnowledgeFlags } from '@modules/common/types/knowledge-flags';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';

// Decorators
import { warmUpObservable } from '@decorators';
import { SpaceParticipant } from '@modules/settings/types/space-participant';

@Injectable()
export class TasksService extends BaseStitchChildService<Task, TasksFilters> implements CalendarEventsService {
  protected url = environment.baseUrl + '/api/tasking/tasks/';
  protected stitchType = StitchType.task;
  protected filtersConstructor = TasksFilters;

  constructor(
    protected http: HttpClient,
    @Optional() linkedInfoService: LinkedInfoService,
    protected toasterService: ToasterService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
    @Optional() private spacesService: SpacesService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<TasksFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Task, 'projectId' | 'parentId'>>,
    config: FeedbackConfig,
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  search(filters?: Partial<TasksFilters>, config?: KnowledgeFlags): Observable<BaseSearchResponse<Task>> {
    return (this.spacesService?.getCurrentSpace() || of(null)).pipe(
      switchMap((space) =>
        super
          .search(filters, config)
          .pipe(map(({ items, count }) => ({ items: items.map((task) => new Task({ ...task }, space)), count }))),
      ),
    );
  }

  create(taskInstance: Task, { emit, toast, message }: FeedbackConfig = { emit: true }): Observable<Task> {
    return this.http
      .post<{ task: Task; success: boolean }>(`${environment.baseUrl}/api/tasking/tasks`, taskInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Task created.` });
        }),
        map(({ task }) => new Task(task)),
        switchMap((task) =>
          this.processKnowledgeItems(
            {
              linkedInfo: taskInstance.linkedInfo,
              knots: taskInstance.knots,
              tags: taskInstance.tags,
            },
            task,
          ),
        ),
        switchMap((task) =>
          this.processUploads(taskInstance.uploads, task, { other: true, parentFolderName: 'Task App' }),
        ),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  update(taskInstance: Task, { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true }): Observable<Task> {
    return this.http
      .put<{
        task: Task;
        success: boolean;
      }>(environment.baseUrl + '/api/tasking/tasks/' + taskInstance.id, taskInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Task updated' });
          }
        }),
        map(({ task }) => task),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  reorderBoard(tasks: Task[], config?: FeedbackConfig): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(environment.baseUrl + '/api/tasking/tasks/reorder-board', {
        tasks: tasks.map(({ id, boardPosition, projectId, columnId, rowId, parentId }) => ({
          id,
          boardPosition,
          projectId,
          columnId,
          rowId,
          parentId,
        })),
      })
      .pipe(
        tap(({ success }) => {
          success && config?.emit && this.forceRefresh();
        }),
        map(({ success }) => success),
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime }).pipe(
      map(({ items }) => items.map((task) => task.asAngularCalendarEvent())),
    );
  }

  getAutocompleteSuggestions(inputFilters: Like<TasksFilters> = {}): AutocompleteFactory<Task> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<TasksFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters,
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title) {
        filters.query = title;
        filters.esAnalyzer = 'ngrams_7';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters).pipe(
        map(({ items }) =>
          items.map((task) => ({
            title: task.title,
            value: task.id,
            source: task,
          })),
        ),
      );
    };
  }

  // TODO move to SpacesService
  getAssigneeAutocompleteSuggestions(): AutocompleteFactory<SpaceParticipant> {
    return (_title?: string, values?: string[], _config?: { limit: number }) => {
      return this.spacesService.getCurrentSpace().pipe(
        map(({ participants }) =>
          participants.map((item) => ({
            title: item.fullName,
            value: item.id,
            icon: item.avatar,
            source: item,
          })),
        ),
        map((participants) =>
          values?.length ? participants.filter(({ value }) => values.includes(value)) : participants,
        ),
      );
    };
  }

  createInstance(item): Task {
    return new Task(item);
  }
}
