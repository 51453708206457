import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { NoteComponent } from '@modules/elements/components/note/note.component';
import { KnotSource } from '@modules/knots/types/knot-source';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { Note } from '@modules/notes/types/note';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class NotesListComponent extends BaseStitchListComponent<Note> implements OnInit, OnDestroy, OnChanges {
  @Input()
  public override filters: Partial<NotesFilters> = {};
  @Input()
  public withTags = false;
  @Input()
  public withKnots = false;
  @Input()
  public knotsSource: KnotSource;
  @Input()
  public debug: 'score';

  @Output()
  public readonly clickNote = new EventEmitter<Note>();
  @Output()
  public readonly openNote = new EventEmitter<Note>();
  @Output()
  public readonly doubleClickNote = new EventEmitter<Note>();

  @ViewChildren(NoteComponent, { read: ElementRef<HTMLElement> })
  public override itemElements: QueryList<ElementRef<HTMLElement>>;

  public override items: StitchListItem<Note>[] = [];

  private readonly notesService = inject(NotesService);
  private readonly linkedInfoService = inject(LinkedInfoService);
  private readonly notesStateService = inject(NotesAppStateService);

  public override ngOnInit() {
    merge(this.linkedInfoService.getRefresh(), this.notesService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadPage(this.page);
      });

    merge(this.notesStateService.getRefreshAll(), this.notesService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: Note[]; count: number }> {
    return this.notesService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  public handleDoubleClickItem(note: Note) {
    this.doubleClickNote.emit(note);
  }

  public handleClickItem(note: Note) {
    this.clickNote.emit(note);
  }
}
