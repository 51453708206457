import { Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypedFormGroup } from '@modules/common/utils/form';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export type QuickReplyTemplateFormGroup = FormGroup<
  TypedFormGroup<{
    title: string;
    content: string;
    id: string;
  }>
>;

export class QuickReplyTemplate {
  formBuilder = injector.get(FormBuilder);

  id: string;
  content: string;
  title: string;

  constructor(data: Partial<QuickReplyTemplate> = {}) {
    this.id = data.id;
    this.title = data.title || '';
    this.content = data.content || '';
  }

  static fromFormGroup(form: FormGroup): QuickReplyTemplate {
    const { value: formValues } = form;

    return new QuickReplyTemplate({
      id: formValues.id,
      title: formValues.title,
      content: formValues.content,
    });
  }

  public asFormGroup(): QuickReplyTemplateFormGroup {
    return this.formBuilder.group({
      id: this.id,
      title: [this.title, Validators.required],
      content: this.content,
    });
  }

  public asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
    };
  }
}
