// Common
import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';

// Types
import { Message } from '@modules/messages/types/message';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { MessagesService } from '@modules/messages/services/messages.service';
import { StateService } from '@modules/settings/services/state.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-link-info-message',
  templateUrl: './link-info-message.component.html',
  styleUrls: ['./link-info-message.component.less'],
  standalone: false,
})
export class LinkInfoMessageComponent implements OnDestroy {
  // Inputs
  @Input() message: Message;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.message &&
    !(
      dragData.type === DragDataTypes.message &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.message.id
    ) &&
    dragDataTypeAllowed(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private messagesService: MessagesService,
    private stateService: StateService,
    private stitchService: StitchService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.messagesService
      .pin({ ids: [this.message.id] }, !this.message.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.message.pinned = !this.message.pinned));
  }

  archive() {
    this.messagesService
      .archive({ ids: [this.message.id] }, !this.message.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.message.archived = !this.message.archived));
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.message.deleted
        ? this.messagesService.deletePermanently({ ids: [this.message.id] })
        : this.messagesService.delete({ ids: [this.message.id] }, true);
    }
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.messagesService
      .flag({ ids: [this.message.id] }, !this.message.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.message.flagged = !this.message.flagged));
  }

  public dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.message, dragData);
  }

  public snoozeMessage() {
    // TODO
  }

  public removeSnoozeMessage() {
    // TODO
  }

  public followUpMessage() {
    // TODO
  }

  public removeFollowUpMessage() {
    // TODO
  }

  public unreadMessage() {
    // TODO
  }
}
