import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[stchRerender]',
  standalone: false,
})
export class RerenderDirective {
  @Input() set stchRerender(value) {
    if (value === undefined) {
      return;
    }

    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}
}
