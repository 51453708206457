// Common
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

// Types
import { Priority } from '@modules/tasks/types/priority';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.less'],
  standalone: false,
})
export class CircularProgressComponent implements OnInit, OnChanges {
  //  Inputs
  @Input() progress = 0;
  @Input() size = 16;
  @Input() checkmark = true;
  @Input() priority: Priority; // For default appearance only
  @Input() completed: boolean;
  @Input() appearance: 'default' | 'sapphire' = 'default';

  @Output() onClick = new EventEmitter<void>();

  // Public
  public strokeDashArray: string;

  public readonly viewBoxSize = 24;
  public readonly radius = 9;

  public ngOnInit(): void {
    this.calculate();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('progress' in changes) {
      this.calculate();
    }
  }

  /*
   * Helpers
   */
  private calculate() {
    const circumference = 2 * Math.PI * this.radius;
    this.strokeDashArray = `${(circumference * this.progress) / 100} ${(circumference * (100 - this.progress)) / 100}`;
  }
}
