// Common
import { Component, Injector } from '@angular/core';

// Types
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { SidebarItem } from '@modules/common/types/sidebar-item';

// Services
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Components
import { BaseSidebarComponent } from '@modules/common/components/base-sidebar/base-sidebar.component';

@Component({
  selector: 'app-contacts-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
  standalone: false,
})
export class ContactsSideBarComponent extends BaseSidebarComponent<Group, Contact, VirtualFolder> {
  application = Application.contacts;
  sidebarSplitViewKey = SidebarSplitViewKey.contacts;
  selfDragDataTypes = [DragDataTypes.contact, DragDataTypes.group];

  public items: SidebarItem[] = [
    { title: 'Contacts', id: 'all_contacts', icon: 'sapphire-contact' },
    { title: 'VIP', id: 'vip', icon: 'sapphire-vip' },
    { title: 'Flagged', id: 'flagged', icon: 'sapphire-flagged' },
    { title: 'Follow Up', id: 'followed', icon: 'sapphire-follow-up' },
    { title: 'Snoozed', id: 'snoozed', icon: 'sapphire-snooze' },
    { title: 'Upcoming', id: 'upcoming', icon: 'sapphire-upcoming' },
    // { title: 'Frequent', id: 'frequent', icon: 'frequent' }, // TODO do we need frequent filter?
    { title: 'Recent', id: 'recent', icon: 'sapphire-recent' },
    { title: 'Archive', id: 'archived', icon: 'sapphire-archive' },
    { title: 'Trash', id: 'deleted', icon: 'sapphire-trash' },
  ];

  constructor(
    injector: Injector,
    stateService: ContactsAppStateService,
    private contactsService: ContactsService,
    private groupsService: GroupsService,
  ) {
    super(injector, stateService);
  }

  /**
   * Actions
   */

  handleMove(dragData: DragData, sidebarFolder: VirtualFolder) {
    if (dragData.type === DragDataTypes.contact) {
      const { changes, message } = Contact.getChangesForVirtualFolder(sidebarFolder);
      this.contactsService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    } else if (dragData.type === DragDataTypes.group) {
      const { changes, message } = Group.getChangesForVirtualFolder(sidebarFolder);
      this.groupsService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    }
  }

  handleMore(item: Contact) {
    this.stateService.setMainView(item);
  }

  getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder): Contact {
    const { changes } = Contact.getChangesForVirtualFolder(sidebarFolder);

    return new Contact({ ...changes, title: dragData.data[0]['title'] });
  }
}
