// Common
import { Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { PaymentMethodsService } from '@modules/settings/services/payment-methods.service';
import { StripeService } from '@modules/settings/services/stripe.service';

// Types
import { PaymentMethod } from '@modules/settings/types/payment-method';
import { SortOrder } from '@modules/common/types/sort-order';
import { TableColumn } from '@modules/common/types/table-column';
import { PaymentMethodsFilters } from '@modules/settings/types/payment-methods-filters';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';

// Component
import { StripeCardComponent } from './stripe-form/stripe-form.component';

@Component({
  selector: 'app-settings-general-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less'],
  standalone: false,
})
export class BillingComponent implements OnInit, OnDestroy {
  public paymentMethods: PaymentMethod[];
  public page = 0;
  public perPage = 10;
  public sortBy: PaymentMethodsFilters['sortBy'] = 'createdAt';
  public sortOrder: SortOrder = 'asc';
  public count = 0;
  public columns: TableColumn[] = [];

  private reload = new Subject<void>();
  private alive = new Subject<void>();

  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<void>;
  @ViewChild('defaultTemplate', { static: true }) defaultTemplate: TemplateRef<void>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<void>;
  @ViewChild('cardNumberTemplate', { static: true }) cardNumberTemplate: TemplateRef<void>;

  constructor(
    private modalService: ModalService,
    private injector: Injector,
    private alertService: AlertService,
    private paymentMethodsService: PaymentMethodsService,
    private stripeService: StripeService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit(): void {
    this.columns = [
      { key: 'type', title: 'type', sortable: true },
      { key: 'isDefault', title: 'Default', template: this.defaultTemplate },
      { key: 'cardBrand', title: 'Brand', sortable: true },
      { key: 'cardFunding', title: 'Funding', sortable: true },
      { key: 'cardLast4', title: 'Card Number', sortable: true, template: this.cardNumberTemplate },
      { key: 'cardExpMonth', title: 'Exp Month', sortable: true },
      { key: 'cardExpYear', title: 'Exp Year', sortable: true },
      { key: 'cardCity', title: 'City', sortable: true },
      { key: 'cardCountry', title: 'Country', sortable: true },
      { key: 'cardLine1', title: 'Line1', sortable: true },
      { key: 'cardLine2', title: 'Line2', sortable: true },
      { key: 'cardPostalCode', title: 'Postal Code', sortable: true },
      { key: 'cardState', title: 'State', sortable: true },
      { key: 'cardName', title: 'Name', sortable: true },
      { key: 'createdAt', title: 'Created At', sortable: true, template: this.dateTemplate },
      { key: '', template: this.actionsTemplate },
    ];

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() =>
          this.paymentMethodsService.search({
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
            limit: this.perPage,
            offset: this.page * this.perPage,
          }),
        ),
        takeUntil(this.alive),
      )
      .subscribe(({ items, count }) => {
        this.paymentMethods = items;
        this.count = count;
      });

    this.reload.next();

    merge(this.paymentMethodsService.getRefresh(), this.stripeService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openStripeForm() {
    this.modalService.showModal({
      component: StripeCardComponent,
      appearance: 'amethyst',
      title: 'Add Credit Card',
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
      injector: this.injector,
    });
  }

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: PaymentMethodsFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  activate(method: PaymentMethod) {
    method.isDefault = !method.isDefault;

    this.paymentMethodsService.update(method).pipe(takeUntil(this.alive)).subscribe();
  }

  removeMethod(method: PaymentMethod) {
    this.paymentMethodsService.delete(method);
  }

  removeMethodPrompt(event: MouseEvent, method: PaymentMethod) {
    event.preventDefault();
    event.stopPropagation();

    this.alertService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete Payment Method **** **** **** ${method.cardLast4}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true,
        },
        {
          title: 'REMOVE',
          click: () => this.removeMethod(method),
          close: true,
        },
      ],
      showUntil: this.alive,
    });
  }
}
