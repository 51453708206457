import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  standalone: false,
})
export class FilterByPipe implements PipeTransform {
  transform(array: object[], ...rest): object[] {
    if (!array?.length) {
      return [];
    }

    const pairs = rest.reduce((memo, item, i) => (i % 2 !== 0 ? memo : [...memo, [item, rest[i + 1]]]), []);

    return array.filter((item) => pairs.every(([key, value]) => item[key] === value));
  }
}
