// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Utils
import { UserValidator } from '@modules/auth/validators/user.validator';

// RxJs
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AccountService } from '@modules/account/services/account.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.less'],
  standalone: false,
})
export class RestorePasswordComponent implements OnInit, OnDestroy {
  public passwordsForm = new FormGroup(
    {
      password: new FormControl('', Validators.required),
      passwordConfirmation: new FormControl('', Validators.required),
    },
    [UserValidator.passwordConfirmation('password', 'passwordConfirmation')],
  );

  private alive = new Subject<void>();
  private params: { email: string; token: string };

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    this.activeRouter.queryParams
      .pipe(takeUntil(this.alive))
      .subscribe((params: { email: string; token: string }) => (this.params = params));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleSubmit() {
    if (!this.passwordsForm.valid) {
      return;
    }

    this.accountService
      .restorePassword({
        ...this.params,
        newPassword: this.passwordsForm.value.password,
      })
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.router.navigate(['/welcome']));
  }
}
