<div class="tags py-2 px-4">
  <span
    class="text-sm font-medium text-gray-950"
    *ngIf="!withPlaceholder || tags.length > 0"
  >
    Tags:
  </span>

  <div
    *ngIf="withPlaceholder && tags.length === 0"
    class="tags-placeholder"
  >
    No tags were added to that item
  </div>

  <mat-icon
    *ngIf="withControls"
    class="tags-icon"
    svgIcon="manage"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="manageForm"
    stchPopoverTrigger="click"
    stchPopoverPlacement="right"
    [stchPopoverShowUntil]="hidePopover"
  />

  <mat-icon
    *ngIf="withControls"
    class="tags-icon"
    svgIcon="plus-manage"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="addForm"
    stchPopoverTrigger="click"
    stchPopoverPlacement="right"
    [stchPopoverShowUntil]="hidePopover"
  />

  <app-context-menu-state-indicator
    class="tags-icon no-width"
    svgIcon="filters"
    appTagsManageContextMenu
    [appTagsManageContextMenuStateKey]="stateKey"
    (appTagsManageContextMenuStateChange)="setState($event)"
  />

  <app-tag
    *ngFor="let tag of tags | slice : 0 : (page + 1) * perPage"
    class="align-middle inline-block mr-2"
    [tag]="tag"
    [withControls]="withControls"
    [withDetach]="true"
    [selected]="selectedTags | includesBy : tag.name : 'name'"
    (delete)="handleDelete(tag)"
    (pin)="handlePin(tag)"
    (click)="handleClick(tag, $event)"
  />

  <div
    *ngIf="page !== 0"
    class="tags-item tags-more"
    (click)="showLess()"
  >
    &lt; Less
  </div>

  <div
    *ngIf="page < pagesCount - 1"
    class="tags-item tags-more"
    (click)="showMore()"
  >
    More >
  </div>

  <span
    *ngFor="let recommendation of recommendations"
    class="recommendation"
    matTooltip="Add Recommended Tag"
    (click)="addRecommendation(recommendation)"
  >
    {{ recommendation.key }}({{ recommendation.doc_count }})
  </span>
</div>

<ng-template #manageForm>
  <app-manage-tags-form
    [existingTags]="tags"
    (close)="closePopovers()"
    (save)="saveTags($event)"
  />
</ng-template>

<ng-template #addForm>
  <app-add-tags
    [stitchItems]="stitchItems"
    [control]="control"
    (close)="closePopovers()"
    (save)="saveTags($event)"
  />
</ng-template>
