// Common
import { Component, Injector } from '@angular/core';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-contact-shared',
  templateUrl: './contact-shared.component.html',
  styleUrls: ['./contact-shared.component.less'],
  standalone: false,
})
export class ContactSharedComponent extends SharedBaseComponent<Contact> {
  protected stitchType = StitchType.contact;

  constructor(injector: Injector) {
    super(injector);
  }

  get contact() {
    return this.stitchItem;
  }
}
