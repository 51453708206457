// Common
import { Component, EventEmitter, Injector, Output } from '@angular/core';

// Services
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesService } from '@modules/files/services/files.service';

// Types
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

// Rx
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './folder.component.less'],
  standalone: false,
})
export class FolderComponent extends BaseStitchComponent<Folder> {
  @Output() openFile = new EventEmitter<File>();

  moveDragDataTypes = [DragDataTypes.file, DragDataTypes.folder];
  dragDataType = DragDataTypes.folder;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.folder &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type);

  constructor(
    injector: Injector,
    protected foldersService: FoldersService,
    private filesService: FilesService,
  ) {
    super(injector, foldersService);
  }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    (() => {
      if (dragData.type === DragDataTypes.file) {
        return this.filesService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { folderId: this.item.id },
          { message },
        );
      } else if (dragData.type === DragDataTypes.folder) {
        return this.foldersService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { parentId: this.item.id },
          { message },
        );
      }
    })()
      .pipe(take(1), takeUntil(this.alive))
      .subscribe();
  }

  get folder(): Folder {
    return this.item;
  }
}
