import { Component, inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { Tab } from '@modules/common/types/tab';
import { MessageFolderListStateService } from '@modules/messages/services/message-folder-list-state-holder.service';
import { MessageListStateService } from '@modules/messages/services/message-list-state-holder.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { ListState } from '@modules/messages/types/list-state';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { MessagesListState } from '@modules/messages/types/messages-list-state';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateKey } from '@modules/settings/types/state-key';
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messages2-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.less'],
  standalone: false,
  providers: [
    MessageListStateService.providers({
      stateKey: StateKey.messagesListState,
      withAdvancedSearch: true,
    }),
    MessageFolderListStateService.providers({
      stateKey: StateKey.messagesFoldersListState,
      withAdvancedSearch: true,
    }),
  ],
})
export class MessagesListContainerComponent implements OnInit, OnDestroy {
  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder = 'all_messages';
  public quickFolder: MessageFolder;
  public quickMessage: Message;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Folders', value: 'folders' },
    { title: 'Messages', value: 'messages' },
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'messages';
  public foldersFilters: FoldersFilters;
  public messagesFilters: MessagesFilters;
  public debug: 'score' = null;
  public messageListState: MessagesListState;

  private readonly messageListStateService = inject(MessageListStateService, { self: true });
  private readonly messageFolderListStateService = inject(MessageFolderListStateService, { self: true });

  private readonly alive = new Subject<void>();

  constructor(
    private splitViewService: SplitViewService,
    private stateService: MailAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.messageFolderListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.foldersFilters = new FoldersFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.messageListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.messageListState = list;
        this.messagesFilters = new MessagesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    this.splitViewService
      .getMinimized('mailsList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService
      .getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([this.stateService.getVirtualFolder(), this.searchService.getState()])
      .pipe(takeUntil(this.alive))
      .subscribe(([folder, state]) => {
        this.sidebarFilter = folder;

        this.quickFolder = MessageFolder.fromAdvancedState(state, folder);
        this.quickMessage = Message.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewMessage(message) {
    this.stateService.composeMessage({ message, injector: this.injector });
  }

  handleNewFolder(folder = new MessageFolder()) {
    this.stateService.setMainView(folder);
  }

  handleDoubleClickMessage(message: Message) {
    this.modalService.openFullForm(message, this.injector);
  }

  handleDoubleClickFolder(folder: MessageFolder) {
    this.modalService.openFullForm(folder, this.injector);
  }

  handleClickMessage(message: Message) {
    this.stateService.setMainView(message);
  }

  handleClickFolder(folder: MessageFolder) {
    this.stateService.setMainView(folder);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
