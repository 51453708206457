// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Types
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { File } from '@modules/files/types/file';
import { Folder } from '@modules/files/types/folder';
import { FoldersFilters } from '@modules/files/types/folders-filters';
import { FoldersListState } from '@modules/files/types/folders-list-state';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { FilesAppStateService } from '@modules/files/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';

@Component({
  selector: 'app-side-bar-folders',
  templateUrl: './side-bar-folders.component.html',
  styleUrls: [
    '../../../common/components/base-sidebar-containers-item/base-sidebar-containers-item.component.less',
    './side-bar-folders.component.less',
  ],
  animations: [heightAnimation],
  standalone: false,
})
export class SideBarFoldersComponent
  extends BaseSidebarContainersItemComponent<Folder, File, FoldersFilters, VirtualFolder, FoldersListState>
  implements OnInit
{
  application = Application.files;
  sidebarSplitViewKey = SidebarSplitViewKey.files;
  listStateKey = StateKey.sidebarFoldersListState;

  constructor(filesStateService: FilesAppStateService) {
    super(filesStateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.itemsListState.pipe(takeUntil(this.alive)).subscribe((state) => {
      this.filters = new FoldersFilters().applyListState(state);
    });
  }
}
