// Common
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  Self,
  Output,
  EventEmitter,
} from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Section } from '@modules/common/types/section';

// Services
import { SectionsListService } from '@modules/calendar-app/services/sections-list.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Component({
  selector: 'app-calendar-sections',
  templateUrl: './calendar-sections.component.html',
  styleUrls: ['./calendar-sections.component.less'],
  providers: [{ provide: BaseSectionsListService, useClass: SectionsListService }],
  standalone: false,
})
export class CalendarSectionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() calendar: Calendar;

  @Output() clickItem = new EventEmitter<CalendarEvent>();
  @Output() dblClickItem = new EventEmitter<CalendarEvent>();

  public sections: Section[] = [];

  private alive = new Subject<void>();
  private calendarChanged = new Subject<void>();

  constructor(@Self() private listService: BaseSectionsListService<CalendarEvent, Calendar>) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.listService
      .getSections()
      .pipe(takeUntil(this.alive))
      .subscribe((sections) => {
        this.sections = sections;
      });

    this.calendarChanged
      .pipe(
        filter(() => !!this.calendar),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.listService.setContainer(this.calendar);
      });

    this.calendarChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('calendar' in changes) {
      this.calendarChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.listService.detach();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addSection() {
    this.listService.addSection();
  }
}
