<div
  *ngIf="folder"
  class="folder"
  [class.no-actions]="!withActions"
  [class.pinned]="folder.pinned"
  [class.short-view]="viewType === 'short'"
  [class.with-description]="folder.description"
  [class.with-linked-info]="folder.linkedInfo?.length"

  [appFolderContextMenu]="folder"
  [appFolderContextMenuDisabled]="!withContextMenu"
  (appFolderContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [folder], type: 'folder'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="folder-body">
    <div class="folder-body-heading">
      <div class="folder-body-heading-title">
        {{ folder.title }}
      </div>
    </div>

    <div class="folder-body-date">
      <div class="folder-body-date-item">
        {{ folder.createdAt ? 'Created: ' + (folder.createdAt | date : 'MMM d') : 'No Created' }}
      </div>
      <div class="folder-body-date-separator"></div>
      <div class="folder-body-date-item">
        {{ folder.updatedAt ? 'Updated: ' + (folder.updatedAt | date : 'MMM d') : 'No Updated' }}
      </div>
    </div>

    <div class="folder-body-description" *ngIf="folder.description && viewType !== 'short'">
      {{folder.description}}
    </div>

    <app-link-info-bar
      class="folder-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="folder"
    />
  </div>

  <mat-icon
    class="folder-pin-icon"
    [class.active]="folder.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  />

  <mat-icon
    class="folder-flag-icon"
    [class.active]="folder.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  />

  <mat-icon class="folder-marker" svgIcon="folder" />
  <mat-icon class="folder-attachments-icon" *ngIf="folder.files?.length" svgIcon="attachment" />

  <div
    class="folder-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="download-large"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="folder"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    />
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="archive"
      [class.active]="folder.archived"
      [matTooltip]="folder?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    />
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="trash"
      [class.active]="folder.deleted"
      [matTooltip]="folder?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div
    class="folder-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="trash"
      [class.active]="folder.deleted"
      [matTooltip]="folder?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div class="folder-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="folder-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    />
  </div>

  <div class="drop-zone"></div>
</div>

<div
  class="folder placeholder"
  *ngIf="!folder"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-folder
    [folder]="folder"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  />
</ng-template>
