import { Component, Input } from '@angular/core';

@Component({
  selector: 'stch-postpone-indicators',
  templateUrl: './stitch-postpone-indicators.component.html',
  styleUrl: './stitch-postpone-indicators.component.less',
  standalone: false,
})
export class StitchPostponeIndicatorsComponent {
  @Input() snoozed: Date;
  @Input() followed: Date;
}
