import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { RelativeDatePipe } from '@modules/utils/pipes/relative-date.pipe';

@Component({
  standalone: true,
  selector: 'app-list-items-separator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<span class="block text-lg text-primary-600 sticky top-0 py-2 px-8 mt-6 mr-0.5 z-[2] bg-background">
    <ng-content>
      @if (isDate()) {
        {{ value() | relativeDate }}
      } @else {
        {{ value() }}
      }
    </ng-content>
  </span>`,
  imports: [RelativeDatePipe],
})
export class ListItemSeparatorComponent {
  public value = input();

  public readonly isDate = computed(() => this.value() instanceof Date);
}
