// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarType } from '@modules/calendar-app/types/calendar-type';
import { Clipboard } from '@modules/calendar-app/types/clipboard';

@Component({
  selector: 'app-calendar-cell-context-menu',
  templateUrl: './calendar-cell-context-menu.component.html',
  styleUrls: ['./calendar-cell-context-menu.component.less'],
  standalone: false,
})
export class CalendarCellContextMenuComponent implements OnInit, OnDestroy {
  public clipboard = false;

  private alive: Subject<void> = new Subject<void>();

  @Input() date: Date;
  @Input() calendarType: CalendarType;

  @Output() loadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private eventsStateService: CalendarAppStateService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.eventsStateService
      .getClipboard()
      .pipe(takeUntil(this.alive))
      .subscribe((clipboard: Clipboard) => (this.clipboard = !!clipboard));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  createEvent() {
    this.eventsStateService.setMainView(
      new CalendarEvent({
        allDay: true,
        startTime: this.date,
        endTime: this.date,
      }),
    );
  }

  handleLoadDayEvents() {
    this.loadDayEvents.emit();
  }

  pasteEvents() {
    this.eventsStateService.performPaste(this.date);
  }

  openCalendar(_type: CalendarType) {
    // this.eventsStateService.setCalendarView(type, this.date);
  }
}
