// Common
import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, NgZone, Output } from '@angular/core';

// Types
import { Bounding } from '../types/bounding';
import { TextSelectEvent } from '../types/text-select-event';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[textSelect]',
  standalone: false,
})
export class TextSelectionDirective implements OnInit, OnDestroy {
  private hasSelection: boolean;

  @Output() textSelect = new EventEmitter<TextSelectEvent>();

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {
    this.hasSelection = false;
    this.handleMousedown = this.handleMousedown.bind(this);
    this.handleMouseup = this.handleMouseup.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  public ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.elementRef.nativeElement.addEventListener('mousedown', this.handleMousedown, false);
      document.addEventListener('selectionchange', this.handleSelectionChange, false);
    });
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('mousedown', this.handleMousedown, false);
    document.removeEventListener('mouseup', this.handleMouseup, false);
    document.removeEventListener('selectionchange', this.handleSelectionChange, false);
  }

  private getRangeContainer(range: Range): Node {
    let container = range.commonAncestorContainer;

    // If the selected node is a Text node, climb up to an element node
    while (container.nodeType !== Node.ELEMENT_NODE) {
      container = container.parentNode;
    }

    return container;
  }

  private handleMousedown() {
    document.addEventListener('mouseup', this.handleMouseup, false);
  }

  private handleMouseup() {
    document.removeEventListener('mouseup', this.handleMouseup, false);
    this.processSelection();
  }

  private handleSelectionChange() {
    if (this.hasSelection) {
      this.processSelection();
    }
  }

  private processSelection(): void {
    const selection = document.getSelection();

    if (this.hasSelection) {
      this.ngZone.run(() => {
        this.hasSelection = false;
        this.textSelect.emit({ text: null, viewport: null, host: null });
      });
    }

    if (!selection.rangeCount || !selection.toString()) {
      return;
    }

    const range = selection.getRangeAt(0);
    const rangeContainer = this.getRangeContainer(range);

    // only emit events for selections that are fully contained within the host element
    if (!this.elementRef.nativeElement.contains(rangeContainer)) {
      return;
    }

    const viewportRectangle = range.getBoundingClientRect();
    const localRectangle = this.viewportToHost(viewportRectangle, rangeContainer);

    this.ngZone.run(() => {
      this.hasSelection = true;
      this.textSelect.emit({
        text: selection.toString(),
        viewport: {
          left: viewportRectangle.left,
          right: 0,
          top: viewportRectangle.top,
          bottom: 0,
          width: viewportRectangle.width,
          height: viewportRectangle.height,
        },
        host: {
          left: localRectangle.left,
          right: 0,
          top: localRectangle.top,
          bottom: 0,
          width: localRectangle.width,
          height: localRectangle.height,
        },
      });
    });
  }

  private viewportToHost(viewportRectangle: Bounding, rangeContainer: Node): Bounding {
    const host = this.elementRef.nativeElement;
    const hostRectangle = host.getBoundingClientRect();

    let localLeft = viewportRectangle.left - hostRectangle.left;
    let localTop = viewportRectangle.top - hostRectangle.top;

    let node = rangeContainer;

    do {
      localLeft += (node as Element).scrollLeft;
      localTop += (node as Element).scrollTop;
    } while (node !== host && (node = node.parentNode));

    return {
      left: localLeft,
      right: 0,
      top: localTop,
      bottom: 0,
      width: viewportRectangle.width,
      height: viewportRectangle.height,
    };
  }
}
