// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

// Utils
import { RelativeDate } from '@modules/common/types/relative-date';
import { relativeDateToDate } from '@modules/common/utils/date';

// RX
import { Subject, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less'],
  standalone: false,
})
export class DatePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: FormControl<Date>;
  @Input() min: Date;
  @Input() max: Date;
  @Input() range: [Date, Date];
  @Input() showQuickDateActions = false;

  public viewDate = new FormControl<Date>(new Date());

  private alive = new Subject<void>();
  private inputControlChanged = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControlChanged
      .pipe(
        switchMap(() => this.control?.valueChanges || of(null)),
        startWith(this.control?.value),
        takeUntil(this.alive),
      )
      .subscribe((value) => {
        this.viewDate.setValue(value || new Date());
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  setToday() {
    this.viewDate.setValue(new Date());
  }

  handleDateSelect(date: Date) {
    this.control.markAsDirty();
    this.control.setValue(date);
  }

  handleQuickDateSelect(dateByWords: RelativeDate): void {
    const date = relativeDateToDate(dateByWords);

    this.control.setValue(date);
  }

  handleNextMonthClick(direction: 1 | -1): void {
    this.viewDate.setValue(new Date(this.viewDate.value.getFullYear(), this.viewDate.value.getMonth() + direction));
  }
}
