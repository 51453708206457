// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Types
import { BaseAppAdvancedFilters } from './base-app-advanced-filters';
import { Participant } from '@modules/messages/types/participant';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }] });

export class MailAppAdvancedFilters extends BaseAppAdvancedFilters {
  formBuilder = injector.get(UntypedFormBuilder);

  anyRecipient?: boolean;
  bcc?: Pick<Participant, 'name' | 'address'>[];
  body?: string;
  cc?: Pick<Participant, 'name' | 'address'>[];
  dateReceived?: Date;
  dateSent?: Date;
  from?: Pick<Participant, 'name' | 'address'>[];
  hasAttachments?: boolean;
  size?: { from?: number; to?: number };
  subject?: string;
  to?: Pick<Participant, 'name' | 'address'>[];

  constructor(filters?: any) {
    super(filters);

    this.anyRecipient = !!filters?.anyRecipient;
    this.bcc = Array.isArray(filters?.bcc) ? filters.bcc : [];
    this.body = filters?.body || '';
    this.cc = Array.isArray(filters?.cc) ? filters.cc : [];
    this.dateReceived = filters?.dateReceived ? new Date(filters.dateReceived) : null;
    this.dateSent = filters?.dateSent ? new Date(filters.dateSent) : null;
    this.from = Array.isArray(filters?.from) ? filters.from : [];
    this.hasAttachments = !!filters?.hasAttachments;
    this.size = { from: filters?.size?.from || null, to: filters?.size?.to || null };
    this.subject = filters?.subject || '';
    this.to = Array.isArray(filters?.to) ? filters.to : [];
  }

  static fromFormGroup(form: UntypedFormGroup): MailAppAdvancedFilters {
    return new MailAppAdvancedFilters(this.getFormValues(form));
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...super.commonFormGroupFields(),
      anyRecipient: [this.anyRecipient],
      bcc: [this.bcc],
      body: [this.body],
      cc: [this.cc],
      dateReceived: [this.dateReceived],
      dateSent: [this.dateSent],
      from: [this.from],
      hasAttachments: [this.hasAttachments],
      size: [this.size],
      subject: [this.subject],
      to: [this.to],
    });
  }
}
