import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'someTrue',
  standalone: false,
})
export class SomeTruePipe implements PipeTransform {
  transform(object: { [key: string]: boolean } = {}): boolean {
    return Object.values(object).some(Boolean);
  }
}
