// Common
import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { Editor, EditorOptions } from 'tinymce';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { EditorService } from '@modules/form-controls/services/editor.service';

@Component({
  selector: 'stch-simple-editor',
  templateUrl: './simple-editor.component.html',
  styleUrls: ['./simple-editor.component.less'],
  standalone: false,
})
export class SimpleEditorComponent implements OnInit, OnDestroy {
  public settings: Partial<EditorOptions> = {
    plugins: ['lists', 'link', 'image', 'table', 'code'],
    resize: false,
    height: '100%',
    elementpath: false,
    statusbar: false,
    promotion: false,
    toolbar: false,
    menubar: false,
    highlight_on_focus: false,
    setup: (editor: Editor) => {
      this.editor = editor;

      // Work around to make undo remove last word and not entire line
      editor.on('keyup', (event: KeyboardEvent) => {
        if (event && event.code === 'Space') {
          editor.undoManager.add();
        }
      });

      editor.on('init', () => {
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'alignleft',
          'aligncenter',
          'alignright',
          'alignjustify',
        ].forEach((format) => {
          editor.formatter.formatChanged(
            format,
            (state) => {
              this.editorService?.setFormat?.({ [format]: state });
            },
            false,
          );
        });
      });
    },
  };

  private editor: Editor;
  private alive = new Subject<void>();

  @Input() control: FormControl<string>;
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string } = {};

  constructor(@Optional() private editorService: EditorService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.editorService
      ?.getCommand()
      .pipe(takeUntil(this.alive))
      .subscribe((command) => {
        this.editor.execCommand(command);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
