<div
  #contentContainer
  class="quick-form"
  appDroppable
  appDroppableHoverClass="drag-over"
  appDroppableZIndex="10"
  (appDroppableDrop)="handleDrop($event)"
  [style.minHeight.px]="(inline || expanded) ? formsSizes[currentFormSelector] : 40"
  [@collapseMotion]="{
    value: inline || expanded ? 'expanded' : 'collapsed',
    params: { height: formsSizes[currentFormSelector], contentHeight: contentHeight }
  }"
>
  <div
    class="quick-form-container"
    [class.visible]="inline || expanded"
  >
    <app-message-quick-form
      *ngIf="currentFormSelector === 'message'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [message]="message"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-event-quick-form
      *ngIf="currentFormSelector === 'event'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [event]="event"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-project-quick-form
      *ngIf="currentFormSelector === 'project'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [project]="project"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-task-quick-form
      *ngIf="currentFormSelector === 'task'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [task]="task"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-notebook-quick-form
      *ngIf="currentFormSelector === 'notebook'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [notebook]="notebook"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-note-quick-form
      *ngIf="currentFormSelector === 'note'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [note]="note"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-group-quick-form
      *ngIf="currentFormSelector === 'group'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [group]="group"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-contact-quick-form
      *ngIf="currentFormSelector === 'contact'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [contact]="contact"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-folder-quick-form
      *ngIf="currentFormSelector === 'folder'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [folder]="folder"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
    <app-file-quick-form
      *ngIf="currentFormSelector === 'file'"
      [autofocus]="true"
      [title]="this.titleControl.value"
      [file]="file"
      (more)="handleMore($event)"
      (close)="handleClose()"
    />
  </div>

  <div
    class="quick-form-heading"
    [class.hidden]="inline || expanded"
  >
    <input
      [formControl]="titleControl"
      class="quick-form-heading-input"
      [class.active]="expanded"
      [placeholder]="placeholder"
    />
    <mat-icon
      svgIcon="plus-medium"
      class="quick-form-heading-plus-icon"
    />
  </div>
</div>
