// Types
import { Priority } from '@modules/tasks/types/priority';
import { ProjectsListState } from './projects-list-state';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { VirtualFolder } from './virtual-folder';

// Utils
import { addDays, beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { isNil } from '@modules/common/utils/base';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Pipes
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';
import { Project } from './project';

export class ProjectsFilters extends StitchFilters<Project> {
  // Filters
  priority?: Priority;
  withPriority?: boolean;
  fromTime?: Date;
  toTime?: Date;
  startDate?: Date;
  dueDate?: Date;
  scheduled?: boolean;
  overdue?: boolean;
  completed?: boolean;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'priority' | 'score' | 'sharedViaLinkAt';

  constructor(filters?: Like<ProjectsFilters>) {
    super(filters);
    this.overdue = filters?.overdue;
    this.fromTime = filters?.fromTime;
    this.toTime = filters?.toTime;
    this.sortBy = filters?.sortBy;
    this.priority = filters?.priority;
    this.withPriority = filters?.withPriority;
    this.scheduled = filters?.scheduled;
  }

  applyListState(state: ProjectsListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    if (state?.next7days) {
      const today = new Date();
      this.fromTime = beginningOfDay(addDays(today, 1));
      this.toTime = endOfDay(addDays(today, 7));
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.snoozedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = super.format();

    if (this.priority) {
      result['priority'] = this.priority;
    }
    if (!isNil(this.withPriority)) {
      result['with_priority'] = this.withPriority + '';
    }

    if (this.fromTime) {
      result['from_time'] = this.fromTime.toISOString();
    }
    if (this.toTime) {
      result['to_time'] = this.toTime.toISOString();
    }
    if (!isNil(this.scheduled)) {
      result['scheduled'] = this.scheduled + '';
    }
    if (this.overdue) {
      result['overdue_till'] = new Date().toISOString();
    }
    if (this.completed) {
      result['completed'] = this.completed + '';
    }
    if (!isNil(this.startDate)) {
      result['start_date'] = beginningOfDay(this.startDate).toISOString();
    }
    if (!isNil(this.dueDate)) {
      result['due_date'] = endOfDay(this.dueDate).toISOString();
    }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced);

    const state = advanced.tasking;

    this.parentsIds = state.containersIds;
    this.noParent = !state.containersIds?.length;
    this.esTitle = advanced.tasking.title;
    this.esBody = advanced.tasking.description;
    this.fromTime = advanced.tasking.dateRangeFrom;
    this.toTime = advanced.tasking.dateRangeTo;
    this.startDate = advanced.tasking.startDate;
    this.dueDate = advanced.tasking.dueDate;
    this.priority = advanced.tasking.priority;
    this.overdue = advanced.tasking.overdue;
    this.completed = advanced.tasking.completed;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    const today = new Date();

    this.deleted = false;
    this.archived = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;

    if (!folder) {
      return this;
    }

    switch (folder) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'today':
        this.fromTime = beginningOfDay(today);
        this.toTime = endOfDay(today);
        break;
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
        const dateByDayIndexPipe = new DateByDayIndexPipe();
        const day = dateByDayIndexPipe.transform(folder);

        this.fromTime = beginningOfDay(day);
        this.toTime = endOfDay(day);
        break;
      case 'week':
        this.fromTime = beginningOfDay(addDays(today, 1));
        this.toTime = endOfDay(addDays(today, 7));
        break;
      case 'all_tasks':
      case 'all_projects':
        break;
      case 'overdue':
        this.overdue = true;
        break;
      case 'priority':
        this.withPriority = true;
        break;
      case 'scheduled':
        this.scheduled = true;
        break;
      case 'unscheduled':
        this.scheduled = false;
        break;
      case 'low':
      case 'normal':
      case 'high':
        this.priority = folder;
        break;
      case 'flagged':
        this.flagged = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
