// Common
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.less', '../../styles/input.less'],
  standalone: false,
})
export class TextareaComponent {
  @Input() inputFormControl: UntypedFormControl;
  @Input() placeholder = '';
  @Input() width: string;
  @Input() rows = 3;
  @Input() appearance: 'simple' | 'amethyst' | 'sapphire-outline' | 'sapphire-filled' | 'sapphire-inline' = 'simple';
  @Input() withClear = false;
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() label = '';
  @Input() hint: string;
  @Input() invertedColor = false;
  @Input() disabled = false;
  @Input() resize = true;

  public focused = false;

  /**
   * Actions
   */

  handleFocus() {
    this.focused = true;
  }

  handleBlur() {
    this.focused = false;
  }

  handleClear() {
    this.inputFormControl.setValue('');
  }
}
