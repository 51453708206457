// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-notes-layout',
  templateUrl: './notes-layout.component.html',
  styleUrls: ['./notes-layout.component.less', '../../styles.less'],
  standalone: false,
})
export class NotesLayoutComponent {}
