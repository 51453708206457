// Types
import { ButtonsActionTypes, NotificationsType } from '@modules/notification/types/notifications-type';
import { StitchType } from '@modules/common/types/stitch-type';

// Pipes
import { DatePipe } from '@angular/common';

export class Notification {
  id: string;
  title: string;
  content: string;
  createdAt: Date;
  notificationType: NotificationsType;
  subjectType: StitchType;
  icon: string;
  user: { id: string; title: string };
  entity: {
    id: string;
    entityType: StitchType;
    title: string;
    description?: string;
    fromTime?: Date;
    toTime?: Date;
    snoozed?: Date;
    followed?: Date;
    date?: Date;
    issueKeyTitle?: string;
  };
  buttons: { title: string; actionType: ButtonsActionTypes }[];

  constructor(obj) {
    this.id = obj.id;
    this.createdAt = new Date(obj.createdAt);
    this.notificationType = obj.notificationType;
    this.subjectType = obj.subjectType;
    this.user = obj.user;
    if (obj.entity) {
      this.entity = {
        id: obj.entity.id,
        entityType: obj.entity.subjectType,
        title: obj.entity.title,
        description: obj.entity.description,
        fromTime: obj.entity.fromTime ? new Date(obj.entity.fromTime) : null,
        toTime: obj.entity.toTime ? new Date(obj.entity.toTime) : null,
        snoozed: obj.entity.snoozed ? new Date(obj.entity.snoozed) : null,
        followed: obj.entity.followed ? new Date(obj.entity.followed) : null,
        issueKeyTitle:
          obj.entity.issueKey && obj.entity.issueEnum ? `[${obj.entity.issueKey}-${obj.entity.issueEnum}] ` : null,
      };
    }
    this.prepareContent();
  }

  private prepareContent() {
    if (!this.entity) {
      this.title = 'This ' + this.subjectType + ' was deleted';
      this.content = this.subjectType + ' does not exist. This ' + this.subjectType + ' was deleted';
      this.icon = '';
      return;
    }

    const datePipe = new DatePipe('en');
    switch (this.notificationType) {
      case NotificationsType.snoozedProject:
      case NotificationsType.snoozedTask:
      case NotificationsType.snoozedMessage:
      case NotificationsType.snoozedMessageFolder:
      case NotificationsType.snoozedFolder:
      case NotificationsType.snoozedFile:
      case NotificationsType.snoozedContact:
      case NotificationsType.snoozedGroup:
      case NotificationsType.snoozedNote:
      case NotificationsType.snoozedNotebook:
      case NotificationsType.snoozedEvent:
      case NotificationsType.snoozedCalendar:
        this.title = this.entity?.title + ' was snoozed';
        this.content =
          'You have snoozed ' +
          this.entity?.title +
          ' until ' +
          datePipe.transform(this.entity.snoozed, 'MMM dd hh:mm aaa');
        this.icon = '';
        this.buttons = [{ title: 'Resnooze', actionType: 'snoozed' }];
        break;
      case NotificationsType.followedProject:
      case NotificationsType.followedTask:
      case NotificationsType.followedMessage:
      case NotificationsType.followedMessageFolder:
      case NotificationsType.followedFolder:
      case NotificationsType.followedFile:
      case NotificationsType.followedContact:
      case NotificationsType.followedGroup:
      case NotificationsType.followedNote:
      case NotificationsType.followedNotebook:
      case NotificationsType.followedEvent:
      case NotificationsType.followedCalendar:
        this.title = this.entity?.title + ' was followed up';
        this.content =
          'You have followed up ' +
          this.entity?.title +
          ' until ' +
          datePipe.transform(this.entity.followed, 'MMM dd hh:mm aaa');
        this.icon = '';
        this.buttons = [{ title: 'Refollow', actionType: 'followed' }];
        break;
      case NotificationsType.startedTask:
      case NotificationsType.startedProject:
      case NotificationsType.startedEvent:
        this.title = this.entity?.title;
        this.content = this.entity?.title;
        this.icon = '';
        const startTime = Math.round((this.entity.fromTime.getTime() - new Date().getTime()) / 1000 / 60);

        if (startTime > 0) {
          this.content += ` will start at ${datePipe.transform(this.entity.fromTime, 'MMM dd hh:mm aaa')} (in ${startTime} minutes)`;
        } else {
          this.content += ` has been started at ${datePipe.transform(this.entity.fromTime, 'MMM dd hh:mm aaa')} (${Math.abs(startTime)} minutes ago)`;
        }
        break;
      case NotificationsType.dueTask:
      case NotificationsType.dueProject:
      case NotificationsType.dueEvent:
        this.title = this.entity?.title;
        this.content = this.entity?.title;
        this.icon = '';
        const dueTime = Math.round((this.entity.toTime.getTime() - new Date().getTime()) / 1000 / 60);

        if (dueTime > 0) {
          this.content += ` will end at ${datePipe.transform(this.entity.toTime, 'MMM dd hh:mm aaa')} (in ${dueTime} minutes)`;
        } else {
          this.content += ` has been ended at ${datePipe.transform(this.entity.toTime, 'MMM dd hh:mm aaa')} (${Math.abs(dueTime)} minutes ago)`;
        }
        break;
      case NotificationsType.commented:
        this.title = this.entity?.title;
        this.content = `Someone left a comment in the ${this.entity?.title}`;
        this.icon = '';
        break;
      case NotificationsType.spaceInvited:
        this.title = this.entity?.title;
        this.content = 'Someone has invited you to space ' + this.entity?.title;
        this.buttons = [
          { title: 'Accept', actionType: 'space-accept' },
          { title: 'Reject', actionType: 'space-reject' },
        ];
        this.icon = '';
        break;
      case NotificationsType.spaceAccepted:
        this.title = this.entity?.title;
        this.content = 'User has accepted your invitation to space ' + this.entity?.title;
        this.icon = '';
        break;
      case NotificationsType.spaceRejected:
        this.title = this.entity?.title;
        this.content = 'User has rejected your invitation to space ' + this.entity?.title;
        this.icon = '';
        break;
      case NotificationsType.spaceDeleted:
        this.title = this.entity?.title;
        this.content = 'Someone has removed you from space ' + this.entity?.title;
        this.icon = '';
        break;
      case NotificationsType.spaceRoleChanged:
        this.title = this.entity?.title;
        this.content = 'Someone has changed your role in space ' + this.entity?.title;
        this.icon = '';
        break;
      case NotificationsType.newMessage:
        this.title = this.entity?.title;
        this.content = 'You have received new email - ' + this.entity?.title;
        this.icon = '';
        break;
      default:
        this.title = this.entity?.title;
        this.content = 'Your reminder';
        this.icon = '';
        break;
    }
  }
}
