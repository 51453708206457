// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Types
import { VirtualFolder } from '@modules/messages/types/virtual-folder';

@Component({
  selector: 'app-messages2-list-context-menu',
  templateUrl: './messages-context-menu.component.html',
  styleUrls: ['./messages-context-menu.component.less'],
  standalone: false,
})
export class MessagesContextMenuComponent {
  // Input
  @Input() filterKey: VirtualFolder;

  // Outputs
  @Output() change = new EventEmitter<VirtualFolder>();

  /**
   * Actions
   */

  selectFilter(filter: VirtualFolder) {
    this.change.emit(filter);
  }
}
