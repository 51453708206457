// Common
import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// RxJS
import { fromEvent, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { MailAppStateService } from '@modules/messages/services/state.service';
import { PermissionParticipantsService } from '@modules/messages/services/permission-participants.service';

// Types
import { Message } from '@modules/messages/types/message';
import { Participant } from '@modules/messages/types/participant';
import { PermissionParticipant } from '@modules/messages/types/permission-participant';

@Component({
  selector: 'app-participant-context-menu',
  templateUrl: './participant-context-menu.component.html',
  styleUrls: ['./participant-context-menu.component.less'],
  standalone: false,
})
export class ParticipantContextMenuComponent implements OnInit, OnDestroy {
  private alive = new Subject<void>();
  private permissionParticipants: PermissionParticipant[] = [];

  // Inputs
  @Input() participant: Participant;

  constructor(
    private messagesStateService: MailAppStateService,
    private permissionParticipantsService: PermissionParticipantsService,
    @Optional() @Inject(CLOSE_POPOVER) private closePopoverToken,
    private injector: Injector,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.permissionParticipantsService
      .search({ values: [this.participant.address] })
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: participants }) => {
        this.permissionParticipants = participants;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  moveToList(type: 'vip' | 'white-list' | 'black-list') {
    if (!this.participant) {
      return;
    }

    if (this.participant?.[type]) {
      const permission = this.permissionParticipants.find(
        (permissionParticipant) =>
          permissionParticipant.type === type && this.participant.address === permissionParticipant.value,
      );

      !permission && this.closePopoverToken?.next();

      permission &&
        this.permissionParticipantsService
          .deletePermanently(permission.id)
          .pipe(takeUntil(this.alive))
          .subscribe(() => {
            this.participant.vip = false;
            this.closePopoverToken?.next();
          });
    } else {
      this.permissionParticipantsService
        .create(
          new PermissionParticipant({
            value: this.participant.address,
            type,
          }),
        )
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          this.participant.vip = true;
          this.closePopoverToken?.next();
        });
    }
  }

  copy() {
    fromEvent(document, 'copy')
      .pipe(take(1))
      .subscribe((clipboard: ClipboardEvent) => {
        clipboard.clipboardData.setData('text/plain', this.participant?.address);
        clipboard.preventDefault();
      });
    document.execCommand('copy');
  }

  compose() {
    this.messagesStateService.composeMessage({
      message: new Message({ to: [this.participant] }),
      injector: this.injector,
    });
  }
}
