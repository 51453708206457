// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { Space } from '../types/space';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { SpaceRole } from '../types/space-role';
import { SpacesRolesFilters } from '../types/spaces-roles-filters';

// Env
import { environment } from '@environment';

@Injectable()
export class SpaceRolesService extends BaseRestService<SpaceRole, SpacesRolesFilters> {
  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(filters: Partial<SpacesRolesFilters>, { space }: { space: Space }): Observable<BaseSearchResponse<SpaceRole>> {
    const requestParams = { params: new SpacesRolesFilters(filters || {}).format() };

    return this.http
      .get<{
        count: number;
        items: SpaceRole[];
        total: number;
      }>(`${environment.baseUrl}/api/account/spaces/${space.id}/roles`, requestParams)
      .pipe(
        map(({ count, items, total }) => ({
          count,
          items: (items || []).map((item) => new SpaceRole(item)),
          total,
        })),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  create(
    instance: SpaceRole,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<SpaceRole> {
    return this.http
      .post<{
        success: boolean;
        item: SpaceRole;
      }>(`${environment.baseUrl}/api/account/spaces/${instance.spaceId}/roles`, instance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Role created.` });
        }),
        map(({ item }) => new SpaceRole(item)),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  update(
    instance: SpaceRole,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<SpaceRole> {
    return this.http
      .put<{
        success: boolean;
        item: SpaceRole;
      }>(`${environment.baseUrl}/api/account/spaces/${instance.spaceId}/roles/${instance.id}`, instance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            toast && this.toaster.show({ text: message || 'Role updated' });
          }
        }),
        map(({ item }) => new SpaceRole(item)),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  delete(
    instance: SpaceRole,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<boolean> {
    return this.http
      .delete<{
        success: boolean;
      }>(`${environment.baseUrl}/api/account/spaces/${instance.spaceId}/roles/${instance.id}`)
      .pipe(
        map(({ success }) => success),
        tap((success) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || 'Space successfully deleted' });
        }),
        catchError((error) => this.handleObserverError(error)),
      );
  }
}
