<app-input
  class="title-input"
  [inputFormControl]="signatureForm.controls.title"
  appearance="amethyst"
  label="Title"
/>

<app-rich-text-editor
  class="editor"
  [control]="signatureForm.controls.content"
/>

<div class="toggle-container">
  <span class="toggle-container-title">Make as a default template</span>
  <app-toggle-switch
    [inputFormControl]="signatureForm.controls.isDefault"
  />
</div>

<div class="actions">
  <app-button
    appearance="purple-outline"
    label="Cancel"
    (click)="handleClose()"
  />

  <app-button
    appearance="purple"
    label="Submit"
    (click)="handleSubmit()"
  />
</div>
