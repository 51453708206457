// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { GroupContextMenuComponent } from '../components/context-menus/group-context-menu/group-context-menu.component';

// Types
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appGroupContextMenu]',
  standalone: false,
})
export class GroupContextMenuDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() appGroupContextMenu: Group;
  @Input() appGroupContextMenuDisabled = false;
  @Input() appGroupContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appGroupContextMenuOpened = this.contextMenuOpened;
  @Output() appGroupContextMenuOpenGroup = new EventEmitter();
  @Output() appGroupContextMenuOpenContact = new EventEmitter<Contact>();

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appGroupContextMenuDisabled;

    if (this.appGroupContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return GroupContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<GroupContextMenuComponent>) {
    componentRef.instance.group = this.appGroupContextMenu;
    componentRef.instance.open.pipe(takeUntil(this.alive)).subscribe(() => {
      this.appGroupContextMenuOpenGroup.emit();
    });

    componentRef.instance.openContact.pipe(takeUntil(this.alive)).subscribe((contact) => {
      this.appGroupContextMenuOpenContact.emit(contact);
    });
  }
}
