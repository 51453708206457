// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { Team } from '../types/team';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { TeamParticipant } from '../types/team-participant';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { TeamParticipantsFilters } from '../types/team-participants-filters';

// Env
import { environment } from '@environment';

@Injectable()
export class TeamParticipantsService extends BaseRestService<TeamParticipant, TeamParticipantsFilters> {
  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(
    filters: Partial<TeamParticipantsFilters>,
    { team }: { team: Team },
  ): Observable<BaseSearchResponse<TeamParticipant>> {
    const requestParams = { params: new TeamParticipantsFilters(filters || {}).format() };

    return this.http
      .get<{
        count: number;
        items: TeamParticipant[];
        total: number;
      }>(`${environment.baseUrl}/api/account/teams/${team.id}/participants`, requestParams)
      .pipe(
        map(({ count, items, total }) => ({
          count,
          items: (items || []).map((item) => new TeamParticipant(item)),
          total,
        })),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  create(
    _instance: TeamParticipant,
    _config: FeedbackConfig = { emit: true, toast: true },
  ): Observable<TeamParticipant> {
    throw new Error('Method forbidden');
  }

  addParticipant(
    team: Team,
    email: string,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/account/teams/${team.id}/participants`, { email })
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${email} added to team ${team.title}.` });
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  update(
    instance: TeamParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<TeamParticipant> {
    return this.http
      .put<{
        success: boolean;
        item: TeamParticipant;
      }>(`${environment.baseUrl}/api/account/teams/${instance.teamId}/participants/${instance.id}`, instance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${instance.userName} role updated.` });
        }),
        map(({ item }) => new TeamParticipant(item)),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  accept(team: Team, accepted: boolean, { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }) {
    return this.http
      .post<{
        success: boolean;
      }>(`${environment.baseUrl}/api/account/teams/${team.id}/participants/accept`, { accepted })
      .pipe(
        map(({ success }) => success),
        tap((success) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();

          toast &&
            this.toaster.show({
              text:
                message ||
                `You have ${accepted ? 'accepted' : 'rejected'} an invitation to participate in a team ${team.title}.`,
            });
        }),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  deleteParticipant(
    team: Team,
    participant: TeamParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<boolean> {
    return this.http
      .delete<{
        success: boolean;
      }>(`${environment.baseUrl}/api/account/teams/${team.id}/participants/${participant.id}`)
      .pipe(
        map(({ success }) => success),
        tap((success) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();

          toast &&
            this.toaster.show({
              text: message || `Participant ${participant.userName} removed from team ${team.title}.`,
            });
        }),
        catchError((error) => this.handleObserverError(error)),
      );
  }
}
