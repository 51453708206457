// Common
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// Modules
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Types
import { icons } from './types/icons';

@NgModule({ imports: [MatIconModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class IconsModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    icons.forEach(([name, path]) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
    });
  }
}
