// Common
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileColorByType',
  standalone: false,
})
export class FileColorByTypePipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '#409aff';
      case 'application/pdf':
        return '#f16969';
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
        return '#6a69c3';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      case 'application/vnd.ms-excel':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        return '#00b047';
      case 'message/rfc822':
        return '#409aff';
      default:
        return '#409aff';
    }
  }
}
