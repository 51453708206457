import { Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TypedFormGroup } from '@modules/common/utils/form';

export type SpaceRoleForm = FormGroup<
  TypedFormGroup<{
    id: string;
    title: string;
    spaceId: string;
    addUsers: boolean;
    removeUsers: boolean;
    addTeams: boolean;
    addDocuments: boolean;
    editDocuments: boolean;
    deleteDocuments: boolean;
    shareDocuments: boolean;
  }>
>;

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class SpaceRole {
  formBuilder = injector.get(FormBuilder);

  id: string;
  title: string;
  spaceId: string;
  addUsers: boolean;
  removeUsers: boolean;
  addTeams: boolean;
  addDocuments: boolean;
  editDocuments: boolean;
  deleteDocuments: boolean;
  shareDocuments: boolean;

  constructor(data: Partial<SpaceRole> = {}) {
    this.id = data?.id;
    this.title = data?.title;
    this.spaceId = data?.spaceId;
    this.addUsers = !!data.addUsers;
    this.removeUsers = !!data.removeUsers;
    this.addTeams = !!data.addTeams;
    this.addDocuments = !!data.addDocuments;
    this.editDocuments = !!data.editDocuments;
    this.deleteDocuments = !!data.deleteDocuments;
    this.shareDocuments = !!data.shareDocuments;
  }

  public asFormGroup(): SpaceRoleForm {
    return this.formBuilder.group({
      id: this.id,
      title: this.title,
      spaceId: this.spaceId,
      addUsers: this.addUsers,
      removeUsers: this.removeUsers,
      addTeams: this.addTeams,
      addDocuments: this.addDocuments,
      editDocuments: this.editDocuments,
      deleteDocuments: this.deleteDocuments,
      shareDocuments: this.shareDocuments,
    });
  }

  static fromFormGroup(form: SpaceRoleForm): SpaceRole {
    const { value: formValues } = form;

    return new SpaceRole(formValues);
  }

  asPayloadJSON() {
    return {
      title: this.title,
      spaceId: this.spaceId,
      addUsers: this.addUsers,
      removeUsers: this.removeUsers,
      addTeams: this.addTeams,
      addDocuments: this.addDocuments,
      editDocuments: this.editDocuments,
      deleteDocuments: this.deleteDocuments,
      shareDocuments: this.shareDocuments,
    };
  }
}

export const adminRole = new SpaceRole({
  id: 'admin',
  title: 'Admin',
  addUsers: true,
  removeUsers: true,
  addTeams: true,
  addDocuments: true,
  editDocuments: true,
  deleteDocuments: true,
  shareDocuments: true,
});

export const regularRole = new SpaceRole({
  id: 'regular',
  title: 'Regular',
  addUsers: false,
  removeUsers: false,
  addTeams: false,
  addDocuments: true,
  editDocuments: true,
  deleteDocuments: true,
  shareDocuments: true,
});
