// Common
import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  TemplateRef,
  Optional,
  Inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';

// Injection Tokens
import { INPUTS_GROUP } from '@modules/form-controls/injection-tokens/inputs-group.injection-token';

// Types
import { InputAppearance } from '@modules/form-controls/types/input-appearance';
import { Icon } from '@modules/icons/types/icons';

// RX
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.less', '../../styles/input.less'],
  standalone: false,
})
export class InputComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('input', { static: true }) inputElement: ElementRef;

  @Input() inputFormControl: FormControl<string | number>;
  @Input() originalControl: FormControl<string>;
  @Input() placeholder = '';
  @Input() withClear = false;
  @Input() focusImmediately = false;
  @Input() appearance: InputAppearance = 'default';
  @Input() password: boolean;
  @Input() number: boolean;
  @Input() sideText: string;
  @Input() hint: string;
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() label = '';
  @Input() leftIcon: Icon;
  @Input() rightIcon: Icon;
  @Input() invertedColor = false;
  @Input() disabled = false;
  @Input() disabledInteraction = false;
  /**
   * [size] 'xs' | 's' | 'm' | 'l' only for 'sapphire-*'
   */
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';
  @Input() template: TemplateRef<any>;
  @Input() min: number;
  @Input() max: number;

  @Output() onEnter = new EventEmitter<void>();
  @Output() onFocus = new EventEmitter<void>();
  @Output() onBlur = new EventEmitter<void>();
  @Output() onLeftIconClick = new EventEmitter<void>();
  @Output() onClear = new EventEmitter<void>();

  public focused = false;
  public passwordVisible = false;
  public inputType = 'text';

  private alive = new Subject<void>();

  constructor(@Optional() @Inject(INPUTS_GROUP) public insideGroup: boolean) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.focusImmediately && this.focus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('password' in changes || 'number' in changes) {
      if (this.password) {
        this.inputType = 'password';
      } else if (this.number) {
        this.inputType = 'number';
      }
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFocus() {
    this.onFocus.emit();
    this.focused = true;
  }

  focus() {
    timer(100)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.inputElement.nativeElement.focus());
  }

  handleBlur() {
    this.onBlur.emit();
    this.focused = false;
  }

  handleEnter() {
    this.onEnter.emit();
  }

  handleClear() {
    if (this.onClear.observers.length > 0) {
      this.onClear.emit();
    } else {
      this.inputFormControl?.setValue('');
    }
  }

  handleClickLeftIcon() {
    this.onLeftIconClick.emit();
  }

  handleSpin(event: MouseEvent) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const offsetY = event.clientY - rect.top;

    this.inputFormControl?.setValue(
      ((this.inputFormControl.value as number) || 0) + (offsetY < rect.height / 2 ? 1 : -1),
    );
  }
}
