// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateKey } from '@modules/settings/types/state-key';
import { type MessageFolder } from './message-folder';

// Services
import { SortOrder } from '@modules/common/types/sort-order';
import { SortBy } from '@modules/common/types/SortBy';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export type MessageFolderListSortField = 'title' | 'createdAt' | 'updatedAt';

export class FoldersListState extends BaseStitchListState<MessageFolder, MessageFolderListSortField> {
  @OnChange('onPropertyChange') public override sortBy?: MessageFolderListSortField;

  constructor({
    searchService,
    stateService,
    stateKey,
    withAdvanced,
    defaultSort = { by: 'createdAt', order: SortOrder.DESC },
  }: {
    searchService?: AdvancedSearchService;
    stateService?: StateService;
    stateKey?: StateKey;
    withAdvanced?: boolean;
    defaultSort?: SortBy<MessageFolderListSortField>;
  }) {
    super({ searchService, stateService, stateKey, withAdvanced, defaultSort });
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
    });
  }

  protected override syncFromAS({ mail: state }: AdvancedSearchState) {
    this.stitchedWith = state.stitched;
  }

  protected override syncToAS(): void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.mail;
    state.stitched = this.stitchedWith;

    this.searchService.setState(searchState);
  }
}
