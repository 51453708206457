// Common
import { Component, Inject, NgZone, OnInit, Self, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FormControl } from '@angular/forms';
import { LocalStorageItem, LSStringArray } from 'src/app/decorators/local-storage.decorator';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// Types
import { Notification } from '@modules/notification/types/notification';
import { NotificationsFilters } from '@modules/notification/types/notifications-filters';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Application } from '@modules/common/types/application';
import { SignalEnum } from '@modules/common/types/signal';

// Services
import { NotificationsService } from '@modules/notification/services/notifications.service';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';
import { SignalsService } from '@modules/common/services/signals-service/signals.service';

// RxJS
import { switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class NotificationsListComponent extends InfinityScrollListComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  public itemHeight = 132;
  public filters: Partial<NotificationsFilters>;
  public applicationFilterControl = new FormControl<string[]>(['all']);
  public applicationDropDownOptions: DropdownSelectItem<string>[] = [
    { title: 'All', value: 'all' },
    { title: 'Mail', value: Application.mail },
    { title: 'Calendar', value: Application.calendar },
    { title: 'Tasking', value: Application.tasking },
    { title: 'Notes', value: Application.notes },
    { title: 'Contacts', value: Application.contacts },
    { title: 'Files', value: Application.files },
  ];
  public showIssueKey: boolean;

  @LSStringArray({ default: ['all'], lsKey: 'notifications.applications' })
  applicationsFilter: LocalStorageItem<Application & 'all'[]>;

  constructor(
    private notificationsService: NotificationsService,
    ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject,
    private settingsService: TaskingAppSettingsService,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
    private signalsService: SignalsService,
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  ngOnInit(): void {
    this.spaceId
      .pipe(
        switchMap((spaceId) => this.signalsService.getSignal(SignalEnum.NEW_NOTIFICATION, spaceId)),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(() => this.resetItems());

    this.applicationsFilter.get()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(applications => {
        this.filters = { applications };
        this.applicationFilterControl.setValue(applications);
        this.resetItems();
      });

    this.settingsService
      .listAll()
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(({ issueKeyEnabled }) => (this.showIssueKey = issueKeyEnabled));

    super.ngOnInit();
  }

  getItems(offset: number, limit: number): Observable<{ items: Notification[]; count: number }> {
    return this.notificationsService.search({ ...this.filters, limit, offset });
  }

  public handleClearAll() {
    this.notificationsService.deleteNotifications({ emit: false }).subscribe(() => this.resetItems());
  }

  handleChangeFilter(key: string, value: boolean | string[]) {
    this[key].set(value);
  }

  handleSelectApp(option: DropdownSelectItem<string>) {
    if (option.value !== 'all' && this.applicationFilterControl.value.includes('all')) {
      this.applicationFilterControl.setValue(this.applicationFilterControl.value.filter((item) => item !== 'all'));
    } else if (option.value === 'all' || this.applicationFilterControl.value.length === 0) {
      this.applicationFilterControl.setValue(['all']);
    }

    this.handleChangeFilter('applicationsFilter', this.applicationFilterControl.value);
  }
}
