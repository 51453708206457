// Common
import { Component, Injector } from '@angular/core';

// Services
import { FilesService } from '@modules/files/services/files.service';

// Types
import { File } from '@modules/files/types/file';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './file.component.less'],
  standalone: false,
})
export class FileComponent extends BaseStitchComponent<File> {
  moveDragDataTypes = [];
  dragDataType = DragDataTypes.file;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(dragData.type === DragDataTypes.file && dragData.data.length === 1 && dragData.data[0]['id'] === this.item.id) &&
    dragDataTypeAllowed(dragData.type);

  constructor(injector: Injector, filesService: FilesService) {
    super(injector, filesService);
  }

  download() {
    this.item.download();
  }

  public handleMove(_dragData: DragData) {
    // TODO
  }

  get file(): File {
    return this.item;
  }
}
