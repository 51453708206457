export class PermissionParticipant {
  id?: string;
  value?: string;
  type?: 'white-list' | 'black-list' | 'vip' | 'company' | 'bulk' | 'non-bulk';
  createdAt?: Date;

  constructor(data: Partial<PermissionParticipant> = {}) {
    this.id = data?.id;
    this.value = data?.value;
    this.type = data?.type;
    this.createdAt = data?.createdAt;
  }

  asPayloadJSON() {
    return {
      id: this.id,
      value: this.value,
      type: this.type,
      createdAt: this.createdAt,
    };
  }
}
