// Common
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { GroupsListState } from '@modules/contacts/types/groups-list-state';
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { combineLatest, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-groups-list-context-menu',
  templateUrl: './groups-list-context-menu.component.html',
  styleUrls: ['./groups-list-context-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GroupsListContextMenuComponent implements OnInit, OnChanges, OnDestroy {
  // Input
  @Input() state: GroupsListState;
  @Input() withAdvancedSearch: boolean;
  @Input() withScore: boolean;

  // Public
  public innerFromDate: UntypedFormControl = new UntypedFormControl();
  public innerToDate: UntypedFormControl = new UntypedFormControl();
  public dateButtonSelected: 'from' | 'to' = 'from';
  public advancedSearchApplied: boolean;

  // Private
  private stateChanged = new Subject<void>();
  private alive = new Subject<void>();
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  constructor(private advancedSearchService: AdvancedSearchService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.innerFromDate.valueChanges.pipe(startWith(this.state.from)),
      this.innerToDate.valueChanges.pipe(startWith(this.state.to)),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([from, to]) => {
        if (from?.getTime() !== this.state.from?.getTime() || to?.getTime() !== this.state.to?.getTime()) {
          this.state.from = from;
          this.state.to = to;
          this.state.notifyStateChanged();
        }
      });

    this.stateChanged
      .pipe(
        startWith(void 0),
        switchMap(() => this.state.changes().pipe(startWith(this.state))),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.innerFromDate.setValue(this.state.from, { emitEvent: false });
        this.innerToDate.setValue(this.state.to, { emitEvent: false });
        this.changeDetectorRef.markForCheck();
      });

    this.advancedSearchService
      .getApplied()
      .pipe(takeUntil(this.alive))
      .subscribe((state) => {
        this.advancedSearchApplied = state;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('state' in changes) {
      this.stateChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  sort(sortBy: 'title' | 'date' | 'due-date' | 'score') {
    let order = this.state.sortOrder;
    let by = this.state.sortBy;

    if (by === sortBy) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      by = sortBy;
      order = 'asc';
    }

    this.state.sortBy = by;
    this.state.sortOrder = order;
    this.state.notifyStateChanged();
  }

  pinnedOnTop() {
    this.state.pinnedOnTop = !this.state.pinnedOnTop;
    this.state.notifyStateChanged();
  }

  flaggedOnTop() {
    this.state.flaggedOnTop = !this.state.flaggedOnTop;
    this.state.notifyStateChanged();
  }

  flag() {
    this.state.flagged = this.state.flagged ? null : true;
    this.state.notifyStateChanged();
  }

  pin() {
    this.state.pinned = this.state.pinned ? null : true;
    this.state.notifyStateChanged();
  }

  stitch(stitch: StitchTypeFilters) {
    this.state.stitchedWith = stitch;
    this.state.notifyStateChanged();
  }

  today() {
    this.innerFromDate.setValue(null);
    this.innerToDate.setValue(null);

    this.state.today = !this.state.today;
    this.state.notifyStateChanged();
  }
}
