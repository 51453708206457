// Common
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Components
import { AlertComponent } from './components/alert/alert.component';

// Modules
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';

// Services
import { AlertService } from './services/alert.service';

@NgModule({
  imports: [AngularCommonModule, FormControlsModule, MatIconModule, OverlayModule],
  providers: [AlertService],
  declarations: [AlertComponent],
  exports: [],
})
export class AlertModule {}
