// Common
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-combined-dropdown-input',
  templateUrl: './combined-dropdown-input.component.html',
  styleUrls: ['./combined-dropdown-input.component.less'],
  standalone: false,
})
export class CombinedDropdownInputComponent {
  // Inputs
  @Input() inputFormControl: UntypedFormControl;
  @Input() dropDownFormControl: UntypedFormControl;
  @Input() dropDownOptions: DropdownSelectItem<unknown>[];
}
