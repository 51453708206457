<div class="sidebar">
  <app-sidebar-item
    *dndNestedFor="
      let item of items;
      let index = index;
      let depth = depth;
      let expandable = expandable;
      let expanded = expanded;
      stateKey: 'settings-sidebar';
    "
    [dndNestedForDropDisabled]="true"
    [title]="item.title"
    [icon]="item.icon"
    [depth]="depth"
    [expandable]="expandable"
    [expanded]="expanded.value"
    [active]="currentItemId === item.id || currentContainerId === item.id"
    (onExpand)="expanded.next($event)"
    (appClick)="selectFilter(item)"
    [iconPadding]="depth === 0"
  />
</div>
