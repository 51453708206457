import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesNow',
  standalone: false,
})
export class IncludesNowPipe implements PipeTransform {
  transform(firstDate: Date, secondDate: Date): boolean {
    const now = Date.now();
    return !!secondDate && !!firstDate && now > firstDate.getTime() && now < secondDate.getTime();
  }
}
