// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { CalendarAppModule } from '@modules/calendar-app/calendar-app.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamicPanelModule } from './../dynamic-panel/dynamic-panel.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FilesModule } from '@modules/files/files.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessagesModule } from '@modules/messages/messages.module';
import { NotesModule } from '@modules/notes/notes.module';
import { PagesModule } from '../pages/pages.module';
import { PopoverModule } from '../popover/popover.module';
import { SettingsModule } from '@modules/settings/settings.module';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Directives
import { ModalHostDirective } from './directives/modal-host.directive';

// Components
import { BaseModalComponent } from './components/base-modal/base-modal.component';
import { FullFormModalComponent } from './components/full-form-modal/full-form-modal.component';
import { MessageComposeComponent } from './components/message-compose/message-compose.component';
import { ModalWrapperComponent } from './components/modal-wrapper/modal-wrapper.component';

@NgModule({
  imports: [
    AppCommonModule,
    AttachmentsModule,
    CalendarAppModule,
    CommonModule,
    ContactsModule,
    DragDropModule,
    DynamicPanelModule,
    ElementsModule,
    FilesModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    MessagesModule,
    NotesModule,
    PagesModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    SettingsModule,
    SplitViewModule,
    TasksModule,
    UserAvatarModule,
    UtilsModule,
  ],
  exports: [BaseModalComponent, MessageComposeComponent, ModalHostDirective, ModalWrapperComponent],
  declarations: [
    BaseModalComponent,
    FullFormModalComponent,
    MessageComposeComponent,
    ModalHostDirective,
    ModalWrapperComponent,
  ],
  providers: [],
})
export class ModalModule {}
