// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { StitchTypeContextMenuComponent } from '../components/stitch-type-context-menu/stitch-type-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { KPListType } from '../types/kp-list-type';

// RX
import { take, takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.kpResultsStitchType]?: KPListType;
  }
}

@Directive({
  selector: '[appKPStitchTypeContextMenu]',
  standalone: false,
})
export class StitchTypeContextMenuDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() appKPStitchTypeContextMenu: StateKey;

  // Outputs
  @Output() appKPStitchTypeContextMenuOpened = this.contextMenuOpened;
  @Output() appKPStitchTypeContextMenuChange = new EventEmitter<KPListType>();

  // Private
  private value: KPListType = KPListType.message;

  constructor(
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<StitchTypeContextMenuComponent>) {
    componentRef.instance.value = this.value;

    componentRef.instance.change.pipe(takeUntil(this.alive)).subscribe((value: KPListType) => {
      this.value = value;
      this.appKPStitchTypeContextMenuChange.emit(this.value);
      componentRef.instance.value = this.value;
      this.stateService.setState(this.appKPStitchTypeContextMenu, this.value);
    });
  }

  registerComponent() {
    return StitchTypeContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stateService
      .getState(this.appKPStitchTypeContextMenu)
      .pipe(take(1), takeUntil(this.alive))
      .subscribe((filterKey: KPListType) => {
        this.value = filterKey || KPListType.message;
        this.appKPStitchTypeContextMenuChange.emit(this.value);
      });
  }
}
