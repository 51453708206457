<div class="notifications">
  <div class="notifications-header">
    <div class="notifications-header-title">
      <h1>Notifications</h1>

      <span
        class="notifications-header-title-mark-all"
        (click)="handleClearAll()"
      >Clear All</span>
    </div>

    <div class="notifications-header-footer">
      <app-dropdown-multiple-input
        appearance="amethyst"
        class="notifications-header-footer-dropdown"
        [inputFormControl]="applicationFilterControl"
        [options]="applicationDropDownOptions"
        (select)="handleSelectApp($event)"
      />
    </div>
  </div>

  <div *ngIf="!items?.length">There are no notifications yet</div>

  <cdk-virtual-scroll-viewport
    [itemSize]="itemHeight"
    minBufferPx="500"
    maxBufferPx="1320"
    class="notifications-list-wrapper"
  >
    <ng-container *cdkVirtualFor="let notification of itemsStreamObservable">
      <app-notification
        [notification]="notification"
        [showIssueKey]="showIssueKey"
      />
    </ng-container>
  </cdk-virtual-scroll-viewport>

</div>

