// Common
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-settings-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.less'],
  standalone: false,
})
export class FilesComponent {
  // Public
  public form = new FormGroup({
    openInListView: new FormControl<boolean>(true),
    browseInListView: new FormControl<boolean>(false),
    useRelativeDates: new FormControl<boolean>(false),
    calculateAllSizes: new FormControl<boolean>(false),
    groupBy: new FormControl<string>('none'),
    sortBy: new FormControl<string>('lastOpenedDate'),
    showDate: new FormControl<string[]>(['created', 'updated']),
  });
  public sortOptions: DropdownSelectItem<unknown>[] = [
    { title: 'None', value: 'none' },
    { title: 'Last Opened Date', value: 'lastOpenedDate' },
    { title: 'Last Modified Date', value: 'lastModifiedDate' },
    { title: 'Created Date', value: 'createdDate' },
    { title: 'Name', value: 'name' },
  ];
  public showDateOptions: DropdownSelectItem<unknown>[] = [
    { title: 'None', value: 'none' },
    { title: 'Creation Date', value: 'created' },
    { title: 'Modified Date', value: 'updated' },
    { title: 'Last Opened Date', value: 'opened' },
  ];
}
