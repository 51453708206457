// Common
import { UntypedFormGroup, Validators } from '@angular/forms';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Message } from '@modules/messages/types/message';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

// Validators
import { NestedValidators } from '@modules/form-controls/validators/nested.validators';

// Services
import { NotebooksService } from '../services/notebooks.service';

export class Notebook extends Stitch {
  parentId?: string;
  title?: string;

  constructor(data: Partial<Notebook> = {}) {
    super(data);

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.parentId = data.parentId || null;
      this.title = data.title;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Notebook {
    const formValues = form.value;

    return new Notebook({
      archived: formValues.archived,
      color: formValues.color,
      connections: formValues.connections,
      deleted: formValues.deleted,
      description: formValues.description,
      flagged: formValues.flagged,
      followed: formValues.followed,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      parentId: formValues.parentId,
      pinned: formValues.pinned,
      snoozed: formValues.snoozed,
      tags: formValues.tags,
      title: formValues.title,
      uploads: formValues.uploads,
    });
  }

  static fromDragData(dragItem: DragData): Notebook {
    return super.fromDragData(dragItem) as Notebook;
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Notebook {
    return new Notebook({
      title: filters.query || filters.notes.title,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.notes.description || filters.notes.body,
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color', 'parentId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<Notebook>; message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_notes':
        break;
      case 'all_notebooks':
        return { changes: { parentId: null }, message: (message += 'Orphan') };
      case 'upcoming':
      case 'recent':
        break;
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.notebook;
  }

  asFormGroup(notebooksService?: NotebooksService): UntypedFormGroup {
    return this.formBuilder.group(
      {
        archived: [this.archived],
        color: [this.color],
        connections: [this.connections],
        deleted: [this.deleted],
        description: [this.description],
        flagged: [this.flagged],
        followed: [this.followed],
        id: [this.id],
        knots: [this.knots || []],
        linkedInfo: [this.linkedInfo || []],
        parentId: [this.parentId || ''],
        snoozed: [this.snoozed],
        tags: [this.tags || []],
        title: [this.title, Validators.required],
        uploads: [this.uploads || [], FileValidators.size(26214400)],
      },
      {
        asyncValidators: [NestedValidators.circular('id', 'parentId', notebooksService)],
      },
    );
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      color: this.color,
      deleted: this.deleted,
      description: this.description,
      flagged: this.flagged,
      followed: this.followed,
      parentId: this.parentId,
      snoozed: this.snoozed,
      title: this.title && this.title.trim(),
      noNotification: this.noNotification,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title,
      this.createdAt,
      this.updatedAt,
      this.createdAt,
      this.updatedAt,
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.description = data.bodyText;
    }
  }
}
