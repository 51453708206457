// Common
import { Directive, ViewContainerRef, OnInit, OnDestroy, ComponentRef } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '../services/modal.service';

// Components
import { ModalWrapperComponent } from '../components/modal-wrapper/modal-wrapper.component';

// Types
import { ModalConfig } from '../types/modal-config';

@Directive({
  selector: '[appModalHost]',
  standalone: false,
})
export class ModalHostDirective implements OnInit, OnDestroy {
  // Private
  private alive = new Subject<void>();
  private modals = {};
  private maxNumberOfSameTypeModals = 5;
  private minimizedModals: ComponentRef<ModalWrapperComponent>[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
  ) {}

  /**
   * Directive lifecycle
   */

  ngOnInit() {
    this.modalService
      .getDisplay()
      .pipe(takeUntil(this.alive))
      .subscribe((data: ModalConfig) => {
        if (!this.isAvailableForCreatingMore(data)) {
          return;
        }

        const wrapperComponentRef = this.viewContainerRef.createComponent(ModalWrapperComponent, {
          injector: data.injector,
        });

        wrapperComponentRef.instance.overlaps = this.isModalExists(data.component.name);
        wrapperComponentRef.instance.childComponent = data.component;
        wrapperComponentRef.instance.childComponentData = data.content;
        wrapperComponentRef.instance.resize = data.resize ?? true;
        wrapperComponentRef.instance.title = data.title;
        wrapperComponentRef.instance.appearance = data.appearance || 'amethyst';
        wrapperComponentRef.instance.frame = data.frame || {
          x: 'calc(50% - 400px)',
          y: 'calc(50% - 250px)',
          width: '800px',
          height: '500px',
        };
        wrapperComponentRef.instance.collapsible = data.collapsible;
        wrapperComponentRef.instance.expandable = data.expandable;
        wrapperComponentRef.instance.icon = data.icon;

        this.addModal(data.component.name);

        wrapperComponentRef.instance.onClose.pipe(take(1), takeUntil(this.alive)).subscribe(() => {
          this.removeModal(data.component.name);
          this.removeFromMinimized(wrapperComponentRef);
          wrapperComponentRef.destroy();
        });

        wrapperComponentRef.instance.collapsed.subscribe((minimized) => {
          this.collapseModal(wrapperComponentRef, minimized);
        });
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  addModal(modalName: string) {
    if (this.modals[modalName]) {
      this.modals[modalName]++;
    } else {
      this.modals[modalName] = 1;
    }
  }

  removeModal(modalName: string) {
    if (this.modals[modalName]) {
      this.modals[modalName]--;
    }
  }

  isModalExists(modalName: string) {
    return this.modals[modalName] > 0;
  }

  isAvailableForCreatingMore(data: ModalConfig): boolean {
    const modalName = data.component.name;
    const maxSameModal = data.maxModals || this.maxNumberOfSameTypeModals;
    return !this.modals[modalName] || this.modals[modalName] < maxSameModal;
  }

  removeFromMinimized(component: ComponentRef<ModalWrapperComponent>) {
    const removeIndex = this.minimizedModals.indexOf(component);
    if (removeIndex !== -1) {
      this.minimizedModals.splice(removeIndex, 1);
    }
    this.minimizedModals.forEach((value, index) => {
      value.instance.collapse(true, index);
    });
  }

  collapseModal(component: ComponentRef<ModalWrapperComponent>, minimized: boolean) {
    if (minimized) {
      const index = this.minimizedModals.push(component) - 1;
      component.instance.collapse(minimized, index);
    } else {
      this.removeFromMinimized(component);
      component.instance.collapse(minimized);
    }
  }
}
