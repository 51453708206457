// Common
import { Injectable } from '@angular/core';
import { LSStringArray, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';

// RX
import { Subject, BehaviorSubject, of } from 'rxjs';
import { map, takeUntil, switchMap, filter } from 'rxjs/operators';

// Types
import { NestedDataItem, NestedTreeItem } from '../types/nested-item';

@Injectable()
export class DNDListService {
  private items: NestedDataItem[] = [];
  private beforeTreeChanged = new Subject<void>();
  private stateKey = new BehaviorSubject<string>(null);
  private tree = new BehaviorSubject<NestedTreeItem[]>([]);
  private maxDepth = new BehaviorSubject<number>(Infinity);
  @LSStringArray({ lsKey: 'dnd-list' }) private expandedState: LocalStorageItem<string[]>;
  private expandedIds: string[] = [];
  private newlyAddedIds: string[] = [];
  private changes = new Subject<NestedDataItem[]>();
  private positionKey: string;

  constructor() {
    this.stateKey.pipe(switchMap((key) => (key ? this.expandedState.get(key) : of([])))).subscribe((value) => {
      this.expandedIds = value;
    });
  }

  getChanges() {
    return this.changes.pipe(filter((changes) => changes.length > 0));
  }

  setStateKey(key: string) {
    this.stateKey.next(key);
  }

  setPositionKey(key: string) {
    this.positionKey = key;
  }

  setItems(items: NestedDataItem[]) {
    this.beforeTreeChanged.next();

    const [treeItems] = this.syncBranch(items, null, 0);
    this.items = this.flattenTree(treeItems);
    this.tree.next(treeItems);

    this.changes.next(
      this.items.filter(
        (item) =>
          item.position !== item.data[this.positionKey] ||
          item.parentId !== item.data.parentId ||
          this.newlyAddedIds.includes(item.id),
      ),
    );

    this.newlyAddedIds = [];
  }

  getItems() {
    return this.tree.asObservable();
  }

  flattenTree(tree: NestedTreeItem[], flatArray: NestedTreeItem[] = []): NestedTreeItem[] {
    for (const item of tree) {
      flatArray.push(item);

      if (item.children && item.children.length > 0) {
        this.flattenTree(item.children, flatArray);
      }
    }

    return flatArray;
  }

  private syncBranch(
    items: NestedDataItem[],
    parentId: string,
    depth: number,
    parentIndex = -1,
  ): [NestedTreeItem[], number] {
    const result: NestedTreeItem[] = [];
    let index = parentIndex;

    items
      .filter((item) => item.parentId === parentId)
      .sort((a, b) => a.position - b.position)
      .forEach((item, position) => {
        index++;

        const [children, newIndex] = this.syncBranch(items, item.id, depth + 1, index);

        const expanded = new BehaviorSubject<boolean>(
          this.stateKey.getValue() ? this.expandedIds.includes(item.id) : true,
        );

        expanded
          .pipe(
            map((value) => [item.id, value]),
            takeUntil(this.beforeTreeChanged),
          )
          .subscribe(([id, value]: [string, boolean]) => {
            this.syncState(id, value);
          });

        result.push({
          index,
          position,
          data: item.data,
          parentId: item.parentId,
          id: item.id,
          children: depth >= this.maxDepth.getValue() ? [] : children,
          expanded,
        });

        if (depth >= this.maxDepth.getValue()) {
          this.flattenTree(children).forEach((child, childPosition) => {
            index++;

            result.push({
              index,
              position: position + childPosition + 1,
              data: child.data,
              parentId: item.parentId,
              id: child.id,
              children: [],
              expanded: child.expanded,
            });
          });
        }

        index = newIndex;
      });

    return [result, index];
  }

  syncState(id: string, expanded: boolean) {
    let newState = [...this.expandedIds];

    if (expanded) {
      if (!newState.includes(id)) {
        newState.push(id);
      }
    } else {
      newState = newState.filter((item) => id !== item);
    }

    if (this.stateKey.getValue()) {
      this.expandedState.set(newState, this.stateKey.getValue());
    }
  }

  findTarget(items: NestedTreeItem[], itemId: string) {
    for (const [index, item] of items.entries()) {
      if (item.id === itemId) {
        return [items[index - 1], item];
      }

      const [beforeChildTarget, childTarget] = this.findTarget(item.children, itemId);

      if (childTarget) {
        return [beforeChildTarget, childTarget];
      }
    }

    return [null, null];
  }

  move(movedData: NestedDataItem[], targetItemId: string, after: boolean, nested: boolean) {
    const tree = this.tree.getValue();

    const [beforeTarget, target] = this.findTarget(tree, targetItemId);

    const movedIds = movedData.map(({ id }) => id);

    const filteredItems = this.items.filter(({ id }) => !movedIds.includes(id));

    if (!target) {
      movedData.forEach((item, index) => {
        item.parentId = null;
        item.position = index;
      });
    } else if (after && nested) {
      movedData.forEach((item, index) => {
        item.parentId = target.id;
        item.position = (movedData.length - index) * -1;
      });

      this.expandedIds.push(target.id);
    } else if (after && !nested) {
      movedData.forEach((item, index) => {
        item.parentId = target.parentId;
        item.position = target.position + (1 / (movedData.length + 1)) * (index + 1);
      });
    } else if (!after && nested && beforeTarget) {
      movedData.forEach((item, index) => {
        item.parentId = beforeTarget.id;
        item.position = beforeTarget.children.length + index;
      });

      this.expandedIds.push(beforeTarget.id);
    } else if ((!after && !nested) || (!after && nested && !beforeTarget)) {
      movedData.forEach((item, index) => {
        item.parentId = target.parentId;
        item.position = target.position - (1 / (movedData.length + 1)) * (movedData.length - index + 1);
      });

      this.expandedIds.push(target.id);
    }

    const existingItemsIds = this.items.map(({ id }) => id);

    movedData.forEach((item) => {
      if (!existingItemsIds.includes(item.id) && item.id !== 'temp') {
        this.newlyAddedIds.push(item.id);
      }
    });

    this.setItems([...filteredItems, ...movedData]);
  }

  setMaxDepth(maxDepth: number) {
    this.maxDepth.next(maxDepth >= 0 ? maxDepth : Infinity);
  }

  getMaxDepth() {
    return this.maxDepth.asObservable();
  }

  detach() {
    this.beforeTreeChanged.next();
    this.beforeTreeChanged.complete();
    this.stateKey.complete();
  }
}
