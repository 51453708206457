// Common
import { Component, Injectable, Input, TemplateRef } from '@angular/core';

// Types
import { CalendarDateFormatter, CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';

// RX
import { Subject } from 'rxjs';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }
}

@Component({
  selector: 'stch-calendar-month-small',
  templateUrl: './calendar-month-small.component.html',
  styleUrls: ['./calendar-month-small.component.less'],
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }],
  standalone: false,
})
export class CalendarMonthSmallComponent {
  @Input() viewDate: Date = new Date();
  @Input() events: CalendarEvent[];
  @Input() refresh: Subject<void>;
  @Input() dayTemplate: TemplateRef<any>;
}
