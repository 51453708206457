<div
  class="modal"
  [class.minimized]="minimized"
  [ngClass]="appearance"
>
  <div class="modal-heading">
    <mat-icon
      *ngIf="icon && minimized"
      [svgIcon]="icon"
      class="modal-heading-icon"
    />

    <div class="modal-heading-title">{{ title }}</div>

    <div class="modal-heading-divider"></div>

    <mat-icon
      *ngIf="collapsible"
      svgIcon="collapse"
      class="modal-heading-icon"
      (click)="doCollapse()"
    />

    <mat-icon
      *ngIf="expandable"
      svgIcon="expand"
      class="modal-heading-icon"
      (click)="expand()"
    />

    <mat-icon
      svgIcon="close2"
      class="modal-heading-icon"
      (click)="close()"
    />
  </div>

  <div class="modal-body">
    <ng-template #modalAnchor />
  </div>

  <mat-icon
    *ngIf="resize && !minimized"
    class="resize-icon"
    svgIcon="resize"
    (mousedown)="handleResize($event)"
  />
</div>
