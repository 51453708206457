// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// Rx
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { StitchService } from '@modules/common/services/stitch.service';

// Types
import { Import } from '../types/import';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class ImportsService {
  private refreshRequired = new BehaviorSubject<void>(null);

  constructor(
    protected http: HttpClient,
    protected toaster: ToasterService,
    protected stitchService: StitchService,
  ) {}

  private handleObserverError(error: HttpErrorResponse) {
    this.toaster.show({ text: error?.error?.message || error?.error?.error || error?.message });
    return throwError(error);
  }

  public forceRefresh() {
    this.refreshRequired.next();
  }

  getRefreshRequired(): Observable<void> {
    return this.refreshRequired.asObservable();
  }

  /**
   * Methods
   */

  listAll(): Observable<Import[]> {
    return this.refreshRequired.pipe(
      switchMap(() => this.http.get<{ items: object[] }>(`${environment.baseUrl}/api/mail/imports`)),
      map(({ items }) => items.map((item) => new Import(item))),
    );
  }

  create(
    importInstance: Import,
    { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true },
  ): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/mail/imports`, importInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();

          displayToast && this.toaster.show({ text: toastMessage || `Import created.` });
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  delete(importInstance: Import, emitChanges = true, displayToast = true): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(`${environment.baseUrl}/api/mail/imports/${importInstance.id}`).pipe(
      map(({ success }) => success),
      tap((success) => {
        if (!success) {
          return;
        }

        emitChanges && this.forceRefresh();

        displayToast && this.toaster.show({ text: 'Import successfully deleted' });
      }),
    );
  }

  @warmUpObservable
  retry(importInstance: Import): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/mail/imports/${importInstance.id}/retry`, {})
      .pipe(map(({ success }) => success));
  }
}
