import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Signature {
  formBuilder = injector.get(FormBuilder);

  id: string;
  title: string;
  content: string;
  isDefault: boolean;

  constructor(data: Partial<Signature> = {}) {
    this.id = data.id;
    this.title = data.title || '';
    this.content = data.content || '';
    this.isDefault = data.isDefault;
  }

  static fromFormGroup(form: FormGroup): Signature {
    const { value: formValues } = form;

    return new Signature({
      id: formValues.id,
      title: formValues.title,
      content: formValues.content,
      isDefault: formValues.isDefault,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: this.id,
      title: this.title,
      content: this.content,
      isDefault: this.isDefault,
    });
  }

  public asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
      isDefault: this.isDefault,
    };
  }
}
