// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Types
import { Account } from '@modules/account/types/account';

// Utils
import { emailPattern } from '@modules/common/utils/email';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { AccountService } from '@modules/account/services/account.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { AuthService } from '@modules/auth/services/auth.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

// Components
import { ChangePasswordComponent } from '@modules/settings/components/general/account/change-password/change-password.component';
import { AvatarFormComponent } from '@modules/settings/components/avatar-form/avatar-form.component';

@Component({
  selector: 'app-settings-general-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less'],
  standalone: false,
})
export class AccountComponent implements OnInit, OnDestroy {
  public account: Account;
  public generalForm = new FormGroup({
    recoveryEmail: new FormControl<string>('', [Validators.pattern(emailPattern)]),
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
  });

  private alive = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private alertsService: AlertService,
    private auth: AuthService,
    private router: Router,
    private modalService: ModalService,
    private injector: Injector,
  ) {}

  ngOnInit() {
    this.accountService
      .getAccount()
      .pipe(takeUntil(this.alive))
      .subscribe((account) => {
        this.account = account;
        this.generalForm.controls.firstName.setValue(this.account.firstName, { emitEvent: false });
        this.generalForm.controls.lastName.setValue(this.account.lastName, { emitEvent: false });
        this.generalForm.controls.recoveryEmail.setValue(this.account.recoveryEmail, { emitEvent: false });
      });

    this.generalForm.valueChanges
      .pipe(
        debounceTime(1000),
        switchMap((value) => {
          if (!this.generalForm.valid) {
            return of();
          }

          return this.accountService.update(new Account(value));
        }),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  removeAvatar() {
    this.accountService
      .removeAvatar()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.accountService.reloadAccount();
      });
  }

  removeAvatarPrompt() {
    this.alertsService.show({
      title: 'Are you sure?',
      body: 'Are you sure you want to remove the avatar image from disk?',
      rightButtons: [
        {
          title: 'CANCEL',
          close: true,
        },
        {
          title: 'REMOVE',
          click: () => this.removeAvatar(),
          close: true,
        },
      ],
      showUntil: this.alive,
    });
  }

  cancelAccount() {
    this.accountService
      .cancel()
      .pipe(
        switchMap(() => this.auth.signOut()),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.router.navigate(['welcome']);
      });
  }

  cancelAccountPrompt() {
    this.alertsService.show({
      title: 'Delete account',
      body: 'Warning! Account cancellation cannot be undone.',
      rightButtons: [
        {
          title: 'CANCEL',
          close: true,
        },
        {
          title: 'DELETE ACCOUNT',
          click: () => this.cancelAccount(),
          close: true,
        },
      ],
      showUntil: this.alive,
    });
  }

  signOut() {
    this.accountService.cleanUp();

    this.auth
      .signOut()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.router.navigate(['welcome']);
      });
  }

  editAvatar() {
    this.modalService.showModal({
      component: AvatarFormComponent,
      content: {},
      appearance: 'amethyst',
      title: 'Avatar',
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
      injector: this.injector,
    });
  }

  changePassword() {
    this.modalService.showModal({
      title: 'Change password',
      component: ChangePasswordComponent,
      content: {},
      appearance: 'default',
      injector: this.injector,
    });
  }

  openStorybook() {
    window.open('https://storybook-stitch.web.app', '_blank').focus();
  }
}
