// Common
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

// Types
import { File } from '@modules/files/types/file';
import { Folder } from '@modules/files/types/folder';
import { Tab } from '@modules/common/types/tab';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { FilesService } from '@modules/files/services/files.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-file-quick-form',
  templateUrl: './file-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './file-quick-form.component.less'],
  standalone: false,
})
export class FileQuickFormComponent extends BaseQuickFormComponent implements OnChanges {
  @Input() file: File = new File();
  @Input() folder: Folder;
  @Input() fromDnd = false;

  @Output() more: EventEmitter<File> = new EventEmitter<File>();
  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<File>();

  public tabs: Tab[] = [
    { value: 'file', title: 'Upload' },
    { value: 'link', title: 'Embed link' },
  ];
  public selectedTab: 'file' | 'link' = 'file';

  constructor(
    private filesService: FilesService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('file' in changes) {
      this.reset();
    }

    if ('folder' in changes) {
      this.form.patchValue({ folderId: this.folder?.id });
    }
  }

  /**
   * Actions
   */

  submit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const file = File.fromFormGroup(this.form);
    const { linkedInfo } = this.form.value;

    if (this.save.observers.length > 0) {
      this.save.emit(file);
      return;
    }

    this.saveInProgress = true;

    if (this.fromDnd && linkedInfo?.length) {
      this.filesService
        .createPDFfromStitch(
          {
            stitchId: linkedInfo[0].id,
            stitchType: linkedInfo[0].type,
            title: file.title,
          },
          { toast: true },
        )
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          this.close.emit();
        });
    } else {
      this.filesService
        .create(file)
        .pipe(takeUntil(this.alive))
        .subscribe(
          (fileInstance) => {
            this.afterSave.emit(fileInstance);
            this.close.emit();
          },
          () => this.handleError(),
        );
    }
  }

  reset() {
    if (!this.file) {
      return;
    }
    this.form = this.file.asFormGroup();
    if (this.fromDnd) {
      this.form.get('attachment').clearValidators();
      this.form.get('attachment').updateValueAndValidity();
    }
    this.folder && this.form.patchValue({ folderId: this.folder.id });
    super.reset();
  }

  clickMore() {
    this.more.emit(File.fromFormGroup(this.form));
    super.clickMore();
  }
}
