import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { type Icon } from '@modules/icons/types/icons';

@Component({
  standalone: true,
  selector: 'app-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
  template: `<mat-icon [svgIcon]="name()" />`,
  styleUrl: './icon.component.css',
})
export class IconComponent {
  public readonly name = input.required<Icon>();
}
