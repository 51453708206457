// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Knot } from '@modules/knots/types/knot';
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';

@Component({
  selector: 'app-add-knots',
  templateUrl: './add-knots.component.html',
  styleUrls: ['./add-knots.component.less'],
  standalone: false,
})
export class AddKnotsComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() stitchItems: Stitch[];
  @Input() control: UntypedFormControl;

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Knot[]>();

  // Public
  public inputControl = new UntypedFormControl('');
  public canAdd = false;
  public state: ManageListState = {
    sort: {
      by: 'name',
      order: 'asc',
    },
    filters: {
      createdFrom: null,
      createdTo: null,
    },
  };
  public knots: Knot[] = [];
  public suggestions: AutocompleteFactory<Knot>;
  public suggestionsReceived = false;
  public focused = false;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(private knotsService: KnotsService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestions = (query?, _values?, { limit }: { limit: number } = { limit: 5 }) => {
      if (!query || query.trim() === '') {
        return of([]);
      }

      if (query.trim().split(',').length > 1) {
        this.suggestionsReceived = true;
        return of([]);
      }

      this.suggestionsReceived = false;

      return this.knotsService.search({ limit, query }).pipe(
        tap(() => (this.suggestionsReceived = true)),
        map(({ items: knots }) =>
          knots.map((knot) => ({
            title: knot.name,
            value: knot.name,
            source: knot,
          })),
        ),
      );
    };

    this.inputControl.valueChanges.pipe(takeUntil(this.alive)).subscribe((value: string) => {
      const newKnots = (value || '')
        .split(',')
        .map((item) => item.trim())
        .filter((item) => !!item);

      const knotsNames = this.knots.map(({ name }) => name);

      this.canAdd = newKnots.some((item) => !knotsNames.includes(item));
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addKnot() {
    if (!this.canAdd) {
      return;
    }

    const names: string[] = this.inputControl.value
      .split(',')
      .map((item) => item.trim())
      .filter((item) => !!item && !this.knots.some(({ name }) => name === item));

    if (!names.length) {
      return;
    }

    this.knotsService
      .search({ names })
      .pipe(take(1), takeUntil(this.alive))
      .subscribe(({ items: receivedKnots }) => {
        const receivedKnotsNames = receivedKnots.map(({ name }) => name);
        const newKnots = names.reduce(
          (memo, name) => (receivedKnotsNames.includes(name) ? memo : [...memo, new Knot({ name, added: true })]),
          [],
        );

        this.knots = [...this.knots, ...receivedKnots.map((knot) => new Knot({ ...knot, added: true })), ...newKnots];
      });

    this.inputControl.setValue('');
  }

  handlePin(knot: Knot) {
    this.knots = this.knots.map((item) =>
      item.name === knot.name ? new Knot({ ...knot, pinned: !knot.pinned, changed: true }) : item,
    );
  }

  handleDelete(knot: Knot) {
    if (knot.added) {
      this.knots = this.knots.filter((item) => item.name !== knot.name);
    } else {
      this.knots = this.knots.map((item) => (item.name === knot.name ? new Knot({ ...knot, deleted: true }) : item));
    }
  }

  handleCancel() {
    this.close.emit();
  }

  handleSelect(option: DropdownSelectItem<Knot>) {
    this.knots = [...this.knots, new Knot({ ...option.source, added: true })];
    this.inputControl.setValue('');
  }

  handleSave() {
    if (this.save.observers.length > 0) {
      this.save.emit(this.knots);
      this.close.emit();
    } else {
      const newKnots = this.knots.filter((knot) => (knot.added && knot.id === undefined) || knot.id === null);
      const changedKnots = this.knots.filter((knot) => knot.changed && knot.id !== undefined && knot.id !== null);
      const linkedKnots = this.knots.filter((knot) => knot.added);
      const unlinkedKnots = this.knots.filter((knot) => knot.deleted);

      combineLatest([
        newKnots.length ? this.knotsService.createBulk(newKnots, false, false) : of(true),
        changedKnots.length ? this.knotsService.updateBulk(changedKnots, false, false) : of(true),
      ])
        .pipe(
          switchMap(([created, updated]) =>
            created && updated && this.stitchItems?.length
              ? combineLatest([
                  linkedKnots.length ? this.knotsService.link(linkedKnots, this.stitchItems, false, false) : of(true),
                  unlinkedKnots.length
                    ? this.knotsService.unlink(unlinkedKnots, this.stitchItems, false, false)
                    : of(true),
                ])
              : of(null),
          ),
          takeUntil(this.alive),
        )
        .subscribe(() => {
          this.knotsService.forceRefresh();
          this.close.emit();
        });
    }
  }
}
