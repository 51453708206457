<div class="block">
  <div class="heading2">
    White List
    <app-toggle-switch
      class="heading2-toggle"
      [inputFormControl]="filters.controls.whiteListEnabled"
    />
  </div>

  <div class="body-secondary">
    Emails from those senders are never moved to the Junk folder.
  </div>

  <app-input
    class="input"
    appearance="amethyst"
    [inputFormControl]="addControls['white-list']"
    label="Add"
    leftIcon="plus-medium"
    [invertedColor]="true"
    [withErrors]="submitted"
    [validationMessages]="{
      pattern: 'Invalid email address',
      uniqueness: 'This address is already taken'
    }"
    (onEnter)="addToList('white-list')"
  />

  <div
    class="add"
    (click)="addToList('white-list')"
  >
    + Add Email
  </div>

  <app-settings-messages-permissions-list type="white-list" />
</div>

<div class="block">
  <div class="heading2">
    Black List
    <app-toggle-switch
      class="heading2-toggle"
      [inputFormControl]="filters.controls.blackListEnabled"
    />
  </div>

  <div class="body-secondary">
    You can add a sender to Blacklist and block those who send spam emails.
  </div>

  <app-input
    class="input"
    appearance="amethyst"
    [inputFormControl]="addControls['black-list']"
    label="Add"
    leftIcon="plus-medium"
    [invertedColor]="true"
    [withErrors]="submitted"
    [validationMessages]="{
      pattern: 'Invalid email address',
      uniqueness: 'This address is already taken'
    }"
    (onEnter)="addToList('black-list')"
  />

  <div
    class="add"
    (click)="addToList('black-list')"
  >
    + Add Email
  </div>

  <app-settings-messages-permissions-list type="black-list" />
</div>

<div class="block">
  <div class="heading2">
    Vip List
    <app-toggle-switch
      class="heading2-toggle"
      [inputFormControl]="filters.controls.vipListEnabled"
    />
  </div>

  <div class="body-secondary">
    You can mark a sender as a VIP to keep track of messages they send.
  </div>

  <app-input
    class="input"
    appearance="amethyst"
    [inputFormControl]="addControls['vip']"
    label="Add"
    leftIcon="plus-medium"
    [invertedColor]="true"
    [withErrors]="submitted"
    [validationMessages]="{
      pattern: 'Invalid email address',
      uniqueness: 'This address is already taken'
    }"
    (onEnter)="addToList('vip')"
  />

  <div
    class="add"
    (click)="addToList('vip')"
  >
    + Add Email
  </div>

  <app-settings-messages-permissions-list type="vip" />
</div>

<div class="block">
  <div class="heading2">
    Save Company Domains
    <app-toggle-switch
      class="heading2-toggle"
      [inputFormControl]="filters.controls.companyDomainsListEnabled"
    />
  </div>

  <div class="body-secondary">
    Don’t move email from these senders to my Junk email folder.
  </div>

  <app-input
    class="input"
    appearance="amethyst"
    [inputFormControl]="addControls['company']"
    label="Add"
    leftIcon="plus-medium"
    [invertedColor]="true"
    [withErrors]="submitted"
    [validationMessages]="{
      uniqueness: 'This address is already taken'
    }"
    (onEnter)="addToList('company')"
  />

  <div
    class="add"
    (click)="addToList('company')"
  >
    + Add Email
  </div>

  <app-settings-messages-permissions-list type="company" />
</div>

<div class="block">
  <div class="heading2">
    Bulk
  </div>

  <div class="body-secondary">
    Don’t move email from these senders to my Junk email folder.
  </div>

  <app-input
    class="input"
    appearance="amethyst"
    [inputFormControl]="addControls['bulk']"
    label="Add"
    leftIcon="plus-medium"
    [invertedColor]="true"
    [withErrors]="submitted"
    [validationMessages]="{
      pattern: 'Invalid email address',
      uniqueness: 'This address is already taken'
    }"
    (onEnter)="addToList('bulk')"
  />

  <div
    class="add"
    (click)="addToList('bulk')"
  >
    + Add Email
  </div>

  <app-settings-messages-permissions-list type="bulk" />
</div>

<div class="block">
  <div class="heading2">
    Non Bulk
  </div>

  <div class="body-secondary">
    Don’t move email from these senders to my Junk email folder.
  </div>

  <app-input
    class="input"
    appearance="amethyst"
    [inputFormControl]="addControls['non-bulk']"
    label="Add"
    leftIcon="plus-medium"
    [invertedColor]="true"
    [withErrors]="submitted"
    [validationMessages]="{
      pattern: 'Invalid email address',
      uniqueness: 'This address is already taken'
    }"
    (onEnter)="addToList('non-bulk')"
  />

  <div
    class="add"
    (click)="addToList('non-bulk')"
  >
    + Add Email
  </div>

  <app-settings-messages-permissions-list type="non-bulk" />
</div>
