// Common
import { Component, Input } from '@angular/core';

// Types
import { Icon } from '@modules/icons/types/icons';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.less'],
  standalone: false,
})
export class BadgeComponent {
  @Input() icon: Icon;
  @Input() label: string;
  @Input() appearance: 'light' | 'gray' | 'accent' | 'green' | 'red' = 'accent';
  @Input() size: 'small' | 'medium' = 'small';
  @Input() shape: 'rectangle' | 'rounded' = 'rectangle';
}
