// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Stitch } from '@modules/common/types/stitch';

@Component({
  selector: 'app-stitch-info-popover',
  templateUrl: './stitch-info-popover.component.html',
  styleUrls: ['./stitch-info-popover.component.less'],
  standalone: false,
})
export class StitchInfoPopoverComponent {
  @Input() stitch: Stitch;

  @Output() dblClickStitchItem = new EventEmitter<Stitch>();
}
