<div
  *ngIf="attachment"
  class="file"
  [class.hovered]="priorityOpened || contextMenuOpened"

  [appFileContextMenu]="file"
  (appFileContextMenuOpened)="contextMenuOpened = $event"
  (appFileContextMenuOpenFile)="open.emit()"

  (appClick)="onClick.emit(file)"
  (appDblClick)="onDoubleClick.emit(file)"
>
  <div class="file-selection"></div>

  <div
    class="file-type-icon"
    [style.backgroundColor]="attachment.fileType | fileColorByType"
  >
    {{ attachment.fileType | fileMimeToExt }}
  </div>

  <div class="file-body">
    <div class="file-body-title">
      <div
        class="file-body-title-text"
        [class.blank]="!attachment.fileName"
      >
        {{ attachment.fileName || 'Untitled File' }}
      </div>
    </div>

    <div class="file-body-date">
      <div
        *ngIf="!withTags && !withKnots"
        class="file-body-date-item"
        [class.blank]="!receivedDate"
      >
        {{ receivedDate ? 'Received Date: ' + (receivedDate | date : 'MMM d') : '' }};
        {{ attachment.fileSize ? 'Size: ' + (attachment.fileSize | fileSize) : '' }}
      </div>
    </div>
  </div>

  <mat-icon
    class="file-marker"
    svgIcon="file"
  />

  <div
    class="file-hover-icons"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="download-large"
      (click)="download()"
    />
  </div>
</div>
