<div
  class="knot text-sm font-medium px-4"
  [class.pinned]="knot?.pinned"
  [class.drag-placeholder]="!dragEnabled"
  [class.selected]="selected"
  [class.manual]="knot?.source === 'user'"

  [appKnotContextMenu]="knot"
  [appKnotContextMenuWithDetach]="withDetach"
  [appKnotContextMenuDisabled]="!withControls"
  (appKnotContextMenuPinned)="handlePin()"
  (appKnotContextMenuDelete)="handleDelete()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="{data: [knot], type: DragDataTypes.knot}"
  [appDraggablePlaceholder]="dragPlaceholder"
  [appDraggableContainerAdjustFit]="true"
  [appDraggableContainerStyles]="{'border-radius': '20px', 'overflow': 'hidden'}"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <mat-icon
    *ngIf="knot?.pinned"
    class="knot-pin-icon"
    svgIcon="sapphire-pin"
  />

  <mat-icon
    *ngIf="knot?.source !== 'user' && !knot?.pinned"
    class="knot-pin-icon"
    svgIcon="sapphire-search"
  />

  <div class="knot-name">{{ knot?.name }}</div>

  <mat-icon
    *ngIf="withRemove"
    class="knot-remove-icon"
    svgIcon="sapphire-close-small"
    (click)="onRemove.emit()"
  />

  <div class="knot-debug" [ngSwitch]="debug">
    <div *ngSwitchCase="'frequency'">{{ knot?.frequency }}</div>
    <div *ngSwitchCase="'recency'">{{ knot?.recency | date : 'short' }}</div>
    <div *ngSwitchCase="'createdAt'">{{ knot?.createdAt | date : 'short' }}</div>
    <div *ngSwitchCase="'entityType'">{{ knot?.entityType }}</div>
    <div *ngSwitchCase="'source'">{{ knot?.source }}</div>
  </div>
</div>

<ng-template #dragPlaceholder>
  <app-knot [knot]="knot" [dragEnabled]="false" />
</ng-template>
