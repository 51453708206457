<div
  *ngIf="project"
  class="project"
  [class.no-actions]="!withActions"
  [class.short-view]="viewType === 'short'"
  [class.pinned]="project.pinned"
  [class.with-description]="project.description"

  [appProjectContextMenu]="project"
  [appProjectContextMenuDisabled]="!withContextMenu"
  (appProjectContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [project], type: 'project'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="project-body">
    <div class="project-body-heading">
      <div class="project-body-heading-title">
        {{ project.title }}
      </div>
    </div>

    <app-date-time
      class="project-body-date-time"
      [fromDate]="project.fromTime"
      [fromTime]="project.fromTime"
      [toDate]="project.toTime"
      [toTime]="project.toTime"
    />

    <div class="project-body-description" *ngIf="project.description && viewType !== 'short'">
      {{project.description}}
    </div>

    <app-link-info-bar
      *ngIf="viewType !== 'short'"
      class="project-body-info-bar"
      [stitch]="project"
    />
  </div>

  <mat-icon
    class="project-pin-icon"
    [class.active]="project.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  />

  <mat-icon
    class="project-flag-icon"
    [class.active]="project.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  />

  <mat-icon class="project-marker" svgIcon="project" />
  <mat-icon class="project-attachments-icon" *ngIf="project.files?.length" svgIcon="attachment" />

  <div
    class="project-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="snooze"
      matTooltip="Coming soon"  
    />
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    />
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="archive"
      [class.active]="project.archived"
      [matTooltip]="project?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    />
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="trash"
      [class.active]="project.deleted"
      [matTooltip]="project?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div
    class="project-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="trash"
      [class.active]="project.deleted"
      [matTooltip]="project?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div class="project-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="project-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    />
  </div>

  <div class="drop-zone"></div>
</div>

<div
  class="project placeholder"
  *ngIf="!project"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-project
    [project]="project"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  />
</ng-template>
