// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RRule } from 'rrule';

// Utils
import { getDayOccurrenceOfMonth, isLastWeekdayOfMonth } from '@modules/common/utils/date';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { Subject, of } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

const daysOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const daysOfWeek = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];

@Component({
  selector: 'app-recurrence-dropdown',
  templateUrl: './recurrence-dropdown.component.html',
  styleUrls: ['./recurrence-dropdown.component.less'],
  standalone: false,
})
export class RecurrenceDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: FormControl<string>;
  @Input() referenceDate: FormControl<Date>;
  @Input() appearance = 'sapphire';

  @Output() onCustom = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<string>();

  public options?: DropdownSelectItem<RRule>[] = [];

  private alive = new Subject<void>();
  private referenceDateChanged = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.referenceDateChanged
      .pipe(
        switchMap(() => this.referenceDate?.valueChanges || of(null)),
        startWith(this.referenceDate.value),
        map((value) => value || new Date()),
        takeUntil(this.alive),
      )
      .subscribe((reference) => {
        const dayOccurence = getDayOccurrenceOfMonth(reference);
        const isLastWeekday = isLastWeekdayOfMonth(reference);

        const monthlyOnThisDayRule = new RRule({
          freq: RRule.MONTHLY,
          dtstart: reference,
          byweekday: [daysOfWeek[reference.getDay()].nth(isLastWeekday ? -1 : dayOccurence)],
        });

        const annuallyRule = new RRule({
          freq: RRule.YEARLY,
          dtstart: reference,
          bymonth: reference.getMonth() + 1,
          bymonthday: reference.getDate(),
        });

        const everyWeekdayRule = new RRule({
          freq: RRule.WEEKLY,
          dtstart: reference,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
        });

        const options: DropdownSelectItem<RRule>[] = [
          { title: 'Does Not Repeat', source: null },
          {
            title: 'Daily',
            source: new RRule({ freq: RRule.DAILY, dtstart: reference }),
          },
          {
            title: `Weekly On ${daysOfWeekNames[reference.getDay()]}`,
            source: new RRule({ freq: RRule.WEEKLY, dtstart: reference, byweekday: [daysOfWeek[reference.getDay()]] }),
          },
          {
            title: monthlyOnThisDayRule.toText(),
            source: monthlyOnThisDayRule,
          },
          {
            title: annuallyRule.toText(),
            source: annuallyRule,
          },
          {
            title: 'Every Weekday (Monday to Friday)',
            source: everyWeekdayRule,
          },
        ];

        this.options = options.map(({ title, source }) => ({ title, source, value: source?.toString() || null }));
      });

    this.referenceDateChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('referenceDate' in changes) {
      this.referenceDateChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(option: DropdownSelectItem<RRule>) {
    this.control.setValue(option.value);
    this.control.markAsDirty();

    this.onSelect.emit(option.value);
  }
}
