// Common
import { ComponentRef, Directive, Input, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { SpacesContextMenuComponent } from '../components/spaces-context-menu/spaces-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spacesContextMenu]',
  standalone: false,
})
export class SpacesContextMenuDirective extends ContextMenuDirective {
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  @Output() spacesContextMenuOpened = this.contextMenuOpened;

  /**
   * Context Menu Interface
   */

  registerInstance(_componentRef: ComponentRef<SpacesContextMenuComponent>) {
    // nothing to do
  }

  registerComponent() {
    return SpacesContextMenuComponent;
  }
}
