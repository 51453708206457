// Common
import { Component, EventEmitter, Injector, Output } from '@angular/core';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

// Rx
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-message-folder',
  templateUrl: './message-folder.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './message-folder.component.less'],
  standalone: false,
})
export class MessageFolderComponent extends BaseStitchComponent<MessageFolder> {
  @Output() openMessage = new EventEmitter<Message>();

  moveDragDataTypes = [DragDataTypes.message, DragDataTypes.messageFolder];
  dragDataType = DragDataTypes.messageFolder;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.messageFolder &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type);

  constructor(
    injector: Injector,
    protected foldersService: MessageFoldersService,
    private messagesService: MessagesService,
  ) {
    super(injector, foldersService);
  }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    (() => {
      if (dragData.type === DragDataTypes.message) {
        return this.messagesService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { folderId: this.item.id },
          { message },
        );
      } else if (dragData.type === DragDataTypes.messageFolder) {
        return this.foldersService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { parentId: this.item.id },
          { message },
        );
      }
    })()
      .pipe(take(1), takeUntil(this.alive))
      .subscribe();
  }

  get folder() {
    return this.item;
  }
}
