import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testAllElements',
  standalone: false,
})
export class TestAllElementsPipe implements PipeTransform {
  transform(items: object[], values: unknown[], key?: string): boolean {
    if (!items?.length) {
      return false;
    }

    return values.some((value) => items.every((item) => (key ? item[key] : item) === value));
  }
}
