<div
  class="toast-message"
  [class.waiting]="actionExecuted"
>
  <mat-icon class="toast-icon" *ngIf="icon" [svgIcon]="icon" />

  <div class="toast-text">
    {{ message }}
    {{ countdown && '(' + counter + ')' }}
  </div>

  <ng-container *ngFor="let currentAction of actions">
    <div class="toast-action-button" (click)="action(currentAction, $event)">{{ currentAction.text }}</div>
  </ng-container>
  <mat-icon class="toast-close-icon" svgIcon="close" (click)="remove()" />
</div>
