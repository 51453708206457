// Common
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  Injectable,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { slideAnimation } from '@modules/calendar-app/animations/slide-animation';

// RX
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  DateFormatterParams,
} from 'angular-calendar';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Pipes
import { SameWeekPipe } from '@modules/utils/pipes/datetime/same-week.pipe';

@Injectable()
class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'hh:mm a', locale);
  }

  public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'd', locale);
  }
}

@Component({
  selector: 'stch-calendar-week',
  styleUrls: ['./calendar-week.component.less'],
  templateUrl: './calendar-week.component.html',
  animations: [slideAnimation],
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }],
  standalone: false,
})
export class CalendarWeekComponent implements OnInit, OnChanges, OnDestroy {
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];
  @Input() selectedDate: Date;
  @Input() excludeDays: number[];

  @Output() dateClicked = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked = new EventEmitter<Date>();
  @Output() dayNumberClicked = new EventEmitter<Date>();
  @Output() eventDropped = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents = new EventEmitter<Date>();

  public refresh = new Subject<void>();
  public displayDate: Date;
  public timezoneOffset: string;
  public allDayCollapsed = true;
  public allDayExtraCount = 0;
  public displayEvents: CalendarEvent[] = [];

  private alive = new Subject<void>();

  @ViewChild('currentTimeMarker') currentTimeMarker: ElementRef<HTMLElement>;

  constructor(private sameWeek: SameWeekPipe) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.getCurrentTimezone();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('events' in changes) {
      this.buildDisplayEvents();
    }

    if (
      'viewDate' in changes &&
      this.sameWeek.transform(changes.viewDate.currentValue, changes.viewDate.previousValue)
    ) {
      this.displayDate = this.viewDate;

      timer(1000)
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          this.currentTimeMarker?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        });
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  collapseAllDay() {
    this.allDayCollapsed = !this.allDayCollapsed;
    this.buildDisplayEvents();
  }

  private buildDisplayEvents() {
    const allDayEvents = this.events.filter(({ allDay }) => allDay);
    const regularEvents = this.events.filter(({ allDay }) => !allDay);

    const allDayEventsSliced = this.allDayCollapsed
      ? allDayEvents.slice(0, 3).map((event) => ({
          ...event,
          resizable: undefined,
          draggable: false,
        }))
      : [];

    this.displayEvents = [...regularEvents, ...(this.allDayCollapsed ? allDayEventsSliced : allDayEvents)];

    this.allDayExtraCount = Math.max(0, allDayEvents.length - 3);
  }

  private getCurrentTimezone() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetSign = offsetMinutes < 0 ? '+' : '-';

    this.timezoneOffset = `${offsetSign} ${this.padZero(offsetHours)}:${this.padZero(offsetMinutes % 60)}`;
  }

  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  clickHourSegment(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.dateClicked.emit({ date, originRef: new ElementRef(origin), event });
  }

  handleDrop(dragData: DragData, newStart: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart,
      newEnd: new Date(
        newStart.getFullYear(),
        newStart.getMonth(),
        newStart.getDate(),
        newStart.getHours() + 1,
        newStart.getMinutes(),
      ),
      originRef: new ElementRef(origin),
    });
  }

  handleMove({ event, newStart, newEnd }: CalendarEventTimesChangedEvent) {
    this.eventDropped.emit({
      dragData: {
        type: DragDataTypes.event,
        data: [event.meta],
      },
      newStart,
      newEnd,
    });

    this.displayEvents = this.displayEvents.map((iEvent) =>
      iEvent === event ? { ...event, start: newStart, end: newEnd } : iEvent,
    );
  }
}
