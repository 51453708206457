// Common
import {
  Component,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

// Types
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Connection } from '@modules/connections/types/connection';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Knot } from '@modules/knots/types/knot';
import { StateKey } from '@modules/settings/types/state-key';
import { Team } from '@modules/settings/types/team';
import { Tag } from '@modules/tags/types/tag';

// Services
import { AlertService } from '@modules/alert/services/alert.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { AlertButtonConfig } from '@modules/alert/types/config';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  template: '',
  standalone: false,
})
export abstract class BaseSidebarContainersTreeComponent<
    C extends Stitch,
    I extends Stitch,
    Filters extends StitchFilters<C>,
    VirtualFolder,
  >
  implements OnInit, OnChanges, OnDestroy
{
  @Input() filters: Filters;
  @Input() team: Team;
  @Input() itemCreateFormTemplate: TemplateRef<unknown>;

  public items: C[] = [];
  public selectedItemsIds: string[] = [];
  public abstract application: Application;
  public abstract treeStateKey: StateKey;
  public abstract dndPredicate: (stitchItem: Stitch) => (dragData: DragData) => boolean;

  protected abstract selfDragDataTypes: DragDataTypes[];
  protected alive = new Subject<void>();
  protected selectedSynced = false;
  protected readonly injector: Injector = inject(Injector);
  protected readonly popoverService: PopoverService = inject(PopoverService);

  private readonly filtersChanged = new BehaviorSubject<void>(null);
  private readonly stitchService: StitchService = inject(StitchService);
  private readonly searchService: AdvancedSearchService = inject(AdvancedSearchService);
  private readonly selectableService: SelectableService = inject(SelectableService);
  private readonly alertService: AlertService = inject(AlertService);
  private readonly modalService: ModalService = inject(ModalService);

  constructor(
    protected itemsService: BaseStitchService<C, Filters>,
    protected stateService: BaseAppStateService<C, I, VirtualFolder>,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectableService
      .getSelectedItems()
      .pipe(
        filter(() => this.selectedSynced),
        takeUntil(this.alive),
      )
      .subscribe((selectedItems: string[]) => {
        const state = this.searchService.getStateSync();
        state[this.application].containersIds = selectedItems;
        this.searchService.setState(state);
      });

    combineLatest([
      this.searchService.getState(),
      this.filtersChanged.pipe(
        filter(() => !!this.filters),
        switchMap(() =>
          this.itemsService.findAll({
            ...this.filters,
            deleted: false,
            archived: false,
            snoozedOnTop: false,
            followedOnTop: false,
          }),
        ),
      ),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(
        ([
          {
            [this.application]: { containersIds },
          },
          items,
        ]) => {
          if (this.selectedSynced) {
            this.selectedItemsIds = containersIds;
          } else {
            this.selectedItemsIds = items.reduce((arr, item) => {
              if (containersIds.includes(item.id)) {
                arr.push(item.id);
              }
              return arr;
            }, []);

            this.selectedSynced = true;
          }

          const selectedItems = this.selectableService.getSelectedItemsSync();
          const newSelected = selectedItems.filter((item) =>
            this.selectedItemsIds.includes(item.identifier ?? item.data),
          );
          if (newSelected.length !== selectedItems.length) {
            this.selectableService.setSelectedItems(newSelected);
          }

          this.items = items;
          this.onItemsReceived();
        },
      );

    this.itemsService
      .getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.filtersChanged.next();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes) {
      this.filtersChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  protected onItemsReceived(): void {
    // do nothing
  }

  abstract handleDuplicate(dragData: DragData, item: Stitch);

  abstract handleMove(dragData: DragData, item: Stitch);

  handleClick(item: C) {
    this.stateService.setMainView(item);
  }

  handleDblClick(item: C) {
    this.modalService.openFullForm(item, this.injector);
  }

  public handleStitch(dragData: DragData, item: Stitch) {
    this.stitchService.linkDragData(item, dragData);
  }

  protected getDndModalButtons(dragData: DragData, item: Stitch): AlertButtonConfig[] {
    return [
      {
        title: 'Cancel',
        close: true,
      },
      {
        title: 'Copy',
        click: () => this.handleDuplicate(dragData, item),
        close: true,
      },
      {
        title: 'Move',
        click: () => {
          this.handleMove(dragData, item);
        },
        close: true,
      },
      {
        title: 'Stitch',
        click: () => {
          this.handleStitch(dragData, item);
        },
        close: true,
      },
    ];
  }

  public dndDrop(dragData: DragData, item: Stitch) {
    if (this.selfDragDataTypes.includes(dragData.type)) {
      this.alertService.show({
        title: 'Move or Stitch?',
        body: 'Move or Stitch?',
        rightButtons: this.getDndModalButtons(dragData, item),
      });
    } else {
      this.handleStitch(dragData, item);
    }
  }

  protected getIds(items: (Stitch | Tag | Knot | Connection)[]): string[] {
    return items.map(({ id }) => id);
  }
}
