<div
  #droppableArea
  class="file-input"
  [class.hide]="uploads.length && !multiple"
  [class.dragover]="dragOver > 0"
  [class.error]="withErrors && !inputFormControl?.valid"
  [ngClass]="size"
  (drop)="handleDrop($event)"
  (dragenter)="dragOver = dragOver + 1;"
  (dragleave)="dragOver = dragOver - 1;"
>
  <app-button
    label="Browse files"
    appearance="sapphire-text-accent"
    [size]="size"
    (click)="fileUploadInput.click()"
  /><span> / drop your files here</span>

  <input
    #fileUploadInput
    type="file"
    multiple
    id="files-input"
    (change)="handleChange($event)"
  >
</div>

<div
  class="uploads"
  [class.indent]="uploads.length && multiple"
>
  <stch-upload
    *ngFor="let upload of uploads | filterBy : 'inline' : false; let index = index"
    class="uploads-item"
    [upload]="upload"
    [uploadInProgress]="uploadsInProgress > 0"
    [size]="uploadCardSize"
    (retry)="retry(index)"
    (delete)="delete(index)"
  />
</div>

<app-input-errors
  *ngIf="withErrors"
  [inputFormControl]="inputFormControl"
  [validationMessages]="validationMessages"
/>
