// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { FilesService } from '@modules/files/services/files.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesAppStateService } from '@modules/files/services/state.service';

// Types
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { File } from '@modules/files/types/file';
import { Folder } from '@modules/files/types/folder';
import { FoldersFilters } from '@modules/files/types/folders-filters';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// Rx
import { take } from 'rxjs/operators';

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';

@Component({
  selector: 'app-side-bar-folders-tree',
  templateUrl: './side-bar-folders-tree.component.html',
  styleUrls: ['./side-bar-folders-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService],
  standalone: false,
})
export class SideBarFoldersTreeComponent
  extends BaseSidebarContainersTreeComponent<Folder, File, FoldersFilters, VirtualFolder>
  implements OnInit
{
  selfDragDataTypes = [DragDataTypes.file, DragDataTypes.folder];
  application = Application.files;
  treeStateKey = StateKey.filesSidebarFoldersTree;

  public dndPredicate =
    (stitchItem: Stitch) =>
    (dragData: DragData): boolean =>
      stitchItem &&
      !(
        dragData.type === DragDataTypes.folder &&
        dragData.data.length === 1 &&
        dragData.data?.[0]?.id === stitchItem?.id
      ) &&
      dragDataTypeAllowed(dragData.type);

  constructor(
    private foldersService: FoldersService,
    private filesService: FilesService,
    filesAppStateService: FilesAppStateService,
  ) {
    super(foldersService, filesAppStateService);
  }

  /**
   * Actions
   */

  public handleMove(dragData: DragData, item: Folder) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.file) {
      this.filesService.bunchUpdate({ ids: this.getIds(dragData.data) }, { folderId: item.id }, { message });
    } else if (dragData.type === DragDataTypes.folder) {
      this.foldersService.bunchUpdate({ ids: this.getIds(dragData.data) }, { parentId: item.id }, { message });
    }
  }

  public handleDuplicate(dragData: DragData, folder: Folder) {
    if (dragData.type === DragDataTypes.file) {
      this.filesService.duplicate(this.getIds(dragData.data), folder.id).pipe(take(1)).subscribe();
    } else if (dragData.type === DragDataTypes.folder) {
      this.foldersService.duplicate(this.getIds(dragData.data)).pipe(take(1)).subscribe();
    }
  }
}
