<app-context-menu-item
  *ngIf="!(['all_groups', 'deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Add New Contact"
>
  <app-contact-quick-form
    class="quick-form"
    [contact]="stitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  />
</app-context-menu-item>

<app-context-menu-item
  *ngIf="!(['all_contacts', 'deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Add New Group"
>
  <app-group-quick-form
    class="quick-form"
    [group]="containerStitchItem"
    [inline]="true"
    (close)="handleClose()"
    (more)="handleMore($event)"
  />
</app-context-menu-item>

<app-context-menu-divider
  *ngIf="!(['deleted', 'archived'] | includesBy: sidebarFilterKey)"
/>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'flagged'"
  title="Unflag all"
  [close]="true"
  (execute)="unFlagAll()"
/>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'followed'"
  title="Unfollow all"
  [close]="true"
  (execute)="unFollowAll()"
/>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'snoozed'"
  title="Unsnooze all"
  [close]="true"
  (execute)="unSnoozeAll()"
/>

<app-context-menu-divider
  *ngIf="(['flagged', 'followed', 'snoozed'] | includesBy: sidebarFilterKey)"
/>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'archived'"
  title="Delete all"
  [close]="true"
  (execute)="deleteAll()"
/>

<app-context-menu-item
  *ngIf="sidebarFilterKey === 'deleted'"
  title="Empty trash"
  [close]="true"
  (execute)="emptyTrash()"
/>

<app-context-menu-item
  *ngIf="!(['deleted', 'archived'] | includesBy: sidebarFilterKey)"
  title="Settings"
  [close]="true"
  (execute)="openSettings()"
/>
