import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { environment } from '@environment';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { MessageComponent } from '@modules/elements/components/message/message.component';
import { KnotSource } from '@modules/knots/types/knot-source';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { Message } from '@modules/messages/types/message';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messages2-list',
  templateUrl: './messages-list.component.html',
  styleUrl: './messages-list.component.less',
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class MessagesListComponent extends BaseStitchListComponent<Message> implements OnDestroy, OnChanges {
  @Input()
  public override filters: Partial<MessagesFilters> = {};
  @Input()
  public withTags = false;
  @Input()
  public withKnots = false;
  @Input()
  public withConnections = false;
  @Input()
  public knotsSource: KnotSource;
  @Input()
  public debug: 'score';
  @Input()
  public notFoundTemplate?: TemplateRef<void>;

  @Output()
  public readonly clickMessage = new EventEmitter<Message>();
  @Output()
  public readonly openMessage = new EventEmitter<Message>();
  @Output()
  public readonly doubleClickMessage = new EventEmitter<Message>();

  @ViewChildren(MessageComponent, { read: ElementRef<HTMLElement> })
  public override itemElements: QueryList<ElementRef<HTMLElement>>;

  public override items: StitchListItem<Message>[] = [];

  constructor(
    private messagesService: MessagesService,
    private messagesStateService: MailAppStateService,
    private linkedInfoService: LinkedInfoService,
    private injector: Injector,
  ) {
    super();

    merge(
      this.messagesStateService.getRefreshAll(),
      this.linkedInfoService.getRefresh(),
      this.messagesService.getRefresh(),
      interval(environment.messageFetchInterval),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadPage(this.page);
      });

    merge(this.messagesStateService.getRefreshAll(), this.messagesService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetSelected.next();
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes && !changes.filters.isFirstChange()) {
      this.resetItems();
    }
  }

  protected override getItems(offset: number, limit: number): Observable<{ items: Message[]; count: number }> {
    return this.messagesService.search(
      { ...this.filters, limit, offset },
      {
        withTags: this.withTags,
        withKnots: this.withKnots,
        withConnections: this.withConnections,
        knotsSource: this.knotsSource,
      },
    );
  }

  public handleDoubleClickItem(message?: Message) {
    if (message.draft) {
      this.messagesStateService.composeMessage({ message, injector: this.injector });
    } else {
      this.doubleClickMessage.emit(message);
    }
  }

  public handleClickItem(message: Message) {
    if (message.draft) {
      this.messagesStateService.composeMessage({ message, injector: this.injector });
    } else {
      this.clickMessage.emit(message);
    }
  }
}
