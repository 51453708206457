<div
  stchPopover
  [stchPopoverArrow]="true"
  [stchPopoverContent]="popoverTemplate"
  [stchPopoverInnerShadow]="true"
  stchPopoverPlacement="right"
  [stchPopoverDisabled]="!minimized"
  stchPopoverTrigger="click"
>
  <app-sidebar-item
    title="Calendars"
    icon="sapphire-calendars"
    [depth]="team ? 2 : 0"
    [expandable]="true"
    [expanded]="expanded | lsValue : application : team?.id"
    [minimized]="minimized"
    [active]="currentTeamId === team?.id && sidebarFilter === 'all_calendars'"
    [actions]="[{
      icon: 'sapphire-plus',
      template: calendarQuickFormTemplate,
      until: quickFormPopoverClose
    }]"
    (onExpand)="expanded.set($event, application, team?.id)"

    (appClick)="selectFilter.emit('all_calendars')"
    appSidebarContextMenu="all_calendars"
  >
    <app-context-menu-state-indicator
      appearance="sapphire"
      svgIcon="sapphire-filters"
      appCalendarsListContextMenu
    />
  </app-sidebar-item>
</div>

<div
  *ngIf="!minimized"
  class="collapseable-body"
  [@heightAnimation]="(expanded | lsValue : application : team?.id) ? 'show' : 'hide'"
>
  <ng-container *ngTemplateOutlet="contentTemplate" />
</div>

<ng-template #popoverTemplate>
  <div
    *ngIf="minimized"
    class="popover-body"
  >
    <ng-container *ngTemplateOutlet="contentTemplate" />
  </div>
</ng-template>

<ng-template #contentTemplate>
  <app-side-bar-calendars-tree [filters]="filters" [team]="team" [itemCreateFormTemplate]="calendarQuickFormTemplate" />
</ng-template>

<ng-template #calendarQuickFormTemplate let-parentId="parentId">
  <div class="quick-form-container">
    <app-calendar-quick-form
      [parentId]="parentId"
      [inline]="true"
      (close)="quickFormPopoverClose.next()"
      (more)="handleMore($event)"
    />
  </div>
</ng-template>
