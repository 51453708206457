<nav class="navbar">
  <div class="app">
    <mat-icon
      class="app-icon"
      [svgIcon]="app | appIcon"
    />
  </div>

  <app-dropdown-label
    appearance="amethyst"
    [appContextMenu]="app"
    (appContextMenuChange)="handleAppChange($event)"
    (appContextMenuOpened)="appContextMenuOpened = $event"
    [collapsed]="!appContextMenuOpened"
  >
    {{ app | appTitle }}
  </app-dropdown-label>

  <div class="filler"></div>

  <app-dropdown-label
    appearance="amethyst"
    spacesContextMenu
    (spacesContextMenuOpened)="spacesContextMenuOpened = $event"
    [collapsed]="!spacesContextMenuOpened"
  >
    {{ space?.title || 'Personal Space' }}
  </app-dropdown-label>

  <div class="filler"></div>

  <div class="navbar-buttons">
    <mat-icon
      class="navbar-buttons-item"
      svgIcon="button-nav-compose"
      (click)="compose()"
    />

    <mat-icon
      class="navbar-buttons-item"
      svgIcon="button-nav-add"
      stchPopover
      stchPopoverTrigger="click"
      stchPopoverPlacement="bottomLeft"
      [stchPopoverContent]="knotsPopover"
      [stchPopoverShowUntil]="popoverHide"
    />
  </div>

  <div class="filler"></div>

  <div *ngIf="!withSearch" class="navbar-form"></div>

  <app-search-input
    *ngIf="withSearch"
    class="navbar-form"
  />

  <div class="filler"></div>

  <div class="navbar-buttons-item notifications">
    <div
      *ngIf="notificationsCount > 0"
      class="notifications-badge"
    >
      {{ notificationsCount }}
    </div>

    <mat-icon
      svgIcon="button-nav-notification"
      stchPopover
      [stchPopoverArrow]="true"
      [stchPopoverContent]="notificationsTemplate"
      stchPopoverPlacement="bottom"
      [stchPopoverFallbackPlacements]="[]"
      stchPopoverTrigger="click"
      [stchPopoverShowUntil]="closeToken"
    />
  </div>
</nav>

<ng-template #knotsPopover>
  <app-context-menu-item
    title="New Email Folder"
    (execute)="newStitchItem(StitchType.messageFolder)"
  />
  <app-context-menu-item
    title="New Email"
    *ngIf="app !== Application.mail"
    (execute)="newStitchItem(StitchType.message)"
  />
  <app-context-menu-item
    title="New Calendar"
    (execute)="newStitchItem(StitchType.calendar)"
  />
  <app-context-menu-item
    title="New Event"
    *ngIf="app !== Application.calendar"
    (execute)="newStitchItem(StitchType.event)"
  />
  <app-context-menu-item
    title="New Project"
    (execute)="newStitchItem(StitchType.project)"
  />
  <app-context-menu-item
    title="New Task"
    *ngIf="app !== Application.tasking"
    (execute)="newStitchItem(StitchType.task)"
  />
  <app-context-menu-item
    title="New Notebook"
    (execute)="newStitchItem(StitchType.notebook)"
  />
  <app-context-menu-item
    title="New Note"
    *ngIf="app !== Application.notes"
    (execute)="newStitchItem(StitchType.note)"
  />
  <app-context-menu-item
    title="New Group"
    (execute)="newStitchItem(StitchType.group)"
  />
  <app-context-menu-item
    title="New Contact"
    *ngIf="app !== Application.contacts"
    (execute)="newStitchItem(StitchType.contact)"
  />
  <app-context-menu-item
    title="New Folder"
    (execute)="newStitchItem(StitchType.folder)"
  />
  <app-context-menu-item
    title="New File"
    *ngIf="app !== Application.contacts"
    (execute)="newStitchItem(StitchType.file)"
  />
</ng-template>

<ng-template #notificationsTemplate>
  <app-notifications-list />
</ng-template>
