import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sameArray',
  standalone: false,
})
export class SameArrayPipe implements PipeTransform {
  transform(array1: (string | number)[], array2: (string | number)[]): boolean {
    if (!array1 || !array2) {
      return false;
    }

    if (array1.length !== array2.length) {
      return false;
    }

    return JSON.stringify([...array1].sort()) === JSON.stringify([...array2].sort());
  }
}
