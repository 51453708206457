import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord',
  standalone: false,
})
export class FirstWordPipe implements PipeTransform {
  transform(value: string): string {
    return (value || '').split('-')[0];
  }
}
