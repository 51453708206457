// Common
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// RX
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { AccountService } from '@modules/account/services/account.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { Space } from '../types/space';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { SpacesFilters } from '../types/spaces-filters';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { Account } from '@modules/account/types/account';

// Env
import { environment } from '@environment';

// Injection tokens
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';
import { SpaceParticipant } from '../types/space-participant';

@Injectable()
export class SpacesService extends BaseRestService<Space, SpacesFilters> {
  private url = environment.baseUrl + '/api/account/spaces';
  private spacesCache: { [key: string]: Space } = {};

  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
    private accountService: AccountService,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {
    super(toaster);
  }

  search(
    filters: Partial<SpacesFilters>,
    { account }: { account?: Account } = { account: null },
  ): Observable<BaseSearchResponse<Space>> {
    const requestParams = { params: new SpacesFilters(filters || {}).format() };

    return this.http.get<{ count: number; items: Space[]; total: number }>(this.url, requestParams).pipe(
      map(({ count, items, total }) => ({
        count,
        items: (items || []).map((item) => new Space(item, account)),
        total,
      })),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  getOne(id: string): Observable<Space> {
    return this.http.get<{ space: object }>(`${environment.baseUrl}/api/account/spaces/${id}`).pipe(
      map(({ space }) => new Space(space)),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  getCurrentSpace(): Observable<Space> {
    return this.getRefreshRequired().pipe(
      switchMap(() => combineLatest([this.spaceId, this.accountService.getAccount()])),
      switchMap(([id, account]) => {
        if (!id) {
          return of(new Space({ participants: [new SpaceParticipant(account)] }));
        }
        if (this.spacesCache[id]) {
          return of(this.spacesCache[id]);
        }
        return this.getOne(id).pipe(tap((space) => (this.spacesCache[id] = space)));
      }),
    );
  }

  create(instance: Space, { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }): Observable<Space> {
    return this.http
      .post<{ success: boolean; item: Space }>(`${environment.baseUrl}/api/account/spaces`, instance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Space created.` });
        }),
        map(({ item }) => new Space(item)),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  update(instance: Space, { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }): Observable<Space> {
    return this.http
      .put<{
        success: boolean;
        item: Space;
      }>(environment.baseUrl + '/api/account/spaces/' + instance.id, instance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            toast && this.toaster.show({ text: message || 'Space updated' });
          }
        }),
        map(({ item }) => new Space(item)),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  upsert(instance: Space, config?: FeedbackConfig): Observable<Space> {
    return instance.id ? this.update(instance, config) : this.create(instance, config);
  }

  @warmUpObservable
  delete(instance: Space, { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(`${environment.baseUrl}/api/account/spaces/${instance.id}`).pipe(
      map(({ success }) => success),
      tap((success) => {
        if (!success) {
          return;
        }

        emit && this.forceRefresh();

        toast && this.toaster.show({ text: message || 'Space successfully deleted' });
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }
}
