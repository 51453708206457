// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-link-info-notebook',
  templateUrl: './link-info-notebook.component.html',
  styleUrls: ['./link-info-notebook.component.less'],
  standalone: false,
})
export class LinkInfoNotebookComponent {
  // Inputs
  @Input() notebook: Notebook;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.notebook &&
    !(
      dragData.type === DragDataTypes.notebook &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.notebook.id
    ) &&
    dragDataTypeAllowed(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private notebooksService: NotebooksService,
    private stitchService: StitchService,
  ) {}

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.notebooksService
      .flag({ ids: [this.notebook.id] }, !this.notebook.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.notebook.flagged = !this.notebook.flagged));
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.notebooksService
      .pin({ ids: [this.notebook.id] }, !this.notebook.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.notebook.pinned = !this.notebook.pinned));
  }

  archive() {
    this.notebooksService
      .archive({ ids: [this.notebook.id] }, !this.notebook.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.notebook.archived = !this.notebook.archived));
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.notebook.deleted
        ? this.notebooksService.deletePermanently({ ids: [this.notebook.id] })
        : this.notebooksService.delete({ ids: [this.notebook.id] }, true);
    }
  }

  dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.notebook, dragData);
  }
}
