import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  pure: true,
  standalone: false,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date): string {
    if (!value) {
      return null;
    }

    const seconds = Math.floor((Date.now() - value.getTime()) / 1000);

    const intervals: { [key: string]: number } = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter;

    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);

      if (counter > 0) {
        if (counter === 1) {
          return counter + ' ' + i + ' ago'; // singular (1 day ago)
        } else {
          return counter + ' ' + i + 's ago'; // plural (2 days ago)
        }
      }
    }
  }
}
