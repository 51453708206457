// Common
import { Component, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import CloseToken from '@modules/modal/types/modal-close.injection-token';
import { StripeCardElement } from '@stripe/stripe-js';

// Services
import { StripeService } from '@modules/settings/services/stripe.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.less'],
  standalone: false,
})
export class StripeCardComponent implements OnDestroy, AfterViewInit {
  private alive = new Subject<void>();
  private card: StripeCardElement;
  public loading = false;

  constructor(
    @Inject(CloseToken) private closeToken,
    private stripeService: StripeService,
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit(): void {
    this.stripeService
      .initializeStripeElements()
      .pipe(takeUntil(this.alive))
      .subscribe((elements) => {
        this.card = elements.create('card', {
          style: {
            base: {
              iconColor: '#c4f0ff',
              color: 'black',
              fontWeight: '500',
              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              fontSize: '26px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                color: '#fce883',
              },
              '::placeholder': {
                color: '#87BBFD',
              },
            },
            invalid: {
              iconColor: '#FFC7EE',
              color: '#FFC7EE',
            },
          },
        });

        this.card.mount('#card-elements');
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.loading = true;

    this.stripeService
      .attachCard(this.card)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loading = false;
        this.closeToken.next();
      });
  }
}
