// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Components
import { FallingLettersComponent } from './components/falling-letters/falling-letters.component';

// Modules
import { OverlayModule } from '@angular/cdk/overlay';

// Directives
import { BundledProviderDirective } from './directives/bundled-provider.directive';
import { BundledConsumerDirective } from './directives/bundled-consumer.directive';

// Services
import { BundledInputsService } from './services/bundled-inputs.service';

@NgModule({
  exports: [BundledProviderDirective, BundledConsumerDirective],
  declarations: [BundledProviderDirective, BundledConsumerDirective, FallingLettersComponent],
  imports: [AngularCommonModule, OverlayModule],
  providers: [BundledInputsService],
})
export class BundledInputsModule {}
