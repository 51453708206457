import { ComponentRef, Directive, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { startWith, takeUntil } from 'rxjs/operators';
import { NotebooksListContextMenuComponent } from '../components/notebooks-list-context-menu/notebooks-list-context-menu.component';
import { NotebookListStateService } from '../services/notebook-list-state.service';

@Directive({
  selector: '[appNotebooksListContextMenu]',
  standalone: false,
})
export class NotebooksListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {
  @Input()
  public appNotebooksListContextMenuWithAdvancedSearch: boolean;
  @Input()
  public appNotebooksListContextMenuWithScore: boolean;
  @Input()
  public stchPopoverTrigger: PopoverTrigger = 'click';
  @Input()
  public stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input()
  public stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  private readonly notebookListStateService = inject(NotebookListStateService);

  public registerInstance(componentRef: ComponentRef<NotebooksListContextMenuComponent>) {
    componentRef.instance.state = this.notebookListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appNotebooksListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appNotebooksListContextMenuWithScore;
  }

  public registerComponent() {
    return NotebooksListContextMenuComponent;
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.notebookListStateService.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.isDefaultStateObservable?.next(this.notebookListStateService.state.isDefault());
      });

    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.notebookListStateService.state.isDefault()) {
        this.notebookListStateService.state.resetToDefault();
      }
    });
  }
}
