import { Component, inject, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { NotebookListStateService } from '@modules/notes/services/notebook-list-state.service';
import { NotesListStateService } from '@modules/notes/services/notes-list-state.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateKey } from '@modules/settings/types/state-key';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notes-panel',
  templateUrl: './notes-panel.component.html',
  styleUrls: ['./notes-panel.component.less'],
  standalone: false,
  providers: [
    NotesListStateService.providers({
      stateKey: StateKey.notesDPListState,
    }),
    NotebookListStateService.providers({
      stateKey: StateKey.notebooksDPListState,
    }),
  ],
})
export class NotesPanelComponent implements OnInit, OnDestroy {
  public notebooksFilters: NotebooksFilters;
  public notesFilters: NotesFilters;
  public contextMenuOpened: boolean;
  public selectedFilter: VirtualFolder;

  private alive = new Subject<void>();

  @Input() searchTemplate: TemplateRef<any>;

  private readonly notesListStateService = inject(NotesListStateService, { self: true });
  private readonly notebookListStateService = inject(NotebookListStateService, { self: true });

  constructor(
    private dpService: DynamicPanelService,
    private modalService: ModalService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: NotesAppStateService,
  ) {}

  public ngOnInit() {
    combineLatest([
      this.notebookListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.notebooksFilters = new NotebooksFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.notesListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.notesFilters = new NotesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  public ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewNote(note = new Note()) {
    this.dpService.setFormItem(note);
  }

  handleNewNotebook(notebook = new Notebook()) {
    this.dpService.setFormItem(notebook);
  }

  openNoteInModal(note: Note) {
    this.modalService.openFullForm(note, this.injector);
  }

  openNote(note: Note) {
    this.dpService.setFormItem(note);
  }

  openNotebook(notebook: Notebook) {
    this.dpService.setFormItem(notebook);
  }

  selectFilter(folder: VirtualFolder): void {
    this.selectedFilter = folder;
    this.stateService.setVirtualFolder(folder);
  }
}
