import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Settings {
  formBuilder = injector.get(FormBuilder);

  notebooksPostponeNotificationsEnabled: boolean;
  notesPostponeNotificationsEnabled: boolean;
  notificationsEnabled?: boolean;

  constructor(data: Partial<Settings> = {}) {
    this.notebooksPostponeNotificationsEnabled = data.notebooksPostponeNotificationsEnabled;
    this.notesPostponeNotificationsEnabled = data.notesPostponeNotificationsEnabled;
    this.notificationsEnabled = data.notificationsEnabled;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      notebooksPostponeNotificationsEnabled: formValues.notebooksPostponeNotificationsEnabled,
      notesPostponeNotificationsEnabled: formValues.notesPostponeNotificationsEnabled,
      notificationsEnabled: formValues.notificationsEnabled,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      notebooksPostponeNotificationsEnabled: [this.notebooksPostponeNotificationsEnabled],
      notesPostponeNotificationsEnabled: [this.notesPostponeNotificationsEnabled],
      notificationsEnabled: [this.notificationsEnabled],
    });
  }

  public asPayloadJSON() {
    return {
      notebooksPostponeNotificationsEnabled: this.notebooksPostponeNotificationsEnabled,
      notesPostponeNotificationsEnabled: this.notesPostponeNotificationsEnabled,
      notificationsEnabled: this.notificationsEnabled,
    };
  }
}
