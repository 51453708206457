import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ListStateService } from '@modules/common/components/list/list-state.service';
import { SortBy } from '@modules/common/types/SortBy';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import { StateKey } from '@modules/settings/types/state-key';
import { Calendar } from '../types/calendar';
import { CalendarsListSortField, CalendarsListState } from '../types/calendars-list-state';
import { EventsListState } from '../types/events-list-state';

export interface CalendarsListStateServiceConfig {
  stateKey?: StateKey;
  withAdvancedSearch?: boolean;
  defaultSort?: SortBy<CalendarsListSortField>;
}

export const CalendarsListStateServiceConfigToken = new InjectionToken<CalendarsListStateServiceConfig>(
  'Configuration object for calendar list state service',
);

@Injectable()
export class CalendarsListStateService extends ListStateService<Calendar, CalendarsListState> {
  public override readonly state: CalendarsListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(CalendarsListStateServiceConfigToken) config: CalendarsListStateServiceConfig,
  ) {
    super();

    this.state = new CalendarsListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch,
      defaultSort: config.defaultSort,
    });
  }

  static providers(config: CalendarsListStateServiceConfig = {}): any[] {
    return super.prepareProviders(CalendarsListStateServiceConfigToken, config);
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.eventsListState]?: EventsListState;
  }
}
