// Common
import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Modules
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';

import { RouterModule } from '@angular/router';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Directives
import { AppContextMenuDirective } from './directives/app-context-menu.directive';
import { NestedForDirective } from './directives/nested-for.directive';
import { RerenderDirective } from './directives/rerender.directive';
import { SelectionTooltipDirective } from './directives/selection-tooltip.directive';
import { ShareContextMenuDirective } from './directives/share.directive';
import { SpaceContextDirective } from './directives/space-context.directive';
import { SpaceRerenderDirective } from './directives/space-rerender.directive';
import { SpacesContextMenuDirective } from './directives/spaces-context-menu.directive';

// Components
import { AppContextMenuComponent } from './components/app-context-menu/app-context-menu.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CajaComponent } from './components/caja/caja.component';
import { DropdownLabelComponent } from './components/dropdown-label/dropdown-label.component';
import { InfinityScrollListComponent } from './components/infinity-scroll-list/infinity-scroll-list.component';
import { JSONViewerComponent } from './components/json-viewer/json-viewer.component';
import { ListItemConnectionsComponent } from './components/list-item-connections/list-item-connections.component';
import { ListItemKnotsComponent } from './components/list-item-knots/list-item-knots.component';
import { ListItemTagsComponent } from './components/list-item-tags/list-item-tags.component';
import { MapPreviewComponent } from '@modules/common/components/map-preview/map-preview.component';
import { MembersAvatarsComponent } from './components/members-avatars/members-avatars.component';
import { MembersPopoverComponent } from './components/members-popover/members-popover.component';
import { NestedItemsComponent } from './components/nested-items/nested-items.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PostponeContextMenuComponent } from '@modules/common/components/postpone-context-menu/postpone-context-menu.component';
import { ResizableComponent } from './components/resizable/resizable.component';
import { SectionComponent } from './components/section/section.component';
import { ShareContextMenuComponent } from './components/share-context-menu/share-context-menu.component';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { SidebarItemsDividerComponent } from './components/sidebar-items-divider/sidebar-items-divider.component';
import { SpacesContextMenuComponent } from './components/spaces-context-menu/spaces-context-menu.component';
import { TableComponent } from './components/table/table.component';
import { TabsComponent } from './components/tabs/tabs.component';

// Services
import { BackgroundJobsService } from './services/background-jobs.service';
import { TemporalExpressionsService } from './services/temporal-expressions.service';

@NgModule({
  imports: [
    AngularCommonModule,
    BundledInputsModule,
    DragNDropModule,
    EditorModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    UtilsModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    SplitViewModule,
  ],
  declarations: [
    // Components
    AppContextMenuComponent,
    AvatarComponent,
    BadgeComponent,
    CajaComponent,
    DropdownLabelComponent,
    InfinityScrollListComponent,
    JSONViewerComponent,
    ListItemConnectionsComponent,
    ListItemKnotsComponent,
    ListItemTagsComponent,
    MapPreviewComponent,
    MembersAvatarsComponent,
    MembersPopoverComponent,
    NestedItemsComponent,
    PaginationComponent,
    PostponeContextMenuComponent,
    ResizableComponent,
    SectionComponent,
    ShareContextMenuComponent,
    SidebarItemComponent,
    SidebarItemsDividerComponent,
    SpacesContextMenuComponent,
    TableComponent,
    TabsComponent,

    // Directives
    AppContextMenuDirective,
    NestedForDirective,
    RerenderDirective,
    SelectionTooltipDirective,
    ShareContextMenuDirective,
    SpaceContextDirective,
    SpaceRerenderDirective,
    SpacesContextMenuDirective,
  ],
  exports: [
    // Components
    AppContextMenuComponent,
    AvatarComponent,
    BadgeComponent,
    CajaComponent,
    DropdownLabelComponent,
    JSONViewerComponent,
    ListItemConnectionsComponent,
    ListItemKnotsComponent,
    ListItemTagsComponent,
    MapPreviewComponent,
    MembersAvatarsComponent,
    MembersPopoverComponent,
    NestedItemsComponent,
    PaginationComponent,
    PostponeContextMenuComponent,
    ResizableComponent,
    SectionComponent,
    ShareContextMenuComponent,
    SidebarItemComponent,
    SidebarItemsDividerComponent,
    SpacesContextMenuComponent,
    TableComponent,
    TabsComponent,

    // Directives
    AppContextMenuDirective,
    NestedForDirective,
    RerenderDirective,
    SelectionTooltipDirective,
    ShareContextMenuDirective,
    SpaceContextDirective,
    SpaceRerenderDirective,
    SpacesContextMenuDirective,
  ],
  providers: [BackgroundJobsService, TemporalExpressionsService],
})
export class CommonModule {}
