// Common
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Utils
import { groupByIds } from '@modules/common/utils/stitch';

// RxJS
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { KnotFilters } from '../types/knot-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Knot } from '../types/knot';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { KnotsNeighboursFilters } from '../types/neighbours-filters';

@Injectable()
export class KnotsService extends BaseRestService<Knot, KnotFilters> {
  constructor(
    protected http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  /**
   * Methods
   */

  search(filters: Partial<KnotFilters>): Observable<BaseSearchResponse<Knot>> {
    return this.http
      .get<{
        knots: Knot[];
        count: number;
      }>(`${environment.baseUrl}/api/knots`, { params: new KnotFilters(filters).format() })
      .pipe(
        map(({ knots, count }) => ({ items: knots.map((knot) => new Knot(knot)), count })),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  createBulk(knots: Knot[], emitUpdate = true, withToast = true) {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/knots`, {
        knots: knots.map((knot) => ({
          name: knot.name,
          pinned: knot.pinned,
        })),
      })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success ? 'New Knot(s) were added.' : 'Something went wrong while adding knots',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  create(knot: Knot, { emit = true, displayToast = true }: FeedbackConfig) {
    return this.createBulk([knot], emit, displayToast).pipe(map(() => null));
  }

  update(_knot: Knot, _config?: FeedbackConfig): Observable<Knot> {
    throw new Error('Method forbidden');
  }

  updateBulk(knots: Knot[], emitUpdate = true, withToast = true) {
    return this.http
      .put<{ success: boolean }>(`${environment.baseUrl}/api/knots`, {
        knots: knots.map((knot) => ({
          id: knot.id,
          name: knot.name,
          pinned: knot.pinned,
          autoDiscovery: knot.autoDiscovery,
        })),
      })
      .pipe(
        tap(() => {
          if (withToast) {
            this.toaster.show({
              text: 'Knot(s) were updated.',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  deletePermanently(filters: Partial<KnotFilters>, _customToastMessage?, emitUpdate = true, withToast = true) {
    return this.http
      .delete<{ success: boolean }>(`${environment.baseUrl}/api/knots`, { params: new KnotFilters(filters).format() })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success ? 'Knot(s) were removed.' : 'Something went wrong while removing knots',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  link(knots: Knot[], items: Stitch[], emitUpdate = true, withToast = true): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/knots/link`, {
        knots: knots.map(({ name }) => name),
        items: groupByIds(items),
      })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success ? 'Knot(s) linked.' : 'Something went wrong while removing knots',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  unlink(knots: Knot[], items: Stitch[], emitUpdate = true, withToast = true): Observable<boolean> {
    return this.http
      .request<{ success: boolean }>('DELETE', `${environment.baseUrl}/api/knots/link`, {
        body: {
          knots: knots.map(({ name }) => name),
          items: groupByIds(items),
        },
      })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success ? 'Knot(s) unlinked.' : 'Something went wrong while unlinking knots',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  pin(knots: Knot[], pinned: boolean, emitUpdate = true) {
    return this.updateBulk(
      knots.map((knot) => ({ ...knot, pinned })),
      emitUpdate,
      false,
    ).pipe(
      tap((success) => {
        if (success) {
          this.toaster.show({
            text: `Knot(s) ${pinned ? 'pinned' : 'unpinned'}.`,
            icon: 'pellets',
          });
        }
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  @warmUpObservable
  setAutoDiscovery(knots: Knot[], autoDiscovery: boolean, emitUpdate = true) {
    return this.updateBulk(
      knots.map((knot) => ({ ...knot, autoDiscovery })),
      emitUpdate,
      false,
    ).pipe(
      tap((success) => {
        if (success) {
          this.toaster.show({
            text: `Knot(s) auto discovery ${autoDiscovery ? 'enabled' : 'disabled'}.`,
            icon: 'pellets',
          });
        }
      }),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  @warmUpObservable
  upsertBulk(knots: Knot[], items: Stitch[], emitUpdate = true, withToast = true) {
    return this.createBulk(knots, true, false).pipe(switchMap(() => this.link(knots, items, emitUpdate, withToast)));
  }

  getAutocompleteSuggestions(): AutocompleteFactory<Knot> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      if (values?.length > 0) {
        return this.search({ limit: config?.limit || 5, names: values }).pipe(
          map(({ items: knots }) =>
            knots.map((knot) => ({
              title: knot.name,
              value: knot.name,
              source: knot,
            })),
          ),
        );
      }

      return this.search({ limit: config?.limit || 5, query: title?.trim() }).pipe(
        map(({ items: knots }) =>
          knots.map((knot) => ({
            title: knot.name,
            value: knot.name,
            source: knot,
          })),
        ),
      );
    };
  }

  getNeighbors(filters: Partial<KnotsNeighboursFilters>) {
    return this.http
      .get<{
        knots: Knot[];
        count: number;
      }>(`${environment.baseUrl}/api/knots/neighbors`, { params: new KnotsNeighboursFilters(filters).format() })
      .pipe(
        map(({ knots, count }) => ({ knots: knots.map((knot) => new Knot(knot)), count })),
        catchError((error) => this.handleObserverError(error)),
      );
  }
}
