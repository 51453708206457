// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Connection } from '../types/connection';
import { ManageListState } from '../types/manage-list-state';

@Pipe({
  name: 'groupAlphabetically',
  standalone: false,
})
export class GroupAlphabeticallyPipe implements PipeTransform {
  transform(connections: Connection[] = [], state: ManageListState): { letter: string; connections: Connection[] }[] {
    return Object.entries(
      connections.reduce((memo, connection) => {
        const firstLetter = connection.name[0].toLocaleUpperCase();

        return {
          ...memo,
          [firstLetter]: [...(memo[firstLetter] || []), connection],
        };
      }, {}),
    )
      .map(([k, v]) => ({ letter: k, connections: v as Connection[] }))
      .sort((a, b) => (a.letter > b.letter ? 1 : -1) * (state.sort.order === 'desc' ? -1 : 1));
  }
}
