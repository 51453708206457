<div
  class="popover-container"
  stchPopover
  stchPopoverPlacement="bottom"
  stchPopoverTrigger="click"
  [stchPopoverContent]="formTemplate"
  [stchPopoverShowUntil]="popoverClose"
  [stchPopoverFallbackPlacements]="['top', 'right', 'left']"
  (stchPopoverVisibleChange)="handlePopoverOpen($event)"
>
  <ng-content />
</div>

<ng-template #formTemplate>
  <div class="form date-time-popover">
    <div class="form-label">Date Picker</div>

    <mat-icon class="form-close-icon" svgIcon="close" (click)="handleClose()" />

    <div
      *ngIf="toDate"
      class="form-date-buttons"
    >
      <div
        class="form-date-buttons-item"
        [class.selected]="dateButtonSelected === 'from'"
        [class.active]="innerFromDate?.value"
        (click)="dateButtonSelected = 'from'"
      >
        {{ innerFromDate?.value ? (innerFromDate?.value | date : 'MMM d') : 'Start Date'}}
      </div>
      <div
        class="form-date-buttons-item"
        [class.selected]="dateButtonSelected === 'to'"
        [class.active]="innerToDate?.value"
        (click)="dateButtonSelected = 'to'"
      >
        {{ innerToDate?.value ? (innerToDate?.value | date : 'MMM d') : 'Due Date'}}
      </div>
    </div>

    <div class="form-quick-actions">
      <mat-icon
        class="form-quick-actions-item"
        [class.active]="(dateButtonSelected === 'from' ? innerFromDate?.value : innerToDate?.value) | compareToRelativeDate: 'today'"
        [class.disabled]="(dateButtonSelected === 'from' ? innerToDate : innerFromDate)
          .value | disableQuickDatePicker: dateButtonSelected: 'today'
        "
        svgIcon="quick-today"
        (click)="setDate('today')"
        matTooltip="Today"
      />

      <mat-icon
        class="form-quick-actions-item"
        [class.active]="(dateButtonSelected === 'from' ? innerFromDate?.value : innerToDate?.value) | compareToRelativeDate: 'tomorrow'"
        [class.disabled]="(dateButtonSelected === 'from' ? innerToDate : innerFromDate)
          .value | disableQuickDatePicker: dateButtonSelected: 'tomorrow'
        "
        svgIcon="quick-tomorrow"
        (click)="setDate('tomorrow')"
        matTooltip="Tomorrow"
      />

      <mat-icon
        class="form-quick-actions-item"
        [class.active]="(dateButtonSelected === 'from' ? innerFromDate?.value : innerToDate?.value) | compareToRelativeDate: 'nextMonday'"
        [class.disabled]="(dateButtonSelected === 'from' ? innerToDate : innerFromDate)
          .value | disableQuickDatePicker: dateButtonSelected: 'nextMonday'
        "
        svgIcon="quick-week"
        (click)="setDate('nextMonday')"
        matTooltip="Next Monday"
      />

      <mat-icon
        class="form-quick-actions-item"
        [class.active]="(dateButtonSelected === 'from' ? innerFromDate?.value : innerToDate?.value) | compareToRelativeDate: 'nextMonth'"
        [class.disabled]="(dateButtonSelected === 'from' ? innerToDate : innerFromDate)
          .value | disableQuickDatePicker: dateButtonSelected: 'nextMonth'
        "
        svgIcon="quick-month"
        (click)="setDate('nextMonth')"
        matTooltip="Next Month"
      />
    </div>

    <app-date-picker-2
      [inputFormControl]="dateButtonSelected === 'from' ? innerFromDate : innerToDate"
      [collapseable]="false"
      [maxDate]="dateButtonSelected === 'from' && innerToDate?.value"
      [minDate]="minDate || (dateButtonSelected === 'to' && innerFromDate?.value)"
      [highlightRange]="highlightRange"
    />

    <div
      class="form-time"
      [class.center]="!toTime"
    >
      <div
        class="form-time-item value-button"
        [class.active]="innerFromTime.value"
        [class.no-border]="!toTime"
      >
        <app-time-picker
          *ngIf="!toTime"
          [inputFormControl]="innerFromTime"
        />
        <app-time-range-picker
          *ngIf="toTime"
          [fromTimeControl]="innerFromTime"
          [toTimeControl]="innerToTime"
        >
          {{ innerFromTime.value ? ((innerFromTime.value | date : 'h:mm a') | lowercase) : 'Start Time' }}
        </app-time-range-picker>
        <mat-icon
          *ngIf="innerFromTime.value"
          class="value-button-delete-icon"
          svgIcon="close"
          (click)="cleanFromTime()"
        />
      </div>
      <div
        *ngIf="toTime"
        class="form-time-item value-button"
        [class.active]="innerToTime.value"
      >
        <app-time-range-picker
          [fromTimeControl]="innerFromTime"
          [toTimeControl]="innerToTime"
          [focusToTime]="true"
        >
          {{ innerToTime.value ? ((innerToTime.value | date : 'h:mm a') | lowercase) : 'Due Time' }}
        </app-time-range-picker>
        <mat-icon
          *ngIf="innerToTime.value"
          class="value-button-delete-icon"
          svgIcon="close"
          (click)="cleanToTime(index)"
        />
      </div>
    </div>

    <app-notification-picker
      *ngIf="reminders && innerReminders?.value?.length === 0"
      (save)="addReminder($event)"
    >
      <div class="form-notification value-button">Set Notification </div>
    </app-notification-picker>

    <div
      class="form-notification-container"
      *ngFor="let reminder of innerReminders.controls; let last = last; let index = index"
    >
      <div
        class="form-notification value-button active"
      >
        <app-notification-picker
          [inputFormGroup]="reminder"
          (save)="addReminder($event)"
        >
          {{ reminder.value.duration | notificationText : reminder.value.units : reminder.value.dateType }}
          <mat-icon
            class="value-button-delete-icon"
            svgIcon="close"
            (click)="deleteReminder(index)"
          />
        </app-notification-picker>
      </div>

      <div *ngIf="last" class="form-notification-container-add">
        <app-notification-picker
          (save)="addReminder($event)"
        >
          <mat-icon class="form-notification-container-add-icon" svgIcon="plus" />
        </app-notification-picker>
      </div>
    </div>

    <div class="form-buttons">
      <app-button appearance="square" size="medium" (click)="handleClose()" label="Cancel" />
      <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
    </div>
  </div>
</ng-template>
