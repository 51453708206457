// Common
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Self, TemplateRef } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

// Rx
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { ControlsListAction } from '@modules/form-controls/types/controls-list-action';

// Utils
import { action } from '@modules/form-controls/services/controls-list-action';

@Component({
  selector: 'app-controls-list',
  templateUrl: './controls-list.component.html',
  styleUrls: ['./controls-list.component.less'],
  providers: [{ provide: action, useFactory: () => new Subject<ControlsListAction>() }],
  standalone: false,
})
export class ControlsListComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() items: UntypedFormArray;
  @Input() itemTemplateRef: TemplateRef<unknown>;
  @Input() withActions = true;

  // Outputs
  @Output() onAddItem = new EventEmitter<number>();

  // Private
  private alive = new Subject<void>();

  constructor(@Inject(action) @Self() private controlsListAction) {}

  // Lifecycle

  ngOnInit(): void {
    this.controlsListAction.pipe(takeUntil(this.alive)).subscribe(({ type, index }: ControlsListAction) => {
      switch (type) {
        case 'add':
          this.handleAddItem(index);
          break;
        case 'remove':
          this.handleRemoveItem(index);
          break;
      }
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  // Actions

  handleAddItem(index?: number): void {
    this.onAddItem.emit(index);
  }

  handleRemoveItem(index): void {
    this.items.length > 1 && this.items.removeAt(index);
  }
}
