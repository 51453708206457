// Common
import { Component, Injector } from '@angular/core';

// Types
import { Task } from '@modules/tasks/types/task';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-task-shared',
  templateUrl: './task-shared.component.html',
  styleUrls: ['./task-shared.component.less'],
  standalone: false,
})
export class TaskSharedComponent extends SharedBaseComponent<Task> {
  protected stitchType = StitchType.task;

  constructor(injector: Injector) {
    super(injector);
  }

  get task() {
    return this.stitchItem;
  }
}
