<app-input
  class="input"
  [inputFormControl]="form.controls.email"
  placeholder="Enter Email"
/>

<app-input
  class="input"
  [inputFormControl]="form.controls.password"
  placeholder="Password"
  [password]="true"
/>

<div class="actions">
  <a class="actions-forgot-password" routerLink="/forgot-password">Forgot password?</a>
  <app-radio-button
    appearance="checkbox"
    [booleanValue]="true"
    title="Keep me signed in"
    [inputFormControl]="form.controls.staySigned"
  />
</div>

<div
  class="button"
  (click)="handleSubmit()"
>
  Sign In
</div>
