// Common
import { Component, Output, EventEmitter, Injector, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// RxJS
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';

// Types
import { Task } from '@modules/tasks/types/task';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './task.component.less'],
  standalone: false,
})
export class TaskComponent extends BaseStitchComponent<Task> implements OnInit {
  @Output() openSubtask = new EventEmitter<Task>();

  public showIssueKey = false;
  public form: UntypedFormGroup;

  moveDragDataTypes = [DragDataTypes.task];
  dragDataType = DragDataTypes.task;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(dragData.type === DragDataTypes.task && dragData.data.length === 1 && dragData.data[0]['id'] === this.item.id) &&
    dragDataTypeAllowed(dragData.type);

  constructor(
    injector: Injector,
    protected tasksService: TasksService,
    private settingsService: TaskingAppSettingsService,
  ) {
    super(injector, tasksService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.settingsService
      .listAll()
      .pipe(takeUntil(this.alive))
      .subscribe(({ issueKeyEnabled }) => (this.showIssueKey = issueKeyEnabled));

    this.changed
      .pipe(
        tap(() => (this.form = (this.item || new Task()).asFormGroup())),
        switchMap(() => this.form.valueChanges),
        debounceTime(200),
        switchMap(() => this.tasksService.update(Task.fromFormGroup(this.form), { emit: false })),
        takeUntil(this.alive),
      )
      .subscribe();

    super.ngOnInit();
  }

  /**
   * Actions
   */

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    if (dragData.type === DragDataTypes.task) {
      this.tasksService
        .bunchUpdate({ ids: this.getIds(dragData.data) }, { parentId: this.item.id }, { message })
        .pipe(take(1), takeUntil(this.alive))
        .subscribe();
    }
  }

  handleComplete() {
    this.item.completed = !this.item.completed;
    this.tasksService.update(this.item).pipe(take(1), takeUntil(this.alive)).subscribe();
  }

  get task(): Task {
    return this.item;
  }
}
