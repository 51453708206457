// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Types
import { MessageTemplate } from '@modules/account/types/message-template';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TemplatesService } from '@modules/account/services/templates.service';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

@Component({
  selector: 'app-settings-messages-templates-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.less'],
  standalone: false,
})
export class TemplateFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() template: MessageTemplate;

  public form: FormGroup<{
    id: FormControl<string>;
    title: FormControl<string>;
    content: FormControl<string>;
  }>;

  private templateChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private templatesService: TemplatesService,
    @Inject(CloseToken) private closeToken,
  ) {}

  ngOnInit(): void {
    this.templateChanged.pipe(takeUntil(this.alive)).subscribe(() => {
      this.form = (this.template || new MessageTemplate()).asFormGroup();
    });

    this.templateChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('template' in changes) {
      this.templateChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleSubmit() {
    if (this.form.controls.content.value.search(/<!--template-->/g) === -1) {
      this.form.controls.content.setValue(
        `<!--template-->
        ${this.form.controls.content.value}
        <!--endTemplate-->`,
        { emitEvent: false },
      );
    }

    const template = MessageTemplate.fromFormGroup(this.form);

    this.templatesService
      .upsert(template)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.handleClose());
  }

  handleClose() {
    this.closeToken.next();
  }
}
