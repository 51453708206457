// Common
import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';
import { environment } from '@environment';

// Rx
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Types
import { Like } from '@modules/common/types/like';
import { Project } from '../types/project';
import { ProjectsFilters } from '../types/projects-filters';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { NestedService } from '@modules/common/types/nested-service.interface';

@Injectable()
export class ProjectsService
  extends BaseStitchService<Project, ProjectsFilters>
  implements CalendarEventsService, NestedService
{
  protected url = environment.baseUrl + '/api/tasking/projects/';
  protected stitchType = StitchType.project;
  protected filtersConstructor = ProjectsFilters;

  constructor(
    protected http: HttpClient,
    protected toasterService: ToasterService,
    @Optional() linkedInfoService: LinkedInfoService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<ProjectsFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Project, 'parentId'>>,
    config: FeedbackConfig,
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  create(projectInstance: Project, { emit, toast, message }: FeedbackConfig = { emit: true }): Observable<Project> {
    return this.http
      .post<{
        project: Project;
        success: boolean;
      }>(`${environment.baseUrl}/api/tasking/projects`, projectInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (!success) {
            return;
          }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Project created.` });
        }),
        map(({ project }) => new Project(project)),
        switchMap((project) =>
          this.processKnowledgeItems(
            {
              linkedInfo: projectInstance.linkedInfo,
              knots: projectInstance.knots,
              tags: projectInstance.tags,
            },
            project,
          ),
        ),
        switchMap((project) =>
          this.processUploads(projectInstance.uploads, project, { other: true, parentFolderName: 'Task App' }),
        ),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  update(
    projectInstance: Project,
    { emit, displayToast, toastMessage }: FeedbackConfig = { emit: true },
  ): Observable<Project> {
    return this.http
      .put<{
        project: Project;
        success: boolean;
      }>(environment.baseUrl + '/api/tasking/projects/' + projectInstance.id, projectInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Project updated' });
          }
        }),
        map(({ project }) => project),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime }).pipe(
      map(({ items }) => items.map((task) => task.asAngularCalendarEvent())),
    );
  }

  getAutocompleteSuggestions(inputFilters: Like<ProjectsFilters> = {}): AutocompleteFactory<Project> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<ProjectsFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters,
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title;
        filters.esAnalyzer = 'ngrams_7';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters).pipe(
        map((response: BaseSearchResponse<Project>) =>
          response.items.map((project) => ({
            title: project.title,
            value: project.id,
            source: project,
          })),
        ),
      );
    };
  }

  createInstance(item): Project {
    return new Project(item);
  }

  validateNested(id: string, parentId: string) {
    return this.http
      .get<{
        deep: boolean;
        circular: boolean;
      }>(`${environment.baseUrl}/api/tasking/projects/validate-nesting/${id}/${parentId}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        }),
        catchError((error) => this.handleObserverError(error)),
      );
  }
}
