<app-autocomplete-input
  [inputFormControl]="inputFormControl"
  [label]="label"
  [suggestions]="suggestions"
  [leftIcon]="selectedCountry?.iso3"
  [noBorder]="showBy === 'phoneCode'"
  [itemTemplate]="itemTemplate"
  [withClear]="withClear"
  [appearance]="appearance"
  [invertedColor]="invertedColor"
/>

<ng-template #itemTemplate let-item="item">
  <div
    class="country-picker-option"
    [class.active]="selectedCountry?.iso3 === item.iso3"
    [ngClass]="appearance"
  >
    <mat-icon
      class="country-picker-option-icon"
      [svgIcon]="item.iso3"
    />
    <span
      *ngIf="showBy === 'phoneCode'"
      class="country-picker-option-phone-code"
    >{{ item.phoneCode }}</span>
    <span
      class="country-picker-option-title"
      [class.dark]="showBy === 'iso3'"
    >{{ item.name }}</span>
  </div>
</ng-template>
