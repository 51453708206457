<div
  class="column"
  [class.large]="appearance === 'board-large'"
  [class.medium]="appearance === 'board-medium'"
  [class.small]="appearance === 'board-small'"
  [class.collapsed]="collapsed"
>
  <div
    *ngIf="!row"
    class="column-right-dot-container"
    (click)="handleNewColumnRight()"
  >
    <div class="dot"></div>
    <div class="plus">+</div>
  </div>

  <div
    class="column-marker"
    [style.background-color]="column.color"
  ></div>

  <div
    *ngIf="!row"
    class="column-heading"
    [appProjectBoardColumnContextMenu]="column"
    [style.background-color]="column.color"
  >
    <input
      class="column-heading-title"
      (blur)="handleBlur()"
      placeholder="Enter column title"
      [formControl]="columnForm.controls['title']"
    >

    <div class="column-heading-left-text">
      {{ tasksCount }}
    </div>
  </div>

  <mat-icon
    *ngIf="(collapsed || !row) && column.id"
    svgIcon="dropdown-down"
    class="column-collapse-icon"
    (click)="collapse()"
  />

  <div class="column-collapsed-title">{{ column.title }}</div>

  <div
    class="column-content"
    [class.default-row]="!row"
  >
    <app-board-task
      *dndNestedFor="
        let task of tasks;
        let index = index;
        let position = position;
        let depth = depth;
        let expandable = expandable;
        let expanded = expanded;
        stateKey: column.projectId;
        positionKey: 'boardPosition';
        maxDepth: 1;
        droppableZIndex: 1;
        changes: tasksChanges;
        predicate: dndPredicate;
      "
      [task]="task"
      [subtask]="depth > 0"
      [index]="index"
      [position]="position"
      [expandable]="expandable"
      [expanded]="expanded"
      [hovered]="hovered"
    />

    <div
      *ngIf="!quickTask"
      class="column-content-new-task"
      (click)="handleNewTask()"
      appDroppable
      appDroppableHoverClass="column-content-new-task-drag-over"
      [appDroppableZIndex]="1"
      [appDroppableNoReposition]="true"
      (appDroppableDrop)="handleNewTaskDrop($event)"
    >
      + Add New Task
      <div class="drop-zone"></div>
    </div>

    <app-task-quick-form
      *ngIf="quickTask"
      class="task-quick-form"
      [task]="quickTask"
      (close)="handleQuickFormClose()"
      (save)="handleQuickFormSave($event)"
      (more)="handleQuickFormMore($event)"
      (afterSave)="close()"
    />
  </div>
</div>
