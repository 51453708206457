// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// Services
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';
import { AccountService } from '@modules/account/services/account.service';
import { AlertService } from '@modules/alert/services/alert.service';

// Types
import { Account } from '@modules/account/types/account';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.less'],
  standalone: false,
})
export class PlanCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rows: { label: string; value: string; type?: string; template?: string }[];
  @Input() badge: string;
  @Input() plan: SubscriptionPlan;
  @Input() account: Account;
  @Input() currentPlan: SubscriptionPlan;

  public chargeTypeControl = new FormControl<'monthly' | 'annually'>('monthly');
  public buttonTitle: string = 'Get Started';

  private alive = new Subject<void>();

  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<void>;

  constructor(
    private plansService: SubscriptionPlansService,
    private accountService: AccountService,
    private alertsService: AlertService,
    private router: Router,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.chargeTypeControl.valueChanges.pipe(takeUntil(this.alive)).subscribe((value) => {
      if (this.currentPlan?.id === this.plan.id && value !== this.account.chargeType) {
        this.buttonTitle = `Change to ${this.chargeTypeControl.value} payments`;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('account' in changes && 'currentPlan' in changes && this.account) {
      if (this.currentPlan) {
        if (this.currentPlan.id === this.plan.id) {
          this.buttonTitle = `Selected`;
        } else {
          this.buttonTitle = `${this.plan.monthlyPrice > this.currentPlan.monthlyPrice ? 'Upgrade' : 'Downgrade'} to ${this.plan.title}`;
        }
      } else {
        this.buttonTitle = `Get ${this.plan.title}`;
      }
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  select() {
    this.alertsService.show({
      appearance: 'sapphire-modal',
      title: `You're switching to ${this.plan.title}`,
      template: this.modalTemplate,
      width: 564,
      rightButtons: [
        {
          title: 'Cancel',
          close: true,
          appearance: 'sapphire-text-accent',
          size: 'm',
        },
        {
          title: 'Confirm',
          close: true,
          appearance: 'sapphire-text-accent',
          size: 'm',
          click: () => this.apply(),
        },
      ],
      leftButtons: [
        {
          title: 'Billing Info',
          close: true,
          appearance: 'sapphire-text-accent',
          size: 'm',
          click: () => this.showBillingPage(),
        },
      ],
    });
  }

  showBillingPage() {
    this.router.navigate(['/settings/general/billing']);
  }

  apply() {
    this.plansService
      .migrateToPlan(this.plan, this.chargeTypeControl.value)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.accountService.reloadAccount();
      });
  }
}
