<div *ngFor="let item of tree; let index = index">
  <ng-container
    [ngTemplateOutlet]="template"
    [ngTemplateOutletContext]="{
      $implicit: item.item,
      depth: depth,
      index: item.index,
      expandable: item.children.length,
      expanded: item.expanded
    }"
  />

  <div
    class="collapseable"
    [style.height.px]="expanded[index] ? null : 0"
    [@collapseMotion]="{ value: expanded[index] ? 'expanded' : 'collapsed', params: { height: contentHeight[index] }}"
  >
    <div #content>
      <app-nested-items
        [template]="template"
        [tree]="item.children"
        [depth]="depth + 1"
      />
    </div>
  </div>
</div>
