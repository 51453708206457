import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.less'],
  standalone: false,
})
export class BaseModalComponent implements OnDestroy {
  // Outputs
  @Output() closed = new EventEmitter();
  @Output() collapsed = new EventEmitter<boolean>();

  // Public
  public minimized = false;
  public defaultSize: ModalFrame;
  public maximizedSize: ModalFrame;

  // Protected
  protected alive = new Subject<void>();

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Methods
   */

  close() {
    this.closed.emit();
  }

  collapse() {
    this.minimized = !this.minimized;
    this.collapsed.emit(this.minimized);
  }
}
