// Common
import { Component, Inject, Injector, Input, OnDestroy } from '@angular/core';

// Types
import { Notification } from '@modules/notification/types/notification';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { NotificationsService } from '@modules/notification/services/notifications.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';

// Injection Token
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less'],
  standalone: false,
})
export class NotificationComponent implements OnDestroy {
  @Input() notification: Notification;
  @Input() showIssueKey: boolean;

  public popoverShowUntil = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private stitchServiceFactory: StitchServiceFactory,
    @Inject(CloseToken) private closeToken,
    private injector: Injector,
  ) {}

  ngOnDestroy() {
    this.popoverShowUntil.next();
    this.popoverShowUntil.complete();
  }

  handleClickEntity() {
    this.stitchServiceFactory
      .getServiceByStitchType(this.notification.entity.entityType)
      .getItem(this.notification.entity.id)
      .pipe(take(1), takeUntil(this.popoverShowUntil))
      .subscribe((item) => {
        this.modalService.openFullForm(item, this.injector);
        this.notificationsService.deleteNotifications({ emit: false }, [this.notification.id]);
        this.closeToken.next();
      });
  }
}
