<app-quick-form
  class="quick-form"
  [inline]="true"
>
  <app-task-quick-form
    [project]="project"
  />
</app-quick-form>

<div class="board">
  <div class="board-area">
    <app-section
      [container]="project"
      [itemTemplate]="taskTemplate"
      dragDataType="task"
    />

    <app-section
      *ngFor="let section of sections"
      [section]="section"
      [container]="project"
      [itemTemplate]="taskTemplate"
      dragDataType="task"
    />

    <div
      *ngIf="project"
      class="board-area-text-button"
      (click)="addSection()"
    >
      + Add Section
    </div>
  </div>
</div>

<ng-template #taskTemplate
  let-task="item"
  let-depth="depth"
  let-index="index"
  let-position="position"
  let-expandable="expandable"
  let-expanded="expanded"
  let-hovered="hovered"
>
  <app-board-task
    [task]="task"
    [subtask]="depth > 0"
    [index]="index"
    [position]="position"
    [expandable]="expandable"
    [expanded]="expanded"
    [hovered]="hovered"
    (appClick)="clickItem.emit(task)"
    (appDblClick)="dblClickItem.emit(task)"
  />
</ng-template>
