// Common
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';

// Components
import { InputComponent } from '@modules/form-controls/components/input/input.component';

@Component({
  template: '',
  standalone: false,
})
export abstract class BaseQuickFormComponent implements OnInit, AfterViewInit, OnDestroy {
  // Inputs
  @Input() inline = false;
  @Input() autofocus = false;
  @Input() title = '';
  @Input() parentId: string;

  // Outputs
  @Output() more: EventEmitter<Stitch> = new EventEmitter<Stitch>();
  @Output() close = new EventEmitter<void>();
  @Output() save: EventEmitter<Stitch> = new EventEmitter<Stitch>();
  @Output() afterSave: EventEmitter<Stitch> = new EventEmitter<Stitch>();

  // Public
  public form: UntypedFormGroup;
  public saveInProgress = false;
  public titleFocusSubject = new Subject<void>();
  public submitted = false;

  // Protected
  protected alive = new Subject<void>();

  // View Children
  @ViewChild('titleInput', { static: true }) titleInput: InputComponent;

  /**
   * Constructor
   */

  constructor(public changeDetector: ChangeDetectorRef) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.titleFocusSubject.pipe(debounceTime(0), takeUntil(this.alive)).subscribe(() => this.titleInput.focus());
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      this.titleInput.focus();
    }

    if (this.title) {
      this.form.controls['title']?.setValue?.(this.title);
      this.form.controls['subject']?.setValue?.(this.title);
    }

    if (this.parentId) {
      this.form.controls['parentId']?.setValue?.(this.parentId);
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleError() {
    this.saveInProgress = false;
  }

  abstract submit(): void;

  reset() {
    this.saveInProgress = false;
  }

  clickMore() {
    this.close.emit();
  }

  cancel() {
    this.close.emit();
  }

  handleEnterKeyPressed(event: KeyboardEvent) {
    event.stopPropagation();
    this.submit();
  }
}
