import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Settings {
  formBuilder = injector.get(FormBuilder);

  contactsPostponeNotificationsEnabled: boolean;
  groupsPostponeNotificationsEnabled: boolean;
  notificationsEnabled: boolean;

  constructor(data: Partial<Settings> = {}) {
    this.contactsPostponeNotificationsEnabled = data.contactsPostponeNotificationsEnabled ?? false;
    this.groupsPostponeNotificationsEnabled = data.groupsPostponeNotificationsEnabled ?? false;
    this.notificationsEnabled = data.notificationsEnabled;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      contactsPostponeNotificationsEnabled: formValues.contactsPostponeNotificationsEnabled,
      groupsPostponeNotificationsEnabled: formValues.groupsPostponeNotificationsEnabled,
      notificationsEnabled: formValues.notificationsEnabled,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      contactsPostponeNotificationsEnabled: [this.contactsPostponeNotificationsEnabled],
      groupsPostponeNotificationsEnabled: [this.groupsPostponeNotificationsEnabled],
      notificationsEnabled: [this.notificationsEnabled],
    });
  }

  public asPayloadJSON() {
    return {
      contactsPostponeNotificationsEnabled: this.contactsPostponeNotificationsEnabled,
      groupsPostponeNotificationsEnabled: this.groupsPostponeNotificationsEnabled,
      notificationsEnabled: this.notificationsEnabled,
    };
  }
}
