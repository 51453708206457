// Common
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  Injector,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { AlertConfig, AlertInputConfig } from '@modules/alert/types/config';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  styleUrls: ['./alert.component.less'],
  standalone: false,
})
export class AlertComponent implements OnInit, AfterViewInit, OnDestroy {
  public visible = false;
  public inputs: AlertInputConfig[] = [];
  public nestedClassName: string[] = [];
  public outsideClickExceptSelectors: string;

  private uniqClassName = 'a' + Math.random().toString(36).slice(-5);
  private closeSubject = new Subject<void>();
  private alive = new Subject<void>();

  @Input() config: AlertConfig;
  @Input() parentNestedSelectors: string;
  @Input() allowedOutsideSelectorsClick: string;

  @Output() close = new EventEmitter<void>();

  @ViewChild('modalAnchor', { read: ViewContainerRef, static: true }) private modalAnchor: ViewContainerRef;
  @ViewChild('popoverContainer') popoverContainer: ElementRef;

  constructor(private injector: Injector) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputs = (this.config?.inputs || []).map((input) => ({
      ...input,
      control: input.control || new UntypedFormControl(),
    }));

    if (this.config?.component) {
      const injector = Injector.create({
        providers: [{ provide: CloseToken, useFactory: () => this.closeSubject }],
        parent: this.config.injector || this.injector,
      });

      const componentRef = this.modalAnchor.createComponent(this.config.component, { injector });

      if (this.config.context) {
        Object.entries(this.config.context).forEach(([k, v]) => {
          componentRef.instance[k] = v;
        });
      }
    }

    this.closeSubject.pipe(takeUntil(this.alive)).subscribe(() => {
      this.close.emit();
    });
  }

  ngAfterViewInit() {
    this.nestedClassName = this.parentNestedSelectors
      ? [...this.parentNestedSelectors.split(','), this.uniqClassName]
      : [this.uniqClassName];

    this.outsideClickExceptSelectors = [`.${this.uniqClassName}`, this.allowedOutsideSelectorsClick]
      .filter((i) => !!i)
      .join(', ');

    this.popoverContainer.nativeElement.dataset.nestedSelectors = this.nestedClassName.join(',');

    this.nestedClassName.forEach((token) => {
      this.popoverContainer.nativeElement.classList.add(token);
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  hide(): void {
    this.close.emit();
  }

  handleButtonClick(buttonsGroup: 'right' | 'left', index: number) {
    const button = (buttonsGroup === 'right' ? this.config.rightButtons : this.config.leftButtons)?.[index];

    const data = this.inputs.reduce((memo, input) => ({ ...memo, [input.name]: input.control.value }), {});

    button?.click?.(data);
    button?.close && this.hide();
  }
}
