import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findBy',
  standalone: false,
})
export class FindByPipe implements PipeTransform {
  transform(array: object[], property, value): object {
    return array?.find((item) => item[property] === value);
  }
}
