// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

export const mapStitchTypeToAppName = {
  [StitchType.contact]: Application.contacts,
  [StitchType.calendar]: Application.calendar,
  [StitchType.event]: Application.calendar,
  [StitchType.file]: Application.files,
  [StitchType.folder]: Application.files,
  [StitchType.group]: Application.contacts,
  [StitchType.messageFolder]: Application.mail,
  [StitchType.message]: Application.mail,
  [StitchType.note]: Application.notes,
  [StitchType.notebook]: Application.notes,
  [StitchType.project]: Application.tasking,
  [StitchType.task]: Application.tasking,
};

export const mapAppNameToStitchType = {
  [Application.contacts]: StitchType.contact,
  [Application.calendar]: StitchType.event,
  [Application.files]: StitchType.file,
  [Application.mail]: StitchType.message,
  [Application.notes]: StitchType.note,
  [Application.tasking]: StitchType.task,
};

const mapStitchTypeToIdsKey = {
  [StitchType.contact]: 'contacts_ids',
  [StitchType.group]: 'groups_ids',
  [StitchType.project]: 'projects_ids',
  [StitchType.task]: 'tasks_ids',
  [StitchType.note]: 'notes_ids',
  [StitchType.notebook]: 'notebooks_ids',
  [StitchType.file]: 'files_ids',
  [StitchType.folder]: 'folders_ids',
  [StitchType.calendar]: 'calendars_ids',
  [StitchType.event]: 'events_ids',
  [StitchType.message]: 'messages_ids',
  [StitchType.messageFolder]: 'message_folders_ids',
};

export const mapStitchTypeToIcon = {
  [StitchType.messageFolder]: 'folder',
  [StitchType.message]: 'mail',
  [StitchType.calendar]: 'quick-calendar',
  [StitchType.event]: 'event',
  [StitchType.project]: 'project',
  [StitchType.task]: 'task',
  [StitchType.notebook]: 'notebook',
  [StitchType.note]: 'note',
  [StitchType.group]: 'groups',
  [StitchType.contact]: 'contacts',
  [StitchType.folder]: 'folder',
  [StitchType.file]: 'file',
};

export const mapAppToIcon = {
  [Application.tasking]: 'task',
  [Application.mail]: 'mail',
  [Application.calendar]: 'event',
  [Application.contacts]: 'contacts',
  [Application.files]: 'file',
  [Application.notes]: 'note-small',
};

export const groupByIds = (items: Stitch[]) =>
  items.reduce(
    (memo, item) => {
      const key = mapStitchTypeToIdsKey[item.getStitchType()];

      return {
        ...memo,
        [key]: [...memo[key], item.id],
      };
    },
    {
      message_folders_ids: [],
      messages_ids: [],
      calendars_ids: [],
      events_ids: [],
      projects_ids: [],
      tasks_ids: [],
      notebooks_ids: [],
      notes_ids: [],
      groups_ids: [],
      contacts_ids: [],
      folders_ids: [],
      files_ids: [],
    },
  );

export const getStitchInstanceByStitchType = (stitchType: StitchType, properties?: object) => {
  switch (stitchType) {
    case StitchType.messageFolder:
      return new MessageFolder(properties);
    case StitchType.message:
      return new Message(properties);
    case StitchType.calendar:
      return new Calendar(properties);
    case StitchType.event:
      return new CalendarEvent(properties);
    case StitchType.project:
      return new Project(properties);
    case StitchType.task:
      return new Task(properties);
    case StitchType.notebook:
      return new Notebook(properties);
    case StitchType.note:
      return new Note(properties);
    case StitchType.group:
      return new Group(properties);
    case StitchType.contact:
      return new Contact(properties);
    case StitchType.folder:
      return new Folder(properties);
    case StitchType.file:
      return new File(properties);
    default:
      checkExhaustiveness(stitchType);
  }
};

export const appToEntityTypes = {
  [Application.mail]: [StitchType.messageFolder, StitchType.message],
  [Application.calendar]: [StitchType.calendar, StitchType.event],
  [Application.tasking]: [StitchType.project, StitchType.task],
  [Application.notes]: [StitchType.notebook, StitchType.note],
  [Application.contacts]: [StitchType.group, StitchType.contact],
  [Application.files]: [StitchType.folder, StitchType.file],
};
