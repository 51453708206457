// Common
import { Component, inject, Injector, OnDestroy, OnInit } from '@angular/core';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarsFilters } from '@modules/calendar-app/types/calendars-filters';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { ListState } from '@modules/calendar-app/types/list-state';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { Tab } from '@modules/common/types/tab';
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RX
import { CalendarsListStateService } from '@modules/calendar-app/services/calendars-list-state.service';
import { EventsListStateService } from '@modules/calendar-app/services/events-list-state.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-events-list-container',
  templateUrl: './events-list-container.component.html',
  styleUrls: ['./events-list-container.component.less'],
  standalone: false,
  providers: [
    EventsListStateService.providers({
      stateKey: StateKey.eventsListState,
      withAdvancedSearch: true,
    }),
    CalendarsListStateService.providers({
      stateKey: StateKey.calendarsListState,
      withAdvancedSearch: true,
    }),
  ],
})
export class EventsListContainerComponent implements OnInit, OnDestroy {
  public selectedOrder: DropdownOption;
  public listState: ListState;
  public listScrollPosition: number;
  public minimized: boolean;
  public sidebarFilter: VirtualFolder;
  public loading: boolean;
  public scrollToDay: Observable<Date>;
  public eventsFilters: EventsFilters;
  public calendarsFilters: CalendarsFilters;
  public debug: 'score' = null;
  public quickEvent: CalendarEvent;
  public quickCalendar = new Calendar();
  public selectedTab: Exclude<ListState, 'tabs'> = 'events';
  public tabs: Tab[] = [
    { title: 'Calendars', value: 'calendars' },
    { title: 'Events', value: 'events' },
  ];

  private alive = new Subject<void>();
  private searchTitle = new BehaviorSubject('');

  private readonly eventsListStateService = inject(EventsListStateService, { self: true });
  private readonly calendarsListStateService = inject(CalendarsListStateService, { self: true });

  constructor(
    private splitViewService: SplitViewService,
    private stateService: CalendarAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {
    this.scrollToDay = this.stateService.getScrollToDay();
  }

  /**
   * Lifecycle
   */

  public ngOnInit() {
    combineLatest([
      this.eventsListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.eventsFilters = new EventsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.calendarsListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.calendarsFilters = new CalendarsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    this.splitViewService
      .getMinimized('eventsList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    combineLatest([this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(takeUntil(this.alive))
      .subscribe(([state, folder]) => {
        this.sidebarFilter = folder;
        this.quickEvent = CalendarEvent.fromAdvancedState(state, folder);
      });

    this.stateService
      .getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleScrollOptionChange(value: number) {
    this.listScrollPosition = null;
    setTimeout(() => (this.listScrollPosition = value));
  }

  handleNewEvent(event = new CalendarEvent()) {
    this.stateService.setMainView(event);
  }

  handleNewCalendar(calendar = new Calendar()) {
    this.stateService.setMainView(calendar);
  }

  handleSearch(title: string) {
    this.searchTitle.next(title);
  }

  handleDoubleClickItem(item: CalendarEvent | Calendar) {
    this.modalService.openFullForm(item, this.injector);
  }

  handleClickEvent(event: CalendarEvent) {
    this.stateService.setSelectedEvents([event]);
    this.stateService.setSelectedCalendarDate(event.startTime);

    this.stateService.setMainView(event);
  }

  handleClickCalendar(calendar: Calendar) {
    this.stateService.setMainView(calendar);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
