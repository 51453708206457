// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { PopoverModule } from '../popover/popover.module';

// Services
import { UserAvatarService } from './services/user-avatar.service';

// Directives
import { ContactContextMenuDirective } from './directives/contact-context-menu-directive/contact-context-menu.directive';

// Components
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { ContactsCounterDropdownComponent } from './components/contacts-counter-dropdown/contacts-counter-dropdown.component';
import { ContactContextMenuComponent } from './components/contact-context-menu/contact-context-menu.component';

@NgModule({
  declarations: [
    ContactContextMenuComponent,
    ContactContextMenuDirective,
    ContactsCounterDropdownComponent,
    UserAvatarComponent,
  ],
  exports: [
    ContactContextMenuComponent,
    ContactContextMenuDirective,
    ContactsCounterDropdownComponent,
    UserAvatarComponent,
  ],
  imports: [CommonModule, PopoverModule],
  providers: [UserAvatarService],
})
export class UserAvatarModule {}
