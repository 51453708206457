<div class="block column">
  <div class="heading2">Default Event Duration</div>

  <div class="body-primary">End 30 min meetings 5 min early and longer meetings 10 min early</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.eventDuration"
    [options]="eventDurationOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Default Guest Permissions</div>

  <app-dropdown-multiple-input
    class="dropdown"
    [inputFormControl]="form.controls.guestPermissions"
    [options]="guestPermissionsOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Default Reminder</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.reminder"
    [options]="reminderOptions"
    appearance="amethyst"
  />
</div>

<div class="block column">
  <div class="heading2">Birthday calendar</div>

  <div class="body-primary">Add a calendar for keeping track of people’s birthdays</div>

  <app-checkbox
    class="birthday-checkbox"
    [inputFormControl]="form.controls.birthdayCalendarEnabled"
    title="Turn on the birthday calendar"
    appearance="new"
  />
</div>
