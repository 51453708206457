import { Injectable } from '@angular/core';

declare global {
  interface Window {
    ga: any;
  }
}

@Injectable()
export class GoogleAnalyticsService {
  trackPage(path: string) {
    window.ga('set', 'page', path);
    window.ga('send', 'pageview');
  }

  trackEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    window.ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });
  }

  setUser(userID: string) {
    window.ga('set', 'userId', userID);
  }
}
