// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Icon } from '@modules/icons/types/icons';

@Component({
  selector: 'stch-bottom-bar',
  templateUrl: './stitch-bottom-bar.component.html',
  styleUrl: './stitch-bottom-bar.component.less',
  standalone: false,
})
export class StitchBottomBarComponent {
  @Input() stitchItem: Stitch;
  @Input() showChild = true;
  @Input() showStitch = true;
  @Input() showNested = true;

  // Back-end implementation is needed
  @Input() childCount = 0;
  @Input() stitchCount = 0;
  @Input() nestedCount = 0;

  @Output() expand = new EventEmitter<boolean>();
  @Output() expandChildren = new EventEmitter<boolean>();

  public childExpanded = false;
  public stitchExpanded = false;
  public nestedExpanded = false;
  public childIconByStitchType: { [K in StitchType]: Icon } = {
    'message-folder': 'sapphire-email',
    message: 'sapphire-thread',
    calendar: 'sapphire-event',
    event: null,
    project: 'sapphire-tasks-list',
    task: 'sapphire-subtask',
    notebook: 'sapphire-note',
    note: 'sapphire-notes',
    group: 'sapphire-contact',
    contact: null,
    folder: 'sapphire-file',
    file: null,
  };

  onExpandclicked($event: MouseEvent) {
    this.childExpanded = !this.childExpanded;
    this.expandChildren.emit(this.childExpanded);
    $event.stopPropagation();
  }
}
