// Common
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.less', '../../styles/input.less'],
  standalone: false,
})
export class DateInputComponent {
  // Inputs
  @Input() appearance: 'default' | 'amethyst' = 'default';
  @Input() inputFormControl: UntypedFormControl;
  @Input() placeholder: string;
  @Input() noBorder = false;
  @Input() withClear = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() invertedColor = false;
  @Input() disabled = false;

  // Public
  public popoverClose = new Subject<void>();
  public focused = false;

  /**
   * Actions
   */

  handleClear() {
    this.inputFormControl?.setValue(null);
  }

  handleCancel() {
    this.popoverClose.next();
  }
}
