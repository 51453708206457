// Types
import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class SpaceParticipantsFilters extends Filters {
  sortBy?: 'joinedAt' | 'fullName';
  title?: string;
  status?: 'pending' | 'accepted';
  roleId?: string;

  constructor(filters?: Like<SpaceParticipantsFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
    this.title = filters?.title;
    this.status = filters?.status;
    this.roleId = filters?.roleId;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = super.format();

    result['sort_by'] = this.sortBy || 'joinedAt';
    if (this.status) {
      result['status'] = this.status;
    }
    if (this.roleId) {
      result['roleId'] = this.roleId;
    }

    if (this.title?.trim()) result['title'] = this.title;

    return result;
  }
}
