// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Application } from '../../common/types/application';

@Pipe({
  name: 'appTitle',
  standalone: false,
})
export class AppTitlePipe implements PipeTransform {
  transform(app: Application): string {
    if (!app) {
      return '';
    }

    switch (app) {
      case Application.mail:
        return 'Mail App';
      case Application.calendar:
        return 'Calendar App';
      case Application.tasking:
        return 'Tasking App';
      case Application.notes:
        return 'Notes App';
      case Application.contacts:
        return 'Contacts App';
      case Application.files:
        return 'Files App';
      case Application.kp:
        return 'KP';
      case Application.settings:
        return 'Settings';
      case Application.admin:
        return 'Admin';
      default:
        return checkExhaustiveness(app);
    }
  }
}
