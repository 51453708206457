import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { environment } from '@environment';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarsFilters } from '@modules/calendar-app/types/calendars-filters';
import { BaseStitchListComponent, StitchListItem } from '@modules/common/components/list/base-stitch-list.component';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { KnotSource } from '@modules/knots/types/knot-source';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { CalendarComponent } from '@modules/settings/components/calendar/calendar/calendar.component';
import { BehaviorSubject, interval, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-calendars-list',
  templateUrl: './calendars-list.component.html',
  styleUrls: ['./calendars-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class CalendarsListComponent extends BaseStitchListComponent<Calendar> implements OnInit, OnChanges, OnDestroy {
  @Input()
  public filters: Partial<CalendarsFilters> = {};

  @Input()
  public withTags = false;
  @Input()
  public withKnots = false;
  @Input()
  public knotsSource: KnotSource;
  @Input()
  public debug: 'score';

  @Output()
  public readonly clickCalendar = new EventEmitter<Calendar>();
  @Output()
  public readonly openCalendar = new EventEmitter<Calendar>();
  @Output()
  public readonly doubleClickCalendar = new EventEmitter<Calendar>();
  @Output()
  public readonly openEvent = new EventEmitter<CalendarEvent>();

  @ViewChildren(CalendarComponent, { read: ElementRef<HTMLElement> })
  public override itemElements: QueryList<ElementRef<HTMLElement>>;

  public override items: StitchListItem<Calendar>[] = [];

  public showCountView = new BehaviorSubject(true);

  private readonly calendarsService = inject(CalendarsService);
  private readonly eventsStateService = inject(CalendarAppStateService);
  private readonly linkedInfoService = inject(LinkedInfoService);

  public override ngOnInit() {
    merge(
      // Global refresh button
      this.eventsStateService.getRefreshAll(),
      // Subscribe for linked info updates
      this.linkedInfoService.getRefresh(),
      // Make automatic updates for new calendars
      interval(environment.messageFetchInterval),
      this.calendarsService.getRefresh(),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadPage(this.page);
      });

    merge(this.eventsStateService.getRefreshAll(), this.calendarsService.getRefresh())
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes) {
      this.resetItems();
    }
  }

  public override getItems(offset: number, limit: number): Observable<{ items: Calendar[]; count: number }> {
    return this.calendarsService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  public handleDoubleClickItem(calendar: Calendar) {
    this.doubleClickCalendar.emit(calendar);
  }

  public handleClickItem(calendar: Calendar) {
    this.clickCalendar.emit(calendar);
  }
}
