// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { StitchedFilters } from '@modules/linked-info/types/stitched-filters';
import { StateKey } from '@modules/settings/types/state-key';
import { type Like } from './like';
import { SortOrder } from './sort-order';
import { type SortBy } from './SortBy';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

// RX
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Decorators
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { OnChange } from 'src/app/decorators/on-change';
import { Stitch } from './stitch';

export abstract class BaseStitchListState<S extends Stitch, SORTABLE_FIELD = keyof S> {
  public abstract sortBy?: SORTABLE_FIELD;
  private stateKey?: StateKey;
  private withAdvanced?: boolean;
  private stateService?: StateService;
  protected defaultSort: SortBy<SORTABLE_FIELD>;

  protected searchService?: AdvancedSearchService;
  private readonly changesSubject = new Subject<void>();
  private readonly alive = new Subject<void>();

  @OnChange('onPropertyChange') flagged?: boolean;
  @OnChange('onPropertyChange') flaggedOnTop?: boolean;
  @OnChange('onPropertyChange') from?: Date;
  @OnChange('onPropertyChange') pinned?: boolean;
  @OnChange('onPropertyChange') pinnedOnTop?: boolean;
  @OnChange('onPropertyChange') snoozedOnTop?: boolean;
  @OnChange('onPropertyChange') followedOnTop?: boolean;
  @OnChange('onPropertyChange') sortOrder?: SortOrder;
  @OnChange('onPropertyChange') stitchedWith?: StitchedFilters;
  @OnChange('onPropertyChange') to?: Date;

  constructor({
    searchService,
    stateService,
    stateKey,
    withAdvanced,
    defaultSort,
  }: {
    searchService?: AdvancedSearchService;
    stateService?: StateService;
    stateKey?: StateKey;
    withAdvanced?: boolean;
    defaultSort: SortBy<SORTABLE_FIELD>;
  }) {
    this.stateKey = stateKey;
    this.stateService = stateService;
    this.withAdvanced = withAdvanced;
    this.searchService = searchService;
    this.defaultSort = defaultSort;

    if (!this.stateKey || !this.stateService) {
      this.applySavedState();
    } else {
      this.applySavedState(
        // stateService knows for StateKey not only BaseStitchListState-states, there may be generic data
        this.stateService.getStateSync(this.stateKey) as unknown as Like<BaseStitchListState<S, SORTABLE_FIELD>>,
      );
    }

    if (!withAdvanced) {
      return;
    }

    searchService
      .getState()
      .pipe(takeUntil(this.alive))
      .subscribe((state) => {
        this.syncFromAS(state);
      });
  }

  protected applySavedState(state?: Partial<BaseStitchListState<S, SORTABLE_FIELD>>) {
    this.to = state?.to ? new Date(state?.to) : null;
    this.sortBy = state?.sortBy ?? this.defaultSort.by;
    this.sortOrder = state?.sortOrder ?? this.defaultSort.order;

    this.flagged = state?.flagged || undefined;
    this.from = state?.from ? new Date(state?.from) : null;
    this.pinned = state?.pinned || undefined;

    //this.pinnedOnTop = state?.pinnedOnTop ?? true;
    this.pinnedOnTop = true; // always start with these values - that's the plan as for 20.01.2025
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop ?? true;
    this.followedOnTop = state?.followedOnTop ?? true;

    this.stitchedWith = {
      [StitchType.messageFolder]: !!state?.stitchedWith?.[StitchType.messageFolder],
      [StitchType.message]: !!state?.stitchedWith?.[StitchType.message],
      [StitchType.event]: !!state?.stitchedWith?.[StitchType.event],
      [StitchType.project]: !!state?.stitchedWith?.[StitchType.project],
      [StitchType.task]: !!state?.stitchedWith?.[StitchType.task],
      [StitchType.notebook]: !!state?.stitchedWith?.[StitchType.notebook],
      [StitchType.note]: !!state?.stitchedWith?.[StitchType.note],
      [StitchType.group]: !!state?.stitchedWith?.[StitchType.group],
      [StitchType.contact]: !!state?.stitchedWith?.[StitchType.contact],
      [StitchType.folder]: !!state?.stitchedWith?.[StitchType.folder],
      [StitchType.file]: !!state?.stitchedWith?.[StitchType.file],
    };
  }

  public isDefault(): boolean {
    return (
      !this.flagged &&
      !this.from &&
      !this.pinned &&
      !this.flaggedOnTop &&
      this.pinnedOnTop &&
      this.snoozedOnTop &&
      this.followedOnTop &&
      !this.stitchedWith[StitchType.messageFolder] &&
      !this.stitchedWith[StitchType.message] &&
      !this.stitchedWith[StitchType.event] &&
      !this.stitchedWith[StitchType.project] &&
      !this.stitchedWith[StitchType.task] &&
      !this.stitchedWith[StitchType.notebook] &&
      !this.stitchedWith[StitchType.note] &&
      !this.stitchedWith[StitchType.group] &&
      !this.stitchedWith[StitchType.contact] &&
      !this.stitchedWith[StitchType.folder] &&
      !this.stitchedWith[StitchType.file] &&
      !this.to &&
      this.sortBy === this.defaultSort.by &&
      this.sortOrder === this.defaultSort.order
    );
  }

  public resetToDefault() {
    this.applySavedState();
    this.syncState();
    this.notifyStateChanged();
  }

  public detach() {
    this.alive.next();
    this.alive.complete();
  }

  public changes(): Observable<void> {
    return this.changesSubject.asObservable();
  }

  public onPropertyChange(_attribute: keyof this): void {
    // nothing to do
  }

  public notifyStateChanged(): void {
    this.syncState();
    this.changesSubject.next();

    if (this.withAdvanced) {
      this.syncToAS();
    }
  }

  protected abstract syncToAS(): void;
  protected abstract syncFromAS(state: AdvancedSearchState): void;

  protected syncState(extendedProperties: any = {}) {
    if (!this.stateKey || !this.stateService) {
      return;
    }

    this.stateService.setState(this.stateKey, {
      flagged: this.flagged,
      flaggedOnTop: this.flaggedOnTop,
      from: this.from,
      pinned: this.pinned,
      pinnedOnTop: this.pinnedOnTop,
      snoozedOnTop: this.snoozedOnTop,
      followedOnTop: this.followedOnTop,
      sortOrder: this.sortOrder,
      stitchedWith: this.stitchedWith,
      to: this.to,
      ...extendedProperties,
    });
  }
}
