<div class="welcome-container" cdkScrollable>
  <div class="welcome">
    <div class="heading">Welcome to Stitch App!</div>
    <div class="heading2">Start your 14 days free trial. No credit card required.</div>

    <div class="buttons">
      <div
        class="buttons-item"
        [class.active]="activeForm === 'signup'"
        (click)="activeForm = 'signup'"
      >
        Sign Up
        <mat-icon svgIcon="red-underscore" class="buttons-item-underscore" />
      </div>
      <div
        class="buttons-item"
        [class.active]="activeForm === 'signin'"
        (click)="activeForm = 'signin'"
      >
        Sign In
        <mat-icon svgIcon="red-underscore" class="buttons-item-underscore" />
      </div>
    </div>

    <img class="image" src="assets/logo/welcome.png" alt="welcome logo image"/>

    <app-signin *ngIf="activeForm === 'signin'" class="content" />
    <app-signup *ngIf="activeForm === 'signup'" class="content" />

    <div class="footer">
      <div class="footer-text">© 2021 Stitch App. All rights reserved.</div>
      <div class="footer-space"></div>
      <div class="footer-text footer-link">Terms of service</div>
      <mat-icon
        class="footer-dot"
        svgIcon="mail-unread"
      />
      <div class="footer-text footer-link">Privacy policy</div>
    </div>
  </div>
</div>
