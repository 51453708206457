import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekend',
  pure: true,
  standalone: false,
})
export class WeekendPipe implements PipeTransform {
  transform(date: Date): boolean {
    if (!date) {
      return false;
    }

    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }
}
