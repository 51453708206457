// Common
import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { objectsEqual } from '../utils/object';

// Components
import { NestedItemsComponent } from '../components/nested-items/nested-items.component';

// Types
import { NestedItem } from '../types/nested-item';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { StateService } from '@modules/settings/services/state.service';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.mailSidebarFoldersTree]?: string[];
    [StateKey.filesSidebarFoldersTree]?: string[];
    [StateKey.notesSidebarNotebooksTree]?: string[];
    [StateKey.contactsSidebarGroupsTree]?: string[];
    [StateKey.taskingSidebarProjectsTree]?: string[];
    [StateKey.calendarSidebarCalendarsTree]?: string[];
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nestedFor]',
  standalone: false,
})
export class NestedForDirective implements OnInit, OnChanges, OnDestroy {
  @Input() nestedForOf: { id: string; parentId: string }[];
  @Input() nestedForStateKey: StateKey;

  private componentRef: ComponentRef<NestedItemsComponent>;
  private treeChanged = new Subject<void>();
  private expandedState: string[] = [];

  constructor(
    private templateRef: TemplateRef<NestedItemsComponent>,
    private viewContainerRef: ViewContainerRef,
    private stateService: StateService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.componentRef = this.viewContainerRef.createComponent(NestedItemsComponent);
    this.componentRef.instance.template = this.templateRef;

    this.syncTree();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('nestedForOf' in changes || 'nestedForStateKey' in changes) {
      this.syncTree();
    }
  }

  ngOnDestroy() {
    this.treeChanged.next();
    this.treeChanged.complete();
  }

  /**
   * Actions
   */

  syncTree() {
    if (!this.componentRef || !this.nestedForOf) {
      return;
    }

    this.treeChanged.next();

    this.expandedState = (this.stateService.getStateSync(this.nestedForStateKey) as string[]) || [];

    const [items] = this.syncBranch(null);

    this.componentRef.instance.tree = items;

    this.componentRef.instance.treeChanged.next();
  }

  syncState(id: string, expanded: boolean) {
    let newState = [...this.expandedState];

    if (expanded) {
      if (!newState.includes(id)) {
        newState.push(id);
      }
    } else {
      newState = newState.filter((item) => id !== item);
    }

    if (!objectsEqual(newState.sort(), this.expandedState.sort())) {
      this.expandedState = newState;
      this.stateService.setState(this.nestedForStateKey, newState);
    }
  }

  syncBranch(parentId: string, parentIndex = -1): [NestedItem[], number] {
    const result: NestedItem[] = [];
    let index = parentIndex;

    this.nestedForOf
      .filter((item) => item.parentId === parentId || (item.parentId === undefined && parentId === null))
      .forEach((item, position) => {
        index++;

        const [children, count] = this.syncBranch(item.id, index);

        const expanded = new BehaviorSubject<boolean>(this.expandedState.includes(item.id));

        expanded
          .pipe(
            map((value) => [item.id, value]),
            takeUntil(this.treeChanged),
          )
          .subscribe(([id, value]: [string, boolean]) => {
            this.syncState(id, value);
          });

        result.push({
          index,
          position,
          item,
          parentId: item.parentId,
          id: item.id,
          children,
          expanded,
        });

        index = count;
      });

    return [result, index];
  }
}
