<div
  class="input"
  [class.no-border]="noBorder"
  [class.focused]="focused"
  [class.inverted]="invertedColor"
  [ngClass]="appearance"
  [ngStyle]="containerStyles"
  stchPopover
  stchPopoverPlacement="bottomFullWidth"
  [stchPopoverPadding]="1"
  [stchPopoverContent]="optionsPopover"
  [stchPopoverCustomTrigger]="popoverCustomTrigger"
  [stchPopoverShowUntil]="popoverHide"
  [stchPopoverDisabled]="!suggestions"
  [stchPopoverCloseOnOutsideClick]="true"
  outsideClickExceptSelectors=".options"
>
  <div
    class="input-container"
    [class.with-clear]="withClear"
  >
    <div
      *ngIf="title"
      class="input-title"
      [class.focused]="focused || inputFormControl?.value?.length > 0 || innerControl.value?.length > 0"
    >
      {{ title }}
    </div>

    <div
      *ngFor="let item of selectedItems; let index = index"
      class="item"
      [ngClass]="appearance"
      [class.inverted]="invertedColor"
      [ngStyle]="itemStyles"

      appDraggable
      [appDraggableDisabled]="!optionsDraggable"
      [appDraggableData]="{data: [item], type: item.type}"
      [appDraggablePlaceholder]="selectedItemTemplate"
      [appDraggablePlaceholderContext]="{item}"
      [appDraggableNoShadow]="true"
      (appDraggableDropped)="handleDropped(item)"
    >

      <mat-icon
        *ngIf="icon  || item.valueIcon"
        class="item-icon"
        [svgIcon]="icon  || item.valueIcon"
        [style.color]="iconColor"
      />

      <div
        class="item-title"
        [style.color]="itemStyles?.color"
      >
        @if (itemTitlePattern) {
          {{ itemTitlePattern | replace : '$title' : item.title }}
        } @else {
          {{ item.title }}
        }
      </div>

      <mat-icon
        class="item-close-icon"
        [svgIcon]="appearance === 'default' ? 'close-small' : 'close-thin'"
        (click)="removeItem(item)"
      />
    </div>

    <input
      #inputElement
      class="input-input"
      [formControl]="innerControl"
      [placeholder]="placeholder"
      autocomplete="off"
      (focus)="handleFocus()"
      (blur)="handleBlur()"
      (click)="handleClick()"
      (keydown)="handleKeydown($event)"
    />

    <mat-icon
      *ngIf="loading"
      class="input-loading-icon"
      svgIcon="loading"
    />

    <mat-icon
      *ngIf="!loading && withClear && inputFormControl?.value?.length !== 0"
      class="input-clear-icon"
      svgIcon="close-thin"
      (click)="handleClear()"
    />
  </div>

  <div
    *ngIf="sideOptions"
    class="input-side"
    stchPopover
    stchPopoverPlacement="bottomRight"
    stchPopoverTrigger="click"
    [stchPopoverContent]="sideOptionsPopover"
    [stchPopoverShowUntil]="sidePopoverHide"
  >
    <div class="input-side-separator"></div>
    <div class="input-side-input">{{ selectedSideOption?.title }}</div>

    <mat-icon
      class="input-side-icon"
      [svgIcon]="appearance === 'amethyst' ? 'dropdown-new' : 'dropdown-down'"
    />
  </div>
</div>

<ng-template #optionsPopover>
  <stch-dropdown-container [appearance]="appearance" (mousedown)="onAutocompleteClick()">
    @for(option of options; track option.value) {
      <app-dropdown-item
        [appearance]="appearance"
        [option]="option"
        [itemTemplate]="itemTemplate"
        [active]="option.value === inputFormControl?.value"
        (click)="handleSelect(option)"
      />
    } @empty {
      <div class="options-placeholder">
        No items matched your search for<br />“{{ innerControl.value }}…”.
      </div>
    }
  </stch-dropdown-container>
</ng-template>

<ng-template #sideOptionsPopover>
  <div
    class="simple-input-options options"
    [ngClass]="appearance"
  >
    <div
      *ngFor="let option of sideOptions"
      class="options-item"
      [class.active]="option.value === selectedSideOption?.value"
      (click)="handleSideSelect(option)"
    >
      <mat-icon class="options-item-icon" *ngIf="option.icon" [svgIcon]="option.icon" />
      {{ option.title }}
    </div>
  </div>
</ng-template>

<ng-template #selectedItemTemplate let-item="item">
  <div
    class="item"
    [ngClass]="appearance"
    [class.inverted]="invertedColor"
    [ngStyle]="itemStyles"
  >
    <div
      class="item-title"
      [style.color]="itemStyles?.color"
    >
      @if (itemTitlePattern) {
        {{ itemTitlePattern | replace : '$title' : item?.title }}
      } @else {
        {{ item?.title }}
      }
    </div>
  </div>
</ng-template>
