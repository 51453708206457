import { Inject, Injectable } from '@angular/core';
import { ListStateService } from '@modules/common/components/list/list-state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import {
  MessageFolderListStateServiceConfig,
  MessageFolderListStateServiceConfigToken,
} from '../types/MessageFolderListStateServiceConfig';
import { FoldersListState } from '../types/folders-list-state';
import { type MessageFolder } from '../types/message-folder';
import { StateKey } from '@modules/settings/types/state-key';

@Injectable()
export class MessageFolderListStateService extends ListStateService<MessageFolder, FoldersListState> {
  public override readonly state: FoldersListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(MessageFolderListStateServiceConfigToken) config: MessageFolderListStateServiceConfig,
  ) {
    super();

    this.state = new FoldersListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch,
    });
  }

  public static providers(config: MessageFolderListStateServiceConfig = {}): any[] {
    return super.prepareProviders(MessageFolderListStateServiceConfigToken, config);
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.messagesFoldersListState]?: FoldersListState;
    [StateKey.sidebarMessagesFoldersListState]?: FoldersListState;
    [StateKey.messagesFoldersDPListState]?: FoldersListState;
  }
}
