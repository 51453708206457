import { inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { CSRF_HEADER_NAME, CSRF_TOKEN_KEY } from '@modules/auth/types/token';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq = request;
    const csrfToken = localStorage.getItem(CSRF_TOKEN_KEY);
    if (request.url.startsWith(environment.baseUrl)) {
      authReq = request.clone({
        withCredentials: true,
        setHeaders: { [CSRF_HEADER_NAME]: csrfToken ?? '' },
      });
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.authService.setAuthPayload(null, null);
          this.router.navigate(['/welcome']);
        }
        return throwError(() => error);
      }),
    );
  }
}
