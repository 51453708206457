<ng-container *ngIf="step === 1">
  <app-input
    class="input input-large"
    [inputFormControl]="form1.controls.userName"
    placeholder="Your Username"
    sideText="@stitch.ai"
    [withErrors]="submitted"
    [validationMessages]="{
      required: 'Please choose a Stitch address',
      unique: 'That username is taken, try another. %m is available',
      pattern: 'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed'
    }"
  />

  <app-input
    class="input input-large"
    [inputFormControl]="form1.controls.recoveryEmail"
    placeholder="Your Recovery Email"
    [withErrors]="submitted"
    [validationMessages]="{
      required: 'Please enter your recovery email',
      email: 'Email is not valid'
    }"
  />

  <div class="row">
    <app-input
      class="input"
      [inputFormControl]="form1.controls.firstName"
      placeholder="First Name"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Please enter a name'
      }"
    />

    <app-input
      class="input"
      [inputFormControl]="form1.controls.lastName"
      placeholder="Last Name"
    />
  </div>

  <div class="row">
    <app-input
      class="input"
      [inputFormControl]="form1.controls.password"
      placeholder="Password"
      [password]="true"
      hint="8 characters minimum"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Please enter a password',
        weekPassword: 'Please choose a stronger password.'
      }"
    />

    <app-input
      class="input"
      [inputFormControl]="form1.controls.passwordConfirmation"
      placeholder="Confirm"
      [password]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        notEqual: 'Passwords do not match'
      }"
    />
  </div>

  <div
    class="button"
    [class.loading]="loading"
    (click)="goToStep2()"
  >
    Next
  </div>
</ng-container>

<ng-container *ngIf="step === 2">
  <div class="heading">Your Birthday</div>

  <app-date-inline-input
    [inputFormControl]="form2.controls.birthDate"
  />

  <div class="heading">What industry are you in?</div>

  <app-autocomplete-input
    class="autocomplete"
    label="Type to search"
    [inputFormControl]="form2.controls.industry"
    [suggestions]="suggestions"
  />

  <div class="heading">How did you hear about Stitch?</div>

  <app-radio-button
    *ngFor="let source of sources"
    class="radio-button"
    [value]="source"
    appearance="radio"
    [title]="source"
    [inputFormControl]="form2.controls.from"
  />

  <app-radio-button
    class="checkbox"
    appearance="checkbox"
    [booleanValue]="true"
    title="I would like to hear about Stitch updates and special offers."
    [inputFormControl]="form2.controls.newsletter"
  />

  <app-radio-button
    class="checkbox"
    appearance="checkbox"
    [booleanValue]="true"
    title="I accept the Terms of use and Subscription"
    [inputFormControl]="form2.controls.termsAccepted"
    [withErrors]="submitted"
    [validationMessages]="{
      required: 'You must accept terms and conditions'
    }"
  />

  <div
    class="button"
    (click)="handleSubmit()"
  >
    Start My Trial
  </div>
</ng-container>
