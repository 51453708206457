<div
  class="app-time-input"
  [class.app-form-controls-input-outline]="appearance === 'outline'"
  [class.app-form-controls-input-standard]="appearance === 'standard'"
  stchPopover
  [stchPopoverContent]="dropdownContent"
  stchPopoverTrigger="click"
  [stchPopoverCustomTrigger]="popoverCustomTrigger"
  [stchPopoverInnerShadow]="true"
  stchPopoverPlacement="bottom"
  [stchPopoverShowUntil]="popoverHide"
  (stchPopoverVisibleChange)="handlePopoverVisible($event)"
>
  <mat-form-field
    [appearance]="appearance"
    [style.width]="width || '100%'"
    [floatLabel]="appearance === 'outline' ? 'auto' : 'never'"
  >
    <mat-label *ngIf="appearance === 'outline'">{{ placeholder }}</mat-label>
    <app-time-input
      [formControl]="inputFormControl"
      [bundledInputConsumerKeys]="bundledInputConsumerKeys"
      [bundledInputAppearance]="bundledInputAppearance"
      [bundledInputConsumerGroup]="bundledInputConsumerGroup"
      [bundledInputFormControl]="inputFormControl"
      [bundledInputInvisible]="bundledInputInvisible"
      (focus)="popoverCustomTrigger.next()"
      (blur)="popoverHide.next()"
    />
  </mat-form-field>
</div>

<ng-template #dropdownContent>
  <div class="dropdown" #dropdown>
    <div
      class="dropdown-option"
      *ngFor="let option of dropdownOptions"
      (click)="handleOptionClick(option.value)"
    >
      {{ option.value | date : 'h:mm a'}} {{ option.difference }}
    </div>
  </div>
</ng-template>
