export enum StitchType {
  messageFolder = 'message-folder',
  message = 'message',
  calendar = 'calendar',
  event = 'event',
  project = 'project',
  task = 'task',
  notebook = 'notebook',
  note = 'note',
  group = 'group',
  contact = 'contact',
  folder = 'folder',
  file = 'file',
}
