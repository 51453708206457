import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';

export interface StitchFiltersHistoryRecord {
  tags: Tag[];
  knots: Knot[];
  connections: Connection[];
}

@Injectable({ providedIn: 'root' })
export class HistoryService {
  private readonly historySubject = new BehaviorSubject<StitchFiltersHistoryRecord>({
    tags: [],
    knots: [],
    connections: [],
  });

  public onChanges(): Observable<StitchFiltersHistoryRecord> {
    return this.historySubject.asObservable().pipe(distinctUntilChanged());
  }

  public getCurrentHistory(): StitchFiltersHistoryRecord {
    return this.historySubject.value;
  }

  public updateHistory(history: StitchFiltersHistoryRecord) {
    this.historySubject.next(history);
  }

  public resetHistory() {
    this.historySubject.next({ tags: [], knots: [], connections: [] });
  }
}
