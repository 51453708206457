// Common
import { Component, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Types
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { StateKey } from '@modules/settings/types/state-key';
import { Project } from '@modules/tasks/types/project';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';
import { ProjectsListState } from '@modules/tasks/types/projects-list-state';
import { Task } from '@modules/tasks/types/task';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';

// Services
import { TaskingAppStateService } from '@modules/tasks/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';

@Component({
  selector: 'app-side-bar-projects',
  templateUrl: './side-bar-projects.component.html',
  styleUrls: [
    '../../../common/components/base-sidebar-containers-item/base-sidebar-containers-item.component.less',
    './side-bar-projects.component.less',
  ],
  animations: [heightAnimation],
  standalone: false,
})
export class SideBarProjectsComponent
  extends BaseSidebarContainersItemComponent<Project, Task, ProjectsFilters, VirtualFolder, ProjectsListState>
  implements OnInit
{
  application = Application.tasking;
  sidebarSplitViewKey = SidebarSplitViewKey.tasking;
  listStateKey = StateKey.sidebarProjectsListState;

  constructor(tasksStateService: TaskingAppStateService) {
    super(tasksStateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.itemsListState.pipe(takeUntil(this.alive)).subscribe((state) => {
      this.filters = new ProjectsFilters().applyListState(state);
    });
  }
}
