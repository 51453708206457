<div class="panel" *ngIf="!minimized">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <div class="panel-heading-top-title">
        {{ sidebarFilter | titleByNotesFilter }}
      </div>

      <div class="panel-heading-top-separator"></div>

      <div
        class="debug"
        [class.active]="debug"
        (click)="doDebug()"
      >
        {{ debug || 'Debug' }}
      </div>

      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'notes'"
          svgIcon="filters"
          appNotesListContextMenu
          [appNotesListContextMenuWithAdvancedSearch]="true"
        />

        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'notebooks'"
          svgIcon="filters"
          appNotebooksListContextMenu
          [appNotebooksListContextMenuWithAdvancedSearch]="true"
        />
      </div>
    </div>

    <app-tabs
      *ngIf="listState === 'tabs'"
      class="panel-heading-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
    />
  </div>

  <app-quick-form
    *ngIf="
      selectedTab === 'notes' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Note"
  >
    <app-note-quick-form
      [note]="quickNote"
      [autofocus]="true"
      (more)="handleNewNote($event)"
    />
  </app-quick-form>

  <app-quick-form
    *ngIf="
      selectedTab === 'notebooks' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Notebook"
  >
    <app-notebook-quick-form
      [notebook]="quickNotebook"
      [autofocus]="true"
      (more)="handleNewNotebook($event)"
    />
  </app-quick-form>

  <div class="panel-list">
    <app-notes-list
      *ngIf="selectedTab === 'notes'"
      [filters]="notesFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openNote)="handleClickNote($event)"
      (clickNote)="handleClickNote($event)"
      (doubleClickNote)="handleDoubleClickNote($event)"
    />

    <app-notebooks-list
      *ngIf="selectedTab === 'notebooks'"
      [filters]="notebooksFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openNotebook)="handleClickNotebook($event)"
      (openNote)="handleClickNote($event)"
      (clickNotebook)="handleClickNotebook($event)"
      (doubleClickNotebook)="handleDoubleClickNotebook($event)"
    />
  </div>
</div>
