// Common
import { Injectable } from '@angular/core';

// Utils
import { isNil } from '@modules/common/utils/base';

// RX
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

// Types
import { SelectableItem } from '../types/selectable-item';

@Injectable()
export class SelectableService {
  // Private
  private selectedItems = new BehaviorSubject<SelectableItem[]>([]);
  private selectAllTrigger = new Subject<void>();
  private readonly update = new Subject<void>();

  // Public
  public items: SelectableItem[] = [];

  /**
   * Actions
   */

  setSelectedItems(items: SelectableItem[]) {
    this.selectedItems.next(items);
    this.fillPositions();
  }

  getSelectedItems(): Observable<unknown[]> {
    return this.selectedItems.pipe(
      map((items) => items?.map(({ data }) => data)),
      startWith([null, []]),
      pairwise(),
      filter(([prev, curr]) => prev?.sort()?.join() !== curr.sort().join()),
      map(([_prev, curr]) => curr),
    );
  }

  fillPositions() {
    let changed = false;

    const filledItems = this.selectedItems.value.map((item) => {
      if (isNil(item.position) && !isNil(item.identifier)) {
        const existingItem = this.items.find((i) => i.identifier === item.identifier);
        if (existingItem) {
          changed = true;
          return { ...item, position: existingItem.position };
        } else {
          return item;
        }
      } else {
        return item;
      }
    });

    if (changed) {
      this.selectedItems.next(filledItems);
    }
  }

  triggerUpdate() {
    this.update.next();
  }

  getUpdate(): Observable<void> {
    return this.update.asObservable();
  }

  getDragData(fallback: unknown, position: number): Observable<unknown[]> {
    return this.selectedItems.pipe(
      map((selectedItems) => {
        if (
          position !== null &&
          position !== undefined &&
          selectedItems.some((selected) => selected.position === position)
        ) {
          return selectedItems.map((item) => item.data);
        } else {
          return [fallback];
        }
      }),
    );
  }

  getSelectedItemsSync(): SelectableItem[] {
    return this.selectedItems.value;
  }

  getSelected(position: number): Observable<boolean> {
    return this.selectedItems.pipe(map((items) => items.some((item) => item.position === position)));
  }

  triggerSelectAll() {
    this.selectAllTrigger.next();
  }

  getSelectAll() {
    return this.selectAllTrigger.asObservable();
  }
}
