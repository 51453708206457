<div class="heading">
  <div class="heading-grow">
    <div class="heading1">Subscription Plans</div> 
    <div class="heading2-subtitle">Spaces helps bring people together so that they can get things done.</div>
  </div>

  <app-button
    icon="plus-medium"
    label="ADD SUBSCRIPTION PLAN"
    appearance="link-blue"
    (click)="openForm()"
  />
</div>

<stch-table
  class="items"
  [items]="plans"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
/>

<ng-template #currencyTemplate let-value=value let-plan=item>
  {{ value | currency : (plan.currency | uppercase) }}
</ng-template>

<ng-template #dateTemplate let-plan=item>
  {{ plan.createdAt | date : 'mediumDate' }}
</ng-template>

<ng-template #suspendedTemplate let-plan=item>
  <app-toggle-switch
    [value]="!plan.suspended"
    (onChange)="suspend(plan)"
  />
  &nbsp;{{ plan.suspended ? 'Suspended' : 'Active' }}
</ng-template>

<ng-template #actionsTemplate let-plan=item>
  <mat-icon
    class="icon"
    svgIcon="sapphire-trash"
    matTooltip="Delete"
    (click)="removePlanPrompt($event, plan)"
  />
</ng-template>
