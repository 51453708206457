// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Types
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';
import { ListState } from '@modules/linked-info/types/list-state';
import { ViewType } from '@modules/linked-info/types/view-type';

@Component({
  selector: 'app-stitch-list-context-menu',
  templateUrl: './stitch-list-context-menu.component.html',
  styleUrls: ['./stitch-list-context-menu.component.less'],
  standalone: false,
})
export class StitchListContextMenuComponent {
  // Input
  @Input() state: ListState;
  @Input() withStitchType = true;

  // Outputs
  @Output() change = new EventEmitter<ListState>();

  /**
   * Actions
   */

  sort(sortBy: 'title' | 'date') {
    let order = this.state.sort.order;
    let by = this.state.sort.by;

    if (this.state.sort.by === sortBy) {
      order = this.state.sort.order === 'asc' ? 'desc' : 'asc';
    } else {
      by = sortBy;
      order = 'asc';
    }

    this.change.emit({
      ...this.state,
      sort: { by, order },
    });
  }

  flag() {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        flagged: !this.state.filters.flagged,
      },
    });
  }

  stitch(stitch: StitchTypeFilters) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        stitch,
      },
    });
  }

  view(view: ViewType) {
    this.change.emit({
      ...this.state,
      view,
    });
  }
}
