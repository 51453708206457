// Utils
import { isNil } from '../utils/base';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Filters } from '@modules/common/types/filters';
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { StitchedFilters } from '@modules/linked-info/types/stitched-filters';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Tag } from '@modules/tags/types/tag';
import { BaseVirtualFolder } from './base-virtual-folder';
import { Like } from './like';
import { StitchType } from './stitch-type';
import { Stitch } from './stitch';

export abstract class StitchFilters<S extends Stitch> extends Filters {
  // Filters
  archived?: boolean;
  connections?: Connection[];
  connections_boost?: number;
  deleted?: boolean;
  flagged?: boolean;
  followed?: boolean;
  knots?: Knot[];
  knots_boost?: number;
  noParent?: boolean;
  parentsIds?: string[];
  pinned?: boolean;
  query?: string | string[];
  query_boost?: number;
  shared?: boolean;
  snoozed?: boolean;
  stitch?: StitchedFilters;
  tags?: Tag[];
  tags_boost?: number;

  // Sort Options
  abstract sortBy?: keyof S | string;
  pinnedOnTop?: boolean;
  flaggedOnTop?: boolean;
  snoozedOnTop?: boolean;
  followedOnTop?: boolean;

  // ElasticSearch fine tuning
  esAnalyzer?: 'standard' | 'english' | 'ngrams_7';
  esPriority?: 'title' | 'body' | 'none';
  esMultiMatchType?: 'best_fields' | 'most_fields' | 'cross_fields' | 'phrase';
  esTitle?: string;
  esBody?: string;

  constructor(filters?: Like<StitchFilters<S>>) {
    super(filters);
    this.archived = filters?.archived;
    this.connections = filters?.connections;
    this.connections_boost = filters?.connections_boost;
    this.deleted = filters?.deleted;
    this.esAnalyzer = filters?.esAnalyzer;
    this.esBody = filters?.esBody;
    this.esMultiMatchType = filters?.esMultiMatchType;
    this.esPriority = filters?.esPriority;
    this.esTitle = filters?.esTitle;
    this.flagged = filters?.flagged;
    this.flaggedOnTop = filters?.flaggedOnTop;
    this.followed = filters?.followed;
    this.followedOnTop = filters?.followedOnTop;
    this.knots = filters?.knots;
    this.knots_boost = filters?.knots_boost;
    this.noParent = filters?.noParent;
    this.parentsIds = filters?.parentsIds;
    this.pinned = filters?.pinned;
    this.pinnedOnTop = filters?.pinnedOnTop;
    this.query = filters?.query;
    this.query_boost = filters?.query_boost;
    this.shared = filters?.shared;
    this.snoozed = filters?.snoozed;
    this.snoozedOnTop = filters?.snoozedOnTop;
    this.stitch = filters?.stitch;
    this.tags = filters?.tags;
    this.tags_boost = filters?.tags_boost;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = {};

    if (this.ids) {
      result['ids[]'] = this.ids;
    }
    if (this.exceptIds) {
      result['except_ids[]'] = this.exceptIds;
    }

    if (!isNil(this.archived)) {
      result['archived'] = this.archived;
    }
    if (!isNil(this.deleted)) {
      result['deleted'] = this.deleted;
    }
    if (!isNil(this.pinned)) {
      result['pinned'] = this.pinned;
    }
    if (!isNil(this.flagged)) {
      result['flagged'] = this.flagged;
    }
    if (!isNil(this.followed)) {
      result['followed'] = this.followed;
    }
    if (!isNil(this.snoozed)) {
      result['snoozed'] = this.snoozed;
    }
    if (!isNil(this.shared)) {
      result['shared'] = this.shared;
    }
    if (this.parentsIds) {
      result['parents_ids[]'] = this.parentsIds;
    }
    if (!isNil(this.noParent)) {
      result['no_parent'] = this.noParent + '';
    }

    if (this.stitch?.[StitchType.messageFolder]) {
      result['stitch_message_folders'] = this.stitch[StitchType.messageFolder];
    }
    if (this.stitch?.[StitchType.message]) {
      result['stitch_messages'] = this.stitch[StitchType.message];
    }
    if (this.stitch?.[StitchType.event]) {
      result['stitch_events'] = this.stitch[StitchType.event];
    }
    if (this.stitch?.[StitchType.project]) {
      result['stitch_projects'] = this.stitch[StitchType.project];
    }
    if (this.stitch?.[StitchType.task]) {
      result['stitch_tasks'] = this.stitch[StitchType.task];
    }
    if (this.stitch?.[StitchType.notebook]) {
      result['stitch_notebooks'] = this.stitch[StitchType.notebook];
    }
    if (this.stitch?.[StitchType.note]) {
      result['stitch_notes'] = this.stitch[StitchType.note];
    }
    if (this.stitch?.[StitchType.group]) {
      result['stitch_groups'] = this.stitch[StitchType.group];
    }
    if (this.stitch?.[StitchType.contact]) {
      result['stitch_contacts'] = this.stitch[StitchType.contact];
    }
    if (this.stitch?.[StitchType.folder]) {
      result['stitch_folders'] = this.stitch[StitchType.folder];
    }
    if (this.stitch?.[StitchType.file]) {
      result['stitch_files'] = this.stitch[StitchType.file];
    }

    if (this.query) {
      if (Array.isArray(this.query)) {
        result['query[]'] = this.query;
      } else if (this.query?.trim().length > 0) {
        result['query'] = this.query;
      }
      if (!isNil(this.query_boost)) {
        result['query_boost'] = this.query_boost;
      }
    }

    if (this.knots && this.knots.length) {
      result['knots[]'] = this.knots.map((k) => k.name);
      if (!isNil(this.knots_boost)) {
        result['knots_boost'] = this.knots_boost;
      }
    }
    if (this.tags && this.tags.length) {
      result['tags[]'] = this.tags.map((t) => t.name);
      if (!isNil(this.tags_boost)) {
        result['tags_boost'] = this.tags_boost;
      }
    }
    if (this.connections && this.connections.length) {
      result['connections[]'] = this.connections.map((c) => c.name);
      if (!isNil(this.connections_boost)) {
        result['connections_boost'] = this.connections_boost;
      }
    }

    if (this.limit) {
      result['limit'] = this.limit;
    }
    if (this.offset) {
      result['offset'] = this.offset + '';
    }

    result['sort_order'] = this.sortOrder || 'asc';

    if (this.sortBy !== 'score') {
      // score sorting is controlled by ElasticSearch and doesn't support db-specific (unknown for ES) pinned flags
      if (this.pinnedOnTop) {
        result['pinned_on_top'] = true;
      }
      if (this.flaggedOnTop) {
        result['flagged_on_top'] = true;
      }
      if (this.snoozedOnTop || this.followedOnTop) {
        result['snoozed_on_top'] = this.snoozedOnTop;
        result['followed_on_top'] = this.followedOnTop;
        result['postponed_on_top_till'] = new Date().toISOString();
      }
    }

    if (this.esAnalyzer) {
      result['es_analyzer'] = this.esAnalyzer;
    }
    if (this.esPriority) {
      result['es_priority'] = this.esPriority;
    }
    if (this.esMultiMatchType) {
      result['es_multi_match_type'] = this.esMultiMatchType;
    }
    if (this.esTitle) {
      result['es_title'] = this.esTitle;
    }
    if (this.esBody) {
      result['es_body'] = this.esBody;
    }

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    this.query = advanced?.query;

    this.knots = [].concat(this.knots, advanced?.knots).filter((i) => !!i);

    this.tags = [].concat(this.tags, advanced?.tags).filter((i) => !!i);

    this.connections = [].concat(this.connections, advanced?.connections).filter((i) => !!i);

    this.esAnalyzer = advanced?.analyzer;
    this.esPriority = advanced?.priority;
    this.esMultiMatchType = advanced?.multiMatchType;

    return this;
  }

  abstract applyListState(state: BaseStitchListState<S, unknown>): this;

  abstract applyVirtualFolder(folder: BaseVirtualFolder): this;
}
