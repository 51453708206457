<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Files"
  [active]="filterKey === 'all_files'"
  (execute)="selectFilter('all_files')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Flagged"
  [active]="filterKey === 'flagged'"
  (execute)="selectFilter('flagged')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Recent"
  [active]="filterKey === 'recent'"
  (execute)="selectFilter('recent')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Follow Up"
  [active]="filterKey === 'followed'"
  (execute)="selectFilter('followed')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Archive"
  [active]="filterKey === 'archived'"
  (execute)="selectFilter('archived')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Trash"
  [active]="filterKey === 'deleted'"
  (execute)="selectFilter('deleted')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Folders"
  [active]="filterKey === 'all_folders'"
  (execute)="selectFilter('all_folders')"
/>
