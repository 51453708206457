import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plainText',
  standalone: false,
})
export class PlainTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    return value.replace(/<\/?[^>]+(>|$)/g, '');
  }
}
