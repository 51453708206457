
<app-context-menu-item
  [withCheckOption]="true"
  title="By Date"
  [active]="state?.sort?.by === 'date'"
  [sortable]="true"
  [reversed]="state?.sort?.order === 'desc'"
  (execute)="sort('date')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  title="Alphabetical"
  [active]="state?.sort?.by === 'title'"
  [sortable]="true"
  [reversed]="state?.sort?.order === 'desc'"
  (execute)="sort('title')"
/>

<app-context-menu-divider />

<app-context-menu-item
  [withCheckOption]="true"
  title="Flagged"
  [active]="state?.filters?.flagged"
  (execute)="flag()"
/>

<app-stitch-type-context-menu-item
  *ngIf="withStitchType"
  [withCheckOption]="true"
  title="Stitched Info"
  [state]="state?.filters?.stitch"
  (onSelect)="stitch($event)"
/>

<app-context-menu-divider />

<app-context-menu-item
  [withCheckOption]="true"
  title="View"
>
  <app-context-menu-item
    [withCheckOption]="true"
    title="Full View"
    [active]="state?.view === 'full'"
    (execute)="view('full')"
  />

  <app-context-menu-item
    [withCheckOption]="true"
    title="Min View"
    [active]="state?.view === 'short'"
    (execute)="view('short')"
  />
</app-context-menu-item>
