// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Application } from '@modules/common/types/application';
import { PostponeType } from '@modules/common/types/postpone-type';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-message-folder-context-menu',
  templateUrl: './message-folder-context-menu.component.html',
  styleUrls: [
    '../stitch-context-menu/stitch-context-menu.component.less',
    './message-folder-context-menu.component.less',
  ],
  standalone: false,
})
export class MessageFolderContextMenuComponent
  extends StitchContextMenuComponent<MessageFolder>
  implements OnInit, OnChanges
{
  @Input() folder: MessageFolder;

  applicationName = Application.mail;
  gaPrefix = 'message-folder-context-menu';
  public form: UntypedFormGroup;

  constructor(
    private foldersService: MessageFoldersService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, foldersService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.folder;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('folder' in changes) {
      this.item = this.folder;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.foldersService
      .update(MessageFolder.fromFormGroup(this.form))
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.folder || new MessageFolder()).asFormGroup();
  }

  public duplicate() {
    if (!this.folder) {
      return;
    }

    const foldersToDuplicate = this.multiple ? this.selectedItems : [this.folder];

    combineLatest(
      foldersToDuplicate.map((folder) => this.foldersService.create(folder, { emit: false, displayToast: false })),
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService.show({
          text: `Folder${this.multiple ? 's have' : ' has'} been duplicated`,
        });
        this.foldersService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.foldersService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(
      `When deleting, all messages in ${this.multiple ? 'these' : 'this'} folder${this.multiple && 's'} will be removed also`,
    );
  }
}
