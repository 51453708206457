// Common
import { Component, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { EventsService } from '@modules/calendar-app/services/events.service';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// RX
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './event.component.less'],
  standalone: false,
})
export class EventComponent extends BaseStitchComponent<CalendarEvent> {
  public form: UntypedFormGroup;

  moveDragDataTypes = [];
  dragDataType = DragDataTypes.event;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(dragData.type === DragDataTypes.event && dragData.data.length === 1 && dragData.data[0]['id'] === this.item.id) &&
    dragDataTypeAllowed(dragData.type);

  constructor(
    injector: Injector,
    protected eventsService: EventsService,
  ) {
    super(injector, eventsService);

    this.changed
      .pipe(
        tap(() => (this.form = (this.item || new CalendarEvent()).asFormGroup())),
        switchMap(() => this.form.valueChanges),
        debounceTime(200),
        switchMap(() => this.eventsService.update(CalendarEvent.fromFormGroup(this.form), { emit: false })),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  public handleMove(_dragData: DragData) {
    // TODO
  }

  get event(): CalendarEvent {
    return this.item;
  }
}
