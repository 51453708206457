// Common
import { Component, Input, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Types
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';
import { MessagesCounts } from '@modules/messages/types/messages-counts';

@Component({
  selector: 'app-side-bar-folders-tree',
  templateUrl: './side-bar-folders-tree.component.html',
  styleUrls: ['./side-bar-folders-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService],
  standalone: false,
})
export class SideBarFoldersTreeComponent
  extends BaseSidebarContainersTreeComponent<MessageFolder, Message, FoldersFilters, VirtualFolder>
  implements OnInit
{
  @Input() messagesCount: MessagesCounts;

  selfDragDataTypes = [DragDataTypes.message, DragDataTypes.messageFolder];
  application = Application.mail;
  treeStateKey = StateKey.mailSidebarFoldersTree;

  public getDndModalButtons(dragData: DragData, item: MessageFolder) {
    return [
      {
        title: 'Cancel',
        close: true,
      },
      {
        title: 'Move',
        click: () => {
          this.handleMove(dragData, item);
        },
        close: true,
      },
      {
        title: 'Stitch',
        click: () => {
          this.handleStitch(dragData, item);
        },
        close: true,
      },
    ];
  }

  dndPredicate =
    (stitchItem: Stitch) =>
    (dragData: DragData): boolean =>
      stitchItem &&
      !(
        dragData.type === DragDataTypes.messageFolder &&
        dragData.data.length === 1 &&
        dragData.data?.[0]?.id === stitchItem?.id
      ) &&
      dragDataTypeAllowed(dragData.type);

  constructor(
    private foldersService: MessageFoldersService,
    private messagesService: MessagesService,
    mailAppStateService: MailAppStateService,
  ) {
    super(foldersService, mailAppStateService);
  }

  /**
   * Actions
   */

  public handleMove(dragData: DragData, item: MessageFolder) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.message) {
      this.messagesService.bunchUpdate({ ids: this.getIds(dragData.data) }, { folderId: item.id }, { message });
    } else if (dragData.type === DragDataTypes.messageFolder) {
      this.foldersService.bunchUpdate({ ids: this.getIds(dragData.data) }, { parentId: item.id }, { message });
    }
  }

  public handleDuplicate(_dragData: DragData, _item: MessageFolder) {
    // For V1 we don't need duplicate logic for emails
  }
}
