// Common
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Space } from '@modules/settings/types/space';
import { Team } from '@modules/settings/types/team';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// RX
import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Services
import { SpacesService } from '@modules/settings/services/spaces.service';
import { TeamsService } from '@modules/settings/services/teams.service';

@Component({
  selector: 'app-settings-spaces-team-form',
  templateUrl: './team-form.component.html',
  styleUrls: ['./team-form.component.less'],
  standalone: false,
})
export class TeamFormComponent implements OnInit, OnDestroy {
  @Input() team = new Team();

  public form: FormGroup;
  public submitted = false;
  public spaceSuggestions: AutocompleteFactory<Space>;

  private alive = new Subject<void>();

  constructor(
    @Inject(CloseToken) private closeToken,
    private spacesService: SpacesService,
    private teamsService: TeamsService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.form = this.team.asFormGroup();

    this.spacesService
      .findAll()
      .pipe(takeUntil(this.alive))
      .subscribe((spaces) => {
        this.spaceSuggestions = (title, values) =>
          of(
            spaces
              .filter((space) => !title || space.title.includes(title))
              .filter((space) => !values || values.includes(space.id))
              .map(({ title, id }) => ({ title, value: id })),
          );
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const item = Team.fromFormGroup(this.form);

    this.teamsService
      .upsert(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.closeToken.next();
      });
  }
}
