// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Upload } from '@modules/common/types/upload';
import { ListState as StitchListState } from '@modules/linked-info/types/list-state';

// RX
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

// Services

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrl: './attachments-list.component.less',
  standalone: false,
})
export class AttachmentsListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() contextMenuStateVariant: string;
  @Input() stitchItem: Stitch;
  @Input() inputControl: FormControl<Upload[]>;

  @Output() onClickItem = new EventEmitter<Stitch>();
  @Output() onDblClickItem = new EventEmitter<Stitch>();

  public allowedStitchTypes = [StitchType.file];
  public listState: StitchListState;

  private stitchItemChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(private stitchServiceFactory: StitchServiceFactory) {}

  ngOnInit(): void {
    this.stitchItemChanged
      .pipe(
        switchMap(() => this.inputControl.valueChanges),
        filter(() => !!this.stitchItem.id),
        filter((uploads) => !!uploads?.length),
        takeUntil(this.alive),
      )
      .subscribe((uploads) => {
        this.stitchServiceFactory
          .getServiceByStitchType(this.stitchItem.getStitchType())
          .processUploads(uploads, this.stitchItem, {})
          .subscribe(() => this.inputControl.reset());
      });

    this.stitchItemChanged.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stitchItem' in changes) {
      this.stitchItemChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
