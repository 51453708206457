import { Notification } from '@modules/notification/types/notification';
import { StitchType } from '@modules/common/types/stitch-type';

export enum SignalEnum {
  RELOAD_PROJECT_BOARD = 'reload-project-board',
  NEW_NOTIFICATION = 'new-notification',
  STITCH_EVENT = 'stitch-event',
}

export interface Signal {
  spaceId: string;
  type: SignalEnum;
}

export interface SignalReloadProjectBoard extends Signal {
  containerId?: string;
  type: SignalEnum.RELOAD_PROJECT_BOARD;
}

export interface SignalNotificationReceived extends Signal {
  count?: number;
  item?: Notification;
  accountId?: string;
  type: SignalEnum.NEW_NOTIFICATION;
}

export enum SignalStitchEventType {
  DELETED = 'deleted',
  KNOTS_UPDATED = 'knots-updated',
}

export interface SignalStitch extends Signal {
  ids: string[];
  stitchType: StitchType;
  eventType: SignalStitchEventType;
  accountId: string;
  type: SignalEnum.STITCH_EVENT;
}
