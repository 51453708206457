// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// Types
import { Team } from '@modules/settings/types/team';
import { TeamRole, TeamRoleForm } from '@modules/settings/types/team-role';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Services
import { TeamRolesService } from '@modules/settings/services/team-roles.service';

@Component({
  selector: 'app-settings-teams-team-role-form',
  templateUrl: './team-role-form.component.html',
  styleUrls: ['./team-role-form.component.less'],
  standalone: false,
})
export class TeamsTeamRoleFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() team: Team;

  public form: TeamRoleForm;
  public submitted = false;

  private alive = new Subject<void>();

  constructor(
    @Inject(CloseToken) private closeToken,
    private teamRolesService: TeamRolesService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('team' in changes) {
      this.createForm();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  createForm() {
    this.form = new TeamRole({ teamId: this.team.id }).asFormGroup();
  }

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const item = TeamRole.fromFormGroup(this.form);

    this.teamRolesService
      .create(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.closeToken.next();
      });
  }
}
