import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assign',
  standalone: false,
})
export class AssignPipe implements PipeTransform {
  transform(...assignableObjects: object[]): object {
    const target = {};

    return Object.assign(target, ...assignableObjects);
  }
}
