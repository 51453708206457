// Common
import { Component, Input } from '@angular/core';

// Types
import { MonthViewDay } from 'calendar-utils';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'stch-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less'],
  standalone: false,
})
export class EventsListComponent {
  @Input() day: MonthViewDay;
  @Input() popoverClose: Subject<void>;

  handleClosePopover() {
    this.popoverClose.next();
  }
}
