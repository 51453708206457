// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, FormControl, FormGroup } from '@angular/forms';

// RxJs
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-settings-messages-imap',
  templateUrl: './imap.component.html',
  styleUrls: ['./imap.component.less'],
  standalone: false,
})
export class ImapComponent implements OnInit, OnDestroy {
  // Public
  public form = new FormGroup({
    popEnabled: new FormControl<'on' | 'off' | 'since'>('on'),
    popSince: new FormControl<Date>(null),
    popAccessed: new UntypedFormControl('copy'),
    imapEnabled: new FormControl<'on' | 'off'>('on'),
    autoRemoveMessages: new FormControl<boolean>(true),
    removingBehaviour: new FormControl<'archive' | 'trash' | 'permanently'>('archive'),
    messagesLimitEnabled: new FormControl<boolean>(false),
    messagesLimitSize: new FormControl<number>(1000),
  });

  public popAccessedOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Keep Stitch’s copy in the Inbox', value: 'copy' },
  ];

  // Private
  private alive = new Subject<void>();

  // Mock
  imapSettings = {
    popEnabled: 'on',
    popSince: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 25),
    popAccessed: 'copy',
    imapEnabled: 'on',
  };

  ngOnInit(): void {
    this.form.controls.popEnabled.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.form.controls.popSince.setValue(new Date(), { emitEvent: false }));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
