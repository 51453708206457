// Common
import { Directive, Injector, Input } from '@angular/core';

// Directives
import { TextSelectionTooltipDirective } from '@modules/popover/directives/text-selection-tooltip.directive';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';

// Types
import { Stitch } from '../types/stitch';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appSelectionTooltip]',
  standalone: false,
})
export class SelectionTooltipDirective extends TextSelectionTooltipDirective {
  @Input() appSelectionTooltip: Stitch;

  constructor(
    injector: Injector,
    private asService: AdvancedSearchService,
    private knotsService: KnotsService,
    private tagsService: TagsService,
  ) {
    super(injector);
  }

  protected actions = [
    { title: 'Search', handler: this.handleSearch.bind(this) },
    { title: 'Make Knot', handler: this.handleKnot.bind(this) },
    { title: 'Make Tag', handler: this.handleTag.bind(this) },
  ];

  handleSearch(text: string) {
    this.asService.search(text);
  }

  handleKnot(text: string) {
    this.knotsService
      .upsertBulk([new Knot({ name: text })], [this.appSelectionTooltip])
      .pipe(takeUntil(this.alive))
      .subscribe();
  }

  handleTag(text: string) {
    this.tagsService
      .upsertBulk([new Tag({ name: text })], [this.appSelectionTooltip])
      .pipe(takeUntil(this.alive))
      .subscribe();
  }
}
