// Common
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

// RX
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Task } from '@modules/tasks/types/task';

@Component({
  selector: 'app-board-task',
  templateUrl: './board-task.component.html',
  styleUrls: ['./board-task.component.less'],
  standalone: false,
})
export class BoardTaskComponent implements OnInit, OnChanges, OnDestroy {
  public isExpanded = false;
  public isDragging = false;
  public dragData: DragData = { data: [], type: DragDataTypes.task };
  public showIssueKey = false;

  private expandedChanged = new Subject<void>();
  private alive = new Subject<void>();

  @Input() task: Task;
  @Input() expanded: Subject<boolean>;
  @Input() expandable = false;
  @Input() dragging = false;
  @Input() subtask = false;

  @Input() active: boolean;
  @Input() index: number;
  @Input() position: number;

  @Output() open = new EventEmitter();
  @Output() openSubtask = new EventEmitter<Task>();

  constructor(
    protected stateService: StateService,
    private settingsService: TaskingAppSettingsService,
    private tasksService: TasksService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dragData = { data: [this.task], type: DragDataTypes.task };

    this.expandedChanged
      .pipe(
        filter(() => !!this.expanded),
        switchMap(() => this.expanded),
        takeUntil(this.alive),
      )
      .subscribe((value) => {
        this.isExpanded = value;
      });

    this.settingsService
      .listAll()
      .pipe(takeUntil(this.alive))
      .subscribe(({ issueKeyEnabled }) => (this.showIssueKey = issueKeyEnabled));

    this.expandedChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('expanded' in changes) {
      this.expandedChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleComplete() {
    this.task.completed = !this.task.completed;
    this.tasksService.update(this.task);
  }

  /**
   * Actions
   */

  trigger(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.expanded.next(!this.isExpanded);
  }
}
