// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Settings } from '@modules/admin/types/settings';

// Services
import { SettingsService } from '@modules/admin/services/settings.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'app-admin-plans-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
  standalone: false,
})
export class PlansSettingsComponent implements OnInit, OnDestroy {
  public settings: Settings;
  public avatar: string;
  public form: FormGroup;

  private alive = new Subject<void>();

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.settingsService
      .getSettings()
      .pipe(takeUntil(this.alive))
      .subscribe((settings) => {
        this.settings = settings;
        this.form = this.settings.asFormGroup();

        this.watchFormChanges();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  watchFormChanges() {
    this.form.valueChanges
      .pipe(
        debounceTime(1000),
        switchMap(() => {
          if (!this.form.valid) {
            return of();
          }

          return this.settingsService.update(Settings.fromFormGroup(this.form));
        }),
        takeUntil(this.alive),
      )
      .subscribe();
  }
}
