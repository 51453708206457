// Common
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Types
import { SubscriptionPlan } from '@modules/admin/types/subscription-plan';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { SubscriptionPlansService } from '@modules/admin/services/subscription-plans.service';

@Component({
  selector: 'app-admin-plans-plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.less'],
  standalone: false,
})
export class SubscriptionPlanFormComponent implements OnInit, OnDestroy {
  @Input() plan: SubscriptionPlan;

  public form: FormGroup;
  public submitted = false;

  private alive = new Subject<void>();

  constructor(
    @Inject(CloseToken) private closeToken,
    private plansService: SubscriptionPlansService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.form = this.plan.asFormGroup();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const item = SubscriptionPlan.fromFormGroup(this.form);

    this.plansService
      .upsert(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.closeToken.next();
      });
  }
}
