import { Directive, Inject, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SPACE_ID } from '../injection-tokens/space-id.injection-token';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[stchSpaceRerender]',
  standalone: false,
})
export class SpaceRerenderDirective implements OnInit, OnDestroy {
  private alive = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(SPACE_ID) private space: BehaviorSubject<string>,
  ) {}

  ngOnInit() {
    this.space.pipe(takeUntil(this.alive)).subscribe((spaceId) => {
      if (spaceId === undefined) {
        return;
      }

      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
