<div class="panel">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <app-dropdown-label
        appDPFilesContextMenu
        (appDPFilesContextMenuOpened)="contextMenuOpened = $event"
        (appDPFilesContextMenuFilterChanged)="selectFilter($event)"
        [collapsed]="!contextMenuOpened"
      >
        {{ selectedFilter | titleByFilesFilter}}
      </app-dropdown-label>
      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedFilter !== 'all_folders'"
          svgIcon="filters"
          appFilesListContextMenu
          [appFilesListContextMenuStateKey]="filesListStateKey"
          (appFilesListContextMenuChange)="handleFilesListStateChange($event)"
        />
        <app-context-menu-state-indicator
          *ngIf="selectedFilter === 'all_folders'"
          svgIcon="filters"
          appFoldersListContextMenu
          [appFoldersListContextMenuStateKey]="foldersListStateKey"
          (appFoldersListContextMenuChange)="handleFoldersListStateChange($event)"
        />

        <ng-container [ngTemplateOutlet]="searchTemplate" />
      </div>
    </div>
  </div>

  <app-quick-form
    *ngIf="
      selectedFilter !== 'all_folders' &&
      selectedFilter !== 'archived' &&
      selectedFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New File"
  >
    <app-file-quick-form
      (more)="handleNewFile($event)"
    />
  </app-quick-form>

  <app-quick-form
    *ngIf="selectedFilter === 'all_folders'"
    class="panel-quick-form"
    placeholder="Add New Folder"
  >
    <app-folder-quick-form
      (more)="handleNewFolder($event)"
    />
  </app-quick-form>

  <div class="panel-list">
    <app-files-list
      *ngIf="selectedFilter !== 'all_folders'"
      [filters]="filesFilters"
      (openFile)="openFile($event)"
      (doubleClickFile)="openFile($event)"
    />

    <app-folders-list
      *ngIf="selectedFilter === 'all_folders'"
      [filters]="foldersFilters"
      (openFolder)="openFolder($event)"
      (openFile)="openFileInModal($event)"
      (doubleClickFolder)="openFolder($event)"
    />
  </div>
</div>
