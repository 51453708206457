// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';

// Services
import { ToasterService } from './services/toaster.service';

// Components
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    ToastrModule.forRoot({
      preventDuplicates: false,
      positionClass: 'toast-bottom-right',
      tapToDismiss: false,
      toastComponent: ToastComponent,
    }),
  ],
  declarations: [ToastComponent],
  providers: [ToasterService],
})
export class ToasterModule {}
