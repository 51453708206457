<app-context-menu-item
  *ngIf="!relatedContact.vip"
  title="Make VIP"
  [close]="true"
  (execute)="changeVipStatus(true)"
/>

<app-context-menu-item
  *ngIf="relatedContact.vip"
  title="Remove VIP"
  [close]="true"
  (execute)="changeVipStatus(false)"
/>

<app-context-menu-item
  title="Copy Email Address"
  [close]="true"
  (execute)="copyEmail()"
/>

<app-context-menu-item
  title="Send New Email"
  [close]="true"
  (execute)="sendNewEmail()"
/>

<app-context-menu-item
  title="All Emails for Connection"
  [close]="true"
  (execute)="openRelatedEmails()"
/>

<app-context-menu-item
  title="Go to Explore"
  [close]="true"
  (execute)="openRelatedTopics()"
/>

<app-context-menu-item
  *ngIf="loadInKnowledgePanel.observers.length > 0"
  title="Load in Knowledge Pane"
  [close]="true"
  (execute)="loadContactInKnowledgePanel()"
/>
