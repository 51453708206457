// Common
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// RxJS
import { throwError, of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { ChartState } from '../types/chart-state';
import { ChartNode } from '../types/chart-node';
import { ChartLink } from '../types/chart-link';

@Injectable()
export class KnowledgeService {
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {
    this.handleObserverError = this.handleObserverError.bind(this);
  }

  /**
   * Static methods
   */

  handleObserverError(error: HttpErrorResponse) {
    if (error?.error?.error) {
      this.toasterService.show({
        text: error.error.error,
        icon: 'tags',
      });
    }

    console.error(error);
    return throwError(error);
  }

  /**
   * Methods
   */

  getGraphForItem(stitchItem: Stitch, state: ChartState): Observable<{ nodes: ChartNode[]; links: ChartLink[] }> {
    if (!stitchItem?.id) {
      return of({ nodes: [], links: [] });
    }

    return this.http
      .get<{ success: boolean; nodes: ChartNode[]; links: ChartLink[] }>(
        `${environment.baseUrl}/api/graphs/graph-for-item/${stitchItem.id}`,
        {
          params: ChartState.format(state),
        },
      )
      .pipe(catchError((error) => this.handleObserverError(error)));
  }
}
