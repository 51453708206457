<div class="move-dialog">
  <div class="move-dialog-title">
    {{ appearance === 'move' ? 'Move' : 'Copy' }} To
  </div>

  <app-simple-input
    class="move-dialog-input"
    [suggestions]="suggestions"
    [inputFormControl]="inputControl"
    placeholder=""
    [itemTemplate]="itemTemplate"
    (onSelect)="handleSelect($event)"
  />

  <div
    class="move-dialog-quick-form"
    [ngSwitch]="parentItemType"
  >
    <app-quick-form
      *ngSwitchCase="'task'"
      placeholder="Add New Task"
    >
      <app-task-quick-form
        [forceRefresh]="false"
        (afterSave)="handleQuickAfterSave($event)"
      />
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'project'"
      placeholder="Add New Project"
    >
      <app-project-quick-form (afterSave)="handleQuickAfterSave($event)" />
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'notebook'"
      placeholder="Add New Notebook"
    >
      <app-notebook-quick-form (afterSave)="handleQuickAfterSave($event)" />
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'group'"
      placeholder="Add New Group"
    >
      <app-group-quick-form (afterSave)="handleQuickAfterSave($event)" />
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'folder'"
      placeholder="Add New Folder"
    >
      <app-folder-quick-form (afterSave)="handleQuickAfterSave($event)" />
    </app-quick-form>

    <app-quick-form
      *ngSwitchCase="'message-folder'"
      placeholder="Add New Folder"
    >
      <app-message-folder-quick-form (afterSave)="handleQuickAfterSave($event)" />
    </app-quick-form>
  </div>

  <app-scroll
    class="move-dialog-list"
    (top)="scrollShadowTop = !$event"
    (bottom)="scrollShadowBottom = !$event"
  >
    <div class="shadow" *ngIf="scrollShadowTop"></div>
    <ng-container
      *ngFor="let item of displayedItems"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ item: item, mainTemplate: true }"
    />

    <div *ngIf="!displayedItems.length" class="placeholder">
      <img
        class="placeholder-image"
        src="assets/placeholders/placeholder-stitch-info.jpg"
        alt="There are no items"
      />
      <div class="placeholder-text">
        Search or Add New Item to {{ appearance === 'move' ? 'Move' : 'Copy' }}
      </div>
    </div>

    <div
      class="unlink-all"
      *ngIf="displayedItems.length"
      (click)="removeAll()"
    >
      Remove All
    </div>
  </app-scroll>

  <div class="shadow-bottom">
    <div class="shadow-bottom-inner" *ngIf="scrollShadowBottom"></div>
  </div>

  <div class="move-dialog-buttons">
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button
      appearance="square-blue"
      [label]="appearance === 'move' ? 'Move' : 'Copy'"
      size="medium"
      (click)="handleSave()"
    />
  </div>
</div>

<ng-template #itemTemplate let-item="item" let-mainTemplate="mainTemplate">
  <ng-container [ngSwitch]="parentItemType">
    <app-link-info-task
      *ngSwitchCase="'task'"
      [class.move-dialog-list-item]="mainTemplate"
      [task]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="mainTemplate"
      [removeOnly]="true"
      [withUnlink]="false"
      viewType="short"
      (remove)="removeItem(item)"
    />

    <app-link-info-project
      *ngSwitchCase="'project'"
      [class.move-dialog-list-item]="mainTemplate"
      [project]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="mainTemplate"
      [removeOnly]="true"
      [withUnlink]="false"
      viewType="short"
      (remove)="removeItem(item)"
    />

    <app-link-info-notebook
      *ngSwitchCase="'notebook'"
      [class.move-dialog-list-item]="mainTemplate"
      [notebook]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="mainTemplate"
      [removeOnly]="true"
      [withUnlink]="false"
      viewType="short"
      (remove)="removeItem(item)"
    />

    <app-link-info-group
      *ngSwitchCase="'group'"
      [class.move-dialog-list-item]="mainTemplate"
      [group]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="mainTemplate"
      [removeOnly]="true"
      [withUnlink]="false"
      viewType="short"
      (remove)="removeItem(item)"
    />

    <app-link-info-folder
      *ngSwitchCase="'folder'"
      [class.move-dialog-list-item]="mainTemplate"
      [folder]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="mainTemplate"
      [removeOnly]="true"
      [withUnlink]="false"
      viewType="short"
      (remove)="removeItem(item)"
    />

    <app-link-info-message-folder
      *ngSwitchCase="'message-folder'"
      [class.move-dialog-list-item]="mainTemplate"
      [folder]="item"
      [withDraggable]="false"
      [withContextMenu]="false"
      [withActions]="mainTemplate"
      [removeOnly]="true"
      [withUnlink]="false"
      viewType="short"
      (remove)="removeItem(item)"
    />

  </ng-container>
</ng-template>
