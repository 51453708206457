<div
  class="container"
  (resize)="handleResize($event)"
>
  <div class="knots">
    <div
      *ngFor="let knot of (knots | slice : 0 : displayCount)"
      class="knots-item"
    >
      {{ knot.name }}
    </div>
  </div>
  <div
    *ngIf="more"
    class="more"
  >
    <mat-icon
      svgIcon="more-actions"
      class="more-icon"
      matTooltip="More Knots"
      stchPopover
      stchPopoverTrigger="click"
      stchPopoverPlacement="right"
      [stchPopoverContent]="knotsPopover"
      [appClickStopPropagation]="true"
    />
  </div>
</div>

<div class="sandbox">
  <div
    *ngFor="let knot of knots"
    #knot
    class="knots-item"
  >
    {{ knot.name }}
  </div>
</div>

<ng-template #knotsPopover>
  <div class="popover">
    <div
      *ngFor="let knot of knots"
      class="knots-item knots-item-popover"
    >
      {{ knot.name }}
    </div>
  </div>
</ng-template>
