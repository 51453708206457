// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { PushNotificationsService } from './services/push-notifications.service';

// Modules
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PopoverModule } from '@modules/popover/popover.module';
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { MatIconModule } from '@angular/material/icon';

// Components
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationComponent } from './components/notification/notification.component';

@NgModule({
  imports: [
    CommonModule,
    FormControlsModule,
    MatIconModule,
    PopoverModule,
    ScrollModule,
    ScrollingModule,
    AppCommonModule,
  ],
  declarations: [NotificationsListComponent, NotificationComponent],
  exports: [NotificationsListComponent],
  providers: [PushNotificationsService],
})
export class NotificationModule {}
