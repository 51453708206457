// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { priorities } from '@modules/form-controls/types/priorities';

// Models
import { Priority } from '@modules/tasks/types/priority';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-priority-input',
  templateUrl: './priority-input.component.html',
  styleUrls: ['./priority-input.component.less'],
  standalone: false,
})
export class PriorityInputComponent {
  // Inputs
  @Input() control: UntypedFormControl;
  @Input() withLabel = false;
  @Input() appearance: 'default' | 'sapphire' = 'default';

  // Output
  @Output() open = new EventEmitter<boolean>();

  // Public
  public prioritiesList = priorities;

  // Private
  public popoverShowUntil = new Subject<void>();

  handleClick(priority: Priority) {
    this.control.setValue(priority === this.control.value ? 'none' : priority);
    this.popoverShowUntil.next();
  }
}
