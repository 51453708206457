<div
  class="input"
  [class.focused]="focused"
  [class.error]="withErrors && !inputFormControl?.valid"
  [class.inverted]="invertedColor"
  [class.disabled]="disabled"
  [ngClass]="appearance"
>
  <div
    *ngIf="label && appearance === 'amethyst'"
    class="input-label"
    [class.focused]="focused || inputFormControl?.value"
  >
    {{ label }}
  </div>

  <textarea
    [class.with-label]="label"
    [class.no-resize]="!resize"
    [formControl]="inputFormControl"
    [placeholder]="placeholder"
    [rows]="rows"
    [readonly]="disabled"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    autocomplete="new-password"
  ></textarea>

  <mat-icon
    *ngIf="withClear && inputFormControl?.value !== ''"
    class="clear-icon"
    svgIcon="close-thin"
    (click)="handleClear()"
  />

  <app-input-errors
    *ngIf="withErrors"
    [appearance]="appearance | firstWord"
    [inputFormControl]="inputFormControl"
    [validationMessages]="validationMessages"
  />

  <mat-icon
    *ngIf="resize"
    class="resize-handle-icon"
    svgIcon="sapphire-resize-handle"
  />
</div>

<div
  *ngIf="hint && !(withErrors && !inputFormControl.valid)"
  class="hint"
  [class.disabled]="disabled"
  [ngClass]="appearance"
>
  <mat-icon class="hint-icon" svgIcon="sapphire-info" />
  {{ hint }}
</div>
