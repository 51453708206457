// Common
import { Component, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

// Types
import { Team } from '@modules/settings/types/team';
import { Tab } from '@modules/common/types/tab';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';
import { Account } from '@modules/account/types/account';

// Services
import { AlertService } from '@modules/alert/services/alert.service';
import { TeamsService } from '@modules/settings/services/teams.service';
import { AccountService } from '@modules/account/services/account.service';
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';
import { TeamParticipantsService } from '@modules/settings/services/team-participants.service';

// RX
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';

// Components
import { TeamsTeamRoleFormComponent } from './team-role-form/team-role-form.component';

@Component({
  selector: 'app-settings-teams-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.less'],
  standalone: false,
})
export class SpacesTeamComponent implements OnInit, OnDestroy {
  public team: Team;
  public form = new Team().asFormGroup();
  public total = 0;
  public currentPlan: SubscriptionPlan;
  public account: Account;
  public selectedTab = 'members';
  public tabs: Tab[] = [
    { title: 'Members', value: 'members' },
    { title: 'Details', value: 'details' },
    { title: 'Permissions', value: 'roles' },
  ];

  private alive = new Subject<void>();

  constructor(
    private alertsService: AlertService,
    private teamsService: TeamsService,
    private accountService: AccountService,
    private plansService: SubscriptionPlansService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private teamParticipantsService: TeamParticipantsService,
    private injector: Injector,
    private elementRef: ElementRef,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([this.accountService.getAccount(true), this.plansService.search()])
      .pipe(takeUntil(this.alive))
      .subscribe(([account, { items: plans }]) => {
        this.currentPlan = plans.find((plan) => plan.id === account.subscriptionPlanId);
        this.account = account;
      });

    this.route.params
      .pipe(
        map((params) => params['id']),
        switchMap((id) => this.teamsService.getOne(id)),
        tap((team) => {
          this.team = team;
          this.total = this.team.participants.length;
          this.form = this.team.asFormGroup();
        }),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() => this.teamsService.update(Team.fromFormGroup(this.form))),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openParticipantForm() {
    this.alertsService.show({
      title: 'Add participant',
      body: `Enter participant's email address:`,
      inputs: [{ type: 'simple', label: 'Email', name: 'email' }],
      rightButtons: [
        { title: 'CANCEL', close: true },
        { title: 'ADD', click: (data) => this.addParticipant(data.email), close: true },
      ],
      showUntil: this.alive,
    });
  }

  addParticipant(email: string) {
    this.teamParticipantsService
      .addParticipant(this.team, email)
      .pipe(
        switchMap(() => this.teamsService.getOne(this.team.id)),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.teamParticipantsService.forceRefresh();
      });
  }

  handleTabChange(tab: string) {
    this.selectedTab = tab;
  }

  handleBack() {
    if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigate(['/settings/teams']);
    }
  }

  handleUpgrade() {
    this.router.navigate(['/settings/general/plans']);
  }

  openRoleForm() {
    this.alertsService.show({
      appearance: 'sapphire-modal-custom',
      component: TeamsTeamRoleFormComponent,
      context: { team: this.team },
      origin: this.elementRef.nativeElement,
      injector: this.injector,
    });
  }
}
