<editor
  *ngIf="settingsPrepared"
  class="editor"
  [formControl]="control"
  [init]="settings"
  (onInit)="editorInitialized($event)"
/>

<app-input-errors
  *ngIf="withErrors"
  [inputFormControl]="control"
  [validationMessages]="validationMessages"
/>
