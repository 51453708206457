// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit, Injector } from '@angular/core';
import { isEqual } from 'lodash';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { KnowledgeChartContextMenuComponent } from '../components/context-menus/knowledge-chart-context-menu/knowledge-chart-context-menu.component';

// Types
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { ChartState } from '@modules/knowledge/types/chart-state';
import { StateKey } from '@modules/settings/types/state-key';

// RX
import { map, take, takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.fullFormChart]?: ChartState;
  }
}

@Directive({
  selector: '[appKnowledgeChartContextMenu]',
  standalone: false,
})
export class KnowledgeChartContextMenuDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() appKnowledgeChartContextMenuStateKey: StateKey = StateKey.fullFormChart;

  // Outputs
  @Output() appKnowledgeChartContextMenuChange = new EventEmitter<ChartState>();

  // Public
  public defaultState: ChartState = {
    filters: {
      stitch: {
        message: false,
        event: false,
        project: false,
        task: false,
        notebook: false,
        note: false,
        group: false,
        contact: false,
        folder: false,
        file: false,
      },
      displayKnots: true,
      displayTags: true,
      displayConnections: true,
      createdFrom: null,
      createdTo: null,
      linkedFrom: null,
      linkedTo: null,
    },
  };
  private state = { ...this.defaultState };

  constructor(
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<KnowledgeChartContextMenuComponent>) {
    componentRef.instance.state = this.state;

    componentRef.instance.change.pipe(takeUntil(this.alive)).subscribe((state: ChartState) => {
      this.setState(state);
      componentRef.instance.state = state;

      this.appKnowledgeChartContextMenuChange.emit(state);

      if (this.appKnowledgeChartContextMenuStateKey) {
        this.stateService.setState(this.appKnowledgeChartContextMenuStateKey, this.state);
      }
    });
  }

  registerComponent() {
    return KnowledgeChartContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stateService
      .getState(this.appKnowledgeChartContextMenuStateKey)
      .pipe(
        map((state) => Object.assign(this.state, state)),
        map((state: any) => ({
          ...(state || {}),
          filters: {
            ...(state?.filters || {}),
            createdFrom: state?.filters?.createdFrom ? new Date(state.filters.createdFrom) : null,
            createdTo: state?.filters?.createdTo ? new Date(state.filters.createdTo) : null,
            linkedFrom: state?.filters?.linkedFrom ? new Date(state.filters.linkedFrom) : null,
            linkedTo: state?.filters?.createdTo ? new Date(state.filters.createdTo) : null,
          },
        })),
        take(1),
        takeUntil(this.alive),
      )
      .subscribe((state: ChartState) => {
        this.setState(Object.assign(this.state, state));
        this.appKnowledgeChartContextMenuChange.emit(this.state);
      });

    if (this.isDefaultStateObservable) {
      this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefaultState) => {
        if (isDefaultState && !isEqual(this.defaultState, this.state)) {
          this.state = this.defaultState;
          this.appKnowledgeChartContextMenuChange.emit(this.defaultState);
          this.stateService.setState(this.appKnowledgeChartContextMenuStateKey, this.defaultState);
        }
      });
    }
  }

  /**
   * Actions
   */

  setState(newState: ChartState) {
    if (this.isDefaultStateObservable) {
      this.isDefaultStateObservable.next(isEqual(this.defaultState, newState));
    }

    this.state = newState;
  }
}
