// Common
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ngClassDirective } from '@modules/utils/directives/ngClass/ngClass.directive';
import { WrapTextNodesDirective } from '@modules/utils/directives/wrap-text-nodes/wrap-text-nodes.directive';

// Types

type Button2Size = 's' | 'm' | 'l' | 'xl';
type Button2Appearance =
  | 'primary'
  | 'secondary'
  | 'primary-ghost'
  | 'secondary-ghost'
  | 'link'
  | 'sublink'
  | 'primary-text'
  | 'secondary-text';

@Component({
  standalone: true,
  selector: 'app-button2',
  templateUrl: './button2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [WrapTextNodesDirective, ngClassDirective],
  styleUrl: './button2.component.css',
})
export class Button2Component {
  public readonly type = input<'button' | 'submit'>('button');
  public readonly appearance = input<Button2Appearance>('secondary');
  public readonly size = input<Button2Size>('m');
  public readonly disabled = input<boolean>();
}
