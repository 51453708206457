<div class="header">
  <div class="header-title">
    <ng-container *ngTemplateOutlet="tabs" />
  </div>

  <div class="header-separator"></div>

  <app-context-menu-state-indicator
    class="header-filters-icon"
    svgIcon="filters"
    appConnectionsManageContextMenu
    [appConnectionsManageContextMenuStateKey]="'kp-connections'"
    (appConnectionsManageContextMenuStateChange)="setState($event)"
  />
</div>

@if (neighborsConnections.length === 0) {
  <app-no-content-stub imageSrc="assets/placeholders/placeholder-pellets.svg">
    @if (!stitchItem) {
      Select item to see related connections
    } @else {
      No related connections
    }
  </app-no-content-stub>
}

<div
  *ngIf="stitchItem"
  class="list"
>
  <div class="connections">
    <div
      *ngFor="let connection of (neighborsConnections | slice : 0 : (page + 1) * perPage)"
      class="connections-item"
      (click)="handleClickItem(connection, $event)"
    >
      {{ connection?.name }}
    </div>

    <div
      *ngIf="page !== 0"
      class="more-less"
      (click)="showLess()"
    >
      &lt; Less
    </div>

    <div
      *ngIf="page < pagesCount - 1"
      class="more-less"
      (click)="showMore()"
    >
      More >
    </div>
  </div>
</div>
