// Common
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Space } from '@modules/settings/types/space';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Services
import { SpacesService } from '@modules/settings/services/spaces.service';

@Component({
  selector: 'app-settings-spaces-space-form',
  templateUrl: './space-form.component.html',
  styleUrls: ['./space-form.component.less'],
  standalone: false,
})
export class SpaceFormComponent implements OnInit, OnDestroy {
  @Input() space = new Space();

  public form: FormGroup;
  public submitted = false;

  private alive = new Subject<void>();

  constructor(
    @Inject(CloseToken) private closeToken,
    private spacesService: SpacesService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.form = this.space.asFormGroup();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const item = Space.fromFormGroup(this.form);

    this.spacesService
      .upsert(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.closeToken.next();
      });
  }
}
