import { Injectable } from '@angular/core';
import { SignalsService } from './signals-service/signals.service';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SignalEnum, SignalStitch, SignalStitchEventType } from '@modules/common/types/signal';
import { StitchType } from '@modules/common/types/stitch-type';
import { isNil } from '@modules/common/utils/base';
import { conditionalOperator } from '@modules/common/utils/conditioanl-operator';

@Injectable({
  providedIn: 'root',
})
export class StitchEventsService {
  constructor(private signalsService: SignalsService) {}

  getEvents(
    spaceId: Observable<string>,
    stitchType?: StitchType,
    stitchEventType?: SignalStitchEventType,
  ): Observable<SignalStitch> {
    return spaceId.pipe(
      switchMap((id) => this.signalsService.getSignal(SignalEnum.STITCH_EVENT, id)),
      conditionalOperator(
        !isNil(stitchType),
        filter((event: SignalStitch) => event.stitchType === stitchType),
      ),
      conditionalOperator(
        !isNil(stitchEventType),
        filter((event: SignalStitch) => event.eventType === stitchEventType),
      ),
    );
  }
}
