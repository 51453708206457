// Common
import { Directive, Input, ComponentRef, Output, OnInit, EventEmitter } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { StitchInfoPopoverComponent } from '../components/link-info/stitch-info-popover/stitch-info-popover.component';

// Types
import { Stitch } from '@modules/common/types/stitch';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appStitchInfoPopover]',
  standalone: false,
})
export class StitchInfoPopoverDirective extends ContextMenuDirective implements OnInit {
  @Input() appStitchInfoPopover: Stitch;

  @Output() appStitchInfoPopoverOpened = this.contextMenuOpened;
  @Output() appStitchInfoPopoverItemDoubleClick = new EventEmitter<Stitch>();

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverTrigger = 'click';
    this.stchPopoverPlacement = this.stchPopoverPlacement || 'right';
    this.stchPopoverArrow = true;
    this.stchPopoverStopPropagation = true;
  }

  /**
   * Actions
   */

  registerComponent() {
    return StitchInfoPopoverComponent;
  }

  registerInstance(componentRef: ComponentRef<StitchInfoPopoverComponent>) {
    componentRef.instance.stitch = this.appStitchInfoPopover;
    componentRef.instance.dblClickStitchItem.pipe(takeUntil(this.alive)).subscribe((item) => {
      this.appStitchInfoPopoverItemDoubleClick.next(item);
      this.closeSubject.next();
    });
  }
}
