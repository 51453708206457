// Common
import { Component, Input, OnInit, OnDestroy, TemplateRef, Inject } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';
import { LocalStorageItem, LSBoolean } from 'src/app/decorators/local-storage.decorator';

// Injection Tokens
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// Services
import { TeamsService } from '@modules/settings/services/teams.service';

// Types
import { Team } from '@modules/settings/types/team';
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-side-bar-teams',
  templateUrl: './side-bar-teams.component.html',
  styleUrls: ['./side-bar-teams.component.less'],
  animations: [heightAnimation],
  standalone: false,
})
export class SidebarTeamsComponent implements OnInit, OnDestroy {
  public teams: Team[] = [];
  public minimized: boolean;
  @LSBoolean({ lsKey: 'sidebar-teams.collapsed' }) public expanded: LocalStorageItem<boolean>;

  private alive = new Subject<void>();

  @Input() template: TemplateRef<any>;
  @Input() splitViewKey: SidebarSplitViewKey;

  constructor(
    public teamsService: TeamsService,
    public splitViewService: SplitViewService,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.spaceId
      .pipe(
        switchMap((spaceId) => this.teamsService.findAll({ spaceId }, { cache: true })),
        takeUntil(this.alive),
      )
      .subscribe((teams) => {
        this.teams = teams;
      });

    this.splitViewService
      .getMinimized(this.splitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe((minimized) => {
        this.minimized = minimized;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
