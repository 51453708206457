// Types
import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class SpacesRolesFilters extends Filters {
  sortBy?: 'title';

  constructor(filters?: Like<SpacesRolesFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
  }

  format(): { [param: string]: string | string[] | boolean | number } {
    const result = super.format();

    result['sort_by'] = this.sortBy || 'title';

    return result;
  }
}
