// Common
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { countries, CountryType } from '../../../../../assets/countries/countries';
import { Country } from '@modules/common/types/country';
import { Account } from '@modules/account/types/account';
import { CountryPickerType } from '@modules/form-controls/types/country-picker';

// RxJS
import { combineLatest, of, Subject } from 'rxjs';
import { filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';

// Services
import { AccountService } from '@modules/account/services/account.service';

const mapper = {
  iso3: {
    regExp: (title) => new RegExp(`^${title}`, 'i'),
    title: 'name',
    value: 'iso3',
  },
  phoneCode: {
    regExp: (title) => new RegExp(`${title?.replace('+', '')}`, 'i'),
    title: 'phoneCode',
    value: 'phoneCode',
  },
};

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.less'],
  standalone: false,
})
export class CountryPickerComponent implements OnInit {
  @Input() inputFormControl: UntypedFormControl;
  @Input() showBy: CountryPickerType = 'iso3';
  @Input() label = 'Country';
  @Input() withClear = false;
  @Input() appearance: 'default' | 'amethyst' = 'default';
  @Input() invertedColor = false;

  public countries = countries;
  public suggestions: AutocompleteFactory<CountryType>;
  public selectedCountry: Country;

  private alive = new Subject<void>();

  constructor(private accountService: AccountService) {}

  /**
   * Lifecycle
   */
  ngOnInit(): void {
    this.suggestions = (title, values) => {
      const filteredCountries = [];

      for (let i = 0, l = this.countries.length; i < l; i++) {
        const country = this.countries[i];

        if (
          !(
            values?.includes(country[this.showBy]) ||
            country[mapper[this.showBy].title].search(mapper[this.showBy].regExp(title)) > -1
          )
        ) {
          continue;
        }

        filteredCountries.push({
          title: country[mapper[this.showBy].title],
          value: country[mapper[this.showBy].value],
          icon: country.iso3,
          source: country,
        });
      }

      return of(filteredCountries);
    };

    combineLatest([
      this.accountService.getAccount(),
      this.inputFormControl.valueChanges.pipe(
        pairwise(),
        startWith([null, this.inputFormControl.value as string]),
        filter(([previousValue, currentValue]) => previousValue !== currentValue),
        map(([_, currentValue]) => currentValue),
      ),
    ])
      .pipe(
        map(([account, value]: [Account, string]) => (value ? value : Country.fromIso2(account.country)[this.showBy])),
        takeUntil(this.alive),
      )
      .subscribe((value) => {
        this.inputFormControl.setValue(value);
        this.selectedCountry = Country[this.showBy === 'iso3' ? 'fromIso3' : 'fromPhoneCode'](value);
      });
  }
}
