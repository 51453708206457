// Common
import { Component, Input, OnDestroy } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar-tags-knots-context-menu',
  templateUrl: './sidebar-tags-knots-context-menu.component.html',
  styleUrls: ['./sidebar-tags-knots-context-menu.component.less'],
  standalone: false,
})
export class SidebarTagsKnotsContextMenuComponent implements OnDestroy {
  @Input() appearance: 'tags' | 'knots';

  public popoverShowUntil = new Subject<void>();

  ngOnDestroy() {
    this.popoverShowUntil.next();
    this.popoverShowUntil.complete();
  }

  closePopover() {
    this.popoverShowUntil.next();
  }
}
