import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { Injectable } from '@angular/core';

@Injectable()
export class HideTooltipEventTitleFormatter extends CalendarEventTitleFormatter {
  monthTooltip(_event: CalendarEvent): string {
    return;
  }

  weekTooltip(_event: CalendarEvent): string {
    return;
  }

  dayTooltip(_event: CalendarEvent): string {
    return;
  }
}
