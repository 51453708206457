// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Settings } from '@modules/tasks/types/settings';

// RxJS
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';

@Component({
  selector: 'app-settings-tasking-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.less'],
  standalone: false,
})
export class GeneralComponent implements OnInit, OnDestroy {
  public form = new Settings().asFormGroup();
  public defaultDateOptions: DropdownSelectItem<unknown>[] = [
    { title: 'None', value: null },
    { title: 'Today', value: 'today' },
    { title: 'Tomorrow', value: 'tomorrow' },
    { title: 'Day after Tomorrow', value: 'afterTomorrow' },
    { title: 'Next Week', value: 'nextWeek' },
  ];
  public defaultReminderOptions: DropdownSelectItem<unknown>[] = [
    { title: 'On Time', value: 0 },
    { title: '5 minutes ahead', value: 5 },
    { title: '30 minutes ahead', value: 30 },
    { title: '1 hour ahead', value: 60 },
    { title: '1 day ahead', value: 1440 },
  ];
  public defaultReminderWholeDayOptions: DropdownSelectItem<unknown>[] = [{ title: 'None', value: 'none' }];
  public defaultPriorityOptions: DropdownSelectItem<unknown>[] = [
    { title: 'None', value: null },
    { title: 'Low', value: 'low' },
    { title: 'Medium', value: 'medium' },
    { title: 'High', value: 'high' },
  ];
  public defaultAddToOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Top of list', value: 'top' },
    { title: 'Bottom of list', value: 'bottom' },
  ];
  public overdueTasksShowAtOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Top', value: 'top' },
    { title: 'Bottom', value: 'bottom' },
  ];
  public completedTaskAppearanceOptions: DropdownSelectItem<unknown>[] = [
    { title: 'None', value: null },
    { title: 'Faded', value: 'faded' },
    { title: 'Strikethrough', value: 'strikethrough' },
  ];

  private alive = new Subject<void>();

  constructor(private settingsService: TaskingAppSettingsService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.settingsService
      .listAll(true)
      .pipe(
        tap((settings) => {
          this.form = settings.asFormGroup();
        }),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() => this.settingsService.update(Settings.fromFormGroup(this.form))),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
