// Common
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// RX
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Type
import { StitchType } from '@modules/common/types/stitch-type';
import { Account } from '@modules/account/types/account';
import { Stitch } from '@modules/common/types/stitch';

// Service
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';

@Component({
  selector: 'app-full-form-popup',
  templateUrl: './full-form-popup.component.html',
  styleUrls: ['./full-form-popup.component.less'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FullFormPopupComponent implements OnInit, OnDestroy {
  // Public
  public stitchType: StitchType;
  public stitchItem: Stitch;
  public account: Account;

  // Private
  private alive = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private stitchServiceFactory: StitchServiceFactory,
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        tap((params) => {
          this.stitchType = params['stitch-type'];
          document.title = params['stitch-type'];
        }),
        switchMap((params) =>
          this.stitchServiceFactory.getServiceByStitchType(params['stitchType']).getItem(params['id']),
        ),
        takeUntil(this.alive),
      )
      .subscribe((item: Stitch) => {
        this.stitchItem = item;
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  close() {
    window.close();
  }
}
