// Common
import { Component, Injector, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

// Utils
import { isNil } from '@modules/common/utils/base';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { ContactInfo } from '@modules/contacts/types/contact-info';
import { contactInfoTypes } from '@modules/form-controls/types/contactInfoTypes';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tab } from '@modules/common/types/tab';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Group } from '@modules/contacts/types/group';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsAppStateService } from '@modules/contacts/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less'],
  standalone: false,
})
export class ContactFormComponent extends FullFormBaseComponent<Contact> {
  public changesKey = 'contact';
  tabs: Tab[] = [
    { title: 'Contact', value: 'contact' },
    { title: 'Attachments', value: 'attachments' },
    { title: 'Stitch', value: 'stitch' },
    { title: 'Lab', value: 'lab' },
    { title: 'Comments', value: 'comments' },
  ];
  selectedTab = 'contact';
  tabsStateKey = 'ffContact';
  public contactInfoTypes = contactInfoTypes;
  public suggestions: AutocompleteFactory<Group>;

  // Private
  protected dragDataType = DragDataTypes.contact;

  @Input() contact = new Contact();

  constructor(
    contactsService: ContactsService,
    groupsService: GroupsService,
    contactsAppStateService: ContactsAppStateService,
    injector: Injector,
  ) {
    super(injector, contactsService, contactsAppStateService);

    this.suggestions = groupsService.getAutocompleteSuggestions();
  }

  /**
   * Actions
   */

  handleAddItem(type: 'emails' | 'phones', index?: number): void {
    const control = this.form.controls[type] as UntypedFormArray;
    const newItem = ContactInfo.createItem(type);

    control.insert((!isNil(index) ? index : control.length) + 1, newItem);
  }

  /**
   * Helpers
   */

  get stitchItem(): Contact {
    return this.contact;
  }

  protected shouldRefreshList(prev, current) {
    return Contact.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Contact {
    return Contact.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.contact.asFormGroup();
  }
}
