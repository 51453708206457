export interface AuthResponsePayloadDto {
  expiresInSeconds: number;
  userId: string;
}

export interface AuthResponseDto {
  success: boolean;
  payload: AuthResponsePayloadDto;
  csrfToken: string;
}

export const AUTH_PAYLOAD_KEY = 'authPayload';
