// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { CommentsModule } from '@modules/comments/comments.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TagsModule } from '@modules/tags/tags.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { BoardTaskComponent } from './components/board-task/board-task.component';
import { ColumnContextMenuComponent } from './components/column-context-menu/column-context-menu.component';
import { ProjectBoardColumnComponent } from './components/project-board-column/project-board-column.component';
import { ProjectBoardComponent } from './components/project-board/project-board.component';
import { ProjectBoardRowComponent } from './components/project-board-row/project-board-row.component';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { ProjectSectionsComponent } from './components/project-sections/project-sections.component';
import { ProjectSharedComponent } from './components/project-shared/project-shared.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { ProjectsListContextMenuComponent } from './components/projects-list-context-menu/projects-list-context-menu.component';
import { RowContextMenuComponent } from './components/row-context-menu/row-context-menu.component';
import { SideBarProjectsComponent } from './components/side-bar-projects/side-bar-projects.component';
import { SideBarProjectsTreeComponent } from './components/side-bar-projects-tree/side-bar-projects-tree.component';
import { SidebarContextMenuComponent } from './components/sidebar-context-menu/sidebar-context-menu.component';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { TaskFormSubtasksComponent } from './components/task-form-subtasks/task-form-subtasks.component';
import { TaskSharedComponent } from './components/task-shared/task-shared.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { TasksListContainerComponent } from './components/tasks-list-container/tasks-list-container.component';
import { TasksListContextMenuComponent } from './components/tasks-list-context-menu/tasks-list-context-menu.component';
import { TasksMainViewComponent } from './components/tasks-main-view/tasks-main-view.component';
import { TasksSideBarComponent } from './components/side-bar/side-bar.component';

// Directives
import { BoardColumnContextMenuDirective } from './directives/board-column-context-menu/board-column-context-menu.directive';
import { RowContextMenuDirective } from './directives/row-context-menu/row-context-menu.directive';
import { SidebarContextMenuDirective } from './directives/sidebar-context-menu.directive';
import { TasksListContextMenuDirective } from './directives/tasks-list-context-menu.directive';
import { ProjectsListContextMenuDirective } from './directives/projects-list-context-menu.directive';

// Pipes
import { TitleByTasksFilterPipe } from '@modules/tasks/pipes/title-by-tasks-filter.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    BundledInputsModule,
    CommentsModule,
    CommonModule,
    ConnectionsModule,
    DragNDropModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    ScrollModule,
    ScrollingModule,
    TagsModule,
    UtilsModule,
  ],
  declarations: [
    BoardTaskComponent,
    ColumnContextMenuComponent,
    ProjectBoardColumnComponent,
    ProjectBoardComponent,
    ProjectBoardRowComponent,
    ProjectFormComponent,
    ProjectSectionsComponent,
    ProjectSharedComponent,
    ProjectsListComponent,
    ProjectsListContextMenuComponent,
    RowContextMenuComponent,
    SideBarProjectsComponent,
    SideBarProjectsTreeComponent,
    SidebarContextMenuComponent,
    SidebarContextMenuDirective,
    TaskFormComponent,
    TaskFormSubtasksComponent,
    TaskSharedComponent,
    TasksListComponent,
    TasksListContainerComponent,
    TasksListContextMenuComponent,
    TasksMainViewComponent,
    TasksSideBarComponent,

    // Directives
    BoardColumnContextMenuDirective,
    ProjectsListContextMenuDirective,
    RowContextMenuDirective,
    TasksListContextMenuDirective,

    // Pipes
    TitleByTasksFilterPipe,
  ],
  exports: [
    ProjectsListComponent,
    TasksMainViewComponent,
    TasksListComponent,
    TasksListContainerComponent,
    TasksSideBarComponent,
    TaskFormComponent,
    TaskFormSubtasksComponent,
    ProjectFormComponent,

    // Directives
    ProjectsListContextMenuDirective,
    TasksListContextMenuDirective,
    TitleByTasksFilterPipe,
  ],
  providers: [],
})
export class TasksModule {}
