import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: false,
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb'];

  transform(size: number | string, precision: number = 2): string {
    let bytes = parseFloat(String(size));

    if (isNaN(bytes) || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    return bytes.toFixed(+precision) + ' ' + this.units[unit];
  }
}
