// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Settings } from '@modules/tasks/types/settings';

// Services
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';

// RX
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-settings-tasking-due-date',
  templateUrl: './due-date.component.html',
  styleUrls: ['./due-date.component.less'],
  standalone: false,
})
export class DueDateComponent implements OnInit, OnDestroy {
  public form = new Settings().asFormGroup();
  public tasksOptions: DropdownSelectItem<unknown>[] = [
    { title: 'All Tasks', value: 'all' },
    { title: 'Flagged', value: 'flagged' },
  ];
  public reminderOptions: DropdownSelectItem<unknown>[] = [
    { title: '5 minutes before', value: 5 },
    { title: '15 minutes before', value: 15 },
    { title: '30 minutes before', value: 30 },
    { title: '1 hour before', value: 60 },
    { title: '2 hours before', value: 120 },
    { title: '3 hours before', value: 180 },
    { title: '6 minutes before', value: 360 },
    { title: '8 hours before', value: 480 },
    { title: '12 hours before', value: 720 },
    { title: '1 day before', value: 1440 },
    { title: '2 days before', value: 2880 },
    { title: '3 days before', value: 4320 },
  ];

  private alive = new Subject<void>();

  constructor(private settingsService: TaskingAppSettingsService) {}

  ngOnInit() {
    this.settingsService
      .listAll(true)
      .pipe(
        tap((settings) => {
          this.form = settings.asFormGroup();
        }),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() => this.settingsService.update(Settings.fromFormGroup(this.form))),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
