import { Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';
import { Constants } from '@modules/common/types/Constants';
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { isNil } from '@modules/common/utils/base';
import { isSameDay } from '@modules/common/utils/date';
import { catchError, filter, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

export interface StitchListItem<DATA extends Stitch> {
  data: DATA;
  section: unknown;
  showSectionHeader?: boolean;
}

@Component({ template: '' })
export abstract class BaseStitchListComponent<ITEM extends Stitch> implements OnInit, OnDestroy {
  private static readonly EXTRACT_TEXT_TAG = new RegExp('(?:[\\[\\(\\{])([^\\]\\)]+)[\\]\\)\\}]');

  @Output()
  public readonly loadInProgress = new EventEmitter<boolean>();

  public readonly pageSize = Constants.PAGE_SIZE;
  public page: number = 1;
  public loading = false;
  public loadingError?: unknown;
  public totalCount: number;

  public readonly resetSelected = new Subject<void>();
  protected readonly alive = new Subject<void>();
  protected readonly loadItems = new Subject<{ start: number; end: number }>();
  private readonly scrollToPositionSubject = inject(ScrollToPosition, { self: true });

  public abstract items: StitchListItem<ITEM>[];
  public abstract itemElements: QueryList<ElementRef<HTMLElement>>;
  public abstract filters: Partial<StitchFilters<ITEM>>;
  protected abstract getItems(offset: number, limit: number): Observable<{ items: ITEM[]; count: number }>;

  public ngOnInit(): void {
    this.loadItems
      .pipe(
        tap(() => {
          this.loadInProgress.emit(true);
          this.loadingError = undefined;
        }),
        switchMap((range) =>
          this.getItems(range.start, range.end - range.start + 1).pipe(
            catchError((err) => {
              this.loadInProgress.emit(false);
              this.loadingError = err;
              return of();
            }),
          ),
        ),
        filter((data) => !!data),
        takeUntil(this.alive),
        tap(() => {
          this.loadInProgress.emit(false);
        }),
      )
      .subscribe((response) => {
        this.items = this.convertToItems(response.items);
        this.totalCount = response.count;
      });

    this.loadInProgress.pipe(takeUntil(this.alive)).subscribe((value: boolean) => (this.loading = value));

    this.scrollToPositionSubject
      .pipe(
        filter((index) => !isNil(index)),
        takeUntil(this.alive),
      )
      .subscribe((index) => {
        this.scrollToIndex(index);
      });

    this.resetItems();
  }

  public ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  public loadPage(page: number): void {
    this.loadItems.next({
      start: (page - 1) * this.pageSize,
      end: page * this.pageSize - 1,
    });
  }

  public resetItems() {
    this.resetSelected.next();
    this.items = [];
    this.page = 1;
    this.loadPage(this.page);
  }

  private scrollToIndex(index: number) {
    const el = this.itemElements.get(index);
    el && el.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private convertToItems(items: ITEM[]): StitchListItem<ITEM>[] {
    return items.reduce((arr: StitchListItem<ITEM>[], item: ITEM) => {
      const converted = this.convertToItem(item);
      converted.showSectionHeader = this.doNeedToShowGroupSeparator(converted.section, arr[arr.length - 1]?.section);
      arr.push(converted);
      return arr;
    }, []);
  }

  private convertToItem(data: ITEM): StitchListItem<ITEM> {
    return {
      data,
      section: this.getSection(data),
    };
  }

  private getSection(item: ITEM): unknown | undefined {
    if (
      (item.pinned && this.filters.pinnedOnTop) ||
      (item.flagged && this.filters.flaggedOnTop) ||
      (item.snoozed && this.filters.snoozedOnTop) ||
      (item.followed && this.filters.followedOnTop)
    ) {
      return undefined;
    }

    return this.getSectionValue(item);
  }

  private doNeedToShowGroupSeparator(sectionValue: unknown, prevSectionValue?: unknown): boolean {
    if (sectionValue instanceof Date && prevSectionValue instanceof Date) {
      return !isSameDay(sectionValue, prevSectionValue);
    }
    return sectionValue !== prevSectionValue;
  }

  protected getSectionValue(item: ITEM): unknown | undefined {
    const value = item[this.filters.sortBy as keyof ITEM];
    if (value) {
      if (value instanceof Date) {
        return value;
      } else if (typeof value === 'string') {
        const match = value.match(BaseStitchListComponent.EXTRACT_TEXT_TAG);
        if (match) {
          return match[1];
        } else {
          return value[0].toUpperCase();
        }
      }
    }
    return undefined;
  }
}
