<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Connections"
  [active]="value === KPListType.connection"
  (execute)="select(KPListType.connection)"
/>

<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Messages"
  [active]="value === KPListType.message"
  (execute)="select(KPListType.message)"
/>

<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Events"
  [active]="value === KPListType.event"
  (execute)="select(KPListType.event)"
/>

<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Tasks"
  [active]="value === KPListType.task"
  (execute)="select(KPListType.task)"
/>

<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Notes"
  [active]="value === KPListType.note"
  (execute)="select(KPListType.note)"
/>

<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Contacts"
  [active]="value === KPListType.contact"
  (execute)="select(KPListType.contact)"
/>

<app-context-menu-item
  [close]="true"
  [withCheckOption]="true"
  title="Files"
  [active]="value === KPListType.file"
  (execute)="select(KPListType.file)"
/>
