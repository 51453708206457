import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'past',
  pure: true,
  standalone: false,
})
export class PastPipe implements PipeTransform {
  transform(value: Date): boolean {
    if (!value) {
      return false;
    }

    return value.getTime() < Date.now();
  }
}
