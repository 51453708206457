import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  standalone: false,
})
export class MapPipe implements PipeTransform {
  transform<T>(value: T[], property: keyof T): T[typeof property][] {
    return value && value.map((item) => item[property]);
  }
}
