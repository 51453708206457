<app-context-menu-item
  *ngFor="let option of sortOptions"
  [title]="option.title"
  [active]="state?.sort?.by === option.key"
  [secondary]="state?.sort?.secondaryBy === option.key"
  [badge]="state?.sort?.by === option.key || state?.sort?.secondaryBy === option.key"
  [sortable]="true"
  [reversed]="
    (state?.sort?.by === option.key && state?.sort?.order === 'desc') ||
    (state?.sort?.secondaryBy === option.key && state?.sort?.secondaryOrder === 'desc')
  "
  (execute)="sort(option.key)"
/>

<app-context-menu-divider *ngIf="neighbours" />

<app-context-menu-item
  *ngIf="neighbours"
  title="3 in a row (neo4j)"
  [active]="state?.filters?.threeInARow"
  (execute)="setBooleanFilter('threeInARow')"
/>

<app-context-menu-item
  *ngIf="neighbours"
  title="Common neighbours (neo4j)"
  [active]="state?.filters?.commonNeighbours"
  (execute)="setBooleanFilter('commonNeighbours')"
/>

<app-context-menu-item
  *ngIf="global"
  title="Manage My Knots"
>
  <app-knots-form
    (close)="handleClose()"
  />
</app-context-menu-item>
