import { ComponentRef, Directive, inject, Input, OnInit } from '@angular/core';
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { startWith, takeUntil } from 'rxjs/operators';
import { EventsListContextMenuComponent } from '../components/events-list-context-menu/events-list-context-menu.component';
import { EventsListStateService } from '../services/events-list-state.service';

@Directive({
  selector: '[appEventsListContextMenu]',
  standalone: false,
})
export class EventsListContextMenuDirective extends ContextMenuDirective implements OnInit {
  @Input()
  public appEventsListContextMenuWithAdvancedSearch: boolean;
  @Input()
  public stchPopoverTrigger: PopoverTrigger = 'click';
  @Input()
  public stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input()
  public appEventsListContextMenuWithScore: boolean;

  private readonly eventsListStateService = inject(EventsListStateService);

  public registerInstance(componentRef: ComponentRef<EventsListContextMenuComponent>) {
    componentRef.instance.state = this.eventsListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appEventsListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appEventsListContextMenuWithScore;
  }

  public registerComponent() {
    return EventsListContextMenuComponent;
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.eventsListStateService.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.isDefaultStateObservable?.next(this.eventsListStateService.state.isDefault());
      });

    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.eventsListStateService.state.isDefault()) {
        this.eventsListStateService.state.resetToDefault();
      }
    });
  }
}
