// Common
import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// Types
import { Knot } from '@modules/knots/types/knot';

@Component({
  selector: 'app-knot-context-menu',
  templateUrl: './knot-context-menu.component.html',
  styleUrls: ['./knot-context-menu.component.less'],
  standalone: false,
})
export class KnotContextMenuComponent {
  @Input() knot: Knot;
  @Input() withDetach = false;

  @Output() delete = new EventEmitter();
  @Output() pinned = new EventEmitter();

  constructor(
    private knotsService: KnotsService,
    private router: Router,
    private searchService: AdvancedSearchService,
    @Optional() @Inject(CLOSE_POPOVER) private closePopoverToken,
  ) {}

  /**
   * Actions
   */

  detachKnot() {
    this.delete.emit();
  }

  pinKnot() {
    this.pinned.emit(this.knot.pinned);
  }

  handleClose() {
    this.closePopoverToken?.next();
  }

  deleteKnot() {
    if (this.knot.id) {
      this.knotsService.deletePermanently({ ids: [this.knot.id] });
    } else {
      this.delete.emit();
    }
  }

  autodiscoverKnot(enabled: boolean) {
    this.knotsService.setAutoDiscovery([this.knot], enabled);
  }

  showRelatedEmails(): void {
    this.searchService.addKnowledgeItem(this.knot);
  }

  showRelatedKnots(): void {
    this.router.navigate(['/insights/search']);
  }

  searchExternal(searchEngine: string = 'google'): void {
    const knotNameParsed = this.knot.name.replace(/\s+/g, '+').toLowerCase();

    switch (searchEngine) {
      case 'bing':
        window.open('https://www.bing.com/news/search?q=' + knotNameParsed, '_blank');
        break;
      case 'google':
      default:
        window.open('https://www.google.com/search?q=' + knotNameParsed, '_blank');
        break;
    }
  }
}
