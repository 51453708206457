// Common
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Services
import { AccountService } from '@modules/account/services/account.service';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Utils
import { UserValidator } from '@modules/auth/validators/user.validator';

@Component({
  selector: 'app-settings-general-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less'],
  standalone: false,
})
export class ChangePasswordComponent {
  public passwordsForm = new FormGroup(
    {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      passwordConfirmation: new FormControl('', Validators.required),
    },
    [UserValidator.passwordConfirmation('newPassword', 'passwordConfirmation')],
  );

  constructor(
    private accountService: AccountService,
    @Inject(CloseToken) private closeToken,
  ) {}

  handleCancel() {
    this.closeToken.next();
  }

  handleSave() {
    const { oldPassword, newPassword } = this.passwordsForm.value;
    if (!this.passwordsForm.valid) {
      return;
    }

    this.accountService.changePassword(oldPassword, newPassword).subscribe(() => {
      // TODO rise error in any case
      this.closeToken.next();
    });
  }
}
