import { ComponentRef, Directive, inject, Input, OnInit } from '@angular/core';
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { startWith, takeUntil } from 'rxjs/operators';
import { FoldersListContextMenuComponent } from '../components/folders-list-context-menu/folders-list-context-menu.component';
import { MessageFolderListStateService } from '../services/message-folder-list-state-holder.service';

@Directive({
  selector: '[appMessagesFoldersListContextMenu]',
  standalone: false,
})
export class MessagesFoldersListContextMenuDirective extends ContextMenuDirective implements OnInit {
  @Input()
  public appMessagesFoldersListContextMenuWithAdvancedSearch: boolean;
  @Input()
  public appMessagesFoldersListContextMenuWithScore: boolean;
  @Input()
  public stchPopoverTrigger: PopoverTrigger = 'click';
  @Input()
  public stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input()
  public stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  private readonly messageFolderListStateService = inject(MessageFolderListStateService);

  /**
   * Context Menu Interface
   */

  public registerInstance(componentRef: ComponentRef<FoldersListContextMenuComponent>) {
    componentRef.instance.state = this.messageFolderListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appMessagesFoldersListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appMessagesFoldersListContextMenuWithScore;
  }

  public registerComponent() {
    return FoldersListContextMenuComponent;
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.messageFolderListStateService.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.isDefaultStateObservable?.next(this.messageFolderListStateService.state.isDefault());
      });

    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.messageFolderListStateService.state.isDefault()) {
        this.messageFolderListStateService.state.resetToDefault();
      }
    });
  }
}
