<div class="form">
  <div class="form-body">
    <app-input
      appearance="sapphire-outline"
      placeholder="Import Name"
      withClear="true"
      [inputFormControl]="form.controls.folderName"
      [validationMessages]="{ required: 'Please enter a value' }"
      [withErrors]="submitted"
    />

    <app-files-input
      class="form-file-input"
      [titleFormControl]="form.controls.folderName"
      [inputFormControl]="form.controls.attachment"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Please select a file to upload',
        size: 'File is too large. Maximum size 100GB.',
        mimeTypes: 'Unacceptable file type, only .mbox, .pab, .pst and .ost are allowed',
        uploading: 'Can\'t upload file'
      }"
    />
  </div>

  <div class="form-buttons">
    <app-button
      class="form-buttons-item"
      label="CANCEL"
      appearance="purple-outline"
      (click)="handleCancel()"
    />

    <app-button
      class="form-buttons-item"
      label="SAVE"
      appearance="purple"
      (click)="handleSubmit()"
    />
  </div>
</div>
