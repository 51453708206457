<div class="form">
  <div class="form-body">
    <app-input
      class="input"
      appearance="amethyst"
      label="Title"
      [inputFormControl]="form.controls.title"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />

    <app-textarea
      class="input"
      appearance="amethyst"
      [inputFormControl]="form.controls.description"
      [rows]="8"
      [withClear]="true"
      [invertedColor]="true"
      label="Description"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Monthly Price (in subunits. For example cents)"
      [inputFormControl]="form.controls.monthlyPrice"
      [number]="true"
      [min]="0"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank',
        min: 'Min 50 cents'
      }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Annually Price (in subunits. For example cents)"
      [inputFormControl]="form.controls.annuallyPrice"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank',
        min: 'Min 50 cents'
      }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Number Of Spaces"
      [inputFormControl]="form.controls.maxSpaces"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Number Of Space Members"
      [inputFormControl]="form.controls.maxSpaceMembers"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Number Of Teams"
      [inputFormControl]="form.controls.maxTeams"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Number Of Team Members"
      [inputFormControl]="form.controls.maxTeamMembers"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{ required: 'Can\'t be blank' }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Number Of Inboxes"
      [inputFormControl]="form.controls.maxInboxes"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Disk Space"
      [inputFormControl]="form.controls.maxDiskSpace"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Additional Disk Space Price Per 1Gb"
      [inputFormControl]="form.controls.additionalDiskSpacePricePerGb"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.taskingAppEnabled"
      title="Tasking App Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.mailAppEnabled"
      title="Mail App Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.calendarAppEnabled"
      title="Calendar App Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.notesAppEnabled"
      title="Notes App Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.contactsAppEnabled"
      title="Contacts App Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.filesAppEnabled"
      title="Files App Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.knotsEnabled"
      title="Knots Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.tagsEnabled"
      title="Tags Enabled"
      appearance="amethyst"
    />

    <app-checkbox
      class="checkbox"
      [inputFormControl]="form.controls.connectionsEnabled"
      title="Connections Enabled"
      appearance="amethyst"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Knots Count"
      [inputFormControl]="form.controls.maxKnotsCount"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Tags Count"
      [inputFormControl]="form.controls.maxTagsCount"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />

    <app-input
      class="input"
      appearance="amethyst"
      label="Maximum Connections Count"
      [inputFormControl]="form.controls.maxConnectionsCount"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    />
  </div>

  <div class="form-buttons">
    <app-button
      class="form-buttons-item"
      label="CANCEL"
      appearance="purple-outline"
      (click)="handleCancel()"
    />

    <app-button
      class="form-buttons-item"
      label="SAVE"
      appearance="purple"
      (click)="handleSubmit()"
    />
  </div>
</div>
 