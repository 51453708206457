// Common
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { HistoryService } from '@modules/common/services/history.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StateKey } from '@modules/settings/types/state-key';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { Tab } from '@modules/common/types/tab';

@Component({
  selector: 'app-knowledge-panel',
  templateUrl: './knowledge-panel.component.html',
  styleUrls: ['./knowledge-panel.component.less'],
  standalone: false,
})
export class KnowledgePanelComponent implements AfterViewInit, OnDestroy {
  public resultsTypeStateKey = StateKey.kpResultsStitchType;
  public resultsTags: Tag[] = [];
  public resultsKnots: Knot[] = [];
  public resultsConnections: Connection[] = [];
  public currentTab: 'knots' | 'tags' | 'connections' = 'knots';
  public tabsValues: Tab[] = [
    { title: 'Knots', value: 'knots' },
    { title: 'Tags', value: 'tags' },
    { title: 'Connections', value: 'connections' },
  ];

  private alive = new Subject<void>();
  private getItemConnections = new Subject<Stitch>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private historyService: HistoryService,
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.historyService
      .onChanges()
      .pipe(takeUntil(this.alive))
      .subscribe(({ connections, knots, tags }) => {
        this.resultsKnots = knots;
        this.resultsTags = tags;
        this.resultsConnections = connections;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClickItem(item: Stitch) {
    this.getItemConnections.next(item);
  }

  handleTabsChange(value: 'knots' | 'tags' | 'connections') {
    this.currentTab = value;
    this.changeDetector.detectChanges();
  }
}
