<div class="heading">
  <div class="heading-grow">
    <div class="heading1">Payment methods</div> 
    <div class="heading2-subtitle">
      Set up your Payment Method. No commitments. Cancel online anytime.
    </div>
  </div>

  <app-button
    icon="plus-medium"
    appearance="link-blue"
    label="ADD STRIPE"
    (click)="openStripeForm()"
  />
</div>

<stch-table
  class="items"
  [items]="paymentMethods"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
/>

<ng-template #dateTemplate let-method=item>
  {{ method.createdAt | date : 'mediumDate' }}
</ng-template>

<ng-template #defaultTemplate let-method=item>
  <app-toggle-switch
    [value]="method.isDefault"
    (onChange)="activate(method)"
  />
</ng-template>

<ng-template #actionsTemplate let-method=item>
  <mat-icon
    class="icon"
    svgIcon="sapphire-trash"
    matTooltip="Delete"
    (click)="removeMethodPrompt($event, method)"
  />
</ng-template>

<ng-template #cardNumberTemplate let-last4=value>
  **** **** **** {{ last4 }}
</ng-template>
