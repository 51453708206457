// Common
import { FormControl, FormGroup } from '@angular/forms';
import { mapStitchTypeToIcon } from '@modules/common/utils/stitch';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { commonFileSizes } from '@modules/files/types/common-file-sizes';
import { commonFileTypes } from '@modules/files/types/common-file-types';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';
import { Stitch } from '@modules/common/types/stitch';

// RX
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesService } from '@modules/files/services/files.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { MessagesService } from '@modules/messages/services/messages.service';

export type Condition = 'if' | 'and' | 'then';

type ParseToType = 'date';

interface StitchServices {
  [StitchType.messageFolder]: MessageFoldersService;
  [StitchType.message]: MessagesService;
  [StitchType.calendar]: CalendarsService;
  [StitchType.event]: EventsService;
  [StitchType.project]: ProjectsService;
  [StitchType.task]: TasksService;
  [StitchType.notebook]: NotebooksService;
  [StitchType.note]: NotesService;
  [StitchType.group]: GroupsService;
  [StitchType.contact]: ContactsService;
  [StitchType.folder]: FoldersService;
  [StitchType.file]: FilesService;
}

export interface Input {
  active: boolean;
  name: string;
  condition: Condition;
  type?: 'text' | 'autocomplete-multiple' | 'autocomplete' | 'date-range' | 'select' | 'priority' | 'country';
  control?: FormControl | FormGroup;
  suggestions?: AutocompleteFactory<unknown>;
  useInputText?: boolean;
  useInputTextGetValue?: (value: string) => any;
  itemStyles?: any;
  itemTitlePattern?: string;
  key: string;
  fromLabel?: string;
  toLabel?: string;
  parseTo?: { [key: string]: ParseToType };
  itemIcon?: string;
  options?: DropdownSelectItem<unknown>[];
  sideOptions?: DropdownSelectItem<StitchType>[];
}

export const stitchTypeOptions: DropdownSelectItem<StitchType>[] = [
  { title: 'Message Folder', value: StitchType.messageFolder },
  { title: 'Message', value: StitchType.message },
  { title: 'Calendar', value: StitchType.calendar },
  { title: 'Event', value: StitchType.event },
  { title: 'Project', value: StitchType.project },
  { title: 'Task', value: StitchType.task },
  { title: 'Notebook', value: StitchType.notebook },
  { title: 'Note', value: StitchType.note },
  { title: 'Group', value: StitchType.group },
  { title: 'Contact', value: StitchType.contact },
  { title: 'Folder', value: StitchType.folder },
  { title: 'File', value: StitchType.file },
];

const generateCommonSchema = ({
  knotsService,
  tagsService,
  stitchServices,
}: {
  knotsService: KnotsService;
  tagsService: TagsService;
  stitchServices: StitchServices;
}): Input[] => [
  {
    condition: 'and',
    active: false,
    name: 'Stitched With',
    key: 'stitched_with',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: getMixedAutocompleteFactory(stitchServices),
    sideOptions: stitchTypeOptions,
  },
  {
    condition: 'and',
    active: false,
    name: 'Has Knots',
    key: 'has_knots',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: (title?: string, values?: string[], config?: { limit: number }) => {
      if (title && title.trim() !== '') {
        return knotsService.search({ limit: config?.limit || 5, query: title }).pipe(
          map(({ items: knots }) =>
            knots.map((knot) => ({
              title: knot.name,
              value: knot.name,
              source: knot,
            })),
          ),
        );
      } else if (values?.length > 0) {
        return of(values.map((value) => ({ title: value, value, source: new Knot({ name: value }) })));
      } else {
        return of([]);
      }
    },
    useInputText: true,
    useInputTextGetValue: (value: string) => ({ title: value, value, source: new Knot({ name: value }) }),
    itemStyles: { backgroundColor: '#E3DCF7' },
  },
  {
    condition: 'and',
    active: false,
    name: 'Has Tags',
    key: 'has_tags',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: (title?: string, values?: string[], config?: { limit: number }) => {
      if (title && title.trim() !== '') {
        return tagsService.search({ limit: config?.limit || 5, query: title }).pipe(
          map(({ items: tags }) =>
            tags.map((tag) => ({
              title: '#' + tag.name,
              value: tag.name,
              source: tag,
            })),
          ),
        );
      } else if (values?.length > 0) {
        return of(values.map((value) => ({ title: value, value, source: new Tag({ name: value }) })));
      } else {
        return of([]);
      }
    },
    useInputText: true,
    useInputTextGetValue: (value: string) => ({ title: value, value, source: new Tag({ name: value }) }),
    itemStyles: { backgroundColor: 'transparent', color: '#5499F2' },
    itemTitlePattern: '#$title',
  },
  {
    condition: 'and',
    active: false,
    name: 'Has Words',
    key: 'has_words',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: (_title?: string, values?: string[], _config?: { limit: number }) => {
      if (values?.length > 0) {
        return of(values.map((value) => ({ title: value, value, source: value })));
      } else {
        return of([]);
      }
    },
    useInputText: true,
    useInputTextGetValue: (value: string) => ({ title: value, value, source: value }),
  },
  {
    condition: 'then',
    active: false,
    name: 'Stitch To',
    key: 'stitch_to',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: getMixedAutocompleteFactory(stitchServices),
    sideOptions: stitchTypeOptions,
  },
  {
    condition: 'then',
    active: false,
    name: 'Add Knots',
    key: 'add_knots',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: (title?: string, values?: string[], config?: { limit: number }) => {
      if (title && title.trim() !== '') {
        return knotsService.search({ limit: config?.limit || 5, query: title }).pipe(
          map(({ items: knots }) =>
            knots.map((knot) => ({
              title: knot.name,
              value: knot.name,
              source: knot,
            })),
          ),
        );
      } else if (values?.length > 0) {
        return of(values.map((value) => ({ title: value, value, source: new Knot({ name: value }) })));
      } else {
        return of([]);
      }
    },
    useInputText: true,
    useInputTextGetValue: (value: string) => ({ title: value, value, source: new Knot({ name: value }) }),
    itemStyles: { backgroundColor: '#E3DCF7' },
  },
  {
    condition: 'then',
    active: false,
    name: 'Add Tags',
    key: 'add_tags',
    type: 'autocomplete-multiple',
    control: new FormControl<string[]>([]),
    suggestions: (title?: string, values?: string[], config?: { limit: number }) => {
      if (title && title.trim() !== '') {
        return tagsService.search({ limit: config?.limit || 5, query: title }).pipe(
          map(({ items: tags }) =>
            tags.map((tag) => ({
              title: '#' + tag.name,
              value: tag.name,
              source: tag,
            })),
          ),
        );
      } else if (values?.length > 0) {
        return of(values.map((value) => ({ title: value, value, source: new Tag({ name: value }) })));
      } else {
        return of([]);
      }
    },
    useInputText: true,
    useInputTextGetValue: (value: string) => ({ title: value, value, source: new Tag({ name: value }) }),
    itemStyles: { backgroundColor: 'transparent', color: '#5499F2' },
    itemTitlePattern: '#$title',
  },
];

export const generateSchemas = ({
  knotsService,
  tagsService,
  stitchServices,
}: {
  knotsService: KnotsService;
  tagsService: TagsService;
  stitchServices: StitchServices;
}): { [k in StitchType]: Input[] } => ({
  [StitchType.messageFolder]: [...generateCommonSchema({ knotsService, tagsService, stitchServices })],
  [StitchType.message]: [
    {
      condition: 'if',
      active: false,
      name: 'Date Range',
      key: 'date_range',
      type: 'date-range',
      control: new FormGroup({
        from: new FormControl<Date>(null),
        to: new FormControl<Date>(null),
      }),
      fromLabel: 'From',
      toLabel: 'To',
      parseTo: { from: 'date', to: 'date' },
    },
    {
      condition: 'if',
      active: false,
      name: 'From',
      key: 'from',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      useInputText: true,
      suggestions: stitchServices[StitchType.contact].getEmailAutocompleteSuggestions(),
    },
    {
      condition: 'if',
      active: false,
      name: 'To',
      key: 'to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.contact].getEmailAutocompleteSuggestions(),
      useInputText: true,
      useInputTextGetValue: (value: string) => ({
        title: value,
        value: { address: value },
        source: { address: value },
      }),
    },
    {
      condition: 'if',
      active: false,
      name: 'CC',
      key: 'cc',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.contact].getEmailAutocompleteSuggestions(),
      useInputText: true,
      useInputTextGetValue: (value: string) => ({
        title: value,
        value: { address: value },
        source: { address: value },
      }),
    },
    {
      condition: 'if',
      active: false,
      name: 'BCC',
      key: 'bcc',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.contact].getEmailAutocompleteSuggestions(),
      useInputText: true,
      useInputTextGetValue: (value: string) => ({
        title: value,
        value: { address: value },
        source: { address: value },
      }),
    },
    {
      condition: 'then',
      active: false,
      name: 'Copy To',
      key: 'copy_to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.messageFolder].getAutocompleteSuggestions(),
      itemIcon: 'folder',
    },
    {
      condition: 'then',
      active: false,
      name: 'Move To',
      key: 'move_to',
      type: 'autocomplete',
      control: new FormControl<string>(null),
      suggestions: stitchServices[StitchType.messageFolder].getAutocompleteSuggestions(),
      itemIcon: 'folder',
    },
    ...generateCommonSchema({ knotsService, tagsService, stitchServices }),
  ],
  [StitchType.calendar]: [...generateCommonSchema({ knotsService, tagsService, stitchServices })],
  [StitchType.event]: [
    {
      condition: 'if',
      active: false,
      name: 'Date Range',
      key: 'date_range',
      type: 'date-range',
      control: new FormGroup({
        from: new FormControl<Date>(null),
        to: new FormControl<Date>(null),
      }),
      fromLabel: 'Start Date',
      toLabel: 'End Date',
      parseTo: { from: 'date', to: 'date' },
    },
    {
      condition: 'if',
      active: false,
      name: 'Calendar',
      key: 'calendar',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.calendar].getAutocompleteSuggestions(),
      itemIcon: 'calendar',
    },
    {
      condition: 'if',
      active: false,
      name: 'Location',
      key: 'location',
    },
    {
      condition: 'then',
      active: false,
      name: 'Copy To',
      key: 'copy_to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.calendar].getAutocompleteSuggestions(),
      itemIcon: 'calendar',
    },
    {
      condition: 'then',
      active: false,
      name: 'Move To',
      key: 'move_to',
      type: 'autocomplete',
      control: new FormControl<string>(null),
      suggestions: stitchServices[StitchType.calendar].getAutocompleteSuggestions(),
      itemIcon: 'calendar',
    },
    ...generateCommonSchema({ knotsService, tagsService, stitchServices }),
  ],
  [StitchType.project]: [...generateCommonSchema({ knotsService, tagsService, stitchServices })],
  [StitchType.task]: [
    {
      condition: 'if',
      active: false,
      name: 'Start/Due Date',
      key: 'date_range',
      type: 'date-range',
      control: new FormGroup({
        from: new FormControl<Date>(null),
        to: new FormControl<Date>(null),
      }),
      fromLabel: 'Start Date',
      toLabel: 'Due Date',
      parseTo: { from: 'date', to: 'date' },
    },
    {
      condition: 'if',
      active: false,
      name: 'Project',
      key: 'project',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.project].getAutocompleteSuggestions(),
      itemIcon: 'project',
    },
    {
      condition: 'if',
      active: false,
      name: 'Priority',
      key: 'priority',
      type: 'priority',
      control: new FormControl<string[]>([]),
      options: [
        { title: 'Low', value: 'low', itemStyles: { backgroundColor: '#36B37E' } },
        { title: 'Mid', value: 'mid', itemStyles: { backgroundColor: '#FFAB00' } },
        { title: 'High', value: 'high', itemStyles: { backgroundColor: '#FF4766' } },
      ],
      itemStyles: { borderRadius: '4px', color: '#FFF' },
    },
    {
      condition: 'then',
      active: false,
      name: 'Copy To',
      key: 'copy_to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.project].getAutocompleteSuggestions(),
      itemIcon: 'project',
    },
    {
      condition: 'then',
      active: false,
      name: 'Move To',
      key: 'move_to',
      type: 'autocomplete',
      control: new FormControl<string>(null),
      suggestions: stitchServices[StitchType.project].getAutocompleteSuggestions(),
      itemIcon: 'project',
    },
    ...generateCommonSchema({ knotsService, tagsService, stitchServices }),
  ],
  [StitchType.notebook]: [...generateCommonSchema({ knotsService, tagsService, stitchServices })],
  [StitchType.note]: [
    {
      condition: 'if',
      active: false,
      name: 'Created/Modified Date',
      key: 'date_range',
      type: 'date-range',
      control: new FormGroup({
        from: new FormControl<Date>(null),
        to: new FormControl<Date>(null),
      }),
      fromLabel: 'Created Date',
      toLabel: 'Modified Date',
      parseTo: { from: 'date', to: 'date' },
    },
    {
      condition: 'if',
      active: false,
      name: 'Notebook',
      key: 'notebook',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.notebook].getAutocompleteSuggestions(),
      itemIcon: 'notebook',
    },
    {
      condition: 'then',
      active: false,
      name: 'Copy To',
      key: 'copy_to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.notebook].getAutocompleteSuggestions(),
      itemIcon: 'notebook',
    },
    {
      condition: 'then',
      active: false,
      name: 'Move To',
      key: 'move_to',
      type: 'autocomplete',
      control: new FormControl<string>(null),
      suggestions: stitchServices[StitchType.notebook].getAutocompleteSuggestions(),
      itemIcon: 'notebook',
    },
    ...generateCommonSchema({ knotsService, tagsService, stitchServices }),
  ],
  [StitchType.group]: [...generateCommonSchema({ knotsService, tagsService, stitchServices })],
  [StitchType.contact]: [
    {
      condition: 'if',
      active: false,
      name: 'Full Name',
      key: 'full_name',
      type: 'text',
      control: new FormControl<string>(null),
    },
    {
      condition: 'if',
      active: false,
      name: 'Company',
      key: 'group',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.group].getAutocompleteSuggestions(),
      itemIcon: 'group',
    },
    {
      condition: 'if',
      active: false,
      name: 'Country',
      key: 'country',
      type: 'country',
      control: new FormControl<string>(null),
    },
    {
      condition: 'if',
      active: false,
      name: 'Group',
      key: 'group',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.group].getAutocompleteSuggestions(),
      itemIcon: 'groups',
    },
    {
      condition: 'then',
      active: false,
      name: 'Copy To',
      key: 'copy_to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.group].getAutocompleteSuggestions(),
      itemIcon: 'groups',
    },
    {
      condition: 'then',
      active: false,
      name: 'Move To',
      key: 'move_to',
      type: 'autocomplete',
      control: new FormControl<string>(null),
      suggestions: stitchServices[StitchType.group].getAutocompleteSuggestions(),
      itemIcon: 'groups',
    },
    ...generateCommonSchema({ knotsService, tagsService, stitchServices }),
  ],
  [StitchType.folder]: [...generateCommonSchema({ knotsService, tagsService, stitchServices })],
  [StitchType.file]: [
    {
      condition: 'if',
      active: false,
      name: 'Received/Uploaded Date',
      key: 'date_range',
      type: 'date-range',
      control: new FormGroup({
        from: new FormControl<Date>(null),
        to: new FormControl<Date>(null),
      }),
      fromLabel: 'Received Date',
      toLabel: 'Uploaded Date',
      parseTo: { from: 'date', to: 'date' },
    },
    {
      condition: 'if',
      active: false,
      name: 'File Type',
      key: 'file_type',
      type: 'select',
      control: new FormControl<string[]>(null),
      options: commonFileTypes,
    },
    {
      condition: 'if',
      active: false,
      name: 'File Size',
      key: 'file_size',
      type: 'select',
      control: new FormControl<{ from?: number; to?: number }[]>(null),
      options: commonFileSizes,
    },
    {
      condition: 'if',
      active: false,
      name: 'Folder',
      key: 'folder',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.folder].getAutocompleteSuggestions(),
      itemIcon: 'folder',
    },
    {
      condition: 'then',
      active: false,
      name: 'Copy To',
      key: 'copy_to',
      type: 'autocomplete-multiple',
      control: new FormControl<string[]>([]),
      suggestions: stitchServices[StitchType.folder].getAutocompleteSuggestions(),
      itemIcon: 'folder',
    },
    {
      condition: 'then',
      active: false,
      name: 'Move To',
      key: 'move_to',
      type: 'autocomplete',
      control: new FormControl<string>(null),
      suggestions: stitchServices[StitchType.folder].getAutocompleteSuggestions(),
      itemIcon: 'folder',
    },
    ...generateCommonSchema({ knotsService, tagsService, stitchServices }),
  ],
});

export const getFiltersFromTab = (tab) =>
  ({
    mail: [StitchType.message, StitchType.messageFolder],
    calendar: [StitchType.event, StitchType.calendar],
    tasking: [StitchType.task, StitchType.project],
    notes: [StitchType.note, StitchType.notebook],
    contacts: [StitchType.contact, StitchType.group],
    files: [StitchType.file, StitchType.folder],
  })[tab];

export const castInputValue = (input: Input, value: unknown): unknown => {
  if (Array.isArray(value)) {
    // no such controls yet
    return value;
  } else if (typeof value === 'object') {
    return Object.fromEntries(
      Object.entries(value).map(([k, v]) => [k, input.parseTo?.[k] ? castSingleValue(v, input.parseTo[k]) : v]),
    );
  } else {
    // no such controls yet
    return value;
  }
};

const castSingleValue = (value: unknown, parseTo: ParseToType): unknown => {
  if (typeof value !== 'string') {
    return;
  }

  switch (parseTo) {
    case 'date':
      return value ? new Date(value) : null;
    default:
      return value;
  }
};

const getMixedAutocompleteFactory = (
  stitchServices: StitchServices,
): AutocompleteFactory<{ id: string; title: string; type: StitchType; icon: string }> => {
  return (
    title: string,
    values: { id: string; title: string; type: StitchType; icon: string }[],
    { limit, sideValue }: { limit?: number; sideValue?: StitchType; except?: any[] } = {},
  ) => {
    if (values?.length > 0) {
      return of(
        values.map((value) => ({
          title: value.title,
          value,
          valueIcon: value.icon,
        })),
      );
    } else {
      const derevo: Observable<DropdownSelectItem<Stitch>[]> = stitchServices[sideValue].getAutocompleteSuggestions()(
        title?.trim(),
        null,
        { limit },
      );

      return derevo.pipe(
        map((items) =>
          items.map(({ title: item, value }) => ({
            title: item,
            value: { id: value, title, type: sideValue, icon: mapStitchTypeToIcon[sideValue] },
            valueIcon: mapStitchTypeToIcon[sideValue],
          })),
        ),
      );
    }
  };
};
