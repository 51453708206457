// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { Task } from '@modules/tasks/types/task';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-link-info-task',
  templateUrl: './link-info-task.component.html',
  styleUrls: ['./link-info-task.component.less'],
  standalone: false,
})
export class LinkInfoTaskComponent {
  // Inputs
  @Input() task: Task;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.task &&
    !(dragData.type === DragDataTypes.task && dragData.data.length === 1 && dragData.data[0]['id'] === this.task.id) &&
    dragDataTypeAllowed(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private tasksService: TasksService,
    private stitchService: StitchService,
  ) {}

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.tasksService
      .flag({ ids: [this.task.id] }, !this.task.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.task.flagged = !this.task.flagged));
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.tasksService
      .pin({ ids: [this.task.id] }, !this.task.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.task.pinned = !this.task.pinned));
  }

  archive() {
    this.tasksService
      .archive({ ids: [this.task.id] }, !this.task.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.task.archived = !this.task.archived));
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.task.deleted
        ? this.tasksService.deletePermanently({ ids: [this.task.id] })
        : this.tasksService.delete({ ids: [this.task.id] }, true);
    }
  }

  dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.task, dragData);
  }
}
