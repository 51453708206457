// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@modules/utils/utils.module';

// Modules
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { MatIconModule } from '@angular/material/icon';

// Components
import { AttachmentComponent } from './components/attachment/attachment.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';

// Services
import { AttachmentsService } from './services/attachments.service';

@NgModule({
  imports: [CommonModule, FormControlsModule, UtilsModule, MatIconModule],
  declarations: [AttachmentsComponent, AttachmentComponent],
  exports: [AttachmentsComponent],
  providers: [AttachmentsService],
})
export class AttachmentsModule {}
