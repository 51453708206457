// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

// RX
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, filter } from 'rxjs/operators';

// Env
import { environment } from '@environment';

// Types
import { Upload } from '../types/upload';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class UploadsService {
  constructor(
    protected http: HttpClient,
    protected toasterService: ToasterService,
  ) {}

  upload(upload: Upload): Observable<{ id: string; success: boolean }> {
    const formData: FormData = new FormData();

    formData.append('upload', upload.nativeFile);

    const req = new HttpRequest('POST', `${environment.baseUrl}/api/attachments/temp`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request<{ id: string; success: boolean }>(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          upload?.progress?.next(Math.round((100 * event.loaded) / event.total));
        }
      }),
      filter((event) => event instanceof HttpResponse),
      tap(() => {
        upload?.progress?.next(100);
      }),
      map((event) => ({ success: event['body']['success'], id: event['body']['id'] })),
      catchError((error: HttpErrorResponse) => {
        this.toasterService.show({ text: error.error.error });
        return throwError(error);
      }),
    );
  }

  @warmUpObservable
  delete(ids: string[]): Observable<boolean> {
    return this.http
      .delete<{ success: boolean }>(`${environment.baseUrl}/api/attachments/temp`, { params: { 'ids[]': ids } })
      .pipe(map(({ success }) => success));
  }
}
