// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// Types
import { PlansFilters } from '../types/subscription-plans-filters';
import { SubscriptionPlan } from '../types/subscription-plan';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class SubscriptionPlansService extends BaseRestService<SubscriptionPlan, PlansFilters> {
  private url = environment.baseUrl + '/api/subscription-plans';

  constructor(
    private http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super(toaster);
  }

  search(): Observable<BaseSearchResponse<SubscriptionPlan>> {
    return this.http.get<{ count: number; items: SubscriptionPlan[] }>(this.url).pipe(
      map(({ count, items }) => ({
        count,
        items: items.map((item) => new SubscriptionPlan(item)),
      })),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  create(): Observable<SubscriptionPlan> {
    throw new Error('Method forbidden');
  }

  update(): Observable<SubscriptionPlan> {
    throw new Error('Method forbidden');
  }

  migrateToPlan(
    plan: SubscriptionPlan,
    chargeType: 'monthly' | 'annually',
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true },
  ): Observable<boolean> {
    return this.http.put<{ success: boolean }>(`${this.url}/${plan.id}`, { chargeType }).pipe(
      tap(({ success }) => {
        if (success) {
          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Successfully migrated to '${plan.title}'` });
        }
      }),
      map(({ success }) => success),
      catchError((error) => this.handleObserverError(error)),
    );
  }

  delete(): Observable<boolean> {
    throw new Error('Method forbidden');
  }
}
