// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Utils
import { addDays } from '@modules/common/utils/date';

// Types
import { priorities } from '@modules/form-controls/types/priorities';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';

@Component({
  selector: 'app-tasks-list-context-menu',
  templateUrl: './tasks-context-menu.component.html',
  styleUrls: ['./tasks-context-menu.component.less'],
  standalone: false,
})
export class TasksContextMenuComponent {
  // Input
  @Input() filterKey: VirtualFolder;

  // Outputs
  @Output() change = new EventEmitter<VirtualFolder>();

  // Public
  public nextWeekDays: Date[];
  public prioritiesList = priorities;

  /**
   * Constructor
   */

  constructor() {
    const today = new Date();
    this.nextWeekDays = Array.from({ length: 7 }, (_v, k) => addDays(today, k + 1));
  }

  /**
   * Actions
   */

  selectFilter(filter: VirtualFolder) {
    this.change.emit(filter);
  }
}
