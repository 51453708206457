<nav aria-label="Pagination control" class="px-8 py-6 gap-4 flex text-gray-500 items-center">

  <ul class="flex gap-4">
    <li class="leading-0">
      <app-button2 aria-label="Refresh"
        appearance="secondary"
        size="s"
        (click)="refresh.emit()">
        <app-icon name="refresh"/>
      </app-button2>
    </li>
    <li class="leading-0">
      <app-button2 aria-label="Previous page"
        appearance="secondary"
        size="s"
        [disabled]="page() <= 1 || totalItems() === 0"
        (click)="prevPage()">
        <app-icon name="chevron-left"/>
      </app-button2>
    </li>
    <li class="leading-0">
      <app-button2 aria-label="Next page"
        appearance="secondary"
        size="s"
        [disabled]="isLastPage() || totalItems() === 0"
        (click)="nextPage()">
        <app-icon name="chevron-right"/>
      </app-button2>
    </li>
  </ul>

  <span class="grow"></span>

  <span class="text-sm text-nowrap" [hidden]="totalItems() === 0">
    {{showingFrom()}}-{{showingTo() ?? '?'}} / {{totalItems() ?? '?'}}
  </span>
</nav>