import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertModule } from '@modules/alert/alert.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { CalendarsListComponent } from '@modules/calendar-app/components/calendars-list/calendars-list.component';
import { EventsSideBarComponent } from '@modules/calendar-app/components/side-bar/side-bar.component';
import { SidebarContextMenuComponent } from '@modules/calendar-app/components/sidebar-context-menu/sidebar-context-menu.component';
import { CommentsModule } from '@modules/comments/comments.module';
import { ListLoadErrorComponent } from '@modules/common/components/list/list-load-error/list-load-error.component';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { HideTooltipEventTitleFormatter } from '@modules/full-calendar/providers/hide-tooltip-event-title-formatter.provider';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { TagsModule } from '@modules/tags/tags.module';
import { UtilsModule } from '@modules/utils/utils.module';
import { CalendarEventTitleFormatter } from 'angular-calendar';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ElementsModule } from '../elements/elements.module';
import { FullCalendarModule } from '../full-calendar/full-calendar.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { AgendaEventComponent } from './components/agenda-event/agenda-event.component';
import { CalendarContextMenuComponent } from './components/calendar-context-menu/calendar-context-menu.component';
import { CalendarFormComponent } from './components/calendar-form/calendar-form.component';
import { CalendarSectionsComponent } from './components/calendar-sections/calendar-sections.component';
import { CalendarSharedComponent } from './components/calendar-shared/calendar-shared.component';
import { CalendarsListContextMenuComponent } from './components/calendars-list-context-menu/calendars-list-context-menu.component';
import { EventFormComponent } from './components/event-form/event-form.component';
import { EventPopoverFormComponent } from './components/event-popover-form/event-popover-form.component';
import { EventSharedComponent } from './components/event-shared/event-shared.component';
import { EventsListContainerComponent } from './components/events-list-container/events-list-container.component';
import { EventsListContextMenuComponent } from './components/events-list-context-menu/events-list-context-menu.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { FullCalendarMonthComponent } from './components/full-calendar-month/full-calendar-month.component';
import { FullCalendarComponent } from './components/full-calendar/full-calendar.component';
import { MainViewComponent } from './components/main-view/main-view.component';
import { ParticipantsInputComponent } from './components/participants-input/participants-input.component';
import { SideBarCalendarsTreeComponent } from './components/side-bar-calendars-tree/side-bar-calendars-tree.component';
import { SideBarCalendarsComponent } from './components/side-bar-calendars/side-bar-calendars.component';
import { CalendarContextMenuDirective } from './directives/calendar-context-menu-directive/calendar-context-menu.directive';
import { CalendarsListContextMenuDirective } from './directives/calendars-list-context-menu.directive';
import { EventsListContextMenuDirective } from './directives/events-list-context-menu.directive';
import { SidebarContextMenuDirective } from './directives/sidebar-context-menu.directive';
import { IsEventCutPipe } from './pipes/is-event-cut.pipe';
import { TitleByEventsFilterPipe } from './pipes/title-by-events-filter.pipe';
import { NoContentStubComponent } from '@modules/common/components/list/no-content-stub/no-content-stub.component';
import { ListItemSeparatorComponent } from '@modules/common/components/list/list-items-separator/list-items-separator.component';
import { Pagination2Component } from '@modules/common/components/pagination/pagination2.component';
import { LoadingIndicatorComponent } from '@modules/common/components/loading-indicator/loading-indicator.component';

@NgModule({
  imports: [
    AlertModule,
    AppCommonModule,
    AttachmentsModule,
    CommentsModule,
    CommonModule,
    ConnectionsModule,
    DragNDropModule,
    ElementsModule,
    FormControlsModule,
    FullCalendarModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    ScrollModule,
    ScrollingModule,
    TagsModule,
    UserAvatarModule,
    UtilsModule,
    ListLoadErrorComponent,
    NoContentStubComponent,
    ListItemSeparatorComponent,
    Pagination2Component,
    LoadingIndicatorComponent,
  ],
  exports: [
    CalendarFormComponent,
    CalendarsListComponent,
    EventFormComponent,
    EventsListComponent,
    EventsListContainerComponent,
    EventsListContextMenuDirective,
    EventsSideBarComponent,
    MainViewComponent,
    TitleByEventsFilterPipe,
  ],
  declarations: [
    AgendaEventComponent,
    CalendarContextMenuComponent,
    CalendarFormComponent,
    CalendarSectionsComponent,
    CalendarSharedComponent,
    CalendarsListComponent,
    CalendarsListContextMenuComponent,
    EventFormComponent,
    EventPopoverFormComponent,
    EventSharedComponent,
    EventsListComponent,
    EventsListContainerComponent,
    EventsListContextMenuComponent,
    EventsSideBarComponent,
    FullCalendarComponent,
    FullCalendarMonthComponent,
    MainViewComponent,
    ParticipantsInputComponent,
    SideBarCalendarsComponent,
    SideBarCalendarsTreeComponent,
    SidebarContextMenuComponent,

    // Directives
    CalendarContextMenuDirective,
    CalendarsListContextMenuDirective,
    EventsListContextMenuDirective,
    SidebarContextMenuDirective,

    // Pipes
    IsEventCutPipe,
    TitleByEventsFilterPipe,
  ],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: HideTooltipEventTitleFormatter,
    },
  ],
})
export class CalendarAppModule {}
