<div class="placeholder" *ngIf="!this.focused && this.empty">
  {{ placeholderValue }}
</div>

<div class="time-input" [formGroup]="parts" [ngClass]="{transparent: !this.focused && this.empty}">
  <input
    #hoursInput
    class="hours"
    formControlName="hours"
    size="2"
    (keydown)="handleHoursKeyDown($event)"
    (blur)="handleHoursBlur($event)"
    (keypress)="handleHoursKeyPress($event)"
    (focus)="handleHoursFocus($event)"
    placeholder="00"
    [appBundledConsumer]="bundledInputConsumerKeys"
    [appBundledTransformFunction]="bundledTransformFunction"
    appBundledAnimationStrategy="highlightOnly"
    [appBundledConsumerGroup]="bundledInputConsumerGroup"
    [appBundledFormControl]="bundledInputFormControl"
    [appBundledValueCompareFunction]="bundledValueCompareFunction"
    [appBundledInputInvisible]="bundledInputInvisible"
  >
  <span>:</span>
  <input
    #minutesInput
    class="minutes"
    formControlName="minutes"
    size="2"
    (keydown)="handleMinutesKeyDown($event)"
    (blur)="handleMinutesBlur($event)"
    (keypress)="handleMinutesKeyPress($event)"
    (focus)="handleMinutesFocus($event)"
    placeholder="00"
  >
  <span>&nbsp;</span>
  <input
    #periodInput
    class="period"
    formControlName="period"
    size="2"
    (keydown)="handlePeriodKeyDown($event)"
    (blur)="handlePeriodBlur($event)"
    (keypress)="handlePeriodKeyPress($event)"
    (focus)="handlePeriodFocus($event)"
    placeholder="am"
  >
</div>
