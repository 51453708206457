// Common
import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

// RX
import { BehaviorSubject, EMPTY, fromEvent, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { PopoverService } from '../services/popover.service';

// Types
import { ComponentType } from '@angular/cdk/overlay';
import { PopoverPlacement } from '../types/placement';
import { PopoverTrigger } from '../types/trigger';

@Directive({
  selector: '[stchPopover]',
  standalone: false,
})
export class PopoverDirective implements AfterViewInit, OnChanges, OnDestroy {
  // Private
  private visibleStatus = new BehaviorSubject<boolean>(false);
  private readonly showPopover = new Subject<MouseEvent>();
  private customTrigger = new ReplaySubject<Observable<MouseEvent | void>>(1);
  private mouseEntered = false;
  private readonly innerHide = new Subject<void>();

  // Protected
  protected alive = new Subject<void>();

  // Inputs
  @Input() stchPopoverTrackMouse = false;
  /**
   * @deprecated Use 'stchPopoverTemplate' instead
   */
  @Input() stchPopoverContent: TemplateRef<void>;
  @Input() stchPopoverTemplate: TemplateRef<void>;
  @Input() stchPopoverComponent: ComponentType<any>;
  @Input() stchPopoverComponentDelegate: (componentRef: ComponentRef<any>) => void;
  @Input() stchPopoverContext: object;
  @Input() stchPopoverPlacement: PopoverPlacement;
  @Input() stchPopoverDisabled = false;
  @Input() stchPopoverTrigger: PopoverTrigger;
  @Input() stchPopoverCustomTrigger: Observable<MouseEvent>;
  @Input() stchPopoverCloseOnOutsideClick = false;
  @Input() stchPopoverInnerShadow = false;
  @Input() stchPopoverDelay = 0;
  @Input() stchPopoverArrow = false;
  @Input() stchPopoverAllowMultiplePopovers: boolean;
  @Input() stchPopoverOffsetX = 0;
  @Input() stchPopoverOffsetY = 0;
  @Input() stchPopoverShowUntil: Observable<void>;
  @Input() stchPopoverFallbackPlacements: PopoverPlacement[];
  @Input() stchPopoverPadding = 0;
  @Input() stchPopoverStopPropagation = false;
  @Input() stchPopoverNoBorder = false;
  @Input() stchPopoverCustomStyles: { [key: string]: string };
  @Input() stchPopoverAppearance: 'default' | 'black' | 'sapphire-context-menu' = 'default';

  @Output() readonly stchPopoverVisibleChange = new EventEmitter<boolean>();

  constructor(
    protected elementRef: ElementRef,
    private popoverService: PopoverService,
    private ngZone: NgZone,
    protected injector: Injector,
  ) {
    this.visibleStatus.pipe(takeUntil(this.alive)).subscribe((status) => this.stchPopoverVisibleChange.emit(status));
  }

  /**
   * Lifecycle
   */

  ngAfterViewInit(): void {
    this.showPopover.pipe(takeUntil(this.alive)).subscribe((event) => {
      this.popoverService.create(
        this.elementRef,
        {
          allowedOutsideElements: this.stchPopoverTrigger === 'click' ? [this.elementRef.nativeElement] : [],
          appearance: this.stchPopoverAppearance,
          arrow: this.stchPopoverArrow,
          closeOnOutsideClick: this.stchPopoverCloseOnOutsideClick,
          component: this.stchPopoverComponent,
          componentDelegate: this.stchPopoverComponentDelegate,
          context: this.stchPopoverContext,
          customStyles: this.stchPopoverCustomStyles,
          fallbackPlacements: this.stchPopoverFallbackPlacements,
          injector: this.injector,
          innerShadow: this.stchPopoverInnerShadow,
          noBorder: this.stchPopoverNoBorder,
          padding: this.stchPopoverPadding,
          placement: this.stchPopoverPlacement,
          popoverOffsetX: this.stchPopoverOffsetX,
          popoverOffsetY: this.stchPopoverOffsetY,
          showUntil: this.stchPopoverShowUntil ? merge(this.innerHide, this.stchPopoverShowUntil) : this.innerHide,
          template: this.stchPopoverTemplate || this.stchPopoverContent,
          trackMouse: this.stchPopoverTrackMouse,
          trigger: this.stchPopoverTrigger,
          visibleChange: this.visibleStatus,
        },
        event,
        this.elementRef.nativeElement.closest('.popover-nested-state-container')?.dataset?.nestedSelectors,
      );
    });

    let trigger: Observable<MouseEvent> = EMPTY;

    if (this.stchPopoverTrigger === 'click') {
      trigger = fromEvent(this.elementRef.nativeElement, 'click').pipe(
        tap((event: MouseEvent) => {
          if (!this.stchPopoverStopPropagation) {
            return;
          }
          event.stopPropagation();
          event.preventDefault();
        }),
      );
    } else if (this.stchPopoverTrigger === 'contextmenu') {
      trigger = fromEvent(this.elementRef.nativeElement, 'contextmenu').pipe(
        tap((event: MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
        }),
      );
    } else if (this.stchPopoverTrigger === 'hover') {
      trigger = fromEvent(this.elementRef.nativeElement, 'mouseenter');
      trigger = trigger.pipe(
        tap(() => (this.mouseEntered = true)),
        delay(this.stchPopoverDelay),
        filter(() => this.mouseEntered),
      );

      // Close delayed popover. Which is not even instantiated yet
      this.ngZone.runOutsideAngular(() => {
        fromEvent(this.elementRef.nativeElement, 'mouseleave')
          .pipe(
            takeUntil(this.alive),
            filter(() => this.mouseEntered),
          )
          .subscribe(() => (this.mouseEntered = false));
      });
    }

    this.ngZone.runOutsideAngular(() => {
      merge(
        trigger.pipe(tap((event) => event.preventDefault())),
        this.customTrigger.pipe(
          switchMap((customTrigger) => customTrigger || EMPTY),
          filter(() => {
            if (this.visibleStatus.value && !this.stchPopoverAllowMultiplePopovers) {
              return false;
            }
            return true;
          }),
        ),
      )
        .pipe(
          filter(() => !this.stchPopoverDisabled),
          takeUntil(this.alive),
        )
        .subscribe((event) =>
          this.ngZone.run(() => {
            if (this.visibleStatus.value && this.stchPopoverTrigger === 'click') {
              this.innerHide.next();
            } else {
              this.showPopover.next(event || null);
            }
          }),
        );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('stchPopoverCustomTrigger' in changes) {
      this.customTrigger.next(this.stchPopoverCustomTrigger);
    }

    if ('stchPopoverDisabled' in changes && this.visibleStatus.value) {
      this.innerHide.next();
    }
  }

  ngOnDestroy(): void {
    this.innerHide.next();
    this.innerHide.complete();
    this.customTrigger.next(null);
    this.customTrigger.complete();
    this.alive.next();
    this.alive.complete();
  }
}
