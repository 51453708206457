<app-context-menu-item
  [title]="knot?.pinned ? 'Unpin' : 'Pin'"
  [close]="true"
  (execute)="pinKnot()"
/>

<app-context-menu-item
  *ngIf="withDetach"
  title="Detach"
  [close]="true"
  (execute)="detachKnot()"
/>

<app-context-menu-item
  title="Delete Permanently"
  [close]="true"
  (execute)="deleteKnot()"
/>

<app-context-menu-item
  *ngIf="!knot?.autoDiscovery"
  title="Enable AutoDiscovery"
  [close]="true"
  (execute)="autodiscoverKnot(true)"
/>

<app-context-menu-item
  *ngIf="knot?.autoDiscovery"
  title="Disable AutoDiscovery"
  [close]="true"
  (execute)="autodiscoverKnot(false)"
/>

<app-context-menu-item
  title="Related Emails"
  [close]="true"
  (execute)="showRelatedEmails()"
/>

<app-context-menu-item
  title="Load in Pellet Map"
  [close]="true"
  (execute)="showRelatedKnots()"
/>

<app-context-menu-item
  title="Search on Google"
  [close]="true"
  (execute)="searchExternal('google')"
/>

<app-context-menu-item
  title="Search on Bing News"
  [close]="true"
  (execute)="searchExternal('bing')"
/>
