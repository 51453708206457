// Common
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { warmUpObservable } from '@decorators';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// Services
import { BaseService } from '@modules/common/services/base.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { SignalsService } from '@modules/common/services/signals-service/signals.service';

// Types
import { Notification } from '@modules/notification/types/notification';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { NotificationsFilters } from '@modules/notification/types/notifications-filters';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { SignalEnum } from '@modules/common/types/signal';

// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, scan, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class NotificationsService extends BaseService {
  constructor(
    private http: HttpClient,
    private stitchServiceFactory: StitchServiceFactory,
    private signalsService: SignalsService,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {
    super();
  }

  create(): Observable<Notification> {
    throw new Error('not available');
  }

  search(
    filters?: Partial<NotificationsFilters>,
    _config?: object,
  ): Observable<BaseSearchResponse<Notification>> {
    const requestParams = { params: new NotificationsFilters(filters || {}).format() };

    return this.getRefreshRequired().pipe(
      switchMap(() => this.spaceId),
      switchMap((spaceId) =>
        this.http
          .get<{
            items: Notification[];
            count: number;
          }>(environment.baseUrl + '/api/notifications', requestParams)
          .pipe(map((response) => ({ spaceId, response }))),
      ),
      switchMap(({ spaceId, response }) =>
        this.signalsService.getSignal(SignalEnum.NEW_NOTIFICATION, spaceId).pipe(
          startWith(null),
          map((notificationSignal) => ({ spaceId, response, notificationSignal })),
        ),
      ),
      scan((acc, { response, notificationSignal }) => {
        const items = (notificationSignal?.item ? [notificationSignal.item] : []).concat(acc?.items ?? response.items);
        notificationSignal?.item && items.pop();

        return {
          items: items.map((item) => new Notification(item)),
          count: notificationSignal?.item ? acc.count + 1 : (acc?.count ?? response.count),
        };
      }, null),
    );
  }

  @warmUpObservable
  deleteNotifications(
    { emit }: FeedbackConfig = { emit: true },
    ids: string[] = [],
  ): Observable<{ success: boolean }> {
    return this.http
      .delete<{ success: boolean }>(`${environment.baseUrl}/api/notifications`, { params: { 'ids[]': ids } })
      .pipe(
        tap(() => emit && this.forceRefresh()),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  getCount(): Observable<number> {
    return this.search({ limit: 0, offset: 0 }).pipe(map(({ count }) => count));
  }
}
