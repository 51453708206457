// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { BundledInputsModule } from '@modules/bundled-inputs/bundled-inputs.module';
import { CommentsModule } from '@modules/comments/comments.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { ElementsModule } from '../elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TagsModule } from '@modules/tags/tags.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactSharedComponent } from './components/contact-shared/contact-shared.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { ContactsListContainerComponent } from './components/list-container/list-container.component';
import { ContactsListContextMenuComponent } from './components/contacts-list-context-menu/contacts-list-context-menu.component';
import { ContactsMainViewComponent } from './components/main-view/main-view.component';
import { ContactsSideBarComponent } from '@modules/contacts/components/side-bar/side-bar.component';
import { GroupFormComponent } from './components/group-form/group-form.component';
import { GroupSectionsComponent } from './components/group-sections/group-sections.component';
import { GroupSharedComponent } from './components/group-shared/group-shared.component';
import { GroupsListComponent } from './components/groups-list/groups-list.component';
import { GroupsListContextMenuComponent } from './components/groups-list-context-menu/groups-list-context-menu.component';
import { SideBarGroupsComponent } from './components/side-bar-groups/side-bar-groups.component';
import { SideBarGroupsTreeComponent } from './components/side-bar-groups-tree/side-bar-groups-tree.component';
import { SidebarContextMenuComponent } from './components/sidebar-context-menu/sidebar-context-menu.component';

// Directives
import { ContactsListContextMenuDirective } from './directives/contacts-list-context-menu.directive';
import { GroupsListContextMenuDirective } from './directives/groups-list-context-menu.directive';
import { SidebarContextMenuDirective } from './directives/sidebar-context-menu.directive';

// Pipes
import { TitleByContactsFilterPipe } from '@modules/contacts/pipes/title-by-contacts-filter.pipe';
import { FormatParticipantPipe } from '@modules/messages/pipes/format-participant.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    BundledInputsModule,
    CommentsModule,
    CommonModule,
    ConnectionsModule,
    DragNDropModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    ScrollModule,
    ScrollingModule,
    TagsModule,
    UtilsModule,
    FormatParticipantPipe,
  ],
  declarations: [
    // Components
    ContactFormComponent,
    ContactSharedComponent,
    ContactsListComponent,
    ContactsListContainerComponent,
    ContactsListContextMenuComponent,
    ContactsMainViewComponent,
    ContactsSideBarComponent,
    GroupFormComponent,
    GroupSectionsComponent,
    GroupSharedComponent,
    GroupsListComponent,
    GroupsListContextMenuComponent,
    SideBarGroupsComponent,
    SideBarGroupsTreeComponent,
    SidebarContextMenuComponent,

    // Directives
    ContactsListContextMenuDirective,
    GroupsListContextMenuDirective,
    SidebarContextMenuDirective,
    // Pipes
    TitleByContactsFilterPipe,
  ],
  exports: [
    ContactFormComponent,
    ContactsListComponent,
    ContactsListContainerComponent,
    ContactsMainViewComponent,
    ContactsSideBarComponent,
    GroupFormComponent,
    GroupsListComponent,
    // Directives
    ContactsListContextMenuDirective,
    GroupsListContextMenuDirective,
    TitleByContactsFilterPipe,
  ],
  providers: [FormatParticipantPipe],
})
export class ContactsModule {}
