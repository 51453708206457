// Rx
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

export abstract class BaseService {
  protected refreshRequired = new BehaviorSubject<number>(0); // BehaviorSubject causes redundant backend fetches
  private refresh = new Subject<number>();

  protected handleObserverError(error: Error) {
    console.error(error);
    return throwError(error);
  }

  public forceRefresh(delayTime = 0) {
    this.refreshRequired.next(delayTime);
    this.refresh.next(delayTime);
  }

  /**
   * @deprecated Use getRefresh instead
   */
  getRefreshRequired(): Observable<number> {
    return this.refreshRequired.pipe(switchMap((value) => of(null).pipe(delay(value))));
  }

  getRefresh(): Observable<number> {
    return this.refresh.pipe(switchMap((value) => of(null).pipe(delay(value))));
  }
}
