<app-context-menu-item
  title="By Date"
  [active]="state?.sort?.by === 'date'"
  [sortable]="true"
  [reversed]="state?.sort?.order === 'desc'"
  (execute)="sort('date')"
/>

<app-context-menu-item
  title="Alphabetically"
  [active]="state?.sort?.by === 'name'"
  [sortable]="true"
  [reversed]="state?.sort?.order === 'desc'"
  (execute)="sort('name')"
/>

<app-context-menu-item
  title="Most Frequent"
  [active]="state?.sort?.by === 'frequency'"
  [sortable]="true"
  [reversed]="state?.sort?.order === 'desc'"
  (execute)="sort('frequency')"
/>

<app-context-menu-item
  title="Most Recent"
  [active]="state?.sort?.by === 'recency'"
  [sortable]="true"
  [reversed]="state?.sort?.order === 'desc'"
  (execute)="sort('recency')"
/>

<app-context-menu-divider />

<app-context-menu-item
  title="Selected Date"
>
  <div class="date">
    <div class="date-buttons">
      <div
        class="date-buttons-item"
        [class.selected]="dateButtonSelected === 'from'"
        [class.active]="innerFromDate?.value"
        (click)="dateButtonSelected = 'from'"
      >
        {{ innerFromDate?.value ? (innerFromDate?.value | date : 'MMM d') : 'Start Date'}}
      </div>
      <div
        class="date-buttons-item"
        [class.selected]="dateButtonSelected === 'to'"
        [class.active]="innerToDate?.value"
        (click)="dateButtonSelected = 'to'"
      >
        {{ innerToDate?.value ? (innerToDate?.value | date : 'MMM d') : 'Due Date'}}
      </div>
    </div>
  
    <app-date-picker-2
      [inputFormControl]="dateButtonSelected === 'from' ? innerFromDate : innerToDate"
      [collapseable]="false"
      [maxDate]="dateButtonSelected === 'from' && innerToDate?.value"
      [minDate]="dateButtonSelected === 'to' && innerFromDate?.value"
      [highlightRange]="true"
    />
  </div>
</app-context-menu-item>

<app-context-menu-divider *ngIf="global" />

<app-context-menu-item
  *ngIf="global"
  title="Manage My Tags"
>
  <app-tags-form
    (close)="handleClose()"
  />
</app-context-menu-item>
