// Common
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { DateTimeValidators } from '@modules/form-controls/validators/date-time.validators';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

// Utils
import { generateSecondaryColor } from '@modules/common/utils/color';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { CalendarContact } from './calendar-contact';
import { ChildStitch } from '@modules/common/types/child-stitch';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { StitchType } from '@modules/common/types/stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';
import { CalendarType } from './calendar-type';
import { Message } from '@modules/messages/types/message';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Notebook } from '@modules/notes/types/notebook';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { TimeZone } from '@modules/form-controls/types/timezones';
import { EventParticipant } from './event-participant';
import { TypedFormGroup } from '@modules/common/utils/form';

type EventFormGroup = FormGroup<
  TypedFormGroup<CalendarEvent> & {
    startDate: FormControl<Date>;
    endDate: FormControl<Date>;
    reminders: FormArray<ReminderFormGroup>;
  }
>;
const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZone;

export class CalendarEvent extends ChildStitch {
  allDay: boolean;
  calendarId: string;
  endTime: Date;
  followed: Date;
  location: { address: string; coordinates?: { longitude: number; latitude: number } };
  meetingLink: string;
  owner: CalendarContact;
  participants: EventParticipant[];
  readOnly: boolean;
  recurrence: string;
  reminder: boolean;
  reminders: Reminder[];
  snoozed: Date;
  startTime: Date;
  status: 'confirmed' | 'tentative' | 'cancelled';
  timeZone: TimeZone;
  title: string;

  // Tech properties

  /**
   * virtual - temporal event object - used for instance on calendar quick form badge
   *    can't be draged, hovered, clicked, etc
   */
  virtual: boolean;

  constructor(data: Partial<CalendarEvent> = {}) {
    super(data);
    this.containerId = data?.containerId || data?.calendarId;

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      const startTime = data.startTime ? new Date(data.startTime) : new Date();

      this.calendarId = data.calendarId || data.containerId;
      this.title = data.title;
      this.location = {
        address: data.location?.address,
        coordinates: {
          longitude: data.location?.coordinates?.longitude,
          latitude: data.location?.coordinates?.latitude,
        },
      };
      this.allDay = data.allDay || false;
      this.endTime = data.endTime ? new Date(data.endTime) : new Date(startTime.getTime() + 3600000);
      this.meetingLink = data.meetingLink;
      this.owner = data.owner;
      this.participants = Array.isArray(data.participants) ? data.participants : [];
      this.readOnly = data.readOnly;
      this.recurrence = data.recurrence;
      this.reminder = data?.reminder || false;
      this.reminders =
        (Array.isArray(data.reminders) && data.reminders?.map((reminder) => new Reminder(reminder))) || [];
      this.startTime = startTime;
      this.status = data.status;
      this.timeZone = data.timeZone || CURRENT_TIMEZONE;
    }
  }

  static fromDragData(dragItem: DragData): CalendarEvent {
    return super.fromDragData(dragItem) as CalendarEvent;
  }

  static fromFormGroup(form: EventFormGroup): CalendarEvent {
    const formValues = form.value;

    let startTime, endTime;

    if (formValues.allDay) {
      startTime = new Date(
        Date.UTC(
          formValues.startDate?.getFullYear(),
          formValues.startDate?.getMonth(),
          formValues.startDate?.getDate(),
        ),
      );
      if (formValues.endDate) {
        endTime = new Date(
          Date.UTC(formValues.endDate?.getFullYear(), formValues.endDate?.getMonth(), formValues.endDate?.getDate()),
        );
      }
    } else {
      startTime = new Date(
        formValues.startDate?.getFullYear(),
        formValues.startDate?.getMonth(),
        formValues.startDate?.getDate(),
        (formValues.startTime && formValues.startTime?.getHours()) || 0,
        (formValues.startTime && formValues.startTime?.getMinutes()) || 0,
        0,
      );

      if (formValues.endDate && formValues.endTime) {
        endTime = new Date(
          formValues.endDate?.getFullYear(),
          formValues.endDate?.getMonth(),
          formValues.endDate?.getDate(),
          (formValues.endTime && formValues.endTime?.getHours()) || 0,
          (formValues.endTime && formValues.endTime?.getMinutes()) || 0,
          0,
        );
      }
    }

    return new CalendarEvent({
      id: formValues.id,
      calendarId: formValues.calendarId,
      color: formValues.color,
      followed: formValues.followed,
      title: formValues.title,
      description: formValues.description,
      location: { address: formValues.location?.address },
      startTime,
      endTime,
      allDay: formValues.allDay,
      meetingLink: formValues.meetingLink,
      owner: formValues.owner,
      participants: formValues.participants,
      pinned: formValues.pinned,
      flagged: formValues.flagged,
      archived: formValues.archived,
      reminder: formValues.reminder,
      recurrence: formValues.recurrence,
      reminders: form.controls.reminders.controls.map((reminder) => Reminder.fromFormGroup(reminder)),
      readOnly: formValues.readOnly,
      status: formValues.status,
      knots: formValues.knots,
      tags: formValues.tags,
      connections: formValues.connections,
      linkedInfo: formValues.linkedInfo,
      snoozed: formValues.snoozed,
      uploads: formValues.uploads,
    });
  }

  static fromCalendarCell(
    tempEvent: CalendarEvent,
    newDate: Date = new Date(),
    calendarType: CalendarType,
    virtual: boolean,
  ): CalendarEvent {
    const event = tempEvent ? new CalendarEvent({ ...tempEvent }) : new CalendarEvent();
    let allDay = false;
    let rangeType = null;

    switch (calendarType) {
      case CalendarType.YEAR:
      case CalendarType.MONTH:
        rangeType = 'day';
        allDay = true;
        break;
      case CalendarType.WEEK:
      case CalendarType.WORKWEEK:
      case CalendarType.DAY:
        rangeType = 'hour';
        allDay = false;
        break;
    }

    if (rangeType === 'hour' && newDate && tempEvent && tempEvent.endTime && tempEvent.startTime) {
      const duration = tempEvent.endTime.getTime() - tempEvent.startTime.getTime();

      event.allDay = false;
      event.startTime = newDate;
      event.endTime = new Date(newDate.getTime() + duration);
    } else {
      event.startTime = newDate;
      event.endTime = new Date(newDate.getTime() + (rangeType === 'hour' ? 60 * 60 * 1000 : 0));
      event.allDay = allDay;
    }

    event.virtual = virtual;
    return event;
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): CalendarEvent {
    return new CalendarEvent({
      title: filters.query || filters.calendar.subject,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.calendar.body,
      calendarId: filters.calendar.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, [
      'title',
      'description',
      'color',
      'startTime',
      'endTime',
      'startDate',
      'endDate',
      'calendarId',
    ]);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<CalendarEvent>; message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_events':
        return { changes: { calendarId: null }, message: (message += 'Orphan') };
      case 'all_calendars':
        break;
      case 'snoozed':
      case 'archived':
      case 'deleted':
      case 'flagged':
      case 'followed':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.event;
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return {
      id: this.id,
      start: this.allDay ? beginningOfDay(this.startTime) : this.startTime,
      end: this.allDay ? endOfDay(this.endTime) : this.endTime,
      title: this.title,
      color: { primary: this.color, secondary: generateSecondaryColor(this.color) },
      allDay: this.allDay,
      meta: this,
      resizable: this.recurrence ? undefined : { beforeStart: true, afterEnd: true },
      draggable: !this.recurrence,
    };
  }

  asFormGroup(): EventFormGroup {
    return this.formBuilder.group(
      {
        allDay: [this.allDay],
        archived: [this.archived],
        calendarId: [this.calendarId],
        color: [this.color],
        deleted: [this.deleted],
        description: [this.description],
        endDate: [this.endTime, Validators.required],
        endTime: [this.endTime],
        flagged: [this.flagged],
        followed: [this.followed],
        id: [this.id],
        knots: [this.knots || []],
        linkedInfo: [this.linkedInfo || []],
        location: this.formBuilder.group({ address: [this.location?.address || ''] }),
        meetingLink: [this.meetingLink],
        noNotification: this.noNotification,
        owner: [this.owner],
        participants: [this.participants],
        pinned: [this.pinned],
        readOnly: [this.readOnly],
        recurrence: [this.recurrence],
        reminder: [this.reminder],
        reminders: this.formBuilder.array((this.reminders || []).map((reminder) => reminder.asFormGroup())),
        snoozed: [this.snoozed],
        startDate: [this.startTime, Validators.required],
        startTime: [this.startTime],
        status: [this.status],
        tags: [this.tags || []],
        timeZone: [this.timeZone],
        title: [this.title, Validators.required],
        uploads: [this.uploads || [], FileValidators.size(26214400)],
        visibility: [null],
      },
      {
        validators: [DateTimeValidators.dateRange('startDate', 'startTime', 'endDate', 'endTime')],
      },
    );
  }

  asPayloadJSON() {
    return {
      allDay: this.allDay,
      archived: this.archived,
      calendarId: this.calendarId,
      color: this.color,
      description: this.description,
      endTime: this.endTime && this.endTime.toISOString(),
      flagged: this.flagged,
      followed: this.followed,
      location: this.location?.address,
      meetingLink: this.meetingLink,
      owner: this.owner,
      participants: this.participants,
      pinned: this.pinned,
      readOnly: this.readOnly,
      recurrence: this.recurrence,
      reminders: (this.reminders || []).map((reminder) => reminder.asPayloadJson(this.startTime, this.endTime)),
      snoozed: this.snoozed,
      startTime: this.startTime && this.startTime.toISOString(),
      status: this.status,
      timeZone: this.timeZone,
      title: this.title,
    };
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    this.participants = [];
    this.reminders = [];

    if (data instanceof Message) {
      this.description = data.bodyText;
    } else if (data instanceof CalendarEvent) {
      this.calendarId = data.calendarId;
      this.location = data.location;
      this.allDay = data.allDay;
      (this.startTime = data.startTime),
        (this.endTime = data.endTime),
        (this.meetingLink = data.meetingLink),
        (this.owner = data.owner);
      this.readOnly = data.readOnly;
      this.status = data.status;
      this.reminders = data.reminders;
    } else if (data instanceof Project || data instanceof Task) {
      this.allDay = false;
      this.startTime = data.fromTime;
      this.endTime = data.toTime;
      this.reminders = data.reminders;
    } else if (data instanceof Notebook) {
      this.allDay = false;
      this.startTime = data.createdAt;
      this.endTime = data.updatedAt;
    }
  }
}
