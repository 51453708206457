<div class="dp">
  <div class="nav-bar">
    <div class="app" *ngIf="!minimized">
      <mat-icon
        class="app-icon"
        [svgIcon]="selectedApp | appIcon"
      />
    </div>

    <app-dropdown-label
      *ngIf="!minimized"
      appearance="amethyst"
      [appContextMenu]="selectedApp"
      [appContextMenuDP]="true"
      (appContextMenuChange)="handleAppChange($event)"
      (appContextMenuOpened)="appContextMenuOpened = $event"
      [collapsed]="!appContextMenuOpened"
    >
      {{ selectedApp | appTitle }}
    </app-dropdown-label>

    <div class="filler"></div>

    <app-dropdown-label
      appearance="amethyst"
      spacesContextMenu
      (spacesContextMenuOpened)="spacesContextMenuOpened = $event"
      [collapsed]="!spacesContextMenuOpened"
    >
      {{ space?.title || 'Personal Space' }}
    </app-dropdown-label>
  </div>

  <ng-container *stchRerender="spaceId | async">
    <div class="body" [ngSwitch]="selectedApp" *ngIf="!minimized && !item">
      <app-knowledge-panel *ngSwitchCase="Application.kp" [searchTemplate]="searchTemplate" />
      <app-messages2-panel *ngSwitchCase="Application.mail" [searchTemplate]="searchTemplate" />
      <app-events-panel *ngSwitchCase="Application.calendar" [searchTemplate]="searchTemplate" />
      <app-tasks-panel *ngSwitchCase="Application.tasking" [searchTemplate]="searchTemplate" />
      <app-notes-panel *ngSwitchCase="Application.notes" [searchTemplate]="searchTemplate" />
      <app-contacts-panel *ngSwitchCase="Application.contacts" [searchTemplate]="searchTemplate" />
      <app-files-panel *ngSwitchCase="Application.files" [searchTemplate]="searchTemplate" />
    </div>
  </ng-container>

  <app-dynamic-panel-forms-container *ngIf="!minimized && !!item" />
</div>

<ng-template #searchTemplate>
  <mat-icon
    class="advanced-search-icon"
    svgIcon="filters"
    stchPopover
    stchPopoverPlacement="bottomRight"
    stchPopoverTrigger="click"
    [stchPopoverContent]="searchPopover"
    [stchPopoverFallbackPlacements]="[]"
    [stchPopoverShowUntil]="popoverClose"
  />
</ng-template>

<ng-template #searchPopover>
  <app-search-popover
    [width]="600"
    (onSearch)="handleSearch()"
    (onCancel)="handleCancel()"
  />
</ng-template>
