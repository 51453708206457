<app-list-load-error [error]="loadingError" (retry)="resetItems()" />

@if (items?.length === 0 && !loading && !loadingError) {
  @if (notFoundTemplate) {
    <ng-template [ngTemplateOutlet]="notFoundTemplate" />
  } @else {
    <app-no-content-stub imageSrc="assets/placeholders/placeholder-events.svg">
      You have no calendars
    </app-no-content-stub>
  }
}


<div
  class="overflow-y-auto grow basis-0"
  appSelectableList
  [appSelectableListReset]="resetSelected">

  @if (loading && (!items || items.length === 0)) {
    <app-loading-indicator>Loading calendars...</app-loading-indicator>
  }

  @for (item of items; track item.data.id; let index = $index) {
    @if (item.showSectionHeader && item.section) {
      <app-list-items-separator [value]="item.section"/>
    }

    <app-calendar
      [item]="item.data"
      [position]="index"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [debug]="debug"
      (open)="openCalendar.emit(item.data)"
      (appClick)="handleClickItem(item.data)"
      (appDblClick)="handleDoubleClickItem(item.data)"
      [short]="false"
      [withBar]="true"

      [appSelectable]="item.data"
      [appSelectablePosition]="index"
    />
  }
</div>

<app-pagination2
    [(page)]="page"
    [pageSize]="pageSize"
    (refresh)="resetItems()"
    (pageChange)="loadPage($event)"
    [totalItems]="totalCount"
    class="border-t border-border"></app-pagination2>