// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Pipes
import { DatePipe } from '@angular/common';
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';

// Types
import { VirtualFolder } from '../types/virtual-folder';

@Pipe({
  name: 'titleByTasksFilter',
  standalone: false,
})
export class TitleByTasksFilterPipe implements PipeTransform {
  transform(value: VirtualFolder): string {
    if (!value) {
      return 'All';
    }

    switch (value) {
      case 'all_tasks':
        return 'Tasks';
      case 'scheduled':
        return 'Scheduled';
      case 'unscheduled':
        return 'Unscheduled';
      case 'today':
        return 'Today';
      case 'week':
        return 'Next 7 days';
      case 'day0':
        return 'Tomorrow';
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
        const datePipe = new DatePipe('en-us');
        const dateByDayIndexPipe = new DateByDayIndexPipe();
        const day = dateByDayIndexPipe.transform(value);
        return datePipe.transform(day, 'EEEE');
      case 'priority':
        return 'Priority';
      case 'low':
        return 'Low';
      case 'normal':
        return 'Normal';
      case 'high':
        return 'High';
      case 'flagged':
        return 'Flagged';
      case 'followed':
        return 'Follow Up';
      case 'overdue':
        return 'Overdue';
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'all_projects':
        return 'Projects';
      case 'snoozed':
        return 'Snoozed';
      default:
        return checkExhaustiveness(value);
    }
  }
}
