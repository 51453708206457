// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { QuickReplyTemplate } from '@modules/account/types/quick-reply-template';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { QuickReplyTemplatesService } from '@modules/account/services/quick-reply-templates.service';

@Component({
  selector: 'app-settings-messages-quick-replies',
  templateUrl: './quick-replies.component.html',
  styleUrls: ['./quick-replies.component.less'],
  standalone: false,
})
export class QuickRepliesComponent implements OnInit, OnDestroy {
  public quickReplyTemplates: QuickReplyTemplate[];

  private alive = new Subject<void>();

  constructor(private quickReplyTemplatesService: QuickReplyTemplatesService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.quickReplyTemplatesService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: quickReplyTemplates }) => (this.quickReplyTemplates = quickReplyTemplates));
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
