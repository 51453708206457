// Common
import { Component, Input, OnDestroy, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { StitchType } from '@modules/common/types/stitch-type';
import { Stitch } from '@modules/common/types/stitch';
import { MailMessage } from '@modules/mail/types/mail-message';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: '40px', minHeight: 0 }),
    animate(`.2s ease-in-out`, style({ height: '{{height}}px' })),
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{contentHeight}}px', minHeight: 0 }),
    animate(`.2s ease-in-out`, style({ height: '40px' })),
  ]),
]);

@Component({
  selector: 'app-smart-quick-form',
  templateUrl: './smart-quick-form.component.html',
  styleUrls: ['./smart-quick-form.component.less'],
  animations: [collapseMotion],
  standalone: false,
})
export class SmartQuickFormComponent implements AfterViewInit, OnDestroy {
  // Inputs
  @Input() placeholder: string;
  @Input() smart: boolean;
  @Input() inline = false;

  // Outputs
  @Output() more: EventEmitter<Stitch> = new EventEmitter<Stitch>();

  // Public
  public titleControl: UntypedFormControl = new UntypedFormControl();
  public expanded = false;
  public formsSizes = {
    message: 180,
    event: 148,
    project: 120,
    task: 120,
    notebook: 120,
    note: 148,
    group: 200,
    contact: 200,
    folder: 100,
    file: 100,
  };
  public currentFormSelector: StitchType;
  public contentHeight: 0;
  public formEntity: Stitch;
  public message: MailMessage;
  public event: CalendarEvent;
  public project: Project;
  public task: Task;
  public notebook: Notebook;
  public note: Note;
  public group: Group;
  public contact: Contact;
  public folder: Folder;
  public file: File;

  // Private
  private alive = new Subject<void>();

  // View Children
  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.titleControl.valueChanges.pipe(takeUntil(this.alive)).subscribe((value: string) => {
      const currentFormSelector = keywords[value.trim().split(' ')[0].toLowerCase()];

      if (currentFormSelector) {
        this.currentFormSelector = currentFormSelector;
        this.expanded = true;
      }
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClose() {
    this.contentHeight = this.contentContainer.nativeElement.offsetHeight;
    this.titleControl.setValue('');
    this.expanded = false;
    this.currentFormSelector = null;
  }

  handleDrop(dragData: DragData) {
    if (this.currentFormSelector) {
      return;
    }

    switch (dragData.type) {
      case DragDataTypes.message:
        this.currentFormSelector = StitchType.message;
        this.message = MailMessage.fromDragData(dragData);
        break;
      case DragDataTypes.event:
        this.currentFormSelector = StitchType.event;
        this.event = CalendarEvent.fromDragData(dragData);
        break;
      case DragDataTypes.project:
        this.currentFormSelector = StitchType.project;
        this.project = Project.fromDragData(dragData);
        break;
      case DragDataTypes.task:
        this.currentFormSelector = StitchType.task;
        this.task = Task.fromDragData(dragData);
        break;
      case DragDataTypes.notebook:
        this.currentFormSelector = StitchType.notebook;
        this.notebook = Notebook.fromDragData(dragData);
        break;
      case DragDataTypes.note:
        this.currentFormSelector = StitchType.note;
        this.note = Note.fromDragData(dragData);
        break;
      case DragDataTypes.group:
        this.currentFormSelector = StitchType.group;
        this.group = Group.fromDragData(dragData);
        break;
      case DragDataTypes.contact:
        this.currentFormSelector = StitchType.contact;
        this.contact = Contact.fromDragData(dragData);
        break;
      case DragDataTypes.folder:
        this.currentFormSelector = StitchType.folder;
        this.folder = Folder.fromDragData(dragData);
        break;
      case DragDataTypes.file:
        this.currentFormSelector = StitchType.file;
        this.file = File.fromDragData(dragData);
        break;
    }

    this.expanded = true;
  }

  handleMore(event: Stitch) {
    this.more.emit(event);
  }
}

const keywords = {
  send: 'message',
  mail: 'message',
  message: 'message',
  calendar: 'event',
  cal: 'event',
  event: 'event',
  appointment: 'event',
  appt: 'event',
  meeting: 'event',
  mtg: 'event',
  reminder: 'event',
  rem: 'event',
  call: 'event',
  telephone: 'event',
  tel: 'event',
  conference: 'event',
  conf: 'event',
  convention: 'event',
  interview: 'event',

  project: 'project',

  todo: 'task',
  'to-do': 'task',
  task: 'task',
  errand: 'task',

  notebook: 'notebook',

  note: 'note',
  txt: 'note',
  record: 'note',
  scribe: 'note',
  entry: 'note',
  log: 'note',

  group: 'group',

  contact: 'contact',

  folder: 'folder',

  file: 'file',
};
