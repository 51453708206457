// Common
import { UntypedFormGroup } from '@angular/forms';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { StitchedFilters } from '@modules/linked-info/types/stitched-filters';

export class BaseAppAdvancedFilters {
  stitched?: StitchedFilters;
  dateRangeFrom?: Date;
  dateRangeTo?: Date;
  containersIds?: string[];

  constructor(filters?: any) {
    this.dateRangeFrom = filters?.dateRangeFrom ? new Date(filters.dateRangeFrom) : null;
    this.dateRangeTo = filters?.dateRangeTo ? new Date(filters.dateRangeTo) : null;
    this.containersIds = Array.isArray(filters?.containersIds) ? filters.containersIds : [];

    this.stitched = {
      [StitchType.message]: !!filters?.stitched?.[StitchType.message],
      [StitchType.event]: !!filters?.stitched?.[StitchType.event],
      [StitchType.project]: !!filters?.stitched?.[StitchType.project],
      [StitchType.task]: !!filters?.stitched?.[StitchType.task],
      [StitchType.notebook]: !!filters?.stitched?.[StitchType.notebook],
      [StitchType.note]: !!filters?.stitched?.[StitchType.note],
      [StitchType.group]: !!filters?.stitched?.[StitchType.group],
      [StitchType.contact]: !!filters?.stitched?.[StitchType.contact],
      [StitchType.folder]: !!filters?.stitched?.[StitchType.folder],
      [StitchType.file]: !!filters?.stitched?.[StitchType.file],
    };
  }

  static getFormValues(form: UntypedFormGroup) {
    const formValues = { ...form.value };
    formValues.stitched = Object.fromEntries(formValues.stitched.map((i) => [i, true]));

    return formValues;
  }

  protected commonFormGroupFields() {
    return {
      dateRangeFrom: [this.dateRangeFrom],
      dateRangeTo: [this.dateRangeTo],
      stitched: [Object.entries(this.stitched).reduce((memo, [k, v]) => (v ? [...memo, k] : memo), [])],
      containersIds: [this.containersIds],
    };
  }
}
