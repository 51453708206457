// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { contactInfoTypes } from '@modules/form-controls/types/contactInfoTypes';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-contact-quick-form',
  templateUrl: './contact-quick-form.component.html',
  styleUrls: ['../base-quick-form/base-quick-form.component.less', './contact-quick-form.component.less'],
  standalone: false,
})
export class ContactQuickFormComponent extends BaseQuickFormComponent implements OnChanges {
  // Inputs
  @Input() contact: Contact = new Contact();
  @Input() group: Group;

  // Outputs
  @Output() more: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<Contact>();

  // Public
  public contactInfoTypes = contactInfoTypes;

  constructor(
    private contactsService: ContactsService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('contact' in changes) {
      this.reset();
    }

    if ('group' in changes) {
      this.form.patchValue({ groupId: this.group?.id });
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const contact = Contact.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(contact);
      return;
    }

    this.saveInProgress = true;

    this.contactsService
      .create(contact)
      .pipe(takeUntil(this.alive))
      .subscribe(
        (contactInstance) => {
          this.afterSave.emit(contactInstance);
          this.close.emit();
        },
        () => this.handleError(),
      );
  }

  reset() {
    if (!this.contact) {
      return;
    }
    this.form = this.contact.asFormGroup();
    this.group && this.form.patchValue({ groupId: this.group.id });
    super.reset();
  }

  clickMore() {
    this.more.emit(Contact.fromFormGroup(this.form));
    super.clickMore();
  }
}
