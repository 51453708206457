import { Pipe, PipeTransform } from '@angular/core';
import { RelativeDate } from '@modules/common/types/relative-date';
import { relativeDateToDate, isSameDay } from '@modules/common/utils/date';

@Pipe({
  name: 'compareToRelativeDate',
  standalone: false,
})
export class CompareToRelativeDatePipe implements PipeTransform {
  transform(date: Date, parameter: RelativeDate): boolean {
    return isSameDay(relativeDateToDate(parameter), date);
  }
}
