// Common
import { Injectable } from '@angular/core';

// Types
import { Notebook } from '../types/notebook';
import { Note } from '../types/note';
import { Like } from '@modules/common/types/like';

// Services
import { NotesService } from './notes.service';
import { SectionsService } from './sections.service';
import { SignalsService } from '@modules/common/services/signals-service/signals.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Injectable()
export class SectionsListService extends BaseSectionsListService<Note, Notebook> {
  constructor(sectionsService: SectionsService, itemsService: NotesService, signalsService: SignalsService) {
    super(sectionsService, itemsService, signalsService);
  }

  protected createInstance(data: Like<Note>): Note {
    return new Note(data);
  }
}
