// Common
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Toast } from 'ngx-toastr';

// Types
import { Action } from '../../types/action';

// RX
import { interval, Observable, of, Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  standalone: false,
})
export class ToastComponent extends Toast implements OnInit, OnDestroy {
  @Input() countdown: boolean;
  @Input() duration: number;

  @Output() afterTimeout = new EventEmitter();

  public actions: Action[];
  public icon: string;
  public counter: number;
  public actionExecuted = false;

  private alive = new Subject<void>();

  setActions(actions: Action[]) {
    this.actions = actions;
  }

  setIcon(icon: string) {
    this.icon = icon;
  }

  /**
   * Actions
   */

  action(action: Action, event: Event) {
    event.stopPropagation();

    if (this.actionExecuted) {
      return;
    }

    this.actionExecuted = true;

    if (action.handler instanceof Observable) {
      action.handler
        .pipe(
          catchError(() => of(null)),
          takeUntil(this.alive),
        )
        .subscribe(() => {
          this.toastPackage.triggerAction();
          this.remove();
        });
    } else {
      action.handler();
      this.toastPackage.triggerAction();
      this.remove();
    }
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.counter = this.duration / 1000;

    interval(1000)
      .pipe(
        filter(() => !this.actionExecuted),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.counter--;

        if (this.counter < 1) {
          this.remove();
        }
      });
  }

  ngOnDestroy() {
    !this.actionExecuted && this.afterTimeout.emit();
    this.alive.next();
    this.alive.complete();
  }
}
