import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sameMonth',
  standalone: false,
})
export class SameMonthPipe implements PipeTransform {
  transform(day1: Date, day2: Date | number): boolean {
    if (day2 instanceof Date) {
      return day1 && day2 && day1.getFullYear() === day2.getFullYear() && day1.getMonth() === day2.getMonth();
    } else {
      return day1 && day1.getMonth() === day2;
    }
  }
}
