import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@modules/messages/types/message';

@Pipe({
  name: 'messageSenderName',
  standalone: false,
})
export class MessageSenderNamePipe implements PipeTransform {
  transform(message: Message): any {
    const propertyKey = message.draft || message.sent ? 'to' : 'from';

    if (!message?.[propertyKey]?.[0]) {
      return 'No Sender';
    }

    return message?.[propertyKey][0].name || message?.[propertyKey][0].address;
  }
}
