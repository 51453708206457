// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Stitch } from '../types/stitch';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { KnowledgeFlags } from '../types/knowledge-flags';

// RX
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class StitchService {
  constructor(
    private linkedInfoService: LinkedInfoService,
    private knotsService: KnotsService,
    private tagsService: TagsService,
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {}

  /**
   * Methods
   */

  linkDragData(stitchItem: Stitch, dragData: DragData): void {
    if (!stitchItem?.id || !dragData) {
      return;
    }

    if (dragData.type === DragDataTypes.knot) {
      const knots = dragData.data as Knot[];
      this.knotsService.link(knots, [stitchItem]);
    } else if (dragData.type === DragDataTypes.tag) {
      const tags = dragData.data as Tag[];
      this.tagsService.link(tags, [stitchItem]);
    } else if (dragDataTypeAllowed(dragData.type) && dragData.data) {
      const items = dragData.data as Stitch[];
      const linkedItems = items.map((item) => new LinkedInfo(item));
      this.linkedInfoService.linkItems([new LinkedInfo(stitchItem), ...linkedItems]);
    }
  }

  fillKnowledgeItems(ids: string[], config: KnowledgeFlags) {
    return this.http
      .get<{ items: object }>(`${environment.baseUrl}/api/graphs/fill`, {
        params: {
          'items_ids[]': ids,
          with_knots: config.withKnots,
          with_tags: config.withTags,
          with_connections: config.withConnections,
          knots_source: config.knotsSource,
        },
      })
      .pipe(
        map(({ items }) => items),
        catchError((error: Error) => {
          this.toasterService.show({ text: error.message });

          return of({});
        }),
      );
  }
}
