<div
  class="slider"
  [style.--ngx-slider-left-out-color]="leftOutColor"
  [style.--ngx-slider-color]="color"
>
  <ngx-slider
    [value]="fromControl?.value"
    [highValue]="toControl?.value"
    [options]="sliderOptions"
    (userChange)="setScoreValue($event)"
  />
</div>
