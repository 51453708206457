// Common
import { Validators } from '@angular/forms';
import { FileValidators } from '@modules/form-controls/validators/file.validators';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { FormArray, FormGroup } from '@angular/forms';

// Utils
import { addDays, beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Pipes
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';
import { DatePipe } from '@angular/common';

// Types
import { ChildStitch } from '@modules/common/types/child-stitch';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Message } from '@modules/messages/types/message';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Recipient } from '../../mail/types/recipient';
import { StitchType } from '@modules/common/types/stitch-type';
import { Project } from './project';
import { TaskStatus } from './task-status';
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';
import { Priority } from '@modules/tasks/types/priority';
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { Stitch } from '@modules/common/types/stitch';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { SpaceParticipant } from '@modules/settings/types/space-participant';
import { Space } from '@modules/settings/types/space';
import { TypedFormGroup } from '@modules/common/utils/form';

type TaskFormGroup = FormGroup<TypedFormGroup<Task> & { reminders: FormArray<ReminderFormGroup> }>;

export class Task extends ChildStitch {
  boardPosition?: number;
  columnId?: string;
  columnTitle?: string;
  completed?: boolean;
  fromDate?: Date;
  fromTime?: Date;
  owner?: string;
  participants?: Recipient[];
  priority?: Priority;
  progress?: number;
  projectId?: string;
  assigneeId?: string;
  assignee?: SpaceParticipant;
  reminders?: Reminder[];
  rowId?: string;
  status?: TaskStatus;
  toDate?: Date;
  toTime?: Date;
  title?: string;
  readonly issueKey?: string;
  readonly issueEnum?: number;
  get issueKeyTitle() {
    return this.issueKey && this.issueEnum && `${this.issueKey}-${this.issueEnum}`;
  }

  constructor(data: Partial<Task> = {}, space?: Space) {
    super(data);
    this.containerId = data?.containerId || data?.projectId;

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.boardPosition = data.boardPosition;
      this.columnId = data.columnId || null;
      this.columnTitle = data.columnTitle;
      this.fromDate = data.fromDate && new Date(data.fromDate);
      this.fromTime = data.fromTime && new Date(data.fromTime);
      this.reminders =
        (Array.isArray(data.reminders) && data.reminders?.map((reminder) => new Reminder(reminder))) || [];
      this.participants = data.participants || [];
      this.priority = data.priority || 'normal';
      this.projectId = data.projectId || data.containerId;
      this.assigneeId = data.assigneeId;
      this.assignee = new SpaceParticipant(
        space?.participants?.find(({ id }) => this.assigneeId === id) || { firstName: 'Unassigned' },
      );
      this.rowId = data.rowId || null;
      this.status = data.status;
      this.temp = data.temp;
      this.title = data.title;
      this.issueKey = data.issueKey;
      this.issueEnum = data.issueEnum;
      this.toDate = data.toDate && new Date(data.toDate);
      this.toTime = data.toTime && new Date(data.toTime);
      this.completed = data.completed || false;
      this.progress = data.progress;
    }
  }

  static fromFormGroup(form: TaskFormGroup): Task {
    const formValues = form.value;

    return new Task({
      archived: formValues.archived,
      boardPosition: formValues.boardPosition,
      color: formValues.color,
      columnId: formValues.columnId,
      deleted: formValues.deleted,
      description: formValues.description,
      flagged: formValues.flagged,
      pinned: formValues.pinned,
      fromDate: formValues.fromDate,
      fromTime: formValues.fromTime,
      followed: formValues.followed,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      reminders: form.controls.reminders.controls.map((reminder) => Reminder.fromFormGroup(reminder)),
      owner: formValues.owner,
      parentId: formValues.parentId,
      participants: formValues.participants,
      position: formValues.position,
      priority: formValues.priority,
      projectId: formValues.projectId,
      assigneeId: formValues.assigneeId,
      rowId: formValues.rowId,
      sectionId: formValues.sectionId,
      status: formValues.status,
      snoozed: formValues.snoozed,
      tags: formValues.tags,
      temp: formValues.temp,
      title: formValues.title,
      toDate: formValues.toDate,
      toTime: formValues.toTime,
      connections: formValues.connections,
      completed: formValues.completed,
      uploads: formValues.uploads,
    });
  }

  static fromDragData(dragItem: DragData): Task {
    return super.fromDragData(dragItem) as Task;
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Task {
    const today = new Date();

    const attributes = {
      title: filters.query || filters.tasking.title,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.tasking.description,
      projectId: filters.tasking.containersIds?.[0],
      priority: filters.tasking.priority,
      fromDate: filters.tasking.startDate,
      fromTime: filters.tasking.startDate,
      toDate: filters.tasking.dueDate,
      toTime: filters.tasking.dueDate,

      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
    };

    switch (folder) {
      case 'today':
        attributes.fromDate = beginningOfDay(today);
        attributes.toDate = endOfDay(today);
        attributes.fromTime = beginningOfDay(today);
        attributes.toTime = endOfDay(today);
        break;
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
        const dateByDayIndexPipe = new DateByDayIndexPipe();
        const day = dateByDayIndexPipe.transform(folder);

        attributes.fromTime = beginningOfDay(day);
        attributes.toTime = endOfDay(day);
        attributes.fromDate = beginningOfDay(day);
        attributes.toDate = endOfDay(day);
        break;
      case 'week':
        attributes.fromTime = beginningOfDay(addDays(today, 1));
        attributes.toTime = endOfDay(addDays(today, 7));
        attributes.fromDate = beginningOfDay(addDays(today, 1));
        attributes.toDate = endOfDay(addDays(today, 7));
        break;
      case 'low':
      case 'normal':
      case 'high':
        attributes.priority = folder;
        break;
    }

    return new Task(attributes);
  }

  static shouldRefreshList(prev, current): boolean {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<Task>; message: string } {
    let message = 'Successfully moved to ';
    const dateByDayIndexPipe = new DateByDayIndexPipe();

    switch (sidebar) {
      case 'all_tasks':
        return { changes: { parentId: null, projectId: null }, message: (message += 'Orphan') };
      case 'all_projects':
        break;
      case 'scheduled':
        return {
          changes: {
            toDate: dateByDayIndexPipe.transform('today'),
            toTime: dateByDayIndexPipe.transform('today'),
          },
          message: (message += 'Scheduled'),
        };
      case 'today':
      case 'day0':
      case 'day1':
      case 'day2':
      case 'day3':
      case 'day4':
      case 'day5':
      case 'day6':
      case 'week':
        const day = dateByDayIndexPipe.transform(sidebar);
        const datePipe = new DatePipe('en-us');

        return {
          changes: { fromDate: day, fromTime: day },
          message: (message += sidebar === 'day0' ? 'Tomorrow' : datePipe.transform(day, 'EEEE')),
        };
      case 'priority':
        break;
      case 'low':
      case 'normal':
      case 'high':
        return { changes: { priority: sidebar }, message: (message += sidebar) };
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      case 'unscheduled':
        return { changes: { toDate: null, toTime: null }, message: (message += 'Unscheduled') };
      case 'overdue':
        break;
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.task;
  }

  asFormGroup(): TaskFormGroup {
    return this.formBuilder.group({
      archived: [this.archived],
      boardPosition: [this.boardPosition],
      color: [this.color],
      columnId: [this.columnId],
      deleted: [this.deleted],
      description: [this.description || ''],
      flagged: [this.flagged],
      fromDate: [this.fromDate],
      fromTime: [this.fromTime],
      followed: [this.followed],
      id: [this.id],
      knots: [this.knots],
      connections: [this.connections],
      linkedInfo: [this.linkedInfo || []],
      reminders: this.formBuilder.array((this.reminders || []).map((reminder) => reminder.asFormGroup())),
      owner: [this.owner],
      parentId: [this.parentId],
      participants: [this.participants || []],
      position: [this.position],
      priority: [this.priority],
      projectId: [this.projectId],
      assigneeId: [this.assigneeId],
      rowId: [this.rowId],
      sectionId: [this.sectionId],
      status: [this.status],
      snoozed: [this.snoozed],
      tags: [this.tags],
      temp: [this.temp],
      title: [this.title, Validators.required],
      toDate: [this.toDate],
      toTime: [this.toTime],
      completed: [this.completed],
      uploads: [this.uploads || [], FileValidators.size(26214400)],
    });
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      boardPosition: Math.ceil(this.boardPosition),
      color: this.color,
      columnId: this.columnId,
      deleted: this.deleted,
      description: this.description,
      flagged: this.flagged,
      pinned: this.pinned,
      fromDate: this.fromDate,
      fromTime: this.fromTime,
      followed: this.followed,
      reminders: (this.reminders || []).map((reminder) => reminder.asPayloadJson(this.fromTime, this.toTime)),
      parentId: this.parentId,
      participants: this.participants,
      position: Math.ceil(this.position),
      priority: this.priority,
      projectId: this.projectId,
      assigneeId: this.assigneeId,
      rowId: this.rowId,
      sectionId: this.sectionId,
      snoozed: this.snoozed,
      title: this.title && this.title.trim(),
      toDate: this.toDate,
      toTime: this.toTime,
      noNotification: this.noNotification,
      completed: this.completed,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(this.title, this.fromDate, this.toDate, this.fromTime, this.toTime);
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.fromDate = data.date;
      this.fromTime = data.date;
      this.toDate = data.date;
      this.toTime = data.date;
    } else if (data instanceof CalendarEvent) {
      this.fromDate = data.startTime;
      this.fromTime = data.startTime;
      this.reminders = data.reminders;
      this.toDate = data.endTime;
      this.toTime = data.endTime;
    } else if (data instanceof Project) {
      this.fromDate = data.fromDate;
      this.fromTime = data.fromTime;
      this.projectId = data.id;
      this.reminders = data.reminders;
      this.toDate = data.toDate;
      this.toTime = data.toTime;
    } else if (data instanceof Task) {
      this.boardPosition = data.boardPosition;
      this.columnId = data.columnId;
      this.columnTitle = data.columnTitle;
      this.fromDate = data.fromDate;
      this.fromTime = data.fromTime;
      this.reminders = data.reminders;
      this.participants = data.participants;
      this.priority = data.priority;
      this.projectId = data.projectId;
      this.assigneeId = data.assigneeId;
      this.assignee = data.assignee;
      this.rowId = data.rowId;
      this.status = data.status;
      this.temp = data.temp;
      this.toDate = data.toDate;
      this.toTime = data.toTime;
      this.completed = data.completed;
    } else if (data instanceof Notebook) {
      this.fromDate = data.createdAt;
      this.fromTime = data.createdAt;
      this.toDate = data.updatedAt;
      this.toTime = data.updatedAt;
    } else if (data instanceof Note) {
      this.fromDate = data.createdAt;
      this.fromTime = data.createdAt;
      this.toDate = data.updatedAt;
      this.toTime = data.updatedAt;
    }
  }
}
