import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Services
import { authGuard } from '../auth/services/auth.guard';
import { PopupGuardService } from './services/popup-guard.service';

// Components
import { PopupWrapperComponent } from './components/popup-wrapper/popup-wrapper.component';
import { MailEditorPopupComponent } from './components/mail-editor-popup/mail-editor-popup.component';
import { FullFormPopupComponent } from './components/full-form-popup/full-form-popup.component';

const popupRoutes: Routes = [
  {
    path: 'compact',
    canActivate: [authGuard],
    canDeactivate: [PopupGuardService],
    component: PopupWrapperComponent,
    children: [
      { path: 'mail/compose', canActivate: [authGuard], component: MailEditorPopupComponent },
      { path: ':stitch-type/:id', canActivate: [authGuard], component: FullFormPopupComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(popupRoutes)],
  exports: [RouterModule],
})
export class PopupRoutingModule {}
