// Common
import { Component, Renderer2, NgZone, OnDestroy, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

// RX
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-resizable',
  templateUrl: './resizable.component.html',
  styleUrls: ['./resizable.component.less'],
  standalone: false,
})
export class ResizableComponent implements AfterViewInit, OnDestroy {
  @Input() height: number;
  @Input() minHeight = 100;

  private dragging = new Subject<void>();
  private alive = new Subject<void>();

  @ViewChild('content') content: ElementRef;

  constructor(
    private renderer: Renderer2,
    private ngZone: NgZone,
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.renderer.setStyle(this.content.nativeElement, 'height', `${this.minHeight}px`);
  }

  ngOnDestroy() {
    this.dragging.next();
    this.dragging.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Resize
   */

  handleResize(event: MouseEvent) {
    this.ngZone.runOutsideAngular(() => {
      this.dragging.next();

      const lastPosition = { x: event.x, y: event.y };

      fromEvent(window, 'mousemove')
        .pipe(takeUntil(this.dragging))
        .subscribe((moveEvent: MouseEvent) => {
          const delta = {
            x: moveEvent.x - lastPosition.x,
            y: moveEvent.y - lastPosition.y,
          };

          const currentSize = {
            width: this.content.nativeElement.clientWidth,
            height: this.content.nativeElement.clientHeight,
          };

          const newSize = {
            // width: Math.max(currentSize.width + delta.x, this.minWidth),
            height: Math.max(currentSize.height + delta.y, this.minHeight),
          };

          if (currentSize.height !== newSize.height) {
            this.renderer.setStyle(this.content.nativeElement, 'height', `${newSize.height}px`);
          }

          // lastPosition.x = lastPosition.x + (this.host.nativeElement.clientWidth - currentSize.width);
          lastPosition.y = lastPosition.y + (this.content.nativeElement.clientHeight - currentSize.height);
        });

      fromEvent(window, 'mouseup')
        .pipe(takeUntil(this.dragging))
        .subscribe(() => {
          this.dragging.next();
        });
    });
  }
}
