// Common
import { Component, inject, Injector, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '@environment';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { CalendarType } from '@modules/calendar-app/types/calendar-type';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { EventsListState } from '@modules/calendar-app/types/events-list-state';
import { ListState } from '@modules/calendar-app/types/list-state';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { Tab } from '@modules/common/types/tab';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { StateKey } from '@modules/settings/types/state-key';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { BehaviorSubject, combineLatest, interval, merge, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

// Utils
import { EventsListStateService } from '@modules/calendar-app/services/events-list-state.service';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.eventsDPListState]?: EventsListState;
  }
}

@Component({
  selector: 'app-events-panel',
  templateUrl: './events-panel.component.html',
  styleUrls: ['./events-panel.component.less'],
  standalone: false,
  providers: [
    EventsListStateService.providers({
      stateKey: StateKey.eventsDPListState,
    }),
  ],
})
export class EventsPanelComponent implements OnInit, OnDestroy {
  @ViewChild('popoverFormTemplate', { static: true }) public popoverFormTemplate: TemplateRef<any>;

  public tabs: Tab[] = [
    { title: 'Today', value: 'today' },
    { title: 'Events', value: 'events' },
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'events';
  public eventsFilters: EventsFilters;
  public contextMenuOpened: boolean;
  public todayFormControl: UntypedFormControl = new UntypedFormControl(new Date());
  public events: AngularCalendarEvent[] = [];
  public hostWidth = 0;
  public calendarEvent: CalendarEvent;
  public formPopoverClose = new Subject<void>();
  public selectedFilter: VirtualFolder;

  private loadTodayTabEvents = new Subject<void>();
  private alive = new Subject<void>();
  private virtualEvent = new BehaviorSubject<CalendarEvent>(null);
  private calendars: Calendar[] = [];

  @Input() searchTemplate: TemplateRef<any>;

  private readonly eventsListStateService = inject(EventsListStateService, { self: true });

  constructor(
    private dpService: DynamicPanelService,
    private stateService: CalendarAppStateService,
    private eventsService: EventsService,
    private calendarsService: CalendarsService,
    private popoverService: PopoverService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
  ) {}

  /**
   * Lifecycle
   */

  public ngOnInit() {
    combineLatest([
      this.eventsListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.eventsFilters = new EventsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    // this.globalStateService.getSplitState('dp')
    //   .pipe(
    //     takeUntil(this.alive),
    //     distinctUntilChanged(),
    //   )
    //   .subscribe((value: {current: number, last: number}) => {
    //     this.hostWidth = value.current;
    //   });

    this.loadTodayTabEvents
      .pipe(
        switchMap(() =>
          this.eventsService.search({
            ...this.eventsFilters,
            fromTime: beginningOfDay(this.todayFormControl.value),
            toTime: endOfDay(this.todayFormControl.value),
          }),
        ),
        map(({ items }) => items),
        switchMap((events: CalendarEvent[]) =>
          this.virtualEvent.pipe(
            map((virtualEvent: CalendarEvent) => (virtualEvent ? [...events, virtualEvent] : events)),
          ),
        ),
        map((events: CalendarEvent[]) => events.map((event) => event.asAngularCalendarEvent())),
        takeUntil(this.alive),
      )
      .subscribe((events: AngularCalendarEvent[]) => (this.events = events));

    merge(
      this.stateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.eventsService.getRefreshRequired(),
      this.todayFormControl.valueChanges,
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadTodayTabEvents.next();
      });

    this.calendarsService
      .search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: calendars }) => {
        this.calendars = calendars;
      });

    this.loadTodayTabEvents.next();
  }

  ngOnDestroy() {
    this.formPopoverClose.next();
    this.formPopoverClose.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewEvent(event = new CalendarEvent()) {
    this.dpService.setFormItem(event);
  }

  openEvent(event: CalendarEvent) {
    this.dpService.setFormItem(event);
  }

  handleDateClicked(cellEvent: CalendarCellClickEvent) {
    this.virtualEvent.next(CalendarEvent.fromCalendarCell(null, cellEvent.date, CalendarType.DAY, true));
  }

  handlePopoverFormClose() {
    this.virtualEvent.next(null);
    this.formPopoverClose.next();
  }

  handlePopoverFormMore(event: CalendarEvent) {
    this.stateService.setMainView(event);
  }

  handleDrop(event: CalendarDropEvent) {
    this.calendarEvent = CalendarEvent.fromCalendarCell(
      CalendarEvent.fromDragData(event.dragData),
      event.newStart,
      CalendarType.DAY,
      true,
    );
    this.calendarEvent.calendarId = this.calendars?.[0]?.id; // TODO introduce 'default calendar' feature
    this.virtualEvent.next(this.calendarEvent);

    this.popoverService.create(event.originRef, {
      template: this.popoverFormTemplate,
      placement: 'left',
      innerShadow: false,
      arrow: true,
      trigger: 'click',
      showUntil: this.formPopoverClose,
      injector: this.injector,
    });
  }

  selectFilter(folder: VirtualFolder): void {
    this.selectedFilter = folder;
    this.stateService.setVirtualFolder(folder);
  }
}
