// Common
import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Services
import { TagsService } from '@modules/tags/services/tags.service';

// Types
import { Tag } from '@modules/tags/types/tag';

@Component({
  selector: 'app-tag-context-menu',
  templateUrl: './tag-context-menu.component.html',
  styleUrls: ['./tag-context-menu.component.less'],
  standalone: false,
})
export class TagContextMenuComponent {
  // Inputs
  @Input() tag: Tag;
  @Input() withDetach = false;

  // Outputs
  @Output() delete = new EventEmitter();
  @Output() pinned = new EventEmitter();

  constructor(
    @Optional() @Inject(CLOSE_POPOVER) private closePopoverToken,
    private tagsService: TagsService,
  ) {}

  /**
   * Actions
   */

  detachTag() {
    this.delete.emit();
  }

  pinTag() {
    this.pinned.emit(this.tag.pinned);
  }

  handleClose() {
    this.closePopoverToken.next();
  }

  deleteTag() {
    if (this.tag.id) {
      this.tagsService.deletePermanently({ ids: [this.tag.id] });
    } else {
      this.delete.emit();
    }
  }
}
