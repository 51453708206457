<app-split-view
  side="left"
  direction="vertical"
  key="kp"
  [collapsedSize]="42"
  [shadow]="true"
  [magneticSize]="120"
>
  <div split-left-pane class="split-area">
    <app-filters-panel />

    <app-tags-knowledge-section
      *ngIf="currentTab === 'tags'"
      [tabs]="tabs"
    />

    <app-knots-knowledge-section
      *ngIf="currentTab === 'knots'"
      [tabs]="tabs"
    />

    <app-connections-knowledge-section
      *ngIf="currentTab === 'connections'"
      [tabs]="tabs"
    />
  </div>

  <div split-right-pane class="split-area">
    <app-knowledge-section
      title="Results"
      [stateKey]="resultsTypeStateKey"
      [tags]="resultsTags"
      [knots]="resultsKnots"
      [connections]="resultsConnections"
      (itemClick)="handleClickItem($event)"
    />
  </div>
</app-split-view>

<ng-template #tabs>
  <app-tabs
    selectedStateKey="kpTabs"
    [values]="tabsValues"
    [value]="currentTab"
    (valueChange)="handleTabsChange($event)"
  />
</ng-template>
