import { ComponentRef, Directive, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { startWith, takeUntil } from 'rxjs/operators';
import { NotesListContextMenuComponent } from '../components/notes-list-context-menu/notes-list-context-menu.component';
import { NotesListStateService } from '../services/notes-list-state.service';

@Directive({
  selector: '[appNotesListContextMenu]',
  standalone: false,
})
export class NotesListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {
  @Input()
  public appNotesListContextMenuWithAdvancedSearch: boolean;
  @Input()
  public appNotesListContextMenuWithScore: boolean;
  @Input()
  public stchPopoverTrigger: PopoverTrigger = 'click';
  @Input()
  public stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  private readonly notesListStateService = inject(NotesListStateService);

  public registerInstance(componentRef: ComponentRef<NotesListContextMenuComponent>) {
    componentRef.instance.state = this.notesListStateService.state;
    componentRef.instance.withAdvancedSearch = this.appNotesListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appNotesListContextMenuWithScore;
  }

  public registerComponent() {
    return NotesListContextMenuComponent;
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.notesListStateService.state
      .changes()
      .pipe(startWith(null), takeUntil(this.alive))
      .subscribe(() => {
        this.isDefaultStateObservable?.next(this.notesListStateService.state.isDefault());
      });

    if (!this.isDefaultStateObservable) {
      return;
    }

    this.isDefaultStateObservable.pipe(takeUntil(this.alive)).subscribe((isDefault) => {
      if (isDefault && !this.notesListStateService.state.isDefault()) {
        this.notesListStateService.state.resetToDefault();
      }
    });
  }
}
