<div class="knots py-2 px-4">
  <span
    *ngIf="!withPlaceholder || knots.length > 0"
    class="text-sm font-medium text-gray-950"
  >
    Knots:
  </span>

  <div
    *ngIf="withPlaceholder && knots.length === 0"
    class="knots-placeholder"
  >
    No knots were extracted
  </div>

  <mat-icon
    *ngIf="withControls"
    class="knots-icon"
    svgIcon="manage"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="manageForm"
    stchPopoverTrigger="click"
    stchPopoverPlacement="right"
    [stchPopoverShowUntil]="hidePopover"
  />

  <mat-icon
    *ngIf="withControls"
    class="knots-icon"
    svgIcon="plus-manage"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="addForm"
    stchPopoverTrigger="click"
    stchPopoverPlacement="right"
    [stchPopoverShowUntil]="hidePopover"
  />

  <app-context-menu-state-indicator
    class="knots-icon no-width"
    svgIcon="filters"
    appKnotsManageContextMenu
    [appKnotsManageContextMenuStateKey]="stateKey"
    (appKnotsManageContextMenuStateChange)="setState($event)"
  />

  <div
    *ngIf="withControls"
    class="knots-icon no-width debug"
    [class.active]="debug"
    (click)="doDebug()"
  >
    {{ debug || 'Debug' }}
  </div>

  <ng-container *ngIf="withTemporalExpressions">
    <app-temporal-expression
      *ngFor="let expression of temporalExpressions"
      class="align-middle inline-block mr-2"
      [expression]="expression"
      [selected]="selectedTemporalExpressions | includesBy : expression?.id : 'id'"
      [debug]="debug"
      (click)="handleSelectTemporalExpression(expression, $event)"
    />
  </ng-container>

  <app-knot
    *ngFor="let knot of knots | slice : 0 : (page + 1) * perPage"
    class="knots-item mr-2"
    [knot]="knot"
    [withDetach]="true"
    [withControls]="withControls"
    [selected]="selectedKnots | includesBy : knot?.name : 'name'"
    [debug]="debug"
    (delete)="handleDelete(knot)"
    (pin)="handlePin(knot)"
    (click)="handleClick(knot, $event)"
  />

  <div
    *ngIf="page !== 0"
    class="knots-item knots-more"
    (click)="showLess()"
  >
    Less
  </div>

  <div
    *ngIf="page < pagesCount - 1"
    class="knots-item knots-more"
    (click)="showMore()"
  >
    More
  </div>
</div>

<ng-template #manageForm>
  <app-manage-knots-form
    [existingKnots]="knots"
    (close)="closePopovers()"
    (save)="saveKnots($event)"
  />
</ng-template>

<ng-template #addForm>
  <app-add-knots
    [stitchItems]="stitchItems"
    [control]="control"
    (close)="closePopovers()"
    (save)="saveKnots($event)"
  />
</ng-template>
