import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-popup-wrapper',
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.less'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class PopupWrapperComponent {}
