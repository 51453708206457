// Common
import { Injectable, Injector } from '@angular/core';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { ListState } from '@modules/contacts/types/list-state';
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { VirtualFolder } from '../types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

@Injectable()
export class ContactsAppStateService extends BaseAppStateService<Group, Contact, VirtualFolder> {
  protected application = Application.contacts;
  protected defaultVirtualFolder: VirtualFolder = 'all_contacts';

  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Actions
   */

  getTabs(): Observable<ListState> {
    return this.getVirtualFolder().pipe(
      map((folder) => {
        switch (folder) {
          case 'all_contacts':
            return 'contacts';
          case 'vip':
          case 'flagged':
          case 'upcoming':
          case 'frequent':
          case 'recent':
          case 'archived':
          case 'deleted':
          case 'followed':
          case 'snoozed':
            return 'tabs';
          case 'all_groups':
            return 'groups';
          default:
            return 'contacts';
        }
      }),
    );
  }
}
