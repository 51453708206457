<div
  *ngIf="file"
  class="file"
  [class.no-actions]="!withActions"
  [class.pinned]="file.pinned"
  [class.short-view]="viewType === 'short'"
  [class.with-description]="file.description"
  [class.with-linked-info]="file.linkedInfo?.length"

  [appFileContextMenu]="file"
  [appFileContextMenuDisabled]="!withContextMenu"
  (appFileContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [file], type: 'file'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div
    class="file-type-icon"
    [style.backgroundColor]="file.attachmentType | fileColorByType"
  >
    {{ file.attachmentType | fileMimeToExt }}
  </div>

  <div class="file-body">
    <div class="file-body-heading">
      <div class="file-body-heading-title">
        {{ file.title }}
      </div>
    </div>

    <div class="file-body-date">
      <div class="file-body-date-item">
        {{ file.createdAt ? 'Upload Date: ' + (file.createdAt | date : 'MMM d') : 'No Upload Date' }}
      </div>
    </div>

    <div class="file-body-description" *ngIf="file.description && viewType !== 'short'">
      {{file.description}}
    </div>

    <app-link-info-bar
      class="file-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="file"
    />
  </div>

  <mat-icon
    class="file-pin-icon"
    [class.active]="file.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  />

  <mat-icon
    class="file-flag-icon"
    [class.active]="file.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  />

  <mat-icon class="file-marker" svgIcon="file" />
  <mat-icon class="file-attachments-icon" *ngIf="file.files?.length" svgIcon="attachment" />

  <div
    class="file-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="download-large"
      matTooltip="Coming Soon"
    />
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="file"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    />
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="archive"
      [class.active]="file.archived"
      [matTooltip]="file?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    />
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="trash"
      [class.active]="file.deleted"
      [matTooltip]="file?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div
    class="file-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="trash"
      [class.active]="file.deleted"
      [matTooltip]="file?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    />
  </div>

  <div class="file-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="file-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    />
  </div>

  <div class="drop-zone"></div>
</div>

<div
  *ngIf="!file"
  class="placeholder"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-file
    [file]="file"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  />
</ng-template>
