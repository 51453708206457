<div class="connections">
  <div class="connections-title">
    Manage Connections
  </div>

  <mat-icon
    *ngIf="!connections?.length"
    class="connections-top-icon"
    svgIcon="close-thin"
    (click)="handleCancel()"
  />

  <mat-icon
    *ngIf="connections?.length"
    class="connections-top-icon"
    svgIcon="filters"
    appConnectionsManageContextMenu
    [appConnectionsManageContextMenuStateKey]="'mf-connections'"
    (appConnectionsManageContextMenuStateChange)="state = $event"
    [appConnectionsManageContextMenuWithTemporalExpressions]="false"
  />

  <app-simple-input
    class="connections-input"
    [inputFormControl]="inputControl"
    placeholder=""
  />

  <div class="connections-add">
    <div
      *ngIf="canAdd"
      class="connections-add-button"
      (click)="addConnection()"
    >
      + Add Connection «{{ inputControl.value }}»
    </div>
  </div>

  <div
    *ngIf="!connections?.length"
    class="connections-placeholder"
  >
    <img
      class="connections-placeholder-image"
      src="assets/placeholders/placeholder-stitch-info.jpg"
      alt="image for empty connections list"
    />
    <div class="connections-placeholder-text">
      You Have no Connections Added Yet
    </div>
  </div>

  <app-scroll
    *ngIf="connections?.length"
    class="connections-list"
    (top)="scrollShadowTop = !$event"
    (bottom)="scrollShadowBottom = !$event"
  >
    <div class="shadow" *ngIf="scrollShadowTop"></div>

    <ng-container *ngIf="state.sort.by === 'name'">
      <div
        class="connections-list-group"
        *ngFor="let group of ((connections | sortAndFilter : state) | groupAlphabetically : state)"
      >
        <div class="connections-list-group-letter">{{ group.letter }}</div>
        <div class="connections-list-group-connections">
          <app-manage-connection
            *ngFor="let connection of group.connections | sortAndFilter : state; let index = index"
            [connection]="connection"
            (delete)="handleDelete(connection)"
            (change)="handleChange(connection)"
          />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="state.sort.by !== 'name'">
      <div class="connections-list-group">
        <div class="connections-list-group-letter-filler"></div>
        <div class="connections-list-group-connections">
          <app-manage-connection
            *ngFor="let connection of connections | sortAndFilter : state"
            [connection]="connection"
            (delete)="handleDelete(connection)"
          />
        </div>
      </div>
    </ng-container>
  </app-scroll>

  <div class="shadow-bottom">
    <div class="shadow-bottom-inner" *ngIf="scrollShadowBottom"></div>
  </div>

  <div
    *ngIf="connections?.length"
    class="connections-buttons"
  >
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
