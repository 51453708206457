import { Pipe, PipeTransform } from '@angular/core';
import { RelativeDate } from '@modules/common/types/relative-date';
import { beginningOfDay, relativeDateToDate } from '@modules/common/utils/date';

@Pipe({
  name: 'disableQuickDatePicker',
  standalone: false,
})
export class DisableQuickDatePickerPipe implements PipeTransform {
  transform(comparableDate: Date, selectedPicker: 'from' | 'to', parameter: RelativeDate): boolean {
    const date = relativeDateToDate(parameter);

    return comparableDate && selectedPicker === 'from'
      ? beginningOfDay(date) > (comparableDate && beginningOfDay(comparableDate))
      : beginningOfDay(date) < (comparableDate && beginningOfDay(comparableDate));
  }
}
