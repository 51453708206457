// Common
import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ContentChild,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { AnimationTriggerMetadata, trigger, transition, style, animate } from '@angular/animations';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Components
import { MessageFolderQuickFormComponent } from '../message-folder-quick-form/message-folder-quick-form.component';
import { MessageQuickFormComponent } from '@modules/elements/components/quick-forms/message-quick-form/message-quick-form.component';
import { EventQuickFormComponent } from '../event-quick-form/event-quick-form.component';
import { ProjectQuickFormComponent } from '../project-quick-form/project-quick-form.component';
import { TaskQuickFormComponent } from '../task-quick-form/task-quick-form.component';
import { NotebookQuickFormComponent } from '../notebook-quick-form/notebook-quick-form.component';
import { NoteQuickFormComponent } from '../note-quick-form/note-quick-form.component';
import { GroupQuickFormComponent } from '../group-quick-form/group-quick-form.component';
import { ContactQuickFormComponent } from '../contact-quick-form/contact-quick-form.component';
import { FolderQuickFormComponent } from '../folder-quick-form/folder-quick-form.component';
import { FileQuickFormComponent } from '../file-quick-form/file-quick-form.component';
import { CalendarQuickFormComponent } from '@modules/elements/components/quick-forms/calendar-quick-form/calendar-quick-form.component';

// RX
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { StitchType } from '@modules/common/types/stitch-type';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { MailMessage } from '@modules/mail/types/mail-message';
import { Message } from '@modules/messages/types/message';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { Account } from '@modules/account/types/account';
import { ContactEmail } from '@modules/contacts/types/contact-info';
import { Calendar } from '@modules/calendar-app/types/calendar';

const collapseMotion: AnimationTriggerMetadata = trigger('collapseMotion', [
  transition('collapsed => expanded', [
    style({ height: '24px', minHeight: 0 }),
    animate(`.2s ease-in-out`, style({ height: '{{height}}px' })),
  ]),
  transition('expanded => collapsed', [
    style({ height: '{{contentHeight}}px', minHeight: 0 }),
    animate(`.2s ease-in-out`, style({ height: '24px' })),
  ]),
]);

@Component({
  selector: 'app-quick-form',
  templateUrl: './quick-form.component.html',
  styleUrls: ['./quick-form.component.less'],
  animations: [collapseMotion],
  providers: [{ provide: CLOSE_POPOVER, useFactory: () => new Subject<void>() }],
  standalone: false,
})
export class QuickFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  // Inputs
  @Input() placeholder: string;
  @Input() inline = false;

  // Outputs
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  // Public
  public expanded = false;
  public formsSizes = {
    [StitchType.messageFolder]: 150,
    [StitchType.message]: 220,
    [StitchType.calendar]: 220,
    [StitchType.event]: 190,
    [StitchType.project]: 190,
    [StitchType.task]: 155,
    [StitchType.notebook]: 150,
    [StitchType.note]: 150,
    [StitchType.group]: 150,
    [StitchType.contact]: 157,
    [StitchType.folder]: 150,
    [StitchType.file]: 230,
  };
  public currentFormSelector: StitchType;
  public forms: {
    [StitchType.messageFolder]: MessageFolderQuickFormComponent;
    [StitchType.message]: MessageQuickFormComponent;
    [StitchType.calendar]: CalendarQuickFormComponent;
    [StitchType.event]: EventQuickFormComponent;
    [StitchType.project]: ProjectQuickFormComponent;
    [StitchType.task]: TaskQuickFormComponent;
    [StitchType.notebook]: NotebookQuickFormComponent;
    [StitchType.note]: NoteQuickFormComponent;
    [StitchType.group]: GroupQuickFormComponent;
    [StitchType.contact]: ContactQuickFormComponent;
    [StitchType.folder]: FolderQuickFormComponent;
    [StitchType.file]: FileQuickFormComponent;
  };
  public contentHeight = 0;
  public account: Account;
  public enterKeyPressed = new Subject<void>();

  // Private
  private alive = new Subject<void>();
  private currentForm:
    | MessageFolderQuickFormComponent
    | MessageQuickFormComponent
    | CalendarQuickFormComponent
    | EventQuickFormComponent
    | ProjectQuickFormComponent
    | TaskQuickFormComponent
    | NotebookQuickFormComponent
    | NoteQuickFormComponent
    | GroupQuickFormComponent
    | ContactQuickFormComponent
    | FolderQuickFormComponent
    | FileQuickFormComponent;

  // Content Children
  @ContentChild(MessageFolderQuickFormComponent, { static: true })
  private messageFolderForm: MessageFolderQuickFormComponent;
  @ContentChild(MessageQuickFormComponent, { static: true }) private messageForm: MessageQuickFormComponent;
  @ContentChild(CalendarQuickFormComponent, { static: true }) private calendarForm: CalendarQuickFormComponent;
  @ContentChild(EventQuickFormComponent, { static: true }) private eventForm: EventQuickFormComponent;
  @ContentChild(ProjectQuickFormComponent, { static: true }) private projectForm: ProjectQuickFormComponent;
  @ContentChild(TaskQuickFormComponent, { static: true }) private taskForm: TaskQuickFormComponent;
  @ContentChild(NotebookQuickFormComponent, { static: true }) private notebookForm: NotebookQuickFormComponent;
  @ContentChild(NoteQuickFormComponent, { static: true }) private noteForm: NoteQuickFormComponent;
  @ContentChild(GroupQuickFormComponent, { static: true }) private groupForm: GroupQuickFormComponent;
  @ContentChild(ContactQuickFormComponent, { static: true }) private contactForm: ContactQuickFormComponent;
  @ContentChild(FolderQuickFormComponent, { static: true }) private folderForm: FolderQuickFormComponent;
  @ContentChild(FileQuickFormComponent, { static: true }) private fileForm: FileQuickFormComponent;

  // View Children
  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;

  /**
   * Constructor
   */

  constructor(private changeDetector: ChangeDetectorRef) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.enterKeyPressed
      .pipe(
        filter(() => !!this.currentForm.submit),
        takeUntil(this.alive),
      )
      .subscribe(() => this.currentForm.submit());
  }

  ngAfterViewInit() {
    this.forms = {
      [StitchType.messageFolder]: this.messageFolderForm,
      [StitchType.message]: this.messageForm,
      [StitchType.event]: this.eventForm,
      [StitchType.calendar]: this.calendarForm,
      [StitchType.project]: this.projectForm,
      [StitchType.task]: this.taskForm,
      [StitchType.notebook]: this.notebookForm,
      [StitchType.note]: this.noteForm,
      [StitchType.group]: this.groupForm,
      [StitchType.contact]: this.contactForm,
      [StitchType.folder]: this.folderForm,
      [StitchType.file]: this.fileForm,
    };

    const [[selector, form]] = Object.entries(this.forms).filter(([_formType, formComponent]) => !!formComponent);
    this.currentFormSelector = selector as StitchType;
    this.changeDetector.detectChanges();
    this.currentForm = form;
    this.currentForm.inline = this.inline;
    this.currentForm.changeDetector.detectChanges();
    this.currentForm.close.pipe(takeUntil(this.alive)).subscribe(() => this.handleClose());
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inline' in changes && this.currentForm) {
      this.currentForm.inline = this.inline;
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  expand() {
    if (this.currentForm) {
      this.currentForm.titleFocusSubject.next();
    }
    this.expanded = true;
  }

  handleClose() {
    this.contentHeight = this.contentContainer.nativeElement.offsetHeight;
    this.expanded = false;
    this.currentForm.reset();
  }

  handleDrop(dragData: DragData) {
    if (!this.currentForm) {
      return;
    }

    if (this.currentForm instanceof MessageFolderQuickFormComponent) {
      this.currentForm.folder = MessageFolder.fromDragData(dragData);
    } else if (this.currentForm instanceof MessageQuickFormComponent) {
      this.currentForm.message = Message.fromDragData(dragData);
    } else if (this.currentForm instanceof CalendarQuickFormComponent) {
      this.currentForm.calendar = Calendar.fromDragData(dragData);
    } else if (this.currentForm instanceof EventQuickFormComponent) {
      this.currentForm.event = CalendarEvent.fromDragData(dragData);
    } else if (this.currentForm instanceof TaskQuickFormComponent) {
      this.currentForm.task = Task.fromDragData(dragData);
    } else if (this.currentForm instanceof ProjectQuickFormComponent) {
      this.currentForm.project = Project.fromDragData(dragData);
    } else if (this.currentForm instanceof NotebookQuickFormComponent) {
      this.currentForm.notebook = Notebook.fromDragData(dragData);
    } else if (this.currentForm instanceof NoteQuickFormComponent) {
      this.currentForm.note = Note.fromDragData(dragData);
    } else if (this.currentForm instanceof GroupQuickFormComponent) {
      this.currentForm.group = Group.fromDragData(dragData);
    } else if (this.currentForm instanceof ContactQuickFormComponent) {
      const contact = Contact.fromDragData(dragData);

      if (dragData.type === 'message') {
        const message = dragData.data[0] as MailMessage;

        const { email: contactEmail, name: contactTitle } = Contact.getTitleFromMail(message, this.account) || {
          email: message.from.email,
          name: message.from.name,
        };

        contact.emails.push(new ContactEmail({ value: contactEmail }));
        contact.title = contactTitle;
      }

      this.currentForm.contact = contact;
    } else if (this.currentForm instanceof FolderQuickFormComponent) {
      this.currentForm.folder = Folder.fromDragData(dragData);
    } else if (this.currentForm instanceof FileQuickFormComponent) {
      this.currentForm.fromDnd = true;
      this.currentForm.file = File.fromDragData(dragData);
    }

    this.currentForm.reset();
    this.expand();
  }
}
