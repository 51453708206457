// Common
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Types
import { User } from '@modules/admin/types/user';
import { TableColumn } from '@modules/common/types/table-column';
import { SortOrder } from '@modules/common/types/sort-order';
import { UsersFilters } from '@modules/admin/types/users-filters';

// Services
import { AdminUsersService } from '@modules/admin/services/users.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin-users-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less'],
  standalone: false,
})
export class UsersUsersComponent implements OnInit, OnDestroy {
  public users: User[];
  public page = 0;
  public perPage = 10;
  public sortBy: UsersFilters['sortBy'] = 'birthDate';
  public sortOrder: SortOrder = 'asc';
  public count = 0;
  public columns: TableColumn[] = [];

  private reload = new Subject<void>();
  private alive = new Subject<void>();

  @ViewChild('birthDateTemplate', { static: true }) birthDateTemplate: TemplateRef<void>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<void>;
  @ViewChild('avatarTemplate', { static: true }) avatarTemplate: TemplateRef<void>;

  constructor(
    private usersService: AdminUsersService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.columns = [
      { key: 'avatar', template: this.avatarTemplate },
      { key: 'userName', title: 'Name', sortable: true },
      { key: 'recoveryEmail', title: 'Recovery Email', sortable: true },
      { key: 'birthDate', title: 'Birth Date', sortable: true, template: this.birthDateTemplate },
      { key: 'admin', title: 'Admin', sortable: true },
      { key: 'createdAt', title: 'Account Created At', sortable: true, template: this.dateTemplate },
    ];

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() =>
          this.usersService.search({
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
            limit: this.perPage,
            offset: this.page * this.perPage,
          }),
        ),
        takeUntil(this.alive),
      )
      .subscribe(({ items: users, count }) => {
        this.users = users;
        this.count = count;
      });

    this.reload.next();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: UsersFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  handleRowClick(user: User) {
    this.router.navigate([`/admin/users/${user.id}`]);
  }
}
