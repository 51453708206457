// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { InputAppearance } from '@modules/form-controls/types/input-appearance';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Icon } from '@modules/icons/types/icons';

// RX
import { of, Subject } from 'rxjs';
import { switchMap, filter, takeUntil, debounceTime, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.less'],
  standalone: false,
})
export class AutocompleteInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appearance: InputAppearance = 'default';
  @Input() inputFormControl: UntypedFormControl;
  @Input() suggestions?: AutocompleteFactory<unknown>;
  @Input() label: string;
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() noBorder = false;
  @Input() withClear = false;
  @Input() focusImmediately = false;
  @Input() useInputText = false;
  @Input() leftIcon: Icon;
  @Input() invertedColor = false;
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() placeholder = '';
  @Input() hint: string;
  /**
   * [size] 'xs' | 's' | 'm' | 'l' only for 'sapphire-*'
   */
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';

  public innerControl = new UntypedFormControl();
  public options: DropdownSelectItem<unknown>[] = [];
  public popoverCustomTrigger = new Subject<void>();
  public popoverHide = new Subject<void>();
  public focused = false;
  public popoverOpened = false;
  public loading = false;

  private alive = new Subject<void>();
  private suggestionsChanged = new Subject<void>();
  private inputControlChanged = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestionsChanged
      .pipe(
        switchMap(() => this.innerControl.valueChanges.pipe(startWith(''))),
        debounceTime(700),
        filter(() => !!this.suggestions),
        tap(() => {
          this.loading = true;
        }),
        switchMap((title) => this.suggestions(title)),
        takeUntil(this.alive),
      )
      .subscribe((options) => {
        this.loading = false;
        this.options = options;

        if (this.focused && this.options.length > 0) {
          this.open();
        }
      });

    this.suggestionsChanged.next();

    this.inputControlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        switchMap(() => this.inputFormControl.valueChanges.pipe(startWith(this.inputFormControl.value))),
        filter((value) => value !== this.innerControl.value), // is this for useInputText only?
        switchMap((value) => {
          if (this.useInputText) {
            return of([{ title: value }]);
          } else {
            return value && this.suggestions ? this.suggestions(undefined, [value]) : of([]);
          }
        }),
        takeUntil(this.alive),
      )
      .subscribe(([option]) => {
        this.innerControl.setValue(option?.title);
      });

    this.inputControlChanged.next();

    this.innerControl.valueChanges
      .pipe(
        filter(() => this.useInputText),
        takeUntil(this.alive),
      )
      .subscribe((value) => this.inputFormControl.setValue(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('suggestions' in changes) {
      this.suggestionsChanged.next();
    }

    if ('inputFormControl' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFocus() {
    this.focused = true;

    if (this.options.length) {
      this.open();
    }
  }

  handleSelect(option: DropdownSelectItem<unknown>) {
    if (option.disabled) {
      return;
    }

    this.inputFormControl.setValue(option.value);
    this.inputFormControl.markAsDirty();
    this.close();
  }

  handleClear() {
    this.innerControl.setValue(null);
    this.inputFormControl.setValue(null);
    this.inputFormControl.markAsDirty();
  }

  open() {
    if (this.popoverOpened) {
      return;
    }
    this.popoverCustomTrigger.next();
  }

  close() {
    if (!this.popoverOpened) {
      return;
    }
    this.popoverHide.next();
  }
}
