// Common
import { Directive, Output, EventEmitter, ElementRef, AfterViewInit, OnDestroy, NgZone } from '@angular/core';

// RX
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appScroll]',
  standalone: false,
})
export class ScrollDirective implements AfterViewInit, OnDestroy {
  private alive = new Subject<void>();
  private topReached = true;
  private bottomReached = true;

  @Output() appScrollTopReached = new EventEmitter<boolean>();
  @Output() appScrollBottomReached = new EventEmitter<boolean>();

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.element.nativeElement, 'scroll')
        .pipe(takeUntil(this.alive))
        .subscribe(this.handleScroll.bind(this));
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleScroll(event: Event) {
    const scrollPosition = event.target['scrollTop'];

    if (!this.topReached && scrollPosition === 0) {
      this.ngZone.run(() => {
        this.appScrollTopReached.emit(true);
        this.topReached = true;
      });
    }

    if (this.topReached && scrollPosition > 0) {
      this.ngZone.run(() => {
        this.appScrollTopReached.emit(false);
        this.topReached = false;
      });
    }

    if (!this.bottomReached && event.target['scrollHeight'] - 5 < event.target['clientHeight'] + scrollPosition) {
      this.ngZone.run(() => {
        this.appScrollBottomReached.emit(true);
        this.bottomReached = true;
      });
    }

    if (this.bottomReached && event.target['scrollHeight'] - 5 > event.target['clientHeight'] + scrollPosition) {
      this.ngZone.run(() => {
        this.appScrollBottomReached.emit(false);
        this.bottomReached = false;
      });
    }
  }
}
