<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Inbox"
  [active]="filterKey === 'all_messages'"
  (execute)="selectFilter('all_messages')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Bulk"
  [active]="filterKey === 'bulk'"
  (execute)="selectFilter('bulk')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Sent"
  [active]="filterKey === 'sent'"
  (execute)="selectFilter('sent')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Flagged"
  [active]="filterKey === 'flagged'"
  (execute)="selectFilter('flagged')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Follow Up"
  [active]="filterKey === 'followed'"
  (execute)="selectFilter('followed')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Snoozed"
  [active]="filterKey === 'snoozed'"
  (execute)="selectFilter('snoozed')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Scheduled"
  [active]="filterKey === 'scheduled'"
  (execute)="selectFilter('scheduled')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Spam"
  [active]="filterKey === 'spam'"
  (execute)="selectFilter('spam')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Archive"
  [active]="filterKey === 'archived'"
  (execute)="selectFilter('archived')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Trash"
  [active]="filterKey === 'deleted'"
  (execute)="selectFilter('deleted')"
/>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Folders"
  [active]="filterKey === 'all_folders'"
  (execute)="selectFilter('all_folders')"
/>
