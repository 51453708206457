import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

interface StorageConfig<T> {
  default?: T;
  lsKey?: string;
  parse?: (input: { raw: unknown; variant: string }) => T;
  compare?: (a: T, b: T) => boolean;
  transform?: (a: T) => string;
  values?: string[];
}

class Item<T> {
  private value: { [key: string]: BehaviorSubject<T> } = {};
  private key: string;
  private config: StorageConfig<T>;

  constructor(propertyKey: string, config: StorageConfig<T>) {
    this.key = config?.lsKey || propertyKey;
    this.config = config;
  }

  public getSync(variant: string = 'default'): T {
    return cloneDeep(this.getValueSubject(variant).value);
  }

  public get(variant: string = 'default'): Observable<T> {
    return this.getValueSubject(variant).asObservable();
  }

  public set(newValue: T, ...variants: string[]) {
    const variant = variants.length ? variants.join('.') : 'default';

    const currentValue = this.getSync(variant);

    if (this.config.compare) {
      if (this.config.compare(currentValue, newValue)) {
        return;
      }
    } else {
      if (currentValue === newValue) {
        // Use objectsEqual after AS refactoring
        return;
      }
    }

    localStorage.setItem(
      variant === 'default' ? this.key : `${this.key}.${variant}`,
      JSON.stringify(this.config.transform ? this.config.transform(newValue) : newValue),
    );
    this.getValueSubject(variant).next(newValue);
  }

  private getValueSubject(variant?: string): BehaviorSubject<T> {
    if (!this.value[variant]) {
      let raw: T | null = null;
      const stored: string | null = localStorage.getItem(variant === 'default' ? this.key : `${this.key}.${variant}`);
      if (stored !== null && stored !== undefined) {
        try {
          raw = JSON.parse(stored);
        } catch (_e) {
          raw = null;
        }
      }

      const value = this.config.parse ? this.config.parse({ raw, variant }) : raw || this.config.default;

      this.value[variant] = new BehaviorSubject<T>(value);
    }

    return this.value[variant];
  }
}

export type LocalStorageItem<T> = Item<T>;

const decorate = <T>(config: StorageConfig<T>) => {
  return (target: object, propertyKey: string) => {
    const item = new Item<T>(propertyKey, config);

    Object.defineProperty(target, propertyKey, {
      get() {
        return item;
      },
    });
  };
};

export const LSItem = (config: StorageConfig<unknown> = {}) => decorate(config);

export const LSString = (config: StorageConfig<string> = {}) => decorate(config);

export const LSBoolean = (config: StorageConfig<boolean> = {}) =>
  decorate({
    ...config,
    parse:
      config.parse ||
      (({ raw }) => {
        if (raw === true || raw === 'true') {
          return true;
        } else if (raw === false || raw === 'false') {
          return false;
        } else if (config.default !== undefined) {
          return config.default;
        } else {
          return false;
        }
      }),
  });

export const LSDate = (config: StorageConfig<Date> = {}) =>
  decorate({
    ...config,
    parse:
      config.parse ||
      (({ raw }) => {
        let result: Date | null;
        if (typeof raw === 'number' || typeof raw === 'string' || (raw && raw instanceof Date)) {
          result = new Date(raw);
          result.setHours(0);
          result.setMinutes(0);
          result.setSeconds(0);
          result.setMilliseconds(0);

          if (isNaN(result.getTime())) {
            result = config.default || null;
          }
        }
        return result;
      }),
    compare:
      config.compare ||
      ((a: Date, b: Date) =>
        a?.getFullYear() === b?.getFullYear() && a?.getMonth() === b?.getMonth() && a?.getDate() === b?.getDate()),
    transform: config.transform || ((v: Date) => v?.toISOString()),
  });

export const LSDateTime = (config: StorageConfig<Date> = {}) =>
  decorate({
    ...config,
    parse:
      config.parse ||
      (({ raw }) => {
        let result: Date | null;

        if (typeof raw === 'number' || typeof raw === 'string' || (raw && raw instanceof Date)) {
          result = new Date(raw);

          if (isNaN(result.getTime())) {
            result = config.default || null;
          }
        }

        return result;
      }),
    compare: config.compare || ((a: Date, b: Date) => a?.getTime() === b?.getTime()),
  });

interface StorageNumberConfig<T> extends StorageConfig<T> {
  min?: number;
  max?: number;
}

export const LSNumber = (config: StorageNumberConfig<number> = {}) =>
  decorate({
    ...config,
    parse:
      config.parse ||
      (({ raw }) => {
        let result: number | null;
        if (typeof raw === 'string') {
          result = parseFloat(raw);
        } else if (typeof raw === 'number') {
          result = raw;
        }

        if (isNaN(result)) {
          result = config.default ?? null;
        }

        if (!isNaN(config.min)) {
          result = Math.max(config.min, result);
        }

        if (!isNaN(config.max)) {
          result = Math.min(config.max, result);
        }

        return result;
      }),
    compare: config.compare || ((a: number, b: number) => b > config.max || b < config.min || a === b),
  });

export const LSEnum = (config: StorageConfig<string> = {}) =>
  decorate({
    ...config,
    parse:
      config.parse ||
      (({ raw }) => {
        if (typeof raw === 'string') {
          if (config.values && !config.values.includes(raw)) {
            return config.default || null;
          }
        }

        return raw;
      }),
  });

export const LSStringArray = (config: StorageConfig<string[]> = {}) =>
  decorate({
    ...config,
    parse:
      config.parse ||
      (({ raw }) => {
        return Array.isArray(raw) ? raw : config.default || [];
      }),
    compare: config.compare || ((a: string[], b: string[]) => a.sort().join() === b.sort().join()),
  });
