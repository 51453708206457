// Common
import { Injectable } from '@angular/core';
import { environment } from '@environment';

// Types
import { SpaceSettings } from '../types/space-settings';

// Services
import { BaseSettingsService } from '@modules/common/services/base-settings.service';

@Injectable()
export class SpaceSettingsService extends BaseSettingsService<SpaceSettings> {
  protected url = `${environment.baseUrl}/api/account/settings`;

  public override createInstance(data?: any) {
    return new SpaceSettings(data);
  }
}
