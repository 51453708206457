// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RX
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Attachment } from '@modules/form-controls/types/attachment';
import { Stitch } from '@modules/common/types/stitch';

// Services
import { AttachmentsService } from '@modules/attachments/services/attachments.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.less'],
  standalone: false,
})
export class AttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  // Inputs
  @Input() stitchItem: Stitch;
  @Input() receivedDate: Date = new Date();

  // Public
  public attachments: Attachment[] = [];

  // Private
  private stitchItemChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(private attachmentsService: AttachmentsService) {}

  /**
   * Lifecycle
   */

  ngOnInit(): void {
    this.stitchItemChanged
      .pipe(
        switchMap(() => (this.stitchItem ? this.attachmentsService.listAll(this.stitchItem) : of([]))),
        takeUntil(this.alive),
      )
      .subscribe((attachments) => {
        this.attachments = attachments;
      });

    this.stitchItemChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('stitchItem' in changes) {
      this.stitchItemChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
