// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { Account } from '@modules/account/types/account';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';

// RX
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Utils
import { daysDifference } from '@modules/common/utils/date';

@Component({
  selector: 'app-settings-general-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.less'],
  standalone: false,
})
export class PlansComponent implements OnInit, OnDestroy {
  public plans: SubscriptionPlan[];
  public currentPlan: SubscriptionPlan;
  public account: Account;
  public nextPlanTitle: string;
  public planMessage: string;
  public rows: { label: string; value: string; type?: string; template?: string }[] = [
    { label: 'Disc Space', value: 'maxDiskSpace', template: '$1 Gb' },
    { label: 'Additional Disk Space', value: 'additionalDiskSpacePricePerGb', template: '1 Gb / $$1' },
    { label: 'Email', value: 'mailAppEnabled', type: 'boolean' },
    { label: 'Calendar', value: 'calendarAppEnabled', type: 'boolean' },
    { label: 'Contacts', value: 'contactsAppEnabled', type: 'boolean' },
    { label: 'Notes', value: 'notesAppEnabled', type: 'boolean' },
    { label: 'Files', value: 'filesAppEnabled', type: 'boolean' },
    { label: 'Tasks', value: 'taskingAppEnabled', type: 'boolean' },
    { label: 'Tags', value: 'tagsEnabled', type: 'boolean' },
    { label: 'Stitching', value: 'connectionsEnabled', type: 'boolean' },
    { label: 'Knots', value: 'knotsEnabled', type: 'boolean' },
    { label: 'Max Spaces Count', value: 'maxSpaces' },
    { label: 'Max Space Members', value: 'maxSpaceMembers' },
    { label: 'Max Tags Count', value: 'maxTagsCount' },
    { label: 'Max Knots Count', value: 'maxKnotsCount' },
    { label: 'Max Connections Count', value: 'maxConnectionsCount' },
    { label: 'Max Inboxes', value: 'maxInboxes' },
    { label: 'Max Number Of Teams', value: 'maxTeams' },
    { label: 'Max Team Members', value: 'maxTeamMembers' },
  ];

  private alive = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private plansService: SubscriptionPlansService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([this.accountService.getAccount(true), this.plansService.search()])
      .pipe(takeUntil(this.alive))
      .subscribe(([account, { items: plans }]) => {
        this.account = account;
        this.plans = plans;
        this.currentPlan = plans.find((plan) => plan.id === account.subscriptionPlanId);
        this.planMessage = this.getPlanMessage();
        this.nextPlanTitle = plans.find((plan) => plan.id === this.account.nextSubscriptionPlanId)?.title;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  getPlanMessage() {
    if (this.account.subscriptionStatus === 'trial') {
      const daysLeft = daysDifference(new Date(), this.account.nextChargeDate);
      if (daysLeft === 0) {
        return `We wanted to let you know that your trial plan will expire today`;
      }
      if (daysLeft > 0) {
        return `We wanted to let you know that your trial plan will expire in ${daysLeft} days`;
      }
      return `We wanted to let you know that your trial plan has expired as of ${Math.abs(daysLeft)} days`;
    }
    return this.currentPlan ? `$${this.currentPlan.monthlyPrice} per month` : null;
  }
}
