// Common
import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  NgZone,
  Output,
  EventEmitter,
  Self,
  Inject,
} from '@angular/core';

// RxJS
import { interval, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { Contact } from '@modules/contacts/types/contact';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }],
  standalone: false,
})
export class ContactsListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {
  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no contacts';
  @Input() filters: Like<ContactsFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  // Outputs
  @Output() clickContact = new EventEmitter<Contact>();
  @Output() openContact = new EventEmitter<Contact>();
  @Output() doubleClickContact = new EventEmitter<Contact>();

  // Public
  public itemHeight = 94;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private contactsService: ContactsService,
    private contactsStateService: ContactsAppStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject,
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter((value) => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed),
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.contactsStateService.getRefreshAll(),
      this.linkedInfoService.getRefreshRequired(),
      // Make automatic updates for new contact
      interval(environment.messageFetchInterval).pipe(
        filter(() => !this.loading && this.selectedOrder && this.selectedOrder.key === 'date'),
      ),
      this.contactsService.getRefreshRequired(),
    )
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    merge(this.contactsStateService.getRefreshAll(), this.contactsService.getRefreshRequired())
      .pipe(takeUntil(this.componentNotDestroyed))
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('filters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Contact[]; count: number }> {
    return this.contactsService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource },
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(contact: Contact) {
    this.doubleClickContact.emit(contact);
  }

  handleClickItem(contact: Contact) {
    this.clickContact.emit(contact);
  }

  compareItems(item1: object, item2: object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
