import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'last',
  standalone: false,
})
export class LastPipe implements PipeTransform {
  transform(array?: unknown[]): unknown {
    return array?.[array?.length - 1];
  }
}
