// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable, timer } from 'rxjs';
import { map, switchMap, takeWhile, last } from 'rxjs/operators';

// Types
import { AsyncTask } from '../types/background-job';

// Env
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class BackgroundJobsService {
  constructor(private http: HttpClient) {}

  get(task: AsyncTask): Observable<AsyncTask> {
    return this.http
      .get<{ success: boolean; asyncTask: AsyncTask }>(`${environment.baseUrl}/api/async-tasks/${task.type}/${task.id}`)
      .pipe(map(({ asyncTask }) => asyncTask));
  }

  await(task: AsyncTask): Observable<boolean> {
    return timer(0, 1000).pipe(
      switchMap(() => this.get(task)),
      map((asyncTask) => {
        if (asyncTask.status === 'error') {
          throw new Error(`Async task failed: ${asyncTask.errorMessage || 'unknown'}`);
        }
        return asyncTask.status === 'completed';
      }),
      takeWhile((done) => !done, true),
      last(),
    );
  }

  undo(task: AsyncTask): Observable<{ asyncTaks?: AsyncTask; data?: any }> {
    return this.http
      .post<{
        success: boolean;
        asyncTask?: AsyncTask;
        data: any;
      }>(`${environment.baseUrl}/api/async-tasks/${task.type}/${task.id}/undo`, {})
      .pipe(map(({ asyncTask, data }) => ({ asyncTask, data })));
  }

  force(task: AsyncTask): Observable<{ asyncTaks?: AsyncTask; data?: any }> {
    return this.http
      .post<{
        success: boolean;
        asyncTask?: AsyncTask;
        data: any;
      }>(`${environment.baseUrl}/api/async-tasks/${task.type}/${task.id}/force`, {})
      .pipe(map(({ asyncTask, data }) => ({ asyncTask, data })));
  }
}
