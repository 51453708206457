// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown-label',
  templateUrl: './dropdown-label.component.html',
  styleUrls: ['./dropdown-label.component.less'],
  standalone: false,
})
export class DropdownLabelComponent {
  // Inputs
  @Input() collapsed = true;
  @Input() appearance: 'default' | 'amethyst' = 'default';
}
