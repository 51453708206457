// Common
import { ComponentRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { CalendarContextMenuComponent } from '../components/context-menus/calendar-context-menu/calendar-context-menu.component';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appCalendarContextMenu]',
  standalone: false,
})
export class CalendarContextMenuDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() appCalendarContextMenu: Calendar;
  @Input() appCalendarContextMenuDisabled = false;
  @Input() appCalendarContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appCalendarContextMenuOpened = this.contextMenuOpened;
  @Output() appCalendarContextMenuOpenCalendar = new EventEmitter();

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverDisabled = this.appCalendarContextMenuDisabled;

    if (this.appCalendarContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return CalendarContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<CalendarContextMenuComponent>) {
    componentRef.instance.calendar = this.appCalendarContextMenu;
    componentRef.instance.open.pipe(takeUntil(this.alive)).subscribe(() => {
      this.appCalendarContextMenuOpenCalendar.emit();
    });
  }
}
