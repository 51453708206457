import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Settings {
  formBuilder = injector.get(FormBuilder);

  calendarsPostponeNotificationsEnabled?: boolean;
  eventsPostponeNotificationsEnabled?: boolean;
  notificationsEnabled?: boolean;
  remindersEnabled?: boolean;
  remindersTimeBeforeMinutes?: number;

  constructor(data: Partial<Settings> = {}) {
    this.calendarsPostponeNotificationsEnabled = data.calendarsPostponeNotificationsEnabled ?? false;
    this.eventsPostponeNotificationsEnabled = data.eventsPostponeNotificationsEnabled ?? false;
    this.notificationsEnabled = data.notificationsEnabled;
    this.remindersEnabled = data.remindersEnabled;
    this.remindersTimeBeforeMinutes = data.remindersTimeBeforeMinutes || 0;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      calendarsPostponeNotificationsEnabled: formValues.calendarsPostponeNotificationsEnabled,
      eventsPostponeNotificationsEnabled: formValues.eventsPostponeNotificationsEnabled,
      notificationsEnabled: formValues.notificationsEnabled,
      remindersEnabled: formValues.remindersEnabled,
      remindersTimeBeforeMinutes: formValues.remindersTimeBeforeMinutes,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      calendarsPostponeNotificationsEnabled: [this.calendarsPostponeNotificationsEnabled],
      eventsPostponeNotificationsEnabled: [this.eventsPostponeNotificationsEnabled],
      notificationsEnabled: [this.notificationsEnabled],
      remindersEnabled: [this.remindersEnabled],
      remindersTimeBeforeMinutes: [this.remindersTimeBeforeMinutes],
    });
  }

  public asPayloadJSON() {
    return {
      calendarsPostponeNotificationsEnabled: this.calendarsPostponeNotificationsEnabled,
      eventsPostponeNotificationsEnabled: this.eventsPostponeNotificationsEnabled,
      notificationsEnabled: this.notificationsEnabled,
      remindersEnabled: this.remindersEnabled,
      remindersTimeBeforeMinutes: this.remindersTimeBeforeMinutes,
    };
  }
}
