// Common
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';

// Services
import { EditorService } from '@modules/form-controls/services/editor.service';

// Types
import { EditorCommand } from '@modules/form-controls/types/editor-command';
import { Icon } from '@modules/icons/types/icons';

// RX
import { Subject, takeUntil } from 'rxjs';

type ToolbarType = 'simple'; // 'comment' | 'mail-compose'

@Component({
  selector: 'stch-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.less'],
  standalone: false,
})
export class EditorToolbarComponent implements OnInit, OnDestroy {
  public EditorCommand = EditorCommand;
  public state: { [format: string]: boolean } = {};
  public buttonSets: {
    [k in ToolbarType]: { tooltip: string; icon: Icon; state?: string; command: EditorCommand }[][];
  } = {
    simple: [
      [
        { tooltip: 'Bold', icon: 'sapphire-editor-bold', state: 'bold', command: EditorCommand.Bold },
        { tooltip: 'Italic', icon: 'sapphire-editor-italic', state: 'italic', command: EditorCommand.Italic },
        {
          tooltip: 'Underline',
          icon: 'sapphire-editor-underline',
          state: 'underline',
          command: EditorCommand.Underline,
        },
        {
          tooltip: 'Strikethrough',
          icon: 'sapphire-editor-strikethrough',
          state: 'strikethrough',
          command: EditorCommand.Strikethrough,
        },
      ],
      [
        {
          tooltip: 'Align Left',
          icon: 'sapphire-editor-align-left',
          state: 'alignleft',
          command: EditorCommand.JustifyLeft,
        },
        {
          tooltip: 'Align Center',
          icon: 'sapphire-editor-align-center',
          state: 'aligncenter',
          command: EditorCommand.JustifyCenter,
        },
        {
          tooltip: 'Align Right',
          icon: 'sapphire-editor-align-right',
          state: 'alignright',
          command: EditorCommand.JustifyRight,
        },
      ],
      [
        { tooltip: 'Ordered List', icon: 'sapphire-editor-ordered-list', command: EditorCommand.InsertOrderedList },
        {
          tooltip: 'Unordered List',
          icon: 'sapphire-editor-unordered-list',
          command: EditorCommand.InsertUnorderedList,
        },
      ],
    ],
  };

  private alive = new Subject<void>();

  @Input() toolbarType: ToolbarType = 'simple';

  constructor(
    @Optional() private editorService: EditorService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.editorService
      ?.getFormat()
      .pipe(takeUntil(this.alive))
      .subscribe((state) => {
        this.state = state;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  execCommand(command: EditorCommand) {
    this.editorService?.execCommand?.(command);
  }
}
