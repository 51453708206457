// Common
import { Injectable } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { MailMessage } from '../../mail/types/mail-message';
import { PopupSize } from './../types/popup-size';

@Injectable()
export class PopupService {
  private defaultSize: PopupSize = {
    width: 1000,
    height: 500,
  };

  private frameParams(size: PopupSize): string {
    const left = Number(screen.width / 2 - size.width / 2);
    const top = Number(screen.height / 2 - size.height / 2);
    return `width=${size.width},height=${size.height},left=${left},top=${top}`;
  }

  openFullForm(stitchItem: Stitch, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    window.open(
      `compact/${stitchItem.getStitchType()}/${stitchItem.id}`,
      stitchItem.getStitchType().replace('-', ' '),
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`,
    );
  }

  compose(message: MailMessage, size: PopupSize = this.defaultSize) {
    const frame = this.frameParams(size);
    const popup = window.open(
      'compact/mail/compose',
      'Compose',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,${frame}`,
    );
    popup.addEventListener('load', this.receiveMessage.bind(this, popup, message), false);
  }

  receiveMessage(popupWindow: Window, message?: MailMessage, event?: Event) {
    const eventTarget = event.target as Window;
    const currentOrigin = `${window.location.protocol}//${window.location.host}`;
    if (eventTarget.location.origin !== currentOrigin) {
      return;
    }
    popupWindow.postMessage({ mailMessage: message }, currentOrigin);
  }
}
