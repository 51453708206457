import { publish } from 'rxjs/operators';
import { ConnectableObservable, Observable } from 'rxjs';

export function warmUpObservable(_target: object, _key: string | symbol, descriptor: PropertyDescriptor) {
  const origin = descriptor.value;

  descriptor.value = function <T>(...args: any[]): Observable<T> {
    const hotObservable = origin.apply(this, args).pipe(publish()) as ConnectableObservable<T>;
    hotObservable.connect();
    return hotObservable as Observable<T>;
  };

  return descriptor;
}
