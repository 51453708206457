<pdf-viewer
  *ngIf="file"
  class="pdf"
  [page]="currentPage + 1"
  [src]="file"
  [render-text]="true"
  [original-size]="false"
  zoom-scale="page-height"
  [autoresize]="true"
  style="display: block;"
  [show-all]="false"
  [zoom]="zoom"
  [rotation]="rotation"
  (after-load-complete)="handlePDFAfterLoad($event)"
/>

<app-pagination
  class="pagination"
  [pages]="pagesCount"
  [currentPage]="currentPage"
  (currentPageChange)="setCurrentPage($event)"
/>

<div class="actions">
  <mat-icon
    class="actions-item"
    svgIcon="pdf-fullscreen"
    (click)="toggleFullscreen()"
  />

  <mat-icon
    class="actions-item"
    svgIcon="pdf-zoom-out"
    (click)="zoomOut()"
  />

  <mat-icon
    class="actions-item"
    svgIcon="pdf-zoom-in"
    (click)="zoomIn()"
  />

  <mat-icon
    class="actions-item"
    svgIcon="pdf-rotate"
    (click)="rotate()"
  />
</div>
