// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Tag } from '@modules/tags/types/tag';
import { ManageListState } from '@modules/tags/types/manage-list-state';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';

// Services
import { TagsService } from '@modules/tags/services/tags.service';

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.less'],
  standalone: false,
})
export class AddTagsComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() stitchItems: Stitch[];
  @Input() control: UntypedFormControl;

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Tag[]>();

  // Public
  public inputControl = new UntypedFormControl('');
  public canAdd = false;
  public suggestionsReceived = false;
  public state: ManageListState = {
    sort: {
      by: 'name',
      order: 'asc',
    },
    filters: {
      createdFrom: null,
      createdTo: null,
    },
  };
  public tags: Tag[] = [];
  public suggestions: AutocompleteFactory<Tag>;
  public focused = false;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(private tagsService: TagsService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestions = (query?, _values?, config?: { limit: number }) => {
      if (!query || query.trim() === '') {
        return of([]);
      }

      if (query.trim().split(',').length > 1) {
        this.suggestionsReceived = true;
        return of([]);
      }

      this.suggestionsReceived = false;

      return this.tagsService.search({ limit: config?.limit || 5, query }).pipe(
        tap(() => (this.suggestionsReceived = true)),
        map(({ items: tags }) =>
          tags.map((tag) => ({
            title: tag.name,
            value: tag.name,
            source: tag,
          })),
        ),
      );
    };

    this.inputControl.valueChanges.pipe(takeUntil(this.alive)).subscribe((value: string) => {
      const newTags = (value || '')
        .split(',')
        .map((item) => item.trim())
        .filter((item) => !!item);

      const tagsNames = this.tags.map(({ name }) => name);

      this.canAdd = newTags.some((item) => !tagsNames.includes(item));
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addTag() {
    if (!this.canAdd) {
      return;
    }

    const names: string[] = this.inputControl.value
      .split(',')
      .map((item) => item.trim())
      .filter((item) => !!item && !this.tags.some(({ name }) => name === item));

    if (!names.length) {
      return;
    }

    this.tagsService
      .search({ names })
      .pipe(take(1), takeUntil(this.alive))
      .subscribe(({ items: receivedTags }) => {
        const receivedTagsNames = receivedTags.map(({ name }) => name);
        const newTags = names.reduce(
          (memo, name) => (receivedTagsNames.includes(name) ? memo : [...memo, new Tag({ name, added: true })]),
          [],
        );

        this.tags = [...this.tags, ...receivedTags.map((tag) => new Tag({ ...tag, added: true })), ...newTags];
      });

    this.inputControl.setValue('');
  }

  handlePin(tag: Tag) {
    this.tags = this.tags.map((item) =>
      item.name === tag.name ? new Tag({ ...tag, pinned: !tag.pinned, changed: true }) : item,
    );
  }

  handleDelete(tag: Tag) {
    if (tag.added) {
      this.tags = this.tags.filter((item) => item.name !== tag.name);
    } else {
      this.tags = this.tags.map((item) => (item.name === tag.name ? new Tag({ ...tag, deleted: true }) : item));
    }
  }

  handleCancel() {
    this.close.emit();
  }

  handleSelect(option: DropdownSelectItem<Tag>) {
    this.tags = [...this.tags, new Tag({ ...option.source, added: true })];
    this.inputControl.setValue('');
  }

  handleSave() {
    if (this.save.observers.length > 0) {
      this.save.emit(this.tags);
      this.close.emit();
    } else {
      const newTags = this.tags.filter((tag) => (tag.added && tag.id === undefined) || tag.id === null);
      const changedTags = this.tags.filter((tag) => tag.changed && tag.id !== undefined && tag.id !== null);
      const linkedTags = this.tags.filter((tag) => tag.added);
      const unlinkedTags = this.tags.filter((tag) => tag.deleted);

      combineLatest([
        newTags.length ? this.tagsService.createBulk(newTags) : of(true),
        changedTags.length ? this.tagsService.updateBulk(changedTags, { emit: false, toast: false }) : of(true),
      ])
        .pipe(
          switchMap(([created, updated]) =>
            created && updated && this.stitchItems?.length
              ? combineLatest([
                  linkedTags.length ? this.tagsService.link(linkedTags, this.stitchItems, false, false) : of(true),
                  unlinkedTags.length
                    ? this.tagsService.unlink(unlinkedTags, this.stitchItems, false, false)
                    : of(true),
                ])
              : of(null),
          ),
          takeUntil(this.alive),
        )
        .subscribe(() => {
          this.tagsService.forceRefresh();
          this.close.emit();
        });
    }
  }
}
