// Common
import { Component, Injector } from '@angular/core';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { Like } from '@modules/common/types/like';

// Types
import { File } from '@modules/files/types/file';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { Folder } from '@modules/files/types/folder';
import { Application } from '@modules/common/types/application';

// Services
import { FilesAppStateService } from '@modules/files/services/state.service';
import { FilesService } from '@modules/files/services/files.service';
import { FoldersService } from '@modules/files/services/folders.service';

// Components
import { BaseSidebarContextMenuComponent } from '@modules/common/components/base-sidebar-context-menu/base-sidebar-context-menu.component';

@Component({
  selector: 'app-sidebar-context-menu',
  templateUrl: './sidebar-context-menu.component.html',
  styleUrls: [
    '../../../common/components/base-sidebar-context-menu/base-sidebar-context-menu.component.less',
    './sidebar-context-menu.component.less',
  ],
  standalone: false,
})
export class SidebarContextMenuComponent extends BaseSidebarContextMenuComponent<File, Folder, VirtualFolder> {
  advancedSearchStateKey = Application.files;

  constructor(
    injector: Injector,
    filesService: FilesService,
    filesStateService: FilesAppStateService,
    foldersService: FoldersService,
  ) {
    super(injector, filesService, foldersService, filesStateService);
  }

  protected getCommonParams(): Like<File | Folder> {
    let params: Like<File | Folder> = {};

    switch (this.sidebarFilterKey) {
      case 'all_files':
      case 'all_folders':
      case 'upcoming':
      case 'recent':
        break;
      case 'snoozed':
      case 'archived':
      case 'deleted':
      case 'flagged':
      case 'followed':
        params = super.getCommonParams();
        break;
      default:
        checkExhaustiveness(this.sidebarFilterKey);
    }

    return params;
  }

  protected stitchItemFactory(params: Partial<File>): File {
    return new File({ ...params, folderId: this.selectedContainerStitchItemId });
  }

  protected containerStitchItemFactory(params: Partial<Folder>): Folder {
    return new Folder({ ...params, parentId: this.selectedContainerStitchItemId });
  }
}
