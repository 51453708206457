// Common
import { Injectable } from '@angular/core';
import { isNil } from '@modules/common/utils/base';

// Rx
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, tap, takeUntil, take, filter, throttleTime } from 'rxjs/operators';

// Types
import { Task } from '../types/task';
import { Row } from '../types/row';
import { Column } from '../types/column';
import { Project } from '../types/project';
import { LSBoolean, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';
import { NestedDataItem } from '@modules/drag-n-drop/types/nested-item';
import { SignalEnum } from '@modules/common/types/signal';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { RowsService } from './rows.service';
import { ColumnsService } from './columns.service';
import { SignalsService } from '@modules/common/services/signals-service/signals.service';

@Injectable()
export class BoardService {
  private originalRows: Row[] = [];
  private rows = new BehaviorSubject<Row[]>([]);
  private originalColumns: Column[] = [];
  private columns = new BehaviorSubject<Column[]>([]);
  private originalTasks: Task[] = [];
  private tasks = new BehaviorSubject<Task[]>([]);
  private project: Project;
  private alive = new Subject<void>();
  private reload = new Subject<void>();

  @LSBoolean({ lsKey: 'board-row.collapsed' }) rowCollapsed: LocalStorageItem<boolean>;
  @LSBoolean({ lsKey: 'board-column.collapsed' }) columnCollapsed: LocalStorageItem<boolean>;

  constructor(
    private columnsService: ColumnsService,
    private rowsService: RowsService,
    private tasksService: TasksService,
    private signalsService: SignalsService,
  ) {
    this.rows.pipe(takeUntil(this.alive)).subscribe((rows) => {
      if (rows.some(({ id }) => id === 'temp')) {
        return;
      }

      this.syncOrder(rows, this.originalRows, this.rowsService);
      this.originalRows = rows;
    });

    this.columns.pipe(takeUntil(this.alive)).subscribe((columns) => {
      if (columns.some(({ id }) => id === 'temp')) {
        return;
      }

      this.syncOrder(columns, this.originalColumns, this.columnsService);
      this.originalColumns = columns;
    });

    this.tasks.pipe(takeUntil(this.alive)).subscribe((tasks) => {
      if (tasks.some(({ id }) => id === 'temp')) {
        return;
      }

      this.syncTasks(tasks);
      this.originalTasks = tasks;
    });

    this.reload
      .pipe(
        filter(() => !!this.project?.id),
        switchMap(() =>
          combineLatest([
            this.rowsService.findAll({ projectId: this.project.id }),
            this.columnsService.findAll({ projectId: this.project.id }),
          ]),
        ),
        tap(([rows, columns]) => {
          this.originalRows = rows;
          this.originalColumns = columns;

          this.rows.next(
            rows.sort((a, b) => a.position - b.position).map((row, index) => new Row({ ...row, position: index })),
          );

          this.columns.next(
            columns
              .sort((a, b) => a.position - b.position)
              .map((row, index) => new Column({ ...row, position: index })),
          );
        }),
        switchMap(() => this.tasksService.findAll({ containersIds: [this.project.id] })),
        takeUntil(this.alive),
      )
      .subscribe((tasks) => {
        const rowsIds = this.originalRows.map(({ id }) => id);
        const columnsIds = this.originalColumns.map(({ id }) => id);
        const uncategorizedColumnId = this.originalColumns.find(({ uncategorized }) => uncategorized).id;

        this.originalTasks = tasks.map(
          (task) =>
            new Task({
              ...task,
              columnId:
                task.columnId === null || !columnsIds.includes(task.columnId) ? uncategorizedColumnId : task.columnId,
              rowId: rowsIds.includes(task.rowId) ? task.rowId : null,
            }),
        );

        this.tasks.next(this.originalTasks);
      });

    this.signalsService
      .getSignal(SignalEnum.RELOAD_PROJECT_BOARD)
      .pipe(
        filter(({ containerId }) => containerId === this.project?.id),
        throttleTime(300),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        // TODO - don't reload for signal initiator?
        this.reload.next();
      });
  }

  setProject(project: Project) {
    this.project = project;
    this.reload.next();
  }

  getRows() {
    return this.rows.asObservable();
  }

  getColumns() {
    return this.columns.asObservable();
  }

  private getSubtasks(parents: Task[], allTasks: Task[]) {
    if (!parents.length) {
      return [];
    }

    const parentsIds = parents.map(({ id }) => id);
    const children = allTasks.filter(({ parentId }) => parentsIds.includes(parentId));

    return [...children, ...this.getSubtasks(children, allTasks)];
  }

  getTasks(column: Column, row: Row) {
    return this.tasks.pipe(
      map((items) => {
        const tasks = items.filter(
          (task) => task.parentId === null && task.rowId === (row?.id || null) && task.columnId == column.id,
        );

        return [...tasks, ...this.getSubtasks(tasks, items)];
      }),
    );
  }

  getTasksCount(column: Column) {
    return this.tasks.pipe(
      map((items) => {
        const tasks = items.filter((task) => task.parentId === null && task.columnId == column.id);

        return tasks.length + this.getSubtasks(tasks, items).length;
      }),
    );
  }

  addRow(after = true, position?: number) {
    const rows = this.rows.getValue().filter((row) => row.id !== 'temp');

    const finalPosition = isNil(position) ? rows.length - 1 : position;

    const newRows = [
      new Row({
        id: 'temp',
        projectId: this.project.id,
        position: after ? finalPosition + 0.5 : finalPosition - 0.5,
      }),
      ...rows,
    ]
      .sort((a, b) => a.position - b.position)
      .map((row, index) => new Row({ ...row, position: index }));

    this.rows.next(newRows);
  }

  addColumn(after = true, position?: number) {
    const columns = this.columns.getValue().filter((column) => column.id !== 'temp');

    const finalPosition = isNil(position) ? columns.length - 1 : position;

    const newColumns = [
      new Column({
        id: 'temp',
        projectId: this.project.id,
        position: after ? finalPosition + 0.5 : finalPosition - 0.5,
      }),
      ...columns,
    ]
      .sort((a, b) => a.position - b.position)
      .map((column, index) => new Column({ ...column, position: index }));

    this.columns.next(newColumns);
  }

  addTask(task: Task) {
    this.tasks.next([task, ...this.tasks.getValue()]);
  }

  updateRow(row: Row) {
    if (row.id === 'temp' && row.title.trim() === '') {
      return;
    }

    this.rows.next(this.rows.getValue().map((i) => (i.id === row.id ? row : i)));

    this.rowsService
      .upsert(row)
      .pipe(takeUntil(this.alive))
      .subscribe((upsertedRow) => {
        this.rows.next(this.rows.getValue().map((i) => (i.id === row.id || i.id === 'temp' ? upsertedRow : i)));
      });
  }

  updateColumn(column: Column) {
    if (column.id === 'temp' && column.title.trim() === '') {
      return;
    }

    this.columns.next(this.columns.getValue().map((i) => (i.id === column.id ? column : i)));

    this.columnsService
      .upsert(column)
      .pipe(takeUntil(this.alive))
      .subscribe((upsertedColumn) => {
        this.columns.next(
          this.columns.getValue().map((i) => (i.id === column.id || i.id === 'temp' ? upsertedColumn : i)),
        );
      });
  }

  public moveRow(row: Row, direction: 1 | -1) {
    const rows = this.rows.getValue();

    const newRows = [...rows]
      .map((i) => new Row({ ...i, position: i.id === row.id ? i.position + direction * 1.5 : i.position }))
      .sort((a, b) => a.position - b.position)
      .map((i, index) => new Row({ ...i, position: index }));

    this.rows.next(newRows);
  }

  public moveColumn(column: Column, direction: 1 | -1) {
    const columns = this.columns.getValue();

    const newColumns = [...columns]
      .map((i) => new Column({ ...i, position: i.id === column.id ? i.position + direction * 1.5 : i.position }))
      .sort((a, b) => a.position - b.position)
      .map((i, index) => new Column({ ...i, position: index }));

    this.columns.next(newColumns);
  }

  public removeRow(row: Row) {
    this.rowsService.deletePermanently([row.id]).pipe(take(1), takeUntil(this.alive)).subscribe();

    const newRows = this.rows
      .getValue()
      .filter(({ id }) => id !== row.id)
      .sort((a, b) => a.position - b.position)
      .map((row, index) => new Row({ ...row, position: index }));

    this.rows.next(newRows);

    // tasks should be moved to previous row?
  }

  public removeColumn(column: Column) {
    this.columnsService.deletePermanently([column.id]).pipe(take(1), takeUntil(this.alive)).subscribe();

    const newColumns = this.columns
      .getValue()
      .filter(({ id }) => id !== column.id)
      .sort((a, b) => a.position - b.position)
      .map((column, index) => new Column({ ...column, position: index }));

    this.columns.next(newColumns);

    // all tasks should be moved to previous row?
  }

  setColumnCollapsed(columnId: string, value: boolean): void {
    this.columnCollapsed.set(value, columnId);
  }

  getColumnCollapsed(key: string): Observable<boolean> {
    return this.columnCollapsed.get(key);
  }

  setRowCollapsed(columnId: string, value: boolean): void {
    this.rowCollapsed.set(value, columnId);
  }

  getRowCollapsed(key: string): Observable<boolean> {
    return this.rowCollapsed.get(key);
  }

  detach() {
    this.alive.next();
    this.alive.complete();
  }

  private syncOrder(items: (Row | Column)[], originalItems: (Row | Column)[], service: RowsService | ColumnsService) {
    const itemsMap = new Map<string, Row | Column>();

    for (const item of originalItems) {
      itemsMap.set(item.id, item);
    }

    const changedItems = items.filter((item) => {
      const mapItem = itemsMap.get(item.id);
      return mapItem && item.position !== mapItem.position;
    });

    if (!changedItems.length) {
      return;
    }

    service
      .reorder(changedItems.map(({ id, position }) => ({ id, position })))
      .pipe(take(1), takeUntil(this.alive))
      .subscribe();
  }

  private syncTasks(items: Task[]) {
    const itemsMap = new Map<string, Task>();

    for (const item of this.originalTasks) {
      itemsMap.set(item.id, item);
    }

    const changedItems = items.filter((item) => {
      const mapItem = itemsMap.get(item.id);
      return (
        !mapItem ||
        item.boardPosition !== mapItem.boardPosition ||
        item.projectId !== mapItem.projectId ||
        item.rowId !== mapItem.rowId ||
        item.columnId !== mapItem.columnId ||
        item.parentId !== mapItem.parentId
      );
    });

    if (!changedItems.length) {
      return;
    }

    this.tasksService.reorderBoard(changedItems).pipe(take(1), takeUntil(this.alive)).subscribe();
  }

  updateTasks(changes: NestedDataItem<Task>[], rowId: string, columnId: string) {
    const changesIds = changes.map(({ id }) => id);

    const tasks = this.tasks.getValue();

    const newTasks = changes.map(
      (i) =>
        new Task({
          ...i.data,
          boardPosition: i.position,
          parentId: i.parentId,
          rowId: rowId || null,
          columnId,
          projectId: this.project.id,
        }),
    );

    this.tasks.next([...tasks.filter(({ id }) => !changesIds.includes(id)), ...newTasks]);
  }
}
