import { Component, inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { Tab } from '@modules/common/types/tab';
import { ModalService } from '@modules/modal/services/modal.service';
import { NotebookListStateService } from '@modules/notes/services/notebook-list-state.service';
import { NotesListStateService } from '@modules/notes/services/notes-list-state.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { ListState } from '@modules/notes/types/list-state';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateKey } from '@modules/settings/types/state-key';
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notes-list-container',
  templateUrl: './notes-list-container.component.html',
  styleUrls: ['./notes-list-container.component.less'],
  standalone: false,
  providers: [
    NotesListStateService.providers({
      stateKey: StateKey.notesListState,
      withAdvancedSearch: true,
    }),
    NotebookListStateService.providers({
      stateKey: StateKey.notebooksListState,
      withAdvancedSearch: true,
    }),
  ],
})
export class NotesListContainerComponent implements OnInit, OnDestroy {
  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder;
  public quickNotebook: Notebook;
  public quickNote: Note;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Notebooks', value: 'notebooks' },
    { title: 'Notes', value: 'notes' },
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'notes';
  public notebooksFilters: NotebooksFilters;
  public notesFilters: NotesFilters;
  public debug: 'score' = null;

  private readonly notesListStateService = inject(NotesListStateService, { self: true });
  private readonly notebookListStateService = inject(NotebookListStateService, { self: true });

  private alive = new Subject<void>();

  constructor(
    private splitViewService: SplitViewService,
    private stateService: NotesAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {
    combineLatest([
      this.notebookListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.notebooksFilters = new NotebooksFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.notesListStateService.value(),
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder]) => {
        this.notesFilters = new NotesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  public ngOnInit() {
    this.splitViewService
      .getMinimized('notesList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService
      .getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([this.searchService.getState(), this.stateService.getVirtualFolder()])
      .pipe(takeUntil(this.alive))
      .subscribe(([state, folder]) => {
        this.sidebarFilter = folder;
        this.quickNote = Note.fromAdvancedState(state, folder);
        this.quickNotebook = Notebook.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewNote(note = new Note()) {
    this.stateService.setMainView(note);
  }

  handleNewNotebook(notebook = new Notebook()) {
    this.stateService.setMainView(notebook);
  }

  handleDoubleClickNote(note: Note) {
    this.modalService.openFullForm(note, this.injector);
  }

  handleClickNote(note: Note) {
    this.stateService.setMainView(note);
  }

  handleDoubleClickNotebook(notebook: Notebook) {
    this.modalService.openFullForm(notebook, this.injector);
  }

  handleClickNotebook(notebook: Notebook) {
    this.stateService.setMainView(notebook);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
