<div
  class="container"
  [class.changed]="!isDefault"
  [ngClass]="appearance"
>
  <mat-icon
    class="icon"
    [class.changed]="!isDefault"
    [svgIcon]="svgIcon"
  />

  <mat-icon
    *ngIf="!isDefault"
    class="close-icon"
    [svgIcon]="appearance === 'sapphire' ? 'sapphire-close-small' : 'close-small'"
    (click)="handleReset($event)"
  />
</div>
