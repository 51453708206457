import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }] });

export class Settings {
  formBuilder = injector.get(FormBuilder);

  filesPostponeNotificationsEnabled?: boolean;
  foldersPostponeNotificationsEnabled?: boolean;
  notificationsEnabled?: boolean;

  constructor(data: Partial<Settings> = {}) {
    this.filesPostponeNotificationsEnabled = data.filesPostponeNotificationsEnabled;
    this.foldersPostponeNotificationsEnabled = data.foldersPostponeNotificationsEnabled;
    this.notificationsEnabled = data.notificationsEnabled;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      filesPostponeNotificationsEnabled: formValues.filesPostponeNotificationsEnabled,
      foldersPostponeNotificationsEnabled: formValues.foldersPostponeNotificationsEnabled,
      notificationsEnabled: formValues.notificationsEnabled,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      filesPostponeNotificationsEnabled: [this.filesPostponeNotificationsEnabled],
      foldersPostponeNotificationsEnabled: [this.foldersPostponeNotificationsEnabled],
      notificationsEnabled: [this.notificationsEnabled],
    });
  }

  public asPayloadJSON() {
    return {
      filesPostponeNotificationsEnabled: this.filesPostponeNotificationsEnabled,
      foldersPostponeNotificationsEnabled: this.foldersPostponeNotificationsEnabled,
      notificationsEnabled: this.notificationsEnabled,
    };
  }
}
