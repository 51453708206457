// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Types
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';

@Component({
  selector: 'app-events-list-context-menu',
  templateUrl: './events-context-menu.component.html',
  styleUrls: ['./events-context-menu.component.less'],
  standalone: false,
})
export class EventsContextMenuComponent {
  @Input() filterKey: VirtualFolder;

  @Output() change = new EventEmitter<VirtualFolder>();

  /**
   * Actions
   */

  selectFilter(filter: VirtualFolder) {
    this.change.emit(filter);
  }
}
