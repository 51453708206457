import { Inject, Injectable, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SPACE_ID } from '../injection-tokens/space-id.injection-token';

@Injectable()
export class SpacesHttpInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.spaceId?.value) {
      let params = request.params ? request.params : new HttpParams();
      params = params.set('space_id', this.spaceId.value);
      return next.handle(request.clone({ params }));
    }

    return next.handle(request);
  }
}
