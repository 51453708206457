// Common
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Utils
import { groupByIds } from '@modules/common/utils/stitch';

// RxJS
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseService } from '@modules/common/services/base.service';

// Types
import { ConnectionsFilters } from '../types/connections-filters';
import { Connection } from '../types/connection';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { Like } from '@modules/common/types/like';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { Stitch } from '@modules/common/types/stitch';

@Injectable()
export class ConnectionsService extends BaseService {
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {
    super();
    this.handleObserverError = this.handleObserverError.bind(this);
  }

  /**
   * Static methods
   */

  handleObserverError(error: HttpErrorResponse) {
    if (error?.error?.error) {
      this.toasterService.show({
        text: error.error.error,
        icon: 'pellets',
      });
    }

    console.error(error);
    return throwError(error);
  }

  /**
   * Methods
   */

  search(filters: Like<ConnectionsFilters>): Observable<BaseSearchResponse<Connection>> {
    return this.http
      .get<{
        connections: Connection[];
        count: number;
      }>(`${environment.baseUrl}/api/connections`, { params: new ConnectionsFilters(filters).format() })
      .pipe(
        map(({ connections, count }) => ({
          items: connections.map((connection) => new Connection(connection)),
          count,
        })),
        catchError((error: Error) => {
          this.toasterService.show({ text: error.message });

          return of({ items: [], count: 0 });
        }),
      );
  }

  getNeighbors(filters: Like<ConnectionsFilters>) {
    return this.http
      .get<{
        connections: Connection[];
        count: number;
      }>(`${environment.baseUrl}/api/connections/neighbors`, { params: new ConnectionsFilters(filters).format() })
      .pipe(
        map(({ connections, count }) => ({
          connections: connections.map((connection) => new Connection(connection)),
          count,
        })),
        catchError((error: Error) => {
          this.toasterService.show({ text: error.message });
          return of({ connections: [], count: 0 });
        }),
      );
  }

  @warmUpObservable
  createBulk(connections: Connection[], emitUpdate = true, withToast = true) {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/connections`, {
        connections: connections.map((connection) => ({
          name: connection.name,
        })),
      })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toasterService.show({
              text: success ? 'New Connection(s) were added.' : 'Something went wrong while adding connections',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  create(connection: Connection, { emit = true, displayToast = true }: FeedbackConfig) {
    return this.createBulk([connection], emit, displayToast).pipe(map(() => null));
  }

  update(connections: Connection[], emit = true, withToast = true) {
    return this.http
      .put<{ success: boolean }>(`${environment.baseUrl}/api/connections`, {
        connections: connections.map((c) => ({
          id: c.id,
          name: c.name,
        })),
      })
      .pipe(
        tap(() => {
          if (withToast) {
            this.toasterService.show({
              text: 'Connection(s) were updated.',
              icon: 'pellets',
            });
          }

          if (emit) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  link(connections: Connection[], items: Stitch[], emitUpdate = true, withToast = true): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(`${environment.baseUrl}/api/connections/link`, {
        connections: connections.map(({ name }) => name),
        items: groupByIds(items),
      })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toasterService.show({
              text: success ? 'Connection(s) linked.' : 'Something went wrong while removing connections',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  unlink(connections: Connection[], items: Stitch[], emitUpdate = true, withToast = true): Observable<boolean> {
    return this.http
      .request<{ success: boolean }>('DELETE', `${environment.baseUrl}/api/connections/link`, {
        body: {
          connections: connections.map(({ name }) => name),
          items: groupByIds(items),
        },
      })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toasterService.show({
              text: success ? 'Connection(s) unlinked.' : 'Something went wrong while unlinking connections',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }

  @warmUpObservable
  upsert(connections: Connection[], items: Stitch[], emitUpdate = true, withToast = true) {
    return this.createBulk(connections, true, false).pipe(
      switchMap(() => this.link(connections, items, emitUpdate, withToast)),
    );
  }

  @warmUpObservable
  deletePermanently(filters: Like<ConnectionsFilters>, _customToastMessage?, emitUpdate = true, withToast = true) {
    return this.http
      .delete<{
        success: boolean;
      }>(`${environment.baseUrl}/api/connections`, { params: new ConnectionsFilters(filters).format() })
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toasterService.show({
              text: success ? 'Connection(s) were removed.' : 'Something went wrong while removing connections',
              icon: 'pellets',
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError((error) => this.handleObserverError(error)),
      );
  }
}
