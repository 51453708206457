<app-list-load-error [error]="loadingError" (retry)="resetItems()" />

@if (items?.length === 0 && !loading && !loadingError) {
  <app-no-content-stub imageSrc="assets/placeholders/placeholder-tasks.svg">
    You have no folders
  </app-no-content-stub>
}

<div
  class="overflow-y-auto grow basis-0"
  appSelectableList
  [appSelectableListReset]="resetSelected">

  @if (loading && (!items || items.length === 0)) {
    <app-loading-indicator>Loading folders...</app-loading-indicator>
  }

  @for (item of items; track item.data.id; let index = $index) {
    @if (item.showSectionHeader && item.section) {
      <app-list-items-separator [value]="item.section"/>
    }

    <app-message-folder
      [item]="item.data"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [position]="index"
      [debug]="debug"
      (open)="openFolder.emit(item.data)"
      (openMessage)="openMessage.emit($event)"
      (appClick)="handleClickItem(item.data)"
      (appDblClick)="handleDoubleClickItem(item.data)"
      [appSelectable]="item.data"
      [appSelectablePosition]="index"
    />
  }
</div>

<app-pagination2
    [(page)]="page"
    [pageSize]="pageSize"
    (refresh)="resetItems()"
    (pageChange)="loadPage($event)"
    [totalItems]="totalCount"
    class="border-t border-border"></app-pagination2>