// Common
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-settings-calendar-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.less'],
  standalone: false,
})
export class GeneralComponent {
  // Public
  public form = new FormGroup({
    eventDuration: new FormControl<number>(60),
    guestPermissions: new FormControl<string[]>(['edit', 'list']),
    reminder: new FormControl<number>(15),
    birthdayCalendarEnabled: new FormControl<boolean>(true),
  });
  public eventDurationOptions: DropdownSelectItem<unknown>[] = [
    { title: '15 minutes', value: 15 },
    { title: '20 minutes', value: 20 },
    { title: '30 minutes', value: 30 },
    { title: '45 minutes', value: 45 },
    { title: '60 minutes', value: 60 },
    { title: '120 minutes', value: 120 },
  ];
  public guestPermissionsOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Modify event', value: 'edit' },
    { title: 'Invite others', value: 'invite' },
    { title: 'See guests list', value: 'list' },
  ];
  public reminderOptions: DropdownSelectItem<unknown>[] = [
    { title: 'At time of event', value: 0 },
    { title: '5 minutes before', value: 5 },
    { title: '15 minutes before', value: 15 },
    { title: '30 minutes before', value: 30 },
    { title: '1 hour before', value: 60 },
    { title: '12 hour before', value: 720 },
    { title: '1 day before', value: 1_440 },
    { title: '1 week before', value: 10_080 },
  ];
}
