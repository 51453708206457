// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Attachment } from '../types/attachment';

// Env
import { environment } from '@environment';

@Injectable()
export class AttachmentsService {
  constructor(private http: HttpClient) {}

  listAll(stitchItem: Stitch): Observable<Attachment[]> {
    return this.http
      .get(`${environment.baseUrl}/api/attachments/${stitchItem.getStitchType()}/${stitchItem.id}`)
      .pipe(
        map((response) => response['attachments'].map((attachment) => new Attachment({ ...attachment, stitchItem }))),
      );
  }
}
