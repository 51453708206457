// Types
import { Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StitchType } from '@modules/common/types/stitch-type';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }] });

export class Comment {
  formBuilder = injector.get(UntypedFormBuilder);

  id: string;
  stitchId: string;
  stitchType: StitchType;
  text: string;
  accountId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(raw?) {
    this.id = raw?.id;
    this.text = raw?.text;
    this.stitchId = raw?.stitchId;
    this.stitchType = raw?.stitchType;
    this.accountId = raw?.accountId;
    this.createdAt = raw?.createdAt && new Date(raw?.createdAt);
    this.updatedAt = raw?.updatedAt && new Date(raw?.updatedAt);
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [this.id],
      text: [this.text, Validators.required],
      stitchId: [this.stitchId],
      stitchType: [this.stitchType],
      accountId: [this.accountId],
      createdAt: [this.createdAt],
      updatedAt: [this.updatedAt],
    });
  }

  static fromFormGroup(form: UntypedFormGroup): Comment {
    const formValues = form.value;

    return new Comment({
      id: formValues.id,
      text: formValues.text,
      stitchId: formValues.stitchId,
      stitchType: formValues.stitchType,
      accountId: formValues.accountId,
      createdAt: formValues.createdAt,
      updatedAt: formValues.updatedAt,
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      text: this.text,
      stitchId: this.stitchId,
      stitchType: this.stitchType,
    };
  }
}
