// Common
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date-inline-input',
  templateUrl: './date-inline-input.component.html',
  styleUrls: ['./date-inline-input.component.less', '../../styles/input.less'],
  standalone: false,
})
export class DateInlineInputComponent {
  // Inputs
  @Input() inputFormControl: FormControl<Date>;
  @Input() placeholder: { month: string; day: string; year: string } = { month: 'Month', day: 'Day', year: 'Year' };
  @Input() noBorder = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;

  // Public
  public popoverClose = new Subject<void>();
  public innerDate: Date = new Date();
  public monthSet = false;
  public daySet = false;
  public yearSet = false;
  public maxYear = new Date().getFullYear() - 18;

  /**
   * Actions
   */

  handleSelect(value: Date, part: 'year' | 'month' | 'day') {
    this.innerDate = value;

    switch (part) {
      case 'year':
        this.yearSet = true;
        break;
      case 'month':
        this.monthSet = true;
        this.daySet = false;
        break;
      case 'day':
        this.daySet = true;
        break;
    }

    if (this.yearSet && this.monthSet && this.daySet) {
      this.inputFormControl?.setValue(this.innerDate);
    }
  }
}
