<div class="main" *spaceContext="'main'">
  <app-nav-bar />

  <div class="under-nav-bar">
    <app-split-view
      side="left"
      key="settingsSidebar"
      [collapsedSize]="76"
      [shadow]="true"
      [magneticSize]="120"
      [withIcon]="true"
    >
      <div class="sidebar" split-left-pane>
        <app-settings-side-bar />
      </div>

      <div split-right-pane class="content" cdkScrollable>
        <router-outlet *stchSpaceRerender />
      </div>
    </app-split-view>
  </div>
</div>
