// Common
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

// Types
import { CalendarEvent } from 'angular-calendar';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';

@Component({
  selector: 'stch-calendar-day',
  templateUrl: './calendar-day.component.html',
  standalone: false,
})
export class CalendarDayComponent implements OnChanges {
  public excludeDays: number[] = [];

  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];

  @Output() dateClicked = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked = new EventEmitter<Date>();
  @Output() dayNumberClicked = new EventEmitter<Date>();
  @Output() eventDropped = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents = new EventEmitter<Date>();

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('viewDate' in changes) {
      this.excludeDays = new Array(7)
        .fill(null)
        .map((_, index) => index)
        .filter((day) => day !== (this.viewDate || new Date()).getDay());
    }
  }
}
