<div
  class="task"
  [class.expanded]="expandable && isExpanded"
  [class.dragging]="dragging"
  [class.subtask]="subtask"

  appDraggable
  [appDraggableDisabled]="dragging"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  [appTaskContextMenu]="task"
  (appTaskContextMenuOpenTask)="open.emit()"
  (appTaskContextMenuOpenSubtask)="openSubtask.emit($event)"
>
  <div class="task-title">
    <app-circular-progress
      [progress]="task.progress"
      size="20"
      [priority]="task.priority"
      [completed]="task.completed"
      (onClick)="handleComplete()"
    />

    <span
      *ngIf="showIssueKey && task.issueKeyTitle"
      class="task-title-issue-key"
    >
      {{ task.issueKeyTitle }}
    </span>

    <div class="task-title-text">
      {{ task.title }}
    </div>
  </div>

  <div
    *ngIf="!subtask"
    class="task-date"
  >
    <div class="task-date-item">
      {{ !task.fromDate && !task.fromTime ? 'No Start Date' : '' }}
      {{ task.fromDate | date : 'MMM d' }}
      {{ task.fromDate && task.fromTime && ', ' }}
      {{ task.fromTime | date : 'h:mm a' | lowercase }}
    </div>
    <div class="task-date-item">
      {{ !task.toDate && !task.toTime ? 'No Due Date' : '' }}
      {{ task.toDate | date : 'MMM d' }}
      {{ task.toDate && task.toTime && ', ' }}
      {{ task.toTime | date : 'h:mm a' | lowercase }}
    </div>
  </div>

  <div
    *ngIf="!subtask"
    class="task-buttons"
  >
    <div
      *ngIf="expandable"
      class="task-buttons-item"
      (click)="trigger($event)"
    >
      <mat-icon
        svgIcon="subtasks"
        class="task-buttons-item-icon"
      />

      <mat-icon
        svgIcon="chevron-down"
        class="task-buttons-item-chevron-icon"
        [class.expanded]="isExpanded"
      />
    </div>

    <div
      class="task-buttons-item"
    >
      <mat-icon
        svgIcon="stitch-horizontal"
        class="task-buttons-item-icon"
      />

      <mat-icon
        svgIcon="chevron-down"
        class="task-buttons-item-chevron-icon"
      />
    </div>
  </div>
</div>

<ng-template #dragPlaceholder>
  <app-board-task [task]="task" [subtask]="subtask" [dragging]="true" />
</ng-template>
