import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, inject } from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngClass]',
})
export class ngClassDirective implements OnChanges {
  @Input('ngClass')
  public mappings: [classes: string, condition: boolean][];

  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.mappings) {
      const classesToRemove = new Set<string>();
      const classesToAdd = new Set<string>();
      for (const pair of this.mappings) {
        const [classNames, condition] = pair;
        const classList = classNames.split(/\s+/).filter((s) => s.length > 0);
        if (condition) {
          for (const className of classList) {
            classesToAdd.add(className);
          }
        } else {
          for (const className of classList) {
            classesToRemove.add(className);
          }
        }
      }

      for (const className of classesToAdd) {
        classesToRemove.delete(className);
      }

      for (const className of classesToRemove) {
        this.renderer.removeClass(this.el.nativeElement, className);
      }
      for (const className of classesToAdd) {
        this.renderer.addClass(this.el.nativeElement, className);
      }
    }
  }
}
