<div class="panel" *ngIf="!minimized">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <div class="panel-heading-top-title">
        {{ sidebarFilter | titleByFilesFilter }}
      </div>

      <div class="panel-heading-top-separator"></div>

      <div
        class="debug"
        [class.active]="debug"
        (click)="doDebug()"
      >
        {{ debug || 'Debug' }}
      </div>

      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'files'"
          svgIcon="filters"
          appFilesListContextMenu
          [appFilesListContextMenuWithAdvancedSearch]="true"
          [appFilesListContextMenuStateKey]="filesListStateKey"
          (appFilesListContextMenuChange)="handleFilesListStateChange($event)"
        />
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'folders'"
          svgIcon="filters"
          appFoldersListContextMenu
          [appFoldersListContextMenuWithAdvancedSearch]="true"
          [appFoldersListContextMenuStateKey]="foldersListStateKey"
          (appFoldersListContextMenuChange)="handleFoldersListStateChange($event)"
        />
      </div>
    </div>

    <app-tabs
      *ngIf="listState === 'tabs'"
      class="panel-heading-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
    />
  </div>

  <app-quick-form
    *ngIf="
      selectedTab === 'files' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New File"
  >
    <app-file-quick-form
      [file]="quickFile"
      [autofocus]="true"
      (more)="handleNewFile($event)"
    />
  </app-quick-form>

  <app-quick-form
    *ngIf="
      selectedTab === 'folders' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Folder"
  >
    <app-folder-quick-form
      [folder]="quickFolder"
      [autofocus]="true"
      (more)="handleNewFolder($event)"
    />
  </app-quick-form>

  <div class="panel-list">
    <app-files-list
      *ngIf="selectedTab === 'files'"
      [filters]="filesFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openFile)="handleClickFile($event)"
      (clickFile)="handleClickFile($event)"
      (doubleClickFile)="handleDoubleClickFile($event)"
    />

    <app-folders-list
      *ngIf="selectedTab === 'folders'"
      [filters]="foldersFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openFolder)="handleClickFolder($event)"
      (openFile)="handleClickFile($event)"
      (clickFolder)="handleClickFolder($event)"
      (doubleClickFolder)="handleDoubleClickFolder($event)"
    />
  </div>
</div>
