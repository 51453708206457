// Common
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../auth/services/auth.guard';
import { AdminGuard } from '@modules/auth/services/admin.guard';

// Components
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { ContactsLayoutComponent } from './components/contacts-layout/contacts-layout.component';
import { EventsLayoutComponent } from './components/events-layout/events-layout.component';
import { FilesLayoutComponent } from './components/files-layout/files-layout.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MailLayoutComponent } from './components/mail-layout/mail-layout.component';
import { NotesLayoutComponent } from './components/notes-layout/notes-layout.component';
import { SettingsLayoutComponent } from './components/settings-layout/settings-layout.component';
import { TaskingLayoutComponent } from './components/tasking-layout/tasking-layout.component';

// Nested
import { routes as SettingsNestedRoutes } from '@modules/settings/routing.module';
import { routes as AdminNestedRoutes } from '@modules/admin/routing.module';

// Shared Components
import { SharedLayoutComponent } from './components/shared-layout/shared-layout.component';
import { MessageFolderSharedComponent } from '@modules/messages/components/message-folder-shared/message-folder-shared.component';
import { MessageSharedComponent } from '@modules/messages/components/message-shared/message-shared.component';
import { CalendarSharedComponent } from '@modules/calendar-app/components/calendar-shared/calendar-shared.component';
import { EventSharedComponent } from '@modules/calendar-app/components/event-shared/event-shared.component';
import { ProjectSharedComponent } from '@modules/tasks/components/project-shared/project-shared.component';
import { TaskSharedComponent } from '@modules/tasks/components/task-shared/task-shared.component';
import { NotebookSharedComponent } from '@modules/notes/components/notebook-shared/notebook-shared.component';
import { NoteSharedComponent } from '@modules/notes/components/note-shared/note-shared.component';
import { GroupSharedComponent } from '@modules/contacts/components/group-shared/group-shared.component';
import { ContactSharedComponent } from '@modules/contacts/components/contact-shared/contact-shared.component';
import { FolderSharedComponent } from '@modules/files/components/folder-shared/folder-shared.component';
import { FileSharedComponent } from '@modules/files/components/file-shared/file-shared.component';

const pagesRoutes: Routes = [
  { path: 'settings', canActivate: [authGuard], component: SettingsLayoutComponent, children: SettingsNestedRoutes },
  { path: 'admin', canActivate: [authGuard, AdminGuard], component: AdminLayoutComponent, children: AdminNestedRoutes },
  { path: '', redirectTo: '/mail', pathMatch: 'full' },
  {
    path: '',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      { path: 'mail', component: MailLayoutComponent },
      { path: 'events', component: EventsLayoutComponent },
      { path: 'tasks', component: TaskingLayoutComponent },
      { path: 'notes', component: NotesLayoutComponent },
      { path: 'contacts', component: ContactsLayoutComponent },
      { path: 'files', component: FilesLayoutComponent },
    ],
  },
  {
    path: 'shared',
    component: SharedLayoutComponent,
    children: [
      { path: 'message-folder/:id', component: MessageFolderSharedComponent },
      { path: 'message/:id', component: MessageSharedComponent },
      { path: 'calendar/:id', component: CalendarSharedComponent },
      { path: 'event/:id', component: EventSharedComponent },
      { path: 'project/:id', component: ProjectSharedComponent },
      { path: 'task/:id', component: TaskSharedComponent },
      { path: 'notebook/:id', component: NotebookSharedComponent },
      { path: 'note/:id', component: NoteSharedComponent },
      { path: 'group/:id', component: GroupSharedComponent },
      { path: 'contact/:id', component: ContactSharedComponent },
      { path: 'folder/:id', component: FolderSharedComponent },
      { path: 'file/:id', component: FileSharedComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(pagesRoutes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
