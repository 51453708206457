import { NgModule } from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Components
import { ScrollComponent } from './components/scroll/scroll.component';

// Directives
import { ScrollAnchorDirective } from './directives/scroll-anchor/scroll-anchor.directive';
import { ScrollDirective } from './directives/scroll.directive';

@NgModule({
  imports: [CommonModule, MatIconModule, ScrollingModule],
  declarations: [ScrollAnchorDirective, ScrollComponent, ScrollDirective],
  exports: [ScrollAnchorDirective, ScrollComponent, ScrollDirective],
})
export class ScrollModule {}
