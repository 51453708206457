// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { File } from '@modules/files/types/file';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { FilesService } from '@modules/files/services/files.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-link-info-file',
  templateUrl: './link-info-file.component.html',
  styleUrls: ['./link-info-file.component.less'],
  standalone: false,
})
export class LinkInfoFileComponent {
  // Inputs
  @Input() file: File;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.file &&
    !(dragData.type === DragDataTypes.file && dragData.data.length === 1 && dragData.data[0]['id'] === this.file.id) &&
    dragDataTypeAllowed(dragData.type);

  /**
   * Constructor
   */

  constructor(
    private filesService: FilesService,
    private stitchService: StitchService,
  ) {}

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.filesService
      .flag({ ids: [this.file.id] }, !this.file.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.file.flagged = !this.file.flagged));
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.filesService
      .pin({ ids: [this.file.id] }, !this.file.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.file.pinned = !this.file.pinned));
  }

  archive() {
    this.filesService
      .archive({ ids: [this.file.id] }, !this.file.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => (this.file.archived = !this.file.archived));
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.file.deleted
        ? this.filesService.deletePermanently({ ids: [this.file.id] })
        : this.filesService.delete({ ids: [this.file.id] }, true);
    }
  }

  dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.file, dragData);
  }
}
