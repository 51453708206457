// Common
import { UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@environment';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { StitchType } from '@modules/common/types/stitch-type';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Message } from '@modules/messages/types/message';
import { ChildStitch } from '@modules/common/types/child-stitch';
import { Stitch } from '@modules/common/types/stitch';
import { FileValidators } from '@modules/form-controls/validators/file.validators';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { Upload } from '@modules/common/types/upload';

export class File extends ChildStitch {
  color?: string;
  folderId?: string;
  title?: string;
  attachmentName?: string;
  attachmentType?: string;
  attachmentSize?: number;
  tempAttachmentId?: string;

  constructor(data: Partial<File> = {}) {
    super(data);
    this.containerId = data?.containerId || data?.folderId;

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.folderId = data.folderId || data.containerId;
      this.title = data.title;
      this.attachmentName = data.attachmentName;
      this.attachmentType = data.attachmentType;
      this.tempAttachmentId = data.tempAttachmentId;
      this.attachmentSize = data.attachmentSize;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): File {
    const formValues = form.value;

    return new File({
      archived: formValues.archived,
      color: formValues.color,
      deleted: formValues.deleted,
      description: formValues.description,
      flagged: formValues.flagged,
      folderId: formValues.folderId,
      followed: formValues.followed,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      title: formValues.title,
      tags: formValues.tags,
      connections: formValues.connections,
      tempAttachmentId: formValues.attachment?.id,
      attachmentName: formValues.attachment?.name,
      attachmentType: formValues.attachment?.type,
      attachmentSize: formValues.attachment?.size,
      temp: formValues.temp,
      position: formValues.position,
      snoozed: formValues.snoozed,
      uploads: formValues.uploads,
    });
  }

  static fromDragData(dragItem: DragData): File {
    return super.fromDragData(dragItem) as File;
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): File {
    return new File({
      title: filters.query || filters.files.title,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.files.description,
      folderId: filters.files.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'color', 'folderId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<File>; message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_files':
        return { changes: { folderId: null, parentId: null }, message: (message += 'Orphan') };
      case 'all_folders':
        break;
      case 'recent':
      case 'upcoming':
        break;
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  static fromUpload(upload: Upload): File {
    return new File({
      attachmentName: upload.name,
      attachmentType: upload.type,
      attachmentSize: upload.size,
      tempAttachmentId: upload.id,
      title: upload.name,
    });
  }

  getStitchType(): StitchType {
    return StitchType.file;
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      archived: [this.archived],
      color: [this.color],
      deleted: [this.deleted],
      description: [this.description || ''],
      flagged: [this.flagged],
      folderId: [this.folderId],
      followed: [this.followed],
      id: [this.id],
      knots: [this.knots],
      connections: [this.connections],
      linkedInfo: [this.linkedInfo || []],
      title: [this.title, Validators.required],
      tags: [this.tags || []],
      attachment: [null, this.id ? null : [Validators.required, FileValidators.size(26214400)]],
      temp: [this.temp],
      position: [this.position],
      snoozed: [this.snoozed],
      uploads: [this.uploads || [], FileValidators.size(26214400)],
    });
  }

  asPayloadJSON() {
    return {
      title: this.title,
      description: this.description,
      color: this.color,
      folderId: this.folderId,
      followed: this.followed,
      archived: this.archived,
      deleted: this.deleted,
      flagged: this.flagged,
      tempAttachmentId: this.tempAttachmentId,
      attachmentName: this.attachmentName,
      attachmentType: this.attachmentType,
      attachmentSize: this.attachmentSize,
      snoozed: this.snoozed,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title,
      this.createdAt,
      this.updatedAt,
      this.createdAt,
      this.updatedAt,
    );
  }

  download() {
    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', `${environment.baseUrl}/api/attachments/${this.id}/${this.id}/${this.attachmentName}`);
    link.setAttribute('download', this.attachmentName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.description = data.bodyText;
    } else if (data instanceof File) {
      this.folderId = data.id;
    }
  }
}
