// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Utils
import { isNil } from '@modules/common/utils/base';

// Types
import { Connection } from '@modules/connections/types/connection';
import { ManageListState } from '@modules/connections/types/manage-list-state';

// RX
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { ConnectionsService } from '@modules/connections/services/connections.service';

@Component({
  selector: 'app-manage-connections-form',
  templateUrl: './manage-connections-form.component.html',
  styleUrls: ['./manage-connections-form.component.less'],
  standalone: false,
})
export class ManageConnectionsFormComponent implements OnInit, OnChanges, OnDestroy {
  // Inputs
  @Input() existingConnections: Connection[] = [];

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Connection[]>();

  public inputControl = new UntypedFormControl('');
  public scrollShadowTop = false;
  public scrollShadowBottom = false;
  public state: ManageListState;
  public canAdd = false;
  public connections: Connection[] = [];

  private alive = new Subject<void>();

  constructor(private connectionsService: ConnectionsService) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControl.valueChanges.pipe(takeUntil(this.alive)).subscribe((value) => {
      this.canAdd = value.trim() !== '' && !this.connections?.some((item) => item.name === value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('existingConnections' in changes && this.existingConnections?.length) {
      this.connections = this.existingConnections.map((connection) => new Connection(connection));
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addConnection() {
    const name = this.inputControl.value.trim();

    if (name === '') {
      return;
    }

    this.connectionsService
      .search({ names: [name] })
      .pipe(take(1), takeUntil(this.alive))
      .subscribe(({ items: connections }) => {
        if (connections[0]) {
          this.connections = [...this.connections, new Connection({ ...connections[0], added: true })];
        } else {
          this.connections = [...this.connections, new Connection({ name, added: true })];
        }
      });

    this.inputControl.setValue('');
  }

  handleDelete(connection: Connection) {
    if (connection.added) {
      this.connections = this.connections.filter((item) => item.name !== connection.name);
    } else {
      this.connections = this.connections.map((item) =>
        item.name === connection.name ? new Connection({ ...connection, deleted: true }) : item,
      );
    }
  }

  handleChange(connection: Connection) {
    if (isNil(connection.id)) {
      return;
    }

    this.connections = this.connections.map((item) =>
      connection.id === item.id ? new Connection({ ...item, changed: true }) : item,
    );
  }

  handleCancel() {
    this.close.emit();
  }

  handleSave() {
    this.save.emit(this.connections);
  }
}
