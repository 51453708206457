// Common
import { FormGroup, Validators } from '@angular/forms';
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TypedFormGroup } from '@modules/common/utils/form';

// Types
import { TeamParticipant } from './team-participant';
import { Account } from '@modules/account/types/account';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }] });

export type TeamForm = FormGroup<
  TypedFormGroup<{
    id: string;
    color: string;
    description: string;
    archived: boolean;
    spaceId: string;
    title: string;
  }>
>;

export class Team {
  formBuilder = injector.get(UntypedFormBuilder);

  color: string;
  createdAt?: Date;
  description?: string;
  archived?: boolean;
  id?: string;
  owner: TeamParticipant;
  participants: TeamParticipant[] = [];
  title: string;
  currentUserStatus: 'pending' | 'accepted' | 'rejected';
  spaceId: string;

  constructor(data: Partial<Team> = {}, currentUser?: Account) {
    this.color = data.color;
    this.createdAt = data.createdAt && new Date(data.createdAt);
    this.description = data.description;
    this.archived = data.archived;
    this.id = data.id;
    this.title = data.title;
    this.spaceId = data.spaceId;

    if (data instanceof Team) {
      this.participants = data.participants || [];
    } else {
      this.participants = (data.participants || []).map((i) => new TeamParticipant(i));
    }

    this.owner = this.participants.find(({ owner }) => owner);

    this.currentUserStatus = this.participants.find(({ id }) => id === currentUser?.id)?.status;
  }

  static fromFormGroup(form: TeamForm): Team {
    const formValues = form.value;

    return new Team({
      color: formValues.color,
      description: formValues.description,
      archived: formValues.archived,
      id: formValues.id,
      spaceId: formValues.spaceId,
      title: formValues.title,
    });
  }

  asFormGroup(): TeamForm {
    return this.formBuilder.group({
      color: [this.color],
      description: [this.description],
      archived: [this.archived],
      id: [this.id],
      spaceId: [this.spaceId],
      title: [this.title, Validators.required],
    });
  }

  asPayloadJSON() {
    return {
      color: this.color,
      description: this.description,
      archived: this.archived,
      id: this.id,
      spaceId: this.spaceId,
      title: this.title,
    };
  }
}
