import { SortBy } from '@modules/common/types/SortBy';
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { SortOrder } from '@modules/common/types/sort-order';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StateService } from '@modules/settings/services/state.service';
import { StateKey } from '@modules/settings/types/state-key';
import { OnChange } from 'src/app/decorators/on-change';
import { type Note } from './note';

export type NoteListSortField = 'title' | 'createdAt' | 'updatedAt' | 'score' | 'sharedViaLinkAt';

export class NotesListState extends BaseStitchListState<Note, NoteListSortField> {
  @OnChange('onPropertyChange') public override sortBy?: NoteListSortField;
  @OnChange('onPropertyChange') today?: boolean;

  constructor({
    searchService,
    stateService,
    stateKey,
    withAdvanced,
    defaultSort = { by: 'createdAt', order: SortOrder.DESC },
  }: {
    searchService?: AdvancedSearchService;
    stateService?: StateService;
    stateKey?: StateKey;
    withAdvanced?: boolean;
    defaultSort?: SortBy<NoteListSortField>;
  }) {
    super({ searchService, stateService, stateKey, withAdvanced, defaultSort });
  }

  protected override applySavedState(state?: Like<NotesListState>) {
    super.applySavedState(state);
    this.today = !!state?.today;
  }

  public override onPropertyChange(attribute) {
    if ((attribute === 'from' && this.from) || (attribute === 'to' && this.to)) {
      this.today = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
    }

    super.onPropertyChange(attribute);
  }

  protected override syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today,
    });
  }

  public override isDefault(): boolean {
    return super.isDefault() && !this.today;
  }

  protected override syncFromAS({ notes }: AdvancedSearchState) {
    this.stitchedWith = notes.stitched;
    this.from = notes.dateRangeFrom;
    this.to = notes.dateRangeTo;
  }

  protected override syncToAS(): void {
    const searchState = this.searchService.getStateSync();
    const state = searchState.notes;

    state.stitched = this.stitchedWith;
    state.dateRangeFrom = this.from;
    state.dateRangeTo = this.to;

    this.searchService.setState(searchState);
  }
}
