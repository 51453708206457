// Common
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Application } from '@modules/common/types/application';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';

// RX
import { take, takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-notebook-context-menu',
  templateUrl: './notebook-context-menu.component.html',
  styleUrls: ['../stitch-context-menu/stitch-context-menu.component.less', './notebook-context-menu.component.less'],
  standalone: false,
})
export class NotebookContextMenuComponent extends StitchContextMenuComponent<Notebook> implements OnInit, OnChanges {
  @Input() notebook: Notebook;

  @Output() openNote = new EventEmitter<Note>();

  applicationName = Application.notes;
  gaPrefix = 'notebook-context-menu';
  public form: UntypedFormGroup;

  constructor(
    private notebooksService: NotebooksService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, notebooksService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.notebook;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('notebook' in changes) {
      this.resetForm();
      this.item = this.notebook;
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.notebooksService
      .update(Notebook.fromFormGroup(this.form))
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.notebook || new Notebook()).asFormGroup();
  }

  handleNoteFormMore(note: Note) {
    this.openNote.emit(note);
  }

  duplicate() {
    if (!this.notebook) {
      return;
    }

    this.notebooksService.duplicate(this.getIds()).pipe(take(1)).subscribe();
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.notebooksService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(
      `When deleting, all notes in ${this.multiple ? 'these' : 'this'} notebook${this.multiple && 's'} will be removed also`,
    );
  }
}
