// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { RelativeDate } from '@modules/common/types/relative-date';
import { PostponeType } from '@modules/common/types/postpone-type';
import { SendLater } from '@modules/messages/types/send-later';

// Utils
import { addDays, relativeDateToDate } from '@modules/common/utils/date';

@Component({
  selector: 'app-postpone-context-menu',
  templateUrl: './postpone-context-menu.component.html',
  styleUrls: ['./postpone-context-menu.component.less'],
  standalone: false,
})
export class PostponeContextMenuComponent {
  @Input() items: Stitch[];
  @Input() appearance: PostponeType = 'snooze';
  @Input() inputFormControl: UntypedFormControl;
  @Input() convertToDate = true;

  @Output() close = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<Date>();

  public dateControl = new UntypedFormControl(null);
  public timeControl = new UntypedFormControl(null);
  public options: {
    snooze: { title: string; value: number }[];
    followUp: { title: string; value: string }[];
    sendLater: { title: string; value: SendLater }[];
  } = {
    snooze: [
      { title: 'None', value: null },
      { title: '5 minutes', value: 5 },
      { title: '10 minutes', value: 10 },
      { title: '15 minutes', value: 15 },
      { title: '30 minutes', value: 30 },
    ],
    followUp: [
      { title: 'None', value: null },
      { title: 'Tomorrow', value: 'tomorrow' },
      { title: 'Later this week', value: 'thisWeek' },
      { title: 'This weekend', value: 'weekend' },
      { title: 'Next week', value: 'nextMonday' },
    ],
    sendLater: [
      { title: '15 minutes', value: 900 },
      { title: '1 hour', value: 3600 },
      { title: '3 hours', value: 10800 },
      new Date().getHours() < 17 ? { title: 'End of the day (5pm)', value: 'endOfDay' } : null,
      { title: 'Tomorrow', value: 'tomorrow' },
      { title: 'Send Immediately', value: null },
    ],
  };
  public minDate = addDays(new Date(), -1);

  constructor() {
    const today = new Date();

    this.dateControl.setValue(today, { emitEvent: false });
    this.timeControl.setValue(today, { emitEvent: false });

    this.options.sendLater = this.options.sendLater.filter((i) => !!i);
  }

  /**
   * Actions
   */

  handleSelect(option) {
    // if (!this.inputFormControl) { return; }
    if (option.value === null) {
      this.complete(null);
      return;
    }

    if (!this.convertToDate) {
      this.complete(option.value);
      return;
    }

    switch (this.appearance) {
      case 'followUp':
        this.handleFollowUpSelect(option.value);
        break;
      case 'snooze':
        this.handleSnoozeSelect(option.value);
        break;
      case 'sendLater':
        this.handleSendLaterSelect(option.value);
        break;
    }
  }

  handleSnoozeSelect(parameter: number) {
    const date = new Date();
    date.setHours(date.getHours(), date.getMinutes() + parameter, 0, 0);

    this.complete(date);
  }

  handleFollowUpSelect(parameter: RelativeDate) {
    const date = relativeDateToDate(parameter);
    date.setHours(9, 0, 0, 0);

    this.complete(date);
  }

  handleSendLaterSelect(value: SendLater) {
    let date = new Date();

    if (value instanceof Date) {
      this.complete(date);
      return;
    }

    switch (value) {
      case 'endOfDay':
        date.setHours(17);
        break;
      case 'tomorrow':
        date = addDays(date, 1);
        break;
      default:
        date.setSeconds(date.getSeconds() + value);
    }

    this.complete(date);
  }

  handleCustomSelect() {
    if (!this.dateControl.value) {
      return;
    }

    const date = this.dateControl.value;

    if (this.timeControl.value) {
      date.setHours(this.timeControl.value.getHours());
      date.setMinutes(this.timeControl.value.getMinutes());
    }

    this.complete(date);
  }

  handleUnSnooze() {
    this.complete(null);
  }

  private complete(date: Date) {
    this.inputFormControl?.setValue?.(date);
    this.onSelect.emit(date);
    this.close.emit();
  }
}
