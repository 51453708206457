// Common
import { Component, Injector } from '@angular/core';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-calendar-shared',
  templateUrl: './calendar-shared.component.html',
  styleUrls: ['./calendar-shared.component.less'],
  standalone: false,
})
export class CalendarSharedComponent extends SharedBaseComponent<Calendar> {
  protected stitchType = StitchType.calendar;

  constructor(injector: Injector) {
    super(injector);
  }

  get calendar() {
    return this.stitchItem;
  }
}
