<button
  [type]="type()"
  [disabled]="disabled()"
  class="font-medium inline-flex items-center"
  [ngClass]="[
    ['active:scale-95 transition duration-200 ease-in-out', !disabled() && ('primary' === appearance() || 'secondary' === appearance() || 'primary-ghost' === appearance() || 'secondary-ghost' === appearance())],

    ['text-sm h-[24px] rounded-md border gap-2 px-6 has-[app-icon]:px-8  has-[app-icon:first-child]:pl-2 has-[app-icon:last-child]:pr-2 has-[app-icon:only-child]:px-0.5', 's'  === size() && ('primary' === appearance() || 'secondary' === appearance())],
    ['text-m  h-[32px] rounded-md border gap-4 px-8 has-[app-icon]:px-12 has-[app-icon:first-child]:pl-4 has-[app-icon:last-child]:pr-4 has-[app-icon:only-child]:px-2.5', 'm'  === size() && ('primary' === appearance() || 'secondary' === appearance())],
    ['text-m  h-[40px] rounded-md border gap-4 px-8 has-[app-icon]:px-12 has-[app-icon:first-child]:pl-8 has-[app-icon:last-child]:pr-8 has-[app-icon:only-child]:px-4.5', 'l'  === size() && ('primary' === appearance() || 'secondary' === appearance())],
    ['text-lg h-[48px] rounded-md border gap-4 px-8 has-[app-icon]:px-12 has-[app-icon:first-child]:pl-8 has-[app-icon:last-child]:pr-8 has-[app-icon:only-child]:px-6.5', 'xl' === size() && ('primary' === appearance() || 'secondary' === appearance())],

    ['text-sm h-[24px] rounded-md border border-transparent gap-1 px-0 has-[app-icon]:px-4  has-[app-icon:first-child]:pl-2 has-[app-icon:last-child]:pr-2 has-[app-icon:only-child]:px-0.5', 's'  === size() && ('primary-ghost' === appearance() || 'secondary-ghost' === appearance())],
    ['text-m  h-[32px] rounded-md border border-transparent gap-2 px-2 has-[app-icon]:px-8  has-[app-icon:first-child]:pl-4 has-[app-icon:last-child]:pr-4 has-[app-icon:only-child]:px-2.5', 'm'  === size() && ('primary-ghost' === appearance() || 'secondary-ghost' === appearance())],
    ['text-m  h-[40px] rounded-md border border-transparent gap-2 px-4 has-[app-icon]:px-8  has-[app-icon:first-child]:pl-4 has-[app-icon:last-child]:pr-4 has-[app-icon:only-child]:px-4.5', 'l'  === size() && ('primary-ghost' === appearance() || 'secondary-ghost' === appearance())],
    ['text-lg h-[48px] rounded-md border border-transparent gap-2 px-6 has-[app-icon]:px-12 has-[app-icon:first-child]:pl-8 has-[app-icon:last-child]:pr-8 has-[app-icon:only-child]:px-6.5', 'xl' === size() && ('primary-ghost' === appearance() || 'secondary-ghost' === appearance())],

    ['text-sm decoration-[1.6px] px-0 underline-offset-2', 's'  === size() && ('primary-text' === appearance() || 'secondary-text' === appearance() || 'link' === appearance() || 'sublink' === appearance())],
    ['text-m  decoration-2       px-0 underline-offset-5', 'm'  === size() && ('primary-text' === appearance() || 'secondary-text' === appearance() || 'link' === appearance() || 'sublink' === appearance())],
    ['text-m  decoration-2       px-0 underline-offset-5', 'l'  === size() && ('primary-text' === appearance() || 'secondary-text' === appearance() || 'link' === appearance() || 'sublink' === appearance())],
    ['text-lg decoration-2       px-0 underline-offset-4', 'xl' === size() && ('primary-text' === appearance() || 'secondary-text' === appearance() || 'link' === appearance() || 'sublink' === appearance())],

    ['[&>app-icon]:text-gray-500', !disabled() && ('secondary' === appearance() || 'secondary-ghost' === appearance())],

    ['text-background bg-primary-600 border-primary-600 hover:bg-primary-800 focus-visible:border-primary-200 focus-visible:outline-2 outline-primary-800', 'primary' === appearance() && !disabled()],
    ['text-gray-700 border-border hover:bg-gray-100 focus-visible:border-primary-600 active:text-primary-600 active:border-primary-600 focus-visible:outline-2 outline-primary-300', 'secondary' === appearance() && !disabled()],
    ['border-transparent text-primary-600 hover:bg-primary-100 focus-visible:border focus-visible:border-primary-600 active:text-primary-800 focus-visible:outline-2 outline-primary-300', 'primary-ghost' === appearance() && !disabled()],
    ['border-transparent text-gray-700 hover:bg-primary-100 focus-visible:border focus-visible:border-primary-600 active:text-primary-600 focus-visible:outline-2 outline-primary-300', 'secondary-ghost' === appearance() && !disabled()],

    ['text-primary-600 decoration-primary-600 underline hover:text-gray-700 hover:decoration-gray-700 focus-visible:decoration-dotted focus-visible:bg-primary-100 focus-visible:text-primary-600 active:text-primary-600 active:decoration-primary-600 outline-none', 'link' === appearance() && !disabled()],
    ['text-gray-600 decoration-gray-600 underline hover:text-primary-600 hover:decoration-primary-600 focus-visible:decoration-dotted focus-visible:bg-gray-100 focus-visible:text-grary-700 active:text-gray-700 active:decoration-gray-700 outline-none', 'sublink' === appearance() && !disabled()],
    ['text-primary-600 decoration-dotted decoration-primary-600 hover:text-primary-800 focus-visible:bg-primary-100 focus-visible:underline active:text-primary-600 outline-none', 'primary-text' === appearance() && !disabled()],
    ['text-gray-700 decoration-dotted decoration-primary-600 hover:text-primary-600 focus-visible:bg-primary-100 focus-visible:underline active:text-primary-900 outline-none', 'secondary-text' === appearance() && !disabled()],

    ['text-background bg-primary-300 cursor-not-allowed', 'primary' === appearance() && disabled()],
    ['text-gray-300 border-gray-200 cursor-not-allowed', 'secondary' === appearance() && disabled()],
    ['text-primary-300', disabled() && ('primary-ghost' === appearance() || 'primary-text' === appearance() || 'link' === appearance())],
    ['text-gray-300', disabled() && ('secondary-ghost' === appearance() || 'secondary-text' === appearance() || 'sublink' === appearance())]
  ]"
  appWrapTextNodes
>
  <ng-content></ng-content>
</button>