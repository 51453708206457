// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-events-layout',
  templateUrl: './events-layout.component.html',
  styleUrls: ['./events-layout.component.less', '../../styles.less'],
  standalone: false,
})
export class EventsLayoutComponent {}
