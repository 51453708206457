// Common
import io, { Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

// RX
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

// Types
import {
  Signal,
  SignalReloadProjectBoard,
  SignalEnum,
  SignalNotificationReceived,
  SignalStitch,
} from '../../types/signal';

@Injectable({ providedIn: 'root' })
export class SignalsService {
  private socket: Socket;
  private messages = new Subject<Signal>();
  private signalEvent = 'signal';

  constructor() {
    this.socket = io(environment.baseUrl, {
      transports: ['websocket'],
      withCredentials: true,
    });

    this.socket.on(this.signalEvent, (signal) => this.messages.next(signal));
  }

  getSignal(type: SignalEnum.RELOAD_PROJECT_BOARD, spaceId?: string): Observable<SignalReloadProjectBoard>;
  getSignal(type: SignalEnum.NEW_NOTIFICATION, spaceId?: string): Observable<SignalNotificationReceived>;
  getSignal(type: SignalEnum.STITCH_EVENT, spaceId?: string): Observable<SignalStitch>;
  getSignal(type: SignalEnum, spaceId?: string): Observable<Signal> {
    return this.messages.pipe(
      filter((signal) => signal.type === type && (spaceId === undefined || signal.spaceId === spaceId)),
    );
  }
}
