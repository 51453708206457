// Common
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

// Types
import { ManageListState } from '@modules/tags/types/manage-list-state';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tags-manage-context-menu',
  templateUrl: './manage-context-menu.component.html',
  styleUrls: ['./manage-context-menu.component.less'],
  standalone: false,
})
export class ManageContextMenuComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() state: ManageListState;
  @Input() global: boolean;

  // Outputs
  @Output() stateChange = new EventEmitter<ManageListState>();

  // Public
  public innerFromDate: UntypedFormControl = new UntypedFormControl();
  public innerToDate: UntypedFormControl = new UntypedFormControl();
  public dateButtonSelected: 'from' | 'to' = 'from';

  // Private
  private alive = new Subject<void>();

  constructor(@Optional() @Inject(CLOSE_POPOVER) private closePopoverToken) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (this.state.filters.createdFrom) {
      this.innerFromDate.setValue(this.state.filters.createdFrom);
    }

    if (this.state.filters.createdTo) {
      this.innerToDate.setValue(this.state.filters.createdTo);
    }

    this.innerFromDate.valueChanges.pipe(takeUntil(this.alive)).subscribe((value: Date) => {
      this.stateChange.emit({
        ...this.state,
        filters: {
          ...this.state.filters,
          createdFrom: beginningOfDay(value),
        },
      });
    });

    this.innerToDate.valueChanges.pipe(takeUntil(this.alive)).subscribe((value: Date) => {
      this.stateChange.emit({
        ...this.state,
        filters: {
          ...this.state.filters,
          createdTo: endOfDay(value),
        },
      });
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  sort(sortBy: 'name' | 'date' | 'recency' | 'frequency') {
    let { by, order } = this.state.sort;

    if (by === sortBy) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      by = sortBy;
      order = 'asc';
    }

    this.stateChange.emit({
      ...this.state,
      sort: { by, order },
    });
  }

  handleClose() {
    this.closePopoverToken.next();
  }
}
