// Common
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Components
import { ContextMenuDividerComponent } from './components/context-menu-divider/context-menu-divider.component';
import { ContextMenuHeadingComponent } from './components/context-menu-heading/context-menu-heading.component';
import { ContextMenuItemComponent } from './components/context-menu-item/context-menu-item.component';
import { ContextMenuStateIndicatorComponent } from './components/context-menu-state-indicator/context-menu-state-indicator.component';
import { PopoverComponent } from './components/popover/popover.component';
import { SelectionTooltipComponent } from './components/selection-tooltip/selection-tooltip.component';

// Modules
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@modules/utils/utils.module';

// Services
import { PopoverService } from './services/popover.service';

// Directives
import { OutsideClickDirective } from './directives/outside-click.directive';
import { PopoverDirective } from './directives/popover.directive';
import { TextSelectionDirective } from './directives/text-selection.directive';

@NgModule({
  imports: [AngularCommonModule, OverlayModule, MatIconModule, UtilsModule],
  providers: [PopoverService],
  declarations: [
    ContextMenuDividerComponent,
    ContextMenuHeadingComponent,
    ContextMenuItemComponent,
    ContextMenuStateIndicatorComponent,
    PopoverComponent,
    SelectionTooltipComponent,

    // Directives
    OutsideClickDirective,
    PopoverDirective,
    TextSelectionDirective,
  ],
  exports: [
    ContextMenuDividerComponent,
    ContextMenuHeadingComponent,
    ContextMenuItemComponent,
    ContextMenuStateIndicatorComponent,

    // Directives
    OutsideClickDirective,
    PopoverDirective,
    TextSelectionDirective,
  ],
})
export class PopoverModule {}
