import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  OnDestroy,
  QueryList,
  Renderer2,
  inject,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appWrapTextNodes]',
  standalone: true,
})
export class WrapTextNodesDirective implements AfterContentInit, OnDestroy {
  @ContentChildren('*', { descendants: false })
  public contentChildren: QueryList<ElementRef>;

  private contentChangeSubscription: Subscription;

  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  public ngAfterContentInit() {
    this.wrapTextNodes(this.el.nativeElement);
    this.contentChangeSubscription = this.contentChildren.changes.subscribe(() => {
      this.wrapTextNodes(this.el.nativeElement);
    });
  }

  public ngOnDestroy() {
    if (this.contentChangeSubscription) {
      this.contentChangeSubscription.unsubscribe();
    }
  }

  private wrapTextNodes(element: HTMLElement): void {
    const childNodes = Array.from(element.childNodes);
    childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE && node.textContent.trim().length > 0) {
        const span = this.renderer.createElement('span');
        const text = this.renderer.createText(node.textContent);
        this.renderer.appendChild(span, text);
        this.renderer.insertBefore(element, span, node);
        this.renderer.removeChild(element, node);
      }
    });
  }
}
