import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessProperty',
  standalone: false,
})
export class AccessPropertyPipe implements PipeTransform {
  transform(obj: object, propertyPath: string): unknown {
    if (!obj || !propertyPath) {
      return obj;
    }

    const properties = propertyPath.split('.');
    let result = obj;

    for (const property of properties) {
      if (Object.getOwnPropertyDescriptor(result, property)) {
        result = result[property];
      } else {
        return undefined;
      }
    }

    return result;
  }
}
