// Common
import { Directive, Input, ComponentRef, Output, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { StitchToComponent } from '../components/link-info/stitch-to/stitch-to.component';

// Types
import { Stitch } from '@modules/common/types/stitch';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appStitchToPopover]',
  standalone: false,
})
export class StitchToPopoverDirective extends ContextMenuDirective implements OnInit {
  // Inputs
  @Input() appStitchToPopover: Stitch;

  // Outputs
  @Output() appStitchToPopoverOpened = this.contextMenuOpened;

  /**
   * Lifecycle
   */

  override ngOnInit() {
    super.ngOnInit();
    this.stchPopoverTrigger = 'click';
    this.stchPopoverPlacement = 'bottom';
    this.stchPopoverFallbackPlacements = [];
  }

  /**
   * Actions
   */

  registerComponent() {
    return StitchToComponent;
  }

  registerInstance(componentRef: ComponentRef<StitchToComponent>) {
    componentRef.instance.stitchItems = [this.appStitchToPopover];
    componentRef.instance.close.pipe(takeUntil(this.alive)).subscribe(() => {
      this.closeSubject.next();
    });
  }
}
