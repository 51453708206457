import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ListStateService } from '@modules/common/components/list/list-state.service';
import { SortBy } from '@modules/common/types/SortBy';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';
import { StateKey } from '@modules/settings/types/state-key';
import { Note } from '../types/note';
import { NoteListSortField, NotesListState } from '../types/notes-list-state';

export interface NotesListStateServiceConfig {
  stateKey?: StateKey;
  withAdvancedSearch?: boolean;
  defaultSort?: SortBy<NoteListSortField>;
}

export const NotesListStateServiceConfigToken = new InjectionToken<NotesListStateServiceConfig>(
  'Configuration object for notes list state service',
);

@Injectable()
export class NotesListStateService extends ListStateService<Note, NotesListState> {
  public override readonly state: NotesListState;

  constructor(
    stateService: StateService,
    advancedSearchService: AdvancedSearchService,
    @Inject(NotesListStateServiceConfigToken) config: NotesListStateServiceConfig,
  ) {
    super();

    this.state = new NotesListState({
      searchService: advancedSearchService,
      stateService: stateService,
      stateKey: config.stateKey,
      withAdvanced: config.withAdvancedSearch,
      defaultSort: config.defaultSort,
    });
  }

  public static providers(config: NotesListStateServiceConfig = {}): any[] {
    return super.prepareProviders(NotesListStateServiceConfigToken, config);
  }
}

declare module '@modules/settings/types/state' {
  export interface State {
    [StateKey.notesListState]?: NotesListState;
    [StateKey.notesDPListState]?: NotesListState;
  }
}
