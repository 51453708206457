// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

// Utils
import { emailPattern } from '@modules/common/utils/email';

// RX
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Types
import { PermissionParticipant } from '@modules/messages/types/permission-participant';
import { Settings } from '@modules/messages/types/settings';

// Services
import { PermissionParticipantsService } from '@modules/messages/services/permission-participants.service';
import { MailAppSettingsService } from '@modules/messages/services/settings.service';

@Component({
  selector: 'app-settings-messages-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.less'],
  standalone: false,
})
export class PermissionsComponent implements OnInit, OnDestroy {
  public addControls = {
    'white-list': new UntypedFormControl('', [Validators.pattern(emailPattern)]),
    'black-list': new UntypedFormControl('', [Validators.pattern(emailPattern)]),
    vip: new UntypedFormControl('', [Validators.pattern(emailPattern)]),
    company: new UntypedFormControl('', []),
    bulk: new UntypedFormControl('', [Validators.pattern(emailPattern)]),
    'non-bulk': new UntypedFormControl('', [Validators.pattern(emailPattern)]),
  };
  public filters = new Settings().asFormGroup();
  public submitted = false;

  private alive = new Subject<void>();

  constructor(
    private permissionParticipantsService: PermissionParticipantsService,
    private settingsService: MailAppSettingsService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.settingsService
      .listAll(true)
      .pipe(
        tap((settings) => {
          this.filters = settings.asFormGroup();
        }),
        switchMap(() => this.filters.valueChanges),
        debounceTime(500),
        switchMap(() => this.settingsService.update(Settings.fromFormGroup(this.filters))),
        takeUntil(this.alive),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addToList(type: PermissionParticipant['type']) {
    this.submitted = true;

    if (this.addControls[type].value === '' || !this.addControls[type].valid) {
      return;
    }

    this.permissionParticipantsService
      .create(
        new PermissionParticipant({
          value: this.addControls[type].value,
          type,
        }),
      )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.addControls[type].setValue('');
      });
  }
}
