<div class="heading">
  <div class="heading-grow">
    <div class="heading1">Import Emails</div>
    <div class="heading2-subtitle">Stitch Rules allow you to automate your workflow.</div>
  </div>

  <app-button
    icon="plus-medium"
    appearance="link-blue"
    label="ADD IMPORT"
    (click)="openForm()"
  />
</div>

<div class="items">
  <div
    *ngFor="let import of imports"
    class="item"
  >
    <div class="item-title">{{ import.folderName }}</div>

    <div *ngIf="import.status === 'failure'" class="item-error">{{ import.error }}</div>

    <div *ngIf="!import.progress && import.status === 'pending'"><mat-spinner [diameter]="20" /></div>
    <div *ngIf="import.progress" class="item-stats">
      <mat-progress-bar *ngIf="import.status === 'pending'" mode="determinate" [value]="import.progress" />
      {{ import.processedCount }} items ({{ import.progress }}%)
    </div>

    <div class="item-status" [ngClass]="import.status">{{ import.status }}</div>


    <div class="item-text">{{ import.createdAt && (import.createdAt | timeAgo) }}</div>

    <mat-icon
      *ngIf="import.status === 'failure'"
      class="item-icon"
      svgIcon="retry"
      (click)="retry(import)"
    />

    <mat-icon
      class="item-icon"
      svgIcon="trash"
      (click)="removeImportPrompt(import)"
    />
  </div>
</div>
