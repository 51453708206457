import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { Constants } from '@modules/common/types/Constants';
import { Button2Component } from '@modules/form-controls/components/button/button2.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  selector: 'app-pagination2',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pagination2.component.html',
  imports: [IconComponent, Button2Component],
})
export class Pagination2Component {
  public readonly pageSize = input(Constants.PAGE_SIZE);
  public readonly totalItems = input.required<number>();
  public readonly page = model.required<number>();
  public readonly refresh = output();

  public readonly showingFrom = computed(() => (this.page() - 1) * this.pageSize() + 1);
  public readonly showingTo = computed(() =>
    this.totalItems() ? Math.min(this.totalItems(), this.page() * this.pageSize()) : undefined,
  );
  public readonly isLastPage = computed(() => Math.ceil(this.totalItems() / this.pageSize()) === this.page());

  public prevPage(): void {
    const page = this.page();
    if (page > 1) {
      this.page.set(page - 1);
    }
  }

  public nextPage(): void {
    if (!this.isLastPage()) {
      const page = this.page();
      this.page.set(page + 1);
    }
  }
}
