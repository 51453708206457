// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { VirtualFolder } from '@modules/files/types/virtual-folder';

@Pipe({
  name: 'titleByFilesFilter',
  standalone: false,
})
export class TitleByFilesFilterPipe implements PipeTransform {
  transform(value: VirtualFolder): any {
    if (!value) {
      return 'All';
    }

    switch (value) {
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'all_files':
        return 'Files';
      case 'flagged':
        return 'Flagged';
      case 'upcoming':
        return 'Upcoming';
      case 'recent':
        return 'Recent';
      case 'followed':
        return 'Follow up';
      case 'all_folders':
        return 'Folders';
      case 'snoozed':
        return 'Snoozed';
      default:
        return checkExhaustiveness(value);
    }
  }
}
