// Common
import { Component, Injector, OnInit } from '@angular/core';
import { environment } from '@environment';

// Types
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { MessagesCounts } from '@modules/messages/types/messages-counts';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Application } from '@modules/common/types/application';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { SidebarItem } from '@modules/common/types/sidebar-item';

// Services
import { MailAppStateService } from '@modules/messages/services/state.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { FoldersService } from '@modules/files/services/folders.service';

// RX
import { interval, merge } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Components
import { BaseSidebarComponent } from '@modules/common/components/base-sidebar/base-sidebar.component';

@Component({
  selector: 'app-messages-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
  standalone: false,
})
export class MessagesSideBarComponent
  extends BaseSidebarComponent<MessageFolder, Message, VirtualFolder>
  implements OnInit
{
  public counts: MessagesCounts;
  application = Application.mail;
  sidebarSplitViewKey = SidebarSplitViewKey.mail;
  selfDragDataTypes = [DragDataTypes.message, DragDataTypes.messageFolder];

  public items: SidebarItem[] = [
    { title: 'Inbox', id: 'all_messages', icon: 'sapphire-inbox' },
    { title: 'Bulk', id: 'bulk', icon: 'sapphire-bulk' },
    { title: 'Sent', id: 'sent', icon: 'sapphire-sent' },
    { title: 'Draft', id: 'draft', icon: 'sapphire-draft' },
    { title: 'Flagged', id: 'flagged', icon: 'sapphire-flagged' },
    { title: 'Follow Up', id: 'followed', icon: 'sapphire-follow-up' },
    { title: 'Snoozed', id: 'snoozed', icon: 'sapphire-snooze' },
    { title: 'Scheduled', id: 'scheduled', icon: 'sapphire-scheduled' },
    { title: 'Archive', id: 'archived', icon: 'sapphire-archive' },
    { title: 'Trash', id: 'deleted', icon: 'sapphire-trash' },
    { title: 'Spam', id: 'spam', icon: 'sapphire-spam' },
  ];

  constructor(
    injector: Injector,
    stateService: MailAppStateService,
    private messagesService: MessagesService,
    private messageFoldersService: FoldersService,
  ) {
    super(injector, stateService);
  }

  ngOnInit() {
    super.ngOnInit();

    merge(this.messagesService.getRefreshRequired(), interval(environment.messageFetchInterval))
      .pipe(
        switchMap(() => this.messagesService.getMessagesCount()),
        takeUntil(this.alive),
      )
      .subscribe((counts) => (this.counts = counts));
  }

  /**
   * Actions
   */

  handleMove(dragData: DragData, sidebarFolder: VirtualFolder) {
    if (dragData.type === DragDataTypes.message) {
      const { changes, message } = Message.getChangesForVirtualFolder(sidebarFolder);
      this.messagesService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    } else if (dragData.type === DragDataTypes.messageFolder) {
      const { changes, message } = MessageFolder.getChangesForVirtualFolder(sidebarFolder);
      this.messageFoldersService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    }
  }

  handleMore(item: Message) {
    this.stateService.setMainView(item);
  }

  getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder): Message {
    const { changes } = Message.getChangesForVirtualFolder(sidebarFolder);

    return new Message({ ...changes, subject: dragData.data[0]['title'] });
  }
}
