// Types
import { Filters } from '@modules/common/types/filters';
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';
import { isNil } from 'lodash';
import { Connection } from './connection';
import { ManageListState } from './manage-list-state';

export class ConnectionsFilters extends Filters {
  // Filters
  connections?: Connection[];
  connections_boost?: number;
  createdFrom?: Date;
  createdTo?: Date;
  items?: Stitch[];
  knots?: Knot[];
  knots_boost?: number;
  names?: string[];
  query?: string | string[];
  query_boost?: number;
  tags?: Tag[];
  tags_boost?: number;

  // Sort Options
  sortBy?: 'name' | 'date' | 'linkDate' | 'recency' | 'frequency';
  pinnedOnTop?: boolean;

  constructor(filters: Partial<ConnectionsFilters> = {}) {
    super(filters);
    this.connections = filters.connections || [];
    this.createdFrom = filters.createdFrom;
    this.createdTo = filters.createdTo;
    this.items = filters.items || [];
    this.knots = filters.knots || [];
    this.names = filters.names || [];
    this.pinnedOnTop = filters.pinnedOnTop;
    this.query = filters.query;
    this.sortBy = filters.sortBy;
    this.tags = filters.tags || [];
  }

  static fromListState(state: ManageListState): ConnectionsFilters {
    return new ConnectionsFilters({
      createdFrom: state.filters.createdFrom,
      createdTo: state.filters.createdTo,
      sortBy: state.sort.by,
      sortOrder: state.sort.order,
    });
  }

  format(): { [param: string]: string | string[] } {
    const result = {};

    if (this.names.length || this.connections.length) {
      result['names[]'] = [...this.names, ...this.connections.map((c) => c.name)];
    }

    if (this.knots?.length) {
      result['knots[]'] = this.knots.map(({ name }) => name);
    }

    if (this.tags?.length) {
      result['tags[]'] = this.tags.map(({ name }) => name);
    }

    if (Array.isArray(this.query)) {
      result['query[]'] = this.query;
    } else if (this.query?.trim()?.length) {
      result['query'] = this.query;
    }

    if (this.ids?.length) {
      result['ids[]'] = this.ids;
    }

    if (this.createdFrom) {
      result['created_from_time'] = this.createdFrom.toISOString();
    }
    if (this.createdTo) {
      result['created_to_time'] = this.createdTo.toISOString();
    }

    [
      ['message_folders_ids[]', StitchType.messageFolder],
      ['messages_ids[]', StitchType.message],
      ['calendars_ids[]', StitchType.calendar],
      ['events_ids[]', StitchType.event],
      ['projects_ids[]', StitchType.project],
      ['tasks_ids[]', StitchType.task],
      ['notebooks_ids[]', StitchType.notebook],
      ['notes_ids[]', StitchType.note],
      ['groups_ids[]', StitchType.group],
      ['contacts_ids[]', StitchType.contact],
      ['folders_ids[]', StitchType.folder],
      ['files_ids[]', StitchType.file],
    ].forEach(([key, stitchType]) => {
      const items = this.items?.filter((item) => item?.getStitchType() === stitchType) || [];

      if (items.length) {
        result[key] = items.map((item) => item.id);
      }
    });

    if (this.sortBy) {
      result['sort_by'] = this.sortBy;
    }
    if (this.sortOrder) {
      result['sort_order'] = this.sortOrder;
    }

    if (!isNil(this.limit)) {
      result['limit'] = this.limit + '';
    }
    if (!isNil(this.offset)) {
      result['offset'] = this.offset + '';
    }

    return result;
  }
}
