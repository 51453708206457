// Common
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { STCH_HTTP_INTERCEPTORS } from '../injection-tokens/http-interceptor.injection-token';

// RX
import { Observable } from 'rxjs';

export class MyHttpInterceptorHandler implements HttpHandler {
  constructor(
    private next: HttpHandler,
    private interceptor: HttpInterceptor,
  ) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}

export class MyHandlerService implements HttpHandler {
  private chain: HttpHandler | null = null;

  constructor(
    private backend: HttpBackend,
    private injector: Injector,
    private interceptors: InjectionToken<HttpInterceptor[]>,
  ) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (this.chain === null) {
      const interceptors = this.injector.get(this.interceptors, []);
      this.chain = interceptors.reduceRight(
        (next, interceptor) => new MyHttpInterceptorHandler(next, interceptor),
        this.backend,
      );
    }
    return this.chain.handle(req);
  }
}

@Injectable()
export class StchHttpService extends HttpClient {
  constructor(backend: HttpBackend, injector: Injector) {
    super(new MyHandlerService(backend, injector, STCH_HTTP_INTERCEPTORS));
  }
}
